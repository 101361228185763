import { Form, Col, Button, Modal, HeaderRow, LoaderOverlay } from "@emanprague/ppaskit";
import { preventDefault, registerView, ViewComponent } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import React from "react";
import { Input } from "@frui.ts/bootstrap";
import LinksDetailViewModel from "viewModels/admin/linksDetailViewModel";

const linksDetailView: ViewComponent<LinksDetailViewModel> = observer(({ vm }) => (
  <Modal centered show onHide={vm.requestClose} className="admin-modal">
    <Modal.Header closeButton>
      <HeaderRow title={vm.name} level={4} />
    </Modal.Header>
    <LoaderOverlay loading={vm.busyWatcher.isBusy}>
      <Modal.Body>
        <Form className="admin-form" onSubmit={preventDefault(vm.confirmRequest)}>
          {vm.links.map((link, index) => (
            <Form.Row key={index}>
              <Form.Group as={Col} xs={12}>
                <Form.Label className="admin-form-header">
                  <strong>{vm.translate(`link${index + 1}`)}</strong>
                </Form.Label>
              </Form.Group>
              <Form.Group as={Col} xs={12}>
                <Form.Label>{vm.translate("label")}</Form.Label>
                <Input target={link} property="text" />
              </Form.Group>
              <Form.Group as={Col} xs={12}>
                <Form.Label>{vm.translate("link")}</Form.Label>
                <Input target={link} property="url" />
              </Form.Group>
            </Form.Row>
          ))}
        </Form>
      </Modal.Body>
    </LoaderOverlay>
    <Modal.Footer>
      <Button onClick={vm.requestClose} variant="secondary">
        {vm.translateGeneral("cancel_button")}
      </Button>
      <Button disabled={!vm.canConfirm} variant="primary" onClick={vm.confirmRequest} className="ml-1">
        {vm.translateGeneral("confirm_changes_button")}
      </Button>
    </Modal.Footer>
  </Modal>
));

registerView(linksDetailView, LinksDetailViewModel);
