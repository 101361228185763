import { observable } from "mobx";
import { Type } from "class-transformer";
import Notification from "./notification";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class NotificationList {
  @observable
  total!: number;

  @observable
  limit?: number;

  @observable
  offset?: number;

  @observable
  @Type(() => Notification)
  notifications!: Notification[];

  static ValidationRules = {
    total: { required: true, number: true },
    limit: { number: true },
    offset: { number: true },
    notifications: { required: true },
  };
}

export class NotificationListFilter {}
