import React from "react";
import CommodityType from "models/commodityType";

export function getCommodityUnit(commodity: CommodityType): React.ReactNode {
  switch (commodity) {
    case CommodityType.Electricity:
      return "kWh";
    case CommodityType.Gas:
      return (
        <>
          m<sup>3</sup>
        </>
      );
    default:
      return undefined;
  }
}
