import { observable } from "mobx";
import ProductCardListItem from "./productCardListItem";
import { Expose } from "class-transformer";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class ProductCardPreview extends ProductCardListItem {
  @observable
  text!: string;

  @observable
  @Expose({ name: "button_text" })
  buttonText?: string;

  @observable
  @Expose({ name: "button_url" })
  buttonUrl?: string;

  static ValidationRules = Object.assign(
    {
      text: { required: true },
    },
    ProductCardListItem.ValidationRules
  );
}
