import { StepperRequestStep } from "@emanprague/ppaskit/dist/StepperRequest";
import { IEventBus, ILocalizationService } from "@emanprague/shared-services";
import { bound, IDisposable } from "@frui.ts/helpers";
import { BusyWatcher, Router, watchBusy } from "@frui.ts/screens";
import formatISO from "date-fns/formatISO";
import startOfDay from "date-fns/startOfDay";
import subMonths from "date-fns/subMonths";
import subYears from "date-fns/subYears";
import downloadjs from "downloadjs";
import Request from "entities/request";
import RequestState from "entities/requestState";
import debounce from "lodash/debounce";
import { action, computed, observable, runInAction } from "mobx";
import RequestsFilter from "models/requestsFilter";
import RequestsRepository from "repositories/requestsRepository";
import DataSyncService from "services/dataSyncService";
import EnumsService from "services/enumsService";
import { RequestsEvents } from "services/events";
import RequestsService from "services/requestsService";
import UserContext from "services/userContext";
import { IModule } from "viewModels/types";
import ContinuousListViewModelBase from "../continuousListViewModelBase";
import ModalCreateRequestViewModel from "./modalCreateRequestViewModel";

const navigationName = "requests";

@Router.registerRoute({ name: Router.Self, route: navigationName })
export default class OverviewViewModel extends ContinuousListViewModelBase<Request, RequestsFilter> implements IModule {
  orderIndex = 4;
  navigationName = navigationName;
  busyWatcher = new BusyWatcher();

  @observable requests: Request[];
  @observable notificationBadge?: number;

  private eventHandlers: IDisposable[];

  constructor(
    public localization: ILocalizationService,
    private requestsService: RequestsService,
    private userContext: UserContext,
    private enumsService: EnumsService,
    private dataSyncService: DataSyncService,
    private modalCreateRequestViewModelFactory: ReturnType<typeof ModalCreateRequestViewModel.Factory>,
    private repository: RequestsRepository,
    eventBus: IEventBus
  ) {
    super();

    this.name = localization.translateGeneral("requests.title");
    this.loadNotificationsCount();

    this.eventHandlers = [
      eventBus.subscribe(
        RequestsEvents.requestsUpdated,
        debounce(() => {
          this.applyFilterAndLoad();
          this.loadNotificationsCount();
        }, 200)
      ),
    ];
  }

  protected onInitialize() {
    this.loadData();
  }

  protected async onDeactivate(isClosing: boolean) {
    await super.onDeactivate(isClosing);

    if (isClosing) {
      this.eventHandlers?.forEach(x => x.dispose());
    }
  }

  @action.bound
  @watchBusy
  async loadData() {
    const partnerId = this.userContext.activePartnerId;
    if (!partnerId) {
      return;
    }

    await this.dataSyncService.waitForRequests();

    const result = await this.requestsService.getRequests(partnerId, this.pagingFilter, this.filter);
    if (result.success) {
      runInAction(() => {
        this.setData(result.payload);
      });
    }
  }

  @action.bound
  async loadNotificationsCount() {
    const partnerId = this.userContext.activePartnerId;
    if (partnerId) {
      const result = await this.requestsService.getActiveRequestsCount(partnerId);
      if (result.success) {
        runInAction(() => (this.notificationBadge = result.payload[1].totalItems));
      }
    }
  }

  @action.bound
  async downloadFile(id: number) {
    const partnerId = this.userContext.activePartnerId;
    if (partnerId) {
      const response = await this.repository.getFile(partnerId, id);
      if (response.success) {
        const mimeType = (response.payload as any).headers.get("Content-Type") as string;
        const fileNameFromServer = (response.payload as any).headers.get("content-disposition") ?? "default-name";
        const fileName = decodeURIComponent(fileNameFromServer.split("UTF-8")[1].slice(2));
        const body = await (response.payload as any).blob();
        downloadjs(body, fileName, mimeType);
      }
    }
  }

  @action.bound
  activateCreateRequestModal() {
    const partnerId = this.userContext.activePartnerId;
    if (partnerId) {
      return this.tryActivateChild(this.modalCreateRequestViewModelFactory(partnerId));
    }
  }

  @computed
  get formTypes() {
    return this.enumsService.getValues("formTypes");
  }

  @computed
  get formPeriods() {
    const startOfToday = startOfDay(new Date());
    const lastMonth = subMonths(startOfToday, 1);
    const lastYear = subYears(startOfToday, 1);

    return [
      { id: formatISO(lastMonth, { representation: "date" }), name: this.translateGeneral("filter_last_month") },
      { id: formatISO(lastYear, { representation: "date" }), name: this.translateGeneral("filter_last_year") },
    ];
  }

  getRequestStepperNumberOfSteps({ statuses }: Request) {
    if (statuses) {
      const steps: StepperRequestStep[] = statuses.map((item, index) => {
        return { title: item.description };
      });
      if (statuses.length === 4 && statuses[2].estimatedTime) {
        steps[1].note = statuses[2].estimatedTime;
      }
      return steps;
    }
    return [];
  }

  getLastActiveStep({ statuses }: Request) {
    if (!statuses?.length) {
      return 0;
    }

    const waitingIndex = statuses.findIndex(x => !x.isDone);
    if (waitingIndex === -1) {
      // all is done
      return statuses.length;
    } else {
      return waitingIndex;
    }
  }

  @action.bound
  applyRequestStateFilter(requestState?: RequestState) {
    this.filter.state = requestState;
    this.applyFilterAndLoad();
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`requests.${key}`);
  }

  @bound translateGeneral(key: string) {
    return this.localization.translateGeneral(`general.${key}`);
  }

  protected findNavigationChild(navigationName: string | undefined) {
    return undefined;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected resetFilterValues(filter: RequestsFilter) {}

  protected createFilter() {
    return new RequestsFilter();
  }
}
