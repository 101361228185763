import { observable } from "mobx";
import { Expose } from "class-transformer";
import ReqPressModalButtonChoice from "./reqPressModalButtonChoice";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class ReqPressModalButton {
  /**
   * Agree with GDPR
   */
  @observable
  @Expose({ name: "gdpr_consent" })
  gdprConsent?: boolean;

  @observable
  choice!: ReqPressModalButtonChoice;

  static ValidationRules = {
    choice: { required: true },
  };
}
