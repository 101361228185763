import { Expose, Transform, Type } from "class-transformer";
import formatISO from "date-fns/formatISO";
import { observable } from "mobx";
import Attachment from "./attachment";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class AppendixListAppendixesItem {
  id!: number;

  @observable
  type!: string;

  @observable
  code!: string;

  @observable
  @Type(() => Attachment)
  attachments!: Attachment[];

  @observable
  @Transform(value => (value ? new Date(value) : undefined), { toClassOnly: true })
  @Transform(value => (value ? formatISO(value, { representation: "date" }) : undefined), { toPlainOnly: true })
  @Expose({ name: "date_from" })
  dateFrom!: Date;

  @observable
  @Transform(value => (value ? new Date(value) : undefined), { toClassOnly: true })
  @Transform(value => (value ? formatISO(value, { representation: "date" }) : undefined), { toPlainOnly: true })
  @Expose({ name: "date_to" })
  dateTo?: Date;

  @observable
  state!: string;

  @observable
  color?: string;

  static ValidationRules = {
    id: { required: true, number: true },
    type: { required: true },
    code: { required: true },
    attachments: { required: true },
    dateFrom: { required: true, date: true },
    dateTo: { date: true },
    state: { required: true },
  };
}
