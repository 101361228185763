import { observable } from "mobx";
import CommodityType from "./commodityType";

export class ConsumptionData {
  @observable complaintVt?: boolean;
  @observable complaintNt?: boolean;

  @observable oldValue?: number;
  @observable currentValue?: number;
  @observable claimedValue?: number;
  @observable oldValueNt?: number;
  @observable currentValueNt?: number;
  @observable claimedValueNt?: number;
}

export const getConsumptionDataValidationRules = (commodityType?: CommodityType) => ({
  complaintVt: {
    requiredIf: {
      condition: (item: ConsumptionData) => commodityType === CommodityType.Electricity && !item.complaintNt,
    },
  },
  complaintNt: {
    requiredIf: {
      condition: (item: ConsumptionData) => commodityType === CommodityType.Electricity && !item.complaintVt,
    },
  },
  oldValue: {
    requiredIf: { condition: (item: ConsumptionData) => commodityType === CommodityType.Gas || item.complaintVt },
    isFloat: true,
  },
  currentValue: {
    requiredIf: { condition: (item: ConsumptionData) => commodityType === CommodityType.Gas || item.complaintVt },
    isFloat: true,
  },
  claimedValue: {
    isFloat: true,
  },
  oldValueNt: {
    requiredIf: { condition: (item: ConsumptionData) => item.complaintNt },
    isFloat: true,
  },
  currentValueNt: {
    requiredIf: { condition: (item: ConsumptionData) => item.complaintNt },
    isFloat: true,
  },
  claimedValueNt: {
    isFloat: true,
  },
});

export const getIncorrectFinancialStateValidationRules = (commodityType?: CommodityType) => ({
  supplyPointId: { required: true },
  claimTypeId: { required: true },
  note: { required: true },
  rateId: { required: commodityType === CommodityType.Electricity },
});

export const getIncorrectBillingAndOtherValidationRules = () => ({
  supplyPointId: { required: true },
  claimTypeId: { required: true },
  note: { required: true },
});
