import iconTime from "@emanprague/ppaskit/dist/assets/icons/State/Due Soon.svg";
import iconStateOk from "@emanprague/ppaskit/dist/assets/icons/State/Ok.svg";
import iconStateNotOk from "@emanprague/ppaskit/dist/assets/icons/State/Not Ok.svg";
import iconStatePending from "@emanprague/ppaskit/dist/assets/icons/State/Pending.svg";
import AdvanceListItemState from "entities/advanceListItemState";
import InvoiceListItemState from "entities/invoiceListItemState";

export const getIconByStateType = (state?: AdvanceListItemState | InvoiceListItemState) => {
  if (state === "before_payment") {
    return iconTime;
  } else if (state === "payment_in_process") {
    return iconStatePending;
  } else if (state === "unpaid") {
    return iconStateNotOk;
  }
  return iconStateOk;
};

export const getStyleByStateType = (state?: AdvanceListItemState | InvoiceListItemState) => {
  if (state === "before_payment") {
    return { color: "#00264C", fontWeight: 600 };
  } else if (state === "unpaid") {
    return { color: "#DA2022", fontWeight: 600 };
  }
};
