import React from "react";
import { ColoredLabel as Label } from "@emanprague/ppaskit";

export const getColoredLabelFinance = ({ item, value }: any, label?: string) => (
  <Label label={label || value} isRed={item.state === "unpaid"} isBlue={item.state === "before_payment"} />
);

export const getColoredLabelMessages = ({ item, value }: { item: { read?: boolean }; value: string }, label?: string) => (
  <Label label={label || value} isBlue={!item.read} />
);
