import { registerView, ViewComponent, preventDefault } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import React from "react";
import { Form, Col, Button, Modal, HeaderRow, LoaderOverlay } from "@emanprague/ppaskit";
import { Input, Select } from "@frui.ts/bootstrap";
import ModalCreateRequestViewModel from "viewModels/requests/modalCreateRequestViewModel";
import Uploader from "controls/uploader";

const modalCreateRequestView: ViewComponent<ModalCreateRequestViewModel> = observer(({ vm }) => (
  <Modal centered show onHide={vm.requestClose}>
    <Modal.Header closeButton>
      <HeaderRow title={vm.translate("general_question")} level={4} />
    </Modal.Header>
    <LoaderOverlay loading={vm.busyWatcher.isBusy}>
      <Modal.Body>
        <Form className="w-100 w-sm-75 mx-auto mb-n3" onSubmit={preventDefault(vm.confirmRequest)}>
          <Form.Row className="mb-3">
            <Form.Group as={Col} xs={10} className="m-auto">
              <Form.Label>{vm.translate("typ_question")}</Form.Label>
              <Select
                target={vm.data}
                property="requestTypeId"
                items={vm.requestFormTypes}
                mode="key"
                keyProperty="id"
                textProperty="name"
              />
            </Form.Group>
          </Form.Row>
          <Form.Row className="mb-5">
            <Form.Group as={Col} xs={10} className="m-auto">
              <Form.Label>{vm.translate("question")}</Form.Label>
              <Input
                {...{ as: "textarea", rows: 5 }}
                placeholder={vm.translate("question_placeholder")}
                target={vm.data}
                property="text"
              />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} xs={10} className="m-auto">
              <Form.Label>
                <strong>{vm.translateGeneral("upload_attachment")}</strong>
                <span className="text-muted">{" " + vm.translateGeneral("not_mandatory")}</span>
              </Form.Label>
              {vm.errorMessage && <Form.Control.Feedback type="invalid">{vm.errorMessage}</Form.Control.Feedback>}
              <Uploader
                localization={vm.localization}
                onDrop={vm.onDrop}
                onDropRejected={vm.onDropRejected}
                fileSelectError={vm.fileSelectError}
              />
            </Form.Group>
          </Form.Row>
        </Form>
      </Modal.Body>
    </LoaderOverlay>
    <Modal.Footer>
      <Button disabled={!vm.canConfirm} variant="primary" className="order-2" onClick={vm.confirmRequest}>
        {vm.localization.translateGeneral("general.confirm_changes_button")}
      </Button>
      <Button onClick={vm.requestClose} variant="secondary" className="order-1">
        {vm.localization.translateGeneral("general.cancel_button")}
      </Button>
    </Modal.Footer>
  </Modal>
));

registerView(modalCreateRequestView, ModalCreateRequestViewModel);
