import { Col, Row } from "@emanprague/ppaskit";
import logoPpas from "@emanprague/ppaskit/dist/assets/images/Logo_PPAS.svg";
import React from "react";

interface PreAuthHeaderProps {
  translateFunc: (translateCode: string) => string;
  title?: string;
}

export default class PreAuthHeader extends React.PureComponent<PreAuthHeaderProps> {
  render() {
    return (
      <Row noGutters className="justify-content-between align-items-center pt-5 pb-4 pt-sm-4 pb-sm-3 pb-md-4">
        <Col xs={12} sm="auto" className="d-flex justify-content-center mb-3 mb-md-0">
          <a href="#/">
            <img src={logoPpas} alt="" />
          </a>
        </Col>
        <Col xs={12} sm="auto" className="d-flex justify-content-center">
          <h4 className="display-4 mb-0 mobile-light">{this.props.translateFunc("app_title")}</h4>
        </Col>
        {this.props.title && (
          <Col xs={12} className="mt-4 mb-md-2">
            <h1 className="display-1 text-center mb-0">{this.props.title}</h1>
          </Col>
        )}
      </Row>
    );
  }
}
