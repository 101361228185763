import SupplyPointSimple from "entities/supplyPointSimple";
import React from "react";
import { getSupplyPointTypeIcon } from "helpers/supplyPointHelper";
import CommodityType from "models/commodityType";
import { getStyleByStateType } from "helpers/financeHelper";
import AdvanceListItemState from "entities/advanceListItemState";
import InvoiceListItemState from "entities/invoiceListItemState";

const SupplyPointInline: React.FunctionComponent<{
  supplyPoint: SupplyPointSimple;
  // tslint:disable-next-line:prefer-optional
  commodityType: CommodityType | undefined;
  state?: AdvanceListItemState | InvoiceListItemState;
}> = ({ supplyPoint, commodityType, state }) => {
  const splitAddress = (addressText: string) => {
    const parts = addressText.split(", ");
    return parts.map((item, index) => (
      <span key={index}>
        {item}
        <br />
      </span>
    ));
  };

  return (
    <div className="d-flex align-items-center">
      <div className="mr-2">
        <img
          width="22"
          height="21"
          draggable={false}
          src={getSupplyPointTypeIcon(commodityType, state === "unpaid" ? "red" : "yellowAndLightBlue")}
          alt=""
        />
      </div>
      <div className="text-nowrap">
        <span style={getStyleByStateType(state)}>{supplyPoint.name}</span>
        <div style={getStyleByStateType(state)}>{splitAddress(supplyPoint.addressText)}</div>
      </div>
    </div>
  );
};

export default SupplyPointInline;
