import React from "react";
import Nav from "react-bootstrap/Nav";
import { styleBlock } from "..";

export interface TabsProps {
  id: number | string;
  title: string;
  href?: string;
  onClick?: any;
}
export interface TabsNavProps {
  className?: string;
  activeId?: TabsProps["id"];
  tabs?: Array<TabsProps>;
}
const TabsNav: React.FunctionComponent<TabsNavProps> = ({ activeId, tabs, className, ...props }) => {
  const classNames = styleBlock("tabsnav");
  const cn = classNames({}).mix([className]);

  return (
    <div className={cn.toString()}>
      <Nav variant="tabs">
        {tabs &&
          tabs.map((tab, index) => {
            return (
              <Nav.Item key={"tablink-" + index}>
                <Nav.Link href={tab.href} active={tab.id === activeId} onClick={tab.onClick}>
                  {tab.title}
                </Nav.Link>
              </Nav.Item>
            );
          })}
      </Nav>
    </div>
  );
};

export default TabsNav;
