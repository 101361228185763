import { ILocalizationService } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { Router } from "@frui.ts/screens";
import InvoicesRepository from "repositories/invoicesRepository";
import DataSyncService from "services/dataSyncService";
import EnumsService from "services/enumsService";
import UserContext from "services/userContext";
import InvoiceDetailViewModel from "viewModels/finance/invoiceDetailViewModel";
import InvoicesPageViewModel from "viewModels/finance/invoicesPageViewModel";
import { IDetailPage } from "viewModels/types";
import ProductDetailViewModel from "../productDetailViewModel";
import InvoiceListItemState from "entities/invoiceListItemState";

const navigationName = "invoices";

@Router.registerRoute({ name: Router.Self, route: `${navigationName}` })
export default class InvoicesDetailPageViewModel extends InvoicesPageViewModel implements IDetailPage {
  orderIndex = 2;
  navigationName = navigationName;
  className = "icon-receipt";

  parent: ProductDetailViewModel;

  constructor(
    localization: ILocalizationService,
    userContext: UserContext,
    dataService: DataSyncService,
    repository: InvoicesRepository,
    enumsService: EnumsService,
    router: Router,
    detailFactory: ReturnType<typeof InvoiceDetailViewModel.Factory>
  ) {
    super(localization, userContext, dataService, repository, enumsService, router, detailFactory);

    this.name = this.translateProductScope("title");
  }

  @bound
  onInitialize() {
    this.filter.supplyPointIds = [this.parent.productDetailContext.supplyPointId];
    this.initializeStateFilter();
  }

  @bound
  navigate(subPath: string | undefined, params: { filter?: InvoiceListItemState }): Promise<void> {
    return super.navigate(subPath, params);
  }

  @bound translateProductScope(key: string) {
    return this.localization.translateGeneral(`products.invoices.${key}`);
  }

  get depositDueAmount() {
    return this.parent.productDetailContext.supplyPoint?.depositDueAmount;
  }

  get suffix() {
    if (this.depositDueAmount && this.depositDueAmount < 0) {
      return this.localization.formatNumber(this.depositDueAmount) + " " + this.translateGeneral("currency");
    }
    return undefined;
  }

  get supplyPoint() {
    return this.parent.productDetailContext.supplyPoint;
  }

  get supplyPointId() {
    return this.parent.productDetailContext.supplyPointId;
  }
}
