import { IEventBus } from "@emanprague/shared-services";
import FetchError from "@frui.ts/apiclient/dist/fetchError";
import { GeneralEvents } from "services/events";
import { ApiResult } from "./apiModels";
import BackendConnector from "./backendConnector";
import DeserializingRequestBuilder from "./deserializingRequestBuilder";
import { unwrapErrorMessage } from "./helpers";

export default abstract class RepositoryBase {
  protected apiFactory: () => DeserializingRequestBuilder;
  constructor(connector: BackendConnector, protected eventBus: IEventBus) {
    this.apiFactory = connector.getRequestBuilder;
  }

  protected callApi<T>(action: (api: DeserializingRequestBuilder) => Promise<T>, notifyError = true): Promise<ApiResult<T>> {
    return action(this.apiFactory()).then(
      payload => ({ success: true, payload }),
      (error: FetchError) => {
        if (notifyError && error.response?.status && error.response?.status !== 401 && error.response?.status !== 422) {
          const errorMessage = this.unwrapErrorMessage(error);
          this.eventBus.publish(GeneralEvents.apiError({ response: error, errorMessage }));
        }

        if (!error.content) {
          console.warn("Unexpected API error response structure.", error);
        } else if (!error.content.message) {
          // fix server error message
          error.content.message = this.unwrapErrorMessage(error);
        }

        return { success: false, payload: error.content };
      }
    );
  }

  protected unwrapErrorMessage(error: any | FetchError) {
    if (error?.content) {
      return unwrapErrorMessage(error.content);
    }

    return error?.toString() as string;
  }
}
