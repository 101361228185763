import { observable } from "mobx";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class Charge {
  @observable
  description!: string;

  @observable
  amount!: number;

  static ValidationRules = {
    description: { required: true },
    amount: { required: true, number: true },
  };
}
