/* eslint-disable sonarjs/cognitive-complexity */
import { ILocalizationService } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, ScreenBase, watchBusy } from "@frui.ts/screens";
import { attachAutomaticValidator, hasVisibleErrors, validate } from "@frui.ts/validation";
import ReqRegistrationStep2, { ExtendReqRegistrationStep2 } from "entities/reqRegistrationStep2";
import ReqRegistrationStep3, {
  customCompanyValidationRules,
  customHouseholdValidationRules,
} from "entities/reqRegistrationStep3";
import User from "entities/user";
import { action, observable, runInAction } from "mobx";
import PartnerType from "models/partnerType";
import { unwrapErrorMessage } from "repositories/helpers";
import LoginRepository from "repositories/loginRepository";
import PartnersRepository from "repositories/partnersRepository";
import EnumsService from "services/enumsService";
import SecurityService from "services/securityService";
import PasswordValidationService from "services/validation/passwordValidationService";
import { StepName } from "./registrationViewModel";

export default class ConfirmRegistrationViewModel extends ScreenBase {
  static navigationName = "confirmRegistration";
  navigationName = ConfirmRegistrationViewModel.navigationName;
  busyWatcher = new BusyWatcher();
  steps: Array<string> = [];

  @observable dataStep3: ExtendReqRegistrationStep2;
  @observable dataStep4: ReqRegistrationStep3;
  @observable currentStep: StepName = "step3";
  @observable errorMessageStep3?: string;
  @observable errorMessageStep4?: string;
  @observable isPartnerHouseHold = false;

  constructor(
    public localization: ILocalizationService,
    private passwordValidation: PasswordValidationService,
    private loginRepository: LoginRepository,
    private securityService: SecurityService,
    private partnersRepository: PartnersRepository,
    private enumsService: EnumsService
  ) {
    super();

    this.name = this.translate("title");

    this.steps = [this.translate("step1.title"), this.translate("step2.title"), this.translate("step3.title")];

    this.dataStep3 = new ExtendReqRegistrationStep2();
    this.dataStep4 = new ReqRegistrationStep3();

    attachAutomaticValidator(this.dataStep3, ExtendReqRegistrationStep2.CustomValidationRules);
  }

  get passwordComplexityValue() {
    return this.passwordValidation.getComplexityValue(this.dataStep3.password);
  }

  get canConfirm() {
    return !hasVisibleErrors(this.dataStep3);
  }

  get canFinalConfirm() {
    return !hasVisibleErrors(this.dataStep4);
  }

  get isTokenValid() {
    return this.dataStep3.token;
  }

  @action
  setErrorMessage(message?: string) {
    this.errorMessageStep3 = message;
  }

  @action.bound
  @watchBusy
  async confirm() {
    this.errorMessageStep3 = undefined;

    if (validate(this.dataStep3)) {
      const request = new ReqRegistrationStep2();
      request.token = this.dataStep3.token;
      request.password = this.dataStep3.password;

      const response = await this.loginRepository.registerStep2(request);

      runInAction(() => {
        if (response.success) {
          this.moveToStep4();
        } else {
          this.errorMessageStep3 = unwrapErrorMessage(response.payload);
        }
      });
    }
  }

  /**
   * After setting first password we have to fetch current partner,
   * to hide birth date for companies.
   * Ideally, we should check if the registration process is finished after each login,
   * and eventually display this step.
   */
  @bound
  @watchBusy
  async moveToStep4() {
    const userSession = await this.securityService.checkSession();

    if (userSession.user instanceof User) {
      if (userSession.user.role !== "customer") {
        userSession.applySessionCallback();
      } else {
        const partnerRequest = await this.partnersRepository.getPartner(userSession.user.activePartnerId);
        if (partnerRequest.success) {
          const partner = partnerRequest.payload;
          await this.enumsService.initialize();
          const isPartnerHouseHold = this.enumsService.getPartnerType(partner.partnerTypeId) === PartnerType.Household;

          if (isPartnerHouseHold) {
            attachAutomaticValidator(this.dataStep4, customHouseholdValidationRules);
          } else {
            attachAutomaticValidator(this.dataStep4, customCompanyValidationRules);
          }

          runInAction(() => {
            if (partner.phone) {
              this.dataStep4.phone = partner.phone;
            }
            this.isPartnerHouseHold = isPartnerHouseHold;
            this.currentStep = "step4";
          });
        }
      }
    }
  }

  @action.bound
  @watchBusy
  async finalConfirm() {
    this.errorMessageStep4 = undefined;

    if (this.dataStep4.birthDate && this.dataStep4.birthDate >= new Date()) {
      this.errorMessageStep4 = this.localization.translateGeneral("validators.birth_date");
      return;
    }

    if (validate(this.dataStep4)) {
      const response = await this.loginRepository.registerStep3(this.dataStep4);

      if (response.success) {
        const loginResult = await this.securityService.checkSession();
        loginResult.applySessionCallback();
      } else {
        runInAction(() => (this.errorMessageStep4 = unwrapErrorMessage(response.payload)));
      }
    }
  }

  @action navigate(subPath: string | undefined, params: any) {
    if (params && params.token) {
      this.dataStep3.token = params.token;
    }
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`profile.registration.${key}`);
  }
}
