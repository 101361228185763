import { observable } from "mobx";
import { Expose } from "class-transformer";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class ReqTransferSupplyPointRequestNaturalPerson {
  @observable
  @Expose({ name: "first_name" })
  firstName!: string;

  @observable
  @Expose({ name: "last_name" })
  lastName!: string;

  @observable
  @Expose({ name: "title_before_id" })
  titleBeforeId?: number;

  @observable
  @Expose({ name: "title_after_id" })
  titleAfterId?: number;

  static ValidationRules = {
    firstName: { required: true },
    lastName: { required: true },
    titleBeforeId: { number: true },
    titleAfterId: { number: true },
  };
}
