import { ILocalizationService } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { ConductorSingleChild, Router, ScreenBase } from "@frui.ts/screens";
import { IDetailPage } from "viewModels/types";
import ModalInvoiceMailingMethodViewModel from "./modalInvoiceMailingMethodViewModel";
import ProductDetailViewModel from "../../productDetailViewModel";
import ModalInvoicePaymentMethodViewModel from "./modalInvoicePaymentMethodViewModel";
import ModalAdvancePaymentMethodViewModel from "./modalAdvancePaymentMethodViewModel";
import EnumsService from "services/enumsService";
import AddressType from "entities/addressType";

const navigationName = "options_deposit";

@Router.registerRoute({
  name: ModalAdvancePaymentMethodViewModel,
  route: `${navigationName}/${ModalAdvancePaymentMethodViewModel.navigationName}`,
})
@Router.registerRoute({
  name: ModalInvoiceMailingMethodViewModel,
  route: `${navigationName}/${ModalInvoiceMailingMethodViewModel.navigationName}`,
})
@Router.registerRoute({
  name: ModalInvoicePaymentMethodViewModel,
  route: `${navigationName}/${ModalInvoicePaymentMethodViewModel.navigationName}`,
})
export default class OptionsDepositDetailPageViewModel extends ConductorSingleChild<ScreenBase> implements IDetailPage {
  orderIndex = 4;
  navigationName = navigationName;
  className = "icon-wrench";

  parent: ProductDetailViewModel;

  constructor(
    public localization: ILocalizationService,
    private enums: EnumsService,
    private modalInvoiceShippingMethodViewModel: ReturnType<typeof ModalInvoiceMailingMethodViewModel.Factory>,
    private modalInvoicePaymentMethodViewModel: ReturnType<typeof ModalInvoicePaymentMethodViewModel.Factory>,
    private modalAdvancePaymentMethodModel: ReturnType<typeof ModalAdvancePaymentMethodViewModel.Factory>
  ) {
    super();

    this.name = this.translate("title");
  }

  get account() {
    return this.parent.productDetailContext.supplyPoint?.account;
  }

  @bound addressByType(type: AddressType) {
    return this.account?.addresses?.find(x => x.type === type);
  }

  translatePaymentMethod(id: number | undefined) {
    if (id) {
      return this.enums.getValue("paymentMethods", id)?.name;
    } else {
      return "";
    }
  }

  @bound
  activateInvoiceMailingMethodModal() {
    return this.navigate(ModalInvoiceMailingMethodViewModel.navigationName, undefined);
  }

  @bound
  activateInvoicePaymentMethodModal() {
    return this.navigate(ModalInvoicePaymentMethodViewModel.navigationName, undefined);
  }

  @bound
  activateAdvancePaymentMethodModal() {
    return this.navigate(ModalAdvancePaymentMethodViewModel.navigationName, undefined);
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`products.options_deposit.${key}`);
  }

  @bound translateGeneral(key: string) {
    return this.localization.translateGeneral(`general.${key}`);
  }

  protected findNavigationChild(navigationName: string | undefined) {
    const address = this.addressByType("postal");

    switch (navigationName) {
      case ModalAdvancePaymentMethodViewModel.navigationName:
        return this.modalAdvancePaymentMethodModel(this.parent.productDetailContext);
      case ModalInvoiceMailingMethodViewModel.navigationName:
        return address
          ? this.modalInvoiceShippingMethodViewModel(
              this.parent.productDetailContext,
              address,
              this.account?.allowToChangeSentMethod ?? false
            )
          : undefined;
      case ModalInvoicePaymentMethodViewModel.navigationName:
        return this.modalInvoicePaymentMethodViewModel(this.parent.productDetailContext);
    }
    return undefined;
  }
}
