import { registerView, ViewComponent, preventDefault } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import React from "react";
import { Form, Button, Modal, Col, HeaderRow, LoaderOverlay } from "@emanprague/ppaskit";
import ModalEditCustomerInfoViewModel from "viewModels/products/myProducts/detailPages/customerInfo/modalEditCustomerInfoViewModel";
import { Input, Select } from "@frui.ts/bootstrap";
import DateInput from "controls/twoway/DateInput";
import ErrorMessage from "controls/errorMessage";
import Uploader from "controls/uploader";

const modalEditCustomerInfoView: ViewComponent<ModalEditCustomerInfoViewModel> = observer(({ vm }) => {
  const ta = vm.localization.translateAttribute;

  const form = vm.isCompany ? (
    <>
      <Form.Group as={Col} xs={10}>
        <Form.Label>{ta("partner", "name")}</Form.Label>
        <Input target={vm.customerData} property="name" />
      </Form.Group>
      <Form.Group className="text-center">
        <Form.Label className="mb-3 mt-4 font-size-md">
          <strong>{vm.translate("org_id_verification")}</strong>
        </Form.Label>
      </Form.Group>
      <Form.Group as={Col} xs={10}>
        <Form.Label>{ta("partner", "org_id")}</Form.Label>
        <Input target={vm.customerData} property="orgId" />
      </Form.Group>
    </>
  ) : (
    <>
      <Form.Group as={Col} xs={5}>
        <Form.Label>
          {vm.translate("title_before_name")} 1 <span className="text-muted">{vm.translateGeneral("not_mandatory")}</span>
        </Form.Label>
        <Select
          target={vm.customerData}
          property="titleBeforeId"
          items={vm.titlesBefore}
          mode="key"
          keyProperty="id"
          textProperty="name"
          allowEmpty
        />
      </Form.Group>
      <Form.Group as={Col} xs={5}>
        <Form.Label>
          {vm.translate("title_before_name")} 2 <span className="text-muted">{vm.translateGeneral("not_mandatory")}</span>
        </Form.Label>
        <Select
          target={vm.customerData}
          property="titleBefore2Id"
          items={vm.titlesBefore}
          mode="key"
          keyProperty="id"
          textProperty="name"
          allowEmpty
        />
      </Form.Group>
      <Form.Group as={Col} xs={10}>
        <Form.Label>{ta("partner", "first_name")}</Form.Label>
        <Input target={vm.customerData} property="firstName" />
      </Form.Group>
      <Form.Group as={Col} xs={10}>
        <Form.Label>{ta("partner", "last_name")}</Form.Label>
        <Input target={vm.customerData} property="lastName" />
      </Form.Group>
      <Form.Group as={Col} xs={10}>
        <Form.Label>
          {vm.translate("title_after_name")} 1 <span className="text-muted">{vm.translateGeneral("not_mandatory")}</span>
        </Form.Label>
        <Select
          target={vm.customerData}
          property="titleAfterId"
          items={vm.titlesAfter}
          mode="key"
          keyProperty="id"
          textProperty="name"
          allowEmpty
        />
      </Form.Group>
      <Form.Group className="text-center">
        <Form.Label className="mb-3 mt-4 w-75 font-size-md">
          <strong>{vm.translate("birth_date_verification")}</strong>
        </Form.Label>
      </Form.Group>
      <Form.Group as={Col} xs={10}>
        <Form.Label>{vm.localization.translateAttribute("user", "birth_date")}</Form.Label>
        <DateInput target={vm.customerData} property="birthDate" autoFocus />
      </Form.Group>
    </>
  );

  return (
    <Modal centered show onHide={vm.requestClose}>
      <Modal.Header closeButton>
        <HeaderRow title={vm.name} level={4} />
      </Modal.Header>
      <LoaderOverlay loading={vm.busyWatcher.isBusy}>
        <Modal.Body>
          <Form className="modal-form" onSubmit={preventDefault(vm.confirmChange)}>
            <Form.Row className="form-row-centered">{form}</Form.Row>
            <Form.Row className="form-row-centered">
              <Form.Group as={Col} xs={10} className="mt-4 mb-4">
                <Form.Label>
                  <strong>{vm.translateGeneral("upload_attachment")}</strong>{" "}
                  <span className="text-muted">{vm.translateGeneral("not_mandatory")}</span>
                  <div className="hint-text">{vm.translate("upload_hint")}</div>
                </Form.Label>
                <Uploader
                  localization={vm.localization}
                  onDrop={vm.onDrop}
                  onDropRejected={vm.onDropRejected}
                  fileSelectError={vm.fileSelectError}
                />
              </Form.Group>
            </Form.Row>

            <ErrorMessage message={vm.errorMessage} />
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={!vm.canConfirm} variant="primary" className="order-2" onClick={vm.confirmChange}>
            {vm.localization.translateGeneral("general.confirm_changes_button")}
          </Button>
          <Button onClick={vm.requestClose} variant="secondary" className="order-1">
            {vm.localization.translateGeneral("general.cancel_button")}
          </Button>
        </Modal.Footer>
      </LoaderOverlay>
    </Modal>
  );
});

registerView(modalEditCustomerInfoView, ModalEditCustomerInfoViewModel);
