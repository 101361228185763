import { registerView, ViewComponent, preventDefault } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import React from "react";
import { Form, Col, Button, Modal, HeaderRow, LoaderOverlay } from "@emanprague/ppaskit";
import { Input } from "@frui.ts/bootstrap";
import ModalEditContactInfoViewModel from "viewModels/products/myProducts/detailPages/customerInfo/modalEditContactInfoViewModel";
import PhoneInput from "controls/twoway/PhoneInput";
import AddressSelector from "controls/twoway/AddressSelector";

const modalEditContactInfoView: ViewComponent<ModalEditContactInfoViewModel> = observer(({ vm }) => {
  return (
    <>
      <Modal centered show onHide={vm.requestClose}>
        <Modal.Header closeButton>
          <HeaderRow title={vm.name} level={4} />
        </Modal.Header>
        <LoaderOverlay loading={vm.busyWatcher.isBusy}>
          <Modal.Body>
            <Form className="modal-form" onSubmit={preventDefault(vm.confirmChange)}>
              <Form.Row className="form-row-centered">
                <Form.Group className="text-center" as={Col} xs={11}>
                  <Form.Label className="mb-3 mt-4 font-size-sm">
                    <span className="text-gray">{vm.translate("contact_info_message")}</span>
                  </Form.Label>
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} xs={12}>
                  <Form.Label>{vm.localization.translateAttribute("user", "email")}</Form.Label>
                  <Input target={vm.data} property="email" />
                </Form.Group>
                <Form.Group as={Col} xs={12}>
                  <Form.Label>{vm.localization.translateAttribute("user", "phone")}</Form.Label>
                  <PhoneInput target={vm.data} property="phone" />
                </Form.Group>
                <AddressSelector vm={vm.addressChangeVM} />
                {vm.manualValidator.errors.base && (
                  <Form.Control.Feedback type="invalid">{vm.manualValidator.errors.base}</Form.Control.Feedback>
                )}
              </Form.Row>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" className="order-2" onClick={vm.confirmChange}>
              {vm.localization.translateGeneral("general.confirm_changes_button")}
            </Button>
            <Button onClick={vm.requestClose} variant="secondary" className="order-1">
              {vm.localization.translateGeneral("general.cancel_button")}
            </Button>
          </Modal.Footer>
        </LoaderOverlay>
      </Modal>
    </>
  );
});

registerView(modalEditContactInfoView, ModalEditContactInfoViewModel);
