import { Button, Card, Col, Layout, ProductRow, Row } from "@emanprague/ppaskit";
import { registerView, ViewComponent } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import React from "react";
import AppendixesPageViewModel from "viewModels/products/appendixesPageViewModel";
import ContractDetailPageViewModel from "viewModels/products/myProducts/detailPages/contract/contractDetailPageViewModel";

const appendixesPageView: ViewComponent<AppendixesPageViewModel> = observer(({ vm }) => {
  return (
    <Layout fluid="xl" className="mt-3">
      <ProductRow id="pr-1" title={vm.title} className="my-4 mt-sm-5" />
      {vm.supplyPointsWithAppendixes.map((supplyPoint, index) => (
        <div className="px-sm-2" key={`appendixRow-${index}`}>
          <Card key="1" className="mb-3 px-2 px-sm-0">
            <Card.Body className="py-4">
              <Row className="align-items-center appendix_row">
                <Col>
                  <h4 className="display-4 mb-0">
                    <img className="mr-2" width="22" height="21" draggable={false} src={vm.supplyPointIcon(supplyPoint)} alt="" />
                    {supplyPoint.name ?? supplyPoint.addressText}
                  </h4>
                  <div className="text-muted mb-2">{supplyPoint.addressText}</div>
                </Col>
                <div className="right_segment">
                  <Col xs={12} md="auto" className="my-4 mt-md-0 mb-3 mb-lg-0 mt-lg-1">
                    <span className="text-nowrap">{vm.getNumberOfAppendixesLabel(supplyPoint.id)}</span>
                  </Col>
                  <Col xs={12} lg="auto" className="ml-lg-auto ml-xl-3">
                    <Row noGutters className="text-nowrap">
                      <Col xs={6} md="auto" className="pl-1 pr-md-1">
                        <Button
                          variant="secondary"
                          className="my-1 mb-lg-0 w-100"
                          href={vm.router.getUrl(ContractDetailPageViewModel, { supplyPointId: supplyPoint.id })}>
                          {vm.translateGeneral("display_button")}
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </div>
              </Row>
            </Card.Body>
          </Card>
        </div>
      ))}
    </Layout>
  );
});

registerView(appendixesPageView, AppendixesPageViewModel, "screen");
