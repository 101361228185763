import ReadingChartData from "entities/readingChartData";
import ReadingList from "entities/readingList";
import ReqFile from "entities/reqFile";
import ReqNewReading from "entities/reqNewReading";
import { RequestsEvents } from "services/events";
import RepositoryBase from "./repositoryBase";

export default class ReadingsRepository extends RepositoryBase {
  getReadingsList(partnerId: number, supplyPointId: number, limit = 10, offset = 0) {
    return this.callApi(api =>
      api.all(`partners/${partnerId}/supply_points/${supplyPointId}/readings`).getEntity(ReadingList, { limit, offset })
    );
  }

  getReadingsListChart(partnerId: number, supplyPointId: number, limit = 10, offset = 0) {
    return this.callApi(api =>
      api
        .all(`partners/${partnerId}/supply_points/${supplyPointId}/readings/chart`)
        .getEntity(ReadingChartData, { limit, offset })
    );
  }

  setReading(userData: ReqNewReading, partnerId: number, supplyPointId: number) {
    const requestBody = userData;
    requestBody.valueNt = !!String(requestBody.valueNt) ? requestBody.valueNt : undefined;
    const promise = this.callApi(api =>
      api.all(`partners/${partnerId}/supply_points/${supplyPointId}/readings`).postEntity(requestBody, ReqNewReading)
    );
    promise.then(() => this.eventBus.publish(RequestsEvents.requestCreated(userData)));

    return promise;
  }

  makeBulkSelfReading(partnerId: number, reqFile: ReqFile) {
    const formData = reqFile.toFormData();
    const promise = this.callApi(api => api.one("partners", partnerId).all("readings/bulk").postData(formData));
    promise.then(() => this.eventBus.publish(RequestsEvents.requestsUpdated(undefined)));

    return promise;
  }

  getSelfReadingTemplate() {
    return this.callApi(api => api.all("templates/bulk_reading").getRaw());
  }

  getSelfReadingFile(partnerId: number) {
    return this.callApi(api => api.one("partners", partnerId).all("bulk_readings/file").getRaw());
  }
}
