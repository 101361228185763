/* eslint-disable @typescript-eslint/no-throw-literal */
import type { ILocalizationService, INotificationService } from "@emanprague/shared-services";
import { EnumsServiceBase, SeverityLevel } from "@emanprague/shared-services";
import type PortalSettings from "entities/portalSettings";
import type SimplePortalSettings from "entities/simplePortalSettings";
import type PortalSettingsRepository from "repositories/portalSettingsRepository";

export default class PortalSettingsService extends EnumsServiceBase<PortalSettings> {
  constructor(
    private repository: PortalSettingsRepository,
    private notificationService: INotificationService,
    private localization: ILocalizationService
  ) {
    super();
  }

  /**
   * Gets the setting by its key. If the seeting does not exists, nothing (undefined) is returned.
   * Handle returned value accordingly.
   */
  getSimplePortalSettings(key: string): SimplePortalSettings | undefined {
    const simpleSettings = this.getValues("simple");
    return simpleSettings.find((setting: SimplePortalSettings) => setting.key === key);
  }

  async updatePortalSettings(setting: SimplePortalSettings) {
    const response = await this.repository.updatePortalSettings(setting);
    if (response.success) {
      this.notificationService.addNotification(
        this.localization.translateGeneral("general.setting_saved"),
        SeverityLevel.success
      );
    } else {
      this.notificationService.addNotification(
        this.localization.translateGeneral("general.setting_failed"),
        SeverityLevel.critical
      );
    }
  }

  protected async loadContainer() {
    const data = await this.repository.getPortalSettings();
    if (data.success) {
      return data.payload;
    }

    throw data.payload;
  }
}
