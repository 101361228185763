import { bound } from "@frui.ts/helpers";
import { INavigatedEvent } from "@frui.ts/screens";

declare global {
  interface Window {
    dataLayer: any;
  }
  function gtag(event: string, type: string, params: any): any;
}

export default class GAService {
  initialize() {
    window.addEventListener("fruitsNavigated", this.onNavigated);
    this.logEvent("initialized", {
      // fruits uses /#/ hashes for navigation, remove the hash part to get the actual path
      page_path_fragment: window.location.hash.replace(/^#/, ""),
    });
  }

  @bound
  protected onNavigated(e: CustomEventInit<INavigatedEvent>) {
    this.logEvent("page_view", {
      page_path: e.detail?.url,
      page_title: e.detail?.screenName,
      page_path_fragment: window.location.hash.replace(/^#/, ""),
    });
  }

  @bound
  protected logEvent(event: string, params: any) {
    if (window.dataLayer) {
      gtag("event", event, params);
    }
  }
}
