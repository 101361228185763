import { ILocalizationService } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, ScreenBase, watchBusy } from "@frui.ts/screens";
import { attachAutomaticValidator, hasVisibleErrors, validate } from "@frui.ts/validation";
import UserLogin, { UserLoginValidationRules } from "entities/userLogin";
import { action, observable } from "mobx";
import SecurityService from "services/securityService";
import UserContext from "services/userContext";

export default class RefreshCredentialsViewModel extends ScreenBase {
  navigationName = "refreshCredentials";
  busyWatcher = new BusyWatcher();
  @observable credentials = new UserLogin();
  @observable errorMessage?: string;

  constructor(public localization: ILocalizationService, userContext: UserContext, private securityService: SecurityService) {
    super();

    this.name = this.translate("title");

    this.credentials.email = userContext.user?.email ?? "";
    attachAutomaticValidator(this.credentials, UserLoginValidationRules);
  }

  get canLogin() {
    return !hasVisibleErrors(this.credentials);
  }

  @bound
  @watchBusy
  async login() {
    this.setErrorMessage();
    if (validate(this.credentials)) {
      const result = await this.securityService.logIn(this.credentials.email, this.credentials.password);

      if (typeof result === "string") {
        this.setErrorMessage(result);
      } else {
        this.requestClose();
      }
    }
  }

  @bound
  @watchBusy
  logout() {
    this.securityService.logout();
  }

  @action
  setErrorMessage(message?: string) {
    this.errorMessage = message;
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`profile.refresh_credentials.${key}`);
  }
}
