import { observable } from "mobx";
import { Expose } from "class-transformer";
import ProductCardListItem from "./productCardListItem";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class ProductCard extends ProductCardListItem {
  @observable
  title!: string;

  @observable
  text!: string;

  @observable
  @Expose({ name: "button_text" })
  buttonText?: string;

  @observable
  @Expose({ name: "button_url" })
  buttonUrl?: string;

  @observable
  @Expose({ name: "if_electricity" })
  ifElectricity?: boolean;

  @observable
  @Expose({ name: "if_not_electricity" })
  ifNotElectricity?: boolean;

  @observable
  @Expose({ name: "if_gas" })
  ifGas?: boolean;

  @observable
  @Expose({ name: "if_not_gas" })
  ifNotGas?: boolean;

  @observable
  @Expose({ name: "if_household" })
  ifHousehold?: boolean;

  @observable
  @Expose({ name: "if_company" })
  ifCompany?: boolean;

  static ValidationRules = Object.assign(
    {
      title: { required: true },
      text: { required: true },
    },
    ProductCardListItem.ValidationRules
  );
}
