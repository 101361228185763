import { ILocalizationService, INotificationService, SeverityLevel } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, Router, ScreenBase, watchBusy } from "@frui.ts/screens";
import { action, observable, runInAction } from "mobx";
import EnumsService from "services/enumsService";
import UserContext from "services/userContext";
import ReqAddPartner from "entities/reqAddPartner";
import { attachAutomaticValidator, hasVisibleErrors, validate } from "@frui.ts/validation";
import PartnersRepository from "repositories/partnersRepository";
import { unwrapErrorMessage } from "repositories/helpers";
import PartnerType from "models/partnerType";

type OwnerStatus = "owner" | "not_owner";
const navigationName = "partnerConnect";

@Router.registerRoute({ name: Router.Self, route: navigationName })
export default class PartnerConnectViewModel extends ScreenBase {
  fullScreen = true;
  navigationName = navigationName;
  busyWatcher = new BusyWatcher();

  @observable errorMessage?: string;
  @observable request: ReqAddPartner;
  @observable switchNavActiveState = PartnerType.Household;
  @observable currentOwnerStatus: OwnerStatus;
  @observable fileSelectAccountError?: string;
  @observable fileSelectRegistryError?: string;
  @observable isAccountUploadValid = true;
  @observable isRegistryUploadValid = true;
  @observable accountFiles: File[] = [];
  @observable registryFiles: File[] = [];
  @observable requestSent = false;

  constructor(
    public localization: ILocalizationService,
    private enumsService: EnumsService,
    private userContext: UserContext,
    private notificationService: INotificationService,
    private partnersRepository: PartnersRepository
  ) {
    super();

    this.name = this.translate("title");
    this.request = new ReqAddPartner();
    attachAutomaticValidator(this.request, ReqAddPartner.customValidationRules);
    this.currentOwnerStatus = "owner";
  }

  @action.bound
  @watchBusy
  async connectPartner() {
    this.fileSelectAccountError = undefined;
    this.fileSelectRegistryError = undefined;

    if (this.currentOwnerStatus === "not_owner") {
      runInAction(() => (this.isAccountUploadValid = this.accountFiles.length > 0));

      if (this.switchNavActiveState === PartnerType.Business) {
        runInAction(() => (this.isRegistryUploadValid = this.registryFiles.length > 0));
      }
    }
    this.request.file = [...this.accountFiles, ...this.registryFiles];

    validate(this.request);
    if (!this.canConfirm) {
      return;
    }

    if (this.request.birthDate && this.request.birthDate >= new Date()) {
      this.errorMessage = this.localization.translateGeneral("validators.birth_date");
      return;
    }

    const response = await this.partnersRepository.addPartner(this.request);
    if (response.success) {
      this.notificationService.addNotification(this.translateGeneral("request_sent_success"), SeverityLevel.success);
      runInAction(() => (this.requestSent = true));
    } else {
      runInAction(() => (this.errorMessage = unwrapErrorMessage(response.payload)));
    }
  }

  @action.bound
  applySwitchState(state: PartnerType) {
    this.switchNavActiveState = state;
    this.clearUploaderErrors();
  }

  @action.bound
  onChangeOwnerStatus(status: OwnerStatus) {
    this.currentOwnerStatus = status;
    this.clearUploaderErrors();
  }

  @action.bound
  onAccountFilesDrop(files: File[]) {
    this.accountFiles = files;
    this.fileSelectAccountError = undefined;
    this.isAccountUploadValid = true;
  }

  @action.bound
  onRegistryFilesDrop(files: File[]) {
    this.registryFiles = files;
    this.fileSelectRegistryError = undefined;
    this.isRegistryUploadValid = true;
  }

  @bound onAccountDropRejected() {
    runInAction(() => (this.fileSelectAccountError = this.translateGeneral("upload_invalid_files")));
  }

  @bound onRegistryDropRejected() {
    runInAction(() => (this.fileSelectRegistryError = this.translateGeneral("upload_invalid_files")));
  }

  @action.bound
  clearUploaderErrors() {
    this.isAccountUploadValid = true;
    this.isRegistryUploadValid = true;
  }

  get canConfirm() {
    return !hasVisibleErrors(this.request) && this.isAccountUploadValid && this.isRegistryUploadValid;
  }

  get accountUploaderErrorMessage() {
    if (this.fileSelectAccountError) {
      return this.fileSelectAccountError;
    }
    return !this.isAccountUploadValid ? this.localization.translateGeneral("validators.required_file") : undefined;
  }

  get registryUploaderErrorMessage() {
    if (this.fileSelectRegistryError) {
      return this.fileSelectRegistryError;
    }
    return !this.isRegistryUploadValid ? this.localization.translateGeneral("validators.required_file") : undefined;
  }

  get ownerStatuses(): { id: OwnerStatus; name: string }[] {
    return [
      { id: "owner", name: this.translate("account_owner") },
      { id: "not_owner", name: this.translate("not_account_owner") },
    ];
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`partner_connect.${key}`);
  }

  @bound translateGeneral(key: string) {
    return this.localization.translateGeneral(`general.${key}`);
  }
}
