import { InputRange, InputRangeProps } from "@emanprague/ppaskit";
import { BindingProperty } from "@frui.ts/helpers";
import { IBindingProps, useBinding } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import React from "react";

export interface RangeInputProps<TTarget, TProperty extends BindingProperty<TTarget>, TItem>
  extends IBindingProps<TTarget, TProperty>,
    InputRangeProps {}

function RangeInputImpl<TTarget, TProperty extends BindingProperty<TTarget>, TItem>(
  props: RangeInputProps<TTarget, TProperty, TItem>
) {
  const { target, property, onValueChanged, ...otherProps } = props;
  const [value, setValue] = useBinding(props);

  return <InputRange {...otherProps} range={value} onChange={setValue} />;
}

const Rangeinput = observer(RangeInputImpl as any) as typeof RangeInputImpl;
export default Rangeinput;
