import { block } from "bem-cn";
import React from "react";
import DatePickerControl, { ReactDatePickerProps } from "react-datepicker";
export { getDefaultLocale, registerLocale, setDefaultLocale } from "react-datepicker";

export interface DatePickerProps extends ReactDatePickerProps {
  footer?: React.ReactNode;
  noBorder?: boolean;
  isInvalid?: boolean;
}

const controlStyle = block("form-control");

const DatePicker: React.FunctionComponent<DatePickerProps> = ({ calendarClassName, noBorder, footer, isInvalid, ...props }) => {
  if (noBorder) {
    calendarClassName = calendarClassName ? `${calendarClassName} no-border` : "no-border";
  }

  const className = controlStyle.mix(props.className, isInvalid && "is-invalid");

  return (
    <DatePickerControl {...props} className={className.toString()} calendarClassName={calendarClassName}>
      {footer && <div className="react-datepicker__footer">{footer}</div>}
    </DatePickerControl>
  );
};

DatePicker.defaultProps = {
  dateFormat: ["PP", "P"],
  showPopperArrow: false,
  popperPlacement: "bottom",
  popperModifiers: {
    flip: { enabled: false },
  },
} as Partial<DatePickerProps>;

export default DatePicker;
