import { Form, FormControlProps, InputWithTooltip } from "@emanprague/ppaskit";
import { ValidationControlBase } from "@frui.ts/bootstrap";
import { InputProps } from "@frui.ts/bootstrap/dist/controls/input";
import { bound } from "@frui.ts/helpers";
import React from "react";

export interface InfoInputProps extends InputProps {
  tooltip: React.ReactNode;
}

export default class InfoInput<TTarget, TOtherProps = unknown> extends ValidationControlBase<
  TTarget,
  InfoInputProps & FormControlProps & TOtherProps
> {
  @bound
  protected renderInner() {
    const { noValidation, errorMessage, tooltip, ...otherProps } = this.inheritedProps;
    const validationError = this.getValidationError();

    const tooltipId = otherProps.id ? `${otherProps.id}-tooltip` : `${this.props.property}-tooltip`;

    return (
      <>
        <InputWithTooltip id={tooltipId} tooltip={tooltip}>
          <Form.Control
            {...otherProps}
            value={this.value === undefined || this.value === null ? "" : this.value}
            onChange={this.handleValueChanged}
            isInvalid={!!validationError}
          />
        </InputWithTooltip>
        {validationError && <Form.Control.Feedback type="invalid">{validationError}</Form.Control.Feedback>}
      </>
    );
  }

  @bound
  protected handleValueChanged(e: React.FormEvent<any>) {
    const target = e.target as HTMLInputElement;
    if (this.props.type === "number") {
      this.setNumber(target.value);
    } else {
      this.setValue(target.value);
    }
  }

  private setNumber(value: string) {
    if (value) {
      this.setValue(+value);
    } else {
      this.setValue(value === "" ? undefined : value);
    }
  }
}
