import { Expose, Transform } from "class-transformer";
import { observable } from "mobx";
import formatISO from "date-fns/formatISO";

export default class AttachmentsFilter {
  @observable
  @Expose({ name: "q" })
  search?: string;

  @observable
  @Expose({ name: "filters[file_name]" })
  fileName?: string;

  @observable
  @Expose({ name: "filters[title]" })
  title?: string;

  @observable
  @Expose({ name: "filters[description]" })
  description?: string;

  @observable
  @Expose({ name: "filters[file_size_from]" })
  fileSizeFrom?: number;

  @observable
  @Expose({ name: "filters[file_size_to]" })
  fileSizeTo?: number;

  @observable
  @Expose({ name: "filters[created_at_from]" })
  @Transform(value => value && formatISO(value, { representation: "date" }))
  createdAtFrom?: Date;

  @observable
  @Expose({ name: "filters[created_at_to]" })
  @Transform(value => value && formatISO(value, { representation: "date" }))
  createdAtTo?: Date;
}
