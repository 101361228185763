import { Expose } from "class-transformer";
import { observable } from "mobx";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
class ReqChangeInvoicePayment {
  @observable
  @Expose({ name: "supply_point_ids" })
  supplyPointIds!: number[];

  /**
   * Enumeration::PaymentMethod
   */
  @observable
  @Expose({ name: "overpayment_method_id" })
  overpaymentMethodId?: number;

  /**
   * Bank account prefix
   */
  @observable
  @Expose({ name: "overpayment_account_prefix" })
  overpaymentAccountPrefix?: number;

  /**
   * Bank account or SIPO
   */
  @observable
  @Expose({ name: "overpayment_account" })
  overpaymentAccount?: number;

  /**
   * From enumeration::bank
   */
  @observable
  @Expose({ name: "overpayment_account_bank_id" })
  overpaymentAccountBankId?: number;

  /**
   * Enumeration::PaymentMethod
   */
  @observable
  @Expose({ name: "underpayment_method_id" })
  underpaymentMethodId?: number;

  /**
   * Bank account prefix
   */
  @observable
  @Expose({ name: "underpayment_account_prefix" })
  underpaymentAccountPrefix?: number;

  /**
   * Bank account or SIPO
   */
  @observable
  @Expose({ name: "underpayment_account" })
  underpaymentAccount?: number;

  /**
   * From enumeration::bank
   */
  @observable
  @Expose({ name: "underpayment_account_bank_id" })
  underpaymentAccountBankId?: number;

  static ValidationRules = {
    supplyPointIds: { required: true },
    overpaymentMethodId: { number: true },
    overpaymentAccountPrefix: { number: true },
    overpaymentAccount: { number: true },
    overpaymentAccountBankId: { number: true },
    underpaymentMethodId: { number: true },
    underpaymentAccountPrefix: { number: true },
    underpaymentAccount: { number: true },
    underpaymentAccountBankId: { number: true },
  };
}

export default class CustomReqChangeInvoicePayment extends ReqChangeInvoicePayment {
  static getTranslatedErrors(errors: any): any {
    const result: CustomReqChangeInvoicePayment = new this();
    result.underpaymentAccountPrefix = errors.underpayment_account_prefix;
    result.underpaymentAccount = errors.underpayment_account;
    result.overpaymentAccountPrefix = errors.overpayment_account_prefix;
    result.overpaymentAccount = errors.overpayment_account;
    return result;
  }
}
