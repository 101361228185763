import { ILocalizationService, INotificationService, SeverityLevel } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, ScreenBase, watchBusy } from "@frui.ts/screens";
import { action, computed, observable, runInAction } from "mobx";
import { attachAutomaticValidator, hasVisibleErrors, validate } from "@frui.ts/validation";
import { interfaces } from "inversify";
import AddressChangeViewModel from "viewModels/addressChangeViewModel";
import Address from "entities/address";
import SupplyPointsRepository from "repositories/supplyPointsRepository";
import { createSupplyPointsWithIcons, SupplyPointError } from "helpers/supplyPointHelper";
import DataSyncService from "services/dataSyncService";
import EnumsService from "services/enumsService";
import { unwrapErrorMessage } from "repositories/helpers";
import ReqChangePostalAddress, { ReqChangePostalAddressValidationRules } from "entities/reqChangePostalAddress";
import SupplyPoint from "entities/supplyPoint";
import SupplyPointListItem from "entities/supplyPointListItem";

export default class ModalEditMailingAddressViewModel extends ScreenBase {
  static navigationName = "mailing";
  busyWatcher = new BusyWatcher();
  navigationName = ModalEditMailingAddressViewModel.navigationName;

  @observable data: ReqChangePostalAddress;
  @observable successMessage?: string;
  @observable errorMessage?: string;
  @observable addressChangeVM: AddressChangeViewModel;
  @observable supplyPointsErrors: SupplyPointError[] = [];

  constructor(
    private partnerId: number,
    address: Address,
    private supplyPoint: SupplyPoint | SupplyPointListItem,
    includePostalAddresses: boolean,
    public localization: ILocalizationService,
    addressChangeViewModelFactory: ReturnType<typeof AddressChangeViewModel.Factory>,
    private enumsService: EnumsService,
    private dataService: DataSyncService,
    private supplyPointRepository: SupplyPointsRepository,
    private notificationService: INotificationService
  ) {
    super();

    this.name = this.translate("change_postal_address");
    this.data = new ReqChangePostalAddress();
    this.data.supplyPointIds = this.currentSupplyPoints.map(i => i.id);
    attachAutomaticValidator(this.data, ReqChangePostalAddressValidationRules);

    this.addressChangeVM = addressChangeViewModelFactory(address, this.data, supplyPoint, "normal");
  }

  get canConfirm() {
    return !hasVisibleErrors(this.data);
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`products.customer_info.${key}`);
  }

  @computed
  get currentSupplyPoints() {
    return this.dataService.supplyPoints.filter(item => this.supplyPoint.accountId === item.accountId);
  }

  @computed
  get currentSupplyPointsWithIcons() {
    return createSupplyPointsWithIcons(this.enumsService, this.currentSupplyPoints, "grey", this.supplyPointsErrors);
  }

  @computed
  get additionalSupplyPointsWithIcons() {
    return createSupplyPointsWithIcons(
      this.enumsService,
      this.dataService.supplyPoints.filter(item => !this.currentSupplyPoints.some(selectedItem => selectedItem.id === item.id)),
      "grey",
      this.supplyPointsErrors
    );
  }

  @action.bound
  @watchBusy
  async confirmChange() {
    this.errorMessage = undefined;
    this.supplyPointsErrors = [];

    if (!validate(this.data)) {
      return;
    }

    const response = await this.supplyPointRepository.changePostalAddress(this.partnerId, this.data);

    if (response.success) {
      runInAction(() => (this.supplyPointsErrors = response.payload.supplyPoints.filter(item => !item.status)));
      if (this.supplyPointsErrors.length === 0) {
        this.notificationService.addNotification(
          this.localization.translateGeneral("general.request_sent_success"),
          SeverityLevel.success
        );
        this.requestClose();
      }
    } else {
      runInAction(() => (this.errorMessage = unwrapErrorMessage(response.payload)));
    }
  }

  static Factory({ container }: interfaces.Context) {
    return (
      partnerId: number,
      address: Address,
      supplyPoint: SupplyPoint | SupplyPointListItem,
      includePostalAddresses = false
    ) => {
      return new ModalEditMailingAddressViewModel(
        partnerId,
        address,
        supplyPoint,
        includePostalAddresses,
        container.get("ILocalizationService"),
        container.get(AddressChangeViewModel.Factory),
        container.get(EnumsService),
        container.get(DataSyncService),
        container.get(SupplyPointsRepository),
        container.get("INotificationService")
      );
    };
  }
}
