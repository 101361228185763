import * as sharedServices from "@emanprague/shared-services";
import { bound, IDisposable } from "@frui.ts/helpers";
import { Router, ScreenBase } from "@frui.ts/screens";
import ConfirmUpdateLoginEmail from "entities/confirmUpdateLoginEmail";
import PartnerListItem from "entities/partnerListItem";
import { partnerToFullName } from "helpers/utils";
import { action, computed, observable, runInAction } from "mobx";
import { unwrapErrorMessage } from "repositories/helpers";
import LoginRepository from "repositories/loginRepository";
import EnumsService from "services/enumsService";
import { AppendixesEvents, GeneralEvents } from "services/events";
import SecurityService from "services/securityService";
import DashboardOverviewViewModel from "./dashboard/overviewViewModel";
import PartnerConfirmViewModel from "./dashboard/partnerConfirmViewModel";
import PartnerRemoveViewModel from "./dashboard/partnerRemoveViewModel";
import FinanceOverviewViewModel from "./finance/overviewViewModel";
import ProductsOverviewViewModel from "./products/overviewViewModel";
import RefreshCredentialsViewModel from "./profile/refreshCredentialsViewModel";
import ProfileStatusViewModel from "./profile/statusViewModel";
import UpdatePasswordViewModel from "./profile/updatePasswordViewModel";
import RequestsOverviewViewModel from "./requests/overviewViewModel";
import MessagesOverviewViewModel from "./messages/overviewViewModel";
import RootViewModelBase from "./rootViewModelBase";
import ToastViewModel from "./toastViewModel";
import { IModule } from "./types";
import ConfirmationService from "services/confirmationService";
import MessagesService from "services/messagesService";
import UserContext from "services/userContext";
import CompetitionsViewModel from "./profile/competitionsViewModel";
import SupplyPointsOverviewViewModel from "./supplyPoints/overviewViewModel";
import AppendixesPageViewModel from "./products/appendixesPageViewModel";
import AppendixesService from "services/appendixesService";

@Router.registerRoute({
  route: "",
  children: [
    ProductsOverviewViewModel,
    DashboardOverviewViewModel,
    FinanceOverviewViewModel,
    RequestsOverviewViewModel,
    MessagesOverviewViewModel,
    SupplyPointsOverviewViewModel,
  ],
})
export default class RootViewModel extends RootViewModelBase<IModule, ScreenBase> {
  data: ConfirmUpdateLoginEmail;
  @observable errorMessage?: string;

  private eventHandlers: IDisposable[];

  constructor(
    modules: IModule[],
    private notificationService: sharedServices.INotificationService,
    public router: Router,
    public profile: ProfileStatusViewModel,
    private updatePasswordFactory: () => UpdatePasswordViewModel,
    private newAppendixesVM: AppendixesPageViewModel,
    private competitionsVM: CompetitionsViewModel,
    private refreshCredentialsFactory: () => RefreshCredentialsViewModel,
    private loginRepository: LoginRepository,
    public localization: sharedServices.ILocalizationService,
    private partnerRemoveFactory: () => PartnerRemoveViewModel,
    private partnerConfirmFactory: () => PartnerConfirmViewModel,
    private securityService: SecurityService,
    eventBus: sharedServices.IEventBus,
    private enumsService: EnumsService,
    public confirmationService: ConfirmationService,
    public messagesService: MessagesService,
    public appendixesService: AppendixesService,
    public userContext: UserContext
  ) {
    super();
    this.data = new ConfirmUpdateLoginEmail();
    this.setModules(modules);

    this.eventHandlers = [
      eventBus.subscribe(GeneralEvents.unauthorized, this.activateRefreshCredentials),
      eventBus.subscribe(AppendixesEvents.newAppendixesLoaded, this.verifyNewAppendixes),
    ];

    if (this.userContext.user?.forcePasswordChange) {
      this.activateUpdatePasswordModal();
    }
  }

  @action
  public setModules(modules: IModule[]) {
    modules.sort((a, b) => a.orderIndex - b.orderIndex);
    this.children.push(...modules);
  }

  protected onInitialize() {
    if (!this.activeChild && this.children.length) {
      return this.tryActivateChild(this.children[0]);
    }
  }

  protected async onDeactivate(isClosing: boolean) {
    await super.onDeactivate(isClosing);

    if (isClosing) {
      this.eventHandlers?.forEach(x => x.dispose());
    }
  }

  @bound
  async verifyNewAppendixesAndCompetitions(subPath: string | undefined) {
    if (!this.verifyNewAppendixes()) {
      await this.verifyCompetitions(subPath);
    }
  }

  @bound
  verifyNewAppendixes() {
    if (this.newAppendixesVM.modalShown || !this.newAppendixesVM.hasNewAppendixes) {
      return false;
    }

    this.appendViewModel(this.newAppendixesVM);
    runInAction(() => {
      this.newAppendixesVM.modalShown = true;
    });
    return true;
  }

  @bound
  async verifyCompetitions(subPath: string | undefined) {
    if (!(subPath === DashboardOverviewViewModel.navigationName && !this.competitionsVM.wasShowed)) {
      return;
    }

    const hasCompetitions = await this.competitionsVM.checkCompetitions();
    if (hasCompetitions) {
      this.appendViewModel(this.competitionsVM);
      runInAction(() => {
        this.competitionsVM.wasShowed = true;
      });
    }
  }

  @bound
  changeActivePartner(partnerId: number) {
    return this.securityService.changeActivePartner(partnerId);
  }

  @bound
  activateUpdatePasswordModal() {
    this.appendViewModel(this.updatePasswordFactory());
  }

  @bound
  activatePartnerRemove() {
    this.appendViewModel(this.partnerRemoveFactory());
  }

  @bound
  activateRefreshCredentials() {
    if (!this.appendedViewModels.some(x => x instanceof RefreshCredentialsViewModel)) {
      this.appendViewModel(this.refreshCredentialsFactory());
    }
  }

  @action navigate(subPath: string | undefined, params: any) {
    if (!this.userContext.user?.forcePasswordChange) {
      this.verifyNewAppendixesAndCompetitions(subPath);
    }

    if (params?.token && subPath?.startsWith("confirmEmail")) {
      this.data.token = params.token;
      return this.confirmEmailUpdate();
    } else if (params?.token && subPath?.startsWith("confirmPartner")) {
      return this.activatePartnerConfirm(params.token);
    } else {
      return super.navigate(subPath, params);
    }
  }

  async activatePartnerConfirm(token: string) {
    const instance = this.partnerConfirmFactory();
    this.appendViewModel(instance);
    return instance.confirmPartner(token);
  }

  @action.bound
  async confirmEmailUpdate() {
    const response = await this.loginRepository.updateEmailConfirm(this.data);

    if (response.success) {
      this.notificationService.addNotification(
        this.localization.translateGeneral("profile.registration.email_confirmed"),
        sharedServices.SeverityLevel.success
      );
      this.loginRepository.currentUser();
    } else {
      runInAction(() => (this.errorMessage = unwrapErrorMessage(response.payload)));
    }
  }

  getPartnerFullName(partner?: PartnerListItem) {
    return partnerToFullName(this.enumsService, partner);
  }

  @computed
  get messagePreviews() {
    return this.messagesService.messagePreviews;
  }

  get unreadMessagesCount() {
    return this.messagesService.unreadMessagesCount;
  }

  @computed
  get newAppendixesCount() {
    return this.appendixesService.newAppendixesCount;
  }

  get toasts() {
    const results: ToastViewModel[] = [];

    const service = this.notificationService;
    service.notifications.forEach((notification, key) =>
      results.push(new ToastViewModel(key, notification.message, notification.severity, () => service.removeNotification(key)))
    );

    return results;
  }

  get numberOfConnectedAccounts() {
    return this.profile.connectedPartners.length;
  }

  get currentPartner() {
    return this.profile.partners.find(item => item.id === this.profile.activePartnerId) ?? new PartnerListItem();
  }

  @computed
  get activePartnerFullName() {
    return this.getPartnerFullName(this.profile.activePartner);
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`menu.${key}`);
  }

  @bound translateGeneral(key: string) {
    return this.localization.translateGeneral(`general.${key}`);
  }
}
