import { registerView, ViewComponent, preventDefault, View } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import React from "react";
import UpdateEmailViewModel from "viewModels/profile/updateEmailViewModel";
import { Modal, Button, HeaderRow, LoaderOverlay } from "@emanprague/ppaskit";
import { Form, Col } from "react-bootstrap";
import { Input } from "@frui.ts/bootstrap";

const updateEmailListView: ViewComponent<UpdateEmailViewModel> = observer(({ vm }) => (
  <>
    <View vm={vm} context={vm.currentStep} />
  </>
));

registerView(updateEmailListView, UpdateEmailViewModel);

const step1: ViewComponent<UpdateEmailViewModel> = observer(({ vm }) => {
  const tg = vm.localization.translateGeneral;
  return (
    <>
      <Modal centered show onHide={vm.requestClose}>
        <LoaderOverlay loading={vm.busyWatcher.isBusy}>
          <Modal.Header closeButton>
            <HeaderRow title={vm.translate("change_email")} level={4} />
          </Modal.Header>
          <Modal.Body>
            <Form className="w-100 w-sm-75 mx-auto mb-n3" onSubmit={preventDefault(vm.changeEmail)}>
              <Form.Row>
                <Form.Group as={Col} xs={12}>
                  <Form.Label>{vm.translate("current_email")}</Form.Label>
                  <Input target={vm.data} property="emailCurrent" />
                </Form.Group>
                <Form.Group as={Col} xs={12}>
                  <Form.Label>{vm.translate("new_email")}</Form.Label>
                  <Input target={vm.data} property="email" />
                </Form.Group>
                <Form.Group as={Col} xs={12}>
                  <Form.Label>{vm.translate("new_email_confirmation")}</Form.Label>
                  <Input target={vm.data} property="emailConfirmation" />
                </Form.Group>
                {vm.errorMessage && <Form.Control.Feedback type="invalid">{vm.errorMessage}</Form.Control.Feedback>}
              </Form.Row>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button disabled={!vm.canConfirm} variant="primary" className="order-2" onClick={vm.changeEmail}>
              {tg("general.confirm_changes_button")}
            </Button>
            <Button onClick={vm.requestClose} variant="secondary" className="order-1">
              {tg("general.cancel_button")}
            </Button>
          </Modal.Footer>
        </LoaderOverlay>
      </Modal>
    </>
  );
});

registerView(step1, UpdateEmailViewModel, "step1");

const step2: ViewComponent<UpdateEmailViewModel> = observer(({ vm }) => {
  const tg = vm.localization.translateGeneral;
  return (
    <>
      <Modal centered show onHide={vm.requestClose}>
        <Modal.Header closeButton>
          <HeaderRow title={vm.translate("change_email")} level={4} />
        </Modal.Header>
        <Modal.Body>
          <Form className="w-100 w-sm-75 mx-auto mb-n3" onSubmit={preventDefault(vm.changeEmail)}>
            <Form.Row>
              <Form.Group as={Col} xs={12}>
                <p dangerouslySetInnerHTML={{ __html: vm.successMessage ?? "" }} />
              </Form.Group>
              {vm.errorMessage && <Form.Control.Feedback type="invalid">{vm.errorMessage}</Form.Control.Feedback>}
            </Form.Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={vm.requestClose}>
            {tg("general.close_button")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
});

registerView(step2, UpdateEmailViewModel, "step2");
