import { observable } from "mobx";
import { EntityValidationRules } from "services/validation/entityValidationRules";
import { Expose } from "class-transformer";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class UpdateResetPassword {
  /**
   * @example
   * Moo8yi3gei
   */
  @observable
  token!: string;

  /**
   * @example
   * Moo8yi3gei
   */
  @observable
  password!: string;

  static ValidationRules = {
    token: { required: true },
    password: { required: true },
  };
}

export class ExtendUpdateResetPassword extends UpdateResetPassword {
  @observable
  @Expose({ name: "password_confirmation" })
  passwordConfirmation?: string;
}

const UpdateResetPasswordValidationRules: EntityValidationRules<ExtendUpdateResetPassword> = {
  password: { required: true },
  passwordConfirmation: {
    required: true,
    equalWith: { parameter: "password", translationCode: "validators.password_confirmation_mismatch" },
  },
  token: { required: true },
};
export { UpdateResetPasswordValidationRules };
