import React from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { styleBlock } from "..";

export type StepperRequestStep = {
  title: string;
  note?: string;
};

interface StepperRequestProps {
  stepCompleted: number;
  steps: StepperRequestStep[];
  className?: string;
}

export default class StepperRequest extends React.PureComponent<StepperRequestProps> {
  render() {
    const { stepCompleted, steps, className } = this.props;

    const classNames = styleBlock("stepperrequest");
    const cn = classNames({}).mix([className]);
    const hasAnyNotes = steps.find(item => item.note);

    return (
      <div className={cn.toString()}>
        <Row noGutters className="justify-content-start justify-content-md-center flex-md-nowrap">
          {steps.map((curstep, index) => {
            const isStepPassed = stepCompleted >= index + 1;
            const isLastPassedStep = stepCompleted === index + 1;
            const isFirstIncompleteStep = stepCompleted === index && index !== 0;
            return (
              <Col
                xs="auto"
                key={`step-${index}`}
                className={"step" + (isStepPassed ? " step-passed" : "") + (hasAnyNotes ? " mt-md-3" : "")}>
                <span className="d-flex align-items-center">
                  {isFirstIncompleteStep && <span className="first-incomplete-step" />}
                  <span className={"step-circle" + (isLastPassedStep ? " last-passed-step" : "")}>
                    <span className="step-value">{index + 1}</span>
                  </span>
                </span>
                <span className="step-name">{curstep.title}</span>
                <span className="step-note text-muted">{curstep.note}</span>
              </Col>
            );
          })}
        </Row>
      </div>
    );
  }
}
