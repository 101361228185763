import { ILocalizationService } from "@emanprague/shared-services";
import { BusyWatcher, ConductorSingleChild, Router, ScreenBase } from "@frui.ts/screens";
import ModalEditContactInfoViewModel from "viewModels/products/myProducts/detailPages/customerInfo/modalEditContactInfoViewModel";
import ModalEditMarketingAgreementViewModel from "viewModels/products/myProducts/detailPages/customerInfo/modalEditMarketingAgreementViewModel";
import ModalEditMailingAddressViewModel from "viewModels/products/myProducts/detailPages/customerInfo/modalEditMailingAddressViewModel";
import UserContext from "services/userContext";
import { bound } from "@frui.ts/helpers";
import UpdateEmailViewModel from "viewModels/profile/updateEmailViewModel";
import ModalEditCustomerInfoViewModel from "../products/myProducts/detailPages/customerInfo/modalEditCustomerInfoViewModel";
import DataSyncService from "services/dataSyncService";
import Address from "entities/address";
import EnumsService from "services/enumsService";
import { computed } from "mobx";
import PartnerType from "models/partnerType";
import { partnerToFullName } from "helpers/utils";
import SupplyPointListItem from "entities/supplyPointListItem";

interface AddressWithSupplyPoint extends Address {
  supplyPoint: SupplyPointListItem;
}

const navigationName = "account";
@Router.registerRoute({ name: "customerAccount", route: navigationName })
export default class CustomerAccountViewModel extends ConductorSingleChild<ScreenBase> {
  busyWatcher = new BusyWatcher();
  navigationName = navigationName;

  constructor(
    public localization: ILocalizationService,
    public userContext: UserContext,
    public dataSync: DataSyncService,
    public enumsService: EnumsService,
    private modalEditCustomerInfoViewModelFactory: ReturnType<typeof ModalEditCustomerInfoViewModel.Factory>,
    private modalEditContactInfoViewModelFactory: ReturnType<typeof ModalEditContactInfoViewModel.Factory>,
    private modalEditMarketingAgreementViewModelFactory: ReturnType<typeof ModalEditMarketingAgreementViewModel.Factory>,
    private modalEditMailingAddressViewModelFactory: ReturnType<typeof ModalEditMailingAddressViewModel.Factory>,
    public updateEmailFactory: () => UpdateEmailViewModel
  ) {
    super();
  }

  get user() {
    return this.userContext.user;
  }

  get partnerId() {
    return this.userContext.activePartnerId;
  }

  get partner() {
    return this.userContext.activePartner;
  }

  get activePartnerFullName() {
    return partnerToFullName(this.enumsService, this.userContext.activePartner);
  }

  get supplyPoints() {
    return this.dataSync.supplyPoints;
  }

  @computed
  get isCompany() {
    if (!this.partner) {
      return false;
    }
    return this.enumsService.getPartnerType(this.partner.partnerTypeId) === PartnerType.Business;
  }

  @computed get postalAddresses(): AddressWithSupplyPoint[] {
    return this.supplyPoints
      .filter(i => i.postalAddress)
      .map(i => ({
        supplyPoint: i,
        ...i.postalAddress!,
      }));
  }

  @bound
  activateEditCustomerInfoModal() {
    if (this.partnerId) {
      return this.tryActivateChild(this.modalEditCustomerInfoViewModelFactory(this.partnerId));
    }
  }

  @bound
  activateEditContactInfoModal() {
    if (this.partnerId) {
      return this.tryActivateChild(this.modalEditContactInfoViewModelFactory(this.partnerId));
    }
  }

  @bound
  activateEditMarketingAgreementModal() {
    if (this.partnerId) {
      return this.tryActivateChild(this.modalEditMarketingAgreementViewModelFactory(this.partnerId));
    }
  }

  @bound
  activateEditMailingAddressModal(item: AddressWithSupplyPoint) {
    if (this.partnerId) {
      // TODO Probably to split this?
      return this.tryActivateChild(this.modalEditMailingAddressViewModelFactory(this.partnerId, item, item.supplyPoint, true));
    }
  }

  @bound activateUpdateEmailModal() {
    return this.tryActivateChild(this.updateEmailFactory());
  }
  protected findNavigationChild(navigationName: string | undefined) {
    return this.activeChild;
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`products.customer_info.${key}`);
  }

  @bound translateGeneral(key: string) {
    return this.localization.translateGeneral(`general.${key}`);
  }
}
