import { observable } from "mobx";
import { Expose } from "class-transformer";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class ReqChangeAdvancePayment {
  @observable
  @Expose({ name: "supply_point_ids" })
  supplyPointIds!: number[];

  /**
   * Enumeartion::PaymentMethod
   */
  @observable
  @Expose({ name: "advance_method_id" })
  advanceMethodId?: number;

  /**
   * Prefix bank account
   */
  @observable
  @Expose({ name: "advance_account_prefix" })
  advanceAccountPrefix?: number;

  /**
   * Bank account or SIPO
   */
  @observable
  @Expose({ name: "advance_account" })
  advanceAccount?: number;

  /**
   * From enumeration::bank
   */
  @observable
  @Expose({ name: "advance_account_bank_id" })
  advanceAccountBankId?: number;

  static ValidationRules = {
    supplyPointIds: { required: true },
    advanceMethodId: { number: true },
    advanceAccountPrefix: { number: true },
    advanceAccount: { number: true },
    advanceAccountBankId: { number: true },
  };
}
