import React from "react";

export interface ColoredLabelProps {
  label: string;
  isBlue?: boolean;
  isRed?: boolean;
}

const ColoredLabel: React.FC<ColoredLabelProps> = ({ label, isBlue = false, isRed = false }) => {
  const style = { color: "black", fontWeight: 400 };
  if (isBlue) {
    style.color = "#00264C";
    style.fontWeight = 600;
  } else if (isRed) {
    style.color = "#DA2022";
    style.fontWeight = 600;
  }
  return <span style={style}>{label}</span>;
};

export default ColoredLabel;
