import { Expose } from "class-transformer";
import { observable } from "mobx";

export default class AuditLogsFilter {
  @observable
  @Expose({ name: "q" })
  search?: string;

  @observable
  @Expose({ name: "filters[partner_external_id]" })
  partnerExternalId?: number;

  @observable
  @Expose({ name: "filters[status]" })
  status?: string[];

  @observable
  @Expose({ name: "filters[message]" })
  message?: string;
}
