import { observable } from "mobx";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class Link {
  @observable
  text!: string;

  @observable
  url!: string;

  static ValidationRules = {
    text: { requiredIf: { condition: (entity: Link) => !!entity.url } },
    url: { requiredIf: { condition: (entity: Link) => !!entity.text }, isURL: true },
  };
}
