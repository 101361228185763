import { ILocalizationService } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, ConductorSingleChild, watchBusy } from "@frui.ts/screens";
import addMonths from "date-fns/addMonths";
import Advance from "entities/advance";
import AdvanceListItem from "entities/advanceListItem";
import PaymentGateway from "entities/paymentGateway";
import { createSupplyPointsWithIcons } from "helpers/supplyPointHelper";
import { interfaces } from "inversify";
import { computed, observable, runInAction } from "mobx";
import AdvancesRepository from "repositories/advancesRepository";
import EnumsService from "services/enumsService";
import OnlinePaymentViewModel from "./onlinePaymentViewModel";

export default class AdvanceDetailViewModel extends ConductorSingleChild<OnlinePaymentViewModel> {
  navigationName = "detail";
  busyWatcher = new BusyWatcher();
  paymentGateway: PaymentGateway;

  @observable advance: Advance;
  @observable errorMessage?: string;
  @observable qrCode: string;

  constructor(
    public partnerId: number,
    public item: AdvanceListItem,
    public activateAdvanceChange: (partnerId: number, supplyPointId: number) => void,
    public localization: ILocalizationService,
    private advancesRepository: AdvancesRepository,
    public enumsService: EnumsService,
    private onlinePaymentFactory: ReturnType<typeof OnlinePaymentViewModel.Factory>
  ) {
    super();

    this.navigationName = String(item.id);
  }

  @bound
  onInitialize() {
    this.loadData();
  }

  @bound
  @watchBusy
  async loadData() {
    if (!this.partnerId || !this.item.id) {
      return;
    }
    const result = await this.advancesRepository.getAdvanceDetail(this.partnerId, this.item.id);
    if (result.success) {
      runInAction(() => {
        this.advance = result.payload;
        this.qrCode = this.advancesRepository.getQrCodeUrl(this.partnerId, this.item.id);
      });
    }
  }

  @bound
  handlePayOnlineClicked() {
    return undefined;
  }

  @bound
  activateOnlinePayment() {
    return this.tryActivateChild(this.onlinePaymentFactory(this.partnerId, this.item));
  }

  get hasCharges() {
    return this.advance.charges && this.advance.charges.length > 0;
  }

  get supplyPoints() {
    return this.advance?.supplyPoints ? createSupplyPointsWithIcons(this.enumsService, this.advance.supplyPoints) : [];
  }

  get amount() {
    return this.localization.formatNumber(this.advance.amount);
  }

  get nextPayment() {
    return this.localization.formatDate(addMonths(this.advance.dueDate, 1));
  }

  get amountToPay() {
    return this.advance.amountPaid ? this.advance.amount - this.advance.amountPaid : this.advance.amount;
  }

  get areRemindersVisible() {
    if (this.advance) {
      return this.advance.reminders?.length > 0;
    }
    return false;
  }

  @computed
  get frequencies() {
    return this.enumsService.getValues("advanceFrequencies").filter(x => x.active);
  }

  @computed
  get changeReasons() {
    return this.enumsService.getValues("advanceChangeReasons").filter(x => x.active);
  }

  get firstSupplyPointId() {
    return this.advance.supplyPoints && this.advance.supplyPoints.length > 0 ? this.advance.supplyPoints[0].id : 0;
  }

  get isSingleSupplyPoint() {
    return !(this.advance?.supplyPoints && this.advance.supplyPoints.length > 1);
  }

  get headerTitle() {
    return this.isSingleSupplyPoint ? this.translate("title") : this.translate("title_common");
  }

  get supplyPointsTitle() {
    return this.localization.translateModel("supply_point", this.advance.supplyPoints?.length);
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`finance.advance_detail.${key}`);
  }

  @bound translateGeneral(key: string) {
    return this.localization.translateGeneral(`general.${key}`);
  }

  static Factory({ container }: interfaces.Context) {
    return (
      item: AdvanceListItem,
      partnerId: number,
      activateAdvanceChange: (partnerId: number, supplyPointId: number) => void
    ) => {
      return new AdvanceDetailViewModel(
        partnerId,
        item,
        activateAdvanceChange,
        container.get("ILocalizationService"),
        container.get(AdvancesRepository),
        container.get(EnumsService),
        container.get(OnlinePaymentViewModel.Factory)
      );
    };
  }

  protected findNavigationChild(navigationName: string | undefined) {
    return undefined;
  }
}
