import { Button } from "..";
import Dropzone from "react-dropzone";
import React, { useState } from "react";
import iconUpload from "../assets/icons/Upload.svg";
import iconAttachment from "../assets/icons/Attachment.svg";
import iconClose from "../assets/icons/Close.svg";
import { styleBlock } from "..";

export interface UploaderLocalization {
  button: string;
  or: string;
  types: string;
}

export interface UploaderProps {
  className?: string;
  onDrop: (files: File[]) => void;
  onDropRejected?: () => void;
  localization: UploaderLocalization;
  error?: string;
  accept?: string | string[];
  minSize?: number;
  maxSize?: number;
  maxNumberOfFiles?: number;
  maxNumberOfFilesError?: string;
}

const Uploader = (props: UploaderProps) => {
  const classNames = styleBlock("uploader");

  const uploaderClass = classNames.mix([props.className, props.error && "is-invalid border-danger border"]);
  const filesClass = styleBlock("selected-files").mix(props.className);

  const [files, setFiles] = useState<File[]>([]);
  const [numberLimitError, setNumberLimitError] = useState<string | undefined>();

  return (
    <Dropzone
      onDrop={(newFiles: File[]) => {
        setNumberLimitError(undefined);
        const allFiles = [...files, ...newFiles];

        if (props.maxNumberOfFiles && allFiles.length > props.maxNumberOfFiles) {
          setFiles(allFiles.slice(0, props.maxNumberOfFiles));
          props.onDrop(allFiles.slice(0, props.maxNumberOfFiles));

          if (props.maxNumberOfFilesError) {
            setNumberLimitError(props.maxNumberOfFilesError);
          }
        } else {
          setFiles(allFiles);
          props.onDrop(allFiles);
        }
      }}
      accept={props.accept}
      maxSize={props.maxSize}
      minSize={props.minSize}
      onDropRejected={props.onDropRejected}>
      {({ getRootProps, getInputProps }) => {
        const removeFile = (file: File) => {
          const newFiles = files.filter(x => x !== file);
          setFiles(newFiles);
          props.onDrop(newFiles);
        };

        return (
          <>
            <section className={uploaderClass}>
              <div {...getRootProps()} style={{ width: "100%" }}>
                <input {...getInputProps()} />
                <Button variant="secondary" className="mt-4 mb-3">
                  <img width={16} height={16} src={iconUpload} className="ml-n2 mr-2 my-n1" alt="" />
                  {props.localization.button}
                </Button>
                <p className="mb-1">{props.localization.or}</p>
                <p className="mb-4 text-muted">{props.localization.types}</p>
              </div>
            </section>

            <section style={{ width: "100%" }} className={filesClass}>
              {files.map((file, index) => (
                <div key={index} className="file-row">
                  <div className="file-name-wrapper">
                    <img width={18} height={18} src={iconAttachment} className="ml-3 mr-2" alt="" />
                    <div className="file-name">{file.name}</div>
                  </div>

                  <div className="ml-2 mr-3 file-remove" onClick={() => removeFile(file)}>
                    <img width={16} height={16} src={iconClose} alt="" />
                  </div>
                </div>
              ))}
            </section>
            {props.error && <span className="invalid-feedback">{props.error}</span>}
            {numberLimitError && <span className="invalid-feedback">{numberLimitError}</span>}
          </>
        );
      }}
    </Dropzone>
  );
};

export default Uploader;
