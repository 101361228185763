import { Form, Col, Button, HeaderRow, Card, LoaderOverlay } from "@emanprague/ppaskit";
import { Check, Input } from "@frui.ts/bootstrap";
import { preventDefault, registerView, ViewComponent } from "@frui.ts/views";
import { NoticeBar } from "components/NoticeBar";
import SimplePortalSettings from "entities/simplePortalSettings";
import { observer } from "mobx-react-lite";
import React from "react";
import NoticeSettingsPageViewModel from "viewModels/admin/noticeSettingsPageViewModel";

const noticeSettingsDetailView: ViewComponent<NoticeSettingsPageViewModel> = observer(({ vm }) => {
  const settings = vm.settings;

  const card = (setting: SimplePortalSettings) => {
    return (
      <Card>
        <Card.Body>
          <HeaderRow level={3} title={vm.translate(setting.key)}>
            <div style={{ display: "flex", alignItems: "baseline" }}>
              <Check
                custom
                className="mb-2"
                id={`${setting.key}-check`}
                target={setting}
                property="active"
                label={vm.translate("active")}
              />
              <Button
                variant="primary"
                onClick={() => {
                  vm.confirmRequest(setting);
                }}
                className="ml-3">
                {vm.translateGeneral("save_button")}
              </Button>
            </div>
          </HeaderRow>
          <Form
            onSubmit={preventDefault(() => {
              vm.confirmRequest(setting);
            })}>
            {!setting.disabled && (
              <Form.Row>
                <Form.Group as={Col} xs={12}>
                  <Form.Label className="admin-form-header">{vm.translate("preview")}: </Form.Label>
                  <NoticeBar content={setting.value} />

                  <>
                    <Form.Label className="mt-3">{vm.translate("value")}</Form.Label>
                    <Input {...{ as: "textarea", rows: 3 }} disabled={setting.disabled} target={setting} property="value" />
                  </>
                </Form.Group>
              </Form.Row>
            )}
          </Form>
        </Card.Body>
      </Card>
    );
  };

  return (
    <>
      <HeaderRow level={1} title={vm.name} className="mx-1 mx-sm-n3" />
      <LoaderOverlay loading={vm.busyWatcher.isBusy}>{settings && settings.map(setting => card(setting))}</LoaderOverlay>
    </>
  );
});

registerView(noticeSettingsDetailView, NoticeSettingsPageViewModel);
