import { observable } from "mobx";
import { Type } from "class-transformer";
import { Expose } from "class-transformer";
import EnumBank from "./enumBank";
import EnumCountry from "./enumCountry";
import EnumGeneric from "./enumGeneric";
import EnumPaymentMethod from "./enumPaymentMethod";
import EnumRate from "./enumRate";
import EnumGatewayMethod from "./enumGatewayMethod";
import EnumUserRole from "./enumUserRole";
import EnumAuditLogStatus from "./enumAuditLogStatus";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class Enumerations {
  @observable
  @Type(() => EnumGeneric)
  @Expose({ name: "installation_floors" })
  installationFloors!: EnumGeneric[];

  @observable
  @Type(() => EnumGeneric)
  @Expose({ name: "titles_before" })
  titlesBefore!: EnumGeneric[];

  @observable
  @Type(() => EnumGeneric)
  @Expose({ name: "titles_after" })
  titlesAfter!: EnumGeneric[];

  @observable
  @Type(() => EnumGeneric)
  @Expose({ name: "partner_types" })
  partnerTypes!: EnumGeneric[];

  @observable
  @Type(() => EnumGeneric)
  @Expose({ name: "request_types" })
  requestTypes!: EnumGeneric[];

  @observable
  @Type(() => EnumGeneric)
  @Expose({ name: "advance_frequencies" })
  advanceFrequencies!: EnumGeneric[];

  @observable
  @Type(() => EnumBank)
  banks!: EnumBank[];

  @observable
  @Type(() => EnumGeneric)
  commodities!: EnumGeneric[];

  @observable
  @Type(() => EnumRate)
  rates!: EnumRate[];

  @observable
  @Type(() => EnumGeneric)
  @Expose({ name: "reading_reasons" })
  readingReasons!: EnumGeneric[];

  @observable
  @Type(() => EnumCountry)
  countries!: EnumCountry[];

  @observable
  @Type(() => EnumGeneric)
  @Expose({ name: "form_types" })
  formTypes!: EnumGeneric[];

  @observable
  @Type(() => EnumGeneric)
  @Expose({ name: "advance_change_reasons" })
  advanceChangeReasons!: EnumGeneric[];

  @observable
  @Type(() => EnumGeneric)
  @Expose({ name: "claim_types" })
  claimTypes!: EnumGeneric[];

  @observable
  @Type(() => EnumGeneric)
  @Expose({ name: "reading_form_reasons" })
  readingFormReasons!: EnumGeneric[];

  @observable
  @Type(() => EnumGeneric)
  @Expose({ name: "invoice_types" })
  invoiceTypes!: EnumGeneric[];

  @observable
  @Type(() => EnumPaymentMethod)
  @Expose({ name: "payment_methods" })
  paymentMethods!: EnumPaymentMethod[];

  @observable
  @Type(() => EnumGatewayMethod)
  @Expose({ name: "gateway_methods" })
  gatewayMethods!: EnumGatewayMethod[];

  @observable
  @Type(() => EnumUserRole)
  @Expose({ name: "user_roles" })
  userRoles!: EnumUserRole[];

  @observable
  @Type(() => EnumAuditLogStatus)
  @Expose({ name: "audit_log_statuses" })
  auditLogStatuses!: EnumAuditLogStatus[];

  static ValidationRules = {
    installationFloors: { required: true },
    titlesBefore: { required: true },
    titlesAfter: { required: true },
    partnerTypes: { required: true },
    requestTypes: { required: true },
    advanceFrequencies: { required: true },
    banks: { required: true },
    commodities: { required: true },
    rates: { required: true },
    readingReasons: { required: true },
    countries: { required: true },
    formTypes: { required: true },
    advanceChangeReasons: { required: true },
    claimTypes: { required: true },
    readingFormReasons: { required: true },
    invoiceTypes: { required: true },
    paymentMethods: { required: true },
    gatewayMethods: { required: true },
    userRoles: { required: true },
    auditLogStatuses: { required: true },
  };
}
