import { observable } from "mobx";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class SimplePortalSettings {
  id!: number;

  @observable
  value!: string;

  @observable
  key!: string;

  @observable
  active!: boolean;

  get disabled() {
    return !this.active;
  }

  static ValidationRules = {
    id: { required: true, number: true },
    value: { required: true },
    key: { required: true },
    active: { required: true },
  };
}
