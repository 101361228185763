import { LoaderOverlay, Modal, ProductCard } from "@emanprague/ppaskit";
import { registerView, ViewComponent } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import React from "react";
import { HeaderRow } from "@emanprague/ppaskit";
import ProductCardPreviewViewModel from "viewModels/admin/productCards/productCardPreviewViewModel";
import { openInNewTab } from "helpers/utils";

const productCardPreview: ViewComponent<ProductCardPreviewViewModel> = observer(({ vm }) => (
  <Modal centered={true} show={true} onHide={vm.requestClose} size="lg">
    <LoaderOverlay loading={vm.busyWatcher.isBusy}>
      <Modal.Header closeButton>
        <HeaderRow level={4} title={vm.translate("preview")} />
      </Modal.Header>
      <Modal.Body style={{ display: "flex", justifyContent: "center" }}>
        {vm.productCard && (
          <div style={{ width: "655px", height: "260px" }}>
            <ProductCard
              preview={true}
              title={vm.productCard.title}
              body={vm.productCard.text}
              buttonLabel={vm.productCard.buttonText}
              onClick={() => openInNewTab(vm.productCard.buttonUrl!)}
            />
          </div>
        )}
      </Modal.Body>
    </LoaderOverlay>
  </Modal>
));

registerView(productCardPreview, ProductCardPreviewViewModel);
