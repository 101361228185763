import { ILocalizationService, INotificationService, SeverityLevel } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, ScreenBase, watchBusy } from "@frui.ts/screens";
import { attachAutomaticValidator, ManualEntityValidator, validate } from "@frui.ts/validation";
import ReqContactData from "entities/reqContactData";
import { interfaces } from "inversify";
import { action, computed, observable } from "mobx";
import PartnersRepository from "repositories/partnersRepository";
import EnumsService from "services/enumsService";
import UserContext from "services/userContext";
import { addManualValidation, applyServerErrors } from "services/validation/validationHelpers";
import AddressChangeViewModel from "viewModels/addressChangeViewModel";

export default class ModalEditContactInfoViewModel extends ScreenBase {
  static navigationName = "contact";

  navigationName = ModalEditContactInfoViewModel.navigationName;
  busyWatcher = new BusyWatcher();

  @observable data: ReqContactData;
  @observable addressChangeVM: AddressChangeViewModel;

  manualValidator = new ManualEntityValidator<ReqContactData & { base: any }>(true);

  get partner() {
    return this.userContext.activePartner;
  }

  constructor(
    private partnerId: number,
    public localization: ILocalizationService,
    private partnersRepository: PartnersRepository,
    private enumsService: EnumsService,
    private notificationService: INotificationService,
    public userContext: UserContext,
    addressChangeViewModelFactory: ReturnType<typeof AddressChangeViewModel.Factory>
  ) {
    super();

    this.name = this.translate("change_contact_info");

    this.data = new ReqContactData();
    attachAutomaticValidator(this.data, addManualValidation(ReqContactData.ValidationRules, this.manualValidator));

    this.addressChangeVM = addressChangeViewModelFactory(this.partner!.permanentAddress!, this.data, undefined, "permanent");
  }

  @action
  protected onInitialize() {
    this.data.email = this.partner?.email || "";
    this.data.phone = this.partner?.phone || "";
  }

  @computed
  get countries() {
    return this.enumsService.getValues("countries");
  }

  @action.bound
  @watchBusy
  async confirmChange() {
    this.manualValidator.clearErrors();

    if (!validate(this.data)) {
      return;
    }

    const response = await this.partnersRepository.updateContactData(this.partnerId, this.data);

    if (response.success) {
      if (response.payload.message && response.payload.message.length > 0) {
        response.payload.message.forEach(message => {
          this.notificationService.addNotification(message, SeverityLevel.success);
        });
      } // else - nothing was changed
      this.requestClose();
    } else {
      applyServerErrors(response.payload, this.manualValidator, key => this.localization.translateAttribute("user", key));
    }
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`products.customer_info.${key}`);
  }

  @bound translateGeneral(key: string) {
    return this.localization.translateGeneral(`general.${key}`);
  }

  static Factory({ container }: interfaces.Context) {
    return (partnerId: number) => {
      return new ModalEditContactInfoViewModel(
        partnerId,
        container.get("ILocalizationService"),
        container.get(PartnersRepository),
        container.get(EnumsService),
        container.get("INotificationService"),
        container.get(UserContext),
        container.get(AddressChangeViewModel.Factory)
      );
    };
  }
}
