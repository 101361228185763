import { IEventBus } from "@emanprague/shared-services";
import { plainToClass } from "class-transformer";
import PartnerId from "entities/partnerId";
import User from "entities/user";
import UserLogin from "entities/userLogin";
import { action } from "mobx";
import LoginRepository from "repositories/loginRepository";
import { GeneralEvents } from "./events";
import UserContext from "./userContext";
import AdminRepository from "repositories/adminRepository";

export default class SecurityService {
  constructor(
    private loginRepository: LoginRepository,
    private userContext: UserContext,
    eventBus: IEventBus,
    private adminRepository: AdminRepository
  ) {
    eventBus.subscribe(GeneralEvents.sessionChanged, () => this.checkSession().then(x => x.applySessionCallback()));
  }

  async checkSession() {
    const result = await this.loginRepository.currentUser();

    const applySessionCallback: () => void = () => {
      if (result.success) {
        this.setUser(result.payload);
      } else {
        this.setUser();
      }
    };

    return { user: result.payload, applySessionCallback };
  }

  async logIn(login: string, password: string) {
    const credentials = plainToClass(UserLogin, { email: login, password });
    const result = await this.loginRepository.login(credentials);

    if (result.success) {
      this.setUser(result.payload);
      return undefined;
    } else {
      return result.payload.message;
    }
  }

  async changeActivePartner(partnerId: number) {
    if (partnerId === this.userContext.activePartnerId) {
      return;
    }

    const request = new PartnerId();
    request.partnerId = partnerId;
    const result = await this.loginRepository.changeActivePartner(request);
    if (result.success) {
      window.location.reload();
    }
  }

  async logout() {
    await this.loginRepository.logout();
    window.location.reload();
  }

  async unmorphPartner() {
    await this.adminRepository.unmorphAsPartner();
    window.location.reload();
  }

  @action
  private setUser(user?: User) {
    // TODO remove this fix once BE data is migrated
    if (user && !user.role) {
      user.role = "customer";
    }

    this.userContext.user = user;
  }
}
