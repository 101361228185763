import React from "react";

import { Card } from "..";

export interface SmallBannerProps {
  title: string;
  htmlContent: string;
  link: React.ReactNode;
  imageUrl?: string;
  className?: string;
}

export default class SmallBanner extends React.PureComponent<SmallBannerProps> {
  render() {
    const { title, htmlContent, link, imageUrl, className } = this.props;

    return (
      <Card className={className + " small-banner__background"} style={{ backgroundImage: `url(${imageUrl})` }}>
        <Card.Body>
          {title && <h2 className="display-2 mb-3 text-white">{title}</h2>}
          <div className="small-banner_text mb-4" dangerouslySetInnerHTML={{ __html: htmlContent }} />
          {link}
        </Card.Body>
      </Card>
    );
  }
}
