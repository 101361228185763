import { BusyWatcher, ScreenBase, watchBusy } from "@frui.ts/screens";
import { interfaces } from "inversify";
import { action, observable, runInAction } from "mobx";
import { bound } from "@frui.ts/helpers";
import { ILocalizationService } from "@emanprague/shared-services";
import { unwrapErrorMessage } from "repositories/helpers";
import AdminRepository from "repositories/adminRepository";
import ProductCardPreview from "entities/productCardPreview";

const navigationName = "preview";

export default class ProductCardPreviewViewModel extends ScreenBase {
  static navigationName = navigationName;

  navigationName = navigationName;
  busyWatcher = new BusyWatcher();

  @observable productCard: ProductCardPreview;
  @observable errorMessage?: string;

  constructor(public productCardId: number, public localization: ILocalizationService, private adminRepository: AdminRepository) {
    super();
  }

  protected onInitialize(): Promise<any> | void {
    this.loadData();
  }

  @watchBusy
  @action
  async loadData() {
    if (!this.productCardId) {
      return;
    }

    const response = await this.adminRepository.getProductCardDetail(this.productCardId);
    if (response.success) {
      runInAction(() => (this.productCard = response.payload));
    } else {
      runInAction(() => (this.errorMessage = unwrapErrorMessage(response.payload)));
    }
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`admin.product_cards.detail.${key}`);
  }

  @bound translateGeneral(key: string) {
    return this.localization.translateGeneral(`general.${key}`);
  }

  static Factory({ container }: interfaces.Context) {
    return (productCardId: number) => {
      return new ProductCardPreviewViewModel(
        productCardId,
        container.get("ILocalizationService"),
        container.get(AdminRepository)
      );
    };
  }
}
