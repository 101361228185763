import { registerView, ViewComponent, preventDefault } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import React from "react";
import ResetPasswordViewModel from "viewModels/profile/resetPasswordViewModel";
import PasswordInput from "controls/twoway/PasswordInput";
import { Modal, Button, PasswordIndicator, HeaderRow, LoaderOverlay } from "@emanprague/ppaskit";
import { Form, Col } from "react-bootstrap";

const resetPasswordView: ViewComponent<ResetPasswordViewModel> = observer(({ vm }) => {
  return (
    <>
      <Modal centered show onHide={() => {}}>
        {" "}
        {/** empty onHide to not generate errors */}
        <LoaderOverlay loading={vm.busyWatcher.isBusy}>
          <Modal.Header /* closeButton */>
            <HeaderRow title={vm.translate("title")} level={4} />
          </Modal.Header>
          {vm.isTokenValid ? (
            <>
              <Modal.Body>
                <Form className="w-75 w-sm-50 mx-auto mb-n3" onSubmit={preventDefault(vm.confirmResetPassword)}>
                  <Form.Row>
                    <Form.Group as={Col} xs={12}>
                      <Form.Label>{vm.translate("new_password")}</Form.Label>
                      <PasswordInput target={vm.data} property="password" autoFocus />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} xs={12}>
                      <Form.Label>{vm.translate("new_password_confirmation")}</Form.Label>
                      <PasswordInput target={vm.data} property="passwordConfirmation" />
                    </Form.Group>
                    <Form.Group as={Col} xs={12}>
                      <PasswordIndicator
                        label={vm.translatePassword("password_complexity.level_" + vm.passwordComplexityValue.toString())}
                        value={vm.passwordComplexityValue}
                        tooltip={
                          <div
                            className="font-size-base font-weight-bold"
                            dangerouslySetInnerHTML={{ __html: vm.translatePassword("password_complexity_tooltip") }}
                          />
                        }
                        className="mb-3"
                      />
                    </Form.Group>
                    {vm.errorMessage && <Form.Control.Feedback type="invalid">{vm.errorMessage}</Form.Control.Feedback>}
                  </Form.Row>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button disabled={!vm.canConfirm} variant="primary" onClick={vm.confirmResetPassword}>
                  {vm.localization.translateGeneral("general.confirm_changes_button")}
                </Button>
              </Modal.Footer>
            </>
          ) : (
            <p className="font-size-md text-center text-gray mb-4 pb-3">Undefined Token</p>
          )}
        </LoaderOverlay>
      </Modal>
    </>
  );
});

registerView(resetPasswordView, ResetPasswordViewModel);
