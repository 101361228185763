import { observable } from "mobx";
import merge from "lodash/merge";
import { Expose } from "class-transformer";
import ReqChangeInvoiceSentMethodType from "./reqChangeInvoiceSentMethodType";
import { EntityValidationRules } from "services/validation/entityValidationRules";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class ReqChangeInvoiceSentMethod {
  @observable
  type!: ReqChangeInvoiceSentMethodType;

  @observable
  @Expose({ name: "supply_point_ids" })
  supplyPointIds!: number[];

  @observable
  email?: string;

  /**
   * Ruian ID
   */
  @observable
  @Expose({ name: "ruian_id" })
  ruianId?: number;

  /**
   * Existing address
   */
  @observable
  @Expose({ name: "address_id" })
  addressId?: number;

  @observable
  street?: string;

  @observable
  @Expose({ name: "house_number" })
  houseNumber?: string;

  @observable
  @Expose({ name: "street_number" })
  streetNumber?: string;

  @observable
  city?: string;

  @observable
  @Expose({ name: "city_part" })
  cityPart?: string;

  @observable
  zip?: string;

  @observable
  @Expose({ name: "country_id" })
  countryId?: number;

  static ValidationRules = {
    type: { required: true },
    supplyPointIds: { required: true },
    ruianId: { number: true },
    addressId: { number: true },
    countryId: { number: true },
  };
}

merge(ReqChangeInvoiceSentMethod.ValidationRules, {
  supplyPointIds: { required: true },
  type: { required: true },
  email: { requiredIf: { condition: (item: ReqChangeInvoiceSentMethod) => item.type === "email" }, isEmail: true },
  houseNumber: {
    requiredIf: { condition: (item: ReqChangeInvoiceSentMethod) => item.type === "post" && !item.ruianId && !item.addressId },
  },
  city: {
    requiredIf: { condition: (item: ReqChangeInvoiceSentMethod) => item.type === "post" && !item.ruianId && !item.addressId },
  },
  zip: {
    requiredIf: { condition: (item: ReqChangeInvoiceSentMethod) => item.type === "post" && !item.ruianId && !item.addressId },
  },
  countryId: {
    requiredIf: { condition: (item: ReqChangeInvoiceSentMethod) => item.type === "post" && !item.ruianId && !item.addressId },
  },
} as EntityValidationRules<ReqChangeInvoiceSentMethod>);
