import { observable } from "mobx";
import { Expose, Type } from "class-transformer";
import Address from "./address";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class PartnerListItem {
  /**
   * @example
   * 200
   */
  id!: number;

  /**
   * Enumeration partner type
   */
  @observable
  @Expose({ name: "partner_type_id" })
  partnerTypeId!: number;

  /**
   * Last name or name of company
   * @example
   * Novák || eMan s.r.o.
   */
  @observable
  @Expose({ name: "last_name" })
  lastName!: string;

  /**
   * BPAR ID
   */
  @observable
  @Expose({ name: "external_id" })
  externalId!: string;

  /**
   * @example
   * Marek
   */
  @observable
  @Expose({ name: "first_name" })
  firstName?: string;

  @observable
  @Expose({ name: "title_before_id" })
  titleBeforeId?: number;

  @observable
  @Expose({ name: "title_before2_id" })
  titleBefore2Id?: number;

  @observable
  @Expose({ name: "title_after_id" })
  titleAfterId?: number;

  /**
   * IČ
   */
  @observable
  @Expose({ name: "org_id" })
  orgId?: string;

  /**
   * DIČ
   */
  @observable
  @Expose({ name: "vat_id" })
  vatId?: string;

  /**
   * @example
   * 377000111
   */
  @observable
  phone?: string;

  /**
   * @example
   * marek.novak@example.ppas.cz
   */
  @observable
  email!: string;

  /**
   * Greeeting enumeration id
   */
  @observable
  @Expose({ name: "greeting_id" })
  greetingId?: number;

  /**
   * Legal form enumeration id
   */
  @observable
  @Expose({ name: "legal_form_id" })
  legalFormId?: number;

  @observable
  @Expose({ name: "deposit_due_amount" })
  depositDueAmount!: number;

  @observable
  @Expose({ name: "deposit_due_count" })
  depositDueCount!: number;

  @observable
  @Expose({ name: "invoice_due_amount" })
  invoiceDueAmount!: number;

  @observable
  @Expose({ name: "invoice_due_count" })
  invoiceDueCount!: number;

  @observable
  @Expose({ name: "marketing_agreement" })
  marketingAgreement?: boolean;

  @observable
  @Type(() => Address)
  @Expose({ name: "permanent_address" })
  permanentAddress?: Address;

  @observable
  @Expose({ name: "user_id" })
  userId?: number;

  static ValidationRules = {
    id: { required: true, number: true },
    partnerTypeId: { required: true, number: true },
    lastName: { required: true },
    externalId: { required: true },
    titleBeforeId: { number: true },
    titleBefore2Id: { number: true },
    titleAfterId: { number: true },
    email: { required: true },
    greetingId: { number: true },
    legalFormId: { number: true },
    depositDueAmount: { required: true, number: true },
    depositDueCount: { required: true, number: true },
    invoiceDueAmount: { required: true, number: true },
    invoiceDueCount: { required: true, number: true },
    userId: { number: true },
  };
}
