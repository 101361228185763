import { LoaderOverlay, Modal } from "@emanprague/ppaskit";
import { registerView, ViewComponent } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import React from "react";
import { Col, Row } from "react-bootstrap";
import MessagePreviewViewModel from "viewModels/admin/messagePreviewViewModel";
import { HeaderRow } from "@emanprague/ppaskit";

const messagePreview: ViewComponent<MessagePreviewViewModel> = observer(({ vm }) => (
  <Modal centered show onHide={vm.requestClose} className="admin-modal">
    <LoaderOverlay loading={vm.busyWatcher.isBusy}>
      <Modal.Header closeButton />
      <Modal.Body>
        <Row className="mb-4 mt-n2 message-preview-row" noGutters>
          <Col xs={12} className="text-center mb-3">
            <HeaderRow level={4} title={vm.message?.title} />
          </Col>
          <Col xs={12}>
            <div className="message-preview-text" dangerouslySetInnerHTML={{ __html: vm.message?.content }} />
            {vm.errorMessage && <>{vm.errorMessage}</>}
          </Col>
        </Row>
      </Modal.Body>
    </LoaderOverlay>
  </Modal>
));

registerView(messagePreview, MessagePreviewViewModel);
