import { registerView, ViewComponent, preventDefault } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import React from "react";
import { Button, Modal, Form, Col, HeaderRow, LoaderOverlay } from "@emanprague/ppaskit";
import ModalEditMarketingAgreementViewModel from "viewModels/products/myProducts/detailPages/customerInfo/modalEditMarketingAgreementViewModel";
import { Check } from "@frui.ts/bootstrap";

const modalEditMarketingAgreementView: ViewComponent<ModalEditMarketingAgreementViewModel> = observer(({ vm }) => (
  <>
    <Modal centered show onHide={vm.requestClose}>
      <Modal.Header closeButton>
        <HeaderRow title={vm.name} level={4} />
      </Modal.Header>
      <LoaderOverlay loading={vm.busyWatcher.isBusy}>
        <Modal.Body>
          <Form className="modal-form" onSubmit={preventDefault(vm.confirmAgreement)}>
            <Form.Row>
              <Form.Group as={Col} xs={12}>
                <Check
                  custom
                  target={vm.data}
                  property="mktAgreement"
                  label={
                    <>
                      {vm.translateGeneral("mkt_agreement_text")}{" "}
                      <a href={vm.translateGeneral("link_mkt")} target="_blank" rel="noopener noreferrer">
                        {vm.translateGeneral("mkt_agreement_link")}
                      </a>
                    </>
                  }
                />
              </Form.Group>
              {vm.errorMessage && <Form.Control.Feedback type="invalid">{vm.errorMessage}</Form.Control.Feedback>}
            </Form.Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" className="order-2" onClick={vm.confirmAgreement}>
            {vm.localization.translateGeneral("general.confirm_changes_button")}
          </Button>
          <Button variant="secondary" className="order-1" onClick={vm.requestClose}>
            {vm.localization.translateGeneral("general.cancel_button")}
          </Button>
        </Modal.Footer>
      </LoaderOverlay>
    </Modal>
  </>
));

registerView(modalEditMarketingAgreementView, ModalEditMarketingAgreementViewModel);
