import { observable } from "mobx";
import { Expose, Type } from "class-transformer";
import InvoiceListItem from "./invoiceListItem";
import Reminder from "./reminder";
import Attachment from "./attachment";
import Charge from "./charge";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class Invoice extends InvoiceListItem {
  @observable
  @Type(() => Reminder)
  reminders!: Reminder[];

  @observable
  @Type(() => Attachment)
  attachments!: Attachment[];

  /**
   * Enumeration::GatewayPayment ids
   */
  @observable
  @Expose({ name: "gateway_method_ids" })
  gatewayMethodIds?: number[];

  @observable
  @Expose({ name: "amount_principal" })
  amountPrincipal?: number;

  @observable
  @Type(() => Charge)
  charges?: Charge[];

  static ValidationRules = Object.assign(
    {
      reminders: { required: true },
      attachments: { required: true },
      amountPrincipal: { number: true },
    },
    InvoiceListItem.ValidationRules
  );
}
