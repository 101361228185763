import { ILocalizationService } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, Router, watchBusy } from "@frui.ts/screens";
import ContinuousListViewModelBase from "viewModels/continuousListViewModelBase";
import { IAdminModule } from "viewModels/types";
import { action, observable } from "mobx";
import AdminRepository from "repositories/adminRepository";
import AdminPartnersFilter from "models/adminPartnersFilter";
import PartnerListItem from "entities/partnerListItem";
import EnumsService from "services/enumsService";
import PartnerImpersonationListItem from "entities/partnerImpersonationListItem";

const navigationName = "screen-share";

class PartnerListItemWithKey extends PartnerImpersonationListItem {
  @observable key?: number;

  constructor(item: PartnerListItem, key?: number) {
    super();

    Object.assign(this, item);
    this.key = key;
  }
}

@Router.registerRoute({ name: Router.Self, route: `${navigationName}` })
export default class ScreenSharePageViewModel
  extends ContinuousListViewModelBase<PartnerListItemWithKey, AdminPartnersFilter, never>
  implements IAdminModule
{
  navigationName = navigationName;
  orderIndex = 10;
  busyWatcher = new BusyWatcher();

  constructor(
    public localization: ILocalizationService,
    public enumsService: EnumsService,
    private adminRepository: AdminRepository
  ) {
    super();

    this.name = this.translate("title");
  }

  protected onInitialize(): Promise<any> | void {
    return this.loadData();
  }

  @bound
  @watchBusy
  async loadData() {
    const response = await this.adminRepository.getAdminPartners(this.pagingFilter, this.filter);
    if (response.success) {
      response.payload[0] = response.payload[0].map((item, index) => new PartnerListItemWithKey(item, index));
      this.setData(response.payload);
    }
  }

  @bound
  @watchBusy
  async selectCustomer(partnerId: number, userId?: number) {
    const response = await this.adminRepository.morphAsPartner({ partnerId, userId });
    if (response.success) {
      window.location.href = "#/dashboard";
      window.location.reload();
    }
  }

  @action.bound
  setSearchValue(value: string) {
    this.filter.search = value;
    this.applyFilterAndLoadDebounced();
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`admin.screen_share.${key}`);
  }

  @bound translateGeneral(key: string) {
    return this.localization.translateGeneral(`general.${key}`);
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected resetFilterValues(filter: AdminPartnersFilter) {}

  protected createFilter() {
    return new AdminPartnersFilter();
  }

  protected findNavigationChild(navigationName?: string): any {
    return super.findNavigationChild(navigationName);
  }
}
