import { Type } from "class-transformer";
import { observable } from "mobx";
import SimplePortalSettings from "./simplePortalSettings";
// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class PortalSettings {
  @observable
  @Type(() => SimplePortalSettings)
  simple!: SimplePortalSettings[];

  static ValidationRules = {
    simple: { required: true },
  };
}
