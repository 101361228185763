import { Expose, Transform } from "class-transformer";
import formatISO from "date-fns/formatISO";
import { observable } from "mobx";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class User {
  /**
   * @example
   * 200
   */
  id!: number;

  /**
   * Entitities::AdminRole
   */
  @observable
  role!: string;

  /**
   * @example
   * marek.novak@example.ppas.cz
   */
  @observable
  email!: string;

  /**
   * @example
   * asdfasdf309093093093asdfasdf
   */
  @observable
  token?: string;

  /**
   * Current active partner
   */
  @observable
  @Expose({ name: "active_partner_id" })
  activePartnerId!: number;

  /**
   * Admin who morphed for the user (his partner)
   */
  @observable
  @Expose({ name: "morphed_admin_id" })
  morphedAdminId?: number;

  @observable
  @Transform(value => (value ? new Date(value) : undefined), { toClassOnly: true })
  @Transform(value => (value ? formatISO(value) : undefined), { toPlainOnly: true })
  @Expose({ name: "last_sign_in_at" })
  lastSignInAt?: Date;

  /**
   * Will be true if password needs to be changed, will not appear if false.
   */
  @observable
  @Expose({ name: "force_password_change" })
  forcePasswordChange?: boolean;

  @observable
  @Expose({ name: "admin_abilities" })
  adminAbilities?: string[];

  static ValidationRules = {
    id: { required: true, number: true },
    role: { required: true },
    email: { required: true },
    activePartnerId: { required: true, number: true },
    morphedAdminId: { number: true },
    lastSignInAt: { date: true },
  };
}
