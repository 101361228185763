import { ILocalizationService, INotificationService, SeverityLevel } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, ScreenBase, watchBusy } from "@frui.ts/screens";
import { attachAutomaticValidator, hasVisibleErrors, hideValidationErrors, validate } from "@frui.ts/validation";
import ReqChangeAdvancePayment from "entities/reqChangeAdvancePayment";
import { createSupplyPointsWithIcons, SupplyPointError } from "helpers/supplyPointHelper";
import { interfaces } from "inversify";
import { action, computed, observable, runInAction } from "mobx";
import ProductDetailContext from "models/productDetailContext";
import { unwrapErrorMessage } from "repositories/helpers";
import SupplyPointsRepository from "repositories/supplyPointsRepository";
import DataSyncService from "services/dataSyncService";
import EnumsService from "services/enumsService";
import UserContext from "services/userContext";
import EnumPaymentMethodValue from "entities/enumPaymentMethodValue";
import merge from "lodash/merge";
import { EntityValidationRules } from "services/validation/entityValidationRules";

export default class ModalAdvancePaymentMethodViewModel extends ScreenBase {
  static navigationName = "advance_payment";

  navigationName = ModalAdvancePaymentMethodViewModel.navigationName;
  busyWatcher = new BusyWatcher();

  @observable errorMessage?: string;
  @observable data: ReqChangeAdvancePayment;
  @observable supplyPointsErrors: SupplyPointError[] = [];
  @observable validationRules: EntityValidationRules<ReqChangeAdvancePayment> = {};

  constructor(
    private productDetailContext: ProductDetailContext,
    public localization: ILocalizationService,
    private supplyPointRepository: SupplyPointsRepository,
    private enumsService: EnumsService,
    private dataService: DataSyncService,
    private notificationService: INotificationService,
    private userContext: UserContext
  ) {
    super();

    const account = productDetailContext.supplyPoint?.account;
    if (!account) {
      throw new Error("Supply point detail is not loaded");
    }

    this.data = new ReqChangeAdvancePayment();
    this.data.supplyPointIds = this.currentSupplyPoints.map(i => i.id);
    this.data.advanceMethodId = account.advanceMethodId;

    if (account.advanceAccountPrefix && account.advanceAccountPrefix !== 0) {
      this.data.advanceAccountPrefix = account.advanceAccountPrefix;
    }
    this.data.advanceAccount = account.advanceAccount ?? undefined;
    this.data.advanceAccountBankId = account.advanceAccountBankId ?? undefined;

    this.setValidationRules(this.validationRules);
    attachAutomaticValidator(this.data, this.validationRules);
    this.name = this.translate("advance_payment_method");
  }

  @action.bound
  @watchBusy
  async confirmChange() {
    this.errorMessage = undefined;
    this.supplyPointsErrors = [];

    validate(this.data);

    if (!this.canConfirm) {
      return;
    }

    const response = await this.supplyPointRepository.changeAdvancePaymentMethods(this.productDetailContext.partnerId, this.data);

    if (response.success) {
      runInAction(() => (this.supplyPointsErrors = response.payload.supplyPoints.filter(item => !item.status)));
      if (this.supplyPointsErrors.length === 0) {
        this.notificationService.addNotification(this.translateGeneral("request_sent_success"), SeverityLevel.success);
        this.requestClose();
      }
    } else {
      runInAction(() => (this.errorMessage = unwrapErrorMessage(response.payload)));
    }
  }

  paymentMethodValue(key: number | undefined): EnumPaymentMethodValue | undefined {
    if (key) {
      return this.enumsService.getValue("paymentMethods", key)?.value;
    }

    return undefined;
  }

  @action.bound
  onPaymentMethodChanged() {
    this.data.advanceAccountPrefix = undefined;
    this.data.advanceAccount = undefined;
    this.data.advanceAccountBankId = undefined;
    hideValidationErrors(this.data);
  }

  @action.bound
  setValidationRules(target: EntityValidationRules) {
    merge(target, ReqChangeAdvancePayment.ValidationRules, {
      advanceMethodId: { required: true },
      advanceAccountPrefix: {
        isNumeric: true,
        range: { min: 0, maxExclusive: 1000000, translationCode: "validators.account_prefix" },
      },
      advanceAccount: {
        requiredIf: {
          condition: (item: ReqChangeAdvancePayment) =>
            this.selectedPaymentMethod === "zpusobPlatbyPrikazKUhrade" ||
            this.selectedPaymentMethod === "zpusobPlatbyPrimeInkaso" ||
            this.selectedPaymentMethod === "zpusobPlatbySIPO",
        },
        isNumeric: true,
        range: {
          condition: this.selectedPaymentMethod !== "zpusobPlatbySIPO",
          min: 10,
          maxExclusive: 10000000000,
          translationCode: "validators.account",
        },
        isSipo: { condition: (item: ReqChangeAdvancePayment) => this.selectedPaymentMethod === "zpusobPlatbySIPO" },
      },
      advanceAccountBankId: {
        requiredIf: {
          condition: (item: ReqChangeAdvancePayment) =>
            this.selectedPaymentMethod === "zpusobPlatbyPrikazKUhrade" ||
            this.selectedPaymentMethod === "zpusobPlatbyPrimeInkaso",
        },
      },
    });
  }

  get canConfirm() {
    return !hasVisibleErrors(this.data);
  }

  @computed
  get selectedPaymentMethod() {
    return this.paymentMethodValue(this.data.advanceMethodId);
  }

  @computed
  get currentSupplyPoints() {
    const currentAccountId = this.productDetailContext.supplyPointCore.accountId;
    return this.dataService.supplyPoints.filter(item => item.accountId === currentAccountId);
  }

  @computed
  get currentSupplyPointsWithIcons() {
    return createSupplyPointsWithIcons(this.enumsService, this.currentSupplyPoints, "grey", this.supplyPointsErrors);
  }

  get additionalSupplyPointsWithIcons() {
    return createSupplyPointsWithIcons(
      this.enumsService,
      this.dataService.supplyPoints.filter(item => !this.currentSupplyPoints.some(selectedItem => selectedItem.id === item.id)),
      "grey",
      this.supplyPointsErrors
    );
  }

  get paymentMethods() {
    const partnerTypeId = this.userContext.activePartner?.partnerTypeId;

    if (!partnerTypeId) {
      return [];
    }

    return this.enumsService
      .getValues("paymentMethods")
      .filter(x => x.active && x.advances && x.partnerTypeId === partnerTypeId)
      .map(x => ({
        id: x.id,
        name: x.name,
      }));
  }

  get bankCodes() {
    return this.enumsService.getValues("banks").filter(x => x.active);
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`products.options_deposit.${key}`);
  }

  @bound translateGeneral(key: string, placeholders?: Record<string, string>) {
    return this.localization.translateGeneral(`general.${key}`, placeholders);
  }

  static Factory({ container }: interfaces.Context) {
    return (productDetailContext: ProductDetailContext) => {
      return new ModalAdvancePaymentMethodViewModel(
        productDetailContext,
        container.get("ILocalizationService"),
        container.get(SupplyPointsRepository),
        container.get(EnumsService),
        container.get(DataSyncService),
        container.get("INotificationService"),
        container.get(UserContext)
      );
    };
  }
}
