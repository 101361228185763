import ReqRegistrationStep1 from "entities/reqRegistrationStep1";
import ReqRegistrationStep2 from "entities/reqRegistrationStep2";
import ReqRegistrationStep3 from "entities/reqRegistrationStep3";
import User from "entities/user";
import UpdateLoginEmail from "entities/updateLoginEmail";
import UpdatePassword from "entities/updatePassword";
import UpdateResetPassword from "entities/updateResetPassword";
import UserLogin from "entities/userLogin";
import RepositoryBase from "./repositoryBase";
import ResetPassword from "entities/resetPassword";
import PartnerId from "entities/partnerId";
import ConfirmUpdateLoginEmail from "entities/confirmUpdateLoginEmail";

export default class LoginRepository extends RepositoryBase {
  login(credentials: UserLogin) {
    return this.callApi(api => api.all("user/session").postEntity(credentials, User), false);
  }

  logout() {
    return this.callApi(api => api.all("user/session").delete(), false);
  }

  currentUser() {
    return this.callApi(api => api.all("user/session").getEntity(User), false);
  }

  initUserData() {
    return this.callApi(api => api.all("user/session/load_data").postOnly(undefined), false);
  }

  changeActivePartner(userData: PartnerId) {
    return this.callApi(api => api.all("user/active_partner").patchEntity(userData, PartnerId), false);
  }

  registerStep1(userData: ReqRegistrationStep1) {
    return this.callApi(api => api.all("user/registration/step/1").postEntity(userData, ReqRegistrationStep1), false);
  }

  registerStep2(userData: ReqRegistrationStep2) {
    return this.callApi(api => api.all("user/registration/step/2").patchEntity(userData, ReqRegistrationStep2));
  }

  registerStep3(userData: ReqRegistrationStep3) {
    return this.callApi(api => api.all("user/registration/step/3").patchEntity(userData, ReqRegistrationStep3), false);
  }

  resetPassword(userData: ResetPassword) {
    return this.callApi(api => api.all("user/reset_password").postEntity(userData, ResetPassword), false);
  }

  updatePassword(userData: UpdatePassword) {
    return this.callApi(api => api.all("user/password").putEntity(userData, UpdatePassword), false);
  }

  updateEmail(userData: UpdateLoginEmail) {
    return this.callApi(api => api.all("user/email").putEntity(userData, UpdateLoginEmail), false);
  }

  updateEmailConfirm(userData: ConfirmUpdateLoginEmail) {
    return this.callApi(api => api.all("user/email_confirm").patchEntity(userData, UpdateLoginEmail), false);
  }

  resetPasswordConfirm(userData: UpdateResetPassword) {
    return this.callApi(api => api.all("user/reset_password_confirm").putEntity(userData, UpdateResetPassword), false);
  }
}
