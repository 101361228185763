import { ManualPromise, bound } from "@frui.ts/helpers";
import UserContext from "./userContext";
import ProductCardsRepository from "repositories/productCardsRepository";
import type ProductCardListItemCategory from "entities/productCardListItemCategory";
import ProductCard from "entities/productCard";
import { observable, runInAction } from "mobx";

export default class ProductCardsService {
  constructor(private userContext: UserContext, private repository: ProductCardsRepository) {}

  @observable.ref productCards: ProductCard[] = [];

  @bound
  getProductCards(category: ProductCardListItemCategory) {
    return this.productCards.filter(item => item.category === category);
  }

  async loadProductCards() {
    const partnerId = this.userContext.activePartnerId;
    if (!partnerId) {
      return [];
    }

    const response = await this.repository.getProductCards(partnerId);
    if (response.success) {
      runInAction(() => {
        this.productCards = response.payload[0];
      });
    }
  }
}
