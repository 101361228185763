import { EntityValidationRules } from "services/validation/entityValidationRules";
import { observable } from "mobx";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class ResetPassword {
  /**
   * @example
   * marek.novak@example.ppas.cz
   */
  @observable
  email!: string;

  static ValidationRules = {
    email: { required: true },
  };
}

const ResetPasswordValidationRules: EntityValidationRules<ResetPassword> = {
  email: { required: true, isEmail: true },
};
export { ResetPasswordValidationRules };
