import { IEventBus, ILocalizationService } from "@emanprague/shared-services";
import { IProductPage } from "viewModels/types";
import ProductCardPageViewModel from "./productCardsPageViewModel";
import ProductCardsService from "services/productCardsService";

export default class AxaPageViewModel extends ProductCardPageViewModel implements IProductPage {
  orderIndex = 3;
  navigationName = "axa";

  constructor(public localization: ILocalizationService, productCardsService: ProductCardsService, eventBus: IEventBus) {
    super("axa", localization, productCardsService);
  }
}
