import { IEventBus } from "@emanprague/shared-services";
import { IPagingFilter } from "@frui.ts/data";
import { bound, ManualPromise } from "@frui.ts/helpers";
import RequestsFilter from "models/requestsFilter";
import RequestsRepository from "repositories/requestsRepository";
import { RequestsEvents } from "./events";

export default class RequestsService {
  private waitForRefreshPromise?: ManualPromise;

  constructor(private repository: RequestsRepository, private eventBus: IEventBus) {
    eventBus.subscribe(RequestsEvents.requestsChangedOnServer, this.unblockLoading);
    eventBus.subscribe(RequestsEvents.requestCreated, () => {
      this.blockLoading();
      this.eventBus.publish(RequestsEvents.requestsUpdated(undefined));
    });
  }

  @bound
  private blockLoading() {
    this.waitForRefreshPromise = this.waitForRefreshPromise ?? new ManualPromise();
  }

  @bound
  private unblockLoading() {
    this.waitForRefreshPromise?.resolve();
    this.waitForRefreshPromise = undefined;
  }

  async getRequests(partnerId: number, paging: IPagingFilter, filter?: RequestsFilter) {
    await this.waitForRefreshPromise?.promise;
    return await this.repository.getRequests(partnerId, paging, filter);
  }

  async getActiveRequestsCount(partnerId: number) {
    await this.waitForRefreshPromise?.promise;
    return await this.repository.getActiveRequestsCount(partnerId);
  }
}
