import { Button, Card, Col, Form, LoaderOverlay, Row, SwitchNav } from "@emanprague/ppaskit";
import { preventDefault, registerView, ViewComponent } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import React from "react";
import PartnerConnectViewModel from "viewModels/dashboard/partnerConnectViewModel";
import { Input } from "@frui.ts/bootstrap";
import DateInput from "controls/twoway/DateInput";
import InfoInput from "controls/twoway/InfoInput";
import Tooltip from "@emanprague/basekit/dist/Tooltip";
import iconInfo from "@emanprague/ppaskit/dist/assets/icons/Info.svg";
import Uploader from "controls/uploader";
import PartnerType from "models/partnerType";

const partnerConnectView: ViewComponent<PartnerConnectViewModel> = observer(({ vm }) => {
  const navLinks = [
    {
      id: PartnerType.Household,
      title: `${vm.translate("household")}`,
      href: "#",
      onClick: preventDefault(() => vm.applySwitchState(PartnerType.Household)),
    },
    {
      id: PartnerType.Business,
      title: `${vm.translate("business")}`,
      href: "#",
      onClick: preventDefault(() => vm.applySwitchState(PartnerType.Business)),
    },
  ];

  return (
    <div className="mb-3">
      <Row>
        <Col className="d-flex justify-content-center mb-4 mt-5">
          <h2 className="display-2">{vm.name}</h2>
        </Col>
      </Row>
      <Row className="d-flex justify-content-center">
        <Col className="d-flex justify-content-center">
          <LoaderOverlay loading={vm.busyWatcher.isBusy} className="h-100">
            <Card className="mx-n3 mx-sm-0 mb-3 h-100 partner-connect__card">
              <Card.Body>
                {!vm.requestSent ? (
                  <Form className="modal-form" onSubmit={preventDefault(vm.connectPartner)}>
                    <Form.Row>
                      <Form.Group className="text-center mt-3" as={Col} xs={12}>
                        <SwitchNav links={navLinks} size="sm" activeId={vm.switchNavActiveState} variant="secondary" />
                      </Form.Group>
                      <Form.Group as={Col} xs={12} className="mt-3">
                        {vm.ownerStatuses.map(status => (
                          <Form.Check
                            custom
                            key={status.id}
                            id={`change_type_${status.id}`}
                            value={status.id}
                            checked={vm.currentOwnerStatus === status.id}
                            type="radio"
                            label={status.name}
                            onChange={() => vm.onChangeOwnerStatus(status.id)}
                            className="mb-1"
                          />
                        ))}
                      </Form.Group>
                      <Form.Group as={Col} xs={12} className="mt-3">
                        <Form.Label>{vm.localization.translateAttribute("partner", "external_id")}</Form.Label>
                        <InfoInput
                          target={vm.request}
                          property="customerNumber"
                          autoFocus
                          type="number"
                          tooltip={
                            <>
                              <div className="font-size-base font-weight-bold mb-2 w-75">
                                {vm.translateGeneral("customer_number_tooltip_head")}
                              </div>
                              <div className="mb-3 w-75">{vm.translateGeneral("customer_number_tooltip_body")}</div>
                              <img
                                className="mb-n3"
                                width={300}
                                sizes="300px"
                                src={require("assets/images/img-cislo-zakaznika.png")}
                                srcSet={`${require("assets/images/img-cislo-zakaznika.png")} 1x, ${require("assets/images/img-cislo-zakaznika@2x.png")} 2x, ${require("assets/images/img-cislo-zakaznika@3x.png")} 3x`}
                                alt=""
                              />
                            </>
                          }
                        />
                      </Form.Group>
                      <Form.Group as={Col} xs={12}>
                        <Form.Label>{vm.localization.translateAttribute("account", "external_id")}</Form.Label>
                        <InfoInput
                          target={vm.request}
                          property="accountNumber"
                          type="number"
                          tooltip={
                            <>
                              <div className="font-size-base font-weight-bold mb-3">
                                {vm.translateGeneral("account_number_tooltip_head")}
                              </div>
                              <img
                                className="mb-n3"
                                width={300}
                                sizes="300px"
                                src={require("assets/images/img-cislo-smlouvy.png")}
                                srcSet={`${require("assets/images/img-cislo-smlouvy.png")} 1x, ${require("assets/images/img-cislo-smlouvy@2x.png")} 2x, ${require("assets/images/img-cislo-smlouvy@3x.png")} 3x`}
                                alt=""
                              />
                            </>
                          }
                        />
                      </Form.Group>
                      {vm.switchNavActiveState === PartnerType.Household ? (
                        <Form.Group as={Col} xs={10}>
                          <Form.Label>{vm.localization.translateAttribute("user", "birth_date")}</Form.Label>
                          <DateInput target={vm.request} property="birthDate" />
                        </Form.Group>
                      ) : (
                        <Form.Group as={Col} xs={12}>
                          <Form.Label>{vm.translate("company_number")}</Form.Label>
                          <Input target={vm.request} property="orgId" />
                        </Form.Group>
                      )}
                      {vm.currentOwnerStatus === "not_owner" && (
                        <>
                          <Form.Group as={Col} xs={12} className="mt-3 mb-4">
                            <Form.Label className="d-flex align-items-center">
                              <strong className="mr-1">{vm.translate("account_document")}</strong>
                              <Tooltip id="account-tooltip" tooltip={vm.translate("account_document_tooltip")}>
                                <img src={iconInfo} alt="" width={22} height={22} className="align-top" />
                              </Tooltip>
                            </Form.Label>
                            <Uploader
                              localization={vm.localization}
                              onDrop={vm.onAccountFilesDrop}
                              onDropRejected={vm.onAccountDropRejected}
                              fileSelectError={vm.accountUploaderErrorMessage}
                            />
                          </Form.Group>

                          {vm.switchNavActiveState === PartnerType.Business && (
                            <Form.Group as={Col} xs={12} className="mb-4">
                              <Form.Label className="d-flex align-items-center">
                                <strong className="mr-1">{vm.translate("registry_document")}</strong>
                                <Tooltip id="account-tooltip" tooltip={vm.translate("registry_document_tooltip")}>
                                  <img src={iconInfo} alt="" width={22} height={22} className="align-top" />
                                </Tooltip>
                              </Form.Label>
                              <Uploader
                                localization={vm.localization}
                                onDrop={vm.onRegistryFilesDrop}
                                onDropRejected={vm.onRegistryDropRejected}
                                fileSelectError={vm.registryUploaderErrorMessage}
                              />
                            </Form.Group>
                          )}
                        </>
                      )}

                      <Form.Group as={Col} xs={12} className="mt-3">
                        <Button className="w-100" disabled={!vm.canConfirm} onClick={vm.connectPartner}>
                          {vm.translate("connect_button")}
                        </Button>

                        {vm.errorMessage && (
                          <Form.Control.Feedback className="mt-3" type="invalid">
                            {vm.errorMessage}
                          </Form.Control.Feedback>
                        )}
                      </Form.Group>
                    </Form.Row>
                  </Form>
                ) : (
                  <Row>
                    <Col className="text-center">{vm.translate("sent_confirmation")}</Col>
                  </Row>
                )}
              </Card.Body>
            </Card>
          </LoaderOverlay>
        </Col>
      </Row>
    </div>
  );
});

registerView(partnerConnectView, PartnerConnectViewModel);
