import { FormControlProps, InputPhone, InputPhoneProps } from "@emanprague/ppaskit";
import { ValidationControlBase } from "@frui.ts/bootstrap";
import { InputProps } from "@frui.ts/bootstrap/dist/controls/input";
import { bound } from "@frui.ts/helpers";
import React from "react";

export default class PhoneInput<TTarget, TOtherProps = unknown> extends ValidationControlBase<
  TTarget,
  InputProps & InputPhoneProps & FormControlProps & TOtherProps
> {
  @bound
  protected renderInner() {
    const { noValidation, errorMessage, feedbackInvalid, ...otherProps } = this.inheritedProps;
    const validationError = this.getValidationError();

    return (
      <InputPhone
        {...otherProps}
        value={this.value ?? ""}
        onChange={this.setValue}
        feedbackInvalid={validationError || feedbackInvalid}
      />
    );
  }
}
