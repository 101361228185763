import { registerView, ViewComponent, View } from "@frui.ts/views";
import { observer, Observer } from "mobx-react-lite";
import React from "react";
import OverviewViewModel from "viewModels/dashboard/overviewViewModel";

const overviewListView: ViewComponent<OverviewViewModel> = observer(({ vm }) => (
  <Observer>{() => <View vm={vm.activeChild} />}</Observer>
));

registerView(overviewListView, OverviewViewModel);
