import { Button, Card, Col, Form, LoaderOverlay } from "@emanprague/ppaskit";
import { Input, Select } from "@frui.ts/bootstrap";
import { preventDefault, registerView, ViewComponent } from "@frui.ts/views";
import DatePicker from "controls/twoway/DatePicker";
import { getCommodityUnit } from "helpers/commodityHelper";
import { observer } from "mobx-react-lite";
import CommodityType from "models/commodityType";
import React from "react";
import SingleSelfReadingViewModel from "viewModels/products/myProducts/detailPages/history/singleSelfReadingViewModel";
import { Modal } from "react-bootstrap";

const singleSelfReadingView: ViewComponent<SingleSelfReadingViewModel> = observer(({ vm }) => (
  <>
    <Card className="mx-n3 mx-sm-0 mb-3">
      <LoaderOverlay loading={vm.busyWatcher.isBusy}>
        {vm.supplyPoint && (
          <Card.Body>
            <Form onSubmit={preventDefault(vm.confirmReading)}>
              <Form.Row className="form-row-centered">
                <Form.Group as={Col} xs={8} className="my-4 text-center">
                  <Form.Label>
                    {vm.translate(vm.commodityType === CommodityType.Gas ? "last_state_gas" : "last_state_electricity")}

                    <strong>
                      {vm.commodityType === CommodityType.Gas && (
                        <>
                          {vm.localization.formatNumber(vm.lastValue)}&nbsp;{getCommodityUnit(vm.commodityType)}
                        </>
                      )}
                      {vm.commodityType === CommodityType.Electricity && (
                        <>
                          {vm.isDoubleTariff ? (
                            <>
                              {vm.localization.formatNumber(vm.lastValue)}&nbsp;{getCommodityUnit(vm.commodityType)}&nbsp;
                              {vm.translateGeneral("high_tariff_abbr")}, {vm.localization.formatNumber(vm.lastValueNt)}&nbsp;
                              {getCommodityUnit(vm.commodityType)}&nbsp;{vm.translateGeneral("low_tariff_abbr")}
                            </>
                          ) : (
                            <>
                              {vm.localization.formatNumber(vm.lastValue)}&nbsp;{getCommodityUnit(vm.commodityType)}&nbsp;
                            </>
                          )}
                        </>
                      )}
                    </strong>
                  </Form.Label>
                </Form.Group>
                <Form.Group as={Col} xs={8}>
                  <Form.Label>{vm.translateAttribute("reading_reason")}</Form.Label>
                  <Select
                    target={vm.data}
                    property="readingFormReasonId"
                    items={vm.readingFormReasons}
                    mode="key"
                    keyProperty="id"
                    textProperty="name"
                  />
                  <span className="self-reading__hints-link" onClick={vm.toggleHintsVisible}>
                    {vm.translate("self_reading_reasons_hints")}
                  </span>
                </Form.Group>
                <Form.Group as={Col} xs={8}>
                  <Form.Label>{vm.translate("to_date")}</Form.Label>
                  <DatePicker target={vm.data} property="date" maxDate={new Date()} />
                </Form.Group>
                <Form.Group as={Col} xs={8}>
                  <Form.Label>{vm.translateAttribute("device_id")}</Form.Label>
                  <Input target={vm.data} property="deviceId" placeholder={vm.supplyPoint.deviceId} />
                </Form.Group>
                {vm.commodityType === CommodityType.Gas && (
                  <Form.Group as={Col} xs={8}>
                    <Form.Label>
                      {vm.translate("state_gas")} ({getCommodityUnit(vm.commodityType)})
                    </Form.Label>
                    <Input target={vm.data} property="value" />
                  </Form.Group>
                )}
                {vm.commodityType === CommodityType.Electricity && (
                  <>
                    <Form.Group as={Col} xs={8}>
                      <Form.Label>{vm.translate("state_high_tariff")}</Form.Label>
                      <Input target={vm.data} property="value" />
                    </Form.Group>

                    {vm.isDoubleTariff && (
                      <Form.Group as={Col} xs={8}>
                        <Form.Label>{vm.translate("state_low_tariff")}</Form.Label>
                        <Input target={vm.data} property="valueNt" />
                      </Form.Group>
                    )}
                  </>
                )}
                {vm.errorMessage && (
                  <Form.Control.Feedback style={{ textAlign: "center", whiteSpace: "pre-line" }} type="invalid">
                    {vm.errorMessage}
                  </Form.Control.Feedback>
                )}
              </Form.Row>
            </Form>
            <div className="modal-footer px-0 pb-0">
              <Button variant="primary" className="order-2" disabled={!vm.canConfirm} onClick={vm.confirmReading}>
                {vm.localization.translateGeneral("general.send_button")}
              </Button>
              <Button variant="secondary" className="order-1" onClick={vm.cancel}>
                {vm.localization.translateGeneral("general.cancel_button")}
              </Button>
            </div>
          </Card.Body>
        )}
      </LoaderOverlay>
    </Card>

    <Modal centered show={vm.areHintsVisible} onHide={vm.toggleHintsVisible} className="admin-modal">
      <Modal.Header closeButton />
      <Modal.Body>
        <div className="self-reading__hints-body" dangerouslySetInnerHTML={{ __html: vm.translate("self_reading_hints_body") }} />
      </Modal.Body>
    </Modal>
  </>
));

registerView(singleSelfReadingView, SingleSelfReadingViewModel);
