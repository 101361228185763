import { Expose, Transform } from "class-transformer";
import formatISO from "date-fns/formatISO";
import { observable } from "mobx";
import { entityToFormData } from "repositories/helpers";
import { EntityValidationRules } from "services/validation/entityValidationRules";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
class ReqAddPartner {
  @observable
  @Expose({ name: "customer_number" })
  customerNumber!: number;

  @observable
  @Expose({ name: "account_number" })
  accountNumber!: number;

  /**
   * Datum narození v případě fyzické osoby
   */
  @observable
  @Transform(value => (value ? new Date(value) : undefined), { toClassOnly: true })
  @Transform(value => (value ? formatISO(value, { representation: "date" }) : undefined), { toPlainOnly: true })
  @Expose({ name: "birth_date" })
  birthDate?: Date;

  /**
   * IČO v případě společnosti
   */
  @observable
  @Expose({ name: "org_id" })
  orgId?: string;

  @observable
  @Expose({ name: "file[]" })
  file?: Blob[];

  static ValidationRules = {
    customerNumber: { required: true, number: true },
    accountNumber: { required: true, number: true },
    birthDate: { date: true },
  };
}

export default class CustomReqAddPartner extends ReqAddPartner {
  static customValidationRules: EntityValidationRules<CustomReqAddPartner> = {
    customerNumber: { required: true, number: true },
    accountNumber: { required: true, number: true },
    birthDate: { date: true },
    orgId: { isIco: true },
  };

  toFormData() {
    const data = entityToFormData(this);

    // generic entityToFomData is creating "[object OBJECT]" string instead of file
    data.delete("file[]");
    if (this.file && this.file.length > 0) {
      this.file.forEach(item => data.append("file[]", item));
    }

    return data;
  }
}
