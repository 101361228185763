import { ProductCard } from "@emanprague/ppaskit";
import ProductCardPreview from "entities/productCardPreview";
import { openInNewTab } from "helpers/utils";
import React, { ReactNode } from "react";

export interface ProductCardListProps {
  productCards: ProductCardPreview[];
}

const ProductCardList: React.FunctionComponent<ProductCardListProps> = ({ productCards }) => {
  const cards: ReactNode[] = [];
  productCards
    .sort((a, b) => a.order - b.order)
    .forEach((card, index) => {
      cards.push(
        <ProductCard
          title={card.title}
          body={card.text}
          buttonLabel={card.buttonText}
          onClick={() => openInNewTab(card.buttonUrl!)}
          key={`card${index}`}
        />
      );
    });

  return <div className="products_container mb-3">{cards}</div>;
};

export default ProductCardList;
