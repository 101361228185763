import React from "react";

export interface EmptyDataMessageProps {
  hasFilter?: boolean;
  translateGeneral: (code: string) => string;
}

const EmptyDataMessage: React.FunctionComponent<EmptyDataMessageProps> = ({ hasFilter, translateGeneral }) => (
  <div className="text-center mt-5">{translateGeneral(hasFilter ? "general.no_data_filtered" : "general.no_data")}</div>
);

export default EmptyDataMessage;
