import { registerView, ViewComponent, View } from "@frui.ts/views";
import { observer, Observer } from "mobx-react-lite";
import React from "react";
import CustomerInfoDetailPageViewModel from "viewModels/products/myProducts/detailPages/customerInfo/customerInfoDetailPageViewModel";
import { Row, Col, Button, HeaderRow, Card } from "@emanprague/ppaskit";
import iconEdit from "@emanprague/ppaskit/dist/assets/icons/Edit.svg";
import iconIndicatorGreen from "@emanprague/ppaskit/dist/assets/icons/Indicator Green.svg";
import iconIndicatorBlue from "@emanprague/ppaskit/dist/assets/icons/Indicator Blue.svg";
import { addressToHuman } from "helpers/utils";

const customerInfoDetailListView: ViewComponent<CustomerInfoDetailPageViewModel> = observer(({ vm }) => {
  return (
    <div className="mb-4">
      <HeaderRow level={2} title={vm.name} />
      <Card>
        <Card.Body>
          <Row>
            <Col xs={12} md={6}>
              <HeaderRow level={3} title={vm.translate("title")}>
                <Button variant="secondary" size="sm" onClick={vm.activateEditCustomerInfoModal}>
                  <img src={iconEdit} width={16} height={16} alt="" className="mr-2" />
                  {vm.translateGeneral("edit_button")}
                </Button>
              </HeaderRow>
              <table className="table wider-row mb-5 mb-md-0">
                <tbody>
                  <tr>
                    <th>{vm.translate("customer")}</th>
                    <td>{vm.activePartnerFullName}</td>
                  </tr>
                  <tr>
                    <th>{vm.translate("number_customer_account")}</th>
                    <td>{vm.account?.externalId}</td>
                  </tr>
                  <tr>
                    <th>{vm.translate("name_customer_account")}</th>
                    <td>{vm.account?.name}</td>
                  </tr>
                  <tr>
                    <th>{vm.translate("state_customer_account")}</th>
                    <td>
                      {vm.account?.active ? (
                        <>
                          <img className="pr-2" src={iconIndicatorGreen} alt="" />
                          <span className="text-success">{vm.translateGeneral("active")}</span>
                        </>
                      ) : (
                        <span>
                          <img className="pr-2" src={iconIndicatorBlue} alt="" />
                          <span className="text-info">{vm.translateGeneral("inactive")}</span>
                        </span>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>{vm.translate("customer_number")}</th>
                    <td>{vm.partner?.externalId}</td>
                  </tr>
                  {vm.partner?.orgId && (
                    <tr>
                      <th>{vm.localization.translateAttribute("partner", "org_id")}</th>
                      <td>{vm.partner?.orgId}</td>
                    </tr>
                  )}
                  {vm.partner?.vatId && (
                    <tr>
                      <th>{vm.localization.translateAttribute("partner", "vat_id")}</th>
                      <td>{vm.partner?.vatId}</td>
                    </tr>
                  )}
                </tbody>
              </table>

              {vm.partner?.marketingAgreementVisible && (
                <>
                  <HeaderRow level={3} title={vm.translate("agreements")}>
                    <Button variant="secondary" size="sm" onClick={vm.activateEditMarketingAgreementModal}>
                      <img src={iconEdit} width={16} height={16} alt="" className="mr-2" />
                      {vm.translateGeneral("manage_button")}
                    </Button>
                  </HeaderRow>
                  <p className="font-size-sm mb-5 mb-md-0">
                    {vm.translate(vm.partner?.marketingAgreement ? "accepted_agreement" : "not_accepted_agreement")}
                  </p>
                </>
              )}
            </Col>
            <Col xs={12} md={6}>
              <HeaderRow level={3} title={vm.translate("contact_info")}>
                <Button variant="secondary" size="sm" onClick={vm.activateEditContactInfoModal}>
                  <img src={iconEdit} width={16} height={16} alt="" className="mr-2" />
                  {vm.translateGeneral("edit_button")}
                </Button>
              </HeaderRow>
              <table className="table aligned wider-row mb-5 mb-md-0">
                <tbody>
                  <tr>
                    <th>{vm.localization.translateAttribute("user", "email")}</th>
                    <td>{vm.partner?.email}</td>
                  </tr>
                  <tr>
                    <th>{vm.localization.translateAttribute("user", "phone")}</th>
                    <td>{vm.partner?.phone}</td>
                  </tr>
                  <tr>
                    <th>{vm.translate(vm.isCompany ? "company_address" : "permanent_address")}</th>
                    <td>{addressToHuman(vm.partner?.permanentAddress)}</td>
                  </tr>
                </tbody>
              </table>

              <HeaderRow level={3} title={vm.translate("mailing_address")}>
                <Button variant="secondary" size="sm" onClick={vm.activateEditMailingAddressModal}>
                  <img src={iconEdit} width={16} height={16} alt="" className="mr-2" />
                  {vm.translateGeneral("edit_button")}
                </Button>
              </HeaderRow>
              <p className="font-size-sm">{addressToHuman(vm.addressByType("postal"))}</p>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Observer>{() => <View vm={vm.activeChild} />}</Observer>
    </div>
  );
});

registerView(customerInfoDetailListView, CustomerInfoDetailPageViewModel);
