import { IEventBus, ILocalizationService } from "@emanprague/shared-services";
import type { IDisposable } from "@frui.ts/helpers";
import { ConductorOneChildActive, Router } from "@frui.ts/screens";
import SupplyPointListItem from "entities/supplyPointListItem";
import type { interfaces } from "inversify";
import { action, observable, runInAction } from "mobx";
import ProductDetailContext from "models/productDetailContext";
import SupplyPointsRepository from "repositories/supplyPointsRepository";
import DataSyncService from "services/dataSyncService";
import EnumsService from "services/enumsService";
import { DataEvents } from "services/events";
import type { IDetailPage } from "viewModels/types";
import AdvancesDetailPageViewModel from "./detailPages/advancesDetailPageViewModel";
import ContractDetailPageViewModel from "./detailPages/contract/contractDetailPageViewModel";
import CustomerInfoDetailPageViewModel from "./detailPages/customerInfo/customerInfoDetailPageViewModel";
import HistoryDetailPageViewModel from "./detailPages/history/historyDetailPageViewModel";
import InvoicesDetailPageViewModel from "./detailPages/invoicesDetailPageViewModel";
import OptionsDepositDetailPageViewModel from "./detailPages/optionsDeposit/optionsDepositDetailPageViewModel";
import PaymentsDetailPageViewModel from "./detailPages/paymentsDetailPageViewModel";
import SupplyPointDetailPageViewModel from "./detailPages/supplyPoint/supplyPointDetailPageViewModel";

// this route enables generating URLs like `router.getUrl(ProductDetailViewModel, { supplyPointId: 999 })`
@Router.registerRoute({
  name: Router.Self,
  route: ":supplyPointId",
  children: [
    SupplyPointDetailPageViewModel,
    AdvancesDetailPageViewModel,
    InvoicesDetailPageViewModel,
    PaymentsDetailPageViewModel,
    HistoryDetailPageViewModel,
    OptionsDepositDetailPageViewModel,
    CustomerInfoDetailPageViewModel,
    ContractDetailPageViewModel,
  ],
})
export default class ProductDetailViewModel extends ConductorOneChildActive<IDetailPage> {
  orderIndex = 0;
  @observable productDetailContext: ProductDetailContext;

  private eventHandlers: IDisposable[];

  constructor(
    public supplyPointCore: SupplyPointListItem,
    partnerId: number,
    private dataService: DataSyncService,
    private supplyPointsRepository: SupplyPointsRepository,
    private enumsService: EnumsService,
    public router: Router,
    public localization: ILocalizationService,
    eventBus: IEventBus,
    detailPages: IDetailPage[]
  ) {
    super();

    this.navigationName = supplyPointCore.id.toString();

    this.productDetailContext = new ProductDetailContext(supplyPointCore, partnerId);
    const commodityType = this.enumsService.getCommodityType(supplyPointCore.commodityId);
    if (commodityType) {
      this.productDetailContext.commodityType = commodityType;
    }

    this.setDetailPages(detailPages);
    this.eventHandlers = [eventBus.subscribe(DataEvents.supplyPointsUpdated, this.loadDetail)];
  }

  protected onInitialize() {
    return this.loadDetail();
  }

  protected async onDeactivate(isClosing: boolean) {
    await super.onDeactivate(isClosing);

    if (isClosing) {
      this.eventHandlers?.forEach(x => x.dispose());
    }
  }

  @action.bound
  public async loadDetail() {
    const payload = await this.supplyPointsRepository.getSupplyPoint(
      this.productDetailContext.partnerId,
      this.productDetailContext.supplyPointId
    );

    if (payload.success) {
      runInAction(() => {
        this.productDetailContext.supplyPoint = payload.payload;
      });
    }
  }

  @action
  private setDetailPages(detailPages: IDetailPage[]) {
    detailPages.sort((a, b) => a.orderIndex - b.orderIndex);

    // a hack to hide a single page if its disabled in settings
    // parent is not initialized in time in those VMs to be able to set their own "disabled" flag
    const supplyPoint = this.supplyPointCore;
    const showDeposits = !!supplyPoint?.settings.show_deposits;
    const filteredPages = detailPages.filter(item => item.navigationName !== "advances" || showDeposits);

    this.children.push(...filteredPages);
  }

  protected findNavigationChild(navigationName: string | undefined) {
    // set current child to the first one if none is specified
    return navigationName ? super.findNavigationChild(navigationName) : this.children[0];
  }

  static Factory({ container }: interfaces.Context) {
    return (supplyPointCore: SupplyPointListItem, partnerId: number) => {
      return new ProductDetailViewModel(
        supplyPointCore,
        partnerId,
        container.get(DataSyncService),
        container.get(SupplyPointsRepository),
        container.get(EnumsService),
        container.get(Router),
        container.get("ILocalizationService"),
        container.get("IEventBus"),
        container.getAll("IDetailPage")
      );
    };
  }
}
