import { BusyWatcher, ScreenBase, watchBusy } from "@frui.ts/screens";
import { ILocalizationService, INotificationService, SeverityLevel } from "@emanprague/shared-services";
import { interfaces } from "inversify";
import { bound } from "@frui.ts/helpers";
import { action, observable, runInAction } from "mobx";
import { attachAutomaticValidator, hasVisibleErrors, validate } from "@frui.ts/validation";
import type BannerListItemType from "entities/bannerListItemType";
import AdminRepository from "repositories/adminRepository";
import { unwrapErrorMessage } from "repositories/helpers";
import ReqBanner from "entities/reqBanner";
import RspBanner from "entities/rspBanner";
import BannerButton from "entities/bannerButton";
import { assignTargetPropertiesOnly } from "helpers/utils";

const navigationName = "new";

export default class BannerDetailViewModel extends ScreenBase {
  static navigationName = navigationName;

  navigationName = navigationName;
  busyWatcher = new BusyWatcher();
  maxLinksCount = 3;

  @observable data: ReqBanner;
  @observable bannerDetail: RspBanner;
  @observable isNewBanner = true;
  @observable errorMessage?: string;
  @observable fileSelectError?: string;
  @observable isUploadValid = true;

  constructor(
    public bannerId: number | undefined,
    private refreshDataCallback: () => void,
    public localization: ILocalizationService,
    private adminRepository: AdminRepository,
    private notificationService: INotificationService
  ) {
    super();

    this.name = this.translate("title");

    if (bannerId) {
      this.navigationName = "/detail/" + String(bannerId);
      this.isNewBanner = false;
    }
  }

  protected onInitialize(): Promise<any> | void {
    this.loadData();
  }

  @watchBusy
  async loadData() {
    if (this.bannerId) {
      const response = await this.adminRepository.getBannerDetail(this.bannerId);
      if (response.success) {
        runInAction(() => (this.bannerDetail = response.payload));
      }
    }

    this.initializeValues();
  }

  @action.bound
  initializeValues(type: BannerListItemType = "cross_sell") {
    this.data = new ReqBanner();

    if (this.bannerDetail) {
      const plainLinks = this.bannerDetail.links.map(item => ({ url: item.url, text: item.text }));
      assignTargetPropertiesOnly(this.data, this.bannerDetail);
      this.data.links = plainLinks;
      this.data.file = undefined;
    } else {
      this.data.type = type;
      this.data.links = [];
      this.addNewButton();
    }

    this.isUploadValid = true;
    this.data.links.forEach((item: BannerButton) => attachAutomaticValidator(item, BannerButton.ValidationRules));
    attachAutomaticValidator(this.data, ReqBanner.ValidationRules);
  }

  @action.bound
  addNewButton() {
    const newButton = new BannerButton();
    this.data.links.push(newButton);
    attachAutomaticValidator(newButton, BannerButton.ValidationRules);
  }

  @action.bound
  validateRequest() {
    if (this.data.type !== "cross_sell" && this.isNewBanner) {
      this.isUploadValid = !!this.data.file;
    }

    this.data.links.forEach((item: BannerButton) => validate(item));
    validate(this.data);
  }

  @action.bound
  clearDates() {
    this.data.validFrom = undefined;
    this.data.validTo = undefined;
  }

  @action.bound
  @watchBusy
  async confirmRequest() {
    this.errorMessage = undefined;
    this.fileSelectError = undefined;

    this.validateRequest();
    if (!this.canConfirm) {
      return;
    }

    const response = this.isNewBanner
      ? await this.adminRepository.addBanner(this.data)
      : await this.adminRepository.updateBanner(this.bannerId!, this.data);
    if (response.success) {
      this.notificationService.addNotification(this.translateGeneral("request_sent_success"), SeverityLevel.success);
      this.refreshDataCallback();
      this.requestClose();
    } else {
      runInAction(() => (this.errorMessage = unwrapErrorMessage(response.payload)));
    }
  }

  @action.bound
  onChangeType(type: BannerListItemType) {
    this.initializeValues(type);
  }

  @action.bound
  onDrop(files: File[]) {
    if (files[0]) {
      this.data.file = files[0];
      this.isUploadValid = true;
      this.fileSelectError = undefined;
    }
  }

  @action.bound
  onDropRejected() {
    this.fileSelectError = this.translateGeneral("upload_invalid_files");
  }

  get canConfirm() {
    const areButtonsValid = this.data.links.every(x => !hasVisibleErrors(x));
    return !hasVisibleErrors(this.data) && this.isUploadValid && areButtonsValid;
  }

  get buttonsCount() {
    return this.data.links.length;
  }

  get uploaderErrorMessage() {
    if (this.fileSelectError) {
      return this.fileSelectError;
    }
    return !this.isUploadValid ? this.localization.translateGeneral("validators.required_file") : undefined;
  }

  get bannerTypes() {
    const types: { id: BannerListItemType; name: string }[] = [
      { id: "cross_sell", name: this.translate("cross_sell_type") },
      { id: "large", name: this.translate("large_type") },
      { id: "small", name: this.translate("small_type") },
    ];
    return types;
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`admin.banners.detail.${key}`);
  }

  @bound translateConditions(key: string) {
    return this.localization.translateGeneral(`admin.display_condition.${key}`);
  }

  @bound translateGeneral(key: string) {
    return this.localization.translateGeneral(`general.${key}`);
  }

  static Factory({ container }: interfaces.Context) {
    return (bannerId: number | undefined, refreshDataCallback: () => void) => {
      return new BannerDetailViewModel(
        bannerId,
        refreshDataCallback,
        container.get("ILocalizationService"),
        container.get(AdminRepository),
        container.get("INotificationService")
      );
    };
  }
}
