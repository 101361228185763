import Account from "entities/account";
import ReqMktAgreement from "entities/reqMktAgreement";
import { RequestsEvents } from "services/events";
import RepositoryBase from "./repositoryBase";

export default class AccountsRepository extends RepositoryBase {
  getAccounts(partnerId: number) {
    return this.callApi(api => api.all(`partners/${partnerId}/accounts`).getEntities(Account));
  }

  setMarketingAgreement(partnerId: number, userData: ReqMktAgreement) {
    const promise = this.callApi(
      api => api.all(`partners/${partnerId}/mkt_agreement`).patchEntity(userData, ReqMktAgreement),
      false
    );
    promise.then(() => this.eventBus.publish(RequestsEvents.requestCreated(userData)));

    return promise;
  }
}
