import { SeverityLevel } from "@emanprague/shared-services";

export default class ToastViewModel {
  constructor(public key: string, public message: string, public severity: SeverityLevel, public onClose?: () => void) {}

  get variant() {
    switch (this.severity) {
      case SeverityLevel.critical:
        return "danger";

      case SeverityLevel.important:
        return "warning";

      case SeverityLevel.success:
        return "success";

      case SeverityLevel.information:
      default:
        return "info";
    }
  }
}
