import { BusyWatcher, ScreenBase, watchBusy } from "@frui.ts/screens";
import ProductDetailContext from "models/productDetailContext";
import { ILocalizationService, INotificationService, SeverityLevel } from "@emanprague/shared-services";
import { interfaces } from "inversify";
import { bound } from "@frui.ts/helpers";
import { action, computed, observable, runInAction } from "mobx";
import ReqChangeInvoiceSentMethod from "entities/reqChangeInvoiceSentMethod";
import { attachAutomaticValidator, validate } from "@frui.ts/validation";
import SupplyPointsRepository from "repositories/supplyPointsRepository";
import { unwrapErrorMessage } from "repositories/helpers";
import EnumsService from "services/enumsService";
import { createSupplyPointsWithIcons, SupplyPointError } from "helpers/supplyPointHelper";
import DataSyncService from "services/dataSyncService";
import AddressChangeViewModel from "viewModels/addressChangeViewModel";
import Address from "entities/address";

export default class ModalInvoiceMailingMethodViewModel extends ScreenBase {
  static navigationName = "invoice_mailing";

  navigationName = ModalInvoiceMailingMethodViewModel.navigationName;
  busyWatcher = new BusyWatcher();

  @observable errorMessage?: string;
  @observable data: ReqChangeInvoiceSentMethod;
  @observable supplyPointsErrors: SupplyPointError[] = [];
  @observable addressChangeVM: AddressChangeViewModel;
  @observable isChangePossible: boolean;

  constructor(
    private productDetailContext: ProductDetailContext,
    public localization: ILocalizationService,
    private supplyPointRepository: SupplyPointsRepository,
    private enumsService: EnumsService,
    private dataService: DataSyncService,
    private notificationService: INotificationService,
    address: Address,
    isChangePossible: boolean,
    addressChangeViewModelFactory: ReturnType<typeof AddressChangeViewModel.Factory>
  ) {
    super();

    const account = productDetailContext.supplyPoint?.account;
    if (!account) {
      throw new Error("Supply point detail is not loaded");
    }

    this.isChangePossible = isChangePossible;

    this.data = new ReqChangeInvoiceSentMethod();
    this.data.type = account.invoiceSentMethod;
    this.data.email = account.invoiceSentMethodEmail;
    this.data.supplyPointIds = this.currentSupplyPoints.map(i => i.id);

    attachAutomaticValidator(this.data, ReqChangeInvoiceSentMethod.ValidationRules);

    this.name = this.translate("invoice_mailing_method");
    this.addressChangeVM = addressChangeViewModelFactory(address, this.data, this.productDetailContext.supplyPoint);
  }

  @action.bound
  @watchBusy
  async confirmChange() {
    this.errorMessage = undefined;
    this.supplyPointsErrors = [];

    if (!validate(this.data)) {
      return;
    }

    const response = await this.supplyPointRepository.changeInvoiceSentMethod(this.productDetailContext.partnerId, this.data);

    if (response.success) {
      runInAction(() => (this.supplyPointsErrors = response.payload.supplyPoints.filter(item => !item.status)));
      if (this.supplyPointsErrors.length === 0) {
        this.notificationService.addNotification(this.translateGeneral("request_sent_success"), SeverityLevel.success);
        this.requestClose();
      }
    } else {
      runInAction(() => (this.errorMessage = unwrapErrorMessage(response.payload)));
    }
  }

  @computed
  get currentSupplyPoints() {
    const currentAccountId = this.productDetailContext.supplyPointCore.accountId;
    return this.dataService.supplyPoints.filter(item => item.accountId === currentAccountId);
  }

  @computed
  get currentSupplyPointsWithIcons() {
    return createSupplyPointsWithIcons(this.enumsService, this.currentSupplyPoints, "grey", this.supplyPointsErrors);
  }

  @computed
  get additionalSupplyPointsWithIcons() {
    return createSupplyPointsWithIcons(
      this.enumsService,
      this.dataService.supplyPoints.filter(
        item =>
          item.account.allowToChangeSentMethod && !this.currentSupplyPoints.some(selectedItem => selectedItem.id === item.id)
      ),
      "grey",
      this.supplyPointsErrors
    );
  }

  get countries() {
    return this.enumsService.getValues("countries");
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`products.options_deposit.${key}`);
  }

  @bound translateGeneral(key: string, placeholders?: Record<string, string>) {
    return this.localization.translateGeneral(`general.${key}`, placeholders);
  }

  static Factory({ container }: interfaces.Context) {
    return (productDetailContext: ProductDetailContext, address: Address, isChangePossible: boolean) => {
      return new ModalInvoiceMailingMethodViewModel(
        productDetailContext,
        container.get("ILocalizationService"),
        container.get(SupplyPointsRepository),
        container.get(EnumsService),
        container.get(DataSyncService),
        container.get("INotificationService"),
        address,
        isChangePossible,
        container.get(AddressChangeViewModel.Factory)
      );
    };
  }
}
