import { Layout } from "@emanprague/ppaskit";
import type { ViewComponent } from "@frui.ts/views";
import { registerView, View } from "@frui.ts/views";
import PreAuthFooter from "components/PreAuthFooter";
import { NoticeBar } from "components/NoticeBar";
import { observer, Observer } from "mobx-react-lite";
import React from "react";
import AnonymousRootViewModel from "viewModels/anonymousRootViewModel";

const anonymousRootView: ViewComponent<AnonymousRootViewModel> = observer(({ vm }) => {
  return (
    <div className="loginRoot">
      {vm.loginNoticeSetting?.active && <NoticeBar content={vm.loginNoticeSetting.value} />}
      {vm.loginDisabledSetting?.active && (
        <NoticeBar color="var(--red)" showIcon={false} content={vm.loginDisabledSetting.value} textAlign="center" />
      )}
      <Observer>
        {() => (
          <div className="toast__container">
            {vm.toasts.map(item => (
              <View key={item.key} vm={item} useLifecycle />
            ))}
          </div>
        )}
      </Observer>
      <Layout fluid="md" className="layout-pre-auth">
        <View vm={vm.activeChild} />
      </Layout>
      <PreAuthFooter translateFunc={vm.localization.translateGeneral} />
    </div>
  );
});

registerView(anonymousRootView, AnonymousRootViewModel);
