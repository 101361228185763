import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { styleBlock } from "..";

export interface DateInputProps {
  value?: Date;
  onChange?: (date?: Date) => void;
  placeholder?: { day?: string; month?: string; year?: string };
  autoFocus?: boolean;
  isInvalid?: boolean;
}

const style = styleBlock("date-input");

const DateInput: React.FunctionComponent<DateInputProps> = props => {
  const [day, setDay] = useState<number | undefined>(undefined);
  const [month, setMonth] = useState<number | undefined>(undefined);
  const [year, setYear] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (props.value && isNaN(props.value.valueOf())) {
      return;
    }

    setDay(props.value?.getDate());
    setMonth(props.value ? props.value.getMonth() + 1 : undefined);
    setYear(props.value?.getFullYear());
  }, [props.value]);

  const valueChanged = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      let dayValue = day;
      let monthValue = month;
      let yearValue = year;

      const datepart = e.target.dataset.datepart;
      switch (datepart) {
        case "day":
          dayValue = Number(e.target.value);
          setDay(dayValue);
          break;
        case "month":
          monthValue = Number(e.target.value);
          setMonth(monthValue);
          break;
        case "year":
          yearValue = Number(e.target.value);
          setYear(yearValue);
          break;
      }

      if (!dayValue && !monthValue && !yearValue) {
        props.onChange?.(undefined);
        return;
      }

      const dateString = `${yearValue}/${monthValue}/${dayValue}`;
      const date = yearValue && yearValue > 1000 && monthValue && dayValue ? new Date(dateString) : new Date(NaN);
      props.onChange?.(date);
    },
    [day, month, year]
  );

  return (
    <div className={style()}>
      <Form.Control
        className={style("day").toString()}
        placeholder={props.placeholder?.day}
        type="number"
        value={day || ""}
        autoFocus={props.autoFocus}
        onChange={valueChanged}
        data-datepart="day"
        min={1}
        max={31}
        isInvalid={props.isInvalid}
      />
      <Form.Control
        className={style("month").toString()}
        placeholder={props.placeholder?.month}
        type="number"
        value={month || ""}
        onChange={valueChanged}
        data-datepart="month"
        min={1}
        max={12}
        isInvalid={props.isInvalid}
      />
      <Form.Control
        className={style("year").toString()}
        placeholder={props.placeholder?.year}
        type="number"
        value={year || ""}
        onChange={valueChanged}
        data-datepart="year"
        min={1900}
        max={2050}
        isInvalid={props.isInvalid}
      />
    </div>
  );
};

DateInput.defaultProps = {
  placeholder: { day: "Den", month: "Měsíc", year: "Rok" },
} as Partial<DateInputProps>;

export default DateInput;
