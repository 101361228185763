import { Button, HeaderRow, LoaderOverlay, SwitchNav } from "@emanprague/ppaskit";
import { preventDefault, registerView, View, ViewComponent } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import React from "react";
import AdminsPageViewModel, { AdminState } from "viewModels/admin/adminsPageViewModel";
import { Card, Col, Row } from "react-bootstrap";
import { ResponsiveTable } from "@frui.ts/dataviews";
import { createColumnsContext, DataColumn } from "controls/tableHelper";
import { Check, CollectionCheck, Input } from "@frui.ts/bootstrap";
import "./style.scss";
import iconIndicatorGreen from "@emanprague/ppaskit/dist/assets/icons/Indicator Green.svg";
import iconIndicatorGrey from "@emanprague/ppaskit/dist/assets/icons/Indicator Grey.svg";
import iconEdit from "@emanprague/ppaskit/dist/assets/icons/Edit.svg";
import FilterButton from "controls/filterButton";
import EmptyDataMessage from "components/EmptyDataMessage";
import UserListItem from "entities/userListItem";

const columns: DataColumn<UserListItem, AdminsPageViewModel>[] = [
  {
    property: "id",
    titleFactory: ({ vm, ta }) => (
      <>
        {ta("admin", "id")}
        <FilterButton
          id="idFilter"
          isActive={!!vm.filter.id}
          clearLabel={vm.translateGeneral("cancel_button")}
          confirmLabel={vm.translateGeneral("confirm")}
          onClear={() => vm.clearFilter("id")}
          onConfirm={vm.applyFilterAndLoad}>
          <Input target={vm.filter} property="id" placeholder={vm.translateGeneral("search")} />
        </FilterButton>
      </>
    ),
    responsiveTitleFactory: ({ ta }) => ta("admin", "id"),
  },
  {
    titleFactory: ({ vm, ta }) => (
      <>
        {ta("admin", "first_name")}
        <FilterButton
          id="nameFilter"
          isActive={!!vm.filter.firstName || !!vm.filter.lastName}
          clearLabel={vm.translateGeneral("cancel_button")}
          confirmLabel={vm.translateGeneral("confirm")}
          onClear={() => vm.clearFilter("firstName", "lastName")}
          onConfirm={vm.applyFilterAndLoad}>
          <Input target={vm.filter} property="firstName" placeholder={ta("admin", "first_name")} className="mb-2" />
          <Input target={vm.filter} property="lastName" placeholder={ta("admin", "last_name")} />
        </FilterButton>
      </>
    ),
    valueFormatter: ({ item }) => <span className="text-nowrap">{`${item.firstName} ${item.lastName}`}</span>,
    responsiveTitleFactory: ({ ta }) => ta("admin", "first_name"),
  },
  {
    property: "email",
    titleFactory: ({ vm, ta }) => (
      <>
        {ta("admin", "email")}
        <FilterButton
          id="emailFilter"
          isActive={!!vm.filter.email}
          clearLabel={vm.translateGeneral("cancel_button")}
          confirmLabel={vm.translateGeneral("confirm")}
          onClear={() => vm.clearFilter("email")}
          onConfirm={vm.applyFilterAndLoad}>
          <Input target={vm.filter} property="email" placeholder={vm.translateGeneral("search")} />
        </FilterButton>
      </>
    ),
    responsiveTitleFactory: ({ ta }) => ta("admin", "email"),
  },
  {
    property: "role",
    titleFactory: ({ vm, ta }) => (
      <>
        {ta("admin", "role")}
        <FilterButton
          id="roleFilter"
          isActive={vm.selectedRoles.length > 0}
          clearLabel={vm.translateGeneral("cancel_button")}
          confirmLabel={vm.translateGeneral("confirm")}
          onClear={() => vm.clearFilter("role")}
          onConfirm={vm.applyFilterAndLoad}>
          {vm.userRoles.map((item, index) => (
            <CollectionCheck
              custom
              key={index}
              target={vm}
              property="selectedRoles"
              value={item.id}
              label={item.name}
              onValueChanged={vm.applyRolesFilter}
              className="mt-1"
            />
          ))}
        </FilterButton>
      </>
    ),
    valueFormatter: ({ context, item }) => context.vm.translate("role." + item.role),
  },
  {
    property: "active",
    titleFactory: ({ vm, ta }) => (
      <>
        {ta("admin", "state")}
        <FilterButton
          id="stateFilter"
          isActive={vm.selectedAdminStateId !== "all"}
          clearLabel={vm.translateGeneral("cancel_button")}
          confirmLabel={vm.translateGeneral("confirm")}
          onClear={() => vm.clearFilter("status")}
          onConfirm={vm.applyFilterAndLoad}>
          {vm.adminStates.map((item, index) => (
            <Check
              custom
              key={index}
              target={vm}
              property="selectedAdminStateId"
              value={item.id}
              label={item.name}
              className="mt-1"
              type="radio"
              onValueChanged={(state: AdminState) => vm.applyAdminStateFilter(state)}
            />
          ))}
        </FilterButton>
      </>
    ),
    valueFormatter: ({ value, context }) => (
      <>
        {value ? (
          <span className="text-success">
            <img className="pr-2 pb-1" src={iconIndicatorGreen} alt="" />
            {context.vm.translateGeneral("active")}
          </span>
        ) : (
          <span className="text-muted">
            <img className="pr-2 pb-1" src={iconIndicatorGrey} alt="" />
            {context.vm.translateGeneral("inactive")}
          </span>
        )}
      </>
    ),
    responsiveTitleFactory: ({ ta }) => ta("admin", "state"),
  },
  {
    valueFormatter: ({ item, context }) => (
      <Button size="sm" variant="secondary" className="btn-responsive-block" onClick={() => context.vm.openDetail(item)}>
        <img src={iconEdit} width={16} height={16} alt="" className="mr-2" />
        {context.vm.translateGeneral("edit_button")}
      </Button>
    ),
    responsiveTitle: false,
    cellClassName: "cell-right",
  },
];

const adminsPageView: ViewComponent<AdminsPageViewModel> = observer(({ vm }) => {
  const { translateGeneral: tg } = vm.localization;
  const navLinks = [
    {
      id: "all",
      title: `${vm.translate("state_all")}`,
      href: "#",
      onClick: preventDefault(() => vm.applyAdminStateFilter("all")),
    },
    {
      id: "active",
      title: `${vm.translate("state_active")}`,
      href: "#",
      onClick: preventDefault(() => vm.applyAdminStateFilter("active")),
    },
    {
      id: "inactive",
      title: `${vm.translate("state_inactive")}`,
      href: "#",
      onClick: preventDefault(() => vm.applyAdminStateFilter("inactive")),
    },
  ];

  return (
    <>
      <HeaderRow level={2} title={vm.name} className="mx-1 mx-sm-n3">
        <Button variant="primary" onClick={vm.addAdmin}>
          {vm.translate("button_create_admin")}
        </Button>
      </HeaderRow>
      <Row className="mt-4">
        <Col xs={12}>
          <Card>
            <Card.Body>
              <LoaderOverlay loading={vm.busyWatcher.isBusy}>
                <Row className="mb-3 justify-content-between align-items-center">
                  <Col xs={12} md={6} lg="auto" className="py-2 mb-2 mb-md-0">
                    <SwitchNav links={navLinks} size="sm" activeId={vm.switchStateActiveId} />
                  </Col>
                  <Col xs={12} md={5} className="py-2">
                    <Input
                      size="sm"
                      target={vm.filter}
                      property="search"
                      placeholder={vm.translate("search_placeholder")}
                      onValueChanged={vm.applyFilterAndLoadDebounced}
                    />
                  </Col>
                </Row>

                <div className="responsive-table-wrapper">
                  <ResponsiveTable
                    className="table no-wrap"
                    items={vm.items}
                    itemKey="id"
                    columns={columns}
                    context={createColumnsContext(vm)}
                  />
                </div>
                {!vm.items?.length && <EmptyDataMessage translateGeneral={tg} />}

                {vm.canLoadData && (
                  <div className="load-more-button">
                    <Button onClick={vm.loadData} variant="secondary">
                      {tg("general.button_load_more")}
                    </Button>
                  </div>
                )}

                <View vm={vm.activeChild} />
              </LoaderOverlay>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
});

registerView(adminsPageView, AdminsPageViewModel);
