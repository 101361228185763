import Address from "entities/address";
import EnumsService from "services/enumsService";
import Partner from "entities/partner";
import PartnerListItem from "entities/partnerListItem";
import assign from "lodash/assign";
import keys from "lodash/keys";
import pick from "lodash/pick";

export const openInNewTab = (href: string) => {
  Object.assign(document.createElement("a"), {
    target: "_blank",
    rel: "noopener noreferrer",
    href,
  }).click();
};

export const createURLQuery = (object: any) => {
  return new URLSearchParams(object).toString();
};

// TODO remove when this function is available in frui.ts
export function groupBy<TKey, TItem>(collection: TItem[], selector: (item: TItem) => TKey): Map<TKey, TItem[]> {
  const result = new Map<TKey, TItem[]>();

  for (const item of collection) {
    const key = selector(item);
    const bucket = result.get(key);
    if (bucket) {
      bucket.push(item);
    } else {
      result.set(key, [item]);
    }
  }

  return result;
}

export const addressToHuman = (address: Address | undefined): string => {
  if (!address) {
    return "";
  }

  const streetNumber = address.streetNumber ? `/${address.streetNumber}` : "";

  return `${address.street} ${address.houseNumber ?? ""}${streetNumber}, ${address.city}, ${address.zip}`;
};

export const partnerToFullName = (enums: EnumsService, partner?: Partner | PartnerListItem): string => {
  const titlesBefore = enums.getValues("titlesBefore").filter(x => x.active);
  const titlesAfter = enums.getValues("titlesAfter").filter(x => x.active);

  const titleBefore1 = titlesBefore.find(item => item.id === partner?.titleBeforeId)?.name;
  const titleBefore2 = titlesBefore.find(item => item.id === partner?.titleBefore2Id)?.name;
  const titleAfter = titlesAfter.find(item => item.id === partner?.titleAfterId)?.name;

  return (
    (titleBefore1 ? titleBefore1 + " " : "") +
    (titleBefore2 ? titleBefore2 + " " : "") +
    (partner?.firstName ? partner.firstName + " " : "") +
    (partner?.lastName ? partner.lastName + " " : "") +
    (titleAfter ?? "")
  );
};

export const getCzechRepublicCountryId = (enums: EnumsService) => {
  return enums.getValues("countries").find(item => item.name === "Česká republika")?.id;
};

export const formatRange = (value1?: string, value2?: string) => {
  return `${value1}${value1 && value2 && " - "}${value2}`;
};

export const formatBytesToMB = (bytes: number) => {
  const convertedToMB = (bytes / (1024 * 1024)).toFixed(2);
  return `${convertedToMB} MB`;
};

export const convertKilobytesToBytes = (kiloBytes?: number) => {
  return kiloBytes ? kiloBytes * 1024 : undefined;
};

export const assignTargetPropertiesOnly = (target: any, source: any) => {
  return assign(target, pick(source, keys(target)));
};
