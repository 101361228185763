import { observable } from "mobx";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class PaymentGateway {
  /**
   * URL to redirect
   * @example
   * http://comgate.com/payment?id=0930094004
   */
  @observable
  url!: string;

  static ValidationRules = {
    url: { required: true },
  };
}
