import { ILocalizationService } from "@emanprague/shared-services";
import { BusyWatcher, Router, watchBusy } from "@frui.ts/screens";
import { IModule } from "viewModels/types";
import { action, observable, runInAction } from "mobx";
import ContinuousListViewModelBase from "../continuousListViewModelBase";
import MessagesFilter from "models/messagesFilter";
import { bound } from "@frui.ts/helpers";
import MessagesService from "services/messagesService";
import UserContext from "services/userContext";
import PartnerMessageListItem from "entities/partnerMessageListItem";
import MessagesRepository from "repositories/messagesRepository";
import MessagePreview from "entities/messagePreview";

const navigationName = "messages";

@Router.registerRoute({ name: Router.Self, route: navigationName })
export default class OverviewViewModel
  extends ContinuousListViewModelBase<PartnerMessageListItem, MessagesFilter>
  implements IModule
{
  orderIndex = 5;
  hideInMenu = true;
  navigationName = navigationName;
  busyWatcher = new BusyWatcher();

  @observable messageDetail?: MessagePreview;

  constructor(
    public localization: ILocalizationService,
    private messagesService: MessagesService,
    public userContext: UserContext,
    private repository: MessagesRepository
  ) {
    super();

    this.name = this.translate("title");
  }

  navigate(subPath: string | undefined, params: { message?: PartnerMessageListItem }): Promise<void> {
    if (params.message) {
      return this.showDetail(params.message);
    }
    return super.navigate(subPath, params);
  }

  protected onInitialize(): Promise<any> | void {
    this.loadData();
  }

  @watchBusy
  async loadData() {
    const partnerId = this.userContext.activePartnerId;
    if (!partnerId) {
      return;
    }

    const response = await this.repository.getMessages(partnerId, this.pagingFilter, this.filter);
    if (response.success) {
      this.setData(response.payload);
    }
  }

  @action.bound
  hideDetail() {
    this.messageDetail = undefined;
  }

  @bound
  @watchBusy
  async showDetail(message: PartnerMessageListItem) {
    const partnerId = this.userContext.activePartnerId;
    if (!partnerId) {
      return;
    }

    const response = await this.messagesService.getMessageDetail(partnerId, message);
    if (response.success) {
      runInAction(() => (this.messageDetail = response.payload));
      const readMessage = this.items.find(x => x.id === this.messageDetail?.id);
      if (readMessage && !readMessage.read) {
        runInAction(() => (readMessage.read = true));
      }
    }
  }

  @action.bound
  clearFilter(...properties: (keyof MessagesFilter)[]) {
    properties.forEach(property => {
      this.filter[property] = undefined;
    });
    this.applyFilterAndLoad();
  }

  @action.bound
  applySwitchStateFilter(read: boolean) {
    this.filter.read = read;
    this.applyFilterAndLoad();
  }

  @action.bound
  setSearchValue(value: string) {
    this.filter.search = value;
    this.applyFilterAndLoadDebounced();
  }

  get switchStateActiveId() {
    if (this.filter.read === true) {
      return "read";
    } else if (this.filter.read === false) {
      return "unread";
    } else {
      return "all";
    }
  }

  @bound
  translate(key: string) {
    return this.localization.translateGeneral(`messages.${key}`);
  }

  @bound
  translateGeneral(key: string) {
    return this.localization.translateGeneral(`general.${key}`);
  }

  protected findNavigationChild(navigationName: string | undefined) {
    return undefined;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected resetFilterValues(filter: MessagesFilter) {}

  protected createFilter() {
    return new MessagesFilter();
  }
}
