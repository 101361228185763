import { registerView, ViewComponent } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import CngPageViewModel from "viewModels/products/cngPageViewModel";
import "./style.scss";
import ProductCardList from "components/ProductCardList";
import React from "react";

const cngPageListView: ViewComponent<CngPageViewModel> = observer(({ vm }) => {
  return (
    <>
      <ProductCardList productCards={vm.productCards} />
      <div className="products__footer my-5">
        {vm.translate("footer_part1")}
        <br />
        {vm.translate("footer_part2")}{" "}
        <a className="text-indigo" href={vm.translate("footer_link")} target="_blank" rel="noopener noreferrer">
          {vm.translate("footer_part3")}
        </a>
        .
      </div>
    </>
  );
});

registerView(cngPageListView, CngPageViewModel);
