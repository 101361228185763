import { observable } from "mobx";
import { Expose } from "class-transformer";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class RuianListItem {
  id!: number;

  /**
   * Číslo popisné
   * @example
   * 1685
   */
  @observable
  p?: number;

  /**
   * Číslo orientační
   * @example
   * 23
   */
  @observable
  o?: number;

  @observable
  e?: string;

  /**
   * @example
   * Toužimská
   */
  @observable
  street?: string;

  /**
   * @example
   * Plzeň
   */
  @observable
  city!: string;

  /**
   * @example
   * Bolevec
   */
  @observable
  @Expose({ name: "city_part" })
  cityPart?: string;

  /**
   * SK
   * @example
   * 30200
   */
  @observable
  zip!: number;

  /**
   * Humanized address name
   * @example
   * Toužimská 1685/23, Bolevec, Plzeň, 30200
   */
  @observable
  human?: string;

  static ValidationRules = {
    id: { required: true, number: true },
    p: { number: true },
    o: { number: true },
    city: { required: true },
    zip: { required: true, number: true },
  };
}
