import { observable } from "mobx";
import merge from "lodash/merge";
import { EntityValidationRules } from "services/validation/entityValidationRules";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class ReqTransferSupplyPointRequestLegalPerson {
  @observable
  name!: string;

  @observable
  ic!: string;

  static ValidationRules = {
    name: { required: true },
    ic: { required: true },
  };
}

merge(ReqTransferSupplyPointRequestLegalPerson.ValidationRules, {
  ic: { required: true, isIco: true },
} as EntityValidationRules<ReqTransferSupplyPointRequestLegalPerson>);
