import { Router, ScreenBase, UrlNavigationAdapter } from "@frui.ts/screens";
import { View } from "@frui.ts/views";
import { Container } from "inversify";
import { reaction } from "mobx";
import React from "react";
import ReactDOM from "react-dom";
import UserContext from "services/userContext";
import RootViewModel from "./viewModels/rootViewModel";
import AnonymousRootViewModel from "viewModels/anonymousRootViewModel";
import InitializationService from "./services/initializationService";
import AdminRootViewModel from "./viewModels/adminRootViewModel";

let urlAdapter: UrlNavigationAdapter;
let currentRootViewModel: ScreenBase;

function renderViewModel(viewModel: any) {
  ReactDOM.render(<View vm={viewModel} useLifecycle />, document.getElementById("root"));
}

async function renderRoot(newRootViewModel: ScreenBase, router?: Router) {
  await currentRootViewModel?.deactivate(true);
  urlAdapter?.stop();

  urlAdapter = new UrlNavigationAdapter();
  router?.start(newRootViewModel, urlAdapter);
  await urlAdapter.start(newRootViewModel);

  await newRootViewModel.activate();
  currentRootViewModel = newRootViewModel;
  renderViewModel(newRootViewModel);
}

async function bootApp(isLogged: boolean, isAdmin: boolean, container: Container, initializationService: InitializationService) {
  urlAdapter?.stop();

  if (!initializationService.isPreLoginInitialized) {
    initializationService.preLoginInitialize();
    renderViewModel(initializationService);
    return;
  }

  const router = container.get(Router);

  if (!isLogged) {
    if (initializationService.isPostLoginInitialized) {
      initializationService.postLogoutCleanup();
    }

    const anonymousViewModel = container.get(AnonymousRootViewModel);
    await renderRoot(anonymousViewModel, router);
    return;
  }

  if (!initializationService.isPostLoginInitialized) {
    initializationService.postLoginInitialize();
    renderViewModel(initializationService);
    return;
  }

  const rootViewModel = isAdmin ? container.get(AdminRootViewModel) : container.get(RootViewModel);
  await renderRoot(rootViewModel, router);
}

export default function runApp(container: Container) {
  const userContext = container.get<UserContext>(UserContext);
  const initializationService = container.get<InitializationService>("IInitializationService");

  reaction(
    (): [boolean, boolean, boolean, boolean] => [
      userContext.isLogged,
      userContext.isAdmin,
      initializationService.isPreLoginInitialized,
      initializationService.isPostLoginInitialized,
    ],
    ([isLogged, isAdmin]) => bootApp(isLogged, isAdmin, container, initializationService),
    { fireImmediately: true }
  );
}
