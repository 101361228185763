import type { ILocalizationService, IEventBus } from "@emanprague/shared-services";
import { BusyWatcher, ConductorOneChildActive, ScreenBase } from "@frui.ts/screens";
import { action, computed, observable } from "mobx";
import { IModule, IProductPage } from "viewModels/types";
import { IDisposable, bound } from "@frui.ts/helpers";
import UserContext from "services/userContext";
import EnumsService from "services/enumsService";
import AppendixesService from "services/appendixesService";
import { AppendixesEvents } from "services/events";

const navigationName = "products";

export default class OverviewViewModel extends ConductorOneChildActive<ScreenBase & IProductPage> implements IModule {
  orderIndex = 2;
  navigationName = navigationName;
  busyWatcher = new BusyWatcher();

  @observable isNoticeVisible = true;

  private eventHandlers: IDisposable[];

  constructor(
    private localization: ILocalizationService,
    pages: IProductPage[],
    public userContext: UserContext,
    public enumsService: EnumsService,
    private appendixService: AppendixesService,
    eventBus: IEventBus
  ) {
    super();
    this.name = this.translate("title");
    this.setPages(pages);
    this.eventHandlers = [eventBus.subscribe(AppendixesEvents.newAppendixesLoaded, () => this.handleNewAppendixes(pages))];
  }

  protected async onDeactivate(isClosing: boolean) {
    await super.onDeactivate(isClosing);

    if (isClosing) {
      this.eventHandlers?.forEach(x => x.dispose());
    }
  }

  @computed
  get notificationBadge() {
    return this.appendixService.newAppendixesCount;
  }

  @action
  public setPages(modules: IProductPage[]) {
    modules.sort((a, b) => a.orderIndex - b.orderIndex);
    const filteredModules = modules.filter(item => !item.hideInMenu);
    this.children.push(...filteredModules);
  }

  // Add or Remove a menu item for new appendixes
  @action
  public handleNewAppendixes(modules: IProductPage[]) {
    const modulePresent = this.children.find(item => item.navigationName == "offer");
    const newAppendixesPresent = this.appendixService.newAppendixesCount > 0;
    if (!newAppendixesPresent && modulePresent) {
      this.children.remove(modulePresent);
    }

    if (newAppendixesPresent && !modulePresent) {
      const offerModule = modules.find(item => item.navigationName == "offer");
      if (!offerModule) {
        return;
      }
      this.children.splice(offerModule.orderIndex, 0, offerModule);
    }
  }

  @action.bound
  handleNoticeCancel() {
    this.isNoticeVisible = false;
  }

  get noticeText() {
    return this.activeChild?.noticeText;
  }

  protected findNavigationChild(navigationName: string | undefined) {
    // set current child to the first one if none is specified
    return navigationName ? super.findNavigationChild(navigationName) : this.children[0];
  }

  @bound
  translate(key: string) {
    return this.localization.translateGeneral(`products.${key}`);
  }
}
