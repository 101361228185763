import { Expose, Transform, Type } from "class-transformer";
import formatISO from "date-fns/formatISO";
import { observable } from "mobx";
import Attachment from "./attachment";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class NewAppendixListItem {
  id!: number;

  @observable
  name!: string;

  @observable
  product!: string;

  @observable
  @Type(() => Attachment)
  attachments!: Attachment[];

  @observable
  @Transform(value => (value ? new Date(value) : undefined), { toClassOnly: true })
  @Transform(value => (value ? formatISO(value, { representation: "date" }) : undefined), { toPlainOnly: true })
  @Expose({ name: "date_to" })
  dateTo!: Date;

  static ValidationRules = {
    id: { required: true, number: true },
    name: { required: true },
    product: { required: true },
    attachments: { required: true },
    dateTo: { required: true, date: true },
  };
}
