import React from "react";
import { styleBlock } from "../";

import { Row, Col } from "react-bootstrap";

import iconIndicatorBlue from "../assets/icons/Indicator Blue.svg";

export interface MessageProps {
  date: React.ReactNode | string;
  title: React.ReactNode | string;
  isUnread: boolean;
}

export interface MessagesWidgetProps {
  className?: string;
  headerRow?: React.ReactNode | string;
  messages?: Array<MessageProps>;
  noMessagesLabel: string;
}

const MessagesWidget: React.FC<MessagesWidgetProps> = ({ className, headerRow, messages, noMessagesLabel, ...inputProps }) => {
  const b = styleBlock("messages-widget");

  function renderMessage(message: MessageProps, index: number) {
    return (
      <Row noGutters className={b("row", { unread: message.isUnread }).toString()} key={index}>
        <Col xs={12}>
          <div className={b("datetime")}>{message.date}</div>
        </Col>
        <Col xs={12}>
          {message.isUnread && <img src={iconIndicatorBlue} className={b("indicator")} alt="" />}
          <span className={b("message")}>{message.title}</span>
        </Col>
      </Row>
    );
  }

  return (
    <div className={b.toString() + " " + className}>
      {headerRow}
      <div className={b("container").toString()}>
        {messages && messages.length > 0 ? (
          messages.map((message, index) => renderMessage(message, index))
        ) : (
          <div className="text-muted">
            <hr />
            {noMessagesLabel}
          </div>
        )}
      </div>
    </div>
  );
};

export default MessagesWidget;
