import { Button, Card, Col, Graph, HeaderRow, LoaderOverlay, Row } from "@emanprague/ppaskit";
import { ReactComponent as ArrowRight } from "@emanprague/ppaskit/dist/assets/icons/Arrow/Right.svg";
import type { ViewComponent } from "@frui.ts/views";
import { registerView, View } from "@frui.ts/views";
import { getCommodityUnit } from "helpers/commodityHelper";
import { observer, Observer } from "mobx-react-lite";
import React from "react";
import HistoryDetailPageViewModel, {
  electricityDoubleTariffScope,
  singleTariffScope,
} from "viewModels/products/myProducts/detailPages/history/historyDetailPageViewModel";
import type { DataColumn } from "controls/tableHelper";
import { createColumnsContext } from "controls/tableHelper";
import type ReadingListReadingsItem from "entities/readingListReadingsItem";
import { ResponsiveTable } from "@frui.ts/dataviews";
import { ConditionalTooltip } from "components/ConditionalTooltip";

const cellRight = "cell-right";

const columns: DataColumn<ReadingListReadingsItem, HistoryDetailPageViewModel>[] = [
  {
    property: "date",
    titleFactory: ({ ta }) => ta("reading", "date"),
    valueFormatter: ({ value, context }) => context.vm.localization.formatDate(value),
  },
  {
    property: "deviceId",
    titleFactory: ({ ta }) => ta("reading", "device_id"),
  },
  {
    property: "value",
    titleFactory: ({ vm }) => (
      <>
        {vm.translate("machine_state")} ({getCommodityUnit(vm.commodityType)})
      </>
    ),
    headerClassName: cellRight,
    cellClassName: cellRight,
    scope: singleTariffScope,
  },
  {
    property: "consumption",
    titleFactory: ({ vm }) => (
      <>
        {vm.translate("consumption")} ({getCommodityUnit(vm.commodityType)})
      </>
    ),
    headerClassName: cellRight,
    cellClassName: cellRight,
    scope: singleTariffScope,
  },
  {
    property: "value",
    titleFactory: ({ vm }) => (
      <>
        {vm.translate("machine_state_vt")} ({getCommodityUnit(vm.commodityType)})
      </>
    ),
    headerClassName: cellRight,
    cellClassName: cellRight,
    scope: electricityDoubleTariffScope,
  },
  {
    property: "consumption",
    titleFactory: ({ vm }) => (
      <>
        {vm.translate("consumption_vt")} ({getCommodityUnit(vm.commodityType)})
      </>
    ),
    headerClassName: cellRight,
    cellClassName: cellRight,
    scope: electricityDoubleTariffScope,
  },
  {
    property: "valueNt",
    titleFactory: ({ vm }) => (
      <>
        {vm.translate("machine_state_nt")} ({getCommodityUnit(vm.commodityType)})
      </>
    ),
    headerClassName: cellRight,
    cellClassName: cellRight,
    scope: electricityDoubleTariffScope,
  },
  {
    property: "consumptionNt",
    titleFactory: ({ vm }) => (
      <>
        {vm.translate("consumption_nt")} ({getCommodityUnit(vm.commodityType)})
      </>
    ),
    headerClassName: cellRight,
    cellClassName: cellRight,
    scope: electricityDoubleTariffScope,
  },
  {
    property: "readingReasonId",
    titleFactory: ({ ta }) => ta("reading", "reading_type"),
    valueFormatter: ({ context, value }) => context.vm.tableViewModel.getReadingReason(value),
  },
];

const historyDetailListView: ViewComponent<HistoryDetailPageViewModel> = observer(({ vm }) => {
  const { chartViewModel: chart, tableViewModel: table, supplyPoint } = vm;
  const { new_self_reading } = supplyPoint?.settings ?? {};

  return (
    <div className="mb-4">
      {!vm.activeChild && (
        <>
          <Row className="justify-content-between align-items-center">
            <Col xs={12} sm="auto" className="mb-3">
              <h2 className="display-2 mb-0">{vm.translate("title")}</h2>
            </Col>
            <Col xs={12} sm="auto" className="mb-3">
              <ConditionalTooltip
                condition={!new_self_reading}
                tooltip={vm.translateGeneral("locked_action_tooltip")}
                placement="top"
                id="self_reading_tooltip">
                <Button className="ml-auto" variant="primary" disabled={!new_self_reading} onClick={vm.addSelfReading}>
                  {vm.translate("button_add")}
                </Button>
              </ConditionalTooltip>
            </Col>
          </Row>
          <Row noGutters>
            <Col xs={12}>
              {chart && (
                <LoaderOverlay loading={chart.busyWatcher.isBusy}>
                  <Card className="mx-n3 mx-sm-0">
                    <Card.Body>
                      <h4 className="display-4 mb-0">
                        {vm.translate("history_consumption")} ({getCommodityUnit(chart.commodityType)})
                      </h4>

                      <Graph labels={chart.labels} datasets={chart.datasets} />

                      {(chart.canMoveNext || chart.canMoveBack) && (
                        <nav className="history-data-nav">
                          {chart.canMoveNext ? (
                            <Button variant="secondary" size="sm" onClick={chart.moveNext}>
                              <ArrowRight transform="rotate(180)" width="1em" /> {vm.translate("move_data_earlier")}
                            </Button>
                          ) : (
                            <div />
                          )}
                          {chart.canMoveBack && (
                            <Button variant="secondary" size="sm" onClick={chart.moveBack}>
                              {vm.translate("move_data_later")} <ArrowRight width="1em" />
                            </Button>
                          )}
                        </nav>
                      )}
                    </Card.Body>
                  </Card>
                </LoaderOverlay>
              )}
            </Col>
            <Col xs={12} className="mt-5 mb-3">
              <HeaderRow level={2} title={vm.translate("history")} />
              {table && (
                <LoaderOverlay loading={table.busyWatcher.isBusy}>
                  <Card className="mx-n3 mx-sm-0">
                    <Card.Body>
                      <ResponsiveTable
                        className="table no-wrap"
                        items={table.readings}
                        itemKey="id"
                        columns={columns.filter(item => !item.scope || item.scope === vm.currentScope)}
                        context={createColumnsContext(vm)}
                      />

                      {(table.canMoveNext || table.canMoveBack) && (
                        <nav className="history-data-nav">
                          {table.canMoveNext ? (
                            <Button variant="secondary" size="sm" onClick={table.moveNext}>
                              <ArrowRight transform="rotate(180)" width="1em" /> {vm.translate("move_data_earlier")}
                            </Button>
                          ) : (
                            <div />
                          )}
                          {table.canMoveBack && (
                            <Button variant="secondary" size="sm" onClick={table.moveBack}>
                              {vm.translate("move_data_later")} <ArrowRight width="1em" />
                            </Button>
                          )}
                        </nav>
                      )}
                    </Card.Body>
                  </Card>
                </LoaderOverlay>
              )}
            </Col>
          </Row>
        </>
      )}
      <Observer>{() => <View vm={vm.activeChild} />}</Observer>
    </div>
  );
});

registerView(historyDetailListView, HistoryDetailPageViewModel);
