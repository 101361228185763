import { observable } from "mobx";
import { Expose } from "class-transformer";
import { EntityValidationRules } from "services/validation/entityValidationRules";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class UpdatePassword {
  /**
   * @example
   * Moo8yi3gei
   */
  @observable
  @Expose({ name: "current_password" })
  currentPassword!: string;

  /**
   * @example
   * Moo8yi3gei
   */
  @observable
  password!: string;

  static ValidationRules = {
    currentPassword: { required: true },
    password: { required: true },
  };
}

export class ExtendUpdatePassword extends UpdatePassword {
  @observable
  @Expose({ name: "password_confirmation" })
  passwordConfirmation?: string;
}

const UpdatePasswordValidationRules: EntityValidationRules<ExtendUpdatePassword> = {
  password: { required: true },
  passwordConfirmation: {
    required: true,
    equalWith: { parameter: "password", translationCode: "validators.password_confirmation_mismatch" },
  },
  currentPassword: { required: true },
};
export { UpdatePasswordValidationRules };
