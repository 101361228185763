/* eslint-disable curly */
import { classToPlain, ClassTransformOptions, serialize } from "class-transformer";
import { ApiError } from "./apiModels";

export function unwrapErrorMessage(error: ApiError) {
  const baseErrors = error.errors?.["base"];
  if (Array.isArray(baseErrors)) {
    return baseErrors[0];
  }

  for (const key in error.errors) {
    const message = error.errors[key];
    if (Array.isArray(message)) {
      return message[0];
    }
  }

  return error.message ?? (error as any).error ?? "Unknown error"; // error property is used for generic server errors (500)
}

const serializeOptions: ClassTransformOptions = {
  excludePrefixes: ["_"],
};

export function serializeEntity(value: any): string {
  return serialize(value, serializeOptions);
}

export function entityToPlain(entity: any) {
  return classToPlain(entity, serializeOptions);
}

export function entityToFormData(item: any) {
  const payload = entityToPlain(item);

  const data = new FormData();
  for (const [key, value] of Object.entries(payload)) {
    if (value !== undefined && value !== null) {
      data.append(key, value);
    }
  }

  return data;
}

export const entityWithArrayOfObjectsToFormData = (item: any, form: FormData, namespace = ""): FormData => {
  const model = entityToPlain(item);
  const formData = form || new FormData();

  for (const propertyName in model) {
    // eslint-disable-next-line no-prototype-builtins
    if (!model.hasOwnProperty(propertyName) || !model[propertyName] || propertyName.startsWith("_")) continue;
    const formKey = namespace ? `${namespace}[${propertyName}]` : propertyName;
    if (model[propertyName] instanceof Date) formData.append(formKey, model[propertyName].toISOString());
    else if (model[propertyName] instanceof Array) {
      model[propertyName].forEach((element: any) => {
        const tempFormKey = `${formKey}[]`;
        entityWithArrayOfObjectsToFormData(element, formData, tempFormKey);
      });
    } else if (typeof model[propertyName] === "object" && !(model[propertyName] instanceof File))
      entityWithArrayOfObjectsToFormData(model[propertyName], formData, formKey);
    else formData.append(formKey, model[propertyName].toString());
  }
  return formData;
};
