import { BusyWatcher, ScreenBase, watchBusy } from "@frui.ts/screens";
import { interfaces } from "inversify";
import { observable, runInAction } from "mobx";
import AdminRepository from "repositories/adminRepository";
import { unwrapErrorMessage } from "repositories/helpers";
import MessagePreview from "entities/messagePreview";

const navigationName = "preview";

export default class MessagePreviewViewModel extends ScreenBase {
  static navigationName = navigationName;

  navigationName = navigationName;
  busyWatcher = new BusyWatcher();

  @observable message: MessagePreview;
  @observable errorMessage?: string;

  constructor(public messageId: number, private adminRepository: AdminRepository) {
    super();
  }

  protected onInitialize(): Promise<any> | void {
    this.loadData();
  }

  @watchBusy
  async loadData() {
    if (!this.messageId) {
      return;
    }

    const response = await this.adminRepository.getMessageDetail(this.messageId);
    if (response.success) {
      runInAction(() => (this.message = response.payload));
    } else {
      runInAction(() => (this.errorMessage = unwrapErrorMessage(response.payload)));
    }
  }

  static Factory({ container }: interfaces.Context) {
    return (messageId: number) => {
      return new MessagePreviewViewModel(messageId, container.get(AdminRepository));
    };
  }
}
