import { Form, FormControlProps } from "@emanprague/ppaskit";
import { DateInputProps as DateInputControlProps, default as DateInputControl } from "@emanprague/ppaskit/dist/DateInput";
import { ValidationControlBase } from "@frui.ts/bootstrap";
import { bound } from "@frui.ts/helpers";
import React from "react";

export interface DateInputProps extends DateInputControlProps {
  feedbackValid?: React.ReactNode;
  feedbackInvalid?: React.ReactNode;
}

export default class DateInput<TTarget> extends ValidationControlBase<TTarget, DateInputProps & FormControlProps> {
  @bound
  protected renderInner() {
    const { noValidation, errorMessage, feedbackValid, feedbackInvalid, ...otherProps } = this.inheritedProps;
    const validationError = this.getValidationError() || feedbackInvalid;

    return (
      <>
        <DateInputControl {...otherProps} value={this.value} onChange={this.setDate} isInvalid={!!validationError} />
        {feedbackValid && <Form.Control.Feedback className="d-block">{feedbackValid}</Form.Control.Feedback>}
        {validationError && (
          <Form.Control.Feedback className="d-block" type="invalid">
            {validationError}
          </Form.Control.Feedback>
        )}
      </>
    );
  }

  @bound
  private setDate(value: Date) {
    const currentDate = (this.value as Date)?.valueOf();
    const newDate = value?.valueOf();

    if (currentDate !== undefined && isNaN(currentDate) && newDate !== undefined && isNaN(newDate)) {
      // do not set the target value to invalid when the current one is already invalid
      return;
    }

    if (currentDate !== newDate) {
      this.setValue(value);
    }
  }
}
