import type { ILocalizationService } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, Router, watchBusy } from "@frui.ts/screens";
import { action, computed, observable } from "mobx";
import AdminsFilter from "models/adminsFilter";
import ContinuousListViewModelBase from "viewModels/continuousListViewModelBase";
import { IAdminModule } from "viewModels/types";
import AdminRepository from "repositories/adminRepository";
import UserListItem from "entities/userListItem";
import AdminCreateViewModel from "./adminCreateViewModel";
import AdminUpdateViewModel from "./adminUpdateViewModel";
import EnumsService from "services/enumsService";

const navigationName = "admins";
export type AdminState = "all" | "active" | "inactive";

@Router.registerRoute({ name: Router.Self, route: navigationName })
export default class AdminsPageViewModel
  extends ContinuousListViewModelBase<UserListItem, AdminsFilter, AdminCreateViewModel | AdminUpdateViewModel>
  implements IAdminModule
{
  navigationName = navigationName;
  orderIndex = 100;
  menuPart = "admin";
  busyWatcher = new BusyWatcher();

  @observable adminStates: { id: AdminState; name: string }[];
  @observable selectedAdminStateId?: string;
  @observable selectedRoles: string[] = [];

  constructor(
    public localization: ILocalizationService,
    private createFactory: ReturnType<typeof AdminCreateViewModel.Factory>,
    private updateFactory: ReturnType<typeof AdminUpdateViewModel.Factory>,
    private adminRepository: AdminRepository,
    private enumsService: EnumsService
  ) {
    super();

    this.adminStates = [
      { id: "all", name: this.translate("state_all") },
      { id: "active", name: this.translate("state_active") },
      { id: "inactive", name: this.translate("state_inactive") },
    ];
    this.name = this.translate("title");
  }

  protected onInitialize(): Promise<any> | void {
    this.initializeFilter();
    return this.loadData();
  }

  @action.bound
  initializeFilter() {
    this.selectedAdminStateId = this.adminStates[0].id;
    this.selectedRoles = [];
  }

  @bound
  @watchBusy
  async loadData() {
    const response = await this.adminRepository.getAdmins(this.pagingFilter, this.filter);
    if (response.success) {
      this.setData(response.payload);
    }
  }

  @computed
  get userRoles() {
    return this.enumsService.getValues("userRoles");
  }

  @bound
  addAdmin() {
    return this.tryActivateChild(this.createFactory());
  }

  @action
  openDetail(item: UserListItem) {
    const detail = this.updateFactory(item, this.applyFilterAndLoadDebounced);
    return this.tryActivateChild(detail);
  }

  @action.bound
  applyAdminStateFilter(state?: AdminState) {
    if (state === "active") {
      this.filter.status = true;
    } else if (state === "inactive") {
      this.filter.status = false;
    } else {
      this.filter.status = undefined;
    }
    this.selectedAdminStateId = state;
    this.applyFilterAndLoad();
  }

  @action.bound
  applyRolesFilter() {
    this.filter.role = this.selectedRoles;
    void this.applyFilterAndLoad();
  }

  @action.bound
  clearFilter(...properties: (keyof AdminsFilter)[]) {
    properties.forEach(property => {
      this.filter[property] = undefined;
      if (property === "status") {
        this.selectedAdminStateId = "all";
      } else if (property === "role") {
        this.selectedRoles = [];
      }
    });

    this.applyFilterAndLoad();
  }

  get switchStateActiveId() {
    switch (this.filter.status) {
      case true:
        return "active";
      case false:
        return "inactive";
      default:
        return "all";
    }
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`admin.admins.${key}`);
  }

  @bound translateGeneral(key: string) {
    return this.localization.translateGeneral(`general.${key}`);
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected resetFilterValues(filter: AdminsFilter) {}

  protected createFilter() {
    return new AdminsFilter();
  }

  protected findNavigationChild(navigationName: string | undefined): any {
    if (navigationName === AdminCreateViewModel.navigationName) {
      return this.addAdmin();
    }
    return super.findNavigationChild(navigationName);
  }
}
