import { observable } from "mobx";
import { Expose } from "class-transformer";
import { EntityValidationRules } from "services/validation/entityValidationRules";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class ReqRegistrationStep1 {
  /**
   * @example
   * marek.novak@tryit.ppas.cz
   */
  @observable
  email!: string;

  /**
   * @example
   * 2001234
   */
  @observable
  @Expose({ name: "customer_number" })
  customerNumber!: number;

  /**
   * @example
   * 2001234
   */
  @observable
  @Expose({ name: "account_number" })
  accountNumber!: number;

  @observable
  @Expose({ name: "mkt_agreement" })
  mktAgreement?: boolean;

  /**
   * @example
   * true
   */
  @observable
  @Expose({ name: "zop_agreement" })
  zopAgreement?: boolean;

  static ValidationRules = {
    email: { required: true },
    customerNumber: { required: true, number: true },
    accountNumber: { required: true, number: true },
  };
}

const ReqRegistrationStep1ValidationRules: EntityValidationRules<ReqRegistrationStep1> = {
  email: { required: true, isEmail: true },
  customerNumber: { required: true, number: true },
  accountNumber: { required: true, number: true },
  zopAgreement: { required: true, equals: { parameter: true, translationCode: "validators.agreement_required" } },
};
export { ReqRegistrationStep1ValidationRules };
