import { IEventBus, ILocalizationService } from "@emanprague/shared-services";
import { IProductPage } from "viewModels/types";
import ProductCardsService from "services/productCardsService";
import ProductCardPageViewModel from "./productCardsPageViewModel";

export default class OtherProductsPageViewModel extends ProductCardPageViewModel implements IProductPage {
  orderIndex = 1;
  navigationName = "other";

  constructor(public localization: ILocalizationService, productCardsService: ProductCardsService, eventBus: IEventBus) {
    super("other", localization, productCardsService);
  }
}
