import { Button, HeaderRow, LoaderOverlay, Modal, PaymentMethodListItem } from "@emanprague/ppaskit";
import { registerView, ViewComponent } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import React from "react";
import OnlinePaymentViewModel from "viewModels/finance/onlinePaymentViewModel";
import { Col, Row } from "react-bootstrap";

const onlinePayment: ViewComponent<OnlinePaymentViewModel> = observer(({ vm }) => (
  <Modal centered show onHide={vm.requestClose} className="wider-modal">
    <Modal.Header closeButton>
      <HeaderRow title={vm.name} level={4} />
    </Modal.Header>
    <LoaderOverlay loading={vm.busyWatcher.isBusy}>
      <Modal.Body>
        <Row className="justify-content-center">
          <Col xs={12} sm={9}>
            {vm.paymentMethods && (
              <>
                {vm.paymentMethods.map(item => (
                  <PaymentMethodListItem
                    key={item.id}
                    className="mb-2"
                    label={item.name}
                    imgUrl={item.imageUrl}
                    selected={vm.isMethodSelected(item.id)}
                    onClick={() => vm.handleMethodSelected(item.id)}
                  />
                ))}
              </>
            )}
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button disabled={!vm.canConfirm} onClick={vm.confirmGatewayMethod}>
          {vm.translateGeneral("continue_button")}
        </Button>
      </Modal.Footer>
    </LoaderOverlay>
  </Modal>
));

registerView(onlinePayment, OnlinePaymentViewModel);
