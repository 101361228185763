import { Expose } from "class-transformer";
import merge from "lodash/merge";
import { observable } from "mobx";
import { entityToFormData } from "repositories/helpers";
import { EntityValidationRules } from "services/validation/entityValidationRules";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
class ReqChangeSupplyPointAddress {
  @observable
  street?: string;

  @observable
  @Expose({ name: "house_number" })
  houseNumber?: string;

  @observable
  @Expose({ name: "street_number" })
  streetNumber?: string;

  @observable
  city?: string;

  @observable
  @Expose({ name: "city_part" })
  cityPart?: string;

  @observable
  zip?: string;

  @observable
  @Expose({ name: "country_id" })
  countryId?: number;

  @observable
  @Expose({ name: "ruian_id" })
  ruianId?: number;

  @observable
  @Expose({ name: "address_id" })
  addressId?: number;

  @observable
  @Expose({ name: "file[]" })
  file?: Blob[];

  static ValidationRules = {
    countryId: { number: true },
    ruianId: { number: true },
    addressId: { number: true },
  };
}

merge(ReqChangeSupplyPointAddress.ValidationRules, {
  houseNumber: {
    requiredIf: { condition: (item: CustomReqChangeSupplyPointAddress) => !item.ruianId && !item.addressId },
    number: true,
  },
  city: { requiredIf: { condition: (item: CustomReqChangeSupplyPointAddress) => !item.ruianId && !item.addressId } },
  zip: { requiredIf: { condition: (item: CustomReqChangeSupplyPointAddress) => !item.ruianId && !item.addressId } },
  countryId: { requiredIf: { condition: (item: CustomReqChangeSupplyPointAddress) => !item.ruianId && !item.addressId } },
  ruianId: { number: true },
  addressId: { number: true },
} as EntityValidationRules<CustomReqChangeSupplyPointAddress>);

export default class CustomReqChangeSupplyPointAddress extends ReqChangeSupplyPointAddress {
  toFormData() {
    const data = entityToFormData(this);

    // generic entityToFomData is creating "[object OBJECT]" string instead of file
    data.delete("file[]");
    if (this.file && this.file.length > 0) {
      this.file.forEach(item => data.append("file[]", item));
    }

    return data;
  }
}
