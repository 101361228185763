import React from "react";
import { styleBlock } from "..";
import Form from "react-bootstrap/Form";
import iconArrowRight from "../assets/icons/Arrow/Right.svg";
import iconArrowSimpleLightDown from "../assets/icons/Arrow Simple Light/Down.svg";

export interface SupplyPointProps {
  id: number | string;
  title: string;
}
export interface CurrentPathProps {
  id?: number | string;
  href?: string;
  title: string;
  onClick?: (e: any) => void;
}
export interface SupplyPointNavProps {
  className?: string;
  value?: number | string;
  rootTitle?: string;
  rootHref?: string;
  rootOnClick?: (e: any) => void;
  selectSupplyPointLabel?: string;
  currentTitle?: string;
  currentPath?: CurrentPathProps[];
  supplyPoints?: SupplyPointProps[];
  supplyPointIcon?: string;
  supplyPointLabel: string;
  supplyPointsOnChange?: (e: any) => void;
}
const SupplyPointNav: React.FunctionComponent<SupplyPointNavProps> = ({
  className,
  value,
  rootTitle,
  rootHref,
  rootOnClick,
  selectSupplyPointLabel,
  currentTitle,
  currentPath,
  supplyPoints,
  supplyPointIcon,
  supplyPointLabel,
  supplyPointsOnChange,
  ...props
}) => {
  const classNames = styleBlock("supplypointnav");
  const arrowIcon = <img width={15} src={iconArrowRight} className={classNames("arrow")} />;

  return (
    <div className={classNames.toString()}>
      {rootTitle && (
        <a href={rootHref} onClick={rootOnClick} className={classNames("rootTitle")}>
          <span className={classNames("rootTitle", "label")}>{rootTitle}</span>
          {arrowIcon}
        </a>
      )}

      <div className={classNames("fakeselect")}>
        {supplyPoints && supplyPoints.length ? (
          <>
            <img className={classNames("fakeselect", "icon")} src={supplyPointIcon} width={24} height={24} />
            <span className={classNames("fakeselect", "title")}>{supplyPointLabel}</span>
            <span className={classNames("fakeselect", "label")}>{selectSupplyPointLabel}</span>
            <img className={classNames("fakeselect", "arrow")} src={iconArrowSimpleLightDown} width={14} />
            <Form.Control as="select" onChange={supplyPointsOnChange} defaultValue={value}>
              {supplyPoints.map((sp, idx) => (
                <option value={sp.id} key={"supplypointnav-" + idx}>
                  {sp.title}
                </option>
              ))}
            </Form.Control>
          </>
        ) : (
          <span className={classNames("fakeselect", "label d-inline")}>{supplyPointLabel}</span>
        )}
      </div>

      {currentPath &&
        currentPath.map((path, index) => {
          if (index < currentPath.length - 1) {
            return (
              <a href={path.href} onClick={path.onClick} className={classNames("currentTitle")} key={"supCurPath-" + path.id}>
                {arrowIcon}
                {path.title}
              </a>
            );
          } else {
            return (
              <span className={classNames("currentTitle")} key={"supCurPath-" + path.id}>
                {arrowIcon}
                {path.title}
              </span>
            );
          }
        })}

      {currentTitle && !currentPath && (
        <span className={classNames("currentTitle")}>
          {arrowIcon}
          {currentTitle}
        </span>
      )}
    </div>
  );
};

export default SupplyPointNav;
