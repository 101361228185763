import { Alert } from "@emanprague/ppaskit";
import Close from "@emanprague/ppaskit/dist/Icons/Close";
import { registerView, ViewComponent } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import React from "react";
import ToastViewModel from "viewModels/toastViewModel";

const toastView: ViewComponent<ToastViewModel> = observer(({ vm }) => {
  const isHtml = vm.message.includes("<br");
  const content = isHtml ? <div dangerouslySetInnerHTML={{ __html: vm.message }} /> : vm.message;

  return (
    <Alert variant={vm.variant}>
      {content}
      <button className="close" onClick={vm.onClose}>
        <Close />
      </button>
    </Alert>
  );
});

export default registerView(toastView, ToastViewModel);
