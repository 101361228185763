import { Button, Col, Form, HeaderRow, LoaderOverlay, Modal, Row, SupplyPointListItem } from "@emanprague/ppaskit";
import { Check, Input, Select } from "@frui.ts/bootstrap";
import { preventDefault, registerView, ViewComponent } from "@frui.ts/views";
import { getValidationMessage } from "@frui.ts/validation";
import { observer } from "mobx-react-lite";
import React from "react";
import AdvanceChangeViewModel from "viewModels/finance/advanceChangeViewModel";
import Uploader from "controls/uploader";

const advanceChange: ViewComponent<AdvanceChangeViewModel> = observer(({ vm }) => (
  <Modal centered show onHide={vm.requestClose}>
    <Modal.Header closeButton>
      <HeaderRow title={vm.translate("change_title")} level={4} />
    </Modal.Header>
    <LoaderOverlay loading={vm.busyWatcher.isBusy}>
      {vm.supplyPoint && !vm.isChangePossible && (
        <div
          className="d-flex align-items-center justify-content-center mb-3 px-4 mt-n3"
          dangerouslySetInnerHTML={{ __html: vm.translate("advance_change_not_possible") }}
        />
      )}

      {vm.supplyPoint && vm.isChangePossible && (
        <>
          <Modal.Body>
            <Row>
              <Col className="d-flex justify-content-center mb-5">
                {vm.translate("current_advances") + ":"}&nbsp;
                <strong>
                  {vm.currentFrequency} {vm.localization.formatNumber(vm.currentAmount)} {vm.translateGeneral("currency")}
                </strong>
              </Col>
            </Row>
            <Row>
              <Col className="d-flex justify-content-center">
                <h4 className="display-4 m-0 mb-3">{vm.translate("enter_new_data")}</h4>
              </Col>
            </Row>
            <Form className="modal-form" onSubmit={preventDefault(vm.changeAdvances)}>
              <Form.Row className="form-row-centered">
                <Form.Group as={Col} xs={11}>
                  <Form.Label>{vm.translate("advance_frequency")}</Form.Label>
                  <Select
                    target={vm.advanceChangeRequest}
                    property="advanceFrequencyId"
                    items={vm.frequencies}
                    mode="key"
                    keyProperty="id"
                    textProperty="name"
                    isNumeric={true}
                    onValueChanged={vm.handleFrequencyChanged}
                  />

                  <Form.Label className="mt-3">{vm.translate("advance_amount")}</Form.Label>
                  <Input target={vm.advanceChangeRequest} property="amount" onValueChanged={vm.handleAmountChanged} />
                  <Form.Text muted>
                    {vm.translate("minimal_amount")}:{" "}
                    {vm.minimalAmount ? (
                      <>
                        {vm.localization.formatNumber(vm.minimalAmount)} {vm.translateGeneral("currency")}
                      </>
                    ) : (
                      "--"
                    )}
                  </Form.Text>

                  {vm.isStatementRequired && (
                    <>
                      <Form.Label className="mt-3">{vm.translate("change_reason")}</Form.Label>
                      <Select
                        target={vm.advanceChangeRequest}
                        property="advanceChangeReasonId"
                        items={vm.changeReasons}
                        mode="key"
                        keyProperty="id"
                        textProperty="name"
                      />

                      <Check
                        custom
                        label={vm.translate("statement")}
                        className="mt-4"
                        target={vm}
                        property="statementIsConfirmed"
                        errorMessage={getValidationMessage(vm.statement, "isConfirmed")}
                        onValueChanged={vm.handleStatementChanged}
                      />
                    </>
                  )}
                </Form.Group>

                <Form.Group className="mt-4 mb-4 text-center">
                  <Form.Label>
                    {vm.translate("next_payment_date") + ": "}
                    <strong>{vm.localization.formatDate(vm.nextPayment)}</strong>
                  </Form.Label>
                </Form.Group>

                <Form.Group as={Col} xs={11} className="advance-change-attachment-text mb-4">
                  <strong className="mt-4 mb-3">{vm.translateGeneral("upload_attachment")}</strong>
                  <span className="grey-text"> {vm.translateGeneral("not_mandatory")}</span>
                  <p className="grey-text">{vm.translate("upload_hint")}</p>
                  <Uploader
                    localization={vm.localization}
                    onDrop={vm.onDrop}
                    onDropRejected={vm.onDropRejected}
                    fileSelectError={vm.fileSelectError}
                  />

                  <div className="mb-2 text-center">
                    <strong>{vm.translate("supply_point_affected")}</strong>
                  </div>
                  <div className="supply-points-list-container">
                    {vm.supplyPointsWithIcons.map(x => (
                      <SupplyPointListItem key={x.id} data={x} className="mb-3" />
                    ))}
                  </div>
                </Form.Group>
              </Form.Row>
              {vm.errorMessage && <Form.Control.Feedback type="invalid">{vm.errorMessage}</Form.Control.Feedback>}
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={vm.requestClose}>
              {vm.translateGeneral("cancel_button")}
            </Button>
            <Button disabled={!vm.canConfirm} onClick={vm.changeAdvances}>
              {vm.translateGeneral("confirm_changes_button")}
            </Button>
          </Modal.Footer>
        </>
      )}
    </LoaderOverlay>
  </Modal>
));

registerView(advanceChange, AdvanceChangeViewModel);
