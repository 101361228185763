import { observable } from "mobx";
import { Expose } from "class-transformer";
import ReqUpdateAdminUser from "./reqUpdateAdminUser";
import merge from "lodash/merge";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class UserListItem extends ReqUpdateAdminUser {
  /**
   * User ID
   */
  id!: number;

  /**
   * Customer number
   */
  @observable
  @Expose({ name: "external_id" })
  externalId?: number;

  static ValidationRules = merge(
    {
      id: { required: true, number: true },
      externalId: { number: true },
    },
    ReqUpdateAdminUser.ValidationRules
  );
}
