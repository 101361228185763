import type { ILocalizationService } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, ScreenBase, watchBusy } from "@frui.ts/screens";
import { attachAutomaticValidator, hasVisibleErrors, validate } from "@frui.ts/validation";
import UserLogin, { UserLoginValidationRules } from "entities/userLogin";
import { action, observable } from "mobx";
import type SecurityService from "services/securityService";
import type SimplePortalSettings from "entities/simplePortalSettings";
import type PortalSettingsService from "services/portalSettingsService";

export default class LoginViewModel extends ScreenBase {
  navigationName = "login";
  busyWatcher = new BusyWatcher();
  loginDisabledSetting?: SimplePortalSettings;

  @observable credentials = new UserLogin();
  @observable errorMessage?: string;

  forceLogin = false;

  constructor(
    public localization: ILocalizationService,
    private securityService: SecurityService,
    private portalSettingsService: PortalSettingsService
  ) {
    super();

    this.name = this.translate("title");
    attachAutomaticValidator(this.credentials, UserLoginValidationRules);
    this.loginDisabledSetting = this.portalSettingsService.getSimplePortalSettings("disable_login");
  }

  get canLogin() {
    return !hasVisibleErrors(this.credentials);
  }

  get isLoginDisabled() {
    return this.loginDisabledSetting?.active && !this.forceLogin;
  }

  @action navigate(subPath: string | undefined, params: any) {
    // Capture a "secret" parameter for admins to let them login
    // use #/login?force=admin to bypass the disabled login
    if (params?.force == "admin") {
      this.forceLogin = true;
    }
    return super.navigate(subPath, params);
  }

  @bound
  @watchBusy
  async login() {
    this.setErrorMessage();
    if (validate(this.credentials)) {
      const result = await this.securityService.logIn(this.credentials.email, this.credentials.password);

      if (typeof result === "string") {
        this.setErrorMessage(result);
      } else {
        window.location.href = "#/dashboard";
      }
    }
  }

  @action
  setErrorMessage(message?: string) {
    this.errorMessage = message;
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`profile.login.${key}`);
  }
}
