import { IPagingFilter } from "@frui.ts/data";
import { entityToPlain } from "./helpers";
import RepositoryBase from "./repositoryBase";
import NotificationList, { NotificationListFilter } from "entities/notificationList";

export default class NotificationsRepository extends RepositoryBase {
  getNotifications(paging: IPagingFilter, filter?: NotificationListFilter) {
    const query = entityToPlain(filter);
    return this.callApi(api => api.all("notifications").getPagedEntities(NotificationList, x => x.notifications, paging, query));
  }

  deleteNotification(id: number) {
    return this.callApi(api => api.one("notifications", id).delete());
  }

  getFile(id: number) {
    return this.callApi(api => api.one("notifications", id).all("file").getRaw());
  }
}
