import { IPagingFilter } from "@frui.ts/data";
import { entityToPlain } from "./helpers";
import RepositoryBase from "./repositoryBase";
import MessagesFilter from "models/messagesFilter";
import RspPartnerMessageList from "entities/rspPartnerMessageList";
import MessagePreview from "entities/messagePreview";

export default class MessagesRepository extends RepositoryBase {
  getMessages(partnerId: number, paging: IPagingFilter, filter?: MessagesFilter) {
    const query = entityToPlain(filter);

    return this.callApi(api =>
      api.all(`partners/${partnerId}/messages`).getPagedEntities(RspPartnerMessageList, x => x.messages, paging, query)
    );
  }

  getUnreadMessagesCount(partnerId: number) {
    const paging: IPagingFilter = { offset: 0, limit: 0 };

    const filter = new MessagesFilter();
    filter.read = false;

    return this.getMessages(partnerId, paging, filter);
  }

  getMessagePreviews(partnerId: number) {
    const paging: IPagingFilter = { offset: 0, limit: 3 };
    const filter = new MessagesFilter();
    return this.getMessages(partnerId, paging, filter);
  }

  markMessageAsRead(partnerId: number, messageId: number) {
    return this.callApi(api => api.all(`partners/${partnerId}/messages/${messageId}/read`).postEntity(undefined));
  }

  getMessageDetail(partnerId: number, messageId: number) {
    return this.callApi(api => api.all(`partners/${partnerId}/messages/${messageId}`).getEntity(MessagePreview));
  }
}
