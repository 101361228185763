import { observable } from "mobx";
import { Expose } from "class-transformer";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class ReqChangeSupplyPointDetails {
  /**
   * New name
   */
  @observable
  name?: string;

  /**
   * Enumeration::InstallationFloorId
   */
  @observable
  @Expose({ name: "installation_floor_id" })
  installationFloorId?: number;

  /**
   * Číslo bytu
   */
  @observable
  @Expose({ name: "flat_number" })
  flatNumber?: string;

  static ValidationRules = {
    installationFloorId: { number: true },
  };
}
