import { registerView, ViewComponent, preventDefault } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import React from "react";
import UpdatePasswordViewModel from "viewModels/profile/updatePasswordViewModel";
import PasswordInput from "controls/twoway/PasswordInput";
import { Modal, Button, PasswordIndicator, HeaderRow, LoaderOverlay } from "@emanprague/ppaskit";
import { Form, Col } from "react-bootstrap";

const updatePasswordView: ViewComponent<UpdatePasswordViewModel> = observer(({ vm }) => {
  const handleClose = () => {
    if (!vm.isCloseBlocked) {
      vm.requestClose();
    }
  };
  return (
    <>
      <Modal centered show onHide={handleClose}>
        <LoaderOverlay loading={vm.busyWatcher.isBusy}>
          <Modal.Header closeButton>
            <HeaderRow title="Změna hesla" level={4} />
          </Modal.Header>
          <Modal.Body>
            {vm.isCloseBlocked && (
              <p className="mx-auto mb-4 mt-n4 forced_password_warning">{vm.translate("forced_password_change_text")}</p>
            )}
            <Form className="w-75 w-sm-50 mx-auto mb-n3" onSubmit={preventDefault(vm.updatePassword)}>
              <Form.Row>
                <Form.Group as={Col} xs={12}>
                  <Form.Label>Současné heslo</Form.Label>
                  <PasswordInput target={vm.data} property="currentPassword" />
                </Form.Group>
                <Form.Group as={Col} xs={12}>
                  <Form.Label>Nové heslo</Form.Label>
                  <PasswordInput target={vm.data} property="password" />
                </Form.Group>
                <Form.Group as={Col} xs={12}>
                  <Form.Label>Zopakujte nové heslo</Form.Label>
                  <PasswordInput target={vm.data} property="passwordConfirmation" />
                </Form.Group>
                <Form.Group as={Col} xs={12}>
                  <PasswordIndicator
                    label={vm.translate("password_complexity.level_" + vm.passwordComplexityValue.toString())}
                    value={vm.passwordComplexityValue}
                    tooltip={
                      <div
                        className="font-size-base font-weight-bold"
                        dangerouslySetInnerHTML={{ __html: vm.translate("password_complexity_tooltip") }}
                      />
                    }
                    className="mb-3"
                  />
                </Form.Group>
                {vm.errorMessage && <Form.Control.Feedback type="invalid">{vm.errorMessage}</Form.Control.Feedback>}
              </Form.Row>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" className="order-2" onClick={vm.updatePassword} disabled={!vm.canConfirm}>
              {vm.localization.translateGeneral("general.confirm_changes_button")}
            </Button>
            {!vm.isCloseBlocked && (
              <Button variant="secondary" className="order-1" onClick={vm.requestClose}>
                {vm.localization.translateGeneral("general.cancel_button")}
              </Button>
            )}
          </Modal.Footer>
        </LoaderOverlay>
      </Modal>
    </>
  );
});

registerView(updatePasswordView, UpdatePasswordViewModel);
