import { Button, Layout, LoaderOverlay } from "@emanprague/ppaskit";
import { registerView, ViewComponent } from "@frui.ts/views";
import PreAuthHeader from "components/PreAuthHeader";
import { observer } from "mobx-react-lite";
import React from "react";
import InitializationService from "../services/initializationService";

const initializationView: ViewComponent<InitializationService> = observer(({ vm }) => {
  return (
    <>
      <Layout fluid="md" className="layout-pre-auth">
        <PreAuthHeader translateFunc={vm.translateGeneral} />
        {vm.isWsFailed ? (
          <div className="d-flex flex-column align-items-center app-loader px-5">
            <h1>{vm.translateGeneral("app_ws_failed_title")}</h1>
            <p>{vm.translateGeneral("app_ws_failed_description")}</p>
            <Button variant="primary" onClick={vm.logout}>
              {vm.translateGeneral("profile.refresh_credentials.logout_button")}
            </Button>
          </div>
        ) : (
          <LoaderOverlay className="app-loader" variant="transparent" loading text={vm.translateGeneral("app_loading")} />
        )}
      </Layout>
    </>
  );
});

registerView(initializationView, InitializationService);
