import { Expose, Transform } from "class-transformer";
import formatISO from "date-fns/formatISO";
import InvoiceListItemState from "entities/invoiceListItemState";
import { observable } from "mobx";

export default class InvoicesFilter {
  @observable
  @Expose({ name: "q" })
  search?: string;

  @observable
  @Expose({ name: "filters[supply_point_id]" })
  supplyPointIds?: number[];

  @observable
  @Expose({ name: "filters[amount_from]" })
  minAmount?: number;

  @observable
  @Expose({ name: "filters[amount_to]" })
  maxAmount?: number;

  @observable
  @Expose({ name: "filters[vs]" })
  vs?: string;

  @observable
  @Expose({ name: "filters[state]" })
  state?: InvoiceListItemState[];

  @observable
  @Expose({ name: "filters[due_date_from]" })
  @Transform(value => value && formatISO(value, { representation: "date" }))
  dueDateFrom?: Date;

  @observable
  @Expose({ name: "filters[due_date_to]" })
  @Transform(value => value && formatISO(value, { representation: "date" }))
  dueDateTo?: Date;

  @observable
  @Expose({ name: "filters[invoice_type_id]" })
  invoiceTypeId?: number;
}
