import { Col, NotificationBar, Row } from "@emanprague/ppaskit";
import iconInfo from "@emanprague/ppaskit/dist/assets/icons/Info.svg";
import { observer } from "mobx-react-lite";
import React from "react";
import DOMPurify from "dompurify";

interface NoticeBarProps {
  content: string;
  onCancel?: () => void;
  color?: string;
  showIcon?: boolean;
  textAlign?: "left" | "center" | "right";
}

export const NoticeBar: React.FunctionComponent<NoticeBarProps> = observer(
  ({ content, onCancel, color, showIcon = true, textAlign = "left" }) => (
    <NotificationBar cancellable={!!onCancel} onCancel={onCancel} className="mb-2" background={color}>
      <Row className="d-lg-flex align-items-center justify-content-center my-md-1" noGutters>
        {showIcon && (
          <Col xs="auto" className="mr-3">
            <img draggable={false} src={iconInfo} alt="info icon" width={22} height={22} />
          </Col>
        )}
        <Col md="auto" className="d-lg-block" style={{ textAlign: textAlign }}>
          <span
            className="font-size-sm"
            style={{ whiteSpace: "pre-line" }}
            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content) }}
          />
        </Col>
      </Row>
    </NotificationBar>
  )
);
