import { hasCondition, ValidationServiceBase } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { IValidatorsRepository } from "@frui.ts/validation";
import validator from "validator";
import { PpasValidationRules } from "./entityValidationRules";

export default class ValidationService extends ValidationServiceBase {
  initialize(validators: IValidatorsRepository<string>) {
    super.initialize(validators);

    validators.set("isEmail", this.validateEmail);
    validators.set("isPhone", this.validatePhoneNumber);
    validators.set("date", this.validateDate);
    validators.set("isZip", this.validateZip);
    validators.set("isSipo", this.validateSipo);
    validators.set("isIco", this.validateIco);
    validators.set("isDic", this.validateDic);
    validators.set("isNumeric", this.validateNumeric);
    validators.set("isFloat", this.validateFloat);
    validators.set("isURL", this.validateURL);
  }

  @bound
  validateURL(value: any, propertyName: string, entity: any, params: PpasValidationRules["isURL"]) {
    const isValid = !value || validator.isURL(value, { require_protocol: true, protocols: ["http", "https"] });
    return this.getValidationMessage(isValid, params, "validators.is_url");
  }

  @bound
  validateEmail(value: any, propertyName: string, entity: any, params: PpasValidationRules["isEmail"]) {
    const isValid = !value || validator.isEmail(value);
    return this.getValidationMessage(isValid, params, "validators.is_email");
  }

  @bound
  validatePhoneNumber(value: any, propertyName: string, entity: any, params: PpasValidationRules["isPhone"]) {
    const isValid = !value || validator.isMobilePhone(value);
    return this.getValidationMessage(isValid, params, "validators.is_phone");
  }

  @bound
  validateDate(value: any, propertyName: string, entity: any, params: PpasValidationRules["date"]) {
    const isValid = !value || !isNaN(value.valueOf());
    return this.getValidationMessage(isValid, params, "validators.date");
  }

  @bound
  validateZip(value: any, propertyName: string, entity: any, params: PpasValidationRules["isZip"]) {
    const isValid = !value || /^\d{5}$/.test(value);
    return this.getValidationMessage(isValid, params, "validators.is_postal_code");
  }

  @bound
  validateSipo(value: any, propertyName: string, entity: any, params: PpasValidationRules["isSipo"]) {
    if (hasCondition(params) && !params.condition(entity)) {
      return undefined;
    }

    const isValid = !value || /^\d{10}$/.test(value);
    return this.getValidationMessage(isValid, params, "validators.is_sipo");
  }

  @bound
  validateIco(value: any, propertyName: string, entity: any, params: PpasValidationRules["isIco"]) {
    const isValid = !value || /^\d{8}$/.test(value);
    return this.getValidationMessage(isValid, params, "validators.is_ico");
  }

  @bound
  validateDic(value: any, propertyName: string, entity: any, params: PpasValidationRules["isDic"]) {
    const isValid = !value || /^CZ\d{8}$/.test(value);
    return this.getValidationMessage(isValid, params, "validators.is_dic");
  }

  @bound
  validateNumeric(value: any, propertyName: string, entity: any, params: PpasValidationRules["isNumeric"]) {
    const isValid = !value || /^\d+$/.test(value);
    return this.getValidationMessage(isValid, params, "validators.number");
  }

  @bound
  validateFloat(value: any, propertyName: string, entity: any, params: PpasValidationRules["isFloat"]) {
    const isValid = !value || typeof value === "number" || value === parseFloat(value).toString();
    return this.getValidationMessage(isValid, params, "validators.number");
  }
}
