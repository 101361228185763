import React from "react";

import { styleBlock, Card } from "..";

interface LinkListProps {
  title?: string;
  links: Array<React.ReactNode>;
  className?: string;
}

export default class LinkList extends React.PureComponent<LinkListProps> {
  render() {
    const { title, links, className } = this.props;
    const classNames = styleBlock("linklist");
    const cn = classNames({}).mix(className);

    return (
      <Card className={cn.toString()}>
        <Card.Body>
          {title && <h2 className="display-2 mb-3">{title}</h2>}
          {links && <div className={classNames("container")}>{links.map(link => link)}</div>}
        </Card.Body>
      </Card>
    );
  }
}
