import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import ReactSlider from "react-slider";
import { styleBlock } from "..";

export interface InputRangeProps {
  className?: string;
  name?: string;
  form?: string;
  allowUserOverride?: boolean;
  min: number;
  max: number;
  range?: [number, number];
  step?: number;
  disabled?: boolean;
  autofocus?: boolean;
  onChange?: (value: [number, number]) => void;
  prefix?: string;
  suffix?: string;
  pearling?: boolean;
}

type InputRangeState = {
  min: number;
  max: number;
  editValue: string;
  focused: "min" | "max" | null;
};

// TODO: this needs rewrite
const InputRange: React.FC<InputRangeProps> = ({
  className,
  min,
  max,
  range,
  step,
  disabled,
  prefix,
  suffix,
  pearling,
  onChange,
}) => {
  const b = styleBlock("inputrange");

  const [value, setValue] = useState({
    min,
    max,
    focused: null,
    editValue: "",
  } as InputRangeState);

  useEffect(() => {
    if (range) {
      setValue({ ...value, min: range[0], max: range[1] });
    }
  }, range);

  function handleChange(e: any) {
    const newValue = { ...value };
    if (newValue.focused) {
      newValue.editValue = e.target.value.replace(/[\D]*/g, "");
    } else {
      if (Array.isArray(e)) {
        newValue.min = e[0];
        newValue.max = e[1];
      } else {
        const updatedValue = e.target.name.slice(-3);
        newValue[updatedValue] = parseInt(e.target.value);
      }
    }

    setValue(newValue);
    onChange?.([newValue.min, newValue.max]);
  }

  function onBlurFocus(e: any) {
    const focused = e.target.name.slice(-3);
    const newValue = { ...value };
    if (e.type === "focus") {
      newValue.editValue = "" + parseInt(e.target.value);
      newValue.focused = focused;
    } else if (e.type === "blur") {
      const resultingValue = value.editValue ? parseInt(value.editValue) : eval(focused);
      newValue[focused] = (
        focused === "min"
          ? resultingValue >= min && resultingValue < value.max
          : resultingValue > value.min && resultingValue <= max
      )
        ? resultingValue
        : eval(focused);
      newValue.editValue = "";
      newValue.focused = null;
    }

    setValue(newValue);
    onChange?.([newValue.min, newValue.max]);
  }

  return (
    <div className={b.toString() + " " + className}>
      <Form.Control
        type="phone"
        name="inputrangecustommin"
        value={value.focused === "min" ? value.editValue : (prefix ? prefix : "") + value.min + (suffix ? suffix : "")}
        onFocus={onBlurFocus}
        onBlur={onBlurFocus}
        onChange={handleChange}
        tabIndex={1}
        className="px-0"
        autoComplete="off"
      />
      <ReactSlider
        min={min}
        max={max}
        step={step ?? 1}
        minDistance={step ?? 0}
        value={[value.min, value.max]}
        className="slider"
        thumbClassName="thumb"
        trackClassName="track"
        pearling={pearling ?? true}
        disabled={disabled}
        onChange={handleChange}
      />
      <Form.Control
        type="phone"
        name="inputrangecustommax"
        value={value.focused === "max" ? value.editValue : (prefix ? prefix : "") + value.max + (suffix ? suffix : "")}
        onFocus={onBlurFocus}
        onBlur={onBlurFocus}
        onChange={handleChange}
        tabIndex={2}
        className="px-0"
        autoComplete="off"
      />
    </div>
  );
};

export default InputRange;
