import { BusyWatcher, ScreenBase, watchBusy } from "@frui.ts/screens";
import { interfaces } from "inversify";
import { action, observable, runInAction } from "mobx";
import RspBanner from "entities/rspBanner";
import { bound } from "@frui.ts/helpers";
import { ILocalizationService } from "@emanprague/shared-services";
import { unwrapErrorMessage } from "repositories/helpers";
import AdminRepository from "repositories/adminRepository";
import UserContext from "services/userContext";
import { openInNewTab } from "helpers/utils";

const navigationName = "preview";

export default class BannerPreviewViewModel extends ScreenBase {
  static navigationName = navigationName;

  navigationName = navigationName;
  busyWatcher = new BusyWatcher();

  @observable banner: RspBanner;
  @observable errorMessage?: string;

  constructor(
    public bannerId: number,
    public localization: ILocalizationService,
    private adminRepository: AdminRepository,
    private userContext: UserContext
  ) {
    super();
  }

  protected onInitialize(): Promise<any> | void {
    this.loadData();
  }

  @watchBusy
  @action
  async loadData() {
    if (!this.bannerId) {
      return;
    }

    const response = await this.adminRepository.getBannerDetail(this.bannerId);
    if (response.success) {
      runInAction(() => (this.banner = response.payload));
    } else {
      runInAction(() => (this.errorMessage = unwrapErrorMessage(response.payload)));
    }
  }

  @bound
  addSupplyPoint() {
    openInNewTab(this.userContext.newSupplyPointLink);
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`admin.banners.detail.${key}`);
  }

  @bound translateGeneral(key: string) {
    return this.localization.translateGeneral(`general.${key}`);
  }

  static Factory({ container }: interfaces.Context) {
    return (bannerId: number) => {
      return new BannerPreviewViewModel(
        bannerId,
        container.get("ILocalizationService"),
        container.get(AdminRepository),
        container.get(UserContext)
      );
    };
  }
}
