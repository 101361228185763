import { ILocalizationService, INotificationService, SeverityLevel } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, ScreenBase, watchBusy } from "@frui.ts/screens";
import { attachAutomaticValidator, hasVisibleErrors, ManualEntityValidator, validate, validateAll } from "@frui.ts/validation";
import ReqTransferSupplyPointRequest from "entities/reqTransferSupplyPointRequest";
import ReqTransferSupplyPointRequestAddress from "entities/reqTransferSupplyPointRequestAddress";
import ReqTransferSupplyPointRequestLegalPerson from "entities/reqTransferSupplyPointRequestLegalPerson";
import ReqTransferSupplyPointRequestNaturalPerson from "entities/reqTransferSupplyPointRequestNaturalPerson";
import { isDoubleTariff } from "helpers/supplyPointHelper";
import { interfaces } from "inversify";
import { action, computed, observable, runInAction } from "mobx";
import CommodityType from "models/commodityType";
import ProductDetailContext from "models/productDetailContext";
import SupplyPointsRepository from "repositories/supplyPointsRepository";
import EnumsService from "services/enumsService";
import UserContext from "services/userContext";
import { applyServerErrors } from "services/validation/validationHelpers";
import SupplyPointDetailPageViewModel from "./supplyPointDetailPageViewModel";
import { getCzechRepublicCountryId } from "helpers/utils";

class DataStep1 {
  @observable value: number;
  @observable valueNt?: number;
  @observable deviceChanged = false;
  @observable newDeviceId?: string;
}

class DataStep2 {
  @observable email?: string;
  @observable phone?: string;
}

class DataStep3 {
  @observable sentToEmail?: boolean = true;
  @observable sentToAddress?: boolean;
  @observable invoiceEmail?: string;
}

export default class CustomerTransferViewModel extends ScreenBase {
  static navigationName = "transferCustomer";
  fullScreen = true;

  @observable currentStep = 1;
  @observable transferRequest: ReqTransferSupplyPointRequest;

  @observable dataStep1: DataStep1;
  @observable dataStep2: DataStep2;
  @observable dataStep3: DataStep3;
  manualValidator = new ManualEntityValidator<DataStep2 & { base: any }>(true);

  navigationName = CustomerTransferViewModel.navigationName;
  parent: SupplyPointDetailPageViewModel;
  steps: Array<string> = [];
  busyWatcher = new BusyWatcher();

  constructor(
    private productDetailContext: ProductDetailContext,
    public localization: ILocalizationService,
    private supplyPointsRepository: SupplyPointsRepository,
    private enumsService: EnumsService,
    private userContext: UserContext,
    private notificationService: INotificationService
  ) {
    super();

    this.name = this.translate("title");
    this.steps = [
      this.translate("step1_title_commodity" + this.commodityType.toString()),
      this.translate("step2_title"),
      this.translate("step3_title"),
    ];

    this.dataStep1 = new DataStep1();
    this.dataStep2 = new DataStep2();
    this.dataStep3 = new DataStep3();

    this.transferRequest = new ReqTransferSupplyPointRequest();
    this.transferRequest.personType = "natural_person";
    this.transferRequest.legalPerson = new ReqTransferSupplyPointRequestLegalPerson();
    this.transferRequest.naturalPerson = new ReqTransferSupplyPointRequestNaturalPerson();

    this.transferRequest.address = new ReqTransferSupplyPointRequestAddress();
    this.transferRequest.address.countryId = this.czechRepublicCountryId;
  }

  protected onInitialize() {
    attachAutomaticValidator(this.dataStep1, {
      value: { required: true, number: true, manualErrors: this.manualValidator.errors },
      valueNt: { required: this.isDoubleTariff, number: true, manualErrors: this.manualValidator.errors },
      newDeviceId: { requiredIf: { condition: (item: DataStep1) => item.deviceChanged }, number: true },
    });
    attachAutomaticValidator(this.dataStep2, {
      email: { required: true, isEmail: true, manualErrors: this.manualValidator.errors },
      phone: { required: true, isPhone: true, manualErrors: this.manualValidator.errors },
    });
    attachAutomaticValidator(this.dataStep3, {
      sentToEmail: { requiredIf: { condition: (item: DataStep3) => !item.sentToAddress } },
      invoiceEmail: {
        requiredIf: { condition: (item: DataStep3) => item.sentToEmail },
        isEmail: true,
      },
    });
    attachAutomaticValidator(this.transferRequest.address, {
      countryId: { number: true, requiredIf: { condition: () => this.dataStep3.sentToAddress } },
      houseNumber: { requiredIf: { condition: () => this.dataStep3.sentToAddress }, manualErrors: this.manualValidator.errors },
      city: { requiredIf: { condition: () => this.dataStep3.sentToAddress } },
      zip: { requiredIf: { condition: () => this.dataStep3.sentToAddress }, manualErrors: this.manualValidator.errors },
    });
    attachAutomaticValidator(this.transferRequest.legalPerson, ReqTransferSupplyPointRequestLegalPerson.ValidationRules);
    attachAutomaticValidator(this.transferRequest.naturalPerson, ReqTransferSupplyPointRequestNaturalPerson.ValidationRules);
  }

  @bound
  @watchBusy
  async confirmCustomerTransfer() {
    if (!this.dataStep3.sentToEmail && !this.dataStep3.sentToAddress) {
      runInAction(() => {
        this.dataStep3.sentToEmail = undefined;
        this.dataStep3.sentToAddress = undefined;
        this.dataStep3.invoiceEmail = undefined;
      });
    }

    const { supplyPoint, partnerId } = this.productDetailContext;
    if (!validateAll([this.dataStep3, this.transferRequest.address]) || !partnerId || !supplyPoint?.id) {
      return;
    }

    const payload = Object.assign(
      new ReqTransferSupplyPointRequest(),
      this.transferRequest,
      this.dataStep1,
      this.dataStep2,
      this.dataStep3,
      !this.dataStep3.sentToAddress ? { address: undefined } : undefined
    );

    const response = await this.supplyPointsRepository.transferCustomer(partnerId, supplyPoint.id, payload);

    if (response.success) {
      this.notificationService.addNotification(this.translateGeneral("request_sent_success"), SeverityLevel.success);
      this.requestClose();
    } else {
      applyServerErrors(
        response.payload,
        this.manualValidator,
        key => this.localization.translateAttribute("address", key),
        true
      );
      if (this.manualValidator.errors.phone) {
        runInAction(() => (this.currentStep = 2));
      }
    }
  }

  @action.bound
  goToNextStep() {
    switch (this.currentStep) {
      case 1: {
        if (validate(this.dataStep1)) {
          this.currentStep++;
        }
        break;
      }
      case 2: {
        const isPersonValid =
          (this.transferRequest.personType === "legal_person" ? validate(this.transferRequest.legalPerson) : true) &&
          (this.transferRequest.personType === "natural_person" ? validate(this.transferRequest.naturalPerson) : true);

        if (validate(this.dataStep2) && isPersonValid) {
          this.currentStep++;
        }
        break;
      }
      case 3: {
        this.confirmCustomerTransfer();
        break;
      }
    }
  }

  get canGoToNextStep() {
    switch (this.currentStep) {
      case 1: {
        return !hasVisibleErrors(this.dataStep1);
      }
      case 2: {
        const personVisibleErrors =
          (this.transferRequest.personType === "legal_person" && hasVisibleErrors(this.transferRequest.legalPerson)) ||
          (this.transferRequest.personType === "natural_person" && hasVisibleErrors(this.transferRequest.naturalPerson));

        return !personVisibleErrors && !hasVisibleErrors(this.dataStep2);
      }
      case 3: {
        return !hasVisibleErrors(this.dataStep3) && !hasVisibleErrors(this.transferRequest.address);
      }
      default:
        return false;
    }
  }

  @action.bound
  goToPreviousStep() {
    this.currentStep--;
  }

  @action.bound
  handleSentToEmailChanged() {
    if (this.dataStep3.sentToEmail) {
      this.dataStep3.sentToAddress = undefined;
    } else {
      this.dataStep3.invoiceEmail = undefined;
    }
  }

  @action.bound
  handleSentToAddressChanged() {
    if (this.dataStep3.sentToAddress) {
      this.dataStep3.sentToEmail = undefined;
    }
  }

  @action.bound
  handleNewDeviceIdChanged() {
    if (!this.dataStep1.deviceChanged && this.dataStep1.newDeviceId) {
      this.dataStep1.newDeviceId = undefined;
    }
  }

  @computed
  get countries() {
    return this.enumsService.getValues("countries");
  }

  @computed
  get personTypes() {
    return [
      { id: "natural_person", name: this.translate("natural_person") },
      { id: "legal_person", name: this.translate("legal_person") },
    ];
  }

  get currentStepString() {
    return "step" + this.currentStep.toString();
  }

  get supplyPoint() {
    return this.productDetailContext.supplyPoint;
  }

  get commodityType() {
    return this.productDetailContext.commodityType;
  }

  get isDoubleTariff() {
    return (
      this.commodityType === CommodityType.Electricity &&
      this.parent.supplyPoint &&
      isDoubleTariff(this.parent.supplyPoint.tariff)
    );
  }

  @computed
  get czechRepublicCountryId() {
    return getCzechRepublicCountryId(this.enumsService);
  }

  @bound clearManualError(value: unknown, property: string) {
    this.manualValidator.removeError(property as any);
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`products.customer_transfer.${key}`);
  }

  @bound translateGeneral(key: string) {
    return this.localization.translateGeneral(`general.${key}`);
  }

  static Factory({ container }: interfaces.Context) {
    return (productDetailContext: ProductDetailContext) => {
      return new CustomerTransferViewModel(
        productDetailContext,
        container.get("ILocalizationService"),
        container.get(SupplyPointsRepository),
        container.get(EnumsService),
        container.get(UserContext),
        container.get("INotificationService")
      );
    };
  }
}
