import { Expose } from "class-transformer";
import { observable } from "mobx";

export default class MessagesFilter {
  @observable
  @Expose({ name: "q" })
  search?: string;

  @observable
  @Expose({ name: "filters[read]" })
  read?: boolean;
}
