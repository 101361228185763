import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { FormControlProps } from "react-bootstrap/FormControl";
import { styleBlock } from "../";

export interface InputPhoneProps {
  prefixList?: Array<string>;
  feedbackValid?: React.ReactNode;
  feedbackInvalid?: React.ReactNode;
  onChange?: (value: string | undefined) => void;
}

type InputPhoneBaseProps = InputPhoneProps & FormControlProps & Omit<React.InputHTMLAttributes<HTMLInputElement>, "size">;

const InputPhone: React.FC<InputPhoneBaseProps> = ({
  prefixList,
  className,
  feedbackValid,
  feedbackInvalid,
  onChange,
  ...inputProps
}) => {
  const [value, setValue] = useState({
    prefix: "",
    suffix: "",
    focused: false,
  });

  useEffect(() => {
    if (inputProps.value) {
      const valueString = `${inputProps.value}`;
      if (inputProps.value[0] === "+") {
        setValue({
          ...value,
          prefix: valueString.substr(0, 4),
          suffix: valueString.substr(4),
        });
      } else {
        setValue({
          ...value,
          prefix: prefixList ? prefixList[0] : "",
          suffix: valueString,
        });
      }
    }
  }, [inputProps.value]);

  const b = styleBlock("input-phone");

  function handleChange(e: React.ChangeEvent<any>) {
    if (e.target.name === "suffix") {
      e.target.value = e.target.value.replace(/[\D]*/g, "");
    }
    const newValue = { ...value, [e.target.name]: e.target.value };
    setValue(newValue);
    if (onChange) {
      onChange(newValue.suffix ? newValue.prefix + newValue.suffix : undefined);
    }
  }

  function handleFocus() {
    setValue({ ...value, focused: true });
  }

  function handleBlur() {
    setValue({ ...value, focused: false });
  }

  return (
    <Form.Row className={b.toString() + " " + className}>
      <div
        className={b("container")
          .mix(!!feedbackInvalid && "is-invalid")
          .mix(value.focused && "focus")}>
        <Form.Group>
          <Form.Control
            name="prefix"
            custom
            as="select"
            value={value.prefix}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}>
            {prefixList?.map((prefix, index) => (
              <option value={prefix} key={index}>
                {prefix}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        <Form.Group>
          <Form.Control
            name="suffix"
            value={value.suffix}
            type="phone"
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
        </Form.Group>
      </div>
      {feedbackValid && <Form.Control.Feedback>{feedbackValid}</Form.Control.Feedback>}
      {feedbackInvalid && <Form.Control.Feedback type="invalid">{feedbackInvalid}</Form.Control.Feedback>}
    </Form.Row>
  );
};

InputPhone.defaultProps = {
  prefixList: ["+420", "+421"],
};

export default InputPhone;
