import { ILocalizationService } from "@emanprague/shared-services";
import React from "react";
import { observer } from "mobx-react-lite";
import { Uploader as UploaderInner } from "@emanprague/ppaskit";

const acceptedFileTypes = ["image/jpg", "image/jpeg", "image/png", "application/pdf"];

interface UploaderProps {
  accept?: string | string[];
  localization: ILocalizationService;
  onDrop: (files: File[]) => void;
  onDropRejected?: () => void;
  fileSelectError?: string;
  className?: string;
  maxFiles?: number;
  maxFileSizeInBytes?: number;
  customTypes?: string;
}

const Uploader: React.FunctionComponent<UploaderProps> = observer(
  ({
    accept,
    localization,
    onDrop,
    onDropRejected,
    fileSelectError,
    className,
    maxFiles = 5,
    maxFileSizeInBytes = 3000000,
    customTypes,
  }) => {
    const translateGeneral = (code: string, placeholders?: Record<string, string>) =>
      localization.translateGeneral(`general.${code}`, placeholders);
    const translations = {
      button: `${translateGeneral("uploader_button")}`,
      or: `${translateGeneral("uploader_or")}`,
      types: customTypes ?? `${translateGeneral("uploader_types")}`,
    };

    return (
      <UploaderInner
        onDrop={onDrop}
        localization={translations}
        error={fileSelectError}
        onDropRejected={onDropRejected}
        accept={accept ?? acceptedFileTypes}
        maxSize={maxFileSizeInBytes}
        maxNumberOfFiles={maxFiles}
        maxNumberOfFilesError={translateGeneral("upload_limit_reached", { limit: maxFiles.toString() })}
        className={className}
      />
    );
  }
);

export default Uploader;
