import { observable } from "mobx";
import { Expose } from "class-transformer";
import NewAppendixListItem from "./newAppendixListItem";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class NewAppendixListItemGlobal extends NewAppendixListItem {
  @observable
  @Expose({ name: "contract_id" })
  contractId!: number;

  @observable
  @Expose({ name: "supply_point_id" })
  supplyPointId!: number;

  static ValidationRules = Object.assign(
    {
      contractId: { required: true, number: true },
      supplyPointId: { required: true, number: true },
    },
    NewAppendixListItem.ValidationRules
  );
}
