import { Button, Col, Form } from "@emanprague/ppaskit";
import { observer } from "mobx-react-lite";
import React from "react";
import { Input, Select } from "@frui.ts/bootstrap";
import iconArrowBottom from "@emanprague/ppaskit/dist/assets/icons/Arrow Simple/Down.svg";
import AddressChangeViewModel from "viewModels/addressChangeViewModel";
import { addressToHuman } from "helpers/utils";
import Searchbox from "./Searchbox";
import { getValidationMessage, isValid } from "@frui.ts/validation";

export interface AddressSelectorProps {
  vm: AddressChangeViewModel;
}

function AddressSelector(props: AddressSelectorProps) {
  const vm = props.vm;
  const { translateAttribute: ta, translateGeneral: tg } = vm.localization;

  const currentAddressLabelKey = vm.permanentDialogType
    ? vm.isCompany
      ? "current_permanent_address_company"
      : "current_permanent_address"
    : "current_address";

  return (
    <>
      <Form.Group className={vm.permanentDialogType ? "mb-3" : "mb-5"}>
        <Form.Label>{vm.translate(currentAddressLabelKey) + ":"}</Form.Label>
        &nbsp;
        {vm.permanentDialogType ? <br /> : undefined}
        <strong>{addressToHuman(vm.currentAddress)}</strong>
      </Form.Group>

      <Form.Group>
        {vm.addressTypes.map(address => (
          <Form.Check
            custom
            key={address.id}
            id={`change_type_${address.id}`}
            value={address.id}
            checked={vm.currentType === address.id}
            type="radio"
            label={address.name}
            onChange={() => vm.onChangeType(address.id)}
            className="mb-1"
          />
        ))}
      </Form.Group>

      {vm.currentType === "selectFromOther" && (
        <Form.Group as={Col} xs={12}>
          <Select
            target={vm.target}
            property="addressId"
            items={vm.otherAddresses}
            mode="key"
            keyProperty="id"
            textProperty="name"
          />
        </Form.Group>
      )}

      {vm.currentType === "newAddress" && (
        <>
          <Form.Group as={Col} xs={12}>
            <Form.Label>{vm.translate(vm.newAddressLabel)}</Form.Label>
            {!vm.manualAddress && (
              <Searchbox
                promptText={tg("ruian_search.prompt")}
                searchText={tg("ruian_search.searching")}
                emptyLabel={tg("ruian_search.empty")}
                placeholder={vm.translate("new_sending_address_placeholder")}
                id="ruian-searchbox"
                minLength={3}
                target={vm}
                property="selectedRuian"
                textProperty={vm.formatRuian}
                onSearch={vm.searchRuian}
                isValid={isValid(vm.target, "ruianId")}
                onValueChanged={vm.applyRuian}
                errorMessage={getValidationMessage(vm.target, "street")}
                debounce={500}
              />
            )}
          </Form.Group>
          <Form.Group as={Col} xs={12}>
            <Form.Label>
              <strong>
                <Button variant="link" className="font-weight-bold ml-n3 mt-n3" onClick={vm.toggleManualAddress}>
                  {vm.translate("manual_address")}
                  <img className="ml-1" width="16" height="16" draggable={false} src={iconArrowBottom} alt="" />
                </Button>
              </strong>
            </Form.Label>
          </Form.Group>

          {vm.manualAddress && (
            <>
              <Form.Group as={Col} xs={12}>
                <Form.Label>{ta("address", "country")}</Form.Label>
                <Select
                  target={vm.target}
                  property="countryId"
                  items={vm.countries}
                  mode="key"
                  keyProperty="id"
                  textProperty="name"
                />
              </Form.Group>
              <Form.Group as={Col} xs={12}>
                <Form.Label>{ta("address", "street")}</Form.Label>
                <Input type="text" target={vm.target} property="street" />
              </Form.Group>
              <Form.Row className="w-100">
                <Form.Group as={Col} xs={12} sm={6}>
                  <Form.Label>{ta("address", "house_number")}</Form.Label>
                  <Input type="text" target={vm.target} property="houseNumber" />
                </Form.Group>
                <Form.Group as={Col} xs={12} sm={6}>
                  <Form.Label>
                    {ta("address", "street_number")}{" "}
                    <span className="text-muted">{vm.translateGeneral("not_mandatory_abbr")}</span>
                  </Form.Label>
                  <Input type="text" target={vm.target} property="streetNumber" />
                </Form.Group>
              </Form.Row>
              <Form.Row className="w-100">
                <Form.Group as={Col} xs={12} sm={6}>
                  <Form.Label>{ta("address", "city")}</Form.Label>
                  <Input type="text" target={vm.target} property="city" />
                </Form.Group>
                <Form.Group as={Col} xs={12} sm={6}>
                  <Form.Label>{ta("address", "zip")}</Form.Label>
                  <Input type="text" target={vm.target} property="zip" />
                </Form.Group>
              </Form.Row>
              <Form.Group as={Col} xs={12}>
                <Form.Label>
                  {ta("address", "city_part")} <span className="text-muted">{vm.translateGeneral("not_mandatory")}</span>
                </Form.Label>
                <Input type="text" target={vm.target} property="cityPart" />
              </Form.Group>
            </>
          )}
        </>
      )}
    </>
  );
}

export default observer(AddressSelector as any) as typeof AddressSelector;
