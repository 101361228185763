import { ILocalizationService, INotificationService, SeverityLevel } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, ScreenBase, watchBusy } from "@frui.ts/screens";
import { attachAutomaticValidator, hasVisibleErrors, validate } from "@frui.ts/validation";
import ReqNewReading from "entities/reqNewReading";
import { isDoubleTariff } from "helpers/supplyPointHelper";
import { interfaces } from "inversify";
import { action, computed, observable, runInAction } from "mobx";
import ProductDetailContext from "models/productDetailContext";
import { unwrapErrorMessage } from "repositories/helpers";
import ReadingsRepository from "repositories/readingsRepository";
import EnumsService from "services/enumsService";
import HistoryDetailPageViewModel from "./historyDetailPageViewModel";

export default class SingleSelfReadingViewModel extends ScreenBase {
  static navigationName = "single";

  busyWatcher = new BusyWatcher();
  navigationName = SingleSelfReadingViewModel.navigationName;
  parent: HistoryDetailPageViewModel;
  @observable data: ReqNewReading;
  @observable successMessage?: string;
  @observable errorMessage?: string;
  @observable areHintsVisible = false;

  constructor(
    private productDetailContext: ProductDetailContext,
    public localization: ILocalizationService,
    private readingsRepository: ReadingsRepository,
    private enumsService: EnumsService,
    private notificationService: INotificationService
  ) {
    super();

    this.data = new ReqNewReading();
    this.data.deviceId = this.supplyPoint?.deviceId || "";
    this.name = this.translate("title");

    attachAutomaticValidator(this.data, ReqNewReading.ValidationRules);
  }

  @action.bound
  @watchBusy
  async confirmReading() {
    this.successMessage = undefined;
    const { partnerId, supplyPointId } = this.productDetailContext;

    if (!partnerId || !supplyPointId) {
      return;
    }

    if (validate(this.data)) {
      const response = await this.readingsRepository.setReading(this.data, partnerId, supplyPointId);

      if (response.success) {
        this.notificationService.addNotification(this.translate("self_reading_added"), SeverityLevel.success);
        await this.requestClose();
        this.parent.requestClose();
      } else {
        runInAction(() => (this.errorMessage = unwrapErrorMessage(response.payload)));
      }
    }
  }

  @action.bound
  toggleHintsVisible() {
    this.areHintsVisible = !this.areHintsVisible;
  }

  get canConfirm() {
    return !hasVisibleErrors(this.data);
  }

  @computed
  get readingFormReasons() {
    return this.enumsService.getValues("readingFormReasons").filter(x => x.active);
  }

  get supplyPoint() {
    return this.productDetailContext.supplyPoint;
  }

  get commodityType() {
    return this.productDetailContext.commodityType;
  }

  get isDoubleTariff() {
    return this.supplyPoint?.tariff && isDoubleTariff(this.supplyPoint.tariff);
  }

  get lastValue() {
    return this.supplyPoint?.lastValue ?? 0;
  }

  get lastValueNt() {
    return this.supplyPoint?.lastValueNt ?? 0;
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`products.history.${key}`);
  }

  @bound translateGeneral(key: string) {
    return this.localization.translateGeneral(`general.${key}`);
  }

  @bound translateAttribute(key: string) {
    return this.localization.translateAttribute("reading", key);
  }

  @bound async cancel() {
    await this.requestClose();
    this.parent.requestClose();
  }

  static Factory({ container }: interfaces.Context) {
    return (productDetailContext: ProductDetailContext) => {
      return new SingleSelfReadingViewModel(
        productDetailContext,
        container.get("ILocalizationService"),
        container.get(ReadingsRepository),
        container.get(EnumsService),
        container.get("INotificationService")
      );
    };
  }
}
