import { Layout, SwitchNav, Row, Col, Card } from "@emanprague/ppaskit";
import { Router } from "@frui.ts/screens";
import { registerView, View, ViewComponent } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import React from "react";
import OverviewViewModel from "viewModels/finance/overviewViewModel";
import DownloadingQueue from "@emanprague/ppaskit/dist/DownloadingQueue";
import "./style.scss";

const NavigationMenu: ViewComponent<OverviewViewModel> = observer(({ vm }) => {
  const getUrl = Router.getChildUrlFactory(vm);

  const getBalanceForNavLink = (name: string) => {
    if (name === "advances") {
      return vm.localization.formatNumber(vm.depositDueAmount) + " " + vm.translateGeneral("currency");
    }
    if (name === "invoices") {
      return vm.localization.formatNumber(vm.invoiceDueAmount) + " " + vm.translateGeneral("currency");
    }
    return undefined;
  };

  const navLinks = vm.children.map((x: any) => ({
    id: x.navigationName,
    href: getUrl(x),
    title: (
      <span>
        {x.name}
        <br /> {getBalanceForNavLink(x.navigationName)}
      </span>
    ),
  }));

  return <SwitchNav links={navLinks} activeId={vm.activeChild?.navigationName} variant="light" />;
});

const overviewListView: ViewComponent<OverviewViewModel> = observer(({ vm }) => (
  <Layout fluid="xl" className="mt-4 mt-sm-5 mb-3">
    <Row className="mb-5">
      <Col xs={12} md={4} className="mb-4 mb-md-0 mx-3 mx-sm-0">
        <h2 className="display-2 mb-2">{vm.name}</h2>
        {vm.translateGeneral("balance_summary")}:{" "}
        <span className={"font-weight-bold" + (vm.totalBalance < 0 ? " text-danger" : "")}>
          {vm.localization.formatNumber(vm.totalBalance)} {vm.translateGeneral("currency")}
        </span>
      </Col>
      <Col xs={12} md={4} className="text-center">
        <NavigationMenu vm={vm} />
      </Col>
    </Row>
    <Row>
      <Col xs={12}>
        <Card>
          <Card.Body>
            <View vm={vm.activeChild} />
          </Card.Body>
        </Card>
      </Col>
    </Row>
    <DownloadingQueue title={vm.translateGeneral("downloading")} onClick={vm.handleDownloadItemClick} items={vm.downloadsList} />
  </Layout>
));

registerView(overviewListView, OverviewViewModel);
