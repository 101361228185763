import { BusyWatcher, ScreenBase, watchBusy } from "@frui.ts/screens";
import { ILocalizationService, INotificationService, SeverityLevel } from "@emanprague/shared-services";
import { interfaces } from "inversify";
import { bound } from "@frui.ts/helpers";
import { action, observable, runInAction } from "mobx";
import { attachAutomaticValidator, hasVisibleErrors, validate } from "@frui.ts/validation";
import AdminRepository from "repositories/adminRepository";
import { unwrapErrorMessage } from "repositories/helpers";
import { assignTargetPropertiesOnly } from "helpers/utils";
import ReqProductCard from "entities/reqProductCard";
import type ProductCardListItemCategory from "entities/productCardListItemCategory";

const navigationName = "new";

export default class ProductCardDetailViewModel extends ScreenBase {
  static navigationName = navigationName;

  navigationName = navigationName;
  busyWatcher = new BusyWatcher();

  @observable data: ReqProductCard;
  @observable isNewProductCard = true;
  @observable errorMessage?: string;

  constructor(
    public productCardId: number | undefined,
    private refreshDataCallback: () => void,
    public suggestedOrder: number | undefined,
    public suggestedCategory: ProductCardListItemCategory | undefined,
    public localization: ILocalizationService,
    private adminRepository: AdminRepository,
    private notificationService: INotificationService
  ) {
    super();

    this.name = this.translate("title");
    this.data = new ReqProductCard();

    if (productCardId) {
      this.navigationName = "/detail/" + String(productCardId);
      this.isNewProductCard = false;
    }

    attachAutomaticValidator(this.data, ReqProductCard.ValidationRules);
  }

  protected onInitialize(): Promise<any> | void {
    this.loadData();
  }

  @watchBusy
  async loadData() {
    if (!this.productCardId) {
      this.onChangeCategory(this.suggestedCategory || "other");
      runInAction(() => (this.data.order = this.suggestedOrder || 1));
      return;
    }

    const response = await this.adminRepository.getProductCardDetail(this.productCardId);
    if (response.success) {
      runInAction(() => assignTargetPropertiesOnly(this.data, response.payload));
    } else {
      runInAction(() => (this.errorMessage = unwrapErrorMessage(response.payload)));
    }
  }

  @action.bound
  clearDates() {
    this.data.validFrom = undefined;
    this.data.validTo = undefined;
  }

  @action.bound
  @watchBusy
  async confirmRequest() {
    this.errorMessage = undefined;

    validate(this.data);
    if (!this.canConfirm) {
      return;
    }

    const response = this.isNewProductCard
      ? await this.adminRepository.addProductCard(this.data)
      : await this.adminRepository.updateProductCard(this.productCardId!, this.data);
    if (response.success) {
      this.isNewProductCard
        ? this.notificationService.addNotification(this.translate("create_success"), SeverityLevel.success)
        : this.notificationService.addNotification(this.translate("update_success"), SeverityLevel.success);
      this.refreshDataCallback();
      this.requestClose();
    } else {
      runInAction(() => (this.errorMessage = unwrapErrorMessage(response.payload)));
    }
  }

  get canConfirm() {
    return !hasVisibleErrors(this.data);
  }

  get categories() {
    const categories: { id: ProductCardListItemCategory; name: string }[] = [
      { id: "other", name: this.translate("category_name.other") },
      { id: "cng", name: this.translate("category_name.cng") },
      { id: "axa", name: this.translate("category_name.axa") },
    ];
    return categories;
  }

  @action.bound
  onChangeCategory(category: ProductCardListItemCategory) {
    this.data.category = category;
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`admin.product_cards.detail.${key}`);
  }

  @bound translateConditions(key: string) {
    return this.localization.translateGeneral(`admin.display_condition.${key}`);
  }

  @bound translateGeneral(key: string) {
    return this.localization.translateGeneral(`general.${key}`);
  }

  static Factory({ container }: interfaces.Context) {
    return (
      productCardId: number | undefined,
      refreshDataCallback: () => void,
      suggestedOrder?: number,
      suggestedCategory?: ProductCardListItemCategory
    ) => {
      return new ProductCardDetailViewModel(
        productCardId,
        refreshDataCallback,
        suggestedOrder,
        suggestedCategory,
        container.get("ILocalizationService"),
        container.get(AdminRepository),
        container.get("INotificationService")
      );
    };
  }
}
