import { Expose, Transform } from "class-transformer";
import formatISO from "date-fns/formatISO";
import merge from "lodash/merge";
import { observable } from "mobx";
import { EntityValidationRules } from "services/validation/entityValidationRules";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class ReqNewReading {
  /**
   * ReadingReason enumeration key
   */
  @observable
  @Expose({ name: "reading_form_reason_id" })
  readingFormReasonId!: number;

  /**
   * Reading date
   */
  @observable
  @Transform(value => (value ? new Date(value) : undefined), { toClassOnly: true })
  @Transform(value => (value ? formatISO(value, { representation: "date" }) : undefined), { toPlainOnly: true })
  date!: Date;

  /**
   * Device number
   */
  @observable
  @Expose({ name: "device_id" })
  deviceId!: string;

  /**
   * VT / gas
   */
  @observable
  value!: number;

  /**
   * NT
   */
  @observable
  @Expose({ name: "value_nt" })
  valueNt?: number;

  static ValidationRules = {
    readingFormReasonId: { required: true, number: true },
    date: { required: true, date: true },
    deviceId: { required: true },
    value: { required: true, number: true },
    valueNt: { number: true },
  };
}

merge(ReqNewReading.ValidationRules, {
  deviceId: { maxLength: { parameter: 10, translationCode: "validators.maxLength10" } },
} as EntityValidationRules<ReqNewReading>);
