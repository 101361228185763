import { observable } from "mobx";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class SupplyPointListItemContract {
  id!: number;

  @observable
  code!: string;

  @observable
  active!: boolean;

  static ValidationRules = {
    id: { required: true, number: true },
    code: { required: true },
    active: { required: true },
  };
}
