export type ArrayPropertyItemType<T, K extends keyof T> = Required<T>[K] extends (infer I)[] ? I : never;
export type PropertyType<T, P> = P extends keyof T ? Required<T>[P] : never;

export default abstract class EnumsServiceBase<TContainer> {
  protected container: TContainer;

  async initialize() {
    this.container = await this.loadContainer();
  }

  hasValues<K extends keyof TContainer>(name: K) {
    return !!this.container[name];
  }

  getValues<E extends keyof TContainer>(name: E): ArrayPropertyItemType<TContainer, E>[] {
    const values = this.container[name];

    if (!values) {
      throw new Error(`Enumerations container does not contain values for key ${name}`);
    }

    return values as any;
  }

  getValue<E extends keyof TContainer, K extends PropertyType<ArrayPropertyItemType<TContainer, E>, "id">>(name: E, key: K) {
    return this.getValues(name).find((x: any) => x.id === key);
  }

  protected abstract loadContainer(): Promise<TContainer>;
}
