import { Col, Header, Layout, NavDropdown, Row, SideNav } from "@emanprague/ppaskit";
import Logo from "@emanprague/ppaskit/dist/assets/images/Logo_PPAS.svg";
import { Router } from "@frui.ts/screens";
import { registerView, View, ViewComponent } from "@frui.ts/views";
import { observer, Observer } from "mobx-react-lite";
import React from "react";
import AdminRootViewModel from "viewModels/adminRootViewModel";
import "./style.scss";
import { IAdminModule } from "../viewModels/types";

const HeaderView: ViewComponent<AdminRootViewModel> = observer(({ vm }) => {
  const userMenu = (
    <NavDropdown alignRight renderMenuOnMount title={vm.userName} id="user-nav-dropdown" className="user-nav-dropdown">
      <NavDropdown.Item onClick={vm.logout}>{vm.localization.translateGeneral("menu.logout")}</NavDropdown.Item>
    </NavDropdown>
  );

  return (
    <Header
      expand="lg"
      containerWrap={true}
      containerFluid="xl"
      className="bg-white shadow"
      brand={<img src={Logo} alt="" />}
      brandHref={"#/" + vm.children[0]?.navigationName}
      usermenu={userMenu}
    />
  );
});

const MenuView: ViewComponent<AdminRootViewModel> = observer(({ vm }) => {
  const getUrl = Router.getChildUrlFactory(vm);

  // If the menu would have single section, don't bother to even show that
  if (vm.children.length === 1) {
    return null;
  }

  const createMenuItems = (children: IAdminModule[], menuPart?: "admin" | "content") => {
    return children
      .filter(item => menuPart === item.menuPart)
      .map((x: any) => ({
        id: x.navigationName,
        title: x.name,
        href: getUrl(x.navigationName),
        className: x.className,
        suffix: x.suffix,
      }));
  };

  const defaultSections = createMenuItems(vm.children);
  const contentSections = createMenuItems(vm.children, "content");
  const adminSection = createMenuItems(vm.children, "admin");

  return (
    <SideNav
      activeId={vm.activeChild?.navigationName}
      links={defaultSections}
      additionalParts={[
        {
          label: vm.translate("content_management.title"),
          links: contentSections,
        },
        {
          links: adminSection,
        },
      ]}
    />
  );
});

const AppendedViews: ViewComponent<AdminRootViewModel> = observer(({ vm }) => (
  <>
    {vm.appendedViewModels.map(x => (
      <View key={x.name} vm={x} />
    ))}
  </>
));

const rootView: ViewComponent<AdminRootViewModel> = ({ vm }) => {
  return (
    <>
      <Observer>
        {() => (
          <div className="toast__container">
            {vm.toasts.map(item => (
              <View key={item.key} vm={item} useLifecycle />
            ))}
          </div>
        )}
      </Observer>
      <HeaderView vm={vm} />

      <Layout fluid="xl" className="my-3">
        <Row noGutters className="mt-5">
          <Col xs={12} lg="auto" className="px-3 pl-sm-0 pr-sm-3 mb-4 mb-lg-0 mr-O mr-lg-4">
            <MenuView vm={vm} />
          </Col>
          <Col xs={12} lg={true}>
            <Observer>{() => <View vm={vm.activeChild} />}</Observer>
          </Col>
        </Row>
      </Layout>

      <AppendedViews vm={vm} />

      <Observer>
        {() => (
          <>
            {vm.confirmationService.dialogs.map((dialog, i) => (
              <View key={i} vm={dialog} useLifecycle />
            ))}
          </>
        )}
      </Observer>

      <div className="container-xl">
        <div className="pb-4 version">
          © {new Date().getFullYear()} Pražská plynárenská, a.s., {process.env.REACT_APP_VERSION ?? "0.0.1"} (
          {process.env.REACT_APP_VERSION_DEPLOYED_AT ?? "unknown"})
        </div>
      </div>
    </>
  );
};

registerView(rootView, AdminRootViewModel);
