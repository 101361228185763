import { preventDefault, registerView, ViewComponent } from "@frui.ts/views";
import ModalInvoiceMailingMethodViewModel from "viewModels/products/myProducts/detailPages/optionsDeposit/modalInvoiceMailingMethodViewModel";
import { observer } from "mobx-react-lite";
import React from "react";
import { Form, Modal, Col, Button, LoaderOverlay } from "@emanprague/ppaskit";
import { Input, Check } from "@frui.ts/bootstrap";
import SupplyPointSelectByAccount from "controls/twoway/SupplyPointSelectByAccount";
import AddressSelector from "controls/twoway/AddressSelector";

const modalInvoiceMailingMethodView: ViewComponent<ModalInvoiceMailingMethodViewModel> = observer(({ vm }) => (
  <Modal centered show onHide={vm.requestClose}>
    <Modal.Header closeButton>
      <h2 className="display-4 m-0">{vm.name}</h2>
    </Modal.Header>
    <LoaderOverlay loading={vm.busyWatcher.isBusy}>
      {!vm.isChangePossible && (
        <div className="d-flex align-items-center justify-content-center mb-5 mt-3 mx-auto h-100" style={{ width: "60%" }}>
          {vm.translate("invoice_payment_method_change_not_possible")}
        </div>
      )}

      {vm.isChangePossible && (
        <>
          <Modal.Body>
            <Form className="modal-form" onSubmit={preventDefault(vm.confirmChange)}>
              <Form.Row className="form-row-centered">
                <Form.Group as={Col} xs={12} className="text-center">
                  <Check
                    target={vm.data}
                    property="type"
                    label={vm.translate("by_email")}
                    value="email"
                    custom
                    type="radio"
                    inline
                    className="mr-4"
                  />
                  <Check
                    target={vm.data}
                    property="type"
                    label={vm.translate("by_post")}
                    value="post"
                    custom
                    type="radio"
                    inline
                  />
                </Form.Group>
                {vm.data.type === "email" && (
                  <Form.Group as={Col} xs={12}>
                    <Form.Label>{vm.translate("email")}</Form.Label>
                    <Input target={vm.data} property="email" />
                  </Form.Group>
                )}

                {vm.data.type === "post" && <AddressSelector vm={vm.addressChangeVM} />}

                <SupplyPointSelectByAccount
                  defaultSupplyPoints={vm.currentSupplyPointsWithIcons}
                  supplyPoints={vm.additionalSupplyPointsWithIcons}
                  target={vm.data}
                  localization={vm.localization}
                  supplyPointErrors={vm.supplyPointsErrors}
                  property="supplyPointIds"
                />

                <Form.Group as={Col} xs={12}>
                  {vm.errorMessage && <Form.Control.Feedback type="invalid">{vm.errorMessage}</Form.Control.Feedback>}
                </Form.Group>
              </Form.Row>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" className="order-2" onClick={vm.confirmChange}>
              {vm.localization.translateGeneral("general.confirm_changes_button")}
            </Button>
            <Button onClick={vm.requestClose} variant="secondary" className="order-1">
              {vm.localization.translateGeneral("general.cancel_button")}
            </Button>
          </Modal.Footer>
        </>
      )}
    </LoaderOverlay>
  </Modal>
));

registerView(modalInvoiceMailingMethodView, ModalInvoiceMailingMethodViewModel);
