import * as sharedServices from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, Router, watchBusy } from "@frui.ts/screens";
import { action, observable } from "mobx";
import ContinuousListViewModelBase from "viewModels/continuousListViewModelBase";
import { IAdminModule } from "viewModels/types";
import AdminRepository from "repositories/adminRepository";
import ConfirmationService from "services/confirmationService";
import ProductCardListItem from "entities/productCardListItem";
import ProductCardDetailViewModel from "./productCardDetailViewModel";
import ProductCardsFilter from "models/productCardsFilter";
import ProductCardPreviewViewModel from "./productCardPreviewViewModel";
import type ProductCardListItemCategory from "entities/productCardListItemCategory";

const navigationName = "product-cards";

@Router.registerRoute({ name: Router.Self, route: `${navigationName}` })
export default class ProductCardsPageViewModel
  extends ContinuousListViewModelBase<
    ProductCardListItem,
    ProductCardsFilter,
    ProductCardDetailViewModel | ProductCardPreviewViewModel
  >
  implements IAdminModule
{
  navigationName = navigationName;
  orderIndex = 60;
  menuPart = "content";
  busyWatcher = new BusyWatcher();

  @observable categories: { id: ProductCardListItemCategory; name: string }[];
  @observable errorMessage?: string;

  constructor(
    public localization: sharedServices.ILocalizationService,
    private detailFactory: ReturnType<typeof ProductCardDetailViewModel.Factory>,
    private previewFactory: ReturnType<typeof ProductCardPreviewViewModel.Factory>,
    private adminRepository: AdminRepository,
    private confirmationService: ConfirmationService,
    private notificationService: sharedServices.INotificationService
  ) {
    super();

    this.categories = [
      { id: "other", name: this.translate("detail.category_name.other") },
      { id: "cng", name: this.translate("detail.category_name.cng") },
      { id: "axa", name: this.translate("detail.category_name.axa") },
    ];
    this.name = this.translate("title");
  }

  protected onInitialize(): Promise<any> | void {
    return this.loadData();
  }

  // suggests order for new product card
  // returns highest order + 1 out of loaded items = filters are applied
  @bound suggestOrder() {
    const maxOrder = this.items.reduce((max, item) => Math.max(max, item.order), 0);
    return maxOrder + 1;
  }

  @bound
  @watchBusy
  async loadData() {
    const response = await this.adminRepository.getProductCards(this.pagingFilter, this.filter);
    if (response.success) {
      this.setData(response.payload);
    }
  }

  @action.bound
  reloadData() {
    this.applyFilterAndLoadDebounced();
    this.selectedItems.clear();
  }

  @bound
  addProductCard() {
    const suggestedOrder = this.suggestOrder();
    const suggestedCategory = this.filter.category as ProductCardListItemCategory;
    return this.tryActivateChild(this.detailFactory(undefined, this.reloadData, suggestedOrder, suggestedCategory));
  }

  @action
  openDetail(productCardId: number) {
    return this.tryActivateChild(this.detailFactory(productCardId, this.reloadData));
  }

  @action
  openPreview(productCardId: number) {
    return this.tryActivateChild(this.previewFactory(productCardId));
  }

  @bound
  @watchBusy
  async openDelete(productCardIds: number[]) {
    const confirm = await this.confirmationService.showConfirmation(
      this.translate("delete_confirmation_" + this.localization.getPluralForm(productCardIds.length)),
      undefined,
      { variant: "danger", text: this.translateGeneral("delete_confirmation_button") },
      this.translateGeneral("cancel_button")
    );

    if (!confirm) {
      return;
    }

    const response = await this.adminRepository.deleteProductCards(productCardIds);
    if (response.success) {
      this.notificationService.addNotification(
        this.translateGeneral("request_sent_success"),
        sharedServices.SeverityLevel.success
      );
      this.reloadData();
    }
  }

  @action.bound
  clearFilter(...properties: (keyof ProductCardsFilter)[]) {
    properties.forEach(property => {
      this.filter[property] = undefined;
    });
    this.applyFilterAndLoad();
  }

  get deleteButtonLabel() {
    return `${this.translateGeneral("delete_selected_button")} (${this.selectedItems.size})`;
  }

  get selectedProductCardsIds() {
    const productCards: ProductCardListItem[] = Array.from(this.selectedItems);
    return productCards.map(item => item.id);
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected resetFilterValues(filter: ProductCardsFilter) {}

  protected createFilter() {
    return new ProductCardsFilter();
  }

  protected findNavigationChild(navigationName?: string): any {
    if (navigationName === ProductCardDetailViewModel.navigationName) {
      return this.addProductCard();
    }
    return super.findNavigationChild(navigationName);
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`admin.product_cards.${key}`);
  }

  @bound translateGeneral(key: string) {
    return this.localization.translateGeneral(`general.${key}`);
  }
}
