import * as React from "react";

function SvgClose(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path d="M3.485 20.485l16.97-16.97M3.485 3.515l16.97 16.97" stroke="currentColor" />
    </svg>
  );
}

export default SvgClose;
