import { registerView, ViewComponent } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import React from "react";
import { Modal, Button, LoaderOverlay } from "@emanprague/ppaskit";
import { Check } from "@frui.ts/bootstrap";
import ModalDocumentsViewModel from "viewModels/products/myProducts/detailPages/contract/modalDocumentsViewModel";
import DOMPurify from "dompurify";

const modalDocumentsView: ViewComponent<ModalDocumentsViewModel> = observer(({ vm }) => {
  return (
    <Modal centered show onHide={() => {}} onEscapeKeyDown={vm.requestClose}>
      <LoaderOverlay loading={vm.busyWatcher.isBusy}>
        <Modal.Header closeButton={false}>
          <Modal.Title as="h1">{vm.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="table wider-row last-column-right mt-n2">
            <tbody>
              {vm.attachments.map((attachment, index) => (
                <tr key={`attachments-${index}`}>
                  <th
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(attachment.description || attachment.fileName) }}></th>
                  <td>
                    <a
                      className="text-indigo font-weight-medium"
                      href={attachment.file}
                      target="_blank"
                      rel="noopener noreferrer">
                      {vm.translateGeneral(attachment.publicUri ? "show_button" : "download_button")}
                    </a>
                  </td>
                </tr>
              ))}
              {/* // ! Temporarily hide the button
              <tr key={`attachments-download-all`}>
                <td colSpan={2}>
                  <Button variant="secondary" onClick={vm.downloadAll}>
                    <img src={iconDownloadBlue} width={16} height={16} alt="" className="mr-1 ml-n1 my-n1" />{" "}
                    {vm.translateGeneral("download_all_button")}
                  </Button>
                </td>
              </tr>
              */}
            </tbody>
          </table>
          {vm.confirmMode && (
            <Check custom className="mt-5" label={vm.translate("appendix_confirm_text")} target={vm} property="checked" />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={vm.requestClose} variant="secondary">
            {vm.translateGeneral("close_button")}
          </Button>
          {vm.confirmMode && (
            <Button disabled={!vm.checked} onClick={vm.confirm} variant="primary">
              {vm.translateGeneral("sign_button")}
            </Button>
          )}
        </Modal.Footer>
      </LoaderOverlay>
    </Modal>
  );
});

registerView(modalDocumentsView, ModalDocumentsViewModel);
