import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import iconDropdownUpGray from "../assets/icons/Arrow Simple/Up.svg";
import iconDropdownDownGray from "../assets/icons/Arrow Simple/Down.svg";

export interface RequestCardProps {
  description: string;
  number: string;
  state: "in_progress" | "done" | "rejected";
  label: string;
  isOpenByDefault?: boolean;
}

type RequestCard = RequestCardProps & Omit<React.InputHTMLAttributes<HTMLInputElement>, "size">;

const RequestCard: React.FC<RequestCard> = ({
  className,
  description,
  number,
  state,
  label,
  isOpenByDefault,
  children,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(isOpenByDefault || false);

  const arrowIcon = (
    <img
      className="mr-2"
      width="26"
      height="26"
      draggable={false}
      src={isOpen ? iconDropdownUpGray : iconDropdownDownGray}
      alt=""
    />
  );

  return (
    <div className="request-card-body rounded">
      <Row className="cursor-pointer align-items-start" noGutters onClick={() => setIsOpen(!isOpen)}>
        <Col xs={11} md="auto" className="mb-1 mb-md-0 order-0 mr-lg-3 request-title">
          <h4 className="display-4 m-0 text-break" style={{ wordWrap: "break-word" }}>
            {description}
          </h4>
        </Col>
        <Col xs={1} lg="auto" className="d-flex d-md-none justify-content-end order-md-4">
          {arrowIcon}
        </Col>
        <Col xs={12} lg="auto" className="mb-1 mb-md-0 order-md-3 order-lg-1">
          <span className="request-id">(ID {number})</span>
        </Col>
        <Col md="auto" className="ml-auto d-flex align-items-center order-md-1 order-lg-3">
          <span className="mr-4">
            {state === "in_progress" && <div className="request-badge active rounded">{label}</div>}
            {state === "done" && <div className="request-badge done rounded">{label}</div>}
            {state === "rejected" && <div className="request-badge rejected rounded">{label}</div>}
          </span>
          <span className="d-none d-md-inline">{arrowIcon}</span>
        </Col>
      </Row>
      {isOpen && (
        <Row className="mt-3 mb-md-0">
          <Col xs={12}>{children}</Col>
        </Row>
      )}
    </div>
  );
};

export default RequestCard;
