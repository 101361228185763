import { observable } from "mobx";
import { Expose, Type } from "class-transformer";
import AdvanceListItem from "./advanceListItem";
import Reminder from "./reminder";
import Charge from "./charge";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class Advance extends AdvanceListItem {
  @observable
  @Type(() => Reminder)
  reminders!: Reminder[];

  /**
   * Enumeration::GatewayPayment ids
   */
  @observable
  @Expose({ name: "gateway_method_ids" })
  gatewayMethodIds?: number[];

  @observable
  @Type(() => Charge)
  charges?: Charge[];

  static ValidationRules = Object.assign(
    {
      reminders: { required: true },
    },
    AdvanceListItem.ValidationRules
  );
}
