import { Expose } from "class-transformer";
import { observable } from "mobx";
import { entityToFormData } from "repositories/helpers";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
class ReqChangeAdvanceRequest {
  @observable
  amount!: number;

  /**
   * Change reason id from enumeration
   */
  @observable
  @Expose({ name: "advance_change_reason_id" })
  advanceChangeReasonId?: number;

  /**
   * Advance frequency from enumeration
   */
  @observable
  @Expose({ name: "advance_frequency_id" })
  advanceFrequencyId!: number;

  /**
   * User agrees with presented statement / terms
   */
  @observable
  statement!: boolean;

  @observable
  @Expose({ name: "file[]" })
  file?: Blob[];

  static ValidationRules = {
    amount: { required: true, number: true },
    advanceChangeReasonId: { number: true },
    advanceFrequencyId: { required: true, number: true },
    // do NOT add statement here, it is NOT required
  };
}

export default class CustomReqChangeAdvanceRequest extends ReqChangeAdvanceRequest {
  toFormData() {
    const data = entityToFormData(this);

    // generic entityToFomData is creating "[object OBJECT]" string instead of file
    data.delete("file[]");
    if (this.file && this.file.length > 0) {
      this.file.forEach(item => data.append("file[]", item));
    }

    return data;
  }
}
