import { observable } from "mobx";

export default class SupplyPointButtonSettings {
  @observable
  show_deposits: boolean;

  @observable
  change_deposit_amount: boolean;

  @observable
  change_deposit_method: boolean;

  @observable
  change_supply_point_info: boolean;

  @observable
  start_transfer: boolean;

  @observable
  new_self_reading: boolean;
}
