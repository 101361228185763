import { Button, Card, Col, Form, LoaderOverlay, Row, Stepper } from "@emanprague/ppaskit";
import iconArrowLeftSimple from "@emanprague/ppaskit/dist/assets/icons/Arrow Simple Light/Left.svg";
import { Check, Input, Select, ValidationError } from "@frui.ts/bootstrap";
import { preventDefault, registerView, View, ViewComponent } from "@frui.ts/views";
import PhoneInput from "controls/twoway/PhoneInput";
import { getCommodityUnit } from "helpers/commodityHelper";
import { observer } from "mobx-react-lite";
import CommodityType from "models/commodityType";
import React from "react";
import CustomerTransferViewModel from "viewModels/products/myProducts/detailPages/supplyPoint/customerTransferViewModel";

const customerTransferView: ViewComponent<CustomerTransferViewModel> = observer(({ vm }) => (
  <form onSubmit={preventDefault(vm.goToNextStep)}>
    <Row>
      <Col className="d-flex justify-content-center">
        <h2 className="display-2 mb-2">{vm.name}</h2>
      </Col>
    </Row>
    <Row>
      <Col className="d-flex justify-content-center mb-4">
        <span className="text-muted mb-2">
          {vm.localization.translateAttribute("supply_point", vm.commodityType === CommodityType.Electricity ? "ean" : "eic")}
          {": "}
          {vm.supplyPoint?.code}
        </span>
      </Col>
    </Row>
    <Row className="m-auto">
      <Col>
        <Stepper step={vm.currentStep} steps={vm.steps} />
      </Col>
    </Row>
    <Row className="d-flex justify-content-center">
      <Col lg={6}>
        <LoaderOverlay loading={vm.busyWatcher.isBusy} className="h-100">
          <Card className="mx-n3 mx-sm-0 mb-3 h-100">
            <Card.Body className="d-flex flex-column justify-content-between">
              <Form>{vm.isInitialized && <View vm={vm} context={vm.currentStepString} />}</Form>

              {vm.manualValidator.errors.base && (
                <div className="w-100 d-flex justify-content-end">
                  <Form.Control.Feedback type="invalid">{vm.manualValidator.errors.base}</Form.Control.Feedback>
                </div>
              )}

              <div className="w-100 d-flex justify-content-between align-items-end px-3">
                <div>
                  {vm.currentStep > 1 ? (
                    <Button onClick={vm.goToPreviousStep} variant="secondary">
                      <img className="mr-2" width="18" height="18" draggable={false} src={iconArrowLeftSimple} alt="" />
                      {vm.translateGeneral("back_button")}
                    </Button>
                  ) : (
                    <Button variant="secondary" onClick={vm.requestClose}>
                      {vm.translateGeneral("cancel_button")}
                    </Button>
                  )}
                </div>

                <Button type="submit" disabled={!vm.canGoToNextStep}>
                  {vm.translateGeneral(vm.currentStep < 3 ? "continue_button" : "send_button")}
                </Button>
              </div>
            </Card.Body>
          </Card>
        </LoaderOverlay>
      </Col>
    </Row>
  </form>
));

registerView(customerTransferView, CustomerTransferViewModel);

const step1: ViewComponent<CustomerTransferViewModel> = observer(({ vm }) => (
  <>
    <Form.Row className="form-row-centered">
      <Form.Group as={Col} xs={8} className="d-flex justify-content-center">
        <Form.Label className="my-4 font-size-md">
          <strong>{vm.translate("enter_current_state_commodity" + vm.commodityType.toString())}</strong>
        </Form.Label>
      </Form.Group>
      {vm.commodityType === CommodityType.Gas && (
        <Form.Group as={Col} xs={8}>
          <Form.Label>
            {vm.translate("current_state_label_gas")} ({getCommodityUnit(vm.commodityType)})
          </Form.Label>
          <Input target={vm.dataStep1} property="value" onValueChanged={vm.clearManualError} />
          <Form.Label className="text-muted mt-2 mb-4">{vm.translate("current_state_hint")}</Form.Label>
        </Form.Group>
      )}
      {vm.commodityType === CommodityType.Electricity && (
        <>
          <Form.Group as={Col} xs={8}>
            <Form.Label>{vm.translate("current_state_label_high_tariff")}</Form.Label>
            <Input target={vm.dataStep1} property="value" onValueChanged={vm.clearManualError} />
            <Form.Label className="text-muted mt-2 mb-4">{vm.translate("current_state_hint")}</Form.Label>
          </Form.Group>
          {vm.isDoubleTariff && (
            <Form.Group as={Col} xs={8}>
              <Form.Label>{vm.translate("current_state_label_low_tariff")}</Form.Label>
              <Input target={vm.dataStep1} property="valueNt" onValueChanged={vm.clearManualError} />
              <Form.Label className="text-muted mt-2 mb-4">{vm.translate("current_state_hint")}</Form.Label>
            </Form.Group>
          )}
        </>
      )}
      {vm.supplyPoint && (
        <Form.Group as={Col} xs={8}>
          <table className="table wider-row aligned mb-5">
            <tbody>
              {vm.commodityType === CommodityType.Electricity && (
                <tr>
                  <th>{vm.translate("distribution_tariff")}</th>
                  <td>{vm.supplyPoint.tariff}</td>
                </tr>
              )}
              <tr>
                <th>{vm.translate("last_reading")}</th>
                <td className="text-gray">
                  {vm.isDoubleTariff ? (
                    <>
                      {vm.localization.formatNumber(vm.supplyPoint.lastValue ?? 0)} {getCommodityUnit(vm.commodityType)}{" "}
                      {vm.translateGeneral("high_tariff_abbr")}, {vm.localization.formatNumber(vm.supplyPoint.lastValueNt ?? 0)}{" "}
                      {getCommodityUnit(vm.commodityType)} {vm.translateGeneral("low_tariff_abbr")}
                    </>
                  ) : (
                    <>
                      {vm.localization.formatNumber(vm.supplyPoint.lastValue ?? 0)} {getCommodityUnit(vm.commodityType)}
                    </>
                  )}
                </td>
              </tr>
              <tr>
                <th className="mt-2">{vm.translate("device_number_commodity" + vm.commodityType.toString())}</th>
                <td className="text-gray">{vm.supplyPoint.deviceId}</td>
              </tr>
            </tbody>
          </table>
        </Form.Group>
      )}
      <Form.Group as={Col} xs={8}>
        <Check
          custom
          label={vm.translate("device_changed_commodity" + vm.commodityType.toString())}
          target={vm.dataStep1}
          property="deviceChanged"
          onValueChanged={() => vm.handleNewDeviceIdChanged()}
        />
      </Form.Group>
      {vm.dataStep1 && vm.dataStep1.deviceChanged && (
        <Form.Group as={Col} xs={8}>
          <Form.Label className="mt-3">{vm.translate("new_device_number_commodity" + vm.commodityType.toString())}</Form.Label>
          <Input target={vm.dataStep1} property="newDeviceId" />
        </Form.Group>
      )}
    </Form.Row>
  </>
));

registerView(step1, CustomerTransferViewModel, "step1");

const step2: ViewComponent<CustomerTransferViewModel> = observer(({ vm }) => {
  const ta = vm.localization.translateAttribute;

  return (
    <>
      <Form.Row className="form-row-centered">
        <Form.Group className="text-center">
          <Form.Label className="my-4 w-75 font-size-md">
            <strong>{vm.translate("new_customer_credentials")}</strong>
          </Form.Label>
        </Form.Group>
        <Form.Group as={Col} xs={8}>
          <Select
            target={vm.transferRequest}
            property="personType"
            items={vm.personTypes}
            mode="key"
            keyProperty="id"
            textProperty="name"
          />
        </Form.Group>
        {vm.transferRequest.personType === "natural_person" && (
          <>
            <Form.Group as={Col} xs={8}>
              <Form.Label>{ta("partner", "first_name")}</Form.Label>
              <Input target={vm.transferRequest.naturalPerson} property="firstName" />
            </Form.Group>
            <Form.Group as={Col} xs={8}>
              <Form.Label>{ta("partner", "last_name")}</Form.Label>
              <Input target={vm.transferRequest.naturalPerson} property="lastName" />
            </Form.Group>
          </>
        )}
        {vm.transferRequest.personType === "legal_person" && (
          <>
            <Form.Group as={Col} xs={8}>
              <Form.Label>{vm.translate("company_name")}</Form.Label>
              <Input target={vm.transferRequest.legalPerson} property="name" />
            </Form.Group>
            <Form.Group as={Col} xs={8}>
              <Form.Label>{vm.translate("company_number")}</Form.Label>
              <Input target={vm.transferRequest.legalPerson} property="ic" />
            </Form.Group>
          </>
        )}
        <Form.Group as={Col} xs={8}>
          <Form.Label>{ta("user", "email")}</Form.Label>
          <Input target={vm.dataStep2} property="email" onValueChanged={vm.clearManualError} />
        </Form.Group>
        <Form.Group as={Col} xs={8}>
          <Form.Label>{ta("user", "phone")}</Form.Label>
          <PhoneInput target={vm.dataStep2} property="phone" onValueChanged={vm.clearManualError} />
        </Form.Group>
      </Form.Row>
    </>
  );
});

registerView(step2, CustomerTransferViewModel, "step2");

const step3: ViewComponent<CustomerTransferViewModel> = observer(({ vm }) => {
  const ta = vm.localization.translateAttribute;

  return (
    <>
      <Form.Group className="text-center mb-n1">
        <Form.Label className="mt-4 font-size-md">
          <strong>{vm.translate("new_place_summary")}</strong>
        </Form.Label>
      </Form.Group>
      <Form.Group className="text-center">
        {vm.transferRequest.naturalPerson?.firstName && vm.transferRequest.naturalPerson?.lastName && (
          <Form.Text className="font-size-sm">
            {vm.transferRequest.naturalPerson?.firstName} {vm.transferRequest.naturalPerson?.lastName}
          </Form.Text>
        )}
        {vm.dataStep2.email && <Form.Text className="font-size-sm">{vm.dataStep2.email}</Form.Text>}
      </Form.Group>
      <Form.Group className="text-center mb-n1">
        <Form.Label className="mt-3 font-size-md">
          <strong>{vm.translate("current_state_commodity" + vm.commodityType.toString())}</strong>
        </Form.Label>
      </Form.Group>
      <Form.Group className="text-center">
        {vm.dataStep1.value && (
          <Form.Text className="font-size-sm">
            {vm.commodityType === CommodityType.Electricity && <>{vm.translateGeneral("high_tariff")}:</>} {vm.dataStep1.value}{" "}
            {getCommodityUnit(vm.commodityType)}
          </Form.Text>
        )}
        {vm.dataStep1.valueNt && (
          <Form.Text className="font-size-sm">
            {vm.translateGeneral("low_tariff")}: {vm.dataStep1.valueNt} {getCommodityUnit(vm.commodityType)}
          </Form.Text>
        )}
      </Form.Group>

      <Form.Row className="form-row-centered">
        <Form.Group className="text-center mb-n1">
          <Form.Label className="mt-3 font-size-md">
            <strong>{vm.translate("invoice_shipping")}</strong>
          </Form.Label>
        </Form.Group>
        <Form.Group as={Col} xs={8} className="text-center mt-3">
          <div className="d-flex justify-content-center">
            <Check
              onValueChanged={vm.handleSentToEmailChanged}
              custom
              label={vm.translate("by_email")}
              target={vm.dataStep3}
              property="sentToEmail"
              className="mr-3"
              noValidation
            />
            <Check
              onValueChanged={vm.handleSentToAddressChanged}
              custom
              label={vm.translate("by_post")}
              target={vm.dataStep3}
              property="sentToAddress"
              noValidation
            />
          </div>
          <ValidationError target={vm.dataStep3} property="sentToEmail" />
        </Form.Group>
        {vm.dataStep3.sentToEmail && (
          <Form.Group as={Col} xs={8}>
            <Form.Label>{ta("user", "email")}</Form.Label>
            <Input target={vm.dataStep3} property="invoiceEmail" />
          </Form.Group>
        )}
        {vm.dataStep3.sentToAddress && (
          <>
            <Form.Group as={Col} xs={8}>
              <Form.Label>{ta("address", "country")}</Form.Label>
              <Select
                target={vm.transferRequest.address}
                property="countryId"
                items={vm.countries}
                mode="key"
                keyProperty="id"
                textProperty="name"
              />
            </Form.Group>
            <Form.Group as={Col} xs={8}>
              <Form.Label>{ta("address", "street")}</Form.Label>
              <Input target={vm.transferRequest.address} property="street" />
            </Form.Group>
            <Form.Group as={Col} xs={8}>
              <Form.Label>{ta("address", "house_number")}</Form.Label>
              <Input target={vm.transferRequest.address} property="houseNumber" onValueChanged={vm.clearManualError} />
            </Form.Group>
            <Form.Group as={Col} xs={8}>
              <Form.Label>{ta("address", "street_number")}</Form.Label>
              <Input target={vm.transferRequest.address} property="streetNumber" />
            </Form.Group>
            <Form.Group as={Col} xs={8}>
              <Form.Label>{ta("address", "city")}</Form.Label>
              <Input target={vm.transferRequest.address} property="city" />
            </Form.Group>
            <Form.Group as={Col} xs={8}>
              <Form.Label>{ta("address", "city_part")}</Form.Label>
              <Input target={vm.transferRequest.address} property="cityPart" />
            </Form.Group>
            <Form.Group as={Col} xs={8}>
              <Form.Label>{ta("address", "zip")}</Form.Label>
              <Input target={vm.transferRequest.address} property="zip" onValueChanged={vm.clearManualError} />
            </Form.Group>
          </>
        )}
      </Form.Row>
    </>
  );
});

registerView(step3, CustomerTransferViewModel, "step3");
