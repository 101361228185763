import { HeaderRow } from "@emanprague/ppaskit";
import { registerView, View, ViewComponent } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import React from "react";
import LinksSettingsPageViewModel from "viewModels/admin/linksSettingsPageViewModel";
import { Button, Card } from "react-bootstrap";
import iconEdit from "@emanprague/ppaskit/dist/assets/icons/Edit.svg";
import EmptyDataMessage from "components/EmptyDataMessage";
import LinkListItem from "entities/linkListItem";

const linksSettingsPageView: ViewComponent<LinksSettingsPageViewModel> = observer(({ vm }) => {
  const { translateGeneral: tg } = vm.localization;
  const cardWithTable = (items: LinkListItem[]) => {
    return (
      <Card className="mb-5">
        <Card.Body>
          <table className="table responsive-col2row font-size-sm">
            <thead>
              <tr>
                <th>{vm.translate("order")}</th>
                <th>{vm.translate("label")}</th>
                <th>{vm.translate("link")}</th>
              </tr>
            </thead>
            <tbody>
              {items.map((item, index) => (
                <tr className="text-gray" key={index}>
                  <td data-title={vm.translate("order")}>{item.order}</td>
                  <td data-title={vm.translate("label")}>{item.text}</td>
                  <td data-title={vm.translate("link")}>{item.url}</td>
                </tr>
              ))}
            </tbody>
          </table>
          {!items?.length && <EmptyDataMessage translateGeneral={tg} />}
        </Card.Body>
      </Card>
    );
  };

  return (
    <>
      <HeaderRow level={2} title={vm.translate("suggested_title")}>
        <Button size="sm" variant="light" onClick={vm.editSuggestedLinks}>
          <img src={iconEdit} width={16} height={16} alt="" className="mr-2" />
          {vm.translateGeneral("edit_button")}
        </Button>
      </HeaderRow>
      {cardWithTable(vm.suggestedLinks)}

      <HeaderRow level={2} title={vm.translate("faq_title")}>
        <Button size="sm" variant="light" onClick={vm.editFaq}>
          <img src={iconEdit} width={16} height={16} alt="" className="mr-2" />
          {vm.translateGeneral("edit_button")}
        </Button>
      </HeaderRow>
      {cardWithTable(vm.questionLinks)}

      <View vm={vm.activeChild} />
    </>
  );
});

registerView(linksSettingsPageView, LinksSettingsPageViewModel);
