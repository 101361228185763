import { observable } from "mobx";
import ReqUpdateAttachment from "./reqUpdateAttachment";
import { entityToFormData } from "repositories/helpers";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
class ReqCreateAttachment extends ReqUpdateAttachment {
  @observable
  file!: Blob;

  static ValidationRules = {
    file: { required: true },
  };
}

export default class CustomReqCreateAttachment extends ReqCreateAttachment {
  toFormData() {
    const data = entityToFormData(this);

    // generic entityToFomData is creating "[object OBJECT]" string instead of file
    data.delete("file");
    if (this.file) {
      data.append("file", this.file);
    }

    return data;
  }
}
