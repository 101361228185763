import { registerView, ViewComponent } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import React from "react";
import StatusViewModel from "viewModels/profile/statusViewModel";

const statusListView: ViewComponent<StatusViewModel> = observer(({ vm }) => (
  <div>Content for {vm.name} will be displayed here...</div>
));

registerView(statusListView, StatusViewModel);
