import type { ILocalizationService } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import type { Router } from "@frui.ts/screens";
import { BusyWatcher, ConductorOneChildActive, watchBusy } from "@frui.ts/screens";
import type ScreenBase from "@frui.ts/screens/dist/structure/screenBase";
import AdvanceListItem from "entities/advanceListItem";
import InvoiceListItem from "entities/invoiceListItem";
import Request from "entities/request";
import { openInNewTab } from "helpers/utils";
import { action, computed, observable, runInAction } from "mobx";
import type AdvancesRepository from "repositories/advancesRepository";
import type InvoicesRepository from "repositories/invoicesRepository";
import type LinksRepository from "repositories/linksRepository";
import type EnumsService from "services/enumsService";
import type RequestsService from "services/requestsService";
import type UserContext from "services/userContext";
import type AdvanceChangeViewModel from "../finance/advanceChangeViewModel";
import type AdvanceDetailViewModel from "../finance/advanceDetailViewModel";
import type InvoiceDetailViewModel from "../finance/invoiceDetailViewModel";
import type SupplyPointsViewModel from "./widgets/supplyPointsViewModel";
import type OnlinePaymentViewModel from "../finance/onlinePaymentViewModel";
import type InvoiceListItemState from "entities/invoiceListItemState";
import type AdvanceListItemState from "entities/advanceListItemState";
import RequestsFilter from "models/requestsFilter";
import type BannersService from "services/bannersService";
import type BannerPreview from "entities/bannerPreview";
import type { IPagingFilter } from "@frui.ts/data";
import type LinkListItem from "entities/linkListItem";
import LinksFilter from "models/linksFilter";
import { differenceInCalendarDays } from "date-fns";
import type SimplePortalSettings from "entities/simplePortalSettings";
import type PortalSettingsService from "services/portalSettingsService";

export default class DashboardViewModel extends ConductorOneChildActive<ScreenBase> {
  navigationName = "";
  busyWatcher = new BusyWatcher();

  dashboardNotice?: SimplePortalSettings;

  @observable latestRequestBeforeLastLogin: Request;
  @observable isPaymentBannerVisible = true;
  @observable isRequestBannerVisible = true;
  @observable isSapNoticeVisible = true;
  @observable isDashboardNoticeVisible = true;
  @observable filter = new RequestsFilter();

  @observable questionLinks: LinkListItem[] = [];
  @observable suggestedLinks: LinkListItem[] = [];

  constructor(
    public localization: ILocalizationService,
    public supplyPointsViewModel: SupplyPointsViewModel,
    private userContext: UserContext,
    public router: Router,
    public enumsService: EnumsService,
    private bannersService: BannersService,
    private requestsService: RequestsService,
    private advancesRepository: AdvancesRepository,
    private invoicesRepository: InvoicesRepository,
    private advanceDetailFactory: ReturnType<typeof AdvanceDetailViewModel.Factory>,
    private advanceChangeFactory: ReturnType<typeof AdvanceChangeViewModel.Factory>,
    private invoiceDetailFactory: ReturnType<typeof InvoiceDetailViewModel.Factory>,
    private onlinePaymentFactory: ReturnType<typeof OnlinePaymentViewModel.Factory>,
    private linksRepository: LinksRepository,
    private portalSettingsService: PortalSettingsService
  ) {
    super();
    this.name = this.translate("title");

    runInAction(() => {
      this.dashboardNotice = this.portalSettingsService.getSimplePortalSettings("dashboard_notice");
    });
  }

  protected onInitialize() {
    this.loadData();
    this.fetchQuestionLinks();
    this.fetchSuggestedLinks();
  }

  @action.bound
  @watchBusy
  async loadData() {
    const partnerId = this.userContext.activePartnerId;
    if (!partnerId) {
      return;
    }

    this.filter.state = "in_progress";
    this.filter.updatedAt = this.userContext.user?.lastSignInAt?.toISOString();

    const result = await this.requestsService.getRequests(
      partnerId,
      { offset: 0, limit: 1, sortColumn: "external_updated_at_to", sortDirection: -1 },
      this.filter
    );
    if (result.success && result.payload[0][0]) {
      runInAction(() => (this.latestRequestBeforeLastLogin = result.payload[0][0]));
    }
  }

  @action.bound
  openAdvanceDetail(item: AdvanceListItem) {
    if (this.userContext.activePartnerId) {
      const detail = this.advanceDetailFactory(item, this.userContext.activePartnerId, this.activateAdvanceChange);
      return this.tryActivateChild(detail);
    }
  }

  @action.bound
  openInvoiceDetail(item: InvoiceListItem) {
    if (this.userContext.activePartnerId) {
      const detail = this.invoiceDetailFactory(item, this.userContext.activePartnerId);
      return this.tryActivateChild(detail);
    }
  }

  @bound
  activateAdvanceChange(partnerId: number, supplyPointId: number) {
    return this.tryActivateChild(this.advanceChangeFactory(partnerId, supplyPointId));
  }

  @bound
  activateOnlinePayment() {
    if (this.userContext.activePartnerId && this.firstUnpaidPayment) {
      return this.tryActivateChild(this.onlinePaymentFactory(this.userContext.activePartnerId, this.firstUnpaidPayment));
    }
  }

  @action.bound
  handlePaymentBannerCancel() {
    this.isPaymentBannerVisible = false;
  }

  @action.bound
  handleSapNoticeCancel() {
    this.isSapNoticeVisible = false;
  }

  @action.bound
  handleDashboardpNoticeCancel() {
    this.isDashboardNoticeVisible = false;
  }

  @action.bound
  handleRequestBannerCancel() {
    this.isRequestBannerVisible = false;
  }

  @bound
  addSupplyPoint() {
    openInNewTab(this.userContext.newSupplyPointLink);
  }

  @computed
  get qrCodeUrl() {
    if (this.firstUnpaidPayment && this.userContext.activePartnerId) {
      return this.firstUnpaidPayment instanceof AdvanceListItem
        ? this.advancesRepository.getQrCodeUrl(this.userContext.activePartnerId, this.firstUnpaidPayment.id)
        : this.invoicesRepository.getQrCodeUrl(this.userContext.activePartnerId, this.firstUnpaidPayment.id);
    }
    return "";
  }

  get balanceSummary() {
    if (this.activePartner) {
      return this.activePartner.depositDueAmount + this.activePartner.invoiceDueAmount;
    }

    return 0;
  }

  get dueItemsSummary() {
    if (this.activePartner) {
      return this.activePartner.depositDueCount + this.activePartner.invoiceDueCount;
    }
    return 0;
  }

  get isOneOrMoreDue() {
    return this.dueItemsSummary > 0;
  }

  get accountStatus() {
    if (this.activePartner) {
      if (this.activePartner.invoiceDueCount > 0 && this.activePartner.depositDueCount) {
        const countKeyAdvances = this.localization.getPluralForm(this.activePartner.depositDueCount);
        const countKeyInvoices = this.localization.getPluralForm(this.activePartner.invoiceDueCount);
        return this.translateGeneral("balance_widget.advances_invoices_" + countKeyAdvances + "_" + countKeyInvoices, {
          countAdvances: this.activePartner.depositDueCount.toString(),
          countInvoices: this.activePartner.invoiceDueCount.toString(),
        });
      } else if (this.activePartner.invoiceDueCount > 0) {
        const countKey = this.localization.getPluralForm(this.activePartner.invoiceDueCount);
        return this.translateGeneral("balance_widget.invoices_" + countKey, {
          count: this.activePartner.invoiceDueCount.toString(),
        });
      } else if (this.activePartner.depositDueCount > 0) {
        const countKey = this.localization.getPluralForm(this.activePartner.depositDueCount);
        return this.translateGeneral("balance_widget.advances_" + countKey, {
          count: this.activePartner.depositDueCount.toString(),
        });
      }
    }
    return this.translateGeneral("balance_widget.ok");
  }

  get activePartner() {
    return this.userContext.activePartner;
  }

  get activePartnerId() {
    return this.userContext.activePartnerId;
  }

  score(item: AdvanceListItem | InvoiceListItem) {
    // State weights to sort by
    const weight: { [key in AdvanceListItemState & InvoiceListItemState]: number } = {
      unpaid: 1,
      before_payment: 2,
      payment_in_process: 3,
      paid: 4,
    };

    // positive = future
    const dayDiff = differenceInCalendarDays(item.dueDate, new Date());
    if (item.state === "before_payment" && dayDiff > 21) {
      return 5; // over 21 days in the future has the lowest priority
    }

    return weight[item.state];
  }

  @computed
  get latestAdvancesAndInvoices() {
    // get first 4 items, sorted by dueDate and score
    return [...(this.userContext.activePartner?.bannerAdvances ?? []), ...(this.userContext.activePartner?.bannerInvoices ?? [])]
      .sort((a, b) => {
        const diff = this.score(a) - this.score(b);

        if (diff === 0) {
          // They have same state
          if (a.state === "paid") {
            // Paid will be sorted by day difference from today
            const today = new Date();
            return differenceInCalendarDays(b.dueDate, today) - differenceInCalendarDays(a.dueDate, today);
          } else {
            return a.dueDate.valueOf() - b.dueDate.valueOf();
          }
        }

        return diff;
      })
      .slice(0, 4);
  }

  get firstUnpaidPayment() {
    if (this.activePartner?.actionInvoice && this.activePartner?.actionInvoice.allowToPay) {
      return this.activePartner.actionInvoice;
    } else if (this.activePartner?.actionAdvance && this.activePartner?.actionAdvance.allowToPay) {
      return this.activePartner?.actionAdvance;
    } else {
      return undefined;
    }
  }

  get paymentBannerTitle() {
    const key = this.firstUnpaidPayment instanceof AdvanceListItem ? "time_to_pay_advance" : "time_to_pay_invoice";

    return this.translate(key, { period: this.getRecordPeriod(this.firstUnpaidPayment ?? new AdvanceListItem()) });
  }

  get crossSellBanner(): BannerPreview | undefined {
    return this.bannersService.activeCrossSellBanner;
  }

  get largeBanner(): BannerPreview | undefined {
    if (this.bannersService.activeLargeBanner?.links?.length > 0) {
      return this.bannersService.activeLargeBanner;
    }
    return undefined;
  }

  get firstSmallBanner(): BannerPreview | undefined {
    if (this.bannersService.activeSmallBanners[0]?.links?.length > 0) {
      return this.bannersService.activeSmallBanners[0];
    }
    return undefined;
  }

  get secondSmallBanner(): BannerPreview | undefined {
    if (this.bannersService.activeSmallBanners[1]?.links?.length > 0) {
      return this.bannersService.activeSmallBanners[1];
    }
    return undefined;
  }

  getRecordPeriod(record: AdvanceListItem | InvoiceListItem): string {
    return record.period ?? "";
  }

  @bound
  async fetchQuestionLinks() {
    const pagingFilter: IPagingFilter = { offset: 0, limit: 10 };
    const filter = new LinksFilter();
    filter.section = "questions";
    const result = await this.linksRepository.getLinks(pagingFilter, filter);

    if (result.success && result.payload[0]?.length) {
      const links = result.payload[0];
      runInAction(() => {
        this.questionLinks = links;
      });
    }
  }

  @bound
  async fetchSuggestedLinks() {
    const pagingFilter: IPagingFilter = { offset: 0, limit: 10 };
    const filter = new LinksFilter();
    filter.section = "suggested_links";
    const result = await this.linksRepository.getLinks(pagingFilter, filter);

    if (result.success && result.payload[0]?.length) {
      const links = result.payload[0];
      runInAction(() => {
        this.suggestedLinks = links;
      });
    }
  }

  getRecordType(record: AdvanceListItem | InvoiceListItem, amount = 1): string {
    if (record instanceof AdvanceListItem) {
      return this.localization.translateModel("advance", amount);
    } else {
      return this.localization.translateModel("invoice", amount);
    }
  }

  handleBannerLinkClicked() {
    openInNewTab(process.env.REACT_APP_PPAS_URL || "");
  }

  @bound
  handlePaymentBannerDetailClicked() {
    if (this.firstUnpaidPayment) {
      this.firstUnpaidPayment instanceof AdvanceListItem
        ? this.openAdvanceDetail(this.firstUnpaidPayment)
        : this.openInvoiceDetail(this.firstUnpaidPayment);
    }
  }

  @bound translate(key: string, placeholders?: Record<string, string>) {
    return this.localization.translateGeneral(`dashboard.${key}`, placeholders);
  }

  @bound translateGeneral(key: string, placeholders?: Record<string, string>) {
    return this.localization.translateGeneral(`general.${key}`, placeholders);
  }
}
