import { SupplyPointListSelect, SupplyPointListSelectProps } from "@emanprague/ppaskit";
import { BindingProperty } from "@frui.ts/helpers";
import { IBindingProps, useBinding } from "@frui.ts/views";
import { action } from "mobx";
import { observer } from "mobx-react-lite";
import React from "react";

export interface SupplyPointSelectProps<TTarget, TProperty extends BindingProperty<TTarget>>
  extends IBindingProps<TTarget, TProperty, number[]> {
  className?: string;
  supplyPoints: SupplyPointListSelectProps["supplyPoints"];
  search?: boolean;
  multiselect?: boolean;
}

function SupplyPointSelect<TTarget, TProperty extends BindingProperty<TTarget>>(
  props: SupplyPointSelectProps<TTarget, TProperty>
) {
  const [value, setValue] = useBinding(props);

  const onToggle = action((id: number) => {
    if (!value) {
      setValue([id]);
      return;
    }

    const currentIndex = value.indexOf(id);
    if (currentIndex >= 0) {
      value.splice(currentIndex, 1);
    } else {
      if (props.multiselect) {
        value.push(id);
      } else {
        setValue([id]);
      }
    }
  });

  return (
    <SupplyPointListSelect
      className={props.className}
      search={props.search}
      selected={value?.slice() || []}
      onToggle={onToggle}
      supplyPoints={props.supplyPoints}
    />
  );
}

export default observer(SupplyPointSelect as any) as typeof SupplyPointSelect;
