import { appendAcceptHeader, ContentTypes, RestRequestBuilder } from "@frui.ts/apiclient";
import { IPagingFilter, PagedQueryResult, SortingDirection } from "@frui.ts/data";
import { deserialize, deserializeArray } from "class-transformer";
import { ClassType } from "class-transformer/ClassTransformer";
import merge from "lodash/merge";

RestRequestBuilder.DefaultQueryStringOptions = { arrayFormat: "bracket", skipNull: true, skipEmptyString: true };

export default class DeserializingRequestBuilder extends RestRequestBuilder {
  getEntity<T>(returnType: ClassType<T>, queryParams?: any, customParams?: any): Promise<T> {
    const requestUrl = this.appendQuery(this.url, queryParams);
    const params = appendAcceptHeader(merge({}, this.params, customParams), ContentTypes.json);
    return this.apiConnector
      .get(requestUrl, params)
      .then(x => x.text())
      .then(x => deserialize(returnType, x));
  }

  getEntities<T>(returnType: ClassType<T>, queryParams?: any): Promise<T[]> {
    const requestUrl = this.appendQuery(this.url, queryParams);
    const params = appendAcceptHeader(this.params, ContentTypes.json);
    return this.apiConnector
      .get(requestUrl, params)
      .then(x => x.text())
      .then(x => deserializeArray(returnType, x));
  }

  getPagedEntities<TResult extends { total: number }, TEntity>(
    returnType: ClassType<TResult>,
    itemsSelector: (result: TResult) => TEntity[],
    paging: IPagingFilter,
    queryParams?: any
  ) {
    const query = {
      ...queryParams,
      sort: paging.sortColumn
        ? `${paging.sortColumn}:${paging.sortDirection === SortingDirection.Descending ? "desc" : "asc"}`
        : undefined,
      offset: paging.offset,
      limit: paging.limit,
    };
    const requestUrl = this.appendQuery(this.url, query);
    const params = appendAcceptHeader(this.params, ContentTypes.json);

    return this.apiConnector
      .get(requestUrl, params)
      .then(x => x.text())
      .then(x => deserialize(returnType, x))
      .then(
        x =>
          [
            itemsSelector(x),
            {
              limit: query.limit,
              offset: query.offset,
              totalItems: x.total,
            },
          ] as PagedQueryResult<TEntity>
      );
  }

  postEntity<T>(content: any, returnType: ClassType<T>): Promise<T>;
  postEntity(content: any): Promise<Response>;

  postEntity<T>(content: any, returnType?: ClassType<T>): Promise<T | Response> {
    const params = appendAcceptHeader(this.params, ContentTypes.json);
    const promise = this.apiConnector.postJson(this.url, content, params);

    if (returnType) {
      return promise.then(x => x.text()).then(x => deserialize(returnType, x));
    } else {
      return promise;
    }
  }

  putEntity<T>(content: any, returnType: ClassType<T>): Promise<T>;
  putEntity(content: any): Promise<Response>;

  putEntity<T>(content: any, returnType?: ClassType<T>): Promise<T | Response> {
    const params = appendAcceptHeader(this.params, ContentTypes.json);
    const promise = this.apiConnector.putJson(this.url, content, params);

    if (returnType) {
      return promise.then(x => x.text()).then(x => deserialize(returnType, x));
    } else {
      return promise;
    }
  }

  patchEntity<T>(content: any, returnType: ClassType<T>): Promise<T>;
  patchEntity(content: any): Promise<Response>;

  patchEntity<T>(content: any, returnType?: ClassType<T>): Promise<T | Response> {
    const params = appendAcceptHeader(this.params, ContentTypes.json);
    const promise = this.apiConnector.patchJson(this.url, content, params);

    if (returnType) {
      return promise.then(x => x.text()).then(x => deserialize(returnType, x));
    } else {
      return promise;
    }
  }
}
