import { Button, Col, Form, HeaderRow, LoaderOverlay, Modal } from "@emanprague/ppaskit";
import { Input } from "@frui.ts/bootstrap";
import { preventDefault, registerView, ViewComponent } from "@frui.ts/views";
import PasswordInput from "controls/twoway/PasswordInput";
import { observer } from "mobx-react-lite";
import React from "react";
import RefreshCredentialsViewModel from "viewModels/profile/refreshCredentialsViewModel";

const refreshCredentialsView: ViewComponent<RefreshCredentialsViewModel> = observer(({ vm }) => {
  const { translateAttribute: ta } = vm.localization;
  return (
    <>
      <Modal centered show onHide={vm.requestClose}>
        <Modal.Header closeButton>
          <HeaderRow title={vm.name} level={4} />
        </Modal.Header>
        <LoaderOverlay loading={vm.busyWatcher.isBusy}>
          <Modal.Body>
            <Form onSubmit={preventDefault(vm.login)}>
              <p>{vm.translate("message1")}</p>
              <p>{vm.translate("message2")}</p>

              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label>{ta("user", "email")}</Form.Label>
                  <Input disabled target={vm.credentials} property="email" />
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col}>
                  <Form.Label>{ta("user", "password")}</Form.Label>
                  <PasswordInput target={vm.credentials} property="password" feedbackInvalid={vm.errorMessage} autoFocus />
                </Form.Group>
              </Form.Row>

              <Form.Row>
                <Form.Group as={Col} className="d-flex justify-content-end mt-5">
                  <Button onClick={vm.logout} variant="secondary" className="order-1">
                    {vm.translate("logout_button")}
                  </Button>
                  <Button disabled={!vm.canLogin} variant="primary" type="submit" className="order-2" onClick={vm.login}>
                    {vm.translate("login_button")}
                  </Button>
                </Form.Group>
              </Form.Row>
            </Form>
          </Modal.Body>
        </LoaderOverlay>
      </Modal>
    </>
  );
});

registerView(refreshCredentialsView, RefreshCredentialsViewModel);
