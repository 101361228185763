import { ILocalizationService } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, ScreenBase, watchBusy } from "@frui.ts/screens";
import { interfaces } from "inversify";
import { action, observable, runInAction } from "mobx";
import EnumsService from "services/enumsService";
import AdvanceListItem from "entities/advanceListItem";
import InvoiceListItem from "entities/invoiceListItem";
import AdvancesRepository from "repositories/advancesRepository";
import InvoicesRepository from "repositories/invoicesRepository";
import Advance from "entities/advance";
import Invoice from "entities/invoice";
import ReqOnlinePayment from "entities/reqOnlinePayment";
import PaymentGateway from "entities/paymentGateway";
import { ApiResult } from "repositories/apiModels";

export default class OnlinePaymentViewModel extends ScreenBase {
  navigationName = "payment";
  busyWatcher = new BusyWatcher();

  @observable itemDetail: Advance | Invoice;
  @observable data: ReqOnlinePayment;

  constructor(
    private partnerId: number,
    private item: AdvanceListItem | InvoiceListItem,
    private advancesRepository: AdvancesRepository,
    private invoicesRepository: InvoicesRepository,
    public localization: ILocalizationService,
    public enumsService: EnumsService
  ) {
    super();

    this.name = this.translate("title");
    this.data = new ReqOnlinePayment();
  }

  protected onInitialize(): Promise<any> | void {
    this.loadData();
  }

  @bound
  @watchBusy
  async loadData() {
    if (!this.partnerId) {
      return;
    }

    const result =
      this.item instanceof AdvanceListItem
        ? await this.advancesRepository.getAdvanceDetail(this.partnerId, this.item.id)
        : await this.invoicesRepository.getInvoiceDetail(this.partnerId, this.item.id);

    if (result.success) {
      runInAction(() => (this.itemDetail = result.payload));
    }
  }

  @bound
  @watchBusy
  async confirmGatewayMethod() {
    if (!this.partnerId || !this.itemDetail.id) {
      return;
    }

    const result: ApiResult<PaymentGateway> =
      this.item instanceof AdvanceListItem
        ? await this.advancesRepository.setAdvancePay(this.partnerId, this.item.id, this.data)
        : await this.invoicesRepository.setInvoicePay(this.partnerId, this.item.id, this.data);

    if (result.success) {
      window.location.assign(result.payload.url);
    }
  }

  @action.bound
  handleMethodSelected = (methodId: number) => {
    this.data.gatewayMethodId = methodId;
  };

  @bound
  isMethodSelected(methodId: number) {
    return methodId === this.data.gatewayMethodId;
  }

  get canConfirm() {
    return !!this.data.gatewayMethodId;
  }

  get paymentMethods() {
    if (this.itemDetail) {
      return this.enumsService
        .getValues("gatewayMethods")
        .filter(x => this.itemDetail.gatewayMethodIds?.includes(x.id))
        .map(x => ({
          id: x.id,
          name: x.name,
          imageUrl: x.imageUrl,
        }));
    } else {
      return [];
    }
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`online_payment.${key}`);
  }

  @bound translateGeneral(key: string) {
    return this.localization.translateGeneral(`general.${key}`);
  }

  static Factory({ container }: interfaces.Context) {
    return (partnerId: number, transaction: AdvanceListItem | InvoiceListItem) => {
      return new OnlinePaymentViewModel(
        partnerId,
        transaction,
        container.get(AdvancesRepository),
        container.get(InvoicesRepository),
        container.get("ILocalizationService"),
        container.get(EnumsService)
      );
    };
  }
}
