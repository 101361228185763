import { IPagingFilter } from "@frui.ts/data";
import { entityToPlain } from "./helpers";
import RepositoryBase from "./repositoryBase";
import AttachmentsFilter from "models/attachmentsFilter";
import AttachmentList from "entities/attachmentList";
import ReqCreateAttachment from "entities/reqCreateAttachment";
import ReqUpdateAttachment from "entities/reqUpdateAttachment";
import downloadjs from "downloadjs";
import AttachmentListItem from "entities/attachmentListItem";

export default class AttachmentsRepository extends RepositoryBase {
  getAttachments(partnerId: number, paging: IPagingFilter, filter?: AttachmentsFilter) {
    const query = entityToPlain(filter);

    return this.callApi(api =>
      api.all(`partners/${partnerId}/files`).getPagedEntities(AttachmentList, x => x.attachments, paging, query)
    );
  }

  addAttachment(partnerId: number, attachmentData: ReqCreateAttachment) {
    const formData = attachmentData.toFormData();
    return this.callApi(api => api.all(`partners/${partnerId}/files`).postData(formData));
  }

  updateAttachment(partnerId: number, attachmentId: number, attachmentData: ReqUpdateAttachment) {
    return this.callApi(api => api.all(`partners/${partnerId}/files/${attachmentId}`).putEntity(attachmentData));
  }

  downloadAttachment(partnerId: number, attachment: AttachmentListItem) {
    return this.callApi(api => api.all(`partners/${partnerId}/files/${attachment.id}`).getRaw()).then(async response => {
      if (response.success) {
        const blob = await response.payload.blob();
        downloadjs(blob, attachment.fileName);
      }
    });
  }

  deleteAttachment(partnerId: number, attachmentId: number) {
    return this.callApi(api => api.all(`partners/${partnerId}/files/${attachmentId}`).delete());
  }
}
