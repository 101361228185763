import { Expose, Transform } from "class-transformer";
import { observable } from "mobx";
import formatISO from "date-fns/formatISO";

export default class ProductCardsFilter {
  @observable
  @Expose({ name: "q" })
  search?: string;

  @observable
  @Expose({ name: "filters[id]" })
  id?: number;

  @observable
  @Expose({ name: "filters[title]" })
  title?: string;

  @observable
  @Expose({ name: "filters[category]" })
  category?: string;

  @observable
  @Expose({ name: "filters[valid_from]" })
  @Transform(value => value && formatISO(value, { representation: "date" }))
  validFrom?: Date;

  @observable
  @Expose({ name: "filters[valid_to]" })
  @Transform(value => value && formatISO(value, { representation: "date" }))
  validTo?: Date;
}
