import { IPagingFilter } from "@frui.ts/data";
import ReqNewRequest from "entities/reqNewRequest";
import RequestList from "entities/requestList";
import RequestsFilter from "models/requestsFilter";
import { RequestsEvents } from "services/events";
import { entityToPlain } from "./helpers";
import RepositoryBase from "./repositoryBase";

export default class RequestsRepository extends RepositoryBase {
  getRequests(partnerId: number, paging: IPagingFilter, filter?: RequestsFilter) {
    const query = entityToPlain(filter);

    return this.callApi(api =>
      api
        .all(`partners/${partnerId}/requests`)
        .getPagedEntities(RequestList, x => x.requests, paging, query)
        .then(result => {
          result[0].forEach(x => (x.statuses = x.statuses?.sort((a, b) => a.order - b.order))); // ensure that statuses are in order
          return result;
        })
    );
  }

  getActiveRequestsCount(partnerId: number) {
    const paging: IPagingFilter = { offset: 0, limit: 0 };

    const filter = new RequestsFilter();
    filter.state = "in_progress";

    return this.getRequests(partnerId, paging, filter);
  }

  sendRequest(userData: ReqNewRequest, partnerId: number) {
    const formData = userData.toFormData();

    const promise = this.callApi(api => api.all(`partners/${partnerId}/requests`).postData(formData));
    promise.then(() => this.eventBus.publish(RequestsEvents.requestCreated(userData)));

    return promise;
  }

  getFile(partnerId: number, id: number) {
    return this.callApi(api => api.one("partners", partnerId).one("requests", id).all("file").getRaw());
  }
}
