import { Expose, Transform } from "class-transformer";
import formatISO from "date-fns/formatISO";
import { observable } from "mobx";
import { entityToFormData } from "repositories/helpers";
import { EntityValidationRules } from "services/validation/entityValidationRules";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
class ReqCustomerData {
  @observable
  @Expose({ name: "first_name" })
  firstName?: string;

  @observable
  @Expose({ name: "last_name" })
  lastName?: string;

  @observable
  @Expose({ name: "title_before_id" })
  titleBeforeId?: number;

  @observable
  @Expose({ name: "title_after_id" })
  titleAfterId?: number;

  @observable
  @Transform(value => (value ? new Date(value) : undefined), { toClassOnly: true })
  @Transform(value => (value ? formatISO(value, { representation: "date" }) : undefined), { toPlainOnly: true })
  @Expose({ name: "birth_date" })
  birthDate?: Date;

  @observable
  @Expose({ name: "title_before2_id" })
  titleBefore2Id?: number;

  /**
   * Company name
   */
  @observable
  name?: string;

  @observable
  @Expose({ name: "org_id" })
  orgId?: string;

  @observable
  @Expose({ name: "vat_id" })
  vatId?: string;

  @observable
  @Expose({ name: "file[]" })
  file?: Blob[];

  static ValidationRules = {
    titleBeforeId: { number: true },
    titleAfterId: { number: true },
    birthDate: { date: true },
    titleBefore2Id: { number: true },
  };
}

export default class CustomReqCustomerData extends ReqCustomerData {
  static customUserValidationRules: EntityValidationRules<CustomReqCustomerData> = {
    birthDate: { required: true, date: true },
    lastName: { required: true },
    firstName: { required: true },
  };

  static customCompanyValidationRules: EntityValidationRules<CustomReqCustomerData> = {
    name: { required: true },
    orgId: { isIco: true },
    vatId: { isDic: true },
  };

  toFormData() {
    const data = entityToFormData(this);

    if (this.birthDate) {
      data.append("birth_date", formatISO(this.birthDate, { representation: "date" }));
    }

    // generic entityToFomData is creating "[object OBJECT]" string instead of file
    data.delete("file[]");
    if (this.file && this.file.length > 0) {
      this.file.forEach(item => data.append("file[]", item));
    }

    return data;
  }
}
