import { Form, Col, Button, Modal, HeaderRow, LoaderOverlay } from "@emanprague/ppaskit";
import { preventDefault, registerView, ViewComponent } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import React from "react";
import { Input } from "@frui.ts/bootstrap";
import BannerDetailViewModel from "viewModels/admin/bannerDetailViewModel";
import Uploader from "controls/uploader";
import DatePicker from "controls/twoway/DatePicker";
import { DisplayCondition } from "components/DisplayCondition";

const bannerDetail: ViewComponent<BannerDetailViewModel> = observer(({ vm }) => {
  const ta = vm.localization.translateAttribute;

  const buttonWithLinkBlock = (index: number, label?: string) => (
    <>
      <Form.Group as={Col} xs={12} className="text-center mt-3 mb-n1">
        <Form.Label className="admin-form-header">
          <strong>{label ?? vm.translate("button_" + index.toString())}</strong>
        </Form.Label>
      </Form.Group>
      <Form.Group as={Col} xs={12}>
        <Form.Label>{vm.translate("label")}</Form.Label>
        <Input target={vm.data.links[index]} property="text" />
      </Form.Group>
      <Form.Group as={Col} xs={12}>
        <Form.Label>{vm.translate("link")}</Form.Label>
        <Input target={vm.data.links[index]} property="url" />
      </Form.Group>
    </>
  );

  return (
    <>
      {vm.data && (
        <Modal centered show onHide={vm.requestClose} className="admin-modal">
          <Modal.Header closeButton>
            <HeaderRow title={vm.name} level={4} />
          </Modal.Header>
          <LoaderOverlay loading={vm.busyWatcher.isBusy}>
            <Modal.Body>
              <Form className="admin-form mt-n4" onSubmit={preventDefault(vm.confirmRequest)}>
                <Form.Row className="form-row-centered">
                  <Form.Group as={Col} xs={12} className="text-center">
                    <Form.Label className="mb-2 admin-form-header">
                      <strong>{vm.translate("banner_type")}</strong>
                    </Form.Label>
                  </Form.Group>
                  <Form.Group>
                    {vm.bannerTypes.map(type => (
                      <Form.Check
                        custom
                        key={type.id}
                        id={`change_type_${type.id}`}
                        value={type.id}
                        checked={vm.data.type === type.id}
                        type="radio"
                        label={type.name}
                        onChange={() => vm.onChangeType(type.id)}
                        className="mb-1"
                      />
                    ))}
                  </Form.Group>
                </Form.Row>

                <Form.Row>
                  {vm.data.type === "cross_sell" ? (
                    <>
                      <Form.Group as={Col} xs={12}>
                        <Form.Label>{vm.translate("header")}</Form.Label>
                        <Input target={vm.data} property="title" />
                      </Form.Group>
                      <Form.Group as={Col} xs={12}>
                        <Form.Label>{vm.translate("text_1")}</Form.Label>
                        <Input {...{ as: "textarea", rows: 5 }} target={vm.data} property="text1" />
                      </Form.Group>
                      <Form.Group as={Col} xs={12}>
                        <Form.Label>{vm.translate("text_2")}</Form.Label>
                        <Input {...{ as: "textarea", rows: 5 }} target={vm.data} property="text2" />
                      </Form.Group>

                      {vm.buttonsCount > 0 && buttonWithLinkBlock(0)}
                      {vm.buttonsCount > 1 && buttonWithLinkBlock(1)}
                      {vm.buttonsCount > 2 && buttonWithLinkBlock(2)}

                      {vm.buttonsCount < vm.maxLinksCount && (
                        <Form.Group as={Col} xs={12} className="text-center mt-3 mb-4">
                          <Button size="sm" variant="secondary" onClick={vm.addNewButton}>
                            Přidat další
                          </Button>
                        </Form.Group>
                      )}
                    </>
                  ) : (
                    <>
                      <Form.Group as={Col} xs={12}>
                        <Form.Label>{vm.translate("header")}</Form.Label>
                        <Input target={vm.data} property="title" />
                      </Form.Group>
                      <Form.Group as={Col} xs={12}>
                        <Form.Label>{vm.translate("text")}</Form.Label>
                        <Input {...{ as: "textarea", rows: 5 }} target={vm.data} property="text1" />
                      </Form.Group>

                      {buttonWithLinkBlock(0, vm.translate("button"))}

                      <Form.Group as={Col} xs={12} className="text-center mt-3">
                        <Form.Label className="admin-form-header mb-3">
                          <strong>{vm.translate("image")}</strong>
                        </Form.Label>
                        <Uploader
                          localization={vm.localization}
                          onDrop={vm.onDrop}
                          onDropRejected={vm.onDropRejected}
                          fileSelectError={vm.uploaderErrorMessage}
                          maxFiles={1}
                        />
                      </Form.Group>
                    </>
                  )}

                  <Form.Group as={Col} xs={12} className="text-center mt-3 mb-2">
                    <Form.Label className="admin-form-header">
                      <strong>{ta("banner", "validity")}</strong>
                    </Form.Label>
                  </Form.Group>
                  <Form.Group as={Col} xs={6}>
                    <Form.Label>
                      {vm.translateGeneral("valid_from")}{" "}
                      <span className="text-muted">{vm.translateGeneral("not_mandatory")}</span>
                    </Form.Label>
                    <DatePicker
                      target={vm.data}
                      property="validFrom"
                      selectsStart
                      startDate={vm.data.validFrom}
                      endDate={vm.data.validTo}
                      maxDate={vm.data.validTo}
                    />
                  </Form.Group>
                  <Form.Group as={Col} xs={6}>
                    <Form.Label>
                      {vm.translateGeneral("valid_to")} <span className="text-muted">{vm.translateGeneral("not_mandatory")}</span>
                    </Form.Label>
                    <DatePicker
                      target={vm.data}
                      property="validTo"
                      selectsEnd
                      startDate={vm.data.validFrom}
                      endDate={vm.data.validTo}
                      minDate={vm.data.validFrom}
                    />
                  </Form.Group>

                  {(vm.data.validFrom || vm.data.validTo) && (
                    <Form.Group className="w-100 text-right">
                      <Button variant="secondary" size="sm" className="mr-1" onClick={vm.clearDates}>
                        {vm.translate("clear_date")}
                      </Button>
                    </Form.Group>
                  )}

                  <Form.Group as={Col} xs={12} className="text-center mt-3 mb-3">
                    <Form.Label className="admin-form-header">
                      <strong>{vm.translate("display_conditions")}</strong>
                    </Form.Label>
                  </Form.Group>
                  <DisplayCondition data={vm.data} translateConditions={vm.translateConditions} />
                </Form.Row>

                <Form.Row>
                  <Form.Group>
                    {vm.errorMessage && <Form.Control.Feedback type="invalid">{vm.errorMessage}</Form.Control.Feedback>}
                  </Form.Group>
                </Form.Row>
              </Form>
            </Modal.Body>
          </LoaderOverlay>
          <Modal.Footer>
            <Button onClick={vm.requestClose} variant="secondary">
              {vm.translateGeneral("cancel_button")}
            </Button>
            <Button disabled={!vm.canConfirm} variant="primary" onClick={vm.confirmRequest} className="ml-1">
              {vm.translateGeneral(vm.isNewBanner ? "add_button" : "confirm_changes_button")}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
});

registerView(bannerDetail, BannerDetailViewModel);
