import { observable } from "mobx";
import { EntityValidationRules } from "services/validation/entityValidationRules";
import { Expose } from "class-transformer";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class ReqRegistrationStep2 {
  /**
   * @example
   * asdf3940940
   */
  @observable
  token!: string;

  /**
   * @example
   * Heslo123
   */
  @observable
  password!: string;

  static ValidationRules = {
    token: { required: true },
    password: { required: true },
  };
}

export class ExtendReqRegistrationStep2 extends ReqRegistrationStep2 {
  @observable
  @Expose({ name: "password_confirmation" })
  passwordConfirmation?: string;

  static CustomValidationRules: EntityValidationRules<ExtendReqRegistrationStep2> = {
    token: { required: true },
    password: { required: true },
    passwordConfirmation: {
      required: true,
      equalWith: { parameter: "password", translationCode: "validators.password_confirmation_mismatch" },
    },
  };
}
