import { observable } from "mobx";
import { EntityValidationRules } from "services/validation/entityValidationRules";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class UserLogin {
  /**
   * @example
   * marek.novak@tryit.ppas.cz
   */
  @observable
  email!: string;

  /**
   * @example
   * joojaV9Mah
   */
  @observable
  password!: string;

  static ValidationRules = {
    email: { required: true },
    password: { required: true },
  };
}

const UserLoginValidationRules: EntityValidationRules<UserLogin> = {
  email: { required: true, isEmail: true },
  password: { required: true },
};
export { UserLoginValidationRules };
