import { observable } from "mobx";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class BannerButton {
  @observable
  url!: string;

  @observable
  text!: string;

  static ValidationRules = {
    url: { required: true },
    text: { maxLength: 255, required: true },
  };
}
