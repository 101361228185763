import { IEventBus } from "@emanprague/shared-services";
import LocalizationService from "@emanprague/shared-services/dist/localization/localizationService";
import { Router } from "@frui.ts/screens";
import { Container, decorate, injectable } from "inversify";
import BackendConnector from "repositories/backendConnector";
import LocaleProvider from "services/localeProvider";
import UserContext from "services/userContext";
import "./di.decorators";
import registerServices from "./di.registry";
import DevelopLocaleProvider from "./services/developLocaleProvider";
import viewModelsProductsAppendixesPageViewModel from "./viewModels/products/appendixesPageViewModel";

export default function createContainer(apiBaseUrl: string) {
  const container = new Container({ skipBaseClassChecks: true });
  registerServices(container);

  const eventBus = container.get<IEventBus>("IEventBus");
  container.bind(BackendConnector).toConstantValue(new BackendConnector(apiBaseUrl, eventBus));
  container.bind(Router).toConstantValue(new Router());

  const userContext = new UserContext(eventBus);
  container.bind(UserContext).toConstantValue(userContext);

  if (process.env.NODE_ENV === "development" && process.env.REACT_APP_DEVELOP_LOCALE === "true") {
    decorate(injectable(), DevelopLocaleProvider);
    container.rebind(LocaleProvider).to(DevelopLocaleProvider as any);
  }

  const localeProvider = container.get(LocaleProvider);
  container.bind("ILocalizationService").toConstantValue(new LocalizationService(localeProvider));

  // Custom bind for 1 VM handling modal and a screen in a menu.
  container.bind<viewModelsProductsAppendixesPageViewModel>(viewModelsProductsAppendixesPageViewModel).toSelf();

  return container;
}
