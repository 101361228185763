import { registerView, ViewComponent, View, preventDefault } from "@frui.ts/views";
import { observer, Observer } from "mobx-react-lite";
import React from "react";
import OverviewViewModel from "viewModels/requests/overviewViewModel";
import { Row, Col, Button, Form, SwitchNav, StepperRequest, Layout, RequestCard, LoaderOverlay } from "@emanprague/ppaskit";
import { Input, Select } from "@frui.ts/bootstrap";
import iconArrowRightGray from "@emanprague/ppaskit/dist/assets/icons/Arrow/Right.svg";
import "./style.scss";
import Request from "entities/request";
import DOMPurify from "dompurify";

const conversationRow = (title: string, text: string) => {
  return (
    <tr>
      <th>{title}:</th>
      <td>
        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(text),
          }}
        />
      </td>
    </tr>
  );
};

const overviewListView: ViewComponent<OverviewViewModel> = observer(({ vm }) => {
  const links = [
    {
      id: "all",
      title: vm.translate("all"),
      href: "#",
      onClick: preventDefault(() => vm.applyRequestStateFilter()),
    },
    {
      id: "in_progress",
      title: vm.translate("active"),
      href: "#",
      onClick: preventDefault(() => vm.applyRequestStateFilter("in_progress")),
    },
    {
      id: "done",
      title: vm.translate("finished"),
      href: "#",
      onClick: preventDefault(() => vm.applyRequestStateFilter("done")),
    },
  ];
  return (
    <Layout fluid="xl" className="mt-4 mt-md-5 mb-3">
      <LoaderOverlay loading={vm.busyWatcher.isBusy}>
        <Row className="mb-3 mb-md-5 align-items-center mx-2 mx-sm-0">
          <Col xs={6} md={4} className="order-first">
            <h2 className="display-2 mb-2">{vm.translate("detail_title")}</h2>
          </Col>
          <Col xs={12} md={4} className="text-sm-center order-last order-md-1 mt-3 mt-sm-0">
            <SwitchNav links={links} activeId={vm.filter.state ?? "all"} size="sm" variant="light" />
          </Col>
          <Col className="d-flex justify-content-end order-1 order-md-last">
            <Button variant="primary" onClick={vm.activateCreateRequestModal}>
              {vm.translate("general_question")}
            </Button>
          </Col>
        </Row>
        <Row className="mb-3 mx-2 mx-sm-0">
          <Col xs={6} md={3} lg={2}>
            <Form.Group>
              <Form.Label>{vm.translate("typ_requests")}</Form.Label>
              <Select
                target={vm.filter}
                property="type"
                items={vm.formTypes}
                mode="key"
                keyProperty="id"
                textProperty="name"
                onValueChanged={vm.applyFilterAndLoad}
                allowEmpty
                emptyText={vm.translateGeneral("filter_all")}
              />
            </Form.Group>
          </Col>
          <Col xs={6} md={3} lg={2}>
            <Form.Group>
              <Form.Label>{vm.translate("period")}</Form.Label>
              <Select
                target={vm.filter}
                property="createdAt"
                items={vm.formPeriods}
                mode="key"
                keyProperty="id"
                textProperty="name"
                onValueChanged={vm.applyFilterAndLoad}
                allowEmpty
                emptyText={vm.translateGeneral("filter_all")}
              />
            </Form.Group>
          </Col>
          <Col xs={12} md={4} lg={3} className="ml-auto">
            <span className="d-none d-md-inline">
              <Form.Label />
            </span>
            <Input
              placeholder={vm.translate("search_id")}
              target={vm.filter}
              property="search"
              onValueChanged={vm.applyFilterAndLoadDebounced}
            />
          </Col>
        </Row>
        {vm.items &&
          vm.items.map((request: Request, index: number) => (
            <div className="mb-4" key={request.id}>
              <RequestCard
                description={request.description}
                number={request.externalId}
                state={request.state}
                label={vm.translate("state." + request.state)}
                isOpenByDefault={index === 0}>
                <span className="gray-text">
                  {vm.translate("started") + " "}
                  {vm.localization.formatDate(request.externalCreatedAt)}
                </span>
                <Row>
                  <StepperRequest
                    stepCompleted={vm.getLastActiveStep(request)}
                    steps={vm.getRequestStepperNumberOfSteps(request)}
                    className="my-3 mx-3 mx-md-auto"
                  />
                </Row>
                {request.infoOld && request.infoNew && (
                  <Row className="request__information-row">
                    {request.infoOld}
                    <img
                      className="ml-sm-3 opacity-third align-text-bottom"
                      width="16"
                      height="20"
                      src={iconArrowRightGray}
                      alt=""
                    />
                    <span className="ml-3">
                      <strong>{request.infoNew}</strong>
                    </span>
                  </Row>
                )}
                <table className="request_table">
                  <tbody>
                    {request.information && conversationRow(vm.translate("answer"), request.information)}
                    {request.originalInquiry && conversationRow(vm.translate("question"), request.originalInquiry)}
                  </tbody>
                </table>
                {request.fileAvailable && (
                  <Row className="request__information-row">
                    <Button variant="secondary" onClick={() => vm.downloadFile(request.id)}>
                      {vm.translate("download")}
                    </Button>
                  </Row>
                )}
              </RequestCard>
            </div>
          ))}

        {vm.canLoadData && (
          <div className="load-more-button">
            <Button onClick={vm.loadData} variant="secondary">
              {vm.translateGeneral("button_load_more")}
            </Button>
          </div>
        )}
      </LoaderOverlay>
      <Observer>{() => <View vm={vm.activeChild} />}</Observer>
    </Layout>
  );
});

registerView(overviewListView, OverviewViewModel);
