import type { IAdminModule } from "viewModels/types";
import { ILocalizationService } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, Router, ScreenBase, watchBusy } from "@frui.ts/screens";
import { observable, runInAction } from "mobx";
import GeneralSettingsRepository from "repositories/generalSettingsRepository";
import ConfirmationService from "services/confirmationService";
import PortalSettingsService from "services/portalSettingsService";
import SimplePortalSettings from "entities/simplePortalSettings";

const navigationName = "general-settings";

@Router.registerRoute({ name: Router.Self, route: `${navigationName}` })
export default class GeneralSettingsPageViewModel extends ScreenBase implements IAdminModule {
  navigationName = navigationName;
  orderIndex = 300;
  menuPart = "admin";
  busyWatcher = new BusyWatcher();

  @observable lastEnumUpdate?: Date;
  @observable loginDisabledSetting?: SimplePortalSettings;

  constructor(
    public localization: ILocalizationService,
    private generalSettingsRepository: GeneralSettingsRepository,
    private portalSettingsService: PortalSettingsService,
    private confirmationService: ConfirmationService
  ) {
    super();

    this.name = this.translate("title");

    runInAction(() => {
      this.loginDisabledSetting = this.portalSettingsService.getSimplePortalSettings("disable_login");
    });
  }

  protected onInitialize(): Promise<any> | void {
    return this.loadLastEnumUpdate();
  }

  @bound
  @watchBusy
  async refreshEnumerations() {
    await this.generalSettingsRepository.refreshEnums();
    await this.loadLastEnumUpdate();
  }

  @bound
  @watchBusy
  async loadLastEnumUpdate() {
    const response = await this.generalSettingsRepository.generalInfo();
    if (response.success) {
      runInAction(() => {
        this.lastEnumUpdate = response.payload.last_enum_update;
      });
    }
  }

  get isLoginDisabled() {
    return this.loginDisabledSetting?.active ?? false;
  }

  @bound
  async toggleLoginSetting() {
    const confirm = await this.confirmationService.showConfirmation(
      this.isLoginDisabled ? this.translate("confirm_enable") : this.translate("confirm_disable"),
      undefined,
      { variant: this.isLoginDisabled ? "primary" : "danger", text: this.translate("confirm") },
      this.translateGeneral("cancel_button")
    );

    if (!confirm) {
      return;
    }

    await runInAction(async () => {
      if (!this.loginDisabledSetting) {
        return;
      }

      this.loginDisabledSetting.active = !this.isLoginDisabled;
      await this.portalSettingsService.updatePortalSettings(this.loginDisabledSetting);
    });
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`admin.general_settings.${key}`);
  }

  @bound lastUpdatedAtText() {
    const date = this.localization.formatDateTime(this.lastEnumUpdate);

    return this.localization.translateGeneral(`admin.general_settings.last_updated_at`, {
      date: date || this.translate("unknown"),
    });
  }

  @bound translateGeneral(key: string) {
    return this.localization.translateGeneral(`general.${key}`);
  }
}
