import { observable } from "mobx";
import { Transform } from "class-transformer";
import formatISO from "date-fns/formatISO";
import { Expose } from "class-transformer";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class CompetitionListItem {
  /**
   * Competition (internal) ID
   */
  id!: number;

  /**
   * Competition external ID from SAP
   */
  @observable
  @Expose({ name: "external_id" })
  externalId!: string;

  @observable
  title!: string;

  @observable
  description?: string;

  @observable
  @Expose({ name: "check_box_text" })
  checkBoxText?: string;

  /**
   * Link to competition
   */
  @observable
  url?: string;

  /**
   * Link to image for modal window for competition
   */
  @observable
  @Expose({ name: "image_url" })
  imageUrl?: string;

  @observable
  @Transform(value => (value ? new Date(value) : undefined), { toClassOnly: true })
  @Transform(value => (value ? formatISO(value, { representation: "date" }) : undefined), { toPlainOnly: true })
  @Expose({ name: "start_date" })
  startDate!: Date;

  @observable
  @Transform(value => (value ? new Date(value) : undefined), { toClassOnly: true })
  @Transform(value => (value ? formatISO(value, { representation: "date" }) : undefined), { toPlainOnly: true })
  @Expose({ name: "end_date" })
  endDate!: Date;

  static ValidationRules = {
    id: { required: true, number: true },
    externalId: { required: true },
    title: { required: true },
    startDate: { required: true, date: true },
    endDate: { required: true, date: true },
  };
}
