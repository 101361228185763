import MessagesRepository from "repositories/messagesRepository";
import { observable, runInAction } from "mobx";
import { bound } from "@frui.ts/helpers";
import UserContext from "./userContext";
import PartnerMessageListItem from "entities/partnerMessageListItem";
import { ApiResult } from "repositories/apiModels";
import MessagePreview from "entities/messagePreview";

export default class MessagesService {
  @observable unreadMessagesCount = 0;
  @observable.ref messagePreviews: PartnerMessageListItem[] = [];

  constructor(private userContext: UserContext, private repository: MessagesRepository) {}

  @bound
  initialize() {
    this.refreshMessages();
  }

  refreshMessages() {
    this.updateUnreadMessagesCount();
    this.updateMessagePreviews();
  }

  private async updateUnreadMessagesCount() {
    const partnerId = this.userContext.activePartnerId;
    if (!partnerId) {
      return;
    }

    const response = await this.repository.getUnreadMessagesCount(partnerId);
    if (response.success) {
      runInAction(() => (this.unreadMessagesCount = response.payload[1].totalItems));
    }
  }

  private async updateMessagePreviews() {
    const partnerId = this.userContext.activePartnerId;
    if (!partnerId) {
      return;
    }

    const response = await this.repository.getMessagePreviews(partnerId);
    if (response.success) {
      runInAction(() => (this.messagePreviews = response.payload[0]));
    }
  }

  async markMessageAsRead(messageId: number) {
    const partnerId = this.userContext.activePartnerId;
    if (!partnerId) {
      return;
    }

    await this.repository.markMessageAsRead(partnerId, messageId);
    this.refreshMessages();
  }

  getMessageDetail(partnerId: number, message: PartnerMessageListItem): Promise<ApiResult<MessagePreview>> {
    return this.repository.getMessageDetail(partnerId, message.id).then(response => {
      // Do NOT send the flag to BE if an admin is impersonating a user.
      if (!message.read && !this.userContext.morphedAdminId) {
        this.markMessageAsRead(message.id);
      }
      return response;
    });
  }
}
