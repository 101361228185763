import { IInitializationService, ILocalizationService } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { validatorsRepository } from "@frui.ts/validation";
import { observable, runInAction } from "mobx";
import DataSyncService from "./dataSyncService";
import EnumsService from "./enumsService";
import SecurityService from "./securityService";
import UserContext from "./userContext";
import ValidationService from "./validation/validationService";
import BannersService from "./bannersService";
import MessagesService from "./messagesService";
import GAService from "./gaService";
import AppendixesService from "./appendixesService";
import PortalSettingsService from "./portalSettingsService";
import { watchBusy } from "@frui.ts/screens";

export default class InitializationService implements IInitializationService {
  @observable isPreLoginInitialized = false;
  @observable isPostLoginInitialized = false;
  @observable isWsFailed = false;

  constructor(
    private userContext: UserContext,
    private localization: ILocalizationService,
    private validation: ValidationService,
    private security: SecurityService,
    private enums: EnumsService,
    private portalSettings: PortalSettingsService,
    private dataSync: DataSyncService,
    private bannersService: BannersService,
    private messagesService: MessagesService,
    private gaService: GAService,
    private appendixService: AppendixesService
  ) {}

  async preLoginInitialize() {
    const asyncCalls = Promise.all([
      this.security.checkSession(),
      this.localization.initialize("cs"),
      this.portalSettings.initialize(),
      this.gaService.initialize(),
    ]);
    document.documentElement.lang = "cs";
    this.validation.initialize(validatorsRepository);

    const [session] = await asyncCalls;

    runInAction(() => {
      this.isPreLoginInitialized = true;
      session.applySessionCallback();
    });
  }

  async postLoginInitialize() {
    if (!this.userContext.isAdmin) {
      try {
        await this.dataSync.initialize();
      } catch (e) {
        runInAction(() => (this.isWsFailed = true));
        return;
      }
    }

    await Promise.all([
      this.enums.initialize(),
      this.bannersService.initialize(),
      this.messagesService.initialize(),
      this.appendixService.initialize(),
    ]);
    runInAction(() => (this.isPostLoginInitialized = true));
  }

  postLogoutCleanup() {
    this.dataSync.reset();
    runInAction(() => (this.isPostLoginInitialized = false));
  }

  @bound
  translateGeneral(code: string) {
    if (this.isPreLoginInitialized) {
      return this.localization.translateGeneral(code);
    } else {
      return "";
    }
  }

  @bound
  @watchBusy
  logout() {
    if (this.userContext.morphedAdminId) {
      this.security.unmorphPartner();
    } else {
      this.security.logout();
    }
  }
}
