import type { ILocalizationService } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { ConductorSingleChild, Router } from "@frui.ts/screens";
import { groupBy, openInNewTab } from "helpers/utils";
import CommodityType from "models/commodityType";
import DataSyncService from "services/dataSyncService";
import EnumsService from "services/enumsService";
import UserContext from "services/userContext";
import { IModule } from "viewModels/types";
import ProductDetailViewModel from "../products/myProducts/productDetailViewModel";
import BannersService from "services/bannersService";

const navigationName = "supply_points";

@Router.registerRoute({ name: Router.Self, route: navigationName, children: [ProductDetailViewModel] })
export default class OverviewViewModel extends ConductorSingleChild<ProductDetailViewModel> implements IModule {
  orderIndex = 1;
  navigationName = navigationName;

  constructor(
    public localization: ILocalizationService,
    public router: Router,
    private detailFactory: ReturnType<typeof ProductDetailViewModel.Factory>,
    private userContext: UserContext,
    private dataService: DataSyncService,
    private enumsService: EnumsService,
    private bannersService: BannersService
  ) {
    super();
    this.name = this.translate("title");
  }

  @bound
  addSupplyPoint() {
    openInNewTab(this.userContext.newSupplyPointLink);
  }

  get activeSupplyPointLabel() {
    const activeSupplyPoint = this.activeChild?.productDetailContext.supplyPointCore;
    return activeSupplyPoint ? activeSupplyPoint?.name ?? activeSupplyPoint?.addressText : "";
  }

  get supplyPoints() {
    return this.dataService.supplyPoints;
  }

  get supplyPointsByType() {
    const supplyPoints = this.dataService.supplyPoints;
    return groupBy(supplyPoints, x => this.enumsService.getCommodityType(x.commodityId) as CommodityType);
  }

  get isFullScreen() {
    return !!this.activeChild;
  }

  get newSupplyPointLink() {
    return this.userContext.newSupplyPointLink;
  }

  get crossSellBanner() {
    return this.bannersService.activeCrossSellBanner;
  }

  get noticeText() {
    return this.activeChild?.productDetailContext.supplyPoint?.dashboardNotice;
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`dashboard.supply_points.${key}`);
  }

  @bound translateGeneral(key: string, placeholders?: Record<string, string>) {
    return this.localization.translateGeneral(`general.${key}`, placeholders);
  }

  protected findNavigationChild(navigationName: string | undefined) {
    if (navigationName) {
      const supplyPointId = +navigationName;
      const partnerId = this.userContext.activePartnerId;

      if (supplyPointId && partnerId) {
        const currentChildContext = this.activeChild?.productDetailContext;
        if (currentChildContext?.supplyPointId === supplyPointId && currentChildContext?.partnerId === partnerId) {
          return this.activeChild;
        }

        const supplyPointInfo = this.dataService.supplyPoints.find(x => x.id === supplyPointId);
        if (supplyPointInfo) {
          return this.detailFactory(supplyPointInfo, partnerId);
        }
      }
    }

    return undefined;
  }
}
