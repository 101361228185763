import { Button, ButtonTooltip, HeaderRow, LoaderOverlay } from "@emanprague/ppaskit";
import { Card, Col, Row } from "react-bootstrap";
import { registerView, View, ViewComponent } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import React from "react";
import { createColumnsContext, DataColumn } from "controls/tableHelper";
import { Check, CollectionCheck, Input } from "@frui.ts/bootstrap";
import { ResponsiveTable } from "@frui.ts/dataviews";
import FilterButton from "controls/filterButton";
import DatePicker from "controls/twoway/DatePicker";
import iconEdit from "@emanprague/ppaskit/dist/assets/icons/Edit Light.svg";
import iconEye from "@emanprague/ppaskit/dist/assets/icons/Eye Light.svg";
import iconDelete from "@emanprague/ppaskit/dist/assets/icons/Delete Light.svg";
import EmptyDataMessage from "components/EmptyDataMessage";
import { formatRange } from "helpers/utils";
import ProductCardsPageViewModel from "viewModels/admin/productCards/productCardsPageViewModel";
import ProductCardListItem from "entities/productCardListItem";

const columns: DataColumn<ProductCardListItem, ProductCardsPageViewModel>[] = [
  {
    titleFactory: ({ vm }) => <Check custom target={vm} property="isAllSelected" threeState label="" />,
    valueFormatter: ({ item, context }) => (
      <CollectionCheck custom label="" target={context.vm} property="selectedItems" value={item} />
    ),
    responsiveVisible: false,
  },
  {
    property: "order",
    titleFactory: ({ vm, ta }) => <>{ta("product_card", "order")}</>,
    responsiveTitleFactory: ({ ta }) => ta("product_card", "order"),
  },
  {
    property: "title",
    titleFactory: ({ vm, ta }) => (
      <>
        {ta("product_card", "title")}
        <FilterButton
          id="idFilter"
          isActive={!!vm.filter.title}
          clearLabel={vm.translateGeneral("cancel_button")}
          confirmLabel={vm.translateGeneral("confirm")}
          onClear={() => vm.clearFilter("title")}
          onConfirm={vm.applyFilterAndLoad}>
          <Input target={vm.filter} property="title" placeholder={vm.translateGeneral("search")} />
        </FilterButton>
      </>
    ),
    valueFormatter: ({ value }) => <span className="text-nowrap">{value}</span>,
    responsiveTitleFactory: ({ ta }) => ta("product_card", "title"),
  },
  {
    property: "category",
    titleFactory: ({ vm, ta }) => (
      <>
        {ta("product_card", "category")}
        <FilterButton
          id="stateFilter"
          isActive={!!vm.filter.category}
          clearLabel={vm.translateGeneral("cancel_button")}
          confirmLabel={vm.translateGeneral("confirm")}
          onClear={() => vm.clearFilter("category")}
          onConfirm={vm.applyFilterAndLoad}>
          {vm.categories.map(item => (
            <Check
              custom
              key={item.id}
              value={item.id}
              target={vm.filter}
              property="category"
              label={item.name}
              className="mt-1"
              type="radio"
            />
          ))}
        </FilterButton>
      </>
    ),
    valueFormatter: ({ value, context }) =>
      context.vm.localization.translateGeneral(`admin.product_cards.detail.category_name.${value}`),
    responsiveTitleFactory: ({ ta }) => ta("banner", "type"),
  },
  {
    titleFactory: ({ vm, ta }) => (
      <>
        {ta("product_card", "validity")}
        <FilterButton
          id="validityFilter"
          isActive={!!vm.filter.validFrom || !!vm.filter.validTo}
          clearLabel={vm.translateGeneral("cancel_button")}
          confirmLabel={vm.translateGeneral("confirm")}
          onClear={() => vm.clearFilter("validFrom", "validTo")}
          onConfirm={vm.applyFilterAndLoad}>
          <Row className="align-items-center">
            <Col className="mr-n3">
              <span className="date-filter">{vm.translateGeneral("filter_date_from")}</span>
              <DatePicker
                target={vm.filter}
                property="validFrom"
                selectsStart
                startDate={vm.filter.validFrom}
                endDate={vm.filter.validTo}
                maxDate={vm.filter.validTo}
              />
            </Col>
            <Col>
              <span className="date-filter to">{vm.translateGeneral("filter_date_to")}</span>
              <DatePicker
                target={vm.filter}
                property="validTo"
                selectsEnd
                startDate={vm.filter.validFrom}
                endDate={vm.filter.validTo}
                minDate={vm.filter.validFrom}
              />
            </Col>
          </Row>
        </FilterButton>
      </>
    ),
    valueFormatter: ({ item, context }) =>
      formatRange(context.vm.localization.formatDate(item.validFrom), context.vm.localization.formatDate(item.validTo)),
    responsiveTitleFactory: ({ ta }) => ta("product_card", "validity"),
  },
  {
    valueFormatter: ({ item, context }) => (
      <span className="text-nowrap">
        <ButtonTooltip tooltip={context.vm.translateGeneral("edit")} className="mr-2">
          <Button
            size="sm"
            variant="secondary"
            className="btn-responsive-block square-button"
            onClick={() => context.vm.openDetail(item.id)}>
            <img src={iconEdit} width={18} height={18} alt="" />
          </Button>
        </ButtonTooltip>
        <ButtonTooltip tooltip={context.vm.translateGeneral("preview")} className="mr-2">
          <Button
            size="sm"
            variant="secondary"
            className="btn-responsive-block square-button"
            onClick={() => context.vm.openPreview(item.id)}>
            <img src={iconEye} width={18} height={18} alt="" />
          </Button>
        </ButtonTooltip>
        <ButtonTooltip tooltip={context.vm.translateGeneral("delete")} last>
          <Button
            size="sm"
            variant="secondary"
            className="btn-responsive-block square-button"
            onClick={() => context.vm.openDelete([item.id])}>
            <img src={iconDelete} width={18} height={18} alt="" />
          </Button>
        </ButtonTooltip>
      </span>
    ),
    responsiveTitle: false,
    cellClassName: "cell-right",
  },
];

const productCardsPageView: ViewComponent<ProductCardsPageViewModel> = observer(({ vm }) => {
  const { translateGeneral: tg } = vm.localization;

  return (
    <>
      <HeaderRow level={2} title={vm.translate("name")} className="mx-1 mx-sm-n3">
        <Button
          variant="primary"
          onClick={() => {
            vm.addProductCard();
          }}>
          {vm.translate("button_add_card")}
        </Button>
      </HeaderRow>
      <Row className="mt-4">
        <Col xs={12}>
          <Card>
            <Card.Body>
              <LoaderOverlay loading={vm.busyWatcher.isBusy}>
                {vm.selectedItems.size > 0 && (
                  <Row className="mb-3 justify-content-between align-items-center">
                    <Col xs={12} className="d-flex justify-content-end">
                      <Button size="sm" onClick={() => vm.openDelete(vm.selectedProductCardsIds)}>
                        {vm.deleteButtonLabel}
                      </Button>
                    </Col>
                  </Row>
                )}

                <div className="responsive-table-wrapper">
                  <ResponsiveTable
                    className="table no-wrap"
                    items={vm.items}
                    itemKey="id"
                    columns={columns}
                    context={createColumnsContext(vm)}
                  />
                </div>
                {!vm.items?.length && <EmptyDataMessage translateGeneral={tg} />}

                {vm.canLoadData && (
                  <div className="load-more-button">
                    <Button onClick={vm.loadData} variant="secondary">
                      {tg("general.button_load_more")}
                    </Button>
                  </div>
                )}

                <View vm={vm.activeChild} />
              </LoaderOverlay>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
});

registerView(productCardsPageView, ProductCardsPageViewModel);
