import { observable } from "mobx";
import { Type } from "class-transformer";
import { Expose } from "class-transformer";
import SupplyPointListItemContract from "./supplyPointListItemContract";
import Address from "./address";
import SupplyPointListItemAccount from "./supplyPointListItemAccount";
import SupplyPointButtonSettings from "./supplyPointButtonSettings";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class SupplyPointListItem {
  /**
   * @example
   * 200
   */
  id!: number;

  /**
   * @example
   * 123456789
   */
  @observable
  @Expose({ name: "external_id" })
  externalId!: string;

  /**
   * @example
   * Chata
   */
  @observable
  name!: string;

  /**
   * @example
   * 859182400804904899
   */
  @observable
  code!: string;

  /**
   * Commodity id from enumeration
   */
  @observable
  @Expose({ name: "commodity_id" })
  commodityId!: number;

  @observable
  @Expose({ name: "account_id" })
  accountId!: number;

  /**
   * @example
   * Kolmá 1200/12a
   */
  @observable
  @Expose({ name: "address_text" })
  addressText!: string;

  @observable
  @Expose({ name: "deposit_due_amount" })
  depositDueAmount!: number;

  @observable
  @Expose({ name: "deposit_due_count" })
  depositDueCount!: number;

  @observable
  @Expose({ name: "invoice_due_amount" })
  invoiceDueAmount!: number;

  @observable
  @Expose({ name: "invoice_due_count" })
  invoiceDueCount!: number;

  @observable
  @Expose({ name: "dashboard_notice" })
  dashboardNotice?: string;

  @observable
  @Type(() => SupplyPointListItemAccount)
  account!: SupplyPointListItemAccount;

  @observable
  @Type(() => Address)
  @Expose({ name: "postal_address" })
  postalAddress?: Address;

  @observable
  @Type(() => SupplyPointListItemContract)
  contract!: SupplyPointListItemContract;

  @observable
  @Type(() => SupplyPointButtonSettings)
  settings: SupplyPointButtonSettings;

  static ValidationRules = {
    id: { required: true, number: true },
    externalId: { required: true },
    name: { required: true },
    code: { required: true },
    commodityId: { required: true, number: true },
    accountId: { required: true, number: true },
    addressText: { required: true },
    depositDueAmount: { required: true, number: true },
    depositDueCount: { required: true, number: true },
    invoiceDueAmount: { required: true, number: true },
    invoiceDueCount: { required: true, number: true },
    account: { required: true },
    contract: { required: true },
  };
}
