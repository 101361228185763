import React from "react";
import OverlayTrigger, { OverlayTriggerProps as OverlayTriggerBootstrapProps } from "react-bootstrap/OverlayTrigger";
import TooltipBootstrap from "react-bootstrap/Tooltip";
import { styleBlock } from "../";

export interface TooltipProps extends Omit<OverlayTriggerBootstrapProps, "overlay"> {
  tooltip: React.ReactNode;
  id: string;
  className?: string;
  wrap?: boolean; // wrap in "span" in case the single child is disabled and prevents mouse events
}

const Tooltip: React.FunctionComponent<TooltipProps> = ({ id, className, placement, tooltip, children, wrap, ...rest }) => {
  const classNames = styleBlock("tooltip");
  const cn = classNames("").mix([className]);

  return (
    <OverlayTrigger
      placement={placement}
      popperConfig={{
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, 10],
            },
          },
        ],
      }}
      overlay={
        <TooltipBootstrap id={id} className={cn}>
          {tooltip}
        </TooltipBootstrap>
      }
      {...rest}>
      {wrap ? <span style={{ display: "inline-block" }}>{children}</span> : children}
    </OverlayTrigger>
  );
};

Tooltip.defaultProps = {
  placement: "auto",
};

export default Tooltip;
