import { registerView, ViewComponent } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import React from "react";
import { Modal, Button, HeaderRow } from "@emanprague/ppaskit";
import ContractDetailPageViewModel from "viewModels/products/myProducts/detailPages/contract/contractDetailPageViewModel";
import "./style.scss";
import AppendixesPageViewModel from "viewModels/products/appendixesPageViewModel";

const AppendixesModalView: ViewComponent<AppendixesPageViewModel> = observer(({ vm }) => {
  return (
    <>
      <Modal centered show onHide={vm.requestClose} className="wider-modal">
        <Modal.Header closeButton />
        <Modal.Body className="mx-5">
          <div className="text-center mb-4 mx-3 mt-n3">
            <HeaderRow title={vm.translate("modal_title")} level={4} />
          </div>
          <div className="mb-4">
            <p className="font-size-sm">{vm.translate("text_part1")}</p>
          </div>

          <div className="new-appendixes__scroll-gradient">
            <div className="new-appendixes__table-container blue-scrollbar">
              <table className="table responsive-col2row wider-row last-column-right new-appendixes__table mb-3">
                <tbody>
                  {vm.supplyPointsWithAppendixes.map(item => (
                    <tr key={item.id}>
                      <td>
                        <img className="mr-2" width="22" height="21" draggable={false} src={vm.supplyPointIcon(item)} alt="" />
                        <span className="new_appendixes__address-text">{item.addressText}</span>
                      </td>
                      <td className="text-nowrap">{vm.getNumberOfAppendixesLabel(item.id)}</td>
                      <td className="pr-0">
                        <Button
                          href={vm.router.getUrl(ContractDetailPageViewModel, { supplyPointId: item.id })}
                          onClick={vm.requestClose}
                          variant="secondary"
                          size="sm">
                          {vm.translateGeneral("display_button")}
                        </Button>
                      </td>
                      <td className="pr-2" />
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
});

registerView(AppendixesModalView, AppendixesPageViewModel);
