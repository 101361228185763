import { IEventBus, ILocalizationService } from "@emanprague/shared-services";
import ProductCardPageViewModel from "./productCardsPageViewModel";
import { IProductPage } from "viewModels/types";
import ProductCardsService from "services/productCardsService";

export default class CngPageViewModel extends ProductCardPageViewModel implements IProductPage {
  orderIndex = 2;
  navigationName = "cng";

  constructor(public localization: ILocalizationService, productCardsService: ProductCardsService, eventBus: IEventBus) {
    super("cng", localization, productCardsService);
  }
}
