import { ILocalizationService } from "@emanprague/shared-services";
import { SortingDirection } from "@frui.ts/data";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, Router, watchBusy } from "@frui.ts/screens";
import InvoiceListItem from "entities/invoiceListItem";
import InvoiceListItemState from "entities/invoiceListItemState";
import ReqExportType from "entities/reqExportType";
import { createSupplyPointsWithIcons } from "helpers/supplyPointHelper";
import { action, computed, observable, runInAction } from "mobx";
import InvoicesFilter from "models/invoicesFilter";
import InvoicesRepository from "repositories/invoicesRepository";
import DataSyncService from "services/dataSyncService";
import EnumsService from "services/enumsService";
import UserContext from "services/userContext";
import ContinuousListViewModelBase from "viewModels/continuousListViewModelBase";
import InvoiceDetailViewModel from "./invoiceDetailViewModel";
import { IFinancePage } from "./types";

const navigationName = "invoices";
export const defaultAmountRange = [0, 10000];

@Router.registerRoute({ name: Router.Self, route: `${navigationName}` })
export default class InvoicesPageViewModel
  extends ContinuousListViewModelBase<InvoiceListItem, InvoicesFilter, InvoiceDetailViewModel>
  implements IFinancePage
{
  orderIndex = 2;
  navigationName = navigationName;
  busyWatcher = new BusyWatcher();

  @observable balance = 0;
  @observable stateTypes: { id: InvoiceListItemState | "all"; name: string }[];
  @observable selectedState?: string;

  @computed
  get amountRange() {
    return [this.filter.minAmount ?? defaultAmountRange[0], this.filter.maxAmount ?? defaultAmountRange[1]];
  }
  set amountRange([min, max]: [number, number]) {
    this.filter.minAmount = min === defaultAmountRange[0] ? undefined : min;
    this.filter.maxAmount = max === defaultAmountRange[1] ? undefined : max;
  }

  constructor(
    public localization: ILocalizationService,
    private userContext: UserContext,
    private dataService: DataSyncService,
    private repository: InvoicesRepository,
    public enumsService: EnumsService,
    public router: Router,
    private detailFactory: ReturnType<typeof InvoiceDetailViewModel.Factory>
  ) {
    super();

    this.name = this.translate("title");

    this.stateTypes = [
      { id: "all", name: this.translateGeneral("filter_all") },
      { id: "paid", name: this.translateGeneral("filter_paid") },
      { id: "before_payment", name: this.translateGeneral("filter_before_due_date") },
      { id: "unpaid", name: this.translateGeneral("filter_after_due_date") },
      { id: "payment_in_process", name: this.translateGeneral("filter_payment_in_process") },
    ];

    this.pagingFilter.sortColumn = "pay_date";
    this.pagingFilter.sortDirection = SortingDirection.Descending;
  }

  @bound
  navigate(subPath: string | undefined, params: { filter?: InvoiceListItemState; change?: boolean }): Promise<void> {
    if (params.filter) {
      runInAction(() => (this.filter.state = [params.filter] as InvoiceListItemState[]));
      this.selectedState = this.stateTypes[3].id; // unpaid
      this.applyFilterAndLoad();
    } else if (!this.items) {
      this.loadData();
    }
    return super.navigate(subPath, params);
  }

  @bound onInitialize() {
    this.initializeStateFilter();
    this.navigate(undefined, {});
  }

  @bound
  @watchBusy
  async loadData() {
    await this.dataService.waitForFinance();
    if (this.userContext.activePartnerId) {
      const invoicesResult = await this.repository.getInvoices(this.userContext.activePartnerId, this.pagingFilter, this.filter);
      if (invoicesResult.success) {
        this.setData(invoicesResult.payload);
      }
    }
  }

  @action
  openDetail(item: InvoiceListItem) {
    if (this.userContext.activePartnerId) {
      const detail = this.detailFactory(item, this.userContext.activePartnerId);
      return this.tryActivateChild(detail);
    }
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`finance.invoices_page.${key}`);
  }

  @bound translateGeneral(key: string) {
    return this.localization.translateGeneral(`general.${key}`);
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected resetFilterValues(filter: InvoicesFilter) {}

  protected createFilter() {
    return new InvoicesFilter();
  }

  @action.bound
  initializeStateFilter() {
    this.selectedState = this.stateTypes[0].id;
  }

  @action.bound
  applyStateFilter() {
    if (this.selectedState !== "all") {
      this.filter.state = [this.selectedState] as InvoiceListItemState[];
    } else {
      this.filter.state = undefined;
    }
    this.applyFilterAndLoad();
  }

  @action.bound
  applySwitchStateFilter(...state: InvoiceListItemState[]) {
    this.filter.state = state;
    this.selectedState = state[0] === "paid" ? "paid" : undefined;
    this.applyFilterAndLoad();
  }

  get switchStateActiveId() {
    if (this.filter.state?.length === 2) {
      if (this.filter.state.includes("paid") && this.filter.state.includes("payment_in_process")) {
        return "paid";
      } else if (this.filter.state.includes("unpaid") && this.filter.state.includes("before_payment")) {
        return "unpaid";
      } else {
        return "";
      }
    }
    return "all";
  }

  @action.bound
  clearFilter(...properties: (keyof InvoicesFilter)[]) {
    properties.forEach(property => {
      this.filter[property] = undefined;
    });
    this.applyFilterAndLoad();
  }

  getFormattedAmount(item: InvoiceListItem) {
    if (item.amount > 0) {
      return `${this.localization.formatNumber(item.amount)} ${this.translateGeneral("currency")}`;
    } else if (item.amount < 0) {
      return `${this.localization.formatNumber(Math.abs(item.amount))} ${this.translateGeneral("currency")}`;
    }
    return "0 " + this.translateGeneral("currency");
  }

  getFormattedAmountType(item: InvoiceListItem) {
    if (item.amount > 0) {
      return `(${this.translate("underpayment")})`;
    } else if (item.amount < 0) {
      return `(${this.translate("overpayment")})`;
    }
    return "";
  }

  get supplyPoints() {
    return createSupplyPointsWithIcons(this.enumsService, this.dataService.supplyPoints);
  }

  get canDownload() {
    return this.selectedItems.size > 0;
  }

  @action.bound
  async addToDownloadQueue(type: ReqExportType) {
    if (this.userContext.activePartnerId) {
      const ids: number[] = [];
      this.selectedItems.forEach(item => {
        ids.push(item.id);
      });

      const result = await this.repository.exportInvoices(this.userContext.activePartnerId, { ids, type });
      if (result.success) {
        this.selectedItems.clear();
      }
    }
  }

  @bound
  invoiceTypeName(id: number) {
    return this.enumsService.getValue("invoiceTypes", id)?.name;
  }
}
