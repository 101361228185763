import { Expose, Transform } from "class-transformer";
import formatISO from "date-fns/formatISO";
import { observable } from "mobx";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class ReadingChartDataReadingsItem {
  @observable
  @Transform(value => (value ? new Date(value) : undefined), { toClassOnly: true })
  @Transform(value => (value ? formatISO(value, { representation: "date" }) : undefined), { toPlainOnly: true })
  from?: Date;

  @observable
  @Transform(value => (value ? new Date(value) : undefined), { toClassOnly: true })
  @Transform(value => (value ? formatISO(value, { representation: "date" }) : undefined), { toPlainOnly: true })
  to?: Date;

  @observable
  unit!: string;

  @observable
  value!: number;

  @observable
  @Expose({ name: "value_nt" })
  valueNt?: number;

  @observable
  @Expose({ name: "value_vt" })
  valueVt?: number;

  static ValidationRules = {
    from: { date: true },
    to: { date: true },
    unit: { required: true },
    value: { required: true, number: true },
    valueNt: { number: true },
    valueVt: { number: true },
  };
}
