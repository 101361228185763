import ReqChangeAdvancePayment from "entities/reqChangeAdvancePayment";
import ReqChangeAdvanceRequest from "entities/reqChangeAdvanceRequest";
import ReqChangeInvoicePayment from "entities/reqChangeInvoicePayment";
import ReqChangeInvoiceSentMethod from "entities/reqChangeInvoiceSentMethod";
import ReqChangePostalAddress from "entities/reqChangePostalAddress";
import ReqChangeSupplyPointAddress from "entities/reqChangeSupplyPointAddress";
import ReqChangeSupplyPointDetails from "entities/reqChangeSupplyPointDetails";
import ReqTransferSupplyPointRequest from "entities/reqTransferSupplyPointRequest";
import SupplyPoint from "entities/supplyPoint";
import SupplyPointListItem from "entities/supplyPointListItem";
import { RequestsEvents } from "services/events";
import SupplyPointsChangeStatus from "../models/SupplyPointsChangeStatus";
import RepositoryBase from "./repositoryBase";

export default class SupplyPointsRepository extends RepositoryBase {
  getSupplyPoints(partnerId: number) {
    return this.callApi(api => api.all(`partners/${partnerId}/supply_points`).getEntities(SupplyPointListItem));
  }

  getSupplyPoint(partnerId: number, supplyPointId: number) {
    return this.callApi(api => api.all(`partners/${partnerId}/supply_points/${supplyPointId}`).getEntity(SupplyPoint));
  }

  changeSupplyPointDetails(partnerId: number, supplyPointId: number, details: ReqChangeSupplyPointDetails) {
    const promise = this.callApi(api =>
      api
        .all(`partners/${partnerId}/supply_points/${supplyPointId}/change_details`)
        .postEntity(details, ReqChangeSupplyPointDetails)
    );

    promise.then(() => this.eventBus.publish(RequestsEvents.requestCreated(details)));

    return promise;
  }

  changeSupplyPointAddress(partnerId: number, supplyPointId: number, address: ReqChangeSupplyPointAddress) {
    const formData = address.toFormData();
    const promise = this.callApi(api =>
      api.all(`partners/${partnerId}/supply_points/${supplyPointId}/change_address`).postData(formData)
    );
    promise.then(() => this.eventBus.publish(RequestsEvents.requestCreated(address)));

    return promise;
  }

  changeInvoicePaymentMethods(partnerId: number, details: ReqChangeInvoicePayment) {
    const promise = this.callApi(api =>
      api.all(`partners/${partnerId}/supply_points/invoice_payments`).postEntity(details, SupplyPointsChangeStatus)
    );
    promise.then(() => this.eventBus.publish(RequestsEvents.requestCreated(details)));

    return promise;
  }

  changeAdvancePaymentMethods(partnerId: number, details: ReqChangeAdvancePayment) {
    const promise = this.callApi(api =>
      api.all(`partners/${partnerId}/supply_points/advance_payments`).postEntity(details, SupplyPointsChangeStatus)
    );
    promise.then(() => this.eventBus.publish(RequestsEvents.requestCreated(details)));

    return promise;
  }

  changeInvoiceSentMethod(partnerId: number, details: ReqChangeInvoiceSentMethod) {
    details.street = details.street || undefined;

    const promise = this.callApi(api =>
      api.all(`partners/${partnerId}/supply_points/invoice_sent_method`).postEntity(details, SupplyPointsChangeStatus)
    );
    promise.then(() => this.eventBus.publish(RequestsEvents.requestCreated(details)));

    return promise;
  }

  transferCustomer(partnerId: number, supplyPointId: number, transfer: ReqTransferSupplyPointRequest) {
    if (transfer.address) {
      transfer.address.street = transfer.address.street || undefined;
    }

    const promise = this.callApi(api =>
      api.all(`partners/${partnerId}/supply_points/${supplyPointId}/transfer`).postEntity(transfer, ReqTransferSupplyPointRequest)
    );
    promise.then(() => this.eventBus.publish(RequestsEvents.requestCreated(transfer)));

    return promise;
  }

  changeAdvances(partnerId: number, supplyPointId: number, change: ReqChangeAdvanceRequest) {
    const formData = change.toFormData();
    const promise = this.callApi(api =>
      api.all(`partners/${partnerId}/supply_points/${supplyPointId}/change_advances`).postData(formData)
    );
    promise.then(() => this.eventBus.publish(RequestsEvents.requestCreated(change)));

    return promise;
  }

  changePostalAddress(partnerId: number, details: ReqChangePostalAddress) {
    details.street = details.street || undefined;

    const promise = this.callApi(api =>
      api.all(`partners/${partnerId}/supply_points/postal_address`).postEntity(details, SupplyPointsChangeStatus)
    );
    promise.then(() => this.eventBus.publish(RequestsEvents.requestCreated(details)));

    return promise;
  }
}
