import { observable } from "mobx";
import { Type } from "class-transformer";
import NewAppendixListItem from "./newAppendixListItem";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class NewAppendixList {
  @observable
  total!: number;

  @observable
  @Type(() => NewAppendixListItem)
  appendixes!: NewAppendixListItem[];

  static ValidationRules = {
    total: { required: true, number: true },
    appendixes: { required: true },
  };
}
