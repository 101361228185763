import type { IEventBus, ILocalizationService } from "@emanprague/shared-services";
import { IPagingFilter } from "@frui.ts/data";
import { bound, IDisposable } from "@frui.ts/helpers";
import { BusyWatcher, ConductorSingleChild, Router, watchBusy } from "@frui.ts/screens";
import Contract from "entities/contract";
import LinkListItem from "entities/linkListItem";
import ReadingChartData from "entities/readingChartData";
import { isDoubleTariff } from "helpers/supplyPointHelper";
import takeRight from "lodash/takeRight";
import { action, computed, observable, runInAction } from "mobx";
import CommodityType from "models/commodityType";
import LinksFilter from "models/linksFilter";
import ContractRepository from "repositories/contractRepository";
import LinksRepository from "repositories/linksRepository";
import ReadingsRepository from "repositories/readingsRepository";
import EnumsService from "services/enumsService";
import { DataEvents } from "services/events";
import { IDetailPage } from "viewModels/types";
import ProductDetailViewModel from "../../productDetailViewModel";
import CustomerTransferViewModel from "./customerTransferViewModel";
import ModalEditSupplyPointViewModel from "./modalEditSupplyPointViewModel";

export const navigationName = "detail";

const numberOfBarsDisplayed = 3;

@Router.registerRoute({ name: CustomerTransferViewModel, route: `${navigationName}/${CustomerTransferViewModel.navigationName}` })
export default class SupplyPointDetailPageViewModel
  extends ConductorSingleChild<ModalEditSupplyPointViewModel | CustomerTransferViewModel>
  implements IDetailPage
{
  orderIndex = 0;
  navigationName = navigationName;
  className = "icon-home";

  @observable supplyPointContract: Contract;
  @observable readingChartData: ReadingChartData;

  @observable suggestedLinks: LinkListItem[] = [];

  parent: ProductDetailViewModel;
  busyWatcher = new BusyWatcher();
  private eventHandlers: IDisposable[];

  constructor(
    public localization: ILocalizationService,
    public enumsService: EnumsService,
    private modalEditSupplyPointViewModelFactory: ReturnType<typeof ModalEditSupplyPointViewModel.Factory>,
    private customerTransferViewModelFactory: ReturnType<typeof CustomerTransferViewModel.Factory>,
    private readingsRepository: ReadingsRepository,
    private contractRepository: ContractRepository,
    public router: Router,
    eventBus: IEventBus,
    private linksRepository: LinksRepository
  ) {
    super();

    this.name = this.translate("title");
    this.eventHandlers = [eventBus.subscribe(DataEvents.supplyPointsUpdated, this.loadData)];
  }

  protected onInitialize() {
    this.loadData();
    this.fetchSuggestedLinks();
  }

  protected async onDeactivate(isClosing: boolean) {
    await super.onDeactivate(isClosing);

    if (isClosing) {
      this.eventHandlers?.forEach(x => x.dispose());
    }
  }

  @action.bound
  @watchBusy
  private async loadData() {
    const { partnerId } = this.parent.productDetailContext;

    if (!partnerId || !this.supplyPoint?.id) {
      return;
    }

    const [resultContract, resultReadingChartData] = await Promise.all([
      this.contractRepository.getContract(partnerId, this.supplyPoint.id),
      this.readingsRepository.getReadingsListChart(partnerId, this.supplyPoint.id, 5),
    ]);

    runInAction(() => {
      if (resultContract.success) {
        this.supplyPointContract = resultContract.payload;
      }
      if (resultReadingChartData.success) {
        this.readingChartData = {
          ...resultReadingChartData.payload,
          data: takeRight(resultReadingChartData.payload.data, numberOfBarsDisplayed),
        };
      }
    });
  }

  @bound
  async fetchSuggestedLinks() {
    const pagingFilter: IPagingFilter = { offset: 0, limit: 10 };
    const filter = new LinksFilter();
    filter.section = "suggested_links";
    const result = await this.linksRepository.getLinks(pagingFilter, filter);

    if (result.success && result.payload[0]?.length) {
      const links = result.payload[0];
      runInAction(() => {
        this.suggestedLinks = links;
      });
    }
  }

  get supplyPoint() {
    return this.parent.productDetailContext.supplyPoint;
  }

  get commodityType() {
    return this.parent.productDetailContext.commodityType;
  }

  get balanceSummary() {
    if (this.supplyPoint) {
      return this.supplyPoint.depositDueAmount + this.supplyPoint.invoiceDueAmount;
    }
    return 0;
  }

  get dueItemsSummary() {
    if (this.supplyPoint) {
      return this.supplyPoint.depositDueCount + this.supplyPoint.invoiceDueCount;
    }
    return 0;
  }

  get isOneOrMoreDue() {
    return this.dueItemsSummary > 0;
  }

  get accountStatus() {
    if (this.supplyPoint) {
      if (this.supplyPoint.invoiceDueCount > 0 && this.supplyPoint.depositDueCount) {
        const countKeyAdvances = this.localization.getPluralForm(this.supplyPoint.depositDueCount);
        const countKeyInvoices = this.localization.getPluralForm(this.supplyPoint.invoiceDueCount);
        return this.translateGeneral("balance_widget.advances_invoices_" + countKeyAdvances + "_" + countKeyInvoices, {
          countAdvances: this.supplyPoint.depositDueCount.toString(),
          countInvoices: this.supplyPoint.invoiceDueCount.toString(),
        });
      } else if (this.supplyPoint.invoiceDueCount > 0) {
        const countKey = this.localization.getPluralForm(this.supplyPoint.invoiceDueCount);
        return this.translateGeneral("balance_widget.invoices_" + countKey, {
          count: this.supplyPoint.invoiceDueCount.toString(),
        });
      } else if (this.supplyPoint.depositDueCount > 0) {
        const countKey = this.localization.getPluralForm(this.supplyPoint.depositDueCount);
        return this.translateGeneral("balance_widget.advances_" + countKey, {
          count: this.supplyPoint.depositDueCount.toString(),
        });
      }
    }
    return this.translateGeneral("balance_widget.ok");
  }

  @computed
  get chartLabels() {
    if (this.readingChartData?.data) {
      const format = this.localization.formatDate;
      return this.readingChartData.data.map(data => {
        if (data.from && data.to) {
          return `${format(data.from)} - ${format(data.to)}`;
        } else {
          return format(data.from || data.to);
        }
      });
    } else {
      return [];
    }
  }

  @computed
  get chartDatasets() {
    const dataNT: number[] = [];
    const dataVT: number[] = [];
    const dataTotal: number[] = [];

    if (this.readingChartData?.data) {
      this.readingChartData.data.forEach((data: any) => {
        dataVT.push(data.valueVt);
        dataNT.push(data.valueNt);
        dataTotal.push(data.value);
      });
    }

    if (this.isDoubleTariff && this.commodityType === CommodityType.Electricity) {
      return [
        { label: `${this.translateGeneral("high_tariff")}`, data: dataVT },
        { label: `${this.translateGeneral("low_tariff")}`, data: dataNT },
      ];
    } else {
      return [{ label: "", data: dataTotal }];
    }
  }

  get isDoubleTariff() {
    return (
      this.parent.productDetailContext.supplyPoint?.tariff && isDoubleTariff(this.parent.productDetailContext.supplyPoint.tariff)
    );
  }

  @action.bound
  activateEditSupplyPointModal() {
    return this.tryActivateChild(this.modalEditSupplyPointViewModelFactory(this.parent.productDetailContext));
  }

  @action.bound
  activateCustomerTransfer() {
    return this.tryActivateChild(this.customerTransferViewModelFactory(this.parent.productDetailContext));
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`supply_points.detail.${key}`);
  }

  @bound translateGeneral(key: string, placeholders?: Record<string, string>) {
    return this.localization.translateGeneral(`general.${key}`, placeholders);
  }

  @computed
  get commodityName() {
    return this.supplyPoint?.commodityId && this.enumsService.getValue("commodities", this.supplyPoint.commodityId)?.name;
  }

  @computed
  get installationFloorName() {
    return (
      this.supplyPoint?.installationFloorId &&
      this.enumsService.getValue("installationFloors", this.supplyPoint.installationFloorId)?.name
    );
  }

  protected findNavigationChild(navigationName: string | undefined) {
    if (navigationName === CustomerTransferViewModel.navigationName) {
      return this.customerTransferViewModelFactory(this.parent.productDetailContext);
    }

    return undefined;
  }
}
