import { observable } from "mobx";
import { Expose, Type } from "class-transformer";
import PartnerListItem from "./partnerListItem";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class PartnerImpersonationListItem extends PartnerListItem {
  @observable
  @Expose({ name: "locked" })
  locked?: boolean;
}
