import { BusyWatcher, ScreenBase, watchBusy } from "@frui.ts/screens";
import { ILocalizationService, INotificationService, SeverityLevel } from "@emanprague/shared-services";
import { interfaces } from "inversify";
import { bound } from "@frui.ts/helpers";
import { action, observable, runInAction } from "mobx";
import { attachAutomaticValidator, hasVisibleErrors, validate } from "@frui.ts/validation";
import AdminRepository from "repositories/adminRepository";
import { unwrapErrorMessage } from "repositories/helpers";
import ReqMessage from "entities/reqMessage";
import { assignTargetPropertiesOnly } from "helpers/utils";

const navigationName = "new";

export default class MessageDetailViewModel extends ScreenBase {
  static navigationName = navigationName;

  navigationName = navigationName;
  busyWatcher = new BusyWatcher();

  @observable data: ReqMessage;
  @observable isNewMessage = true;
  @observable errorMessage?: string;

  constructor(
    public messageId: number | undefined,
    private refreshDataCallback: () => void,
    public localization: ILocalizationService,
    private notificationService: INotificationService,
    private adminRepository: AdminRepository
  ) {
    super();

    this.name = this.translate("title");
    this.data = new ReqMessage();

    if (messageId) {
      this.navigationName += "/detail/" + String(messageId);
      this.isNewMessage = false;
    }

    attachAutomaticValidator(this.data, ReqMessage.ValidationRules);
  }

  protected onInitialize(): Promise<any> | void {
    this.loadData();
  }

  @watchBusy
  async loadData() {
    if (!this.messageId) {
      return;
    }

    const response = await this.adminRepository.getMessageDetail(this.messageId);
    if (response.success) {
      runInAction(() => assignTargetPropertiesOnly(this.data, response.payload));
    } else {
      runInAction(() => (this.errorMessage = unwrapErrorMessage(response.payload)));
    }
  }

  @action.bound
  clearDates() {
    this.data.validFrom = undefined;
    this.data.validTo = undefined;
  }

  @action.bound
  @watchBusy
  async confirmRequest() {
    this.errorMessage = undefined;

    validate(this.data);
    if (!this.canConfirm) {
      return;
    }

    const response = this.isNewMessage
      ? await this.adminRepository.addMessage(this.data)
      : await this.adminRepository.updateMessage(this.messageId!, this.data);
    if (response.success) {
      this.notificationService.addNotification(this.translateGeneral("request_sent_success"), SeverityLevel.success);
      this.refreshDataCallback();
      this.requestClose();
    } else {
      runInAction(() => (this.errorMessage = unwrapErrorMessage(response.payload)));
    }
  }

  get canConfirm() {
    return !hasVisibleErrors(this.data);
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`admin.messages.detail.${key}`);
  }

  @bound translateConditions(key: string) {
    return this.localization.translateGeneral(`admin.display_condition.${key}`);
  }

  @bound translateGeneral(key: string) {
    return this.localization.translateGeneral(`general.${key}`);
  }

  static Factory({ container }: interfaces.Context) {
    return (messageId: number | undefined, refreshDataCallback: () => void) => {
      return new MessageDetailViewModel(
        messageId,
        refreshDataCallback,
        container.get("ILocalizationService"),
        container.get("INotificationService"),
        container.get(AdminRepository)
      );
    };
  }
}
