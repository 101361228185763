import { bound } from "@frui.ts/helpers";
import { IValidatorsRepository } from "@frui.ts/validation";
import { get } from "mobx";
import { ILocalizationService } from "../contracts/localizationService";
import { DefaultValidatorParams, hasCondition, hasDefaultValidatorParams, ValidationRules } from "./validationRules";

const undefinedValidatorParams = {} as DefaultValidatorParams;

export default class ValidationServiceBase {
  constructor(protected localizationService: ILocalizationService) {}

  initialize(validators: IValidatorsRepository<string>) {
    validators.set("required", this.validateRequired);
    validators.set("requiredIf", this.validateRequiredIf);
    validators.set("number", this.validateNumber);
    validators.set("range", this.validateRange);
    validators.set("equals", this.validateEquals);
    validators.set("equalWith", this.validateEqualWith);
    validators.set("minLength", this.validateMinLength);
    validators.set("maxLength", this.validateMaxLength);
    validators.set("manualErrors", this.manualValidation);
    validators.set("lowerThan", this.validateLowerThan);
  }

  paramsRegex = /%param(\d+)%/g;

  /** Creates localized validation message with params placeholders accordingly replaced */
  protected getLocalizedMessage(messageTemplate: string | undefined, translationCode: string, params?: any, model = "") {
    let message = messageTemplate ?? this.localizationService.translateGeneral(translationCode);

    if (params !== undefined) {
      if (message.includes("%params%")) {
        message = message.replace("%params%", params);
      } else if (Array.isArray(params)) {
        message = message.replace(this.paramsRegex, (match, number) => {
          const paramValue = params[number - 1];
          return paramValue ? this.localizationService.translateAttribute(model, paramValue) : match;
        });
      }
    }

    return message;
  }

  protected getValidationMessage(isValid: boolean, validatorParams: any, translationCode: string, messageParams?: any) {
    if (isValid) {
      return undefined;
    }

    const { message, translationCode: translation } = hasDefaultValidatorParams(validatorParams)
      ? validatorParams
      : undefinedValidatorParams;
    return this.getLocalizedMessage(message, translation ?? translationCode, messageParams);
  }

  @bound
  validateRequired(value: any, propertyName: string, entity: any, params: ValidationRules["required"]) {
    if (!params) {
      return undefined;
    }

    const hasError = value == null || value === "" || value.length === 0;
    return this.getValidationMessage(!hasError, params, "validators.required");
  }

  @bound
  validateRequiredIf(value: any, propertyName: string, entity: any, params: ValidationRules["requiredIf"]) {
    if (!hasCondition(params) || !params.condition(entity)) {
      return undefined;
    }

    const hasError = value == null || value === "" || value.length === 0;
    return this.getValidationMessage(!hasError, params, "validators.requiredIf");
  }

  @bound
  validateLowerThan(value: any, propertyName: string, entity: any, params: ValidationRules["lowerThan"]) {
    if (value == null || value === "") {
      return undefined;
    }

    const currentValue = parseInt(value, 10);
    const compareValue = parseInt(entity[params.key], 10);

    if (currentValue <= compareValue) {
      return undefined;
    }

    return this.getLocalizedMessage(
      params.message,
      params.translationCode ?? "validators.lowerThan",
      params.key.toString(),
      params.modelName
    );
  }

  @bound
  validateNumber(value: any, propertyName: string, entity: any, params: ValidationRules["number"]) {
    if (value == null || value === "") {
      return undefined;
    }

    // Don't check !!parseInt because parseInt("10a", 10) also works
    const isValid = typeof value === "number" || value === parseInt(value, 10).toString();
    return this.getValidationMessage(isValid, params, "validators.number");
  }

  @bound
  validateRange(value: any, propertyName: string, entity: any, params: ValidationRules["range"]) {
    if (value == null || value === "") {
      return undefined;
    }

    const number = typeof value === "number" ? value : parseFloat(value);

    if (params.min !== undefined && params.min > number) {
      return this.getValidationMessage(false, params, "validators.min", params.min);
    }
    if (params.minExclusive !== undefined && params.minExclusive >= number) {
      return this.getValidationMessage(false, params, "validators.minExclusive", params.minExclusive);
    }

    if (params.max !== undefined && params.max < number) {
      return this.getValidationMessage(false, params, "validators.max", params.max);
    }
    if (params.maxExclusive !== undefined && params.maxExclusive <= number) {
      return this.getValidationMessage(false, params, "validators.maxExclusive", params.maxExclusive);
    }

    return undefined;
  }

  @bound
  validateEquals(value: any, propertyName: string, entity: any, params: ValidationRules["equals"]) {
    const expectedValue = params.parameter ?? params;
    const isValid = value === expectedValue;
    return this.getValidationMessage(isValid, params, "validators.equals", [propertyName, expectedValue]);
  }

  @bound
  validateEqualWith(value: any, propertyName: string, entity: any, params: ValidationRules["equalWith"]) {
    const otherPropertyName = typeof params === "string" ? params : params.parameter;
    const isValid = value === entity[otherPropertyName];
    return this.getValidationMessage(isValid, params, "validators.equalWith", [propertyName, otherPropertyName]);
  }

  @bound
  validateMinLength(value: any, propertyName: string, entity: any, params: ValidationRules["minLength"]) {
    const minLength = typeof params === "number" ? params : params.parameter;
    const hasError = value !== null && value !== undefined && value !== "" && value.toString().length < minLength;
    return this.getValidationMessage(!hasError, params, "validators.minLength", [propertyName, minLength]);
  }

  @bound
  validateMaxLength(value: any, propertyName: string, entity: any, params: ValidationRules["maxLength"]) {
    const maxLength = typeof params === "number" ? params : params.parameter;
    const hasError = value !== null && value !== undefined && value !== "" && value.length > maxLength;
    return this.getValidationMessage(!hasError, params, "validators.maxLength", [propertyName, maxLength]);
  }

  // pass observable object with custom errors for the whole entity as params
  manualValidation(value: any, propertyName: string, entity: any, params: ValidationRules["manualErrors"]) {
    return get(params, propertyName);
  }
}
