import { Form } from "@emanprague/ppaskit";
import React from "react";

const ErrorMessage: React.FunctionComponent<{ message?: string }> = ({ message }) => {
  if (!message) {
    return null;
  }

  const isHtml = message.includes("<br");

  return isHtml ? (
    <Form.Control.Feedback type="invalid" dangerouslySetInnerHTML={{ __html: message }} />
  ) : (
    <Form.Control.Feedback type="invalid">{message}</Form.Control.Feedback>
  );
};

export default ErrorMessage;
