import { ILocalizationService } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, ScreenBase, watchBusy } from "@frui.ts/screens";
import downloadjs from "downloadjs";
import AppendixListAppendixesItem from "entities/appendixListAppendixesItem";
import NewAppendixListItem from "entities/newAppendixListItem";
import { interfaces } from "inversify";
import { observable } from "mobx";
import ContractRepository from "repositories/contractRepository";
import ConfirmationService from "services/confirmationService";

export default class ModalDocumentsViewModel extends ScreenBase {
  @observable
  checked = false;

  busyWatcher = new BusyWatcher();

  constructor(
    public localization: ILocalizationService,
    private contractRepository: ContractRepository,
    private confirmationService: ConfirmationService,
    private appendix: NewAppendixListItem | AppendixListAppendixesItem,
    private partnerId: number,
    private supplyPointId: number,
    public confirmMode: boolean
  ) {
    super();
  }

  get attachments() {
    return this.appendix.attachments.sort((a, b) => (a.order || 0) - (b.order || 0));
  }

  get title() {
    return this.confirmMode ? this.translate("appendix_confirm_title") : this.translate("appendix_documents_title");
  }

  @bound
  @watchBusy
  async downloadAll() {
    const response = await this.contractRepository.downloadAppendixDocuments(
      this.partnerId,
      this.supplyPointId,
      this.appendix.id
    );
    if (response.success) {
      const mimeType = (response.payload as any).headers.get("Content-Type") as string;
      const fileNameFromServer = (response.payload as any).headers.get("content-disposition");
      const fileName = decodeURIComponent(fileNameFromServer.split("UTF-8")[1].slice(2));
      const body = await (response.payload as any).blob();

      downloadjs(body, fileName, mimeType);
    }
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`products.contract.${key}`);
  }

  @bound translateGeneral(key: string) {
    return this.localization.translateGeneral(`general.${key}`);
  }

  @bound
  @watchBusy
  async confirm() {
    const response = await this.contractRepository.approveAppendix(this.partnerId, this.supplyPointId, this.appendix.id);

    if (response.success) {
      this.requestClose();
      await this.confirmationService.showConfirmation(this.translate("thank_you_modal"), undefined, undefined, undefined, {
        closeButton: true,
        hideCancelButton: true,
        hideConfirmButton: true,
      });
    }
  }

  static Factory({ container }: interfaces.Context) {
    return (
      appendix: NewAppendixListItem | AppendixListAppendixesItem,
      partnerId: number,
      supplyPointId: number,
      confirmMode: boolean
    ) => {
      return new ModalDocumentsViewModel(
        container.get("ILocalizationService"),
        container.get(ContractRepository),
        container.get(ConfirmationService),
        appendix,
        partnerId,
        supplyPointId,
        confirmMode
      );
    };
  }
}
