import React from "react";
import { FormControlProps } from "react-bootstrap/FormControl";

export interface InputWithRightLabelProps {
  label: string;
}

type InputWithRightLabel = InputWithRightLabelProps &
  FormControlProps &
  Omit<React.InputHTMLAttributes<HTMLInputElement>, "size">;

const InputWithRightLabel: React.FC<InputWithRightLabel> = ({ className, label, children, ...props }) => (
  <div className={"position-relative " + className}>
    {children}
    <span className="input-inner-element text-muted mr-2 font-size-sm">{label}</span>
  </div>
);

export default InputWithRightLabel;
