import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { HeaderRow, styleBlock } from "..";

type MenuPart = {
  links?: Array<LinkProps>;
  label?: string;
};

export interface LinkProps {
  id: number | string;
  title: string;
  href?: string;
  suffix?: string;
  className?: string;
  onClick?: any;
}

export interface SideNavProps {
  className?: string;
  activeId?: LinkProps["id"];
  links?: Array<LinkProps>;
  additionalParts?: Array<MenuPart>;
}
const SideNav: React.FunctionComponent<SideNavProps> = ({ className, activeId, links, additionalParts, ...props }) => {
  const classNames = styleBlock("sidenav").mix(className);

  const activeItem =
    activeId && links
      ? links.filter(point => {
          return point.id === activeId;
        })[0]
      : null;

  const createItems = (links: Array<LinkProps>) => {
    return links.map((link, idx) => {
      let className = link.className ? "with-icon " + link.className : "without-icon ";
      className += activeId === link.id ? " active" : "";

      return (
        <Dropdown.Item className={className} href={link.href} onClick={link.onClick} key={"sidenav-" + idx}>
          {link.title}{" "}
          {link.suffix && <span className="text-right text-danger font-size-sm text-float-right">{link.suffix}</span>}
        </Dropdown.Item>
      );
    });
  };

  let moreLinks =
    additionalParts &&
    additionalParts.map(
      (part: MenuPart) =>
        part.links && part.links.length > 0 && (
          <>
            <Dropdown.Divider />
            {part.label && <HeaderRow title={part.label} level={4} className="pb-2" />}
            {part.links && createItems(part.links)}
          </>
        )
    );

  return (
    <div className={classNames.toString()}>
      <Dropdown>
        <Dropdown.Toggle id="dropdown-sidenav">
          {activeItem && <span className={"active with-icon " + activeItem.className}>{activeItem.title}</span>}
        </Dropdown.Toggle>
        <Dropdown.Menu renderOnMount onSelect={() => null}>
          {links && createItems(links)}
          {moreLinks && moreLinks}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default SideNav;
