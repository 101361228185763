import { Expose, Transform } from "class-transformer";
import formatISO from "date-fns/formatISO";
import { observable } from "mobx";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class ReadingListReadingsItem {
  id!: number;

  @observable
  @Transform(value => (value ? new Date(value) : undefined), { toClassOnly: true })
  @Transform(value => (value ? formatISO(value, { representation: "date" }) : undefined), { toPlainOnly: true })
  date!: Date;

  /**
   * Enumeration::ReadingMethod
   */
  @observable
  @Expose({ name: "reading_method_id" })
  readingMethodId?: number;

  /**
   * Enumeration::ReadingReason
   */
  @observable
  @Expose({ name: "reading_reason_id" })
  readingReasonId?: number;

  /**
   * Device number
   */
  @observable
  @Expose({ name: "device_id" })
  deviceId!: string;

  @observable
  value!: number;

  @observable
  @Expose({ name: "value_nt" })
  valueNt?: number;

  @observable
  consumption?: number;

  @observable
  @Expose({ name: "consumption_nt" })
  consumptionNt?: number;

  static ValidationRules = {
    id: { required: true, number: true },
    date: { required: true, date: true },
    readingMethodId: { number: true },
    readingReasonId: { number: true },
    deviceId: { required: true },
    value: { required: true, number: true },
    valueNt: { number: true },
    consumption: { number: true },
    consumptionNt: { number: true },
  };
}
