import { observable } from "mobx";
import { Expose } from "class-transformer";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class EnumGatewayMethod {
  id!: number;

  @observable
  name!: string;

  @observable
  @Expose({ name: "image_url" })
  imageUrl?: string;

  @observable
  @Expose({ name: "external_id" })
  externalId?: string;

  static ValidationRules = {
    id: { required: true, number: true },
    name: { required: true },
  };
}
