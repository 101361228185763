import { IEventBus, ILocalizationService } from "@emanprague/shared-services";
import type { IDisposable } from "@frui.ts/helpers";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, ConductorSingleChild, Router } from "@frui.ts/screens";
import { isDoubleTariff } from "helpers/supplyPointHelper";
import { action, observable } from "mobx";
import CommodityType from "models/commodityType";
import ReadingsRepository from "repositories/readingsRepository";
import EnumsService from "services/enumsService";
import { DataEvents } from "services/events";
import type { IDetailPage } from "viewModels/types";
import type ProductDetailViewModel from "../../productDetailViewModel";
import ChartViewModel from "./chartViewModel";
import ModalSelfReadingViewModel from "./modalSelfReadingViewModel";
import TableViewModel from "./tableViewModel";

export const singleTariffScope = "singleTariff";
export const electricityDoubleTariffScope = "electricityDoubleTariff";
export type GroupBy = "day" | "month" | "year";
export const navigationName = "readings";

@Router.registerRoute({ name: ModalSelfReadingViewModel, route: `${navigationName}/${ModalSelfReadingViewModel.navigationName}` })
export default class HistoryDetailPageViewModel extends ConductorSingleChild<ModalSelfReadingViewModel> implements IDetailPage {
  orderIndex = 7;
  navigationName = navigationName;
  className = "icon-graph";

  parent: ProductDetailViewModel;
  busyWatcher = new BusyWatcher();
  @observable chartViewModel: ChartViewModel;
  @observable tableViewModel: TableViewModel;

  private eventHandlers: IDisposable[];

  get commodityType() {
    return this.parent.productDetailContext.commodityType;
  }

  get isDoubleTariff() {
    return this.supplyPoint?.tariff && isDoubleTariff(this.supplyPoint.tariff);
  }

  get supplyPoint() {
    return this.parent.productDetailContext.supplyPoint;
  }

  get currentScope() {
    return this.commodityType === CommodityType.Electricity && this.isDoubleTariff
      ? electricityDoubleTariffScope
      : singleTariffScope;
  }

  constructor(
    public localization: ILocalizationService,
    private readingsRepository: ReadingsRepository,
    private modalSelfReadingViewModelFactory: ReturnType<typeof ModalSelfReadingViewModel.Factory>,
    private enumsService: EnumsService,
    eventBus: IEventBus
  ) {
    super();

    this.name = this.translate("title");
    this.eventHandlers = [eventBus.subscribe(DataEvents.supplyPointsUpdated, this.loadData)];
  }

  @action
  protected onInitialize() {
    this.chartViewModel = new ChartViewModel(this.parent.productDetailContext, this.readingsRepository, this.localization);
    this.tableViewModel = new TableViewModel(this.parent.productDetailContext, this.readingsRepository, this.enumsService);
    return this.loadData();
  }

  protected async onDeactivate(isClosing: boolean) {
    await super.onDeactivate(isClosing);

    if (isClosing) {
      this.eventHandlers?.forEach(x => x.dispose());
    }
  }

  @bound loadData() {
    return Promise.all([this.chartViewModel?.loadData(), this.tableViewModel?.loadData()]);
  }

  @action.bound
  addSelfReading() {
    const vm = this.modalSelfReadingViewModelFactory(this.parent.productDetailContext);
    return this.tryActivateChild(vm);
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`products.history.${key}`);
  }

  @bound translateAttribute(key: string) {
    return this.localization.translateAttribute("reading", key);
  }

  @bound translateGeneral(key: string) {
    return this.localization.translateGeneral(`general.${key}`);
  }

  protected findNavigationChild(navigationName: string | undefined) {
    if (navigationName === ModalSelfReadingViewModel.navigationName) {
      return this.modalSelfReadingViewModelFactory(this.parent.productDetailContext);
    }

    return undefined;
  }
}
