import { ILocalizationService } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import Address from "entities/address";
import RuianListItem from "entities/ruianListItem";
import SupplyPoint from "entities/supplyPoint";
import SupplyPointListItem from "entities/supplyPointListItem";
import { addressToHuman, getCzechRepublicCountryId } from "helpers/utils";
import { interfaces } from "inversify";
import uniqBy from "lodash/uniqBy";
import uniqWith from "lodash/uniqWith";
import { action, computed, observable, runInAction } from "mobx";
import PartnerType from "models/partnerType";
import RuianRepository from "repositories/ruianRepository";
import EnumsService from "services/enumsService";
import UserContext from "services/userContext";
import DataSyncService from "services/dataSyncService";

export interface AddressChangeDto {
  street?: string;
  houseNumber?: string;
  streetNumber?: string;
  city?: string;
  cityPart?: string;
  zip?: string;
  countryId?: number;
  ruianId?: number;
  addressId?: number;
}

type AddressType = "sameAsPermanent" | "selectFromOther" | "newAddress";
type DialogType = "normal" | "permanent" | "supplyPoint";

export default class AddressChangeViewModel {
  @observable currentType: AddressType;
  @observable manualAddress = false;
  @observable selectedRuian: RuianListItem;
  @observable zipCode?: string;
  @observable controller? = new AbortController();

  @computed
  get countries() {
    return this.enumsService.getValues("countries");
  }

  @computed
  get isCompany() {
    if (!this.userContext.activePartner) {
      return false;
    }
    return this.enumsService.getPartnerType(this.userContext.activePartner.partnerTypeId) === PartnerType.Business;
  }

  @computed
  get czechRepublicCountryId() {
    return getCzechRepublicCountryId(this.enumsService);
  }

  constructor(
    public currentAddress: Address,
    public target: AddressChangeDto,
    public dialogType: DialogType,
    public localization: ILocalizationService,
    private enumsService: EnumsService,
    private dataService: DataSyncService,
    private userContext: UserContext,
    private ruianRepository: RuianRepository,
    private supplyPoint?: SupplyPoint | SupplyPointListItem
  ) {
    // Default settings (also override target to enable submit button)
    if (this.permanentAddress) {
      this.target.addressId = this.permanentAddress.id;
      this.currentType = "sameAsPermanent";
    } else if (this.otherAddresses.length > 0) {
      this.currentType = "selectFromOther";
      this.target.addressId = this.otherAddresses[0].id;
    } else {
      this.currentType = "newAddress";
    }

    this.target.countryId = this.czechRepublicCountryId;
  }

  @action.bound
  toggleManualAddress() {
    this.manualAddress = !this.manualAddress;

    if (this.manualAddress) {
      this.target.ruianId = undefined;
    } else {
      this.applyRuian(this.selectedRuian);
    }
  }

  @action.bound
  onChangeType(type: AddressType) {
    this.currentType = type;

    // For all `!` in types check available types (checking is there not here)

    switch (this.currentType) {
      case "sameAsPermanent":
        this.resetAddress();
        this.target.addressId = this.permanentAddress!.id;
        break;

      case "selectFromOther":
        this.resetAddress();
        this.target.addressId = this.otherAddresses[0].id;
        break;

      case "newAddress":
        this.target.addressId = undefined;
        this.target.ruianId = undefined;
        break;
    }
  }

  @action
  resetAddress() {
    this.target.street = undefined;
    this.target.streetNumber = undefined;
    this.target.city = undefined;
    this.target.cityPart = undefined;
    this.target.zip = undefined;
    this.target.countryId = this.czechRepublicCountryId;
    this.target.ruianId = undefined;
  }

  @computed get permanentAddress() {
    return this.userContext.activePartner?.permanentAddress;
  }

  @computed get otherAddresses(): { id: number; name: string }[] {
    const others = [this.permanentAddress, ...this.dataService.postalAddresses];

    if (this.supplyPoint instanceof SupplyPoint) {
      this.supplyPoint.account.addresses?.forEach(x => others.push(x));
      others.push(this.supplyPoint.address);
    }

    // Remove undefined & duplicates with same id
    let addresses = others.filter(x => x) as Address[];
    addresses = uniqBy(addresses, "id");

    // Remove duplicates with different ids
    addresses = uniqWith(
      addresses,
      (a: Address, b: Address) =>
        a.street === b.street &&
        a.streetNumber === b.streetNumber &&
        a.houseNumber === b.houseNumber &&
        a.zip === b.zip &&
        a.city === b.city &&
        a.cityPart === b.cityPart
    );

    return addresses.map(x => ({
      id: x.id,
      name: addressToHuman(x),
    }));
  }

  get permanentDialogType() {
    return this.dialogType === "permanent";
  }

  get newAddressLabel() {
    switch (this.dialogType) {
      case "permanent":
        return "new_permanent_address";

      case "supplyPoint":
        return "new_supply_point_address";

      default:
        return "new_sending_address";
    }
  }

  get addressTypes() {
    const types: { id: AddressType; name: string }[] = [];

    if (this.permanentAddress) {
      const label = this.permanentDialogType
        ? "left_current_address"
        : this.isCompany
        ? "same_as_permanent_address_company"
        : "same_as_permanent_address";
      types.push({ id: "sameAsPermanent", name: this.translate(label) });
    }

    if (this.otherAddresses.length > 0) {
      types.push({ id: "selectFromOther", name: this.translate("choose_from_other_addresses") });
    }

    types.push({ id: "newAddress", name: this.translate("enter_new_address") });

    return types;
  }

  @bound async searchRuian(text: string) {
    if (this.controller) {
      this.controller.abort();
      runInAction(() => (this.controller = new AbortController()));
    }

    const streetResults = await this.ruianRepository.search(text, "street", 10, this.controller?.signal);
    if (!streetResults.success) {
      return [];
    }

    const addresses = streetResults.payload.addresses;

    if (addresses.length >= 10) {
      return addresses;
    }

    const detailedResults = await this.ruianRepository.search(text, undefined, 10, this.controller?.signal);
    if (detailedResults.success) {
      addresses.push(...detailedResults.payload.addresses);
      return uniqBy(addresses, x => x.id);
    }

    return addresses;
  }

  @bound applyRuian(ruian: RuianListItem | undefined) {
    this.target.street = ruian?.street ?? ruian?.cityPart ?? ruian?.city;
    this.target.streetNumber = ruian?.o !== undefined ? String(ruian?.o) : undefined;
    this.target.houseNumber = ruian?.p !== undefined ? String(ruian?.p) : undefined;
    this.target.city = ruian?.city;
    this.target.cityPart = ruian?.cityPart;
    this.target.zip = ruian ? `${ruian.zip}` : undefined;
    this.target.countryId = this.czechRepublicCountryId;
    this.target.ruianId = ruian?.id;
  }

  formatRuian(item: RuianListItem) {
    let result = `${item.zip} ${item.city}`;

    const street = item.street ?? item.cityPart ?? item.city;

    if (street) {
      const streetNumber = item.p && item.o ? `${item.p}/${item.o}` : item.p || item.o;
      result = (streetNumber ? `${street} ${streetNumber}, ` : `${street}, `) + result;
    }

    return result;
  }

  translate(key: string) {
    return this.localization.translateGeneral(`general.change_address.${key}`);
  }

  @bound translateGeneral(key: string) {
    return this.localization.translateGeneral(`general.${key}`);
  }

  static Factory({ container }: interfaces.Context) {
    return (
      currentAddress: Address,
      target: AddressChangeDto,
      supplyPoint?: SupplyPoint | SupplyPointListItem,
      type: DialogType = "normal"
    ) => {
      return new AddressChangeViewModel(
        currentAddress,
        target,
        type,
        container.get("ILocalizationService"),
        container.get(EnumsService),
        container.get(DataSyncService),
        container.get(UserContext),
        container.get(RuianRepository),
        supplyPoint
      );
    };
  }
}
