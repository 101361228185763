import { observable } from "mobx";
import { Transform } from "class-transformer";
import formatISO from "date-fns/formatISO";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class PartnerMessageListItem {
  id!: number;

  @observable
  @Transform(value => (value ? new Date(value) : undefined), { toClassOnly: true })
  @Transform(value => (value ? formatISO(value, { representation: "date" }) : undefined), { toPlainOnly: true })
  date!: Date;

  @observable
  title!: string;

  @observable
  read?: boolean;

  static ValidationRules = {
    id: { required: true, number: true },
    date: { required: true, date: true },
    title: { required: true },
  };
}
