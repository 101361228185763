import React from "react";

import { styleBlock, Card, Row, Col } from "..";

export interface BannerProps {
  title: string;
  htmlContent: string;
  link: React.ReactNode;
  className?: string;
  imageUrl?: string;
}

export default class Banner extends React.PureComponent<BannerProps> {
  render() {
    const { title, htmlContent, link, imageUrl, className } = this.props;
    const classNames = styleBlock("banner");
    const cn = classNames({}).mix(className);

    return (
      <Card className={"overflow-hidden " + cn.toString()}>
        <Card.Body className="p-0">
          <Row noGutters>
            <Col xs={12} lg={5} className="d-flex flex-wrap align-items-center">
              <span className="p-4 p-sm-5">
                <h2 className="display-2 mb-4">{title}</h2>
                <div className="mb-4 font-size-md text-gray" dangerouslySetInnerHTML={{ __html: htmlContent }} />
                {link}
              </span>
            </Col>
            <Col xs={12} lg={7} className="overflow-hidden d-flex align-items-center justify-content-end">
              <img src={imageUrl} style={{ height: "100%", maxHeight: "360px", objectFit: "cover" }} className="w-100" alt="" />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  }
}
