import { Button, Col, Form, LoaderOverlay, Modal } from "@emanprague/ppaskit";
import { Check, Input, Select } from "@frui.ts/bootstrap";
import { preventDefault, registerView, ViewComponent } from "@frui.ts/views";
import SupplyPointSelectByAccount from "controls/twoway/SupplyPointSelectByAccount";
import { observer } from "mobx-react-lite";
import React from "react";
import ModalAdvancePaymentMethodViewModel from "viewModels/products/myProducts/detailPages/optionsDeposit/modalAdvancePaymentMethodViewModel";
import { Row } from "react-bootstrap";

const modalAdvancePaymentMethodView: ViewComponent<ModalAdvancePaymentMethodViewModel> = observer(({ vm }) => {
  const ta = vm.localization.translateAttribute;

  return (
    <Modal centered show onHide={vm.requestClose}>
      <Modal.Header closeButton>
        <h2 className="display-4 m-0">{vm.name}</h2>
      </Modal.Header>
      <LoaderOverlay loading={vm.busyWatcher.isBusy}>
        <Modal.Body>
          <Form className="modal-form" onSubmit={preventDefault(vm.confirmChange)}>
            <Form.Row className="form-row-centered">
              <Form.Group as={Col} xs={12}>
                {vm.paymentMethods.map(method => (
                  <Check
                    custom
                    key={method.id}
                    target={vm.data}
                    property="advanceMethodId"
                    value={method.id}
                    type="radio"
                    label={method.name}
                    className="mb-1"
                    onValueChanged={vm.onPaymentMethodChanged}
                  />
                ))}
              </Form.Group>
              <Form.Row className="w-100">
                {(vm.selectedPaymentMethod === "zpusobPlatbyPrikazKUhrade" ||
                  vm.selectedPaymentMethod === "zpusobPlatbyPrimeInkaso") && (
                  <>
                    <Form.Group as={Col} xs={8}>
                      <Row noGutters>
                        <Form.Group as={Col} xs={5} className="bank-account-prefix">
                          <Form.Label>{ta("bank_account", "prefix")}</Form.Label>
                          <Input target={vm.data} property="advanceAccountPrefix" type="number" />
                        </Form.Group>
                        <Form.Group as={Col} xs={6}>
                          <Form.Label>{ta("bank_account", "number")}</Form.Label>
                          <Input target={vm.data} property="advanceAccount" type="number" />
                        </Form.Group>
                      </Row>
                    </Form.Group>

                    <Form.Group as={Col} xs={4}>
                      <Form.Label>{vm.translate("bank_code")}</Form.Label>
                      <Select
                        target={vm.data}
                        property="advanceAccountBankId"
                        items={vm.bankCodes}
                        mode="key"
                        keyProperty="id"
                        textProperty="value"
                      />
                    </Form.Group>
                  </>
                )}

                {vm.selectedPaymentMethod === "zpusobPlatbySIPO" && (
                  <Form.Row className="w-100">
                    <Form.Group as={Col} xs={12}>
                      <Form.Label>{vm.translate("sipo_number")}</Form.Label>
                      <Input target={vm.data} property="advanceAccount" />
                    </Form.Group>
                  </Form.Row>
                )}
              </Form.Row>

              <SupplyPointSelectByAccount
                defaultSupplyPoints={vm.currentSupplyPointsWithIcons}
                supplyPoints={vm.additionalSupplyPointsWithIcons}
                target={vm.data}
                localization={vm.localization}
                supplyPointErrors={vm.supplyPointsErrors}
                property="supplyPointIds"
              />

              <Form.Group as={Col} xs={12}>
                {vm.errorMessage && <Form.Control.Feedback type="invalid">{vm.errorMessage}</Form.Control.Feedback>}
              </Form.Group>
            </Form.Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" className="order-2" onClick={vm.confirmChange} disabled={!vm.canConfirm}>
            {vm.localization.translateGeneral("general.confirm_changes_button")}
          </Button>
          <Button onClick={vm.requestClose} variant="secondary" className="order-1">
            {vm.localization.translateGeneral("general.cancel_button")}
          </Button>
        </Modal.Footer>
      </LoaderOverlay>
    </Modal>
  );
});

registerView(modalAdvancePaymentMethodView, ModalAdvancePaymentMethodViewModel);
