import { Expose } from "class-transformer";
import { observable } from "mobx";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
class ReqNewRequest {
  /**
   * ID from enumeration
   * @example
   * Dotaz na fakturaci
   */
  @observable
  @Expose({ name: "request_type_id" })
  requestTypeId!: number;

  /**
   * @example
   * Text z pole pro zadani textu.
   */
  @observable
  text!: string;

  @observable
  @Expose({ name: "file[]" })
  file?: Blob[];

  static ValidationRules = {
    requestTypeId: { required: true, number: true },
    text: { required: true },
  };
}

export default class CustomReqNewRequest extends ReqNewRequest {
  toFormData() {
    const data = new FormData();
    data.append("request_type_id", String(this.requestTypeId));
    data.append("text", this.text);

    // generic entityToFomData is creating "[object OBJECT]" string instead of file
    data.delete("file[]");
    if (this.file && this.file.length > 0) {
      this.file.forEach(item => data.append("file[]", item));
    }

    return data;
  }
}
