import { observable } from "mobx";
import ReqCreateAdminUser from "./reqCreateAdminUser";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class ReqUpdateAdminUser extends ReqCreateAdminUser {
  @observable
  active?: boolean;

  static ValidationRules = ReqCreateAdminUser.ValidationRules;
}
