import { observable } from "mobx";
import { Transform } from "class-transformer";
import formatISO from "date-fns/formatISO";
import { Expose } from "class-transformer";
import Attachment from "./attachment";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class AttachmentListItem extends Attachment {
  @observable
  title?: string;

  /**
   * Popis souboru.
   */
  @observable
  description?: string;

  /**
   * Size of file in bytes.
   */
  @observable
  @Expose({ name: "file_size" })
  fileSize?: number;

  @observable
  @Transform(value => (value ? new Date(value) : undefined), { toClassOnly: true })
  @Transform(value => (value ? formatISO(value, { representation: "date" }) : undefined), { toPlainOnly: true })
  @Expose({ name: "created_at" })
  createdAt?: Date;

  static ValidationRules = Object.assign(
    {
      fileSize: { number: true },
      createdAt: { date: true },
    },
    Attachment.ValidationRules
  );
}
