import { Button, Col, HeaderRow, LoaderOverlay, Modal, NotificationBar, Row, SupplyPointListItem } from "@emanprague/ppaskit";
import iconInfo from "@emanprague/ppaskit/dist/assets/icons/Info.svg";
import { registerView, View, ViewComponent } from "@frui.ts/views";
import { getIconByStateType } from "helpers/financeHelper";
import { observer } from "mobx-react-lite";
import React from "react";
import AdvanceDetailViewModel from "viewModels/finance/advanceDetailViewModel";
import iconClose from "@emanprague/ppaskit/dist/assets/icons/Close White.svg";
import Tooltip from "@emanprague/basekit/dist/Tooltip";
import ReminderTooltip from "components/ReminderTooltip";

const advanceDetail: ViewComponent<AdvanceDetailViewModel> = observer(({ vm }) => {
  const ta = vm.localization.translateAttribute;
  const allowToPay = vm.advance?.allowToPay;

  const notificationBarItems = vm.advance
    ? {
        amount: (
          <Row className="align-items-center" noGutters>
            <Col md="auto" className="d-flex align-items-center">
              {vm.amount}&nbsp;{vm.translateGeneral("currency")}
            </Col>
            {vm.hasCharges && (
              <Col md="auto" className="d-flex align-items-center">
                <span className="font-size-md ml-1 font-weight-light">(+&nbsp;{vm.translateGeneral("fees").toLowerCase()})</span>
              </Col>
            )}
          </Row>
        ),
        state: (
          <span className="d-flex align-items-center">
            <img className="mr-2" width="24" height="24" draggable={false} src={getIconByStateType(vm.advance.state)} alt="" />
            <span>{vm.advance.stateText}</span>
          </span>
        ),
        accountNumber: (
          <>
            <strong>{ta("bank_account", "number")}</strong>
            <br />
            {vm.advance.accountNumber}
          </>
        ),
        vs: (
          <>
            <strong>{ta("advance", "vs")}</strong>
            <br />
            {vm.advance.vs}
          </>
        ),
      }
    : undefined;

  return (
    <>
      {!vm.activeChild && (
        <Modal centered show onHide={vm.requestClose} className="finance-detail-modal">
          <LoaderOverlay loading={vm.busyWatcher.isBusy}>
            {vm.advance && notificationBarItems && (
              <NotificationBar cancellable={false} className="mb-2 rounded-top d-block d-lg-none">
                <Row noGutters className="mt-1">
                  <Col className="mt-2">
                    <h4 className="display-4 m-0 text-white">{vm.headerTitle}</h4>
                  </Col>
                  <Col xs="auto" className="ml-auto cursor-pointer" onClick={vm.requestClose}>
                    <img width="22" height="22" draggable={false} src={iconClose} alt="" />
                  </Col>
                </Row>
                <Row>
                  <Col xs={6}>
                    <h4 className="display-4 text-white">{notificationBarItems.amount}</h4>
                  </Col>
                  <Col xs={6}>{notificationBarItems.state}</Col>
                  {allowToPay && <Col xs={6}>{notificationBarItems.accountNumber}</Col>}
                  <Col xs={6} className="mb-3">
                    {notificationBarItems.vs}
                  </Col>
                  {allowToPay && (
                    <Col xs={6} className="mb-3">
                      <Button onClick={vm.activateOnlinePayment}>{vm.translateGeneral("pay_online_button")}</Button>
                    </Col>
                  )}
                </Row>
              </NotificationBar>
            )}
            <div className="d-none d-lg-block">
              <Modal.Header closeButton>
                <HeaderRow title={vm.headerTitle} level={4} />
              </Modal.Header>
            </div>
            {vm.advance && notificationBarItems && (
              <>
                <Modal.Body>
                  <NotificationBar cancellable={false} className="rounded mb-5 d-none d-lg-block">
                    <Row className="align-items-center justify-content-between mr-1">
                      <Col>
                        <div className="mb-2 mt-2">
                          <h2 className="display-2 text-white m-1">{notificationBarItems.amount}</h2>
                          {notificationBarItems.state}
                        </div>
                      </Col>
                      <Col xs="auto" className="d-flex align-items-center justify-content-end">
                        {allowToPay && (
                          <>
                            <div className="mr-4 d-flex align-items-center">
                              <img alt="qr code" className="p-1 mx-3 bg-white rounded" width="70" height="70" src={vm.qrCode} />
                              <Tooltip id="qr-tooltip" tooltip={vm.translateGeneral("qr_payment_hint")}>
                                <img src={iconInfo} draggable={false} alt="" width={26} height={26} />
                              </Tooltip>
                            </div>
                            <div className="mr-5">{notificationBarItems.accountNumber}</div>
                          </>
                        )}
                        <div>{notificationBarItems.vs}</div>
                      </Col>
                      {allowToPay && (
                        <Col xs={6} md="auto">
                          <Button onClick={vm.activateOnlinePayment}>{vm.translateGeneral("pay_online_button")}</Button>
                        </Col>
                      )}
                    </Row>
                  </NotificationBar>
                  <Row className="mb-4">
                    <Col xs={6} lg={true}>
                      <strong>{ta("advance", "period")}</strong>
                      <p>{vm.advance.period}</p>
                    </Col>
                    <Col xs={6} lg={true}>
                      <strong>{ta("advance", "due_date")}</strong>
                      <p>{vm.localization.formatDate(vm.advance.dueDate)}</p>
                    </Col>
                    <Col xs={6} lg={true}>
                      <strong>{vm.translate("paid")}</strong>
                      <p>
                        {vm.localization.formatNumber(vm.advance.amountPaid || 0)} {vm.translateGeneral("currency")}
                      </p>
                    </Col>
                    <Col xs={6} lg={true}>
                      <strong>{vm.translate("to_pay")}</strong>
                      <p>
                        {vm.localization.formatNumber(vm.amountToPay)} {vm.translateGeneral("currency")}
                      </p>
                    </Col>
                    <Col xs={6} lg={true}>
                      <strong>{vm.translate("advances_frequency")}</strong>
                      <p>{vm.enumsService.getValue("advanceFrequencies", vm.advance.advanceFrequencyId)?.name}</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} lg={6}>
                      <h4 className="display-4 m-0 mb-3">
                        {vm.supplyPointsTitle}{" "}
                        {!vm.isSingleSupplyPoint && <span className="font-weight-normal">({vm.supplyPoints.length})</span>}
                      </h4>
                      <div className="supply-points-list-container">
                        {vm.supplyPoints.map(x => (
                          <SupplyPointListItem key={x.id} data={x} className="mb-1" />
                        ))}
                      </div>
                    </Col>
                    {vm.areRemindersVisible && (
                      <Col xs={12} lg={6} className="mt-4 mt-md-0">
                        <h4 className="display-4 m-0 mb-3">{vm.translate("to_download")}</h4>
                        <table className="table wider-row last-column-right mt-n2">
                          <tbody>
                            {vm.advance.reminders.map(item => (
                              <tr key={`reminders-${item.id}`}>
                                <th>
                                  <>
                                    {item.name}
                                    &nbsp;
                                    <ReminderTooltip reminder={item} />
                                  </>
                                </th>
                                <td>
                                  <a
                                    className="text-indigo font-weight-medium"
                                    href={item.file}
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    {vm.translateGeneral("download_button")}
                                  </a>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </Col>
                    )}
                  </Row>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={() => vm.activateAdvanceChange(vm.partnerId, vm.firstSupplyPointId)}>
                    {vm.translate("button_change_amount")}
                  </Button>
                </Modal.Footer>
              </>
            )}
          </LoaderOverlay>
        </Modal>
      )}
      <View vm={vm.activeChild} />
    </>
  );
});

registerView(advanceDetail, AdvanceDetailViewModel);
