import { observable } from "mobx";
import { Type } from "class-transformer";
import RuianListItem from "./ruianListItem";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class RuianList {
  @observable
  @Type(() => RuianListItem)
  addresses!: RuianListItem[];

  @observable
  total!: number;

  @observable
  offset!: number;

  @observable
  limit!: number;

  static ValidationRules = {
    addresses: { required: true },
    total: { required: true, number: true },
    offset: { required: true, number: true },
    limit: { required: true, number: true },
  };
}
