import PartnersRepository from "repositories/partnersRepository";
import { bound } from "@frui.ts/helpers";
import { observable, runInAction } from "mobx";
import BannerPreview from "entities/bannerPreview";
import UserContext from "./userContext";
import BannersFilter from "models/bannersFilter";

const REFRESH_INTERVAL = 60000;

export default class BannersService {
  private largeBanners: BannerPreview[] = [];
  private smallBanners: BannerPreview[] = [];

  @observable.ref activeCrossSellBanner: BannerPreview;
  @observable.ref activeLargeBanner: BannerPreview;
  @observable.ref activeSmallBanners: BannerPreview[] = [];

  constructor(private userContext: UserContext, private partnersRepository: PartnersRepository) {}

  @bound
  async initialize() {
    if (!this.userContext.activePartnerId) {
      return;
    }

    const partnerId = this.userContext.activePartnerId;

    const crossSellBannersFilter = new BannersFilter();
    crossSellBannersFilter.type = "cross_sell";

    const largeBannersFilter = new BannersFilter();
    largeBannersFilter.type = "large";

    const smallBannersFilter = new BannersFilter();
    smallBannersFilter.type = "small";

    const [responseCrossSellBanners, responseLargeBanners, responseSmallBanners] = await Promise.all([
      this.partnersRepository.getBanners(partnerId, crossSellBannersFilter),
      this.partnersRepository.getBanners(partnerId, largeBannersFilter),
      this.partnersRepository.getBanners(partnerId, smallBannersFilter),
    ]);

    if (responseCrossSellBanners.success && responseCrossSellBanners.payload.banners.length > 0) {
      runInAction(() => (this.activeCrossSellBanner = responseCrossSellBanners.payload.banners[0]));
    }

    if (responseLargeBanners.success && responseLargeBanners.payload.banners.length > 0) {
      this.largeBanners = responseLargeBanners.payload.banners;
      this.setPeriodicRefreshLarge();
    }

    if (responseSmallBanners.success && responseSmallBanners.payload.banners.length > 0) {
      this.smallBanners = responseSmallBanners.payload.banners;
      this.setPeriodicRefreshSmall();
    }
  }

  @bound
  setPeriodicRefreshLarge(index = 0) {
    if (this.largeBanners.length === 1) {
      runInAction(() => (this.activeLargeBanner = this.largeBanners[0]));
      return;
    }
    runInAction(() => (this.activeLargeBanner = this.largeBanners[index]));
    setTimeout(() => this.setPeriodicRefreshLarge((index + 1) % this.largeBanners.length), REFRESH_INTERVAL);
  }

  @bound
  setPeriodicRefreshSmall(index = 0) {
    if (this.smallBanners.length <= 2) {
      runInAction(() => {
        this.activeSmallBanners =
          this.smallBanners.length === 2 ? [this.smallBanners[0], this.smallBanners[1]] : [this.smallBanners[0]];
      });
      return;
    }
    runInAction(
      () => (this.activeSmallBanners = [this.smallBanners[index], this.smallBanners[(index + 1) % this.smallBanners.length]])
    );
    setTimeout(() => this.setPeriodicRefreshSmall((index + 2) % this.smallBanners.length), REFRESH_INTERVAL);
  }
}
