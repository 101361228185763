import React from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import iconDropdownUpGray from "../assets/icons/Arrow Simple/Up.svg";
import iconDropdownDownGray from "../assets/icons/Arrow Simple/Down.svg";

import { styleBlock, Button } from "../";
import { Collapse } from "react-bootstrap";

interface ProductRowProps {
  id: string;
  actions?: React.ReactNode;
  className?: string;
  title: React.ReactNode | string;
  titleSuffix?: React.ReactNode | string;
  titleDisabled?: boolean;
  icon?: string;
  collapsible?: boolean;
}
interface ProductRowState {
  open: boolean;
}

export default class ProductRow extends React.Component<ProductRowProps, ProductRowState> {
  constructor(props: ProductRowProps) {
    super(props);
    const initialCollapseState = localStorage.getItem("product-row-" + props.id);
    this.state = { open: initialCollapseState !== null ? initialCollapseState === "true" : true };
  }

  toggleCollapse() {
    const newState = !this.state.open;
    this.setState({ open: newState });
    localStorage.setItem("product-row-" + this.props.id, newState.toString());
  }

  render() {
    const { actions, title, titleSuffix, titleDisabled, icon, collapsible, className, children } = this.props;

    const classNames = styleBlock("productrow");
    const cn = classNames({}).mix([className]);

    return (
      <Row noGutters className={"align-items-center " + cn.toString()}>
        <Col className={"d-flex align-items-center" + (titleDisabled ? " title-disabled" : "")}>
          {icon && <img className="d-inline-block pr-2 pl-2 mt-n3 mr-1 content-size" width="56" height="56" src={icon} alt="" />}
          <h1 className="display-1 d-inline-block mb-0 font-weight-normal">
            {title}
            {titleSuffix && (
              <>
                <span className="d-none d-sm-inline"> - </span> <br className="d-inline d-sm-none" />
                {titleSuffix}
              </>
            )}
          </h1>
        </Col>
        <Col xs="auto" className="pr-3 pr-sm-2 ml-auto">
          {actions}
          {collapsible && (
            <Button onClick={() => this.toggleCollapse()} variant="link" className="ml-0 mt-n2 pr-0 pr-sm-2">
              <img width="16" height="16" src={this.state.open ? iconDropdownUpGray : iconDropdownDownGray} alt="" />
            </Button>
          )}
        </Col>
        {collapsible ? (
          <Collapse in={this.state.open}>
            <Col xs={12}>{children}</Col>
          </Collapse>
        ) : (
          children
        )}
      </Row>
    );
  }
}
