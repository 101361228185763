import { PagedQueryResult } from "@frui.ts/data";
import { FilteredListViewModel } from "@frui.ts/datascreens";
import type { ScreenBase } from "@frui.ts/screens";
import debounce from "lodash/debounce";
import { action, computed, observable } from "mobx";

export default abstract class ContinuousListViewModelBase<
  TEntity,
  TFilter extends {} = {},
  TDetail extends ScreenBase = ScreenBase
> extends FilteredListViewModel<TEntity, TFilter, TDetail> {
  // selected items

  @observable selectedItems = new Set<TEntity>();

  @computed get isAllSelected() {
    if (!this.selectedItems.size) {
      return false;
    }

    if (this.selectedItems.size === this.items.length) {
      return true;
    }

    return null;
  }
  set isAllSelected(value) {
    if (value) {
      this.items.forEach(x => this.selectedItems.add(x));
    } else {
      this.selectedItems.clear();
    }
  }

  // list
  applyFilterAndLoadDebounced = debounce(this.applyFilterAndLoad, 300);

  get canLoadData() {
    return this.currentPaging && this.items.length < this.currentPaging.totalItems;
  }

  @action
  protected setData([items, paging]: PagedQueryResult<TEntity>) {
    if (!this.items?.length || paging.offset === 0) {
      this.items = items;
    } else {
      this.items = this.items.slice(0, paging.offset).concat(items.slice()); // `items.slice` is required because `items` might be mobx observable array
    }

    this.currentPaging = {
      offset: 0,
      limit: this.items.length,
      totalItems: paging.totalItems,
    };

    // prepare for next load
    this.pagingFilter.offset = this.items.length;
  }
}
