import { ILocalizationService } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, ScreenBase, watchBusy } from "@frui.ts/screens";
import { attachAutomaticValidator, hasVisibleErrors, validate } from "@frui.ts/validation";
import UpdateLoginEmail, { ExtendUpdateLoginEmail, UpdateLoginEmailValidationRules } from "entities/updateLoginEmail";
import merge from "lodash/merge";
import { action, observable, runInAction } from "mobx";
import { unwrapErrorMessage } from "repositories/helpers";
import LoginRepository from "repositories/loginRepository";
import UserContext from "services/userContext";
import { EntityValidationRules } from "services/validation/entityValidationRules";

type StepName = "step1" | "step2";

export default class UpdateEmailViewModel extends ScreenBase {
  busyWatcher = new BusyWatcher();

  @observable currentStep: StepName = "step1";
  @observable data: ExtendUpdateLoginEmail;
  dataAPI: UpdateLoginEmail;
  @observable errorMessage?: string;
  @observable successMessage?: string;
  steps: Array<string> = [];

  constructor(
    public localization: ILocalizationService,
    private loginRepository: LoginRepository,
    private userContext: UserContext
  ) {
    super();
    this.dataAPI = new UpdateLoginEmail();
    this.data = new ExtendUpdateLoginEmail();
    this.name = this.translate("title");

    const validationRules = merge(
      {
        emailCurrent: {
          equals: { parameter: this.userContext.user?.email, translationCode: "profile.registration.invalid_email" },
        },
      } as EntityValidationRules<UpdateLoginEmail>,
      UpdateLoginEmailValidationRules
    );
    attachAutomaticValidator(this.data, validationRules);
    this.data.emailCurrent = this.userContext.user?.email;
  }

  get canConfirm() {
    return !hasVisibleErrors(this.data);
  }

  @action.bound
  @watchBusy
  async changeEmail() {
    this.errorMessage = undefined;

    if (validate(this.data)) {
      this.dataAPI.email = this.data.email;
      const response = await this.loginRepository.updateEmail(this.dataAPI);
      runInAction(() => {
        if (response.success) {
          this.successMessage = this.translate("message_sent").replace("%email%", this.data.emailCurrent ?? "");
          this.currentStep = "step2";
        } else {
          this.errorMessage = unwrapErrorMessage(response.payload);
        }
      });
    }
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`profile.registration.${key}`);
  }
}
