import { FormControlProps, InputPassword, InputPasswordProps } from "@emanprague/ppaskit";
import { ValidationControlBase } from "@frui.ts/bootstrap";
import { InputProps } from "@frui.ts/bootstrap/dist/controls/input";
import { bound } from "@frui.ts/helpers";
import React from "react";

export default class PasswordInput<TTarget, TOtherProps = unknown> extends ValidationControlBase<
  TTarget,
  InputProps & InputPasswordProps & FormControlProps & TOtherProps
> {
  @bound
  protected renderInner() {
    const { noValidation, errorMessage, feedbackInvalid, ...otherProps } = this.inheritedProps;
    const validationError = this.getValidationError();

    return (
      <InputPassword
        {...otherProps}
        value={this.value ?? ""}
        onChange={this.handleValueChanged}
        feedbackInvalid={validationError || feedbackInvalid}
      />
    );
  }

  @bound
  protected handleValueChanged(e: React.FormEvent<any>) {
    const target = e.target as HTMLInputElement;
    this.setValue(target.value);
  }
}
