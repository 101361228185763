import React from "react";
import { Nav } from "react-bootstrap";
import { default as Navbar, NavbarProps as NavbarBootstrapProps } from "react-bootstrap/Navbar";
import { default as Container, ContainerProps as ContainerBootstrapProps } from "react-bootstrap/Container";
import { ReplaceProps } from "react-bootstrap/helpers";
import { styleBlock } from "../";

interface HeaderBaseProps extends NavbarBootstrapProps {
  className?: string;
  brand?: React.ReactNode;
  brandHref?: string;
  brandOnClick?: () => void;
  menu?: React.ReactNode;
  notifications?: React.ReactNode;
  usermenu?: React.ReactNode;
  containerWrap?: boolean;
  containerFluid?: ContainerBootstrapProps["fluid"];
}

export type HeaderProps = ReplaceProps<"nav", HeaderBaseProps>;

export default class Header extends React.PureComponent<HeaderProps> {
  render() {
    const {
      ref,
      className,
      brand,
      brandHref,
      brandOnClick,
      menu,
      notifications,
      usermenu,
      containerWrap,
      containerFluid,
      ...props
    } = this.props;
    const b = styleBlock("header");

    let headerContent = (
      <>
        {brand && (
          <Navbar.Brand
            href={brandHref ? brandHref : undefined}
            onClick={brandOnClick}
            className={b("brand").toString() + " order-first"}>
            {brand}
          </Navbar.Brand>
        )}
        <Navbar.Toggle />
        <Navbar.Collapse className="flex-grow-0 ml-auto">
          <Nav>{menu}</Nav>
        </Navbar.Collapse>
        <div className="notification-placeholder ml-auto ml-lg-0 order-first order-lg-0">{notifications}</div>
        <Navbar.Collapse className="flex-grow-0">
          <Nav>{usermenu}</Nav>
        </Navbar.Collapse>
      </>
    );

    let wrapClass;
    if (containerWrap) {
      headerContent = <Container fluid={containerFluid}>{headerContent}</Container>;
      wrapClass = containerFluid && props.expand && typeof props.expand === "string" ? `px-${props.expand}-0` : undefined;
    }

    return (
      <>
        <Navbar collapseOnSelect className={b({}).mix(wrapClass).mix(className).toString()} as="header" {...props}>
          {headerContent}
        </Navbar>
      </>
    );
  }
}
