import { observable } from "mobx";
import { Transform } from "class-transformer";
import formatISO from "date-fns/formatISO";
import { Expose } from "class-transformer";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class MessageListItem {
  id!: number;

  @observable
  title!: string;

  @observable
  @Transform(value => (value ? new Date(value) : undefined), { toClassOnly: true })
  @Transform(value => (value ? formatISO(value, { representation: "date" }) : undefined), { toPlainOnly: true })
  @Expose({ name: "valid_from" })
  validFrom?: Date;

  @observable
  @Transform(value => (value ? new Date(value) : undefined), { toClassOnly: true })
  @Transform(value => (value ? formatISO(value, { representation: "date" }) : undefined), { toPlainOnly: true })
  @Expose({ name: "valid_to" })
  validTo?: Date;

  static ValidationRules = {
    id: { required: true, number: true },
    title: { required: true },
    validFrom: { date: true },
    validTo: { date: true },
  };
}
