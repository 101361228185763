import { ILocalizationService } from "@emanprague/shared-services";
import { BusyWatcher, ConductorSingleChild, Router } from "@frui.ts/screens";
import type { IModule } from "viewModels/types";
import CustomerAccountViewModel from "./customerAccountViewModel";
import DashboardPageViewModel from "./dashboardViewModel";
import PartnerConnectViewModel from "./partnerConnectViewModel";
import AttachmentsViewModel from "./attachments/attachmentsViewModel";

const navigationName = "dashboard";
type children = DashboardPageViewModel | CustomerAccountViewModel | PartnerConnectViewModel | AttachmentsViewModel;

@Router.registerRoute({
  route: navigationName,
  children: [CustomerAccountViewModel, PartnerConnectViewModel, AttachmentsViewModel],
})
export default class OverviewViewModel extends ConductorSingleChild<children> implements IModule {
  static navigationName = navigationName;

  orderIndex = 1;
  navigationName = navigationName;
  busyWatcher = new BusyWatcher();

  constructor(
    private localization: ILocalizationService,
    private dashboardPageViewModel: DashboardPageViewModel,
    private customerAccountViewModel: CustomerAccountViewModel,
    private partnerConnectViewModel: PartnerConnectViewModel,
    private attachmentsViewModel: AttachmentsViewModel
  ) {
    super();
    this.name = localization.translateGeneral("dashboard.title");
  }

  protected onInitialize() {
    if (!this.activeChild) {
      return this.tryActivateChild(this.dashboardPageViewModel);
    }
  }

  protected findNavigationChild(navigationName: string | undefined) {
    if (navigationName === this.customerAccountViewModel.navigationName) {
      return this.customerAccountViewModel;
    }
    if (navigationName === this.partnerConnectViewModel.navigationName) {
      return this.partnerConnectViewModel;
    }
    if (navigationName === this.attachmentsViewModel.navigationName) {
      return this.attachmentsViewModel;
    }

    return this.dashboardPageViewModel;
  }
}
