import { Button, Card, CardGroup, Col, Form, LoaderOverlay, Modal, PasswordIndicator } from "@emanprague/ppaskit";
import Stepper from "@emanprague/ppaskit/dist/Stepper";
import { preventDefault, registerView, View, ViewComponent } from "@frui.ts/views";
import PreAuthHeader from "components/PreAuthHeader";
import DateInput from "controls/twoway/DateInput";
import PasswordInput from "controls/twoway/PasswordInput";
import PhoneInput from "controls/twoway/PhoneInput";
import { observer } from "mobx-react-lite";
import React from "react";
import ConfirmRegistrationViewModel from "viewModels/profile/confirmRegistrationViewModel";

const confirmRegistrationListView: ViewComponent<ConfirmRegistrationViewModel> = observer(({ vm }) => (
  <>
    <View vm={vm} context={vm.currentStep} />
  </>
));

registerView(confirmRegistrationListView, ConfirmRegistrationViewModel);

const step3: ViewComponent<ConfirmRegistrationViewModel> = observer(({ vm }) => {
  const ta = vm.localization.translateAttribute;

  return (
    <>
      <PreAuthHeader translateFunc={vm.localization.translateGeneral} title={vm.name} />
      <Stepper step={3} steps={vm.steps} />
      {vm.isTokenValid ? (
        <CardGroup className="card-single">
          <Card className="border-right-sm z-index-1">
            <LoaderOverlay loading={vm.busyWatcher.isBusy}>
              <Card.Body>
                <Form onSubmit={preventDefault(vm.confirm)}>
                  <Form.Row>
                    <Form.Group as={Col}>
                      <Form.Label>{ta("user", "password")}</Form.Label>
                      <PasswordInput target={vm.dataStep3} property="password" autoFocus />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col}>
                      <Form.Label>{vm.translate("password_confirmation")}</Form.Label>
                      <PasswordInput target={vm.dataStep3} property="passwordConfirmation" />
                    </Form.Group>
                    <Form.Group as={Col} xs={12}>
                      <PasswordIndicator
                        label={vm.translate("password_complexity.level_" + vm.passwordComplexityValue.toString())}
                        value={vm.passwordComplexityValue}
                        tooltip={
                          <div
                            className="font-size-base font-weight-bold"
                            dangerouslySetInnerHTML={{ __html: vm.translate("password_complexity_tooltip") }}
                          />
                        }
                        className="mb-3"
                      />
                    </Form.Group>
                  </Form.Row>
                  {vm.errorMessageStep3 && <Form.Control.Feedback type="invalid">{vm.errorMessageStep3}</Form.Control.Feedback>}
                  <Button disabled={!vm.canConfirm} type="submit" block={true}>
                    {vm.localization.translateGeneral("general.continue_button")}
                  </Button>
                </Form>
              </Card.Body>
            </LoaderOverlay>
          </Card>
        </CardGroup>
      ) : (
        <p className="font-size-md text-center text-gray mb-4 pb-3">Odkaz již není platný.</p>
      )}
    </>
  );
});

registerView(step3, ConfirmRegistrationViewModel, "step3");

// eslint-disable-next-line @typescript-eslint/no-empty-function
function doNothing() {}

const step4: ViewComponent<ConfirmRegistrationViewModel> = observer(({ vm }) => {
  return (
    <>
      <Modal centered show onHide={doNothing}>
        <LoaderOverlay loading={vm.busyWatcher.isBusy}>
          <Modal.Body>
            <h2 className="display-2 text-success text-center mt-4 mb-3">{vm.translate("successfull_registration")}</h2>
            <p className="font-size-md text-center text-gray mb-4 pb-3">{vm.translate("check_data")}</p>
            <Form className="w-100 w-sm-75 mx-auto mb-n3" onSubmit={preventDefault(vm.finalConfirm)}>
              <Form.Row>
                {vm.isPartnerHouseHold && (
                  <Form.Group as={Col} xs={12}>
                    <Form.Label>{vm.localization.translateAttribute("user", "birth_date")}</Form.Label>
                    <DateInput target={vm.dataStep4} property="birthDate" autoFocus />
                  </Form.Group>
                )}

                <Form.Group as={Col} xs={12}>
                  <Form.Label>{vm.localization.translateAttribute("user", "phone")}</Form.Label>
                  <PhoneInput target={vm.dataStep4} property="phone" />
                </Form.Group>
              </Form.Row>
              {vm.errorMessageStep4 && <Form.Control.Feedback type="invalid">{vm.errorMessageStep4}</Form.Control.Feedback>}
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button disabled={!vm.canFinalConfirm} variant="primary" onClick={vm.finalConfirm}>
              {vm.translate("button_to_portal")}
            </Button>
          </Modal.Footer>
        </LoaderOverlay>
      </Modal>
    </>
  );
});

registerView(step4, ConfirmRegistrationViewModel, "step4");
