import RawSearchbox, { SearchboxProps as RawSearchboxProps } from "@emanprague/ppaskit/dist/Searchbox";
import { ValidationControlBase } from "@frui.ts/bootstrap";
import { bound } from "@frui.ts/helpers";
import React from "react";

type ItemType = string | object;

export interface SearchboxProps<TItem extends ItemType> extends Omit<RawSearchboxProps<TItem>, "labelKey"> {
  keyProperty?: keyof TItem & string;
  textProperty?: RawSearchboxProps<TItem>["labelKey"];
  mode?: "key" | "item";
  isNumeric?: boolean;
  onBlurAutocomplete?: (item: TItem, text: string) => boolean;
}

export default class Searchbox<TTarget, TItem extends ItemType> extends ValidationControlBase<TTarget, SearchboxProps<TItem>> {
  static defaultProps: Partial<SearchboxProps<any>> = {
    keyProperty: "id",
    textProperty: "label",
    mode: "item",
  };

  @bound
  renderInner() {
    const validationError = this.getValidationError();

    return (
      <div>
        <RawSearchbox
          {...this.inheritedProps}
          onSearch={this.props.onSearch}
          labelKey={this.props.textProperty}
          selected={this.selectedValues}
          onChange={this.handleValueChanged}
          isInvalid={!!validationError}
          onBlur={this.props.onBlurAutocomplete && this.onBlur}
        />
        {validationError && <div className="invalid-feedback">{validationError}</div>}
      </div>
    );
  }

  get selectedValues() {
    const { multiple, mode } = this.props;

    const selectedArray = multiple ? (this.value as any[]) ?? [] : this.value ? [this.value] : [];

    if (mode !== "item") {
      throw new Error("Key mode is not implemented. Provide a way to load item by key."); // TODO
    }

    return selectedArray;
  }

  @bound
  protected handleValueChanged(values: TItem[]) {
    const { multiple, mode, keyProperty, target, property } = this.props;
    const selectedValues = mode === "item" ? values : values.map((x: any) => x[keyProperty]);
    const value = multiple ? selectedValues : selectedValues[0];

    if (target && property) {
      this.setValue(value);
    } else {
      this.props.onValueChanged?.(value, property as any, target as any);
    }
  }

  @bound
  protected onBlur(event: Event) {
    const { onBlurAutocomplete } = this.props;

    if (onBlurAutocomplete) {
      const text = (event.target as any)?.value;

      this.props.onSearch(text).then(stations => {
        const found = stations.find(item => onBlurAutocomplete(item, text));
        found && this.handleValueChanged([found]);
      });
    }
  }
}
