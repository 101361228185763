import { Button, Card, Col, HeaderRow, LoaderOverlay, Row, SwitchNav } from "@emanprague/ppaskit";
import iconDownloadBlue from "@emanprague/ppaskit/dist/assets/icons/Download Blue.svg";
import { Input } from "@frui.ts/bootstrap";
import { ResponsiveTable } from "@frui.ts/dataviews";
import { preventDefault, registerView, View, ViewComponent } from "@frui.ts/views";
import EmptyDataMessage from "components/EmptyDataMessage";
import FilterButton from "controls/filterButton";
import { createColumnsContext, DataColumn } from "controls/tableHelper";
import DatePicker from "controls/twoway/DatePicker";
import RangeInput from "controls/twoway/RangeInput";
import PaymentListPaymentsItem from "entities/paymentListPaymentsItem";
import { observer } from "mobx-react-lite";
import PaymentState from "models/paymentState";
import React from "react";
import PaymentsPageViewModel, { defaultAmountRange } from "viewModels/finance/paymentsPageViewModel";
import PaymentsDetailPageViewModel from "viewModels/products/myProducts/detailPages/paymentsDetailPageViewModel";
import PaymentItemsTooltip from "components/PaymentItemsTooltip";

const columns: DataColumn<PaymentListPaymentsItem, PaymentsPageViewModel>[] = [
  {
    property: "type",
    titleFactory: ({ vm, ta }) => (
      <>
        {ta("payment", "type")}
        <FilterButton
          id="typeFilter"
          isActive={!!vm.filter.type}
          clearLabel={vm.translateGeneral("cancel_button")}
          confirmLabel={vm.translateGeneral("confirm")}
          onClear={() => vm.clearFilter("type")}
          onConfirm={vm.applyFilterAndLoad}>
          <Input target={vm.filter} property="type" placeholder={vm.translateGeneral("search")} />
        </FilterButton>
      </>
    ),
    responsiveTitleFactory: ({ ta }) => ta("payment", "type"),
  },
  {
    property: "vs",
    titleFactory: ({ vm, ta }) => (
      <>
        {ta("payment", "vs")}
        <FilterButton
          id="vsFilter"
          isActive={!!vm.filter.vs}
          clearLabel={vm.translateGeneral("cancel_button")}
          confirmLabel={vm.translateGeneral("confirm")}
          onClear={() => vm.clearFilter("vs")}
          onConfirm={vm.applyFilterAndLoad}>
          <Input target={vm.filter} property="vs" placeholder={vm.translateGeneral("search")} />
        </FilterButton>
      </>
    ),
    responsiveTitleFactory: ({ ta }) => ta("payment", "vs"),
  },
  {
    property: "date",
    titleFactory: ({ vm, ta }) => (
      <>
        {ta("payment", "paid_date")}
        <FilterButton
          id="dueDateFilter"
          isActive={!!vm.filter.dateFrom || !!vm.filter.dateTo}
          clearLabel={vm.translateGeneral("cancel_button")}
          confirmLabel={vm.translateGeneral("confirm")}
          onClear={() => vm.clearFilter("dateFrom", "dateTo")}
          onConfirm={vm.applyFilterAndLoad}>
          <Row className="align-items-center">
            <Col className="mr-n3">
              <span className="date-filter">{vm.translateGeneral("filter_date_from")}</span>
              <DatePicker
                target={vm.filter}
                property="dateFrom"
                selectsStart
                startDate={vm.filter.dateFrom}
                endDate={vm.filter.dateTo}
                maxDate={vm.filter.dateTo}
              />
            </Col>
            <Col>
              <span className="date-filter to">{vm.translateGeneral("filter_date_to")}</span>
              <DatePicker
                target={vm.filter}
                property="dateTo"
                selectsEnd
                startDate={vm.filter.dateFrom}
                endDate={vm.filter.dateTo}
                minDate={vm.filter.dateFrom}
              />
            </Col>
          </Row>
        </FilterButton>
      </>
    ),
    responsiveTitleFactory: ({ ta }) => ta("payment", "paid_date"),
    valueFormatter: ({ value, context }) => context.vm.localization.formatDate(value),
  },
  {
    property: "amount",
    titleFactory: ({ vm, ta }) => (
      <>
        {ta("payment", "amount")}
        <FilterButton
          id="amountFilter"
          isActive={!!vm.filter.minAmount || !!vm.filter.maxAmount}
          clearLabel={vm.translateGeneral("cancel_button")}
          confirmLabel={vm.translateGeneral("confirm")}
          onClear={() => vm.clearFilter("minAmount", "maxAmount")}
          onConfirm={vm.applyFilterAndLoad}>
          <RangeInput
            target={vm}
            property="amountRange"
            allowUserOverride={true}
            min={defaultAmountRange[0]}
            max={defaultAmountRange[1]}
            step={100}
            disabled={false}
            autofocus
            suffix={" " + vm.translateGeneral("currency")}
            name="rangeslider"
            form="rangeform"
          />
        </FilterButton>
      </>
    ),
    responsiveTitleFactory: ({ ta }) => ta("payment", "amount"),
    valueFormatter: ({ item, value, context }) => (
      <span className="text-nowrap">
        {context.vm.localization.formatNumber(value)} {context.vm.translateGeneral("currency")}
        &nbsp;
        <PaymentItemsTooltip
          total_amount={value}
          formatNumber={context.vm.localization.formatNumber}
          translateGeneral={context.vm.translateGeneral}
          items={item.paymentItems}
        />
      </span>
    ),
    cellClassName: "cell-right",
    headerClassName: "cell-right",
  },
  {
    titleFactory: ({ vm }) => vm.translate("invoice"),
    valueFormatter: ({ item, context }) => {
      if (item.documentId || item.file) {
        return (
          <>
            {item.documentId}
            &nbsp; &nbsp;
            {item.file && (
              <Button variant="secondary" size="sm" onClick={() => context.vm.downloadDocument(item)}>
                <img src={iconDownloadBlue} width={16} height={16} alt="" />{" "}
                {context.vm.localization.translateGeneral("general.download_button")}
              </Button>
            )}
          </>
        );
      }
    },
    cellClassName: "cell-right",
  },
];

const PaymentsPageListView: ViewComponent<PaymentsPageViewModel> = observer(({ vm }) => {
  const { translateGeneral: tg } = vm.localization;

  const navLinks = [
    {
      id: "all",
      title: `${tg("general.filter_all")}`,
      href: "#",
      onClick: preventDefault(() => vm.applyPaymentStateFilter()),
    },
    {
      id: PaymentState.Paid,
      title: `${vm.translate("filter_paid")}`,
      href: "#",
      onClick: preventDefault(() => vm.applyPaymentStateFilter(PaymentState.Paid)),
    },
    {
      id: PaymentState.Returned,
      title: `${vm.translate("filter_returned")}`,
      href: "#",
      onClick: preventDefault(() => vm.applyPaymentStateFilter(PaymentState.Returned)),
    },
  ];

  return (
    <LoaderOverlay loading={vm.busyWatcher.isBusy}>
      <Row className="mb-3 justify-content-between align-items-center">
        <Col xs={12} md="auto" className="py-2">
          <SwitchNav links={navLinks} size="sm" activeId={vm.activePaymentState} />
        </Col>
        <Col />
        <Col xs={12} md={5} className="py-2">
          <Input
            size="sm"
            target={vm.filter}
            property="search"
            placeholder={vm.translate("search_placeholder")}
            onValueChanged={vm.applyFilterAndLoadDebounced}
          />
        </Col>
      </Row>
      <div className="responsive-table-wrapper">
        <ResponsiveTable
          className="table no-wrap"
          items={vm.items}
          itemKey="id"
          columns={columns}
          context={createColumnsContext(vm)}
        />
      </div>
      {!vm.items?.length && <EmptyDataMessage translateGeneral={tg} />}

      {vm.canLoadData && (
        <div className="load-more-button">
          <Button onClick={vm.loadData} variant="secondary">
            {tg("general.button_load_more")}
          </Button>
        </div>
      )}

      <View vm={vm.activeChild} />
    </LoaderOverlay>
  );
});

const paymentsDetailListView: ViewComponent<PaymentsDetailPageViewModel> = observer(({ vm }) => (
  <div className="mb-4">
    <HeaderRow level={2} title={vm.translateProductScope("title")} />
    <Card>
      <Card.Body>
        <PaymentsPageListView vm={vm} />
      </Card.Body>
    </Card>
  </div>
));

registerView(paymentsDetailListView, PaymentsDetailPageViewModel);
