import { registerView, ViewComponent } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import React from "react";
import CustomerCardPageViewModel from "viewModels/products/customerCardPageViewModel";

const customerCardPageListView: ViewComponent<CustomerCardPageViewModel> = observer(({ vm }) => (
  <div>Content for {vm.name} will be displayed here...</div>
));

registerView(customerCardPageListView, CustomerCardPageViewModel);
