import { ILocaleProvider, IResourcesContainer } from "@emanprague/shared-services";
import RepositoryBase from "repositories/repositoryBase";

export default class LocaleProvider extends RepositoryBase implements ILocaleProvider {
  getAvailableLocales() {
    return Promise.resolve(["cs"]);
  }

  async fetchResources(locale: string) {
    return await this.apiFactory().one("localization").get<IResourcesContainer>();
  }
}
