import uniqueId from "lodash/uniqueId";
import { action, observable } from "mobx";
import { INotificationService, NotificationModel, SeverityLevel } from "./contracts/notificationService";

export default class NotificationService implements INotificationService {
  @observable notifications = new Map<string, NotificationModel>();

  @action addNotification(message: string, severity: SeverityLevel = SeverityLevel.information, key?: string, timeout = 5000) {
    const id = key || uniqueId();

    const existingHandle = this.notifications.get(id)?.timeoutHandle;
    if (existingHandle) {
      clearTimeout(existingHandle);
    }

    this.notifications.set(id, {
      message,
      severity,
      created: new Date(),
      timeoutHandle: timeout ? setTimeout(this.removeNotification, timeout, id) : undefined,
    });
  }

  @action.bound removeNotification(key: string) {
    this.notifications.delete(key);
  }
}
