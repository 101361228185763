import React from "react";
import { ButtonProps as ButtonBootstrapProps } from "react-bootstrap/Button";
import { default as DropdownBootstrap, DropdownProps as DropdownBootstrapProps } from "react-bootstrap/Dropdown";
import BootstrapDropdownItem from "react-bootstrap/DropdownItem";
import { DropdownToggleProps as DropdownToggleBootstrapProps } from "react-bootstrap/DropdownToggle";
import { ReplaceProps } from "react-bootstrap/helpers";
import { styleBlock } from "../";

interface DropdownBaseProps extends DropdownBootstrapProps {
  id: DropdownToggleBootstrapProps["id"];
  title?: React.ReactNode;
  variant?: ButtonBootstrapProps["variant"];
  size?: ButtonBootstrapProps["size"];
  block?: ButtonBootstrapProps["block"];
}

export type DropdownProps = ReplaceProps<"button", DropdownBaseProps>;

export default class Dropdown extends React.PureComponent<DropdownProps> {
  static Item = BootstrapDropdownItem;

  render() {
    const { id, title, variant, size, disabled, block, className, children } = this.props;
    const classNames = styleBlock("dropdown");

    const cn = classNames({
      block: block,
    }).mix([className]);

    return (
      <DropdownBootstrap className={cn.toString()}>
        <DropdownBootstrap.Toggle
          variant={variant}
          size={size}
          disabled={disabled}
          id={id}
          className={classNames("btn")
            .mix(block ? "btn-block" : undefined)
            .toString()}>
          {title}
        </DropdownBootstrap.Toggle>
        <DropdownBootstrap.Menu renderOnMount>{children}</DropdownBootstrap.Menu>
      </DropdownBootstrap>
    );
  }
}
