import { observable } from "mobx";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class ConfirmUpdateLoginEmail {
  /**
   * @example
   * ALJLSDJFSLKDJFS
   */
  @observable
  token!: string;

  static ValidationRules = {
    token: { required: true },
  };
}
