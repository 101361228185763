import { SupplyPointLike, SupplyPointListSelect } from "@emanprague/ppaskit";
import { BindingProperty } from "@frui.ts/helpers";
import { IBindingProps, useBinding } from "@frui.ts/views";
import { action } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { ILocalizationService } from "@emanprague/shared-services";
import { Form, Col, SupplyPointListItem } from "@emanprague/ppaskit";
import { SupplyPointError } from "helpers/supplyPointHelper";

export interface SupplyPointSelectProps<TTarget, TProperty extends BindingProperty<TTarget>>
  extends IBindingProps<TTarget, TProperty, number[]> {
  className?: string;
  defaultSupplyPoints: SupplyPointLike[];
  supplyPoints: SupplyPointLike[];
  localization: ILocalizationService;
  supplyPointErrors: SupplyPointError[];
}

function SupplyPointSelectByAccount<TTarget, TProperty extends BindingProperty<TTarget>>(
  props: SupplyPointSelectProps<TTarget, TProperty>
) {
  const [value, setValue] = useBinding(props);
  const [others, setOthers] = useState<boolean>(false);

  // Generate label
  const count = value.length;
  const countKey = props.localization.getPluralForm(count);
  const label = props.localization.translateGeneral(`general.change_impact.${countKey}`, { count: count.toString() });

  // Groupped supply points by account
  const otherSupplyPointsByAccount = (id: number): number[] => {
    const accountId = props.supplyPoints.find(x => x.id === id)!.accountId;
    return props.supplyPoints.filter(x => x.accountId === accountId).map(x => x.id);
  };

  const changeOthers = (event: any) => {
    const value = event.currentTarget.checked;
    setOthers(value);

    if (!value) {
      // Reset values to default ones
      setValue(props.defaultSupplyPoints.map(i => i.id));
    }
  };

  const onToggle = action((id: number) => {
    const others = otherSupplyPointsByAccount(id);
    const currentIndex = value.indexOf(id);

    if (currentIndex >= 0) {
      // Remove all others from value
      setValue(value.filter(x => others.indexOf(x) < 0));
    } else {
      // Add all others to values
      setValue([...others, ...value]);
    }
  });

  return (
    <>
      <Form.Group as={Col} xs={12} className="mt-3 text-center">
        <Form.Label className="mb-3">
          <strong>{label}</strong>
        </Form.Label>
        <div className="supply-points-list-container">
          {props.defaultSupplyPoints.map(x => (
            <SupplyPointListItem key={x.id} data={x} className="mb-1" />
          ))}
        </div>
      </Form.Group>

      {props.supplyPoints.length > 0 && (
        <Form.Group>
          <Form.Check
            id="changeOthersCheck"
            custom
            type="checkbox"
            checked={others}
            onChange={changeOthers}
            label={props.localization.translateGeneral("general.change_for_others")}
          />
        </Form.Group>
      )}

      {props.supplyPoints.length > 0 && others && (
        <Form.Group as={Col} xs={12}>
          <SupplyPointListSelect
            className={props.className}
            search={props.supplyPoints.length > 5}
            selected={value?.slice() || []}
            onToggle={onToggle}
            supplyPoints={props.supplyPoints}
          />
        </Form.Group>
      )}
    </>
  );
}

export default observer(SupplyPointSelectByAccount as any) as typeof SupplyPointSelectByAccount;
