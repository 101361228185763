import Contract from "entities/contract";
import NewAppendixListGlobal from "entities/newAppendixListGlobal";
import { AppendixesEvents, RequestsEvents } from "services/events";
import AppendixList from "../entities/appendixList";
import NewAppendixList from "../entities/newAppendixList";
import RepositoryBase from "./repositoryBase";

export default class ContractRepository extends RepositoryBase {
  getContract(partnerId: number, supplyPointId: number) {
    return this.callApi(api => api.all(`partners/${partnerId}/supply_points/${supplyPointId}/contract`).getEntity(Contract));
  }

  getNewGlobalAppendixes(partnerId: number) {
    return this.callApi(api => api.all(`partners/${partnerId}/new_appendixes`).getEntity(NewAppendixListGlobal));
  }

  getNewAppendixes(partnerId: number, supplyPointId: number) {
    return this.callApi(api =>
      api
        .all(`partners/${partnerId}/supply_points/${supplyPointId}/contract/new_appendixes`)
        .getEntity(NewAppendixList)
        .then(response => response.appendixes)
    );
  }

  getAppendixes(partnerId: number, supplyPointId: number) {
    return this.callApi(api =>
      api
        .all(`partners/${partnerId}/supply_points/${supplyPointId}/contract/appendixes`)
        .getEntity(AppendixList)
        .then(response => response.appendixes)
    );
  }

  downloadAppendixDocuments(partnerId: number, supplyPointId: number, appendixId: number) {
    return this.callApi(api =>
      api.all(`partners/${partnerId}/supply_points/${supplyPointId}/contract/appendixes/${appendixId}/attachments`).getRaw()
    );
  }

  approveAppendix(partnerId: number, supplyPointId: number, id: number) {
    const promise = this.callApi(api =>
      api
        .all(`partners/${partnerId}/supply_points/${supplyPointId}/contract/new_appendixes/${id}/confirm`)
        .post({ agreement: true })
    );
    promise.then(() => {
      this.eventBus.publish(RequestsEvents.requestCreated(undefined));
      this.eventBus.publish(AppendixesEvents.appendixAccepted(undefined));
    });

    return promise;
  }
}
