import { observable } from "mobx";
import { Expose } from "class-transformer";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
class ReqContactData {
  @observable
  email!: string;

  @observable
  phone!: string;

  @observable
  street?: string;

  @observable
  @Expose({ name: "house_number" })
  houseNumber?: string;

  @observable
  @Expose({ name: "street_number" })
  streetNumber?: string;

  @observable
  city?: string;

  @observable
  @Expose({ name: "city_part" })
  cityPart?: string;

  @observable
  zip?: string;

  @observable
  @Expose({ name: "country_id" })
  countryId?: number;

  @observable
  @Expose({ name: "address_id" })
  addressId?: number;

  @observable
  @Expose({ name: "ruian_id" })
  ruianId?: number;

  static ValidationRules = {
    email: { required: true },
    phone: { required: true },
    countryId: { number: true },
    addressId: { number: true },
    ruianId: { number: true },
  };
}

export default class CustomReqContactData extends ReqContactData {
  static ValidationRules = {
    email: { required: true, isEmail: true },
    phone: { required: true, isPhone: true },
    houseNumber: { requiredIf: { condition: (item: ReqContactData) => !item.ruianId && !item.addressId }, number: true },
    city: { requiredIf: { condition: (item: ReqContactData) => !item.ruianId && !item.addressId } },
    zip: { requiredIf: { condition: (item: ReqContactData) => !item.ruianId && !item.addressId } },
    countryId: { requiredIf: { condition: (item: ReqContactData) => !item.ruianId && !item.addressId }, number: true },
    addressId: { number: true },
    ruianId: { number: true },
  };
}
