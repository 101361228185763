import { Expose, Transform } from "class-transformer";
import { observable } from "mobx";
import formatISO from "date-fns/formatISO";

export default class AdminMessagesFilter {
  @observable
  @Expose({ name: "filters[id]" })
  id?: number;

  @observable
  @Expose({ name: "filters[title]" })
  title?: number;

  @observable
  @Expose({ name: "filters[valid_from]" })
  @Transform(value => value && formatISO(value, { representation: "date" }))
  validFrom?: Date;

  @observable
  @Expose({ name: "filters[valid_to]" })
  @Transform(value => value && formatISO(value, { representation: "date" }))
  validTo?: Date;
}
