import { observable } from "mobx";
import { Type } from "class-transformer";
import { Expose } from "class-transformer";
import PartnerListItem from "./partnerListItem";
import InvoiceListItem from "./invoiceListItem";
import AdvanceListItem from "./advanceListItem";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class Partner extends PartnerListItem {
  /**
   * Text to show as a notice on dashboard
   */
  @observable
  @Expose({ name: "dashboard_notice" })
  dashboardNotice?: string;

  @observable
  @Type(() => InvoiceListItem)
  @Expose({ name: "banner_invoices" })
  bannerInvoices!: InvoiceListItem[];

  @observable
  @Type(() => AdvanceListItem)
  @Expose({ name: "banner_advances" })
  bannerAdvances!: AdvanceListItem[];

  @observable
  @Type(() => InvoiceListItem)
  @Expose({ name: "action_invoice" })
  actionInvoice?: InvoiceListItem;

  @observable
  @Type(() => AdvanceListItem)
  @Expose({ name: "action_advance" })
  actionAdvance?: AdvanceListItem;

  @observable
  @Expose({ name: "marketing_agreement_visible" })
  marketingAgreementVisible?: boolean;

  static ValidationRules = Object.assign(
    {
      bannerInvoices: { required: true },
      bannerAdvances: { required: true },
    },
    PartnerListItem.ValidationRules
  );
}
