import { ILocalizationService, INotificationService, SeverityLevel } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, ScreenBase, watchBusy } from "@frui.ts/screens";
import { attachAutomaticValidator, hasVisibleErrors, validate } from "@frui.ts/validation";
import UpdatePassword, { ExtendUpdatePassword, UpdatePasswordValidationRules } from "entities/updatePassword";
import { action, observable, runInAction } from "mobx";
import { unwrapErrorMessage } from "repositories/helpers";
import LoginRepository from "repositories/loginRepository";
import UserContext from "services/userContext";
import PasswordValidationService from "services/validation/passwordValidationService";

export default class UpdatePasswordViewModel extends ScreenBase {
  busyWatcher = new BusyWatcher();
  @observable data: ExtendUpdatePassword;
  dataAPI: UpdatePassword;
  @observable errorMessage?: string;

  constructor(
    public localization: ILocalizationService,
    private passwordValidation: PasswordValidationService,
    private loginRepository: LoginRepository,
    private notificationService: INotificationService,
    public userContext: UserContext
  ) {
    super();

    this.dataAPI = new UpdatePassword();
    this.data = new ExtendUpdatePassword();
    this.name = this.translate("title");
    attachAutomaticValidator(this.data, UpdatePasswordValidationRules);
  }

  onInitialize() {
    return super.onInitialize();
  }

  get isCloseBlocked() {
    return this.userContext.user?.forcePasswordChange;
  }

  get passwordComplexityValue() {
    return this.passwordValidation.getComplexityValue(this.data.password);
  }

  get canConfirm() {
    return !hasVisibleErrors(this.data);
  }

  @action.bound
  @watchBusy
  async updatePassword() {
    this.errorMessage = undefined;
    if (validate(this.data)) {
      this.dataAPI.currentPassword = this.data.currentPassword;
      this.dataAPI.password = this.data.password;
      const response = await this.loginRepository.updatePassword(this.dataAPI);
      if (response.success) {
        this.notificationService.addNotification(this.translate("success_password_change"), SeverityLevel.success);
        this.requestClose();
      } else {
        runInAction(() => (this.errorMessage = unwrapErrorMessage(response.payload)));
      }
    }
  }
  @bound translate(key: string) {
    return this.localization.translateGeneral(`profile.registration.${key}`);
  }
}
