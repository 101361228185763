import { ILocalizationService, INotificationService, SeverityLevel } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, Router, watchBusy } from "@frui.ts/screens";
import { action, computed, observable } from "mobx";
import UserContext from "services/userContext";
import ContinuousListViewModelBase from "../../continuousListViewModelBase";
import ConfirmationService from "services/confirmationService";
import AttachmentListItem from "entities/attachmentListItem";
import AttachmentsFilter from "models/attachmentsFilter";
import AttachmentsRepository from "repositories/attachmentsRepository";
import AttachmentUpdateViewModel from "./attachmentUpdateViewModel";
import AttachmentCreateViewModel from "./attachmentCreateViewModel";
import { convertKilobytesToBytes } from "helpers/utils";

const navigationName = "attachments";
export const defaultAmountRange = [0, 5000];

@Router.registerRoute({ name: Router.Self, route: navigationName })
export default class AttachmentsViewModel extends ContinuousListViewModelBase<
  AttachmentListItem,
  AttachmentsFilter,
  AttachmentCreateViewModel | AttachmentUpdateViewModel
> {
  navigationName = navigationName;
  busyWatcher = new BusyWatcher();

  @observable errorMessage?: string;
  @observable fileSizeFromKB?: number;
  @observable fileSizeToKB?: number;

  constructor(
    public localization: ILocalizationService,
    private userContext: UserContext,
    private notificationService: INotificationService,
    private attachmentsRepository: AttachmentsRepository,
    private confirmationService: ConfirmationService,
    private detailFactory: ReturnType<typeof AttachmentUpdateViewModel.Factory>,
    private createFactory: ReturnType<typeof AttachmentCreateViewModel.Factory>
  ) {
    super();

    this.name = this.translate("title");
  }

  protected onInitialize(): Promise<any> | void {
    return this.loadData();
  }

  @bound
  @watchBusy
  async loadData() {
    if (this.userContext.activePartnerId) {
      const response = await this.attachmentsRepository.getAttachments(
        this.userContext.activePartnerId,
        this.pagingFilter,
        this.filter
      );
      if (response.success) {
        this.setData(response.payload);
      }
    }
  }

  @bound
  addAttachment() {
    return this.tryActivateChild(this.createFactory(this.applyFilterAndLoadDebounced));
  }

  @action
  openDetail(attachment: AttachmentListItem) {
    return this.tryActivateChild(
      this.detailFactory(attachment, () => this.openDelete(attachment.id), this.applyFilterAndLoadDebounced)
    );
  }

  @bound
  download(attachment: AttachmentListItem) {
    if (this.userContext.activePartnerId) {
      this.attachmentsRepository.downloadAttachment(this.userContext.activePartnerId, attachment);
    }
  }

  @bound
  async openDelete(attachmentId: number) {
    const confirm = await this.confirmationService.showConfirmation(
      this.translate("delete_confirmation"),
      undefined,
      { variant: "danger", text: this.translateGeneral("delete_confirmation_button") },
      this.translateGeneral("cancel_button")
    );

    if (!confirm) {
      return;
    }

    if (this.userContext.activePartnerId) {
      const response = await this.attachmentsRepository.deleteAttachment(this.userContext.activePartnerId, attachmentId);
      if (response.success) {
        this.notificationService.addNotification(this.translateGeneral("request_sent_success"), SeverityLevel.success);
        this.applyFilterAndLoadDebounced();
      }
    }
  }

  @action.bound
  setSearchValue(value: string) {
    this.filter.search = value;
    this.applyFilterAndLoadDebounced();
  }

  @action.bound
  clearFilter(...properties: (keyof AttachmentsFilter)[]) {
    properties.forEach(property => {
      this.filter[property] = undefined;
    });
    this.applyFilterAndLoad();
  }

  @action.bound
  applyFileSizeFilter() {
    if (this.fileSizeFromKB) {
      this.filter.fileSizeFrom = convertKilobytesToBytes(this.fileSizeFromKB);
    }
    if (this.fileSizeToKB) {
      this.filter.fileSizeTo = convertKilobytesToBytes(this.fileSizeToKB);
    }
    this.applyFilterAndLoad();
  }

  @action.bound
  clearFileSizeFilter() {
    this.filter.fileSizeFrom = undefined;
    this.filter.fileSizeTo = undefined;
    this.fileSizeFromKB = undefined;
    this.fileSizeToKB = undefined;
  }

  @computed
  get amountRange() {
    return [this.fileSizeFromKB ?? defaultAmountRange[0], this.fileSizeToKB ?? defaultAmountRange[1]];
  }

  set amountRange([min, max]: [number, number]) {
    this.fileSizeFromKB = min === defaultAmountRange[0] ? undefined : min;
    this.fileSizeToKB = max === defaultAmountRange[1] ? undefined : max;
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`attachments.${key}`);
  }

  @bound translateGeneral(key: string) {
    return this.localization.translateGeneral(`general.${key}`);
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected resetFilterValues(filter: AttachmentsFilter) {}

  protected createFilter() {
    return new AttachmentsFilter();
  }

  protected findNavigationChild(navigationName: string | undefined) {
    if (navigationName === AttachmentCreateViewModel.navigationName) {
      return this.addAttachment();
    }
    return undefined;
  }
}
