import { ILocalizationService, INotificationService, SeverityLevel } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, ScreenBase, watchBusy } from "@frui.ts/screens";
import { attachAutomaticValidator, hasVisibleErrors, hideValidationErrors, validate } from "@frui.ts/validation";
import ReqChangeAdvanceRequest from "entities/reqChangeAdvanceRequest";
import { createSupplyPointsWithIcons } from "helpers/supplyPointHelper";
import { interfaces } from "inversify";
import { action, computed, observable, runInAction } from "mobx";
import EnumsService from "services/enumsService";
import ProductDetailContext from "models/productDetailContext";
import DataSyncService from "services/dataSyncService";
import SupplyPointsRepository from "repositories/supplyPointsRepository";
import SupplyPoint from "entities/supplyPoint";
import { unwrapErrorMessage } from "repositories/helpers";
import AllowedFrequencyChange from "entities/allowedFrequencyChange";
import merge from "lodash/merge";

export default class AdvanceChangeViewModel extends ScreenBase {
  navigationName = "change";
  busyWatcher = new BusyWatcher();

  @observable advanceChangeRequest: ReqChangeAdvanceRequest;
  @observable errorMessage?: string;
  @observable qrCode: string;
  @observable productDetailContext: ProductDetailContext;
  @observable statement: { isConfirmed?: boolean } = { isConfirmed: undefined };
  @observable fileSelectError?: string;
  @observable supplyPoint: SupplyPoint;

  constructor(
    private partnerId: number,
    private supplyPointId: number,
    public localization: ILocalizationService,
    private supplyPointRepository: SupplyPointsRepository,
    public enumsService: EnumsService,
    private dataService: DataSyncService,
    private notificationService: INotificationService
  ) {
    super();

    this.name = this.translate("title");

    this.advanceChangeRequest = new ReqChangeAdvanceRequest();
    attachAutomaticValidator(this.advanceChangeRequest, ReqChangeAdvanceRequest.ValidationRules);
    attachAutomaticValidator(this.statement, { isConfirmed: { required: true } });

    this.loadData();
  }

  @bound
  @watchBusy
  async loadData() {
    const payload = await this.supplyPointRepository.getSupplyPoint(this.partnerId, this.supplyPointId);

    if (payload.success) {
      runInAction(() => (this.supplyPoint = payload.payload));
    }
  }

  @action.bound
  @watchBusy
  async changeAdvances() {
    this.fileSelectError = undefined;

    validate(this.advanceChangeRequest);
    if (this.isStatementRequired) {
      validate(this.statement);
    }
    if (!this.canConfirm || !this.partnerId) {
      return;
    }

    const response = await this.supplyPointRepository.changeAdvances(
      this.partnerId,
      this.supplyPoint.id,
      this.advanceChangeRequest
    );

    if (response.success) {
      this.notificationService.addNotification(this.translateGeneral("request_sent_success"), SeverityLevel.success);
      this.requestClose();
    } else {
      runInAction(() => (this.errorMessage = unwrapErrorMessage(response.payload)));
    }
  }

  @action.bound
  onDrop(files: File[]) {
    this.advanceChangeRequest.file = files;
    this.fileSelectError = undefined;
  }

  @action.bound
  onDropRejected() {
    this.fileSelectError = this.translateGeneral("upload_invalid_files");
  }

  @bound
  refreshValidator() {
    const rules = merge({}, ReqChangeAdvanceRequest.ValidationRules, {
      amount: { range: { min: this.minimalAmount, translationCode: "validators.minAmount" } },
      advanceChangeReasonId: { required: this.isStatementRequired },
    });

    attachAutomaticValidator(this.advanceChangeRequest, rules);
  }

  @bound
  handleStatementChanged() {
    if (!this.statement.isConfirmed) {
      runInAction(() => (this.statement.isConfirmed = undefined));
    }
  }

  @bound
  handleFrequencyChanged() {
    this.refreshValidator();
  }

  @action.bound
  handleAmountChanged() {
    if (!this.statement.isConfirmed) {
      this.statement.isConfirmed = undefined;
      if (hasVisibleErrors(this.statement)) {
        hideValidationErrors(this.statement);
      }
    }

    this.refreshValidator();
  }

  get canConfirm() {
    return !hasVisibleErrors(this.advanceChangeRequest) && !hasVisibleErrors(this.statement);
  }

  get isChangePossible() {
    return this.contract.allowToChangeAdvanceFrequency;
  }

  get currentAmount() {
    return this.contract.advanceAmount ?? 0;
  }

  get currentFrequency() {
    return this.enumsService.getValue("advanceFrequencies", this.contract.advanceFrequencyId || 0)?.name;
  }

  @computed
  get currentFrequencyChangeSettings() {
    return (
      this.contract.allowedFrequencyChanges?.find(x => x.advanceFrequencyId === this.advanceChangeRequest.advanceFrequencyId) ||
      new AllowedFrequencyChange()
    );
  }

  get account() {
    return this.supplyPoint.account;
  }

  get contract() {
    return this.supplyPoint.contract;
  }

  get supplyPointsWithIcons() {
    return this.supplyPoint ? createSupplyPointsWithIcons(this.enumsService, [this.supplyPoint]) : [];
  }

  get nextPayment() {
    return this.currentFrequencyChangeSettings.nextPayment;
  }

  get minimalAmount() {
    return this.currentFrequencyChangeSettings.min ?? 0;
  }

  get statementLimit() {
    return this.currentFrequencyChangeSettings.limit ?? 0;
  }

  /***
   * Require statement, if the amount is lower than limit
   */
  get isStatementRequired() {
    return !!(
      this.advanceChangeRequest.amount &&
      this.advanceChangeRequest.amount !== 0 &&
      this.advanceChangeRequest.amount < this.statementLimit &&
      this.advanceChangeRequest.amount >= this.minimalAmount
    );
  }

  set statementIsConfirmed(data: boolean) {
    this.advanceChangeRequest.statement = data;
    this.statement.isConfirmed = data;
  }

  @computed
  get statementIsConfirmed() {
    return this.advanceChangeRequest.statement;
  }

  @computed
  get frequencies() {
    return this.enumsService
      .getValues("advanceFrequencies")
      .filter(frequency =>
        this.contract.allowedFrequencyChanges?.some(allowedChange => allowedChange.advanceFrequencyId === frequency.id)
      );
  }

  @computed
  get changeReasons() {
    return this.enumsService.getValues("advanceChangeReasons").filter(x => x.active);
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`finance.advance_detail.${key}`);
  }

  @bound translateGeneral(key: string) {
    return this.localization.translateGeneral(`general.${key}`);
  }

  static Factory({ container }: interfaces.Context) {
    return (partnerId: number, supplyPointId: number) => {
      return new AdvanceChangeViewModel(
        partnerId,
        supplyPointId,
        container.get("ILocalizationService"),
        container.get(SupplyPointsRepository),
        container.get(EnumsService),
        container.get(DataSyncService),
        container.get("INotificationService")
      );
    };
  }
}
