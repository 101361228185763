import debounce from "lodash/debounce";
import React, { useCallback, useState } from "react";
import { AsyncTypeahead, AsyncTypeaheadProps, TypeaheadModel } from "react-bootstrap-typeahead";
export { Highlighter, MenuItem } from "react-bootstrap-typeahead";

export interface SearchboxCoreProps<T> {
  onSearch: (query: string) => Promise<T[]>;
  defaultOptions?: T[];
  label?: string;
  debounce?: number;
}

export interface SearchboxProps<T extends TypeaheadModel>
  extends Omit<
      AsyncTypeaheadProps<T>,
      // eslint-disable-next-line @typescript-eslint/tslint/config
      "onSearch" | "isLoading" | "options" | "filterBy" | "caseSensitive" | "ignoreDiacritics"
    >,
    SearchboxCoreProps<T> {}

function doNotFilter() {
  return true;
}

function Searchbox<TItem extends TypeaheadModel>(props: SearchboxProps<TItem>) {
  const { onSearch, defaultOptions, label, ...restProps } = props;

  const [options, setOptions] = useState<TItem[]>();
  const [loading, setLoading] = useState(0);

  const search = useCallback(
    debounce(async (query: string) => {
      setLoading(x => x + 1);
      try {
        const newOptions = await onSearch(query);
        setOptions(newOptions);
      } finally {
        setLoading(x => x - 1);
      }
    }, props.debounce ?? 500),
    [onSearch]
  );

  return (
    <AsyncTypeahead
      {...restProps}
      onSearch={search}
      isLoading={loading > 0}
      options={options ?? defaultOptions ?? []}
      filterBy={doNotFilter}
    />
  );
}

export default Searchbox;
