import { ILocalizationService, INotificationService, SeverityLevel } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, ScreenBase, watchBusy } from "@frui.ts/screens";
import { interfaces } from "inversify";
import { action, observable, runInAction } from "mobx";
import UserContext from "services/userContext";
import ReqUpdateAttachment from "entities/reqUpdateAttachment";
import AttachmentListItem from "entities/attachmentListItem";
import AttachmentsRepository from "repositories/attachmentsRepository";
import { unwrapErrorMessage } from "repositories/helpers";

export default class AttachmentUpdateViewModel extends ScreenBase {
  static navigationName = "edit";

  navigationName = AttachmentUpdateViewModel.navigationName;
  busyWatcher = new BusyWatcher();

  @observable data: ReqUpdateAttachment;
  @observable errorMessage?: string;

  constructor(
    public attachment: AttachmentListItem,
    private deleteAttachmentCallback: () => void,
    private refreshDataCallback: () => void,
    public localization: ILocalizationService,
    private notificationService: INotificationService,
    private userContext: UserContext,
    private attachmentsRepository: AttachmentsRepository
  ) {
    super();

    this.name = this.translate("title");

    this.data = new ReqUpdateAttachment();
    this.data.title = this.attachment.title ?? undefined;
    this.data.description = this.attachment.description ?? undefined;
  }

  @bound
  @watchBusy
  async confirmChange() {
    const partnerId = this.userContext.activePartnerId;
    if (!partnerId) {
      return;
    }

    this.validate();

    const response = await this.attachmentsRepository.updateAttachment(partnerId, this.attachment.id, this.data);
    if (response.success) {
      this.notificationService.addNotification(this.translateGeneral("request_sent_success"), SeverityLevel.success);
      this.refreshDataCallback();
      this.requestClose();
    } else {
      runInAction(() => (this.errorMessage = unwrapErrorMessage(response.payload)));
    }
  }

  @action.bound
  validate() {
    if (!this.data.title) {
      this.data.title = undefined;
    }
    if (!this.data.description) {
      this.data.description = undefined;
    }
  }

  @bound
  deleteAttachment() {
    this.deleteAttachmentCallback();
    this.requestClose();
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`attachments.detail.${key}`);
  }

  @bound translateGeneral(key: string) {
    return this.localization.translateGeneral(`general.${key}`);
  }

  static Factory({ container }: interfaces.Context) {
    return (attachment: AttachmentListItem, deleteAttachmentCallback: () => void, refreshDataCallback: () => void) => {
      return new AttachmentUpdateViewModel(
        attachment,
        deleteAttachmentCallback,
        refreshDataCallback,
        container.get("ILocalizationService"),
        container.get("INotificationService"),
        container.get(UserContext),
        container.get(AttachmentsRepository)
      );
    };
  }
}
