import { IPagingInfo } from "@frui.ts/data";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, watchBusy } from "@frui.ts/screens";
import ReadingListReadingsItem from "entities/readingListReadingsItem";
import { action, observable, runInAction } from "mobx";
import ProductDetailContext from "models/productDetailContext";
import ReadingsRepository from "repositories/readingsRepository";
import EnumsService from "services/enumsService";

export default class TableViewModel {
  busyWatcher = new BusyWatcher();

  @observable readings: ReadingListReadingsItem[];

  @observable paging: IPagingInfo = {
    offset: 0,
    limit: 10,
    totalItems: 0,
  };

  constructor(
    private context: ProductDetailContext,
    private repository: ReadingsRepository,
    private enumsService: EnumsService
  ) {}

  @watchBusy
  async loadData() {
    const result = await this.repository.getReadingsList(
      this.context.partnerId,
      this.context.supplyPointId,
      this.paging.limit,
      this.paging.offset
    );

    if (result.success) {
      runInAction(() => {
        this.readings = result.payload.readings;
        this.paging.totalItems = result.payload.total ?? 0;
      });
    }
  }

  @bound
  getReadingReason(readingReasonId?: number) {
    if (readingReasonId) {
      const readingReason = this.enumsService.getValue("readingReasons", readingReasonId);
      return readingReason?.name;
    }
  }
  get canMoveNext() {
    return this.paging.totalItems > this.paging.offset + this.paging.limit;
  }

  @action.bound
  moveNext() {
    if (this.canMoveNext) {
      this.paging.offset += this.paging.limit;
      this.loadData();
    }
  }

  get canMoveBack() {
    return this.paging.offset > 0;
  }

  @action.bound
  moveBack() {
    if (this.canMoveBack) {
      this.paging.offset = Math.max(0, this.paging.offset - this.paging.limit);
      this.loadData();
    }
  }
}
