import { observable } from "mobx";
import { Expose } from "class-transformer";
import { EntityValidationRules } from "services/validation/entityValidationRules";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class UpdateLoginEmail {
  /**
   * @example
   * marek.novak@tryit.ppas.cz
   */
  @observable
  email!: string;

  static ValidationRules = {
    email: { required: true },
  };
}

export class ExtendUpdateLoginEmail extends UpdateLoginEmail {
  @observable
  @Expose({ name: "email_confirmation" })
  emailConfirmation?: string;
  @observable
  @Expose({ name: "email_current" })
  emailCurrent?: string;
}

const UpdateLoginEmailValidationRules: EntityValidationRules<ExtendUpdateLoginEmail> = {
  email: { required: true, isEmail: true },
  emailConfirmation: {
    required: true,
    equalWith: { parameter: "email", translationCode: "validators.email_confirmation_mismatch" },
  },
  emailCurrent: { required: true, isEmail: true },
};
export { UpdateLoginEmailValidationRules };
