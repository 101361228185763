const regExPatternContainsDigit = new RegExp(/[\d]/);
const regExPatternContainsUpperCase = new RegExp(/[A-Z]/);
const regExPatternContainsLowerCase = new RegExp(/[a-z]/);
const regExPatternContainsSpecialCharacter = new RegExp(/[^A-Za-z0-9]/);

// ! If you touch this, check application_dto.rb on the backend and translations.
export default class PasswordValidationService {
  PASSWORD_MIN_LENGTH = 10;

  getComplexityValue(password?: string) {
    if (!password) {
      // No password, no fun.
      return 0;
    }

    // Absolutely required rule
    if (password.length < this.PASSWORD_MIN_LENGTH) {
      return 0;
    }

    // Additional rules counting to the complexity
    const levels = [
      regExPatternContainsDigit.test(password), // Contains a digit
      regExPatternContainsUpperCase.test(password), // Contains an uppercase letter
      regExPatternContainsLowerCase.test(password), // Contains a lowercase letter
      regExPatternContainsSpecialCharacter.test(password), // Contains a special character
    ];
    const result = levels.filter(level => level).length;
    return result > 3 ? 3 : result;
  }
}
