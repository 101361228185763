import { registerView, ViewComponent, preventDefault } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import React from "react";
import { Form, Button, Modal, HeaderRow, LoaderOverlay } from "@emanprague/ppaskit";
import ModalEditMailingAddressViewModel from "viewModels/products/myProducts/detailPages/customerInfo/modalEditMailingAddressViewModel";
import AddressSelector from "controls/twoway/AddressSelector";
import SupplyPointSelectByAccount from "controls/twoway/SupplyPointSelectByAccount";
import ErrorMessage from "controls/errorMessage";

const modalEditMailingAddressView: ViewComponent<ModalEditMailingAddressViewModel> = observer(({ vm }) => (
  <>
    <Modal centered show onHide={vm.requestClose}>
      <Modal.Header closeButton>
        <HeaderRow title={vm.name} level={4} />
      </Modal.Header>
      <Modal.Body>
        <LoaderOverlay loading={vm.busyWatcher.isBusy}>
          <Form className="modal-form" onSubmit={preventDefault(vm.confirmChange)}>
            <Form.Row className="form-row-centered">
              <AddressSelector vm={vm.addressChangeVM} />

              <SupplyPointSelectByAccount
                defaultSupplyPoints={vm.currentSupplyPointsWithIcons}
                supplyPoints={vm.additionalSupplyPointsWithIcons}
                target={vm.data}
                localization={vm.localization}
                supplyPointErrors={vm.supplyPointsErrors}
                property="supplyPointIds"
              />
            </Form.Row>

            <ErrorMessage message={vm.errorMessage} />
          </Form>
        </LoaderOverlay>
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={!vm.canConfirm || vm.busyWatcher.isBusy}
          variant="primary"
          className="order-2"
          onClick={vm.confirmChange}>
          {vm.localization.translateGeneral("general.confirm_changes_button")}
        </Button>
        <Button onClick={vm.requestClose} variant="secondary" className="order-1" disabled={vm.busyWatcher.isBusy}>
          {vm.localization.translateGeneral("general.cancel_button")}
        </Button>
      </Modal.Footer>
    </Modal>
  </>
));

registerView(modalEditMailingAddressView, ModalEditMailingAddressViewModel);
