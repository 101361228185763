import { observable } from "mobx";
import { Expose } from "class-transformer";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class Attachment {
  id!: number;

  @observable
  file!: string;

  @observable
  @Expose({ name: "file_name" })
  fileName!: string;

  @observable
  description?: string;

  @observable
  order?: number;

  @observable
  @Expose({ name: "public_uri" })
  publicUri?: boolean;

  static ValidationRules = {
    id: { required: true, number: true },
    file: { required: true },
    fileName: { required: true },
    order: { number: true },
  };
}
