import { HeaderRow, LoaderOverlay, Card } from "@emanprague/ppaskit";
import type { ViewComponent } from "@frui.ts/views";
import { registerView } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import React from "react";
import GeneralSettingsPageViewModel from "viewModels/admin/generalSettingsPageViewModel";
import "./style.scss";

const generalSettingsPageView: ViewComponent<GeneralSettingsPageViewModel> = observer(({ vm }) => {
  const loginDisabled = vm.loginDisabledSetting?.active;

  const toggleLoginCard = (state?: boolean) => {
    if (state == undefined) {
      return;
    }

    const text = state ? vm.translate("login_disabled") : vm.translate("login_enabled");
    const buttonText = state ? vm.translateGeneral("enable") : vm.translateGeneral("disable");
    const style = state ? "text-danger" : "text-success";
    const buttonType = state ? "success" : "danger";

    return (
      <Card>
        <Card.Body>
          <div className="general-setting-card">
            <h1 className={style} style={{ marginBottom: "unset" }}>
              {text}
            </h1>
            <button className={`btn btn-${buttonType}`} onClick={vm.toggleLoginSetting}>
              {buttonText}
            </button>
          </div>
        </Card.Body>
      </Card>
    );
  };

  return (
    <>
      <HeaderRow level={1} title={vm.name} className="mx-1 mx-sm-n3" />
      <LoaderOverlay loading={vm.busyWatcher.isBusy}>
        <Card>
          <Card.Body>
            <div className="general-setting-card">
              {vm.lastUpdatedAtText()}
              <button className="btn btn-primary" onClick={vm.refreshEnumerations}>
                {vm.translate("refresh_enums")}
              </button>
            </div>
          </Card.Body>
        </Card>
        {toggleLoginCard(loginDisabled)}
      </LoaderOverlay>
    </>
  );
});

registerView(generalSettingsPageView, GeneralSettingsPageViewModel);
