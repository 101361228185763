export interface DefaultValidatorParams {
  /** Error message to display */
  message?: string;

  /** Translation code for the error message */
  translationCode?: string;
}

export function hasDefaultValidatorParams(item: any): item is DefaultValidatorParams {
  return item?.message || item?.translationCode;
}

/**
 * Enables shorthand rule definition such as `minLength: 5` instead of `minLength: { parameter: 5 }`
 * while supporting default validator params such as `message` and `translationCode`
 */
export type ShorthandValidatorParams<T> = T | (DefaultValidatorParams & { parameter: T });

export interface EntityCondition<TEntity> {
  condition: (entity: TEntity) => boolean;
}

export function hasCondition(item: any): item is EntityCondition<any> {
  return item?.condition && typeof item.condition === "function";
}

export interface ValidationRules<TEntity = any> {
  required: boolean | DefaultValidatorParams;
  requiredIf: EntityCondition<TEntity> & DefaultValidatorParams;
  lowerThan: { key: keyof TEntity; modelName: string } & DefaultValidatorParams;
  number: boolean | DefaultValidatorParams;
  range: { min?: number; minExclusive?: number; max?: number; maxExclusive?: number } & DefaultValidatorParams;
  equals: ShorthandValidatorParams<any>;
  equalWith: ShorthandValidatorParams<string>; // TODO
  minLength: ShorthandValidatorParams<number>;
  maxLength: ShorthandValidatorParams<number>;
  manualErrors: any;
}
