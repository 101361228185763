import { ILocalizationService, INotificationService, SeverityLevel } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, ScreenBase, watchBusy } from "@frui.ts/screens";
import { action, computed, observable, runInAction } from "mobx";
import { attachAutomaticValidator, hasVisibleErrors, validate } from "@frui.ts/validation";
import EnumsService from "services/enumsService";
import { interfaces } from "inversify";
import ReqCustomerData from "entities/reqCustomerData";
import PartnersRepository from "repositories/partnersRepository";
import { unwrapErrorMessage } from "repositories/helpers";
import UserContext from "services/userContext";
import PartnerType from "models/partnerType";

export default class ModalEditCustomerInfoViewModel extends ScreenBase {
  static navigationName = "info";

  navigationName = ModalEditCustomerInfoViewModel.navigationName;
  busyWatcher = new BusyWatcher();

  @observable errorMessage?: string;
  @observable customerData: ReqCustomerData;
  @observable fileSelectError?: string;

  constructor(
    private partnerId: number,
    public localization: ILocalizationService,
    private partnersRepository: PartnersRepository,
    private enumsService: EnumsService,
    private notificationService: INotificationService,
    public userContext: UserContext
  ) {
    super();

    this.name = this.translate("change_customer_info");
    this.customerData = new ReqCustomerData();
  }

  @action
  protected onInitialize() {
    if (this.isCompany) {
      this.customerData.name = this.partner?.lastName || "";
      this.customerData.vatId = this.partner?.vatId;

      attachAutomaticValidator(this.customerData, ReqCustomerData.customCompanyValidationRules);
    } else {
      this.customerData.firstName = this.partner?.firstName || "";
      this.customerData.lastName = this.partner?.lastName || "";
      this.customerData.titleBeforeId = this.partner?.titleBeforeId;
      this.customerData.titleBefore2Id = this.partner?.titleBefore2Id;
      this.customerData.titleAfterId = this.partner?.titleAfterId;

      attachAutomaticValidator(this.customerData, ReqCustomerData.customUserValidationRules);
    }
  }

  @computed get isCompany() {
    if (!this.partner) {
      return false;
    }

    return this.enumsService.getPartnerType(this.partner.partnerTypeId) === PartnerType.Business;
  }

  get canConfirm() {
    return !hasVisibleErrors(this.customerData);
  }

  @computed
  get titlesBefore() {
    return this.enumsService.getValues("titlesBefore").filter(x => x.active);
  }

  @computed
  get titlesAfter() {
    return this.enumsService.getValues("titlesAfter").filter(x => x.active);
  }

  @action.bound
  onDrop(files: File[]) {
    this.customerData.file = files;
    this.fileSelectError = undefined;
  }

  @action.bound
  onDropRejected() {
    this.fileSelectError = this.translateGeneral("upload_invalid_files");
  }

  @action.bound
  @watchBusy
  async confirmChange() {
    this.errorMessage = undefined;
    this.fileSelectError = undefined;

    if (!validate(this.customerData)) {
      return;
    }

    if (!this.isCompany && this.customerData.birthDate && this.customerData.birthDate >= new Date()) {
      this.errorMessage = this.localization.translateGeneral("validators.birth_date");
      return;
    }

    const response = await this.partnersRepository.updateCustomerData(this.partnerId, this.customerData);

    if (response.success) {
      this.notificationService.addNotification(this.translateGeneral("request_sent_success"), SeverityLevel.success);
      this.requestClose();
    } else {
      runInAction(() => (this.errorMessage = unwrapErrorMessage(response.payload)));
    }
  }

  get partner() {
    return this.userContext.activePartner;
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`products.customer_info.${key}`);
  }

  @bound translateGeneral(key: string) {
    return this.localization.translateGeneral(`general.${key}`);
  }

  static Factory({ container }: interfaces.Context) {
    return (partnerId: number) => {
      return new ModalEditCustomerInfoViewModel(
        partnerId,
        container.get("ILocalizationService"),
        container.get(PartnersRepository),
        container.get(EnumsService),
        container.get("INotificationService"),
        container.get(UserContext)
      );
    };
  }
}
