import React from "react";
import { styleBlock } from "../";

export interface HeaderRowProps {
  title: string;
  children?: React.ReactNode;
  level?: number;
  className?: string;
}

const HeaderRow: React.FunctionComponent<HeaderRowProps> = ({ className, children, title, level }) => {
  const headingElement = React.createElement(`h${level}`, {}, title);
  const cn = styleBlock("header-row");

  return (
    <div className={cn.mix(["row", "align-items-center", "justify-content-between"]).mix(className)}>
      <div className="col">{headingElement}</div>
      {children && <div className="col-auto">{children}</div>}
    </div>
  );
};

HeaderRow.defaultProps = {
  level: 1,
};

export default HeaderRow;
