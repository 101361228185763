import { observable } from "mobx";
import { Expose } from "class-transformer";
import BannerPreview from "./bannerPreview";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class RspBanner extends BannerPreview {
  @observable
  @Expose({ name: "if_electricity" })
  ifElectricity?: boolean;

  @observable
  @Expose({ name: "if_not_electricity" })
  ifNotElectricity?: boolean;

  @observable
  @Expose({ name: "if_gas" })
  ifGas?: boolean;

  @observable
  @Expose({ name: "if_not_gas" })
  ifNotGas?: boolean;

  @observable
  @Expose({ name: "if_household" })
  ifHousehold?: boolean;

  @observable
  @Expose({ name: "if_company" })
  ifCompany?: boolean;

  static ValidationRules = BannerPreview.ValidationRules;
}
