import React from "react";

export interface ButtonTooltipProps {
  tooltip: string;
  last?: boolean;
}

type ButtonTooltip = ButtonTooltipProps & Omit<React.InputHTMLAttributes<HTMLInputElement>, "size">;

const ButtonTooltip: React.FC<ButtonTooltip> = ({ tooltip, last, className, children }) => {
  return (
    <div className={className + " button__tooltip"}>
      {children}
      <span className={"tooltip-text" + (last ? " last_tooltip" : "")}>{tooltip}</span>
    </div>
  );
};

export default ButtonTooltip;
