import { ILocalizationService } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, watchBusy } from "@frui.ts/screens";
import PaymentListPaymentsItem from "entities/paymentListPaymentsItem";
import ReqExportType from "entities/reqExportType";
import { createSupplyPointsWithIcons } from "helpers/supplyPointHelper";
import { openInNewTab } from "helpers/utils";
import { action, computed } from "mobx";
import PaymentsFilter from "models/paymentsFilter";
import PaymentState from "models/paymentState";
import PaymentsRepository from "repositories/paymentsRepository";
import DataSyncService from "services/dataSyncService";
import EnumsService from "services/enumsService";
import UserContext from "services/userContext";
import ContinuousListViewModelBase from "viewModels/continuousListViewModelBase";
import { IFinancePage } from "./types";

export const defaultAmountRange = [0, 10000];
const navigationName = "payments";

export default class PaymentsPageViewModel
  extends ContinuousListViewModelBase<PaymentListPaymentsItem, PaymentsFilter, any>
  implements IFinancePage
{
  orderIndex = 3;
  navigationName = navigationName;
  busyWatcher = new BusyWatcher();

  @computed
  get amountRange() {
    return [this.filter.minAmount ?? defaultAmountRange[0], this.filter.maxAmount ?? defaultAmountRange[1]];
  }
  set amountRange([min, max]: [number, number]) {
    this.filter.minAmount = min === defaultAmountRange[0] ? undefined : min;
    this.filter.maxAmount = max === defaultAmountRange[1] ? undefined : max;
  }

  constructor(
    public localization: ILocalizationService,
    private userContext: UserContext,
    private dataService: DataSyncService,
    private repository: PaymentsRepository,
    public enumsService: EnumsService
  ) {
    super();

    this.name = this.translate("title");
  }

  @action onInitialize() {
    this.loadData();
  }

  @bound
  @watchBusy
  async loadData() {
    await this.dataService.waitForFinance();
    if (this.userContext.activePartnerId) {
      const result = await this.repository.getPayments(this.userContext.activePartnerId, this.pagingFilter, this.filter);
      if (result.success) {
        this.setData(result.payload);
      }
    }
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`finance.payments_page.${key}`);
  }

  @bound translateGeneral(key: string) {
    return this.localization.translateGeneral(`general.${key}`);
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected resetFilterValues(filter: PaymentsFilter) {}

  protected createFilter() {
    return new PaymentsFilter();
  }

  @action clearFilter(...properties: (keyof PaymentsFilter)[]) {
    properties.forEach(property => {
      this.filter[property] = undefined;
    });
  }

  @bound
  downloadDocument(payment: PaymentListPaymentsItem) {
    if (payment?.file) {
      openInNewTab(payment.file);
    }
  }

  @action.bound
  applyPaymentStateFilter(paymentState?: PaymentState) {
    this.filter.maxAmount = undefined;
    this.filter.minAmount = undefined;

    if (paymentState === PaymentState.Paid) {
      this.filter.minAmount = 0;
    }
    if (paymentState === PaymentState.Returned) {
      this.filter.maxAmount = 0;
    }
    this.applyFilterAndLoad();
  }

  get activePaymentState() {
    if (this.filter.minAmount === 0) {
      return PaymentState.Paid;
    } else if (this.filter.maxAmount === 0) {
      return PaymentState.Returned;
    } else {
      return "all";
    }
  }

  get supplyPoints() {
    return createSupplyPointsWithIcons(this.enumsService, this.dataService.supplyPoints);
  }

  get canDownload() {
    return this.selectedItems.size > 0;
  }

  @action.bound
  async addToDownloadQueue(type: ReqExportType) {
    if (this.userContext.activePartnerId) {
      const ids: number[] = [];
      this.selectedItems.forEach(item => {
        ids.push(item.id);
      });

      const result = await this.repository.exportPayments(this.userContext.activePartnerId, { ids, type });
      if (result.success) {
        this.selectedItems.clear();
      }
    }
  }
}
