import { ILocalizationService } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, Router, ScreenBase, watchBusy } from "@frui.ts/screens";
import ReqFile from "entities/reqFile";
import { interfaces } from "inversify";
import { observable, runInAction } from "mobx";
import ProductDetailContext from "models/productDetailContext";
import ReadingsRepository from "repositories/readingsRepository";
import HistoryDetailPageViewModel from "./historyDetailPageViewModel";

import iconStateNotOk from "@emanprague/ppaskit/dist/assets/icons/State/Not Ok.svg";
import iconStateOk from "@emanprague/ppaskit/dist/assets/icons/State/Ok.svg";
import downloadjs from "downloadjs";
import { ApiResult } from "repositories/apiModels";
import OverviewViewModel from "viewModels/requests/overviewViewModel";

export interface BulkResultItem {
  icon: string;
  title: string;
  description: string;
  onDownload?: () => void;
  onClick: () => void;
  actionText: string;
}

export default class BulkSelfReadingViewModel extends ScreenBase {
  static navigationName = "bulk";
  fullScreen = true;

  busyWatcher = new BusyWatcher();
  navigationName = BulkSelfReadingViewModel.navigationName;
  parent: HistoryDetailPageViewModel;
  @observable reqFile?: ReqFile;
  @observable bulkResult?: BulkResultItem;

  constructor(
    private productDetailContext: ProductDetailContext,
    public localization: ILocalizationService,
    private readingsRepository: ReadingsRepository,
    private router: Router
  ) {
    super();
  }

  @bound
  onSelectFile(files: File[]) {
    runInAction(() => {
      if (files[0]) {
        this.reqFile = new ReqFile();
        this.reqFile.file = files[0];
        void this.uploadSelfReading(this.reqFile);
      } else {
        this.reqFile = undefined;
      }
    });
  }

  @bound
  @watchBusy
  async uploadSelfReading(reqFile: ReqFile) {
    const result = await this.readingsRepository.makeBulkSelfReading(this.productDetailContext.partnerId, reqFile);
    runInAction(() => {
      if (result.success) {
        this.bulkResult = {
          icon: iconStateOk,
          title: this.translate("success_result"),
          description: this.translate("success_result_description"),
          onClick: this.goToRequests,
          actionText: this.localization.translateModel("request", 5),
        };
      } else if (!result.success && result.payload.errors && result.payload.errors.file) {
        this.bulkResult = {
          icon: iconStateNotOk,
          title: this.translate("bad_result"),
          description: this.translate("bad_result_description"),
          onDownload: this.downloadAfterValidationFile,
          onClick: this.resetBulkResult,
          actionText: this.translate("bad_result_action"),
        };
      }
    });
  }

  @bound
  goToRequests() {
    runInAction(() => {
      this.bulkResult = undefined;
      this.router.navigate(OverviewViewModel);
    });
  }

  @bound
  @watchBusy
  async downloadTemplate() {
    const response = await this.readingsRepository.getSelfReadingTemplate();
    void this.downloadFromResponse(response);
  }

  @bound
  @watchBusy
  async downloadAfterValidationFile() {
    const response = await this.readingsRepository.getSelfReadingFile(this.productDetailContext.partnerId);
    void this.downloadFromResponse(response);
  }

  @bound
  @watchBusy
  async downloadFromResponse(response: ApiResult<Response>) {
    if (response.success) {
      const mimeType = (response.payload as any).headers.get("Content-Type") as string;
      const fileNameFromServer = (response.payload as any).headers.get("content-disposition") ?? "default-name";
      const fileName = decodeURIComponent(fileNameFromServer.split("UTF-8")[1].slice(2));
      const body = await (response.payload as any).blob();
      downloadjs(body, fileName, mimeType);
    }
  }

  @bound resetBulkResult() {
    runInAction(() => {
      this.bulkResult = undefined;
    });
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`products.history.bulk_self_reading.${key}`);
  }

  @bound translateGeneral(key: string) {
    return this.localization.translateGeneral(`general.${key}`);
  }

  @bound translateAttribute(key: string) {
    return this.localization.translateAttribute("reading", key);
  }

  static Factory({ container }: interfaces.Context) {
    return (productDetailContext: ProductDetailContext) => {
      return new BulkSelfReadingViewModel(
        productDetailContext,
        container.get("ILocalizationService"),
        container.get(ReadingsRepository),
        container.get(Router)
      );
    };
  }
}
