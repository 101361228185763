import { observable } from "mobx";
import { Transform } from "class-transformer";
import formatISO from "date-fns/formatISO";
import { Expose } from "class-transformer";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class EnumGeneric {
  id!: number;

  @observable
  name!: string;

  @observable
  value!: string;

  @observable
  @Transform(value => (value ? new Date(value) : undefined), { toClassOnly: true })
  @Transform(value => (value ? formatISO(value, { representation: "date" }) : undefined), { toPlainOnly: true })
  @Expose({ name: "valid_from" })
  validFrom!: Date;

  @observable
  @Transform(value => (value ? new Date(value) : undefined), { toClassOnly: true })
  @Transform(value => (value ? formatISO(value, { representation: "date" }) : undefined), { toPlainOnly: true })
  @Expose({ name: "valid_to" })
  validTo?: Date;

  @observable
  @Expose({ name: "external_id" })
  externalId!: string;

  /**
   * Allow to choose?
   */
  @observable
  active?: boolean;

  static ValidationRules = {
    id: { required: true, number: true },
    name: { required: true },
    value: { required: true },
    validFrom: { required: true, date: true },
    validTo: { date: true },
    externalId: { required: true },
  };
}
