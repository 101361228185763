import { ILocalizationService, INotificationService, SeverityLevel } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, Router, watchBusy } from "@frui.ts/screens";
import { IAdminModule } from "viewModels/types";
import ContinuousListViewModelBase from "../continuousListViewModelBase";
import AdminMessagesFilter from "models/adminMessagesFilter";
import { action } from "mobx";
import MessageDetailViewModel from "./messageDetailViewModel";
import AdminRepository from "repositories/adminRepository";
import MessagePreviewViewModel from "./messagePreviewViewModel";
import ConfirmationService from "services/confirmationService";
import MessageListItem from "entities/messageListItem";

const navigationName = "messages";

@Router.registerRoute({ name: Router.Self, route: `${navigationName}` })
export default class MessagesPageViewModel
  extends ContinuousListViewModelBase<MessageListItem, AdminMessagesFilter, MessageDetailViewModel | MessagePreviewViewModel>
  implements IAdminModule
{
  navigationName = navigationName;
  orderIndex = 50;
  menuPart = "content";
  busyWatcher = new BusyWatcher();

  constructor(
    public localization: ILocalizationService,
    private detailFactory: ReturnType<typeof MessageDetailViewModel.Factory>,
    private previewFactory: ReturnType<typeof MessagePreviewViewModel.Factory>,
    private adminRepository: AdminRepository,
    private confirmationService: ConfirmationService,
    private notificationService: INotificationService
  ) {
    super();

    this.name = this.translate("title");
  }

  protected onInitialize(): Promise<any> | void {
    return this.loadData();
  }

  @bound
  @watchBusy
  async loadData() {
    const response = await this.adminRepository.getMessages(this.pagingFilter, this.filter);
    if (response.success) {
      this.setData(response.payload);
    }
  }

  @action.bound
  reloadData() {
    this.applyFilterAndLoadDebounced();
    this.selectedItems.clear();
  }

  @bound
  addMessage() {
    return this.tryActivateChild(this.detailFactory(undefined, this.reloadData));
  }

  @action
  openDetail(messageId: number) {
    return this.tryActivateChild(this.detailFactory(messageId, this.reloadData));
  }

  @bound
  openPreview(messageId: number) {
    return this.tryActivateChild(this.previewFactory(messageId));
  }

  @bound
  @watchBusy
  async openDelete(messageIds: number[]) {
    const confirm = await this.confirmationService.showConfirmation(
      this.translate("delete_confirmation_" + this.localization.getPluralForm(messageIds.length)),
      undefined,
      { variant: "danger", text: this.translateGeneral("delete_confirmation_button") },
      this.translateGeneral("cancel_button")
    );

    if (!confirm) {
      return;
    }

    const response = await this.adminRepository.deleteMessages(messageIds);
    if (response.success) {
      this.notificationService.addNotification(this.translateGeneral("request_sent_success"), SeverityLevel.success);
      this.reloadData();
    }
  }

  @action.bound
  clearFilter(...properties: (keyof AdminMessagesFilter)[]) {
    properties.forEach(property => {
      this.filter[property] = undefined;
    });
    this.applyFilterAndLoad();
  }

  get deleteButtonLabel() {
    return `${this.translateGeneral("delete_selected_button")} (${this.selectedItems.size})`;
  }

  get selectedMessagesIds() {
    const messages: MessageListItem[] = Array.from(this.selectedItems);
    return messages.map(item => item.id);
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected resetFilterValues(filter: AdminMessagesFilter) {}

  protected createFilter() {
    return new AdminMessagesFilter();
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`admin.messages.${key}`);
  }

  @bound translateGeneral(key: string) {
    return this.localization.translateGeneral(`general.${key}`);
  }

  protected findNavigationChild(navigationName?: string): any {
    if (navigationName === MessageDetailViewModel.navigationName) {
      return this.addMessage();
    }
    return super.findNavigationChild(navigationName);
  }
}
