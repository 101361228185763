import { Expose, Transform } from "class-transformer";
import formatISO from "date-fns/formatISO";
import { observable } from "mobx";
import PaymentState from "./paymentState";

export default class PaymentsFilter {
  @observable
  @Expose({ name: "q" })
  search?: string;

  @observable
  @Expose({ name: "filters[supply_point_id]" })
  supplyPointIds?: number[];

  @observable
  @Expose({ name: "filters[amount_from]" })
  minAmount?: number;

  @observable
  @Expose({ name: "filters[amount_to]" })
  maxAmount?: number;

  @observable
  @Expose({ name: "filters[vs]" })
  vs?: string;

  @observable
  @Expose({ name: "filters[type]" })
  type?: PaymentState;

  @observable
  @Expose({ name: "filters[date_from]" })
  @Transform(value => value && formatISO(value, { representation: "date" }))
  dateFrom?: Date;

  @observable
  @Expose({ name: "filters[date_to]" })
  @Transform(value => value && formatISO(value, { representation: "date" }))
  dateTo?: Date;
}
