import { createEventDefinition, EventBus, IEventBus } from "@emanprague/shared-services";
import FetchError from "@frui.ts/apiclient/dist/fetchError";
import ReqLinksInSectionSection from "entities/reqLinksInSectionSection";

const GeneralEvents = {
  apiError: createEventDefinition<{
    response: FetchError;
    errorMessage: string;
  }>()("api.error"),

  unauthorized: createEventDefinition<any>()("api.unauthorized"),
  sessionChanged: createEventDefinition<any>()("session.changed"),
};

const DataEvents = {
  supplyPointsUpdated: createEventDefinition<any>()("supplyPoints.updated"),
  activePartnerUpdated: createEventDefinition<any>()("activePartner.updated"),
  linksUpdated: createEventDefinition<ReqLinksInSectionSection>()("links.updated"),
};

const AppendixesEvents = {
  appendixAccepted: createEventDefinition<any>()("appendixes.created"), // blocks appendixes loading until WS notification
  appendixesUpdated: createEventDefinition<any>()("appendixes.updated"), // causes appendixes reload (potentially blocked)
  appendixesChangedOnServer: createEventDefinition<any>()("appendixes.changedOnServer"), // unblocks appendixes loading
  newAppendixesLoaded: createEventDefinition<any>()("newAppendixes.loaded"), // notifies components to read new appendixes state
};

const RequestsEvents = {
  requestCreated: createEventDefinition<any>()("requests.created"), // blocks requests loading until WS notification
  requestsUpdated: createEventDefinition<any>()("requests.updated"), // causes requests reload (potentially blocked)
  requestsChangedOnServer: createEventDefinition<any>()("requests.changedOnServer"), // unblocks requests loading
};

const DownloadingEvents = {
  makeRefresh: createEventDefinition<any>()("downloading.makeRefresh"),
};

export { GeneralEvents, DataEvents, DownloadingEvents, AppendixesEvents, RequestsEvents };

export class EventsService extends EventBus implements IEventBus {}
