import { observable } from "mobx";
import { Transform } from "class-transformer";
import formatISO from "date-fns/formatISO";
import { Expose } from "class-transformer";
import NotificationStatus from "./notificationStatus";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class Notification {
  /**
   * Notification ID
   */
  id!: number;

  /**
   * Status
   */
  @observable
  status!: NotificationStatus;

  /**
   * Class name
   */
  @observable
  type!: string;

  /**
   * Description for UI
   */
  @observable
  @Expose({ name: "human_name" })
  humanName!: string;

  @observable
  @Transform(value => (value ? new Date(value) : undefined), { toClassOnly: true })
  @Transform(value => (value ? formatISO(value, { representation: "date" }) : undefined), { toPlainOnly: true })
  @Expose({ name: "created_at" })
  createdAt!: Date;

  @observable
  @Transform(value => (value ? new Date(value) : undefined), { toClassOnly: true })
  @Transform(value => (value ? formatISO(value, { representation: "date" }) : undefined), { toPlainOnly: true })
  @Expose({ name: "updated_at" })
  updatedAt!: Date;

  @observable
  @Transform(value => (value ? new Date(value) : undefined), { toClassOnly: true })
  @Transform(value => (value ? formatISO(value, { representation: "date" }) : undefined), { toPlainOnly: true })
  @Expose({ name: "finished_at" })
  finishedAt?: Date;

  /**
   * Flag, if notification has downloadable file attached.
   */
  @observable
  @Expose({ name: "file_attached" })
  fileAttached?: boolean;

  static ValidationRules = {
    id: { required: true, number: true },
    status: { required: true },
    type: { required: true },
    humanName: { required: true },
    createdAt: { required: true, date: true },
    updatedAt: { required: true, date: true },
    finishedAt: { date: true },
  };
}
