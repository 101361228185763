import { NotificationBar } from "@emanprague/ppaskit";
import React from "react";

interface SapDashboardNoticeProps {
  text: string;
  onCancel: () => void;
}

// Used for messages from SAP for Partner or Supply Point
export default class SapDashboardNotice extends React.PureComponent<SapDashboardNoticeProps> {
  render() {
    return (
      <NotificationBar background="#E59900" className="mb-3" cancellable onCancel={this.props.onCancel}>
        <div className="text-center" dangerouslySetInnerHTML={{ __html: this.props.text }}></div>
      </NotificationBar>
    );
  }
}
