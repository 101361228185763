import { Button, Card, CardGroup, Col, Form } from "@emanprague/ppaskit";
import { Input } from "@frui.ts/bootstrap";
import { preventDefault, registerView, View, ViewComponent } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import React from "react";
import ForgottenPasswordViewModel from "viewModels/profile/forgottenPasswordViewModel";
import PreAuthHeader from "components/PreAuthHeader";

const forgottenPasswordListView: ViewComponent<ForgottenPasswordViewModel> = observer(({ vm }) => (
  <>
    <PreAuthHeader translateFunc={vm.localization.translateGeneral} title={vm.name} />
    <View vm={vm} context={vm.currentStep} />
  </>
));

registerView(forgottenPasswordListView, ForgottenPasswordViewModel);

const step1: ViewComponent<ForgottenPasswordViewModel> = observer(({ vm }) => {
  const ta = vm.localization.translateAttribute;

  return (
    <CardGroup className="card-single">
      <Card>
        <Card.Body>
          <p className="text-center">{vm.translate("description")}</p>
          <Form onSubmit={preventDefault(vm.continue)}>
            <Form.Row>
              <Form.Group as={Col}>
                <Form.Label>{ta("user", "email")}</Form.Label>
                <Input target={vm.data} property="email" autoFocus />
              </Form.Group>
            </Form.Row>

            <Button disabled={!vm.canContinue} type="submit" block={true}>
              {vm.localization.translateGeneral("general.continue_button")}
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </CardGroup>
  );
});
registerView(step1, ForgottenPasswordViewModel, "step1");

const step2: ViewComponent<ForgottenPasswordViewModel> = observer(({ vm }) => (
  <CardGroup className="card-single">
    <Card>
      <Card.Body className="text-center">
        <p className="mb-0" dangerouslySetInnerHTML={{ __html: vm.successMessage }} />
      </Card.Body>
    </Card>
  </CardGroup>
));

registerView(step2, ForgottenPasswordViewModel, "step2");
