import { ILocalizationService } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { ScreenBase, watchBusy, BusyWatcher } from "@frui.ts/screens";
import { attachAutomaticValidator, validate, hasVisibleErrors } from "@frui.ts/validation";
import { UpdateResetPasswordValidationRules, ExtendUpdateResetPassword } from "entities/updateResetPassword";
import { action, observable, runInAction } from "mobx";
import PasswordValidationService from "services/validation/passwordValidationService";
import LoginRepository from "repositories/loginRepository";
import UpdateResetPassword from "entities/updateResetPassword";
import SecurityService from "services/securityService";
import { unwrapErrorMessage } from "repositories/helpers";

export default class ResetPasswordViewModel extends ScreenBase {
  static navigationName = "resetPassword";
  navigationName = ResetPasswordViewModel.navigationName;
  busyWatcher = new BusyWatcher();

  @observable data: ExtendUpdateResetPassword;
  dataAPI: UpdateResetPassword;
  @observable errorMessage?: string;

  constructor(
    public localization: ILocalizationService,
    private passwordValidation: PasswordValidationService,
    private loginRepository: LoginRepository,
    private securityService: SecurityService
  ) {
    super();

    this.dataAPI = new UpdateResetPassword();
    this.data = new ExtendUpdateResetPassword();
    attachAutomaticValidator(this.data, UpdateResetPasswordValidationRules);
  }

  get passwordComplexityValue() {
    return this.passwordValidation.getComplexityValue(this.data.password);
  }

  get canConfirm() {
    return !hasVisibleErrors(this.data);
  }

  get isTokenValid() {
    return this.data.token;
  }

  @action
  setErrorMessage(message?: string) {
    this.errorMessage = message;
  }

  @action.bound
  @watchBusy
  async confirmResetPassword() {
    this.errorMessage = undefined;
    if (validate(this.data)) {
      this.dataAPI.token = this.data.token;
      this.dataAPI.password = this.data.password;
      const response = await this.loginRepository.resetPasswordConfirm(this.dataAPI);
      runInAction(() => {
        if (response.success) {
          this.securityService.checkSession().then(x => x.applySessionCallback());
        } else {
          this.errorMessage = unwrapErrorMessage(response.payload);
        }
      });
    }
  }

  @action navigate(subPath: string | undefined, params: any) {
    if (params && params.token) {
      this.data.token = params.token;
    }
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`profile.reset_password.${key}`);
  }

  @bound translatePassword(key: string) {
    return this.localization.translateGeneral(`profile.registration.${key}`);
  }
}
