import RepositoryBase from "./repositoryBase";
import BannerList from "entities/bannerList";
import type { IPagingFilter } from "@frui.ts/data";
import type BannersFilter from "models/bannersFilter";
import { entityToPlain } from "./helpers";
import type AdminMessagesFilter from "models/adminMessagesFilter";
import RspBanner from "entities/rspBanner";
import type ReqBanner from "entities/reqBanner";
import type ReqMessage from "entities/reqMessage";
import RspMessageList from "entities/rspMessageList";
import type AdminsFilter from "models/adminsFilter";
import UserList from "entities/userList";
import type ReqCreateAdminUser from "entities/reqCreateAdminUser";
import type ReqUpdateAdminUser from "entities/reqUpdateAdminUser";
import MessagePreview from "entities/messagePreview";
import type AdminPartnersFilter from "models/adminPartnersFilter";
import RspPartnerList from "entities/rspPartnerList";
import IdList from "entities/idList";
import type ReqMorph from "entities/reqMorph";
import type ProductCardsFilter from "models/productCardsFilter";
import ProductCardList from "entities/productCardList";
import ProductCard from "entities/productCard";
import type ReqProductCard from "entities/reqProductCard";
import AuditLogList from "entities/auditLogList";
import type AuditLogsFilter from "models/auditLogsFilter";

export default class AdminRepository extends RepositoryBase {
  /** Admins */

  getAdmins(paging: IPagingFilter, filter?: AdminsFilter) {
    const query = entityToPlain(filter);

    return this.callApi(api => api.all(`admin/users`).getPagedEntities(UserList, x => x.users, paging, query));
  }

  addAdmin(data: ReqCreateAdminUser) {
    return this.callApi(api => api.all(`admin/users`).postEntity(data));
  }

  updateAdmin(data: ReqUpdateAdminUser, userId: number) {
    return this.callApi(api => api.all(`admin/users/${userId}`).putEntity(data));
  }

  /** Screen share */

  getAdminPartners(paging: IPagingFilter, filter?: AdminPartnersFilter) {
    const query = entityToPlain(filter);

    return this.callApi(api => api.all(`admin/partners`).getPagedEntities(RspPartnerList, x => x.partners, paging, query));
  }

  morphAsPartner(data: ReqMorph) {
    return this.callApi(api => api.all(`admin/partners/morph`).postOnly({ partner_id: data.partnerId, user_id: data.userId }));
  }

  unmorphAsPartner() {
    return this.callApi(api => api.all(`admin/partners/unmorph`).delete());
  }

  /**  Banners  */

  getBanners(paging: IPagingFilter, filter?: BannersFilter) {
    const query = entityToPlain(filter);

    return this.callApi(api => api.all(`admin/banners`).getPagedEntities(BannerList, x => x.banners, paging, query));
  }

  getBannerDetail(bannerId: number) {
    return this.callApi(api => api.all(`admin/banners/${bannerId}`).getEntity(RspBanner));
  }

  addBanner(data: ReqBanner) {
    const formData = data.toFormData();

    return this.callApi(api => api.all(`admin/banners`).postData(formData));
  }

  updateBanner(bannerId: number, data: ReqBanner) {
    const formData = data.toFormData();

    return this.callApi(api => api.all(`admin/banners/${bannerId}`).putData(formData));
  }

  deleteBanners(bannerIds: number[]) {
    const bannerIdsList = new IdList();
    bannerIdsList.ids = bannerIds;

    return this.callApi(api => api.all(`admin/banners`).delete(bannerIdsList));
  }

  /**  Product Cards  */

  getProductCards(paging: IPagingFilter, filter?: ProductCardsFilter) {
    const query = entityToPlain(filter);

    return this.callApi(api =>
      api.all(`admin/product_cards`).getPagedEntities(ProductCardList, x => x.productCards, paging, query)
    );
  }

  getProductCardDetail(productCardId: number) {
    return this.callApi(api => api.all(`admin/product_cards/${productCardId}`).getEntity(ProductCard));
  }

  addProductCard(data: ReqProductCard) {
    return this.callApi(api => api.all(`admin/product_cards`).postEntity(data));
  }

  updateProductCard(produuctCardId: number, data: ReqProductCard) {
    return this.callApi(api => api.all(`admin/product_cards/${produuctCardId}`).putEntity(data));
  }

  deleteProductCards(productCardIds: number[]) {
    const productCardIdsList = new IdList();
    productCardIdsList.ids = productCardIds;

    return this.callApi(api => api.all(`admin/product_cards`).delete(productCardIdsList));
  }

  /**  Messages  */

  getMessages(paging: IPagingFilter, filter?: AdminMessagesFilter) {
    const query = entityToPlain(filter);

    return this.callApi(api => api.all(`admin/messages`).getPagedEntities(RspMessageList, x => x.messages, paging, query));
  }

  addMessage(data: ReqMessage) {
    return this.callApi(api => api.all(`admin/messages`).postEntity(data));
  }

  getMessageDetail(messageId: number) {
    return this.callApi(api => api.all(`admin/messages/${messageId}`).getEntity(MessagePreview));
  }

  updateMessage(messageId: number, data: ReqMessage) {
    return this.callApi(api => api.all(`admin/messages/${messageId}`).putEntity(data));
  }

  deleteMessages(messageIds: number[]) {
    const messageIdsList = new IdList();
    messageIdsList.ids = messageIds;

    return this.callApi(api => api.all(`admin/messages`).delete(messageIdsList));
  }

  /**  AuditLog  */

  getAuditLogs(paging: IPagingFilter, filter?: AuditLogsFilter) {
    const query = entityToPlain(filter);

    return this.callApi(api => api.all(`admin/audit_logs`).getPagedEntities(AuditLogList, x => x.items, paging, query));
  }

  resolveAuditLog(auditLogId: number) {
    return this.callApi(api => api.all(`admin/audit_logs/${auditLogId}`).putEntity({ status: "resolved" }));
  }
}
