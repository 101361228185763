import { ILocalizationService } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, Router, ScreenBase, watchBusy } from "@frui.ts/screens";
import { action, observable, runInAction } from "mobx";
import PartnersRepository from "repositories/partnersRepository";

const navigationName = "confirmPartner";

export default class PartnerConfirmViewModel extends ScreenBase {
  static navigationName = navigationName;
  navigationName = PartnerConfirmViewModel.navigationName;

  @observable errorMessage?: string;
  @observable message: string;

  busyWatcher = new BusyWatcher();

  constructor(public localization: ILocalizationService, public router: Router, private partnersRepository: PartnersRepository) {
    super();

    this.name = this.translate("title");
  }

  @action
  navigate(subPath: string | undefined, params: any) {
    if (params && params.token) {
      return this.confirmPartner(params.token);
    }
  }

  @bound
  @watchBusy
  async confirmPartner(token: string) {
    const result = await this.partnersRepository.confirmPartner(token);
    runInAction(() => (this.message = result.success ? this.translate("success_message") : this.translate("error_message")));
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`partner_confirm.${key}`);
  }

  @action.bound
  requestClose() {
    window.location.href = "#";
    return super.requestClose();
  }
}
