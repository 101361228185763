import { observable } from "mobx";
import { Type } from "class-transformer";
import InvoiceListItem from "./invoiceListItem";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class InvoiceList {
  @observable
  total!: number;

  @observable
  @Type(() => InvoiceListItem)
  invoices!: InvoiceListItem[];

  static ValidationRules = {
    total: { required: true, number: true },
    invoices: { required: true },
  };
}
