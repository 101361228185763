import { registerView, ViewComponent } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import React from "react";
import { Modal, HeaderRow, Button, LoaderOverlay } from "@emanprague/ppaskit";
import CompetitionsViewModel from "viewModels/profile/competitionsViewModel";
import "./style.scss";
import { Check } from "@frui.ts/bootstrap";

const competitionsView: ViewComponent<CompetitionsViewModel> = observer(({ vm }) => {
  const competition = vm.competitions[0];

  const checkBoxLabel = competition.checkBoxText ? (
    <div dangerouslySetInnerHTML={{ __html: competition.checkBoxText }} />
  ) : (
    <>
      {vm.translate("agreement_part1")}{" "}
      <a href={competition?.url} target="_blank" rel="noopener noreferrer">
        {vm.translate("agreement_part2")}
      </a>{" "}
      {vm.translate("agreement_part3")}{" "}
      <a href={vm.localization.translateGeneral("profile.registration.link_zou")} target="_blank" rel="noopener noreferrer">
        {vm.translate("agreement_part4")}
      </a>
    </>
  );

  return (
    <>
      <Modal centered show onHide={vm.requestClose} className="wider-modal">
        <img src={competition?.imageUrl} alt="competition" />
        <LoaderOverlay loading={vm.busyWatcher.isBusy}>
          <Modal.Body className="mx-5">
            <div className="text-center mb-4 mx-3 mt-2">
              <HeaderRow title={competition?.title} level={4} />
            </div>
            <div dangerouslySetInnerHTML={{ __html: competition?.description ?? "" }} />
            <Check className="mt-4" custom target={vm} property="agreement" label={checkBoxLabel} />
          </Modal.Body>
        </LoaderOverlay>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => vm.sendChoice("disagree")}>
            {vm.translate("reject")}
          </Button>

          <Button disabled={!vm.agreement} variant="primary" onClick={() => vm.sendChoice("agree")}>
            {vm.translate("confirm")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
});

registerView(competitionsView, CompetitionsViewModel);
