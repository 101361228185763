import * as sharedServices from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, Router, watchBusy } from "@frui.ts/screens";
import { action, computed, observable } from "mobx";
import ContinuousListViewModelBase from "viewModels/continuousListViewModelBase";
import type { IAdminModule } from "viewModels/types";
import AdminRepository from "repositories/adminRepository";
import type AuditLogListItem from "entities/auditLogListItem";
import AuditLogsFilter from "models/auditLogsFilter";
import EnumsService from "services/enumsService";

const navigationName = "audit-logs";

@Router.registerRoute({ name: Router.Self, route: navigationName })
export default class AuditLogsPageViewModel
  extends ContinuousListViewModelBase<AuditLogListItem, AuditLogsFilter>
  implements IAdminModule
{
  navigationName = navigationName;
  orderIndex = 200;
  menuPart = "admin";
  busyWatcher = new BusyWatcher();

  @observable selectedStatuses: string[] = [];

  constructor(
    public localization: sharedServices.ILocalizationService,
    private adminRepository: AdminRepository,
    private enumsService: EnumsService,
    private notificationService: sharedServices.INotificationService
  ) {
    super();

    this.name = this.translate("title");
  }

  protected onInitialize(): Promise<any> | void {
    this.initializeFilter();
    return this.loadData();
  }

  @action.bound
  initializeFilter() {
    this.selectedStatuses = ["unresolved"];
    this.filter.status = this.selectedStatuses;
  }

  @bound
  @watchBusy
  async loadData() {
    const response = await this.adminRepository.getAuditLogs(this.pagingFilter, this.filter);
    if (response.success) {
      this.setData(response.payload);
    }
  }

  @action.bound
  reloadData() {
    this.applyFilterAndLoadDebounced();
    this.selectedItems.clear();
  }

  @computed
  get auditLogStatuses() {
    return this.enumsService.getValues("auditLogStatuses");
  }

  @action.bound
  selectStatuses(statuses: string[]) {
    this.selectedStatuses = statuses;
    this.applyStatusesFilter();
  }

  @action.bound
  applyStatusesFilter() {
    this.filter.status = this.selectedStatuses;
    void this.applyFilterAndLoad();
  }

  @action.bound
  clearFilter(...properties: (keyof AuditLogsFilter)[]) {
    properties.forEach(property => {
      this.filter[property] = undefined;
      if (property === "status") {
        this.selectedStatuses = [];
      }
    });

    void this.applyFilterAndLoad();
  }

  get activeStatusCard() {
    if (this.selectedStatuses.length === 0 || this.selectedStatuses.length === this.auditLogStatuses.length) {
      return "all";
    } else if (this.selectedStatuses.length === 1) {
      return this.selectedStatuses[0];
    } else {
      return undefined;
    }
  }

  @bound
  @watchBusy
  async resolveItem(id: number) {
    const response = await this.adminRepository.resolveAuditLog(id);
    if (response.success) {
      this.notificationService.addNotification(this.translateGeneral("resolved"), sharedServices.SeverityLevel.success);
      this.reloadData();
    }
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`admin.audit_logs.${key}`);
  }

  @bound translateGeneral(key: string) {
    return this.localization.translateGeneral(`general.${key}`);
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected resetFilterValues(filter: AuditLogsFilter) {}

  protected createFilter() {
    return new AuditLogsFilter();
  }

  protected findNavigationChild(navigationName: string | undefined): any {
    return super.findNavigationChild(navigationName);
  }
}
