import { BusyWatcher, ScreenBase, watchBusy } from "@frui.ts/screens";
import { ILocalizationService } from "@emanprague/shared-services";
import { interfaces } from "inversify";
import { bound } from "@frui.ts/helpers";
import { action, observable, runInAction } from "mobx";
import { attachAutomaticValidator, hasVisibleErrors, validateAll } from "@frui.ts/validation";
import ReqLinksInSectionSection from "entities/reqLinksInSectionSection";
import LinkListItem from "entities/linkListItem";
import LinksRepository from "repositories/linksRepository";
import ReqLinksInSection from "entities/reqLinksInSection";
import { classToClass } from "class-transformer";
import { IEventBus } from "@emanprague/shared-services";
import { DataEvents } from "services/events";

const navigationName = "detail";

export default class LinksDetailViewModel extends ScreenBase {
  static navigationName = navigationName;

  navigationName = navigationName;
  busyWatcher = new BusyWatcher();

  @observable links: LinkListItem[];

  constructor(
    links: LinkListItem[],
    private section: ReqLinksInSectionSection,
    public localization: ILocalizationService,
    private repository: LinksRepository,
    private eventBus: IEventBus
  ) {
    super();

    this.name = this.translate("title");

    this.links = [new LinkListItem(), new LinkListItem(), new LinkListItem(), new LinkListItem()];
    this.links.forEach(item => attachAutomaticValidator(item, LinkListItem.ValidationRules));

    runInAction(() => {
      links.forEach((link, index) => {
        this.links[index] = classToClass(link);
      });
    });
  }

  @action.bound
  @watchBusy
  async confirmRequest() {
    if (validateAll(this.links)) {
      const data: ReqLinksInSection = {
        section: this.section,
        links: this.links.filter(link => !!link.text && !!link.url),
      };
      const result = await this.repository.updateLinks(data);
      if (result.success) {
        this.eventBus.publish(DataEvents.linksUpdated(this.section));
        this.requestClose();
      }
    }
  }

  get canConfirm() {
    return !hasVisibleErrors(this.links);
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`admin.link_settings.detail.${key}`);
  }

  @bound translateGeneral(key: string) {
    return this.localization.translateGeneral(`general.${key}`);
  }

  static Factory({ container }: interfaces.Context) {
    return (links: LinkListItem[], section: ReqLinksInSectionSection) => {
      return new LinksDetailViewModel(
        links,
        section,
        container.get("ILocalizationService"),
        container.get(LinksRepository),
        container.get("IEventBus")
      );
    };
  }
}
