import { IEventBus, ILocalizationService, INotificationService, SeverityLevel } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, ScreenBase, watchBusy } from "@frui.ts/screens";
import { attachAutomaticValidator, hasVisibleErrors, validate } from "@frui.ts/validation";
import ReqNewRequest from "entities/reqNewRequest";
import { interfaces } from "inversify";
import { action, computed, observable, runInAction } from "mobx";
import { unwrapErrorMessage } from "repositories/helpers";
import RequestsRepository from "repositories/requestsRepository";
import EnumsService from "services/enumsService";
import { RequestsEvents } from "services/events";

export default class ModalCreateRequestViewModel extends ScreenBase {
  busyWatcher = new BusyWatcher();

  @observable data: ReqNewRequest;
  @observable successMessage?: string;
  @observable errorMessage?: string;
  @observable fileSelectError?: string;

  constructor(
    private partnerId: number,
    public localization: ILocalizationService,
    private repository: RequestsRepository,
    private enumsService: EnumsService,
    private notificationService: INotificationService,
    private eventBus: IEventBus
  ) {
    super();
    this.data = new ReqNewRequest();
    attachAutomaticValidator(this.data, ReqNewRequest.ValidationRules);
  }

  @action.bound
  @watchBusy
  async confirmRequest() {
    this.successMessage = undefined;
    this.fileSelectError = undefined;

    if (validate(this.data)) {
      const response = await this.repository.sendRequest(this.data, this.partnerId);

      if (response.success) {
        this.notificationService.addNotification(this.translateGeneral("request_sent_success"), SeverityLevel.success);
        this.requestClose();
      } else {
        this.eventBus.publish(RequestsEvents.requestsChangedOnServer(undefined));
        runInAction(() => (this.errorMessage = unwrapErrorMessage(response.payload)));
      }
    }
  }

  @computed
  get requestFormTypes() {
    return this.enumsService.getValues("requestTypes").filter(x => x.active);
  }

  get canConfirm() {
    return !hasVisibleErrors(this.data) && this.data.requestTypeId;
  }

  @action.bound
  onDrop(files: File[]) {
    this.data.file = files;
    this.fileSelectError = undefined;
  }

  @action.bound
  onDropRejected() {
    this.fileSelectError = this.translateGeneral("upload_invalid_files");
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`requests.${key}`);
  }

  @bound translateGeneral(key: string) {
    return this.localization.translateGeneral(`general.${key}`);
  }

  static Factory({ container }: interfaces.Context) {
    return (partnerId: number) => {
      return new ModalCreateRequestViewModel(
        partnerId,
        container.get("ILocalizationService"),
        container.get(RequestsRepository),
        container.get(EnumsService),
        container.get("INotificationService"),
        container.get("IEventBus")
      );
    };
  }
}
