import { Form, FormControlProps } from "@emanprague/ppaskit";
import { DatePickerProps as DatePickerControlProps, default as DatePickerControl } from "@emanprague/ppaskit/dist/DatePicker";
import { ValidationControlBase } from "@frui.ts/bootstrap";
import { bound } from "@frui.ts/helpers";
import React from "react";

type DatePickerProps = Omit<DatePickerControlProps, "onChange"> & {
  feedbackValid?: React.ReactNode;
  feedbackInvalid?: React.ReactNode;
};

export default class DatePicker<TTarget> extends ValidationControlBase<TTarget, DatePickerProps & FormControlProps> {
  @bound
  protected renderInner() {
    const { wrapperClassName, noValidation, errorMessage, feedbackValid, feedbackInvalid, ...otherProps } = this.inheritedProps;
    const validationError = this.getValidationError() || feedbackInvalid;

    return (
      <>
        <DatePickerControl
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          {...otherProps}
          dateFormat={undefined}
          wrapperClassName={wrapperClassName ?? "d-block"}
          selected={this.value}
          onChange={this.setValue}
          isInvalid={!!validationError}
        />
        {feedbackValid && <Form.Control.Feedback className="d-block">{feedbackValid}</Form.Control.Feedback>}
        {validationError && (
          <Form.Control.Feedback className="d-block" type="invalid">
            {validationError}
          </Form.Control.Feedback>
        )}
      </>
    );
  }
}
