import React, { useState } from "react";

import Form from "react-bootstrap/Form";
import { FormControlProps } from "react-bootstrap/FormControl";
import { styleBlock } from "../";

import EyeIcon from "../assets/icons/Eye.svg";
import EyeCrossedIcon from "../assets/icons/Eye Crossed.svg";

export interface InputPasswordProps {
  feedbackValid?: any;
  feedbackInvalid?: any;
}

type InputPasswordBaseProps = InputPasswordProps & FormControlProps & Omit<React.InputHTMLAttributes<HTMLInputElement>, "size">;

const InputPassword: React.FC<InputPasswordBaseProps> = ({ className, feedbackValid, feedbackInvalid, ...inputProps }) => {
  const [showPassword, toggleShowPassword] = useState(false);
  const [value, setValue] = useState(inputProps.value || "");

  const b = styleBlock("input-password");

  function handleChange(e: React.ChangeEvent<any>) {
    setValue(e.target.value);
    if (inputProps.onChange) {
      inputProps.onChange(e);
    }
  }

  return (
    <div className={b.toString() + " " + className ?? ""}>
      <div className="position-relative">
        <Form.Control
          isInvalid={!!feedbackInvalid}
          {...inputProps}
          value={value}
          type={showPassword ? "text" : "password"}
          onChange={handleChange}
        />
        <div className="input-inner-element">
          <button
            tabIndex={-1}
            type="button"
            onClick={() => toggleShowPassword(!showPassword)}
            className="d-block border-0 p-0 m-0 bg-transparent cursor-pointer">
            <img src={showPassword ? EyeCrossedIcon : EyeIcon} className="d-block" />
          </button>
        </div>
      </div>
      {feedbackValid && <Form.Control.Feedback>{feedbackValid}</Form.Control.Feedback>}
      {feedbackInvalid && <Form.Control.Feedback type="invalid" dangerouslySetInnerHTML={{__html: feedbackInvalid}}/>}
    </div>
  );
};

export default InputPassword;
