import { ILocalizationService } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, ConductorOneChildActive } from "@frui.ts/screens";
import ReqNewReading from "entities/reqNewReading";
import { interfaces } from "inversify";
import { observable } from "mobx";
import ProductDetailContext from "models/productDetailContext";
import HistoryDetailPageViewModel from "./historyDetailPageViewModel";
import SingleSelfReadingViewModel from "./singleSelfReadingViewModel";
import BulkSelfReadingViewModel from "./bulkSelfReadingViewModel";
import { preventDefault } from "@frui.ts/views";

export default class ModalSelfReadingViewModel extends ConductorOneChildActive<
  SingleSelfReadingViewModel | BulkSelfReadingViewModel
> {
  static navigationName = "type";
  fullScreen = true;

  busyWatcher = new BusyWatcher();
  navigationName = ModalSelfReadingViewModel.navigationName;
  parent: HistoryDetailPageViewModel;
  @observable data: ReqNewReading;
  @observable successMessage?: string;
  @observable errorMessage?: string;
  @observable areHintsVisible = false;

  constructor(
    private productDetailContext: ProductDetailContext,
    public localization: ILocalizationService,
    private singleSelfReadingViewModelFactory: ReturnType<typeof SingleSelfReadingViewModel.Factory>,
    private bulkSelfReadingViewModelFactory: ReturnType<typeof BulkSelfReadingViewModel.Factory>
  ) {
    super();

    this.children.push(
      ...[
        singleSelfReadingViewModelFactory(this.productDetailContext),
        bulkSelfReadingViewModelFactory(this.productDetailContext),
      ]
    );
  }

  protected async onActivate() {
    await super.onActivate();
    if (!this.activeChild && this.children.length) {
      return this.tryActivateChild(this.children[0]);
    }
  }

  get navLinks() {
    return [
      {
        id: SingleSelfReadingViewModel.navigationName,
        title: this.translate("self_reading"),
        href: "#",
        onClick: preventDefault(() => this.activateSingle()),
      },
      {
        id: BulkSelfReadingViewModel.navigationName,
        title: this.translate("bulk_self_reading.title"),
        href: "#",
        onClick: preventDefault(() => this.activateBulk()),
      },
    ];
  }

  @bound activateSingle() {
    const vm = this.children.find(vm => vm.navigationName === SingleSelfReadingViewModel.navigationName);
    if (vm) {
      this.tryActivateChild(vm);
    }
  }

  @bound activateBulk() {
    const vm = this.children.find(vm => vm.navigationName === BulkSelfReadingViewModel.navigationName);
    if (vm) {
      this.tryActivateChild(vm);
    }
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`products.history.${key}`);
  }

  @bound translateGeneral(key: string) {
    return this.localization.translateGeneral(`general.${key}`);
  }

  @bound translateAttribute(key: string) {
    return this.localization.translateAttribute("reading", key);
  }

  protected findNavigationChild(navigationName: string) {
    if (navigationName === BulkSelfReadingViewModel.navigationName) {
      return this.bulkSelfReadingViewModelFactory(this.productDetailContext);
    }

    return this.singleSelfReadingViewModelFactory(this.productDetailContext);
  }

  static Factory({ container }: interfaces.Context) {
    return (productDetailContext: ProductDetailContext) => {
      return new ModalSelfReadingViewModel(
        productDetailContext,
        container.get("ILocalizationService"),
        container.get(SingleSelfReadingViewModel.Factory),
        container.get(BulkSelfReadingViewModel.Factory)
      );
    };
  }
}
