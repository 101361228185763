import { ConductorOneChildActive, IScreen, IChild, ScreenBase } from "@frui.ts/screens";
import { observable, IObservableArray, action } from "mobx";

export default abstract class RootViewModelBase<
  TChild extends IScreen & IChild,
  TAppended extends ScreenBase
> extends ConductorOneChildActive<TChild> {
  appendedViewModels: IObservableArray<TAppended> = observable.array([], { deep: false });

  @action
  appendViewModel(child: TAppended) {
    child.parent = this as any;
    this.appendedViewModels.push(child);
  }

  protected async onActivate() {
    await super.onActivate();
    for (const child of this.appendedViewModels) {
      await child.activate();
    }
  }

  protected async onDeactivate(isClosing: boolean) {
    await super.onDeactivate(isClosing);
    for (const child of this.appendedViewModels) {
      await child.deactivate(isClosing);
    }
  }

  @action
  closeChild(child: TChild | TAppended, forceClose = false): Promise<boolean> | boolean {
    return this.appendedViewModels.remove(child as any);
  }

  async navigate(subPath: string | undefined, params: any) {
    if (!subPath && this.appendedViewModels.length) {
      // navigating to the root should close all active appended view models
      for (const child of this.appendedViewModels.slice()) {
        await this.closeChild(child);
      }
    }

    await super.navigate(subPath, params);
  }

  async navigateToAppendedViewModel(viewModel: TAppended, subPath?: string, params?: any) {
    this.appendViewModel(viewModel);
    await viewModel.activate();
    await viewModel.navigate(subPath, params);
  }
}
