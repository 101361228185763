import { IEventBus } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import ManualPromise from "@frui.ts/helpers/dist/manualPromise";
import PartnerChannel from "entities/partnerChannel";
import type PartnerChannelType from "entities/partnerChannelType";
import SupplyPointListItem from "entities/supplyPointListItem";
import { computed, observable, runInAction } from "mobx";
import LoginRepository from "repositories/loginRepository";
import PartnersRepository from "repositories/partnersRepository";
import SupplyPointsRepository from "repositories/supplyPointsRepository";
import { AppendixesEvents, DataEvents, DownloadingEvents, GeneralEvents, RequestsEvents } from "./events";
import ServerCommunicationProvider from "./serverCommunicationProvider";
import UserContext from "./userContext";
import NotificationChannel from "models/notificationChannel";
import ProductCardsService from "./productCardsService";

export default class DataSyncService {
  private initializedScopes?: Set<PartnerChannelType>;
  private initializationPromise: ManualPromise;
  private financePromise: ManualPromise;
  private requestPromise: ManualPromise;

  @observable.ref supplyPoints: SupplyPointListItem[];

  constructor(
    private userContext: UserContext,
    private backend: ServerCommunicationProvider,
    private loginRepository: LoginRepository,
    private supplyPointsRepository: SupplyPointsRepository,
    private partnersRepository: PartnersRepository,
    private eventBus: IEventBus,
    private productCardsService: ProductCardsService
  ) {}

  async initialize() {
    this.initializedScopes = new Set<PartnerChannelType>();
    this.initializationPromise = new ManualPromise();
    this.financePromise = new ManualPromise();
    this.requestPromise = new ManualPromise();

    this.backend.start();

    if (process.env.REACT_APP_WAIT_FOR_DATA_INIT === "true") {
      await this.backend.subscribe("PartnerChannel", this.handlePartnerMessage);
      await this.backend.subscribe("Notification::NotificationChannel", this.handleNotificationMessage);
      await this.loginRepository.initUserData();
      await this.initializationPromise.promise;
    } else {
      await this.loginRepository.initUserData();
      await this.loadSupplyPointsList();
      await this.loadPartnerData();
      this.financePromise.resolve();
      this.requestPromise.resolve();
    }
    // load cards as soon as possible to let VMs initialize with the data
    await this.productCardsService.loadProductCards();

    if (process.env.NODE_ENV === "development") {
      (window as any).debugMessage = this.debugPartnerMessage;
    }
  }

  reset() {
    this.backend.stop();
  }

  waitForFinance() {
    return this.financePromise.promise;
  }

  waitForRequests() {
    return this.requestPromise.promise;
  }

  @bound
  private async handlePartnerMessage(data: PartnerChannel) {
    if (data.id !== this.userContext.activePartnerId) {
      return;
    }

    switch (data.type) {
      case "all":
        // Just pass to resolve all (no break statement)
        // there is 1 worker working on fetching data from SAP
        // on refresh worker could be in any state, so finances could
        // show up (and no dashboard) and we have to allow to entrance into portal
        this.financePromise.resolve();
        await Promise.all([this.loadSupplyPointsList(), this.loadPartnerData()]);
        break;

      case "finances":
        this.financePromise.resolve();
        break;

      case "dashboard":
        await this.loadPartnerData();
        await this.loadSupplyPointsList();
        break;

      case "base": // Base notification not goes for dashboard, it is used just for partner changes (email etc..)
        await this.loadPartnerData();
        break;

      case "requests":
        this.requestPromise.resolve();
        this.eventBus.publish(RequestsEvents.requestsChangedOnServer(undefined));
        break;

      case "appendixes":
        this.eventBus.publish(AppendixesEvents.appendixesChangedOnServer(undefined));
        this.eventBus.publish(AppendixesEvents.appendixesUpdated(undefined));
        break;

      case "accounts": // Account or contract data is changed - they are loaded in OPM
      case "supply_points": // Supply points or readings are updated
        await this.loadSupplyPointsList(); // TODO: I don't like this... really
        this.eventBus.publish(DataEvents.supplyPointsUpdated(undefined));
        break;

      case "current_user":
        this.eventBus.publish(GeneralEvents.sessionChanged(undefined));
        break;

      case "error":
        this.initializationPromise.reject("websocket failed");
        break;
    }

    if (this.initializedScopes) {
      this.initializedScopes.add(data.type);
      const isInitialized = this.supplyPoints && this.userContext.partners && this.userContext.activePartner;

      if (isInitialized) {
        this.initializationPromise.resolve();
        this.initializedScopes = undefined;
      }
    }
  }

  @bound
  private handleNotificationMessage(message: NotificationChannel) {
    if (message.refresh_notification) {
      this.eventBus.publish(DownloadingEvents.makeRefresh(undefined));
    }
  }

  @bound
  private debugPartnerMessage(type: PartnerChannelType) {
    this.handlePartnerMessage({
      id: this.userContext.activePartnerId ?? 0,
      type,
    });
  }

  private async loadPartnerData() {
    if (!this.userContext.activePartnerId) {
      return;
    }

    const [partnerDetail, partners] = await Promise.all([
      this.partnersRepository.getPartner(this.userContext.activePartnerId),
      this.partnersRepository.getPartners(),
    ]);

    if (partnerDetail.success && partners.success) {
      runInAction(() => {
        this.userContext.activePartner = partnerDetail.payload;
        this.eventBus.publish(DataEvents.activePartnerUpdated(undefined));
        this.userContext.partners = partners.payload;
      });
    }
  }

  private async loadSupplyPointsList() {
    if (!this.userContext.activePartnerId) {
      return;
    }

    const result = await this.supplyPointsRepository.getSupplyPoints(this.userContext.activePartnerId);
    if (result.success) {
      runInAction(() => (this.supplyPoints = result.payload));
      // this.eventBus.publish(DataEvents.supplyPointsUpdated(result.payload));
    }
  }

  @computed
  get postalAddresses() {
    return this.supplyPoints.map(x => x.postalAddress);
  }
}
