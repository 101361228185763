import BannerPreview from "entities/bannerPreview";
import React from "react";
import { getLinkButton } from "helpers/bannerHelper";
import {
  Banner as BannerInner,
  CrossSellBanner as CrossSellBannerInner,
  SmallBanner as SmallBannerInner,
} from "@emanprague/ppaskit";
import { ILocalizationService } from "@emanprague/shared-services";

interface BannerProps {
  banner: BannerPreview;
}

export const SmallBanner: React.FunctionComponent<BannerProps> = ({ banner }) => (
  <SmallBannerInner
    title={banner.title}
    htmlContent={banner.text1}
    link={banner.links?.length > 0 && getLinkButton(banner.links[0])}
    imageUrl={banner.file}
  />
);

export const LargeBanner: React.FunctionComponent<BannerProps> = ({ banner }) => (
  <BannerInner
    title={banner.title}
    htmlContent={banner.text1}
    link={banner.links.length > 0 && getLinkButton(banner.links[0])}
    imageUrl={banner.file}
  />
);

interface CrossSellBannerProps {
  banner: BannerPreview;
  localization: ILocalizationService;
  onAddSupplyPointClick: () => void;
  isPreview?: boolean;
}

export const CrossSellBanner: React.FunctionComponent<CrossSellBannerProps> = ({
  banner,
  localization,
  onAddSupplyPointClick,
  isPreview,
}) => {
  const { translateGeneral: tg } = localization;
  return (
    <CrossSellBannerInner
      title={banner.title}
      htmlContent1={banner.text1}
      htmlContent2={banner.text2}
      links={banner.links}
      // newSupplyPointButtonLabel={tg("general.new_place_button")}
      // newSupplyPointButtonOnClick={onAddSupplyPointClick}
      topCenterMessage={tg("general.cross_sell.top_center_message")}
      className={isPreview ? "banner-preview-grey" : ""}
    />
  );
};
