import SupplyPoint from "entities/supplyPoint";
import SupplyPointListItem from "entities/supplyPointListItem";
import { observable } from "mobx";
import CommodityType from "./commodityType";

export default class ProductDetailContext {
  get supplyPointId() {
    return this.supplyPointCore.id;
  }

  @observable.ref supplyPoint?: SupplyPoint;
  @observable commodityType: CommodityType;

  constructor(public supplyPointCore: SupplyPointListItem, public partnerId: number) {}
}
