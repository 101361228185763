import { ILocalizationService, INotificationService, SeverityLevel } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, ScreenBase, watchBusy } from "@frui.ts/screens";
import { observable, runInAction } from "mobx";
import UserContext from "services/userContext";
import CompetitionListItem from "entities/competitionListItem";
import PartnersRepository from "repositories/partnersRepository";
import ReqPressModalButtonChoice from "entities/reqPressModalButtonChoice";
import ReqPressModalButton from "entities/reqPressModalButton";

export default class CompetitionsViewModel extends ScreenBase {
  @observable competitions: CompetitionListItem[] = [];
  @observable agreement = false;
  @observable wasShowed = false;

  busyWatcher = new BusyWatcher();
  static navigationName = "competitionsModal";

  constructor(
    private userContext: UserContext,
    private repository: PartnersRepository,
    public localization: ILocalizationService,
    private notificationService: INotificationService
  ) {
    super();
  }

  async checkCompetitions() {
    await this.loadData();
    return !!this.competitions.length;
  }

  @bound
  @watchBusy
  private async loadData() {
    if (this.userContext.activePartnerId) {
      const result = await this.repository.getCompetitions(this.userContext.activePartnerId, { offset: 0, limit: 1000 });
      if (result.success) {
        runInAction(() => {
          this.competitions = result.payload[0];
        });
      }
    }
  }

  @bound
  @watchBusy
  async sendChoice(choice: ReqPressModalButtonChoice) {
    if (this.userContext.activePartnerId) {
      const requestData = new ReqPressModalButton();
      requestData.gdprConsent = this.agreement;
      requestData.choice = choice;

      const result = await this.repository.sendChoice(this.userContext.activePartnerId, this.competitions[0]?.id, requestData);

      if (result.success) {
        if (choice === "agree") {
          this.notificationService.addNotification(this.translate("success"), SeverityLevel.success);
        }
        this.requestClose();
      }
    }
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`profile.competitions.${key}`);
  }
}
