import { ILocalizationService } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { ScreenBase } from "@frui.ts/screens";
import { computed } from "mobx";
import SecurityService from "services/securityService";
import UserContext from "services/userContext";

export default class StatusViewModel extends ScreenBase {
  navigationName = "status";

  @computed get userName() {
    return this.userContext.user?.email;
  }

  @computed get partners() {
    return this.userContext.partners;
  }

  @computed get connectedPartners() {
    return this.partners.filter(item => item.id !== this.activePartnerId);
  }

  @computed get activePartnerId() {
    return this.userContext.activePartnerId;
  }

  get activePartner() {
    return this.userContext.activePartner;
  }

  constructor(
    private localization: ILocalizationService,
    private securityService: SecurityService,
    private userContext: UserContext
  ) {
    super();
    this.name = localization.translateGeneral("profile.title");
  }

  @bound
  changePartner(partnerId: number) {
    this.securityService.changeActivePartner(partnerId);
  }

  @bound logout() {
    return this.securityService.logout();
  }

  @bound unmorphPartner() {
    return this.securityService.unmorphPartner();
  }
}
