import { observable } from "mobx";
import { Transform } from "class-transformer";
import formatISO from "date-fns/formatISO";
import { Expose } from "class-transformer";
import type ReqProductCardCategory from "./reqProductCardCategory";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class ReqProductCard {
  @observable
  title!: string;

  @observable
  category!: ReqProductCardCategory;

  @observable
  order!: number;

  @observable
  @Expose({ name: "button_text" })
  @Transform(value => (value ? value : undefined), { toPlainOnly: true })
  buttonText?: string;

  @observable
  @Expose({ name: "button_url" })
  @Transform(value => (value ? value : undefined), { toPlainOnly: true })
  buttonUrl?: string;

  @observable
  text!: string;

  @observable
  @Transform(value => (value ? new Date(value) : undefined), { toClassOnly: true })
  @Transform(value => (value ? formatISO(value, { representation: "date" }) : undefined), { toPlainOnly: true })
  @Expose({ name: "valid_from" })
  validFrom?: Date;

  @observable
  @Transform(value => (value ? new Date(value) : undefined), { toClassOnly: true })
  @Transform(value => (value ? formatISO(value, { representation: "date" }) : undefined), { toPlainOnly: true })
  @Expose({ name: "valid_to" })
  validTo?: Date;

  @observable
  @Expose({ name: "if_electricity" })
  ifElectricity?: boolean;

  @observable
  @Expose({ name: "if_not_electricity" })
  ifNotElectricity?: boolean;

  @observable
  @Expose({ name: "if_gas" })
  ifGas?: boolean;

  @observable
  @Expose({ name: "if_not_gas" })
  ifNotGas?: boolean;

  @observable
  @Expose({ name: "if_household" })
  ifHousehold?: boolean;

  @observable
  @Expose({ name: "if_company" })
  ifCompany?: boolean;

  static ValidationRules = {
    title: { maxLength: 255, required: true },
    text: { required: true },
    order: { required: true, number: true },
    category: { required: true },
    validFrom: { date: true },
    validTo: { date: true },
  };
}
