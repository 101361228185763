import { observable } from "mobx";
import { Expose } from "class-transformer";
import { EntityValidationRules } from "services/validation/entityValidationRules";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class ReqChangePostalAddress {
  @observable
  @Expose({ name: "supply_point_ids" })
  supplyPointIds!: number[];

  /**
   * Existing address id
   */
  @observable
  @Expose({ name: "address_id" })
  addressId?: number;

  /**
   * Ruian ID from autocomplete
   */
  @observable
  @Expose({ name: "ruian_id" })
  ruianId?: number;

  @observable
  street?: string;

  @observable
  @Expose({ name: "house_number" })
  houseNumber?: string;

  @observable
  @Expose({ name: "street_number" })
  streetNumber?: string;

  @observable
  city?: string;

  @observable
  @Expose({ name: "city_part" })
  cityPart?: string;

  @observable
  zip?: string;

  @observable
  @Expose({ name: "country_id" })
  countryId?: number;

  static ValidationRules = {
    supplyPointIds: { required: true },
    addressId: { number: true },
    ruianId: { number: true },
    countryId: { number: true },
  };
}

const ReqChangePostalAddressValidationRules: EntityValidationRules<ReqChangePostalAddress> = {
  supplyPointIds: { required: true },
  houseNumber: { requiredIf: { condition: (item: ReqChangePostalAddress) => !item.ruianId && !item.addressId }, number: true },
  city: { requiredIf: { condition: (item: ReqChangePostalAddress) => !item.ruianId && !item.addressId } },
  zip: { requiredIf: { condition: (item: ReqChangePostalAddress) => !item.ruianId && !item.addressId } },
  countryId: { requiredIf: { condition: (item: ReqChangePostalAddress) => !item.ruianId && !item.addressId } },
};

export { ReqChangePostalAddressValidationRules };
