import { Form, Col, Button, Modal, HeaderRow, LoaderOverlay } from "@emanprague/ppaskit";
import { preventDefault, registerView, ViewComponent } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import React from "react";
import { Input } from "@frui.ts/bootstrap";
import DatePicker from "controls/twoway/DatePicker";
import ProductCardDetailViewModel from "viewModels/admin/productCards/productCardDetailViewModel";
import { DisplayCondition } from "components/DisplayCondition";
import Tooltip from "@emanprague/basekit/dist/Tooltip";
import iconInfo from "@emanprague/ppaskit/dist/assets/icons/Info.svg";

const productCardDetail: ViewComponent<ProductCardDetailViewModel> = observer(({ vm }) => {
  const ta = vm.localization.translateAttribute;

  return (
    <Modal centered show onHide={vm.requestClose} className="admin-modal">
      <Modal.Header closeButton>
        <HeaderRow title={vm.name} level={4} />
      </Modal.Header>
      <LoaderOverlay loading={vm.busyWatcher.isBusy}>
        <Modal.Body>
          <Form className="admin-form mt-n4" onSubmit={preventDefault(vm.confirmRequest)}>
            <Form.Row>
              <Form.Group as={Col} xs={12} className="text-center">
                <Form.Label className="mb-2 admin-form-header">
                  <strong>{vm.translate("category")}</strong>
                  {vm.suggestedCategory && (
                    <Tooltip
                      placement="right"
                      id="category-tooltip"
                      tooltip={vm.localization.translateGeneral("admin.product_cards.category_tooltip")}>
                      <img src={iconInfo} alt="" width={20} height={20} className="align-top" />
                    </Tooltip>
                  )}
                </Form.Label>
              </Form.Group>
              <Form.Group>
                {vm.categories.map(category => (
                  <Form.Check
                    custom
                    key={category.id}
                    id={`category${category.id}`}
                    value={category.id}
                    checked={vm.data.category === category.id}
                    type="radio"
                    label={category.name}
                    className="mb-1"
                    onChange={() => vm.onChangeCategory(category.id)}
                  />
                ))}
              </Form.Group>
              <Form.Group as={Col} xs={12}>
                <Form.Label>
                  {vm.translate("order")}
                  {vm.suggestedOrder && (
                    <Tooltip
                      placement="right"
                      id="order-tooltip"
                      tooltip={vm.localization.translateGeneral("admin.product_cards.order_tooltip")}>
                      <img src={iconInfo} alt="" width={20} height={20} className="align-top" />
                    </Tooltip>
                  )}
                </Form.Label>
                <Input type="number" target={vm.data} property="order" />
              </Form.Group>
              <Form.Group as={Col} xs={12}>
                <Form.Label>{vm.translate("header")}</Form.Label>
                <Input target={vm.data} property="title" />
              </Form.Group>
              <Form.Group as={Col} xs={12}>
                <Form.Label>{vm.translate("content")}</Form.Label>
                <Input {...{ as: "textarea", rows: 10 }} target={vm.data} property="text" />
              </Form.Group>
              <Form.Group as={Col} xs={12}>
                <Form.Label>{vm.translate("button_text")}</Form.Label>
                <Input target={vm.data} property="buttonText" />
              </Form.Group>
              <Form.Group as={Col} xs={12}>
                <Form.Label>{vm.translate("button_url")}</Form.Label>
                <Input target={vm.data} property="buttonUrl" />
              </Form.Group>

              <Form.Group as={Col} xs={12} className="text-center mt-3 mb-2">
                <Form.Label className="admin-form-header">
                  <strong>{ta("message", "validity")}</strong>
                </Form.Label>
              </Form.Group>
              <Form.Group as={Col} xs={6}>
                <Form.Label>
                  {vm.translateGeneral("valid_from")} <span className="text-muted">{vm.translateGeneral("not_mandatory")}</span>
                </Form.Label>
                <DatePicker
                  target={vm.data}
                  property="validFrom"
                  selectsStart
                  startDate={vm.data.validFrom}
                  endDate={vm.data.validTo}
                  maxDate={vm.data.validTo}
                />
              </Form.Group>
              <Form.Group as={Col} xs={6}>
                <Form.Label>
                  {vm.translateGeneral("valid_to")} <span className="text-muted">{vm.translateGeneral("not_mandatory")}</span>
                </Form.Label>
                <DatePicker
                  target={vm.data}
                  property="validTo"
                  selectsEnd
                  startDate={vm.data.validFrom}
                  endDate={vm.data.validTo}
                  minDate={vm.data.validFrom}
                />
              </Form.Group>

              {(vm.data.validFrom || vm.data.validTo) && (
                <Form.Group className="w-100 text-right">
                  <Button variant="secondary" size="sm" className="mr-1" onClick={vm.clearDates}>
                    {vm.translate("clear_date")}
                  </Button>
                </Form.Group>
              )}

              <Form.Group as={Col} xs={12} className="text-center mt-3 mb-3">
                <Form.Label className="admin-form-header">
                  <strong>{vm.translateConditions("title")}</strong>
                </Form.Label>
              </Form.Group>
              <DisplayCondition data={vm.data} translateConditions={vm.translateConditions} />
            </Form.Row>

            <Form.Row>
              <Form.Group>
                {vm.errorMessage && <Form.Control.Feedback type="invalid">{vm.errorMessage}</Form.Control.Feedback>}
              </Form.Group>
            </Form.Row>
          </Form>
        </Modal.Body>
      </LoaderOverlay>
      <Modal.Footer>
        <Button onClick={vm.requestClose} variant="secondary">
          {vm.translateGeneral("cancel_button")}
        </Button>
        <Button disabled={!vm.canConfirm} variant="primary" onClick={vm.confirmRequest} className="ml-1">
          {vm.translateGeneral(vm.isNewProductCard ? "add_button" : "confirm_changes_button")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
});

registerView(productCardDetail, ProductCardDetailViewModel);
