/* eslint-disable @typescript-eslint/no-throw-literal */
import { EnumsServiceBase } from "@emanprague/shared-services";
import CommodityType from "models/commodityType";
import Enumerations from "entities/enumerations";
import EnumsRepository from "repositories/enumsRepository";
import PartnerType from "models/partnerType";

export default class EnumsService extends EnumsServiceBase<Enumerations> {
  constructor(private repository: EnumsRepository) {
    super();
  }

  getCommodityType(commodityId: number): CommodityType | undefined {
    const commodity = this.getValue("commodities", commodityId);

    if (commodity?.value.startsWith("E")) {
      return CommodityType.Electricity;
    } else if (commodity?.value.startsWith("P")) {
      return CommodityType.Gas;
    } else {
      return undefined;
    }
  }

  getPartnerType(partnerTypeId: number): PartnerType | undefined {
    const partnerType = this.getValue("partnerTypes", partnerTypeId);
    const value = partnerType?.value ?? "0"; // Value would be a string

    switch (value) {
      case "1":
        return PartnerType.Household;
      case "2":
        return PartnerType.Business;
      default:
        return undefined;
    }
  }

  protected async loadContainer() {
    const data = await this.repository.getEnums();
    if (data.success) {
      return data.payload;
    }

    throw data.payload;
  }
}
