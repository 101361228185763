import { Transform, Type } from "class-transformer";
import formatISO from "date-fns/formatISO";
import { observable } from "mobx";
import ReminderExecutor from "./reminderExecutor";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class Reminder {
  id!: number;

  @observable
  code!: string;

  @observable
  name?: string;

  @observable
  amount!: number;

  @observable
  @Transform(value => (value ? new Date(value) : undefined), { toClassOnly: true })
  @Transform(value => (value ? formatISO(value, { representation: "date" }) : undefined), { toPlainOnly: true })
  date?: Date;

  /**
   * Url of file
   */
  @observable
  file?: string;

  @observable
  desc?: string;

  @observable
  @Type(() => ReminderExecutor)
  executor?: ReminderExecutor;

  static ValidationRules = {
    id: { required: true, number: true },
    code: { required: true },
    amount: { required: true, number: true },
    date: { date: true },
  };
}
