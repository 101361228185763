import { IEventBus } from "@emanprague/shared-services";
import { bound, ManualPromise } from "@frui.ts/helpers";
import ContractRepository from "repositories/contractRepository";
import { AppendixesEvents } from "./events";
import { observable, runInAction } from "mobx";
import UserContext from "./userContext";
import NewAppendixListItemGlobal from "entities/newAppendixListItemGlobal";

export default class AppendixesService {
  private waitForRefreshPromise?: ManualPromise;
  @observable.ref newAppendixes: NewAppendixListItemGlobal[] = [];
  @observable newAppendixesCount = 0;

  constructor(private repository: ContractRepository, private eventBus: IEventBus, private userContext: UserContext) {
    eventBus.subscribe(AppendixesEvents.appendixesChangedOnServer, () => {
      this.unblockLoading();
      this.loadGlobalNewAppendixes();
    });
    eventBus.subscribe(AppendixesEvents.appendixAccepted, () => {
      this.blockLoading();
      this.eventBus.publish(AppendixesEvents.appendixesUpdated(undefined));
    });
  }

  @bound
  async initialize() {
    await this.loadGlobalNewAppendixes();
  }

  private async loadGlobalNewAppendixes() {
    const partnerId = this.userContext.activePartnerId;
    if (!partnerId) {
      return;
    }

    const response = await this.repository.getNewGlobalAppendixes(partnerId);
    if (response.success) {
      runInAction(() => {
        this.newAppendixes = response.payload.appendixes;
        this.newAppendixesCount = this.newAppendixes.length;
      });
      this.eventBus.publish(AppendixesEvents.newAppendixesLoaded(undefined));
    }
  }

  @bound
  private blockLoading() {
    this.waitForRefreshPromise = this.waitForRefreshPromise ?? new ManualPromise();
  }

  @bound
  private unblockLoading() {
    this.waitForRefreshPromise?.resolve();
    this.waitForRefreshPromise = undefined;
  }

  async getNewAppendixes(partnerId: number, supplyPointId: number) {
    await this.waitForRefreshPromise?.promise;
    return await this.repository.getNewAppendixes(partnerId, supplyPointId);
  }

  async getAppendixes(partnerId: number, supplyPointId: number) {
    await this.waitForRefreshPromise?.promise;
    return await this.repository.getAppendixes(partnerId, supplyPointId);
  }
}
