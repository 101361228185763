import { IEventBus, ILocalizationService, INotificationService } from "@emanprague/shared-services";
import { bound, IDisposable } from "@frui.ts/helpers";
import { Router, ScreenBase } from "@frui.ts/screens";
import { action } from "mobx";
import SecurityService from "services/securityService";
import UserContext from "services/userContext";
import RootViewModelBase from "./rootViewModelBase";
import ToastViewModel from "./toastViewModel";
import { IAdminModule } from "./types";
import AdminsPageViewModel from "./admin/adminsPageViewModel";
import ConfirmationService from "services/confirmationService";
import { GeneralEvents } from "services/events";
import RefreshCredentialsViewModel from "./profile/refreshCredentialsViewModel";

@Router.registerRoute({ route: "", children: [AdminsPageViewModel] })
export default class AdminRootViewModel extends RootViewModelBase<IAdminModule, ScreenBase> {
  private eventHandlers: IDisposable[];

  constructor(
    modules: IAdminModule[],

    private userContext: UserContext,
    private notificationService: INotificationService,
    private securityService: SecurityService,
    public localization: ILocalizationService,
    public confirmationService: ConfirmationService,
    eventBus: IEventBus,
    private refreshCredentialsFactory: () => RefreshCredentialsViewModel
  ) {
    super();
    this.setModules(modules);

    this.eventHandlers = [eventBus.subscribe(GeneralEvents.unauthorized, this.activateRefreshCredentials)];
  }

  protected onInitialize() {
    if (!this.activeChild && this.children.length) {
      return this.tryActivateChild(this.children[0]);
    }
  }

  protected async onDeactivate(isClosing: boolean) {
    await super.onDeactivate(isClosing);

    if (isClosing) {
      this.eventHandlers?.forEach(x => x.dispose());
    }
  }

  canShowSection(section: string) {
    return this.userContext.user?.adminAbilities?.includes(section);
  }

  @action
  public setModules(modules: IAdminModule[]) {
    const filtered_modules = modules
      .filter(item => this.canShowSection(item.navigationName))
      .sort((a, b) => a.orderIndex - b.orderIndex);
    this.children.push(...filtered_modules);
  }

  @bound
  public activateFirstChild() {
    return this.tryActivateChild(this.children[0]);
  }

  @bound
  activateRefreshCredentials() {
    if (!this.appendedViewModels.some(x => x instanceof RefreshCredentialsViewModel)) {
      this.appendViewModel(this.refreshCredentialsFactory());
    }
  }

  @bound logout() {
    return this.securityService.logout();
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`admin.${key}`);
  }

  @bound translateGeneral(key: string) {
    return this.localization.translateGeneral(`general.${key}`);
  }

  get userName() {
    return this.userContext.user?.email;
  }

  get toasts() {
    const results: ToastViewModel[] = [];

    const service = this.notificationService;
    service.notifications.forEach((notification, key) =>
      results.push(new ToastViewModel(key, notification.message, notification.severity, () => service.removeNotification(key)))
    );

    return results;
  }
}
