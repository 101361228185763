import { ILocalizationService } from "@emanprague/shared-services";
import { SortingDirection } from "@frui.ts/data";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, Router, watchBusy } from "@frui.ts/screens";
import endOfMonth from "date-fns/endOfMonth";
import AdvanceListItem from "entities/advanceListItem";
import AdvanceListItemState from "entities/advanceListItemState";
import ReqExportType from "entities/reqExportType";
import { createSupplyPointsWithIcons } from "helpers/supplyPointHelper";
import { action, computed, observable, runInAction } from "mobx";
import AdvancesFilter from "models/advancesFilter";
import AdvancesRepository from "repositories/advancesRepository";
import DataSyncService from "services/dataSyncService";
import EnumsService from "services/enumsService";
import UserContext from "services/userContext";
import ContinuousListViewModelBase from "viewModels/continuousListViewModelBase";
import AdvanceChangeViewModel from "./advanceChangeViewModel";
import AdvanceDetailViewModel from "./advanceDetailViewModel";
import { IFinancePage } from "./types";

const navigationName = "advances";
export const defaultAmountRange = [0, 10000];

@Router.registerRoute({ name: Router.Self, route: `${navigationName}` })
export default class AdvancesPageViewModel
  extends ContinuousListViewModelBase<AdvanceListItem, AdvancesFilter, AdvanceDetailViewModel | AdvanceChangeViewModel>
  implements IFinancePage
{
  navigationName = navigationName;
  orderIndex = 1;
  busyWatcher = new BusyWatcher();

  @observable balance = 0;
  @observable displayPastAdvancesOnly: boolean;
  @observable stateTypes: { id: AdvanceListItemState | "all"; name: string }[];
  @observable selectedState?: string;

  constructor(
    public localization: ILocalizationService,
    private userContext: UserContext,
    private dataService: DataSyncService,
    private repository: AdvancesRepository,
    public enumsService: EnumsService,
    public router: Router,
    private detailFactory: ReturnType<typeof AdvanceDetailViewModel.Factory>,
    private advanceChangeFactory: ReturnType<typeof AdvanceChangeViewModel.Factory>
  ) {
    super();

    this.name = this.translate("title");

    this.stateTypes = [
      { id: "all", name: this.translateGeneral("filter_all") },
      { id: "paid", name: this.translateGeneral("filter_paid") },
      { id: "before_payment", name: this.translateGeneral("filter_before_due_date") },
      { id: "unpaid", name: this.translateGeneral("filter_after_due_date") },
      { id: "payment_in_process", name: this.translateGeneral("filter_payment_in_process") },
    ];

    // default sort by pay date descending
    this.pagingFilter.sortColumn = "pay_date";
    this.pagingFilter.sortDirection = SortingDirection.Descending;
  }

  @bound
  navigate(
    subPath: string | undefined,
    params: { filter?: AdvanceListItemState; change?: boolean; disablePastAdvancesOnly?: boolean }
  ): Promise<void> {
    if (params.filter) {
      runInAction(() => (this.filter.state = [params.filter] as AdvanceListItemState[]));
      this.selectedState = this.stateTypes[3].id; // unpaid
      this.applyFilterAndLoad();
    } else if (!this.items) {
      this.loadData().then(() => !params.disablePastAdvancesOnly && this.verifyIfDisplayPastAdvancesOnly());
    }
    return super.navigate(subPath, params);
  }

  @bound onInitialize() {
    this.initializeStateFilter();
    this.navigate(undefined, {});
  }

  @bound
  @watchBusy
  async loadData() {
    await this.dataService.waitForFinance();
    if (this.userContext.activePartnerId) {
      const advancesResult = await this.repository.getAdvances(this.userContext.activePartnerId, this.pagingFilter, this.filter);
      if (advancesResult.success) {
        this.setData(advancesResult.payload);
      }
    }
  }

  @bound
  verifyIfDisplayPastAdvancesOnly() {
    if (this.displayPastAdvancesOnly === undefined && this.dataService.supplyPoints.length > 1) {
      runInAction(() => (this.displayPastAdvancesOnly = true));
      this.displayPastAdvancesOnlyChanged();
    }
  }

  @action
  openDetail(item: AdvanceListItem) {
    if (this.userContext.activePartnerId) {
      const detail = this.detailFactory(item, this.userContext.activePartnerId, this.activateAdvanceChange);
      return this.tryActivateChild(detail);
    }
  }

  @bound
  activateAdvanceChange(partnerId: number, supplyPointId: number) {
    return this.tryActivateChild(this.advanceChangeFactory(partnerId, supplyPointId));
  }

  @action.bound
  displayPastAdvancesOnlyChanged() {
    if (this.displayPastAdvancesOnly) {
      this.filter.dueDateTo = endOfMonth(new Date());
    } else {
      this.filter.dueDateTo = undefined;
    }
    this.applyFilterAndLoad();
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected resetFilterValues(filter: AdvancesFilter) {}

  protected createFilter() {
    return new AdvancesFilter();
  }

  @action.bound
  initializeStateFilter() {
    this.selectedState = this.stateTypes[0].id;
  }

  @action.bound
  applyStateFilter() {
    if (this.selectedState !== "all") {
      this.filter.state = [this.selectedState] as AdvanceListItemState[];
    } else {
      this.filter.state = undefined;
    }
    this.applyFilterAndLoad();
  }

  @action.bound
  applySwitchStateFilter(...state: AdvanceListItemState[]) {
    this.filter.state = state;
    this.selectedState = state[0] === "paid" ? "paid" : undefined;
    this.applyFilterAndLoad();
  }

  @action.bound
  clearFilter(...properties: (keyof AdvancesFilter)[]) {
    properties.forEach(property => {
      this.filter[property] = undefined;
    });
    this.applyFilterAndLoad();
  }

  get switchStateActiveId() {
    if (this.filter.state?.length === 2) {
      if (this.filter.state.includes("paid") && this.filter.state.includes("payment_in_process")) {
        return "paid";
      } else if (this.filter.state.includes("unpaid") && this.filter.state.includes("before_payment")) {
        return "unpaid";
      } else {
        return "";
      }
    }
    return "all";
  }

  get supplyPoints() {
    return createSupplyPointsWithIcons(this.enumsService, this.dataService.supplyPoints);
  }

  @computed
  get amountRange() {
    return [this.filter.minAmount ?? defaultAmountRange[0], this.filter.maxAmount ?? defaultAmountRange[1]];
  }
  set amountRange([min, max]: [number, number]) {
    this.filter.minAmount = min === defaultAmountRange[0] ? undefined : min;
    this.filter.maxAmount = max === defaultAmountRange[1] ? undefined : max;
  }

  get canDownload() {
    return this.selectedItems.size > 0;
  }

  @action.bound
  async addToDownloadQueue(type: ReqExportType) {
    if (this.userContext.activePartnerId) {
      const ids: number[] = [];
      this.selectedItems.forEach(item => {
        ids.push(item.id);
      });

      const result = await this.repository.exportAdvances(this.userContext.activePartnerId, { ids, type });
      if (result.success) {
        this.selectedItems.clear();
      }
    }
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`finance.advances_page.${key}`);
  }

  @bound translateGeneral(key: string) {
    return this.localization.translateGeneral(`general.${key}`);
  }
}
