import { observable } from "mobx";
import { Type } from "class-transformer";
import ReadingChartDataReadingsItem from "./readingChartDataReadingsItem";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class ReadingChartData {
  @observable
  total?: number;

  @observable
  @Type(() => ReadingChartDataReadingsItem)
  data!: ReadingChartDataReadingsItem[];

  static ValidationRules = {
    total: { number: true },
    data: { required: true },
  };
}
