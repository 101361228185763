import { observable } from "mobx";
import { Expose } from "class-transformer";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class ReqTransferSupplyPointRequestAddress {
  /**
   * @example
   * Kolmá
   */
  @observable
  street?: string;

  /**
   * @example
   * 1200
   */
  @observable
  @Expose({ name: "house_number" })
  houseNumber!: string;

  /**
   * @example
   * 12a
   */
  @observable
  @Expose({ name: "street_number" })
  streetNumber?: string;

  /**
   * @example
   * Horní dolní
   */
  @observable
  city!: string;

  /**
   * @example
   * Horní
   */
  @observable
  @Expose({ name: "city_part" })
  cityPart?: string;

  /**
   * Enumeration::Country
   */
  @observable
  @Expose({ name: "country_id" })
  countryId?: number;

  /**
   * @example
   * 100 00
   */
  @observable
  zip!: string;

  static ValidationRules = {
    houseNumber: { required: true },
    city: { required: true },
    countryId: { number: true },
    zip: { required: true },
  };
}
