import { Expose, Transform, Type } from "class-transformer";
import formatISO from "date-fns/formatISO";
import { observable } from "mobx";
import type RequestState from "./requestState";
import RequestStatusesItem from "./requestStatusesItem";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class Request {
  /**
   * @example
   * 200
   */
  id!: number;

  /**
   * @example
   * 123456789
   */
  @observable
  @Expose({ name: "external_id" })
  externalId!: string;

  /**
   * @example
   * Změna názvu společnosti
   */
  @observable
  description!: string;

  /**
   * @example
   * Je nám líto, zadané údaje se neshodují.
   */
  @observable
  information?: string;

  @observable
  @Expose({ name: "original_inquiry" })
  originalInquiry?: string;

  @observable
  state!: RequestState;

  @observable
  @Expose({ name: "info_old" })
  infoOld?: string;

  @observable
  @Expose({ name: "info_new" })
  infoNew?: string;

  @observable
  @Type(() => RequestStatusesItem)
  statuses?: RequestStatusesItem[];

  /**
   * @example
   * 2020-01-01
   */
  @observable
  @Transform(value => (value ? new Date(value) : undefined), { toClassOnly: true })
  @Transform(value => (value ? formatISO(value, { representation: "date" }) : undefined), { toPlainOnly: true })
  @Expose({ name: "updated_at" })
  updatedAt?: Date;

  /**
   * @example
   * 2020-01-01
   */
  @observable
  @Transform(value => (value ? new Date(value) : undefined), { toClassOnly: true })
  @Transform(value => (value ? formatISO(value, { representation: "date" }) : undefined), { toPlainOnly: true })
  @Expose({ name: "created_at" })
  createdAt?: Date;

  /**
   * @example
   * 2020-01-01
   */
  @observable
  @Transform(value => (value ? new Date(value) : undefined), { toClassOnly: true })
  @Transform(value => (value ? formatISO(value, { representation: "date" }) : undefined), { toPlainOnly: true })
  @Expose({ name: "external_updated_at" })
  externalUpdatedAt?: Date;

  /**
   * @example
   * 2020-01-01
   */
  @observable
  @Transform(value => (value ? new Date(value) : undefined), { toClassOnly: true })
  @Transform(value => (value ? formatISO(value, { representation: "date" }) : undefined), { toPlainOnly: true })
  @Expose({ name: "external_created_at" })
  externalCreatedAt?: Date;

  /**
   * Path to requests file
   */
  @observable
  file?: string;

  /**
   * Is file available
   */
  @observable
  @Expose({ name: "file_available" })
  fileAvailable?: boolean;

  static ValidationRules = {
    id: { required: true, number: true },
    externalId: { required: true },
    description: { required: true },
    state: { required: true },
    updatedAt: { date: true },
    createdAt: { date: true },
    externalUpdatedAt: { date: true },
    externalCreatedAt: { date: true },
  };
}
