import React, { useState, useEffect } from "react";
import { Bar as BarChart } from "react-chartjs-2";
import { ChartOptions } from "chart.js";
import isEqual from "lodash/isEqual";

require("./RoundedBars.js");

export type RoundedChartOptions = ChartOptions | { cornerRadius: number };
type NumbersDataset = Chart.ChartDataSets & { data: number[] };

export interface GraphProps {
  className?: string;
  labels: string[];
  datasets: NumbersDataset[];
}
const Graph: React.FunctionComponent<GraphProps> = ({ className, labels, datasets, ...props }) => {
  const [innerLabels, setInnerLabels] = useState<string[] | string[][]>(labels);
  const [areLabelsSplit, setAreLabelsSplit] = useState<boolean>(false);
  const [lastResizedWidth, setLastResizedWidth] = useState<number>(0);

  const splitLabels = (lastWidth: number) => {
    const newLabels = labels.map((item: string) => {
      const splitArray = item.split("-");
      if (splitArray.length > 1) {
        splitArray[0] += "-";
      }
      return splitArray.map(item => item.trim());
    });
    if (!isEqual(innerLabels, newLabels)) {
      setInnerLabels(newLabels);
      setAreLabelsSplit(true);
      setLastResizedWidth(lastWidth);
    }
  };

  const restoreLabels = (lastWidth: number) => {
    if (!isEqual(innerLabels, labels)) {
      setInnerLabels(labels);
      setAreLabelsSplit(false);
      setLastResizedWidth(lastWidth);
    }
  };

  useEffect(() => (areLabelsSplit ? splitLabels(0) : restoreLabels(0)), [labels]);

  const options: RoundedChartOptions = {
    title: { display: false },
    legend: {
      display: datasets.length > 1,
      align: "end",
      reverse: true,
      labels: {
        fontColor: "#00264C",
        fontSize: 13,
        fontStyle: "600",
        boxWidth: 12,
        padding: 25,
      },
    },
    tooltips: { enabled: false },
    events: [],
    hover: { mode: undefined },
    cornerRadius: 5,
    showLines: false,
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [
        {
          ticks: {
            maxRotation: 0,
          },
          gridLines: { display: false },
          stacked: true,
          afterUpdate: (scale: any) => {
            const columnWidth = scale.maxWidth / scale.ticks.length;
            const isTooWide = scale.longestLabelWidth > columnWidth * 0.95;
            const isTooNarrow = scale.longestLabelWidth < columnWidth / 2.05;
            const shouldBeResized = scale.maxWidth !== lastResizedWidth;

            setTimeout(() => {
              if (shouldBeResized && isTooWide) {
                splitLabels(scale.maxWidth);
              } else if (isTooNarrow) {
                restoreLabels(scale.maxWidth);
              }
            }, 1);
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            drawTicks: false,
            lineWidth: 1,
            color: "#DFE0E2",
            zeroLineColor: "#DFE0E2",
            drawBorder: false,
          },
          ticks: {
            beginAtZero: true,
            padding: 10,
          },
          stacked: true,
        },
      ],
    },
  };

  const graphData = (canvas: HTMLCanvasElement) => {
    const ctx = canvas.getContext("2d");
    const offsetAxisTop = 10;
    const offsetAxisBottom = 32;
    const offsetTop = canvas.height - offsetAxisBottom;

    const dataNumbers = datasets[0].data as number[]; // @TODO: Why number[] is needed when typed above?

    const maxValue = Math.max(...dataNumbers);

    let colors = [];
    if (datasets.length === 1 && ctx) {
      colors = datasets[0].data.map((val: number) => {
        const offsetRatio = maxValue !== 0 ? val / maxValue : maxValue;

        const gradient = ctx.createLinearGradient(0, offsetAxisTop + (offsetTop - offsetTop * offsetRatio), 0, offsetTop);
        // In a case that further development will be needed, I'm leaving this here just for better debugging of gradient start/stop postition...
        // gradient.addColorStop(0, "red");
        // gradient.addColorStop(0.1, "red");
        // gradient.addColorStop(0.11, "blue");
        // gradient.addColorStop(0.89, "blue");
        // gradient.addColorStop(0.9, "red");
        // gradient.addColorStop(1, "red");
        gradient.addColorStop(0, "#00C34C");
        gradient.addColorStop(1, "#D1FFD8");
        return gradient;
      });
    } else {
      colors = ["#00264C", "#00C34C"];
    }

    const datasetDefault = {
      backgroundColor: colors,
      borderWidth: 0,
      barPercentage: 1,
      maxBarThickness: 140,
    };

    const chartDataset = [];
    if (datasets.length === 1) {
      chartDataset.push({ ...datasetDefault, ...datasets[0] });
    } else {
      // Following ignores are due to bad typing which is currently "string[] | CanvasGradient[]", but should be "string | CanvasGradient[]" by Chart.js documentation
      // @ts-ignore
      datasetDefault.backgroundColor = colors[0];
      chartDataset.push({ ...datasetDefault, ...datasets[0], borderSkipped: "top" as const }); // borderSkipped === "top" is here used for disable border-radius generation for bottom part of bar
      // @ts-ignore
      datasetDefault.backgroundColor = colors[1] as string;
      chartDataset.push({ ...datasetDefault, ...datasets[1] });
    }

    return {
      labels: innerLabels,
      datasets: chartDataset,
    };
  };

  return (
    <div className={className}>
      <BarChart width={450} height={260} data={graphData as any} options={options as any} />
    </div>
  );
};

export default Graph;
