import SimplePortalSettings from "entities/simplePortalSettings";
import PortalSettings from "entities/portalSettings";
import RepositoryBase from "./repositoryBase";

export default class PortalSettingsRepository extends RepositoryBase {
  getPortalSettings() {
    return this.callApi(api => api.all("portal_settings").getEntity(PortalSettings));
  }

  updatePortalSettings(data: SimplePortalSettings) {
    return this.callApi(api => api.all(`admin/portal_settings/${data.id}`).putEntity(data, SimplePortalSettings));
  }
}
