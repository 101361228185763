import { Form } from "@emanprague/ppaskit";
import { Check } from "@frui.ts/bootstrap";
import React from "react";

interface displayConditionType {
  ifElectricity?: boolean;
  ifNotElectricity?: boolean;
  ifGas?: boolean;
  ifNotGas?: boolean;
  ifHousehold?: boolean;
  ifCompany?: boolean;
}

export interface DisplayConditionProps {
  data: displayConditionType;
  translateConditions: (code: string) => string;
}

export const DisplayCondition: React.FunctionComponent<DisplayConditionProps> = ({ data, translateConditions }) => {
  return (
    <Form.Group>
      <Check custom target={data} property="ifElectricity" label={translateConditions("electricity")} />
      <Check custom target={data} property="ifNotElectricity" label={translateConditions("noElectricity")} />
      <Check custom target={data} property="ifGas" label={translateConditions("gas")} />
      <Check custom target={data} property="ifNotGas" label={translateConditions("noGas")} />
      <Check custom target={data} property="ifHousehold" label={translateConditions("household")} />
      <Check custom target={data} property="ifCompany" label={translateConditions("company")} />
    </Form.Group>
  );
};
