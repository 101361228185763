import { observable } from "mobx";
import { Transform } from "class-transformer";
import formatISO from "date-fns/formatISO";
import { Expose } from "class-transformer";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class EnumRate {
  id!: number;

  @observable
  value!: string;

  @observable
  desc!: string;

  @observable
  @Expose({ name: "secondary_tariff" })
  secondaryTariff?: boolean;

  @observable
  @Expose({ name: "commodity_id" })
  commodityId?: number;

  @observable
  @Expose({ name: "partner_type_id" })
  partnerTypeId!: number;

  @observable
  @Expose({ name: "category_id" })
  categoryId?: number;

  @observable
  @Expose({ name: "circuit_breaker_ids" })
  circuitBreakerIds?: number[];

  @observable
  @Transform(value => (value ? new Date(value) : undefined), { toClassOnly: true })
  @Transform(value => (value ? formatISO(value, { representation: "date" }) : undefined), { toPlainOnly: true })
  @Expose({ name: "valid_from" })
  validFrom!: Date;

  @observable
  @Transform(value => (value ? new Date(value) : undefined), { toClassOnly: true })
  @Transform(value => (value ? formatISO(value, { representation: "date" }) : undefined), { toPlainOnly: true })
  @Expose({ name: "valid_to" })
  validTo?: Date;

  /**
   * Allow to choose?
   */
  @observable
  active?: boolean;

  static ValidationRules = {
    id: { required: true, number: true },
    value: { required: true },
    desc: { required: true },
    commodityId: { number: true },
    partnerTypeId: { required: true, number: true },
    categoryId: { number: true },
    validFrom: { required: true, date: true },
    validTo: { date: true },
  };
}
