import { LoaderOverlay, Modal } from "@emanprague/ppaskit";
import { registerView, ViewComponent } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import React from "react";
import { HeaderRow } from "@emanprague/ppaskit";
import BannerPreviewViewModel from "viewModels/admin/bannerPreviewViewModel";
import { CrossSellBanner, LargeBanner, SmallBanner } from "controls/banner";

const bannerPreview: ViewComponent<BannerPreviewViewModel> = observer(({ vm }) => (
  <Modal
    centered={true}
    show={true}
    onHide={vm.requestClose}
    className={"banner-preview-modal-" + (vm.banner?.type === "small" ? "small" : "large")}>
    <LoaderOverlay loading={vm.busyWatcher.isBusy}>
      <Modal.Header closeButton>
        <HeaderRow level={4} title={vm.translate("preview")} />
      </Modal.Header>
      <Modal.Body>
        {vm.banner?.type === "small" && <SmallBanner banner={vm.banner} />}
        {vm.banner?.type === "large" && <LargeBanner banner={vm.banner} />}
        {vm.banner?.type === "cross_sell" && (
          <CrossSellBanner
            banner={vm.banner}
            localization={vm.localization}
            onAddSupplyPointClick={vm.addSupplyPoint}
            isPreview
          />
        )}
      </Modal.Body>
    </LoaderOverlay>
  </Modal>
));

registerView(bannerPreview, BannerPreviewViewModel);
