import React from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { styleBlock } from "../";

interface StepperProps {
  step: number;
  steps: Array<string>;
  className?: string;
}

export default class Stepper extends React.PureComponent<StepperProps> {
  render() {
    const { step, steps, className } = this.props;

    const classNames = styleBlock("stepper");
    const cn = classNames({}).mix([className]);

    const stepsCount = Object.keys(steps).length;

    return (
      <div className={cn.toString()}>
        <Row noGutters className="justify-content-center">
          {steps.map((curstep, index) => {
            const isStepPassed = step > index + 1;
            const isStepActive = index + 1 === step;
            return (
              <Col
                xs="auto"
                key={`step-${curstep}`}
                className={"step" + (isStepPassed ? " step-passed" : "") + (isStepActive ? "  step-active" : "")}>
                <span className="step-circle">
                  <span className="step-value">{index + 1}</span>
                </span>
                <span className="step-total">/{stepsCount} –&nbsp;</span>
                <span className="step-name">{curstep}</span>
              </Col>
            );
          })}
        </Row>
      </div>
    );
  }
}
