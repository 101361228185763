import { registerView, ViewComponent } from "@frui.ts/views";
import SupplyPointsList from "controls/supplyPointsList";
import { observer } from "mobx-react-lite";
import React from "react";
import OverviewPageViewModel from "viewModels/dashboard/widgets/supplyPointsViewModel";

const overviewPageView: ViewComponent<OverviewPageViewModel> = observer(({ vm }) => (
  <SupplyPointsList
    supplyPointsByType={vm.supplyPointsByType}
    localization={vm.localization}
    router={vm.router}
    newSupplyPointLink={vm.newSupplyPointLink}
    limitOfDisplayedSupplyPoints={5}
  />
));

registerView(overviewPageView, OverviewPageViewModel);
