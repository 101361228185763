import React, { useState } from "react";
import { styleBlock } from "..";
import iconCheckBox from "../assets/icons/Checkbox.svg";
import iconCheckBoxSelected from "../assets/icons/CheckboxSelected.svg";
import iconCheckSelected from "../assets/icons/CheckSelected.svg";

export interface PaymentMethodListItemProps {
  className?: string;
  selected?: boolean;
  onClick?: any;
  label?: string;
  imgUrl?: string;
}

const style = styleBlock("payment-method-list--item");

const PaymentMethodListItem: React.FunctionComponent<PaymentMethodListItemProps> = ({
  className,
  selected,
  onClick,
  label,
  imgUrl,
}) => {
  const [isHover, setIsHover] = useState<boolean>(false);
  const getCheckboxIcon = () => {
    if (selected) {
      return iconCheckSelected;
    } else {
      return isHover ? iconCheckBoxSelected : iconCheckBox;
    }
  };

  return (
    <div
      className={style.mix(selected && "selected", className)}
      onClick={onClick}
      onMouseOver={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}>
      <span className={style("check")}>
        <img width={22} height={22} src={getCheckboxIcon()} alt="" />
      </span>
      <div className="w-100 d-flex align-items-center">
        <div className={style("image")}>{imgUrl && <img className={style("icon")} src={imgUrl} alt="" />}</div>
        <div className={style("inner")}>
          <div className={style("title")}>{label}</div>
        </div>
      </div>
    </div>
  );
};

export default PaymentMethodListItem;
