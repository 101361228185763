import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { FormControlProps } from "react-bootstrap/FormControl";
import { styleBlock } from "../";

type InputSearchProps = { transparent?: boolean } & FormControlProps & Omit<React.InputHTMLAttributes<HTMLInputElement>, "size">;

const InputSearch: React.FC<InputSearchProps> = ({ className, transparent, ...inputProps }) => {
  const b = styleBlock("search");

  const [value, setValue] = useState(inputProps.value || "");

  function handleChange(e: React.ChangeEvent<any>) {
    setValue(e.target.value);
    if (inputProps.onChange) {
      inputProps.onChange(e);
    }
  }

  function clearValue(e: React.ChangeEvent<any>) {
    e.target.value = "";
    handleChange(e);
  }

  const { size } = inputProps;
  const crossTopMargin = size === "sm" ? "mt-0" : size === undefined ? "mt-1" : "mt-2";

  return (
    <div className={b.toString() + " position-relative " + className}>
      <Form.Control
        type="text"
        className={"with-icon icon-search " + (transparent ? "transparent-background" : "")}
        {...inputProps}
        value={value}
        onChange={handleChange}
      />
      <div className={crossTopMargin + " input-inner-element" + (value ? " visible" : "")}>
        <button onClick={clearValue} type="button" className="d-block border-0 p-0 m-0" />
      </div>
    </div>
  );
};

export default InputSearch;
