import { observable } from "mobx";
import { Expose } from "class-transformer";
import merge from "lodash/merge";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class ReqCreateAdminUser {
  /**
   * Firstname of the admin user
   */
  @observable
  @Expose({ name: "first_name" })
  firstName!: string;

  /**
   * Lastname of the admin user
   */
  @observable
  @Expose({ name: "last_name" })
  lastName!: string;

  /**
   * E-mail of the admin user
   */
  @observable
  email!: string;

  /**
   * Enumeration::UserRole
   */
  @observable
  role!: string;

  static ValidationRules = {
    firstName: { required: true },
    lastName: { required: true },
    email: { required: true },
    role: { required: true },
  };
}

merge(ReqCreateAdminUser.ValidationRules, { email: { isEmail: true } });
