import { Expose } from "class-transformer";
import RequestState from "entities/requestState";
import { observable } from "mobx";

export default class RequestsFilter {
  @observable
  @Expose({ name: "q" })
  search?: string;

  @observable
  @Expose({ name: "filters[state]" })
  state?: RequestState;

  @observable
  @Expose({ name: "filters[form_type_id]" })
  type?: string;

  @observable
  @Expose({ name: "filters[external_created_at_from]" })
  createdAt?: string;

  @observable
  @Expose({ name: "filters[external_updated_at_to" })
  updatedAt?: string;
}
