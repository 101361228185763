import { observable } from "mobx";
import { Expose } from "class-transformer";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class ReqMktAgreement {
  /**
   * @example
   * true
   */
  @observable
  @Expose({ name: "mkt_agreement" })
  mktAgreement?: boolean;
}
