import React from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { HeaderRow } from "../index";
export interface ProductCardProps {
  title: string;
  body: string;
  buttonLabel?: string;
  onClick?: () => void;
  preview?: boolean;
}

type ProductCard = ProductCardProps & Omit<React.InputHTMLAttributes<HTMLInputElement>, "size">;

const ProductCard: React.FC<ProductCard> = ({ className, title, body, buttonLabel, onClick, preview, children, ...props }) => {
  return (
    <Card>
      <HeaderRow className="pt-4 ml-3" level={3} title={title} />
      <Card.Body className="pt-1 mx-3 mx-sm-0 h-100 d-flex flex-column justify-content-between">
        <div className="product-card__body-text mb-3" dangerouslySetInnerHTML={{ __html: body }} />
        {buttonLabel && ( // no text, no button
          <Row>
            <Col>
              <Button onClick={onClick} className="mb-3 mb-sm-0 d-flex flex-wrap">
                {buttonLabel}
              </Button>
            </Col>
          </Row>
        )}
      </Card.Body>
    </Card>
  );
};

export default ProductCard;
