import { ManualEntityValidator } from "@frui.ts/validation";
import merge from "lodash/merge";
import { action } from "mobx";
import { ApiError, ApiErrorBag } from "repositories/apiModels";
import { EntityValidationRules } from "./entityValidationRules";

const applyServerErrors = action(
  (serverErrors: ApiError, validator: ManualEntityValidator<any>, ta?: (property: string) => string, flattenErrors = false) => {
    validator.clearErrors();

    const applyObjectErrors = (errors: ApiErrorBag) => {
      Object.entries(errors).forEach(([property, value]) => {
        if (Array.isArray(value)) {
          validator.addError(property, ta && property !== "base" ? `${ta(property)} ${value[0]}` : value[0]);
        } else if (flattenErrors) {
          applyObjectErrors(value);
        }
      });
    };

    applyObjectErrors(serverErrors.errors);
  }
);

function addManualValidation<TEntity>(rules: EntityValidationRules<any>, manualValidator: ManualEntityValidator<TEntity>) {
  const clonedRules = merge({}, rules);

  Object.keys(clonedRules).forEach(property => {
    manualValidator.addError(property as any, undefined as any);
    clonedRules[property] = Object.assign(clonedRules[property] ?? {}, { manualErrors: manualValidator.errors });
  });

  return clonedRules;
}

export { applyServerErrors, addManualValidation };
