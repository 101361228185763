import { ILocalizationService } from "@emanprague/shared-services";
import { BusyWatcher, ScreenBase } from "@frui.ts/screens";
import { observable } from "mobx";
import ProductCardsService from "services/productCardsService";
import ProductCardPreview from "entities/productCardPreview";
import type ProductCardListItemCategory from "entities/productCardListItemCategory";
import { bound } from "@frui.ts/helpers";
export default abstract class ProductCardPageViewModel extends ScreenBase {
  busyWatcher = new BusyWatcher();

  @observable hideInMenu: boolean;
  @observable.ref productCards: ProductCardPreview[] = [];

  constructor(
    private categoryName: ProductCardListItemCategory,
    public localization: ILocalizationService,
    private productCardsService: ProductCardsService
  ) {
    super();

    this.name = this.translate("title");

    this.productCards = this.productCardsService.getProductCards(this.categoryName);
    this.hideInMenu = this.productCards.length === 0;
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`products.${this.categoryName}.${key}`);
  }
}
