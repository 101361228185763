import { IPagingFilter } from "@frui.ts/data";
import PaymentList from "entities/paymentList";
import ReqExport from "entities/reqExport";
import PaymentsFilter from "models/paymentsFilter";
import { entityToPlain } from "./helpers";
import RepositoryBase from "./repositoryBase";

export default class PaymentsRepository extends RepositoryBase {
  getPayments(partnerId: number, paging: IPagingFilter, filter?: PaymentsFilter) {
    const query = entityToPlain(filter);

    return this.callApi(api =>
      api.all(`partners/${partnerId}/payments`).getPagedEntities(PaymentList, x => x.payments, paging, query)
    );
  }

  exportPayments(partnerId: number, reqExport: ReqExport) {
    return this.callApi(api => api.one("partners", partnerId).all("payments/export").postEntity(reqExport));
  }
}
