import { observable } from "mobx";
import { Expose, Transform } from "class-transformer";
import formatISO from "date-fns/formatISO";

export default class AuditLogListItem {
  /**
   * User ID
   */
  id!: number;

  @observable
  email: string;

  @observable
  kind: string;

  @observable
  status: string;

  @observable
  message: string;

  @observable
  user_email: string;

  @observable
  occurrences: number;

  @observable
  @Expose({ name: "partner_external_id" })
  partnerExternalId: number;

  get isResolved() {
    return this.status === "resolved" || this.status === "auto_resolved";
  }

  @observable
  @Transform(value => (value ? new Date(value) : undefined), { toClassOnly: true })
  @Transform(value => (value ? formatISO(value, { representation: "date" }) : undefined), { toPlainOnly: true })
  @Expose({ name: "last_occurrence_at" })
  lastOccurrenceAt?: Date;

  @observable
  @Transform(value => (value ? new Date(value) : undefined), { toClassOnly: true })
  @Transform(value => (value ? formatISO(value, { representation: "date" }) : undefined), { toPlainOnly: true })
  @Expose({ name: "resolved_at" })
  resolvedAt?: Date;
}
