import React from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";

import { styleBlock } from "../";

interface NotificationBarProps {
  className?: string;
  background?: string;
  cancellable?: boolean;
  cancelButtonBlack?: boolean;
  onCancel?: () => void;
}

export default class NotificationBar extends React.PureComponent<NotificationBarProps> {
  render() {
    const { className, background, cancellable = true, children, cancelButtonBlack, onCancel } = this.props;
    const classNames = styleBlock("notification-bar");
    const cn = classNames({}).mix([className, !background ? " notification-bar-gradient" : ""]);

    const cancelButtonUrl = `data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.05029 10.9498L10.9498 1.05027' stroke='${
      cancelButtonBlack ? "black" : "white"
    }'/%3E%3Cpath d='M1.05029 1.05023L10.9498 10.9497' stroke='${cancelButtonBlack ? "black" : "white"}'/%3E%3C/svg%3E%0A`;

    return (
      <div className={cn.toString()} style={{ backgroundColor: background ? background : undefined }}>
        <Container fluid="xl" className="p-0">
          <Row noGutters>
            <Col className="notification-container">{children}</Col>
          </Row>
        </Container>
        {cancellable && (
          <button
            className="notification-close p-3"
            style={{ backgroundImage: 'url("' + cancelButtonUrl + '")' }}
            onClick={onCancel}
          />
        )}
      </div>
    );
  }
}
