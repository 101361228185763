export type LocaleCode = string;

export interface ILocaleProvider {
  getAvailableLocales(): Promise<LocaleCode[]>;
  fetchResources(locale: LocaleCode): Promise<IResourcesContainer>;
}

export type ILocalizedResource = IResourceDictionary | string | string[];

export interface IResourceDictionary {
  [key: string]: ILocalizedResource;
}

export interface IResourcesContainer {
  general: IResourceDictionary;
  models: {
    attributes: IResourceDictionary;
    models: IResourceDictionary;
  };
  attributes: IResourceDictionary;
}

export function isResourceDictionary(item: ILocalizedResource): item is IResourceDictionary {
  return typeof item === "object" && !Array.isArray(item);
}
