import { observable } from "mobx";
import Link from "./link";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class LinkListItem extends Link {
  /**
   * Order of the link (1. - 4.) in form
   */
  @observable
  order?: number;

  static ValidationRules = Object.assign(
    {
      order: { number: true },
    },
    Link.ValidationRules
  );
}
