import { ILocalizationService, INotificationService, SeverityLevel } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, ScreenBase, watchBusy } from "@frui.ts/screens";
import { attachAutomaticValidator, hasVisibleErrors, validate } from "@frui.ts/validation";
import ReqRegistrationStep1, { ReqRegistrationStep1ValidationRules } from "entities/reqRegistrationStep1";
import { action, observable, runInAction } from "mobx";
import { unwrapErrorMessage } from "repositories/helpers";
import LoginRepository from "repositories/loginRepository";
import queryString from "query-string";

export type StepName = "step1" | "step2" | "step3" | "step4";

export default class RegistrationViewModel extends ScreenBase {
  static navigationName = "registration";
  navigationName = RegistrationViewModel.navigationName;
  name = "Registration";
  busyWatcher = new BusyWatcher();

  steps: Array<string> = [];

  registrationData: ReqRegistrationStep1;
  @observable registrationType: "default" | "bankId" = "default";
  @observable currentStep: StepName = "step1";
  @observable errorMessage?: string;
  @observable successMessage?: string;

  constructor(
    public localization: ILocalizationService,
    private repository: LoginRepository,
    private notificationService: INotificationService
  ) {
    super();

    this.name = this.translate("title");

    this.steps = [this.translate("step1.title"), this.translate("step2.title"), this.translate("step3.title")];

    this.registrationData = new ReqRegistrationStep1();
    attachAutomaticValidator(this.registrationData, ReqRegistrationStep1ValidationRules);
  }

  protected async onActivate() {
    await super.onActivate();
    this.checkRegistrationFlow();
  }

  checkRegistrationFlow() {
    const parsedQuery = queryString.parse(this.queryString);
    if (parsedQuery?.type === "bankId") {
      runInAction(() => {
        this.successMessage = this.translate("bank_id_message_sent");
        this.registrationType = "bankId";
        this.currentStep = "step2";
      });
    }
  }

  get queryString() {
    const startUrl = window.location.href;
    const queryStart = startUrl.indexOf("?");
    if (queryStart > -1) {
      return startUrl.slice(queryStart);
    }
    return "";
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`profile.registration.${key}`);
  }

  @bound translateGeneral(key: string) {
    return this.localization.translateGeneral(`general.${key}`);
  }

  get canContinue() {
    return !hasVisibleErrors(this.registrationData);
  }

  @action.bound
  @watchBusy
  async continue() {
    this.errorMessage = undefined;

    if (this.registrationData.mktAgreement === undefined) {
      this.registrationData.mktAgreement = false;
    }

    if (validate(this.registrationData)) {
      const response = await this.repository.registerStep1(this.registrationData);

      runInAction(() => {
        if (response.success) {
          this.successMessage = this.translate("message_sent").replace("%email%", this.registrationData.email);
          this.currentStep = "step2";
        } else {
          this.errorMessage = unwrapErrorMessage(response.payload);
        }
      });
    }
  }

  get canResend() {
    return this.registrationType === "default";
  }

  @action.bound
  @watchBusy
  async resend() {
    if (validate(this.registrationData)) {
      const response = await this.repository.registerStep1(this.registrationData);

      runInAction(() => {
        if (response.success) {
          this.notificationService.addNotification(
            this.translate("resend").replace("%email%", this.registrationData.email),
            SeverityLevel.success
          );
        } else {
          this.errorMessage = unwrapErrorMessage(response.payload);
        }
      });
    }
  }
}
