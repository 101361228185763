import { Expose } from "class-transformer";
import { observable } from "mobx";

export default class AdminsFilter {
  @observable
  @Expose({ name: "q" })
  search?: string;

  @observable
  @Expose({ name: "filters[id]" })
  id?: number;

  @observable
  @Expose({ name: "filters[first_name]" })
  firstName?: number;

  @observable
  @Expose({ name: "filters[last_name]" })
  lastName?: number;

  @observable
  @Expose({ name: "filters[email]" })
  email?: number;

  @observable
  @Expose({ name: "filters[role]" })
  role?: string[];

  @observable
  @Expose({ name: "filters[status]" })
  status?: boolean;
}
