import React from "react";
import { observer } from "mobx-react-lite";
import OverviewViewModel from "viewModels/messages/overviewViewModel";
import { preventDefault, registerView, ViewComponent } from "@frui.ts/views";
import { LoaderOverlay, Card, SwitchNav, Layout, InputSearch, Button, HeaderRow } from "@emanprague/ppaskit";
import { Col, Modal, Row } from "react-bootstrap";
import { createColumnsContext, DataColumn } from "controls/tableHelper";
import { ResponsiveTable } from "@frui.ts/dataviews";
import EmptyDataMessage from "components/EmptyDataMessage";
import { getColoredLabelMessages } from "helpers/labelHelper";
import iconIndicatorBlue from "@emanprague/ppaskit/dist/assets/icons/Indicator Blue.svg";
import PartnerMessageListItem from "entities/partnerMessageListItem";

const columns: DataColumn<PartnerMessageListItem, OverviewViewModel>[] = [
  {
    property: "date",
    valueFormatter: ({ item, value, context }) => (
      <>
        {!item.read ? <img className="pr-2" src={iconIndicatorBlue} alt="" /> : <span className="pr-0 pr-sm-3" />}
        {getColoredLabelMessages({ item, value }, context.vm.localization.formatDate(value))}
      </>
    ),
  },
  {
    property: "title",
    valueFormatter: ({ item, value }) => <span className="text-nowrap">{getColoredLabelMessages({ item, value })}</span>,
  },
  {
    valueFormatter: ({ item, context }) => (
      <Button
        size="sm"
        variant={!item.read ? "primary" : "secondary"}
        className="btn-responsive-block"
        onClick={() => context.vm.showDetail(item)}>
        {context.vm.translate("detail_button")}
      </Button>
    ),
    responsiveTitle: false,
    cellClassName: "cell-right",
  },
];

const overviewListView: ViewComponent<OverviewViewModel> = observer(({ vm }) => {
  const { translateGeneral: tg } = vm.localization;
  const navLinks = [
    {
      id: "all",
      title: vm.translate("filter_all"),
      href: "#",
      onClick: preventDefault(() => vm.clearFilter("read")),
    },
    {
      id: "unread",
      title: vm.translate("filter_unread"),
      href: "#",
      onClick: preventDefault(() => vm.applySwitchStateFilter(false)),
    },
    {
      id: "read",
      title: vm.translate("filter_read"),
      href: "#",
      onClick: preventDefault(() => vm.applySwitchStateFilter(true)),
    },
  ];

  return (
    <Layout fluid="xl" className="mt-4 mt-md-5 mb-3 px-3">
      <Row className="mb-5 align-items-center">
        <Col xs={12} md="auto" className="my-3 my-md-0">
          <h2 className="display-2 m-0 mr-3">{vm.name}</h2>
        </Col>
        <Col xs={12} md="auto" className="mb-3 mb-md-0">
          <SwitchNav links={navLinks} variant="light" size="sm" activeId={vm.switchStateActiveId} />
        </Col>
        <Col xs={12} md={3} className="ml-auto">
          <InputSearch
            type="text"
            size="sm"
            transparent
            value={vm.filter.search}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => vm.setSearchValue(e.target.value)}
            placeholder={vm.translateGeneral("search_short")}
          />
        </Col>
      </Row>
      <LoaderOverlay loading={vm.busyWatcher.isBusy}>
        <Card>
          <Card.Body>
            <div className="responsive-table-wrapper">
              <ResponsiveTable
                className="table no-wrap wider-row"
                items={vm.items}
                itemKey="id"
                columns={columns}
                context={createColumnsContext(vm)}
                displayHeader={false}
              />
            </div>
            {!vm.items?.length && <EmptyDataMessage translateGeneral={tg} />}

            {vm.canLoadData && (
              <div className="load-more-button">
                <Button onClick={vm.loadData} variant="secondary">
                  {vm.translateGeneral("button_load_more")}
                </Button>
              </div>
            )}

            {vm.messageDetail && (
              <Modal centered show onHide={vm.hideDetail} className="admin-modal">
                <Modal.Header closeButton />
                <Modal.Body>
                  <Row className="mb-4 mt-n2 message-preview-row" noGutters>
                    <Col xs={12} className="text-center mb-3">
                      <HeaderRow level={4} title={vm.messageDetail.title} />
                    </Col>
                    <Col xs={12}>
                      <div className="message-preview-text" dangerouslySetInnerHTML={{ __html: vm.messageDetail?.content }} />
                    </Col>
                  </Row>
                </Modal.Body>
              </Modal>
            )}
          </Card.Body>
        </Card>
      </LoaderOverlay>
    </Layout>
  );
});

registerView(overviewListView, OverviewViewModel);
