import { observable } from "mobx";
import { Expose } from "class-transformer";
import AddressType from "./addressType";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class Address {
  /**
   * @example
   * 200
   */
  id!: number;

  @observable
  type!: AddressType;

  /**
   * @example
   * 12345678
   */
  @observable
  @Expose({ name: "external_id" })
  externalId!: string;

  /**
   * @example
   * Kolmá
   */
  @observable
  street?: string;

  /**
   * @example
   * 1200
   */
  @observable
  @Expose({ name: "house_number" })
  houseNumber?: string;

  /**
   * @example
   * 12a
   */
  @observable
  @Expose({ name: "street_number" })
  streetNumber?: string;

  /**
   * @example
   * Horní dolní
   */
  @observable
  city!: string;

  /**
   * @example
   * Horní
   */
  @observable
  @Expose({ name: "city_part" })
  cityPart?: string;

  /**
   * @example
   * 100 00
   */
  @observable
  zip!: string;

  static ValidationRules = {
    id: { required: true, number: true },
    type: { required: true },
    externalId: { required: true },
    city: { required: true },
    zip: { required: true },
  };
}
