import { ILocalizationService } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { ConductorSingleChild } from "@frui.ts/screens";
import ScreenBase from "@frui.ts/screens/dist/structure/screenBase";
import AddressType from "entities/addressType";
import { partnerToFullName } from "helpers/utils";
import { computed } from "mobx";
import PartnerType from "models/partnerType";
import EnumsService from "services/enumsService";
import UserContext from "services/userContext";
import { IDetailPage } from "viewModels/types";
import ProductDetailViewModel from "../../productDetailViewModel";
import ModalEditContactInfoViewModel from "./modalEditContactInfoViewModel";
import ModalEditCustomerInfoViewModel from "./modalEditCustomerInfoViewModel";
import ModalEditMailingAddressViewModel from "./modalEditMailingAddressViewModel";
import ModalEditMarketingAgreementViewModel from "./modalEditMarketingAgreementViewModel";

export default class CustomerInfoDetailPageViewModel extends ConductorSingleChild<ScreenBase> implements IDetailPage {
  orderIndex = 5;
  navigationName = "customer";
  className = "icon-user";

  parent: ProductDetailViewModel;

  constructor(
    public localization: ILocalizationService,
    public userContext: UserContext,
    private modalEditCustomerInfoViewModelFactory: ReturnType<typeof ModalEditCustomerInfoViewModel.Factory>,
    private modalEditContactInfoViewModelFactory: ReturnType<typeof ModalEditContactInfoViewModel.Factory>,
    private modalEditMarketingAgreementViewModelFactory: ReturnType<typeof ModalEditMarketingAgreementViewModel.Factory>,
    private modalEditMailingAddressViewModelFactory: ReturnType<typeof ModalEditMailingAddressViewModel.Factory>,
    private enumsService: EnumsService
  ) {
    super();

    this.name = this.translate("title");
  }

  get account() {
    return this.parent.productDetailContext.supplyPoint?.account;
  }

  get partnerId() {
    return this.userContext.activePartnerId;
  }

  get partner() {
    return this.userContext.activePartner;
  }

  @computed
  get activePartnerFullName() {
    return partnerToFullName(this.enumsService, this.userContext.activePartner);
  }

  @bound addressByType(type: AddressType) {
    return this.parent.productDetailContext.supplyPoint?.account?.addresses?.find(x => x.type === type);
  }

  @computed
  get isCompany() {
    if (!this.partner) {
      return false;
    }
    return this.enumsService.getPartnerType(this.partner.partnerTypeId) === PartnerType.Business;
  }

  @bound
  activateEditContactInfoModal() {
    return this.navigate(ModalEditContactInfoViewModel.navigationName, undefined);
  }

  @bound
  activateEditCustomerInfoModal() {
    return this.navigate(ModalEditCustomerInfoViewModel.navigationName, undefined);
  }

  @bound
  activateEditMailingAddressModal() {
    return this.navigate(ModalEditMailingAddressViewModel.navigationName, undefined);
  }

  @bound
  activateEditMarketingAgreementModal() {
    return this.navigate(ModalEditMarketingAgreementViewModel.navigationName, undefined);
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`products.customer_info.${key}`);
  }

  @bound translateGeneral(key: string) {
    return this.localization.translateGeneral(`general.${key}`);
  }

  protected findNavigationChild(navigationName: string | undefined) {
    if (navigationName === ModalEditContactInfoViewModel.navigationName) {
      return this.partnerId ? this.modalEditContactInfoViewModelFactory(this.partnerId) : undefined;
    }
    if (navigationName === ModalEditCustomerInfoViewModel.navigationName) {
      return this.partnerId ? this.modalEditCustomerInfoViewModelFactory(this.partnerId) : undefined;
    }
    if (navigationName === ModalEditMailingAddressViewModel.navigationName) {
      const address = this.addressByType("postal");
      const supplyPoint = this.parent.productDetailContext.supplyPoint;
      return this.partnerId && address && supplyPoint
        ? this.modalEditMailingAddressViewModelFactory(this.partnerId, address, supplyPoint)
        : undefined;
    }
    if (navigationName === ModalEditMarketingAgreementViewModel.navigationName) {
      return this.partnerId ? this.modalEditMarketingAgreementViewModelFactory(this.partnerId) : undefined;
    }
    return undefined;
  }
}
