import { ILocalizationService, INotificationService, SeverityLevel } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, ScreenBase, watchBusy } from "@frui.ts/screens";
import { interfaces } from "inversify";
import { action, observable, runInAction } from "mobx";
import UserContext from "services/userContext";
import AttachmentsRepository from "repositories/attachmentsRepository";
import ReqCreateAttachment from "entities/reqCreateAttachment";
import { unwrapErrorMessage } from "repositories/helpers";

export default class AttachmentCreateViewModel extends ScreenBase {
  static navigationName = "new";

  navigationName = AttachmentCreateViewModel.navigationName;
  busyWatcher = new BusyWatcher();

  @observable data: ReqCreateAttachment;
  @observable errorMessage?: string;
  @observable fileSelectError?: string;
  @observable isUploadValid = true;

  constructor(
    private refreshDataCallback: () => void,
    public localization: ILocalizationService,
    private notificationService: INotificationService,
    private userContext: UserContext,
    private attachmentsRepository: AttachmentsRepository
  ) {
    super();

    this.name = this.translate("title_new");

    this.data = new ReqCreateAttachment();
  }

  @bound
  @watchBusy
  async confirmChange() {
    const partnerId = this.userContext.activePartnerId;
    if (!partnerId) {
      return;
    }

    this.validate();
    if (!this.isUploadValid) {
      return;
    }

    const response = await this.attachmentsRepository.addAttachment(partnerId, this.data);
    if (response.success) {
      this.notificationService.addNotification(this.translateGeneral("request_sent_success"), SeverityLevel.success);
      this.refreshDataCallback();
      this.requestClose();
    } else {
      runInAction(() => (this.errorMessage = unwrapErrorMessage(response.payload)));
    }
  }

  @action.bound
  validate() {
    this.isUploadValid = !!this.data.file;
    if (!this.data.title) {
      this.data.title = undefined;
    }
    if (!this.data.description) {
      this.data.description = undefined;
    }
  }

  @action.bound
  onDrop(files: File[]) {
    if (files[0]) {
      this.data.file = files[0];
      this.isUploadValid = true;
      this.fileSelectError = undefined;
    } else {
      const newData = new ReqCreateAttachment();
      newData.title = this.data.title;
      newData.description = this.data.description;
      this.data = newData;
    }
  }

  @action.bound
  onDropRejected() {
    this.fileSelectError = this.translateGeneral("upload_invalid_files");
  }

  get canConfirm() {
    return this.isUploadValid;
  }

  get uploaderErrorMessage() {
    if (this.fileSelectError) {
      return this.fileSelectError;
    }
    return !this.isUploadValid ? this.localization.translateGeneral("validators.required_file") : undefined;
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`attachments.detail.${key}`);
  }

  @bound translateGeneral(key: string) {
    return this.localization.translateGeneral(`general.${key}`);
  }

  static Factory({ container }: interfaces.Context) {
    return (refreshDataCallback: () => void) => {
      return new AttachmentCreateViewModel(
        refreshDataCallback,
        container.get("ILocalizationService"),
        container.get("INotificationService"),
        container.get(UserContext),
        container.get(AttachmentsRepository)
      );
    };
  }
}
