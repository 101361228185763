import { ILocalizationService } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, Router, ScreenBase, watchBusy } from "@frui.ts/screens";
import { computed, observable } from "mobx";
import DataSyncService from "services/dataSyncService";
import EnumsService from "services/enumsService";
import ProfileStatusViewModel from "../profile/statusViewModel";
import PartnerListItem from "entities/partnerListItem";
import PartnersRepository from "repositories/partnersRepository";

export default class PartnerRemoveViewModel extends ScreenBase {
  @observable errorMessage?: string;
  @observable filter: string;
  @observable selectedPartnersIds = new Set<number>();

  busyWatcher = new BusyWatcher();

  constructor(
    public localization: ILocalizationService,
    public router: Router,
    private dataService: DataSyncService,
    public enumsService: EnumsService,
    public profile: ProfileStatusViewModel,
    private partnersRepository: PartnersRepository
  ) {
    super();

    this.name = this.translate("title");
  }

  get supplyPoints() {
    return this.dataService.supplyPoints;
  }

  get connectedPartners() {
    return this.profile.connectedPartners;
  }

  get filteredConnectedPartners() {
    if (!this.filter) {
      return this.connectedPartners;
    }

    const filter = this.filter.toLowerCase();
    const fullName = (item: PartnerListItem) => {
      return (item.firstName ? item.firstName + " " : "") + item.lastName;
    };

    return this.connectedPartners.filter(
      item => fullName(item).toLowerCase().includes(filter) || item.externalId.includes(filter)
    );
  }

  @bound
  @watchBusy
  async removePartners() {
    const selectedPartnersIds = Array.from(this.selectedPartnersIds);
    const data = await Promise.all(selectedPartnersIds.map(partnerId => this.partnersRepository.deletePartner(partnerId)));
    if (data.every(item => item.success)) {
      window.location.reload();
    }
  }

  @bound
  handleSelectAllPartners() {
    if (this.isAllSelected) {
      this.connectedPartners.forEach(item => this.selectedPartnersIds.add(item.id));
    } else {
      this.selectedPartnersIds.clear();
    }
  }

  @computed
  get isAllSelected() {
    if (!this.selectedPartnersIds.size) {
      return false;
    }
    if (this.selectedPartnersIds.size === this.connectedPartners.length) {
      return true;
    }
    return null;
  }

  set isAllSelected(value) {
    if (value) {
      this.connectedPartners.forEach(x => this.selectedPartnersIds.add(x.id));
    } else {
      this.selectedPartnersIds.clear();
    }
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`partner_remove.${key}`);
  }

  @bound translateGeneral(key: string) {
    return this.localization.translateGeneral(`general.${key}`);
  }
}
