import { observable, runInAction } from "mobx";
import ConfirmDialogViewModel, { ButtonParams, ConfirmDialogSettings } from "viewModels/confirmDialogViewModel";
import { ILocalizationService } from "@emanprague/shared-services";

export default class ConfirmationService {
  @observable.shallow dialogs = [] as ConfirmDialogViewModel[];

  constructor(private localization: ILocalizationService) {}

  showConfirmation(
    message: string,
    title?: string,
    confirmButton?: string | ButtonParams,
    cancelButton?: string | ButtonParams,
    settings?: ConfirmDialogSettings
  ) {
    const { translateGeneral: tg } = this.localization;

    const confirmButtonParams = {
      text: (confirmButton as ButtonParams)?.text ?? tg("general.confirm"),
      variant: (confirmButton as ButtonParams)?.variant || "primary",
    } as ButtonParams;

    const cancelButtonParams = {
      text: (cancelButton as ButtonParams)?.text ?? tg("general.cancel_button"),
      variant: (cancelButton as ButtonParams)?.variant || "secondary",
    } as ButtonParams;

    const viewModel = new ConfirmDialogViewModel(message, title || undefined, confirmButtonParams, cancelButtonParams, settings);

    runInAction(() => this.dialogs.push(viewModel));

    const result = viewModel.showConfirmation();
    result.then(() =>
      runInAction(() => {
        const index = this.dialogs.indexOf(viewModel);
        this.dialogs.splice(index, 1);
      })
    );

    return result;
  }
}
