import { observable } from "mobx";
import { Type } from "class-transformer";
import BannerListItem from "./bannerListItem";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class BannerList {
  @observable
  total!: number;

  @observable
  @Type(() => BannerListItem)
  banners!: BannerListItem[];

  static ValidationRules = {
    total: { required: true, number: true },
    banners: { required: true },
  };
}
