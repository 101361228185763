import { registerView, ViewComponent } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import React from "react";
import { LoaderOverlay, Modal } from "@emanprague/ppaskit";
import "./style.scss";
import { Col, Row } from "react-bootstrap";
import PartnerConfirmViewModel from "viewModels/dashboard/partnerConfirmViewModel";

const partnerConfirmView: ViewComponent<PartnerConfirmViewModel> = observer(({ vm }) => (
  <Modal centered show onHide={vm.requestClose}>
    <Modal.Header closeButton>
      <h2 className="display-4 m-0">{vm.name}</h2>
    </Modal.Header>
    <Modal.Body>
      <LoaderOverlay loading={vm.busyWatcher.isBusy}>
        <Row>
          <Col>{vm.message}</Col>
        </Row>
      </LoaderOverlay>
    </Modal.Body>
  </Modal>
));

registerView(partnerConfirmView, PartnerConfirmViewModel);
