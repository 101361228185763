import { observable } from "mobx";
import { Expose, Type } from "class-transformer";
import { entityToFormData } from "repositories/helpers";
import BannerListItem from "./bannerListItem";
import BannerButton from "./bannerButton";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
class Banner extends BannerListItem {
  @observable
  @Type(() => BannerButton)
  links!: BannerButton[];

  @observable
  text1!: string;

  @observable
  text2?: string;

  /**
   * @example
   * file.png
   */
  @observable
  file?: string;

  @observable
  @Expose({ name: "if_electricity" })
  ifElectricity?: boolean;

  @observable
  @Expose({ name: "if_not_electricity" })
  ifNotElectricity?: boolean;

  @observable
  @Expose({ name: "if_gas" })
  ifGas?: boolean;

  @observable
  @Expose({ name: "if_not_gas" })
  ifNotGas?: boolean;

  @observable
  @Expose({ name: "if_household" })
  ifHousehold?: boolean;

  @observable
  @Expose({ name: "if_company" })
  ifCompany?: boolean;

  static ValidationRules = Object.assign(
    {
      links: { required: true },
      text1: { required: true },
    },
    BannerListItem.ValidationRules
  );
}

export default class CustomBanner extends Banner {
  toFormData() {
    const data = entityToFormData(this);

    // generic entityToFomData is creating "[object OBJECT]" string instead of file
    data.delete("file");
    if (this.file) {
      data.append("file", this.file);
    }

    return data;
  }
}
