import { registerView, ViewComponent } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import React from "react";
import AxaPageViewModel from "viewModels/products/axaPageViewModel";
import "./style.scss";
import ProductCardList from "components/ProductCardList";

const axaPageListView: ViewComponent<AxaPageViewModel> = observer(({ vm }) => (
  <>
    <ProductCardList productCards={vm.productCards} />
    <div className="products__footer my-5">
      {vm.translate("footer_part1")}
      <br />
      {vm.translate("footer_part2")}{" "}
      <a className="text-indigo" href={vm.translate("footer_link")} target="_blank" rel="noopener noreferrer">
        {vm.translate("footer_part3")}
      </a>
      .
    </div>
  </>
));

registerView(axaPageListView, AxaPageViewModel);
