import Dashboard from "@emanprague/ppaskit/dist/assets/icons/Dashboard.svg";
import DashboardFill from "@emanprague/ppaskit/dist/assets/icons/Dashboard Fill.svg";
import Gas from "@emanprague/ppaskit/dist/assets/icons/Gas Grey.svg";
import GasFill from "@emanprague/ppaskit/dist/assets/icons/Gas Fill.svg";
import Receipt from "@emanprague/ppaskit/dist/assets/icons/Receipt Grey.svg";
import ReceiptFill from "@emanprague/ppaskit/dist/assets/icons/Receipt Fill.svg";
import Requirements from "@emanprague/ppaskit/dist/assets/icons/Requirements Grey.svg";
import RequirementsFill from "@emanprague/ppaskit/dist/assets/icons/Requirements Fill.svg";
import Document from "@emanprague/ppaskit/dist/assets/icons/Document Grey.svg";
import DocumentFill from "@emanprague/ppaskit/dist/assets/icons/Document Fill.svg";

export const getNavItemIcon = (navigationName: string, isActive: boolean) => {
  switch (navigationName) {
    case "dashboard":
      return isActive ? DashboardFill : Dashboard;
    case "supply_points":
      return isActive ? DocumentFill : Document;
    case "products":
      return isActive ? GasFill : Gas;
    case "finance":
      return isActive ? ReceiptFill : Receipt;
    case "requests":
      return isActive ? RequirementsFill : Requirements;
    default:
      return undefined;
  }
};
