import { observable } from "mobx";
import { Type } from "class-transformer";
import { Transform } from "class-transformer";
import formatISO from "date-fns/formatISO";
import { Expose } from "class-transformer";
import BannerButton from "./bannerButton";
import ReqBannerType from "./reqBannerType";
import { entityWithArrayOfObjectsToFormData } from "repositories/helpers";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
class ReqBanner {
  @observable
  title!: string;

  @observable
  type!: ReqBannerType;

  @observable
  @Type(() => BannerButton)
  links!: BannerButton[];

  @observable
  text1!: string;

  @observable
  text2?: string;

  @observable
  file?: Blob;

  @observable
  @Transform(value => (value ? new Date(value) : undefined), { toClassOnly: true })
  @Transform(value => (value ? formatISO(value, { representation: "date" }) : undefined), { toPlainOnly: true })
  @Expose({ name: "valid_from" })
  validFrom?: Date;

  @observable
  @Transform(value => (value ? new Date(value) : undefined), { toClassOnly: true })
  @Transform(value => (value ? formatISO(value, { representation: "date" }) : undefined), { toPlainOnly: true })
  @Expose({ name: "valid_to" })
  validTo?: Date;

  @observable
  @Expose({ name: "if_electricity" })
  ifElectricity?: boolean;

  @observable
  @Expose({ name: "if_not_electricity" })
  ifNotElectricity?: boolean;

  @observable
  @Expose({ name: "if_gas" })
  ifGas?: boolean;

  @observable
  @Expose({ name: "if_not_gas" })
  ifNotGas?: boolean;

  @observable
  @Expose({ name: "if_household" })
  ifHousehold?: boolean;

  @observable
  @Expose({ name: "if_company" })
  ifCompany?: boolean;

  static ValidationRules = {
    title: { maxLength: 255, required: true },
    type: { required: true },
    links: { required: true },
    text1: { required: true },
    validFrom: { date: true },
    validTo: { date: true },
  };
}

export default class CustomReqBanner extends ReqBanner {
  toFormData() {
    const data = entityWithArrayOfObjectsToFormData(this, new FormData());

    // generic entityToFomData is creating "[object OBJECT]" string instead of file
    data.delete("file");
    if (this.file) {
      data.append("file", this.file);
    }

    return data;
  }
}
