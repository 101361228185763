import { observable } from "mobx";
import { Type } from "class-transformer";
import { Expose } from "class-transformer";
import ReqTransferSupplyPointRequestAddress from "./reqTransferSupplyPointRequestAddress";
import ReqTransferSupplyPointRequestNaturalPerson from "./reqTransferSupplyPointRequestNaturalPerson";
import ReqTransferSupplyPointRequestLegalPerson from "./reqTransferSupplyPointRequestLegalPerson";
import ReqTransferSupplyPointRequestPersonType from "./reqTransferSupplyPointRequestPersonType";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class ReqTransferSupplyPointRequest {
  /**
   * VT / gas
   */
  @observable
  value!: number;

  /**
   * NT
   */
  @observable
  @Expose({ name: "value_nt" })
  valueNt?: number;

  /**
   * New device id after change
   */
  @observable
  @Expose({ name: "new_device_id" })
  newDeviceId?: string;

  @observable
  @Expose({ name: "person_type" })
  personType!: ReqTransferSupplyPointRequestPersonType;

  @observable
  @Type(() => ReqTransferSupplyPointRequestNaturalPerson)
  @Expose({ name: "natural_person" })
  naturalPerson?: ReqTransferSupplyPointRequestNaturalPerson;

  @observable
  @Type(() => ReqTransferSupplyPointRequestLegalPerson)
  @Expose({ name: "legal_person" })
  legalPerson?: ReqTransferSupplyPointRequestLegalPerson;

  @observable
  email?: string;

  @observable
  phone?: string;

  /**
   * TBD
   */
  @observable
  @Expose({ name: "sent_to_email" })
  sentToEmail?: boolean;

  /**
   * TBD
   */
  @observable
  @Expose({ name: "sent_to_address" })
  sentToAddress?: boolean;

  @observable
  @Expose({ name: "invoice_email" })
  invoiceEmail?: string;

  @observable
  @Type(() => ReqTransferSupplyPointRequestAddress)
  address?: ReqTransferSupplyPointRequestAddress;

  static ValidationRules = {
    value: { required: true, number: true },
    valueNt: { number: true },
    personType: { required: true },
  };
}
