import { registerView, ViewComponent } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import React from "react";
import ConfirmDialogViewModel from "viewModels/confirmDialogViewModel";
import { Modal, Button } from "@emanprague/ppaskit";
import { Col, Row } from "react-bootstrap";

const confirmDialogView: ViewComponent<ConfirmDialogViewModel> = observer(({ vm }) => {
  return (
    <Modal centered show={vm.isOpened} onHide={(vm.settings.closeButton && vm.cancel) || (() => {})} onEscapeKeyDown={vm.cancel}>
      <Modal.Header closeButton={vm.settings.closeButton}>
        {vm.title && <Modal.Title as="div">{vm.title}</Modal.Title>}
      </Modal.Header>
      <Modal.Body>
        <Row className="my-3">
          <Col xs={12} className="d-flex justify-content-center align-items-center">
            <div style={{ whiteSpace: "pre-line" }} className="confirmation-message">
              {vm.message}
            </div>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        {!vm.settings.hideCancelButton && (
          <Button onClick={vm.cancel} variant={vm.cancelButtonParams.variant}>
            {vm.cancelButtonParams.text}
          </Button>
        )}
        {!vm.settings.hideConfirmButton && (
          <Button onClick={vm.confirm} variant={vm.confirmButtonParams.variant}>
            {vm.confirmButtonParams.text}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
});

registerView(confirmDialogView, ConfirmDialogViewModel);
