import { Card } from "@emanprague/ppaskit";
import React from "react";
import "./style.scss";
import DOMPurify from "dompurify";

interface MaintenanceNoticeProps {
  title: string;
  message: string;
}

export const MaintenanceNotice = ({ title, message }: MaintenanceNoticeProps) => {
  return (
    <Card className="border-right-sm z-index-1">
      <Card.Body>
        <h1 className="display-1 text-center">{title}</h1>
        <p
          className="maintenance-text"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(message),
          }}
        />
      </Card.Body>
    </Card>
  );
};
