import React from "react";
import { ProductRow, Button, Card } from "..";
// import iconPlus from "../assets/icons/Plus.svg";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export interface CrossSellBannerProps {
  title: string;
  htmlContent1: string;
  htmlContent2?: string;
  links: Array<{ url: string; text: string }>;
  className?: string;
  // newSupplyPointButtonLabel: string;
  // newSupplyPointButtonOnClick: () => void;
  topCenterMessage: string;
}

export default class CrossSellBanner extends React.PureComponent<CrossSellBannerProps> {
  render() {
    const {
      title,
      htmlContent1,
      htmlContent2,
      links,
      // newSupplyPointButtonLabel,
      // newSupplyPointButtonOnClick,
      topCenterMessage,
      className,
    } = this.props;

    const lightFont = (text: string): React.ReactNode => {
      return <span className="font-weight-light ml-2">{text}</span>;
    };

    return (
      <ProductRow
        id="cross-sell-gas"
        title={lightFont(title)}
        className={"mb-0 mb-md-5 pt-5 " + className}
        titleDisabled={true}
        collapsible={true}
        // actions={
        //   <Button variant="light" onClick={newSupplyPointButtonOnClick}>
        //     <img className="mr-md-3" width="14" height="14" src={iconPlus} alt="" />
        //     <span className="d-none d-md-inline">{newSupplyPointButtonLabel}</span>
        //   </Button>
        // }
      >
        <Col xs={12} className="my-4 mt-lg-3 px-sm-2">
          <Card className="bg-transparent border">
            <Card.Body className="">
              <Button
                variant="info"
                size="sm"
                className="position-absolute position-x-center position-y-top translate-50 disable-pointer"
                tabIndex={-1}>
                {topCenterMessage}
              </Button>
              <Row className="align-items-center mt-2 mt-sm-0 pt-1 pb-md-1">
                <Col xs={12} sm={6} xl={3}>
                  <div className="text-body font-weight-medium" dangerouslySetInnerHTML={{ __html: htmlContent1 }} />
                </Col>
                <Col xs="auto" sm={6} lg={6} xl={4} className="my-4 my-xl-0">
                  <div className="text-muted ml-4" dangerouslySetInnerHTML={{ __html: htmlContent2 ?? "" }} />
                </Col>
                <Col xs={12} md="auto" className="ml-sm-auto d-flex justify-content-end">
                  <Row noGutters className="text-nowrap">
                    {links.map((item, index) => (
                      <Col xs={12} sm="auto" key={index} className="pl-sm-2">
                        <Button
                          variant={index === links.length - 1 ? "primary" : "light"}
                          className="my-1 mb-lg-0 w-100"
                          href={item.url}>
                          {item.text}
                        </Button>
                      </Col>
                    ))}
                  </Row>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </ProductRow>
    );
  }
}
