import { Layout, TabsNav } from "@emanprague/ppaskit";
import { registerView, View, ViewComponent } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import React from "react";
import OverviewViewModel from "viewModels/products/overviewViewModel";
import { Router } from "@frui.ts/screens";

const NavigationMenu: ViewComponent<OverviewViewModel> = observer(({ vm }) => {
  const getUrl = Router.getChildUrlFactory(vm);

  const filteredChildren = vm.children.filter(item => !item.hideInMenu);
  const menuItems = filteredChildren.map(x => ({
    id: x.navigationName,
    title: x.name,
    href: getUrl(x.navigationName),
  }));

  return (
    <>
      <TabsNav tabs={menuItems} activeId={vm.activeChild?.navigationName} className="my-5" />
    </>
  );
});

const myProductsListView: ViewComponent<OverviewViewModel> = observer(({ vm }) => {
  return (
    <Layout fluid="xl" className="mt-3">
      {!vm.activeChild?.isFullScreen && <NavigationMenu vm={vm} />}
      <View vm={vm.activeChild} context={vm.activeChild?.navigationName == "offer" ? "screen" : undefined} />
    </Layout>
  );
});

registerView(myProductsListView, OverviewViewModel);
