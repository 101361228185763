import Address from "entities/address";
import FormResponse from "entities/formResponse";
import Partner from "entities/partner";
import PartnerListItem from "entities/partnerListItem";
import ReqAddPartner from "entities/reqAddPartner";
import ReqContactData from "entities/reqContactData";
import ReqCustomerData from "entities/reqCustomerData";
import { RequestsEvents } from "services/events";
import RepositoryBase from "./repositoryBase";
import BannersFilter from "models/bannersFilter";
import { entityToPlain } from "./helpers";
import BannerListToRender from "entities/bannerListToRender";
import CompetitionList from "entities/competitionList";
import { IPagingFilter } from "@frui.ts/data";
import ReqPressModalButton from "entities/reqPressModalButton";
import Ok from "entities/ok";

export default class PartnersRepository extends RepositoryBase {
  getPartners() {
    return this.callApi(api => api.all(`partners`).getEntities(PartnerListItem));
  }

  addPartner(data: ReqAddPartner) {
    const formData = data.toFormData();
    return this.callApi(api => api.all(`partners`).postData(formData));
  }

  deletePartner(partnerId: number) {
    return this.callApi(api => api.all(`partners/${partnerId}`).delete());
  }

  getPartner(partnerId: number) {
    return this.callApi(api => api.all(`partners/${partnerId}`).getEntity(Partner));
  }

  getAddresses(partnerId: number) {
    return this.callApi(api => api.all(`partners/${partnerId}/addresses`).getEntities(Address));
  }

  updateContactData(partnerId: number, data: ReqContactData) {
    data.street = data.street || undefined;

    const promise = this.callApi(api => api.all(`partners/${partnerId}/contact_data`).postEntity(data, FormResponse));
    promise.then(() => this.eventBus.publish(RequestsEvents.requestCreated(data)));
    return promise;
  }

  updateCustomerData(partnerId: number, data: ReqCustomerData) {
    const formData = data.toFormData();
    const promise = this.callApi(api => api.all(`partners/${partnerId}/customer_data`).postData(formData));
    promise.then(() => this.eventBus.publish(RequestsEvents.requestCreated(data)));

    return promise;
  }

  confirmPartner(token: string) {
    return this.callApi(api => api.all(`partners/confirm`).post({ token }));
  }

  getBanners(partnerId: number, filter: BannersFilter) {
    const query = entityToPlain(filter);

    return this.callApi(api => api.all(`partners/${partnerId}/banners`).getEntity(BannerListToRender, query));
  }

  getCompetitions(partnerId: number, paging: IPagingFilter) {
    return this.callApi(api =>
      api.all(`partners/${partnerId}/competitions`).getPagedEntities(CompetitionList, x => x.competitions, paging)
    );
  }

  sendChoice(partnerId: number, competitionId: number, choice: ReqPressModalButton) {
    return this.callApi(api => api.one("partners", partnerId).one("competitions", competitionId).putEntity(choice, Ok));
  }
}
