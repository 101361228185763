import { observable } from "mobx";
import { Expose } from "class-transformer";
import { entityToFormData } from "repositories/helpers";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
class ReqInvoiceComplaint {
  /**
   * Enumeration claim_ypes
   */
  @observable
  @Expose({ name: "claim_type_id" })
  claimTypeId!: number;

  /**
   * Supply point id
   */
  @observable
  @Expose({ name: "supply_point_id" })
  supplyPointId!: number;

  /**
   * Enumeration rates
   */
  @observable
  @Expose({ name: "rate_id" })
  rateId?: number;

  @observable
  @Expose({ name: "claimed_value" })
  claimedValue?: number;

  @observable
  @Expose({ name: "claimed_value_nt" })
  claimedValueNt?: number;

  @observable
  @Expose({ name: "current_value" })
  currentValue?: number;

  @observable
  @Expose({ name: "current_value_nt" })
  currentValueNt?: number;

  @observable
  note?: string;

  @observable
  @Expose({ name: "old_value" })
  oldValue?: number;

  @observable
  @Expose({ name: "old_value_nt" })
  oldValueNt?: number;

  @observable
  @Expose({ name: "file[]" })
  file?: Blob[];

  static ValidationRules = {
    claimTypeId: { required: true, number: true },
    supplyPointId: { required: true, number: true },
    rateId: { number: true },
    claimedValue: { number: true },
    claimedValueNt: { number: true },
    currentValue: { number: true },
    currentValueNt: { number: true },
    oldValue: { number: true },
    oldValueNt: { number: true },
  };
}

export default class CustomReqInvoiceComplaint extends ReqInvoiceComplaint {
  toFormData() {
    const data = entityToFormData(this);

    // generic entityToFomData is creating "[object OBJECT]" string instead of file
    data.delete("file[]");
    if (this.file && this.file.length > 0) {
      this.file.forEach(item => data.append("file[]", item));
    }

    return data;
  }
}
