import { Form, Col, Button, Modal, HeaderRow, LoaderOverlay } from "@emanprague/ppaskit";
import { preventDefault, registerView, ViewComponent } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import React from "react";
import { Check, Input, Select } from "@frui.ts/bootstrap";
import AdminUpdateViewModel from "viewModels/admin/adminUpdateViewModel";

const adminUpdateView: ViewComponent<AdminUpdateViewModel> = observer(({ vm }) => {
  const ta = vm.localization.translateAttribute;

  return (
    <Modal centered show onHide={vm.requestClose} className="admin-modal">
      <Modal.Header closeButton>
        <HeaderRow title={vm.name} level={4} />
      </Modal.Header>
      <LoaderOverlay loading={vm.busyWatcher.isBusy}>
        <Modal.Body>
          <Form className="admin-form" onSubmit={preventDefault(vm.confirmRequest)}>
            <Form.Row>
              <Form.Group as={Col} xs={6}>
                <Form.Label>{ta("admin", "first_name")}</Form.Label>
                <Input target={vm.data} property="firstName" />
              </Form.Group>
              <Form.Group as={Col} xs={6}>
                <Form.Label>{ta("admin", "last_name")}</Form.Label>
                <Input target={vm.data} property="lastName" />
              </Form.Group>
              <Form.Group as={Col} xs={12}>
                <Form.Label>{ta("admin", "email")}</Form.Label>
                <Input target={vm.data} property="email" />
              </Form.Group>
              <Form.Group as={Col} xs={12} className="pb-3">
                <Form.Label>{ta("admin", "role")}</Form.Label>
                <Select target={vm.data} property="role" items={vm.userRoles} mode="key" keyProperty="id" textProperty="name" />
              </Form.Group>
              <Form.Group as={Col} xs={12} className="d-flex justify-content-center">
                <Check custom target={vm.data} property="active" label={vm.translate("account_active")} />
              </Form.Group>
              <Form.Group as={Col} xs={12} className="d-flex justify-content-center">
                <span className="text-muted font-size-sm">{vm.translate("account_tooltip")}</span>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group>
                {vm.errorMessage && <Form.Control.Feedback type="invalid">{vm.errorMessage}</Form.Control.Feedback>}
              </Form.Group>
            </Form.Row>
          </Form>
        </Modal.Body>
      </LoaderOverlay>
      <Modal.Footer>
        <Button onClick={vm.requestClose} variant="secondary">
          {vm.translateGeneral("cancel_button")}
        </Button>
        <Button disabled={!vm.canConfirm} variant="primary" onClick={vm.confirmRequest} className="ml-1">
          {vm.translateGeneral("confirm_changes_button")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
});

registerView(adminUpdateView, AdminUpdateViewModel);
