import { observable } from "mobx";
import { Type } from "class-transformer";
import { Expose } from "class-transformer";
import ProductCardListItem from "./productCardListItem";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class ProductCardList {
  @observable
  total!: number;

  @observable
  @Type(() => ProductCardListItem)
  @Expose({ name: "product_cards" })
  productCards!: ProductCardListItem[];

  static ValidationRules = {
    total: { required: true, number: true },
    productCards: { required: true },
  };
}
