import { Expose, Transform } from "class-transformer";
import formatISO from "date-fns/formatISO";
import { observable } from "mobx";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class RequestStatusesItem {
  id!: number;

  @observable
  description!: string;

  @observable
  order!: number;

  @observable
  @Transform(value => (value ? new Date(value) : undefined), { toClassOnly: true })
  @Transform(value => (value ? formatISO(value, { representation: "date" }) : undefined), { toPlainOnly: true })
  @Expose({ name: "executed_at" })
  executedAt!: Date;

  @observable
  @Expose({ name: "is_done" })
  isDone?: boolean;

  @observable
  @Expose({ name: "estimated_time" })
  estimatedTime?: string;

  static ValidationRules = {
    id: { required: true, number: true },
    description: { required: true },
    order: { required: true, number: true },
    executedAt: { required: true, date: true },
  };
}
