import { Col, Container, Row } from "@emanprague/ppaskit";
import iconSupport from "@emanprague/ppaskit/dist/assets/icons/Support.svg";
import React from "react";

interface Props {
  translateFunc: (translateCode: string) => string;
}

export default class extends React.PureComponent<Props> {
  render() {
    return (
      <div className="bg-white py-3 mt-3">
        <Container>
          <Row noGutters className="align-items-center justify-content-center">
            <Col xs="auto">
              <img src={iconSupport} className="mr-4" alt="" />
            </Col>
            <Col xs={true} sm="auto">
              {this.props.translateFunc("profile.common.hint_text")}{" "}
              <a
                href={"tel:+420" + this.props.translateFunc("profile.common.hint_phone").replace(/\s/g, "")}
                className="text-body font-weight-bold link-underline-hover">
                {this.props.translateFunc("profile.common.hint_phone")}
              </a>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
