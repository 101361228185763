import React from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Tooltip from "@emanprague/basekit/dist/Tooltip";

import InfoIcon from "../assets/icons/Info.svg";

import { styleBlock } from "../";

interface PasswordIndicatorProps {
  label?: React.ReactNode | string;
  value?: number | "";
  tooltip?: React.ReactNode | string;
  className?: string;
}

export default class PasswordIndicator extends React.PureComponent<PasswordIndicatorProps> {
  render() {
    const { label, value, tooltip, className } = this.props;

    const classNames = styleBlock("passwordindicator");
    const cn = classNames({}).mix([className]);

    const isSet = (level: number) => {
      return "level level-" + level + (value && value > level - 1 ? " level-set" : "");
    };

    return (
      <div className={cn.toString()}>
        <Row noGutters className="mb-2">
          <Col className={isSet(1)}></Col>
          <Col className={isSet(2)}></Col>
          <Col className={isSet(3)}></Col>
        </Row>
        <Row noGutters>
          <Col xs={10}>
            <span className="level-status">{label}</span>
          </Col>
          <Col xs={2} className="text-right">
            {tooltip && (
              <Tooltip id="pitooltip" tooltip={tooltip}>
                <img src={InfoIcon} className="cursor-help" />
              </Tooltip>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}
