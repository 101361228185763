import { observable } from "mobx";
import { Expose } from "class-transformer";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class PartnerId {
  /**
   * @example
   * 123456789
   */
  @observable
  @Expose({ name: "partner_id" })
  partnerId!: number;

  static ValidationRules = {
    partnerId: { required: true, number: true },
  };
}
