import { registerView, ViewComponent } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import React from "react";
import { LoaderOverlay, Modal } from "@emanprague/ppaskit";
import "./style.scss";
import PartnerRemoveViewModel from "viewModels/dashboard/partnerRemoveViewModel";
import { Check, CollectionCheck, Input } from "@frui.ts/bootstrap";
import { Button, Col, Row } from "react-bootstrap";

const partnerRemoveView: ViewComponent<PartnerRemoveViewModel> = observer(({ vm }) => {
  const ta = vm.localization.translateAttribute;

  return (
    <Modal centered show onHide={vm.requestClose}>
      <Modal.Header closeButton>
        <h2 className="display-4 m-0">{vm.name}</h2>
      </Modal.Header>
      <Modal.Body className="mx-3">
        <LoaderOverlay loading={vm.busyWatcher.isBusy}>
          <Row className="mb-4">
            <Col>
              <Input size="sm" target={vm} property="filter" placeholder={vm.translate("search_placeholder")} />
            </Col>
          </Row>
          <table className="table wider-row">
            <thead>
              <tr>
                <th>
                  <Check
                    custom
                    label=""
                    target={vm}
                    property="isAllSelected"
                    threeState
                    onValueChanged={vm.handleSelectAllPartners}
                  />
                </th>
                <th>{ta("partner", "account")}</th>
                <th>{ta("partner", "external_id")}</th>
              </tr>
            </thead>
            <tbody>
              {vm.filteredConnectedPartners.map(item => (
                <tr key={item.id}>
                  <td>
                    <CollectionCheck custom label="" target={vm} property="selectedPartnersIds" value={item.id} />
                  </td>
                  <td data-title={ta("partner", "account")}>
                    {item.firstName} {item.lastName}
                  </td>
                  <td data-title={ta("partner", "external_id")}>{item.externalId}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </LoaderOverlay>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={vm.removePartners} disabled={vm.selectedPartnersIds.size === 0}>
          {vm.translate("remove_button")} ({vm.selectedPartnersIds.size})
        </Button>
      </Modal.Footer>
    </Modal>
  );
});

registerView(partnerRemoveView, PartnerRemoveViewModel);
