import { ILocalizationService } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { ScreenBase } from "@frui.ts/screens";
import { IProductPage } from "viewModels/types";

export default class CustomerCardPageViewModel extends ScreenBase implements IProductPage {
  orderIndex = 99;
  navigationName = "customercard";
  hideInMenu = true;

  constructor(public localization: ILocalizationService) {
    super();

    this.name = this.translate("title");
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`products.customer_card.${key}`);
  }
}
