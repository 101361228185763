import { observable } from "mobx";
import { Type } from "class-transformer";
import BannerButton from "./bannerButton";
import BannerListItem from "./bannerListItem";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class BannerPreview extends BannerListItem {
  @observable
  @Type(() => BannerButton)
  links!: BannerButton[];

  @observable
  text1!: string;

  @observable
  text2?: string;

  /**
   * @example
   * file.png
   */
  @observable
  file?: string;

  static ValidationRules = Object.assign(
    {
      links: { required: true },
      text1: { required: true },
    },
    BannerListItem.ValidationRules
  );
}
