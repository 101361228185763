import { registerView, ViewComponent } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import React from "react";
import OtherProductsPageViewModel from "viewModels/products/otherProductsPageViewModel";
import ProductCardList from "components/ProductCardList";

const otherProductsPageListView: ViewComponent<OtherProductsPageViewModel> = observer(({ vm }) => {
  return <ProductCardList productCards={vm.productCards} />;
});

registerView(otherProductsPageListView, OtherProductsPageViewModel);
