import { Expose, Transform } from "class-transformer";
import formatISO from "date-fns/formatISO";
import { observable } from "mobx";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class AllowedFrequencyChange {
  /**
   * Frequency id
   */
  @observable
  @Expose({ name: "advance_frequency_id" })
  advanceFrequencyId!: number;

  /**
   * Nejspíš počet dalších záloh?
   */
  @observable
  count?: number;

  /**
   * Minimální výše zálohy?
   */
  @observable
  min?: number;

  /**
   * Optimální výše zálohy?
   */
  @observable
  amount!: number;

  /**
   * Od jaké částky požadujeme prohlášení?
   */
  @observable
  limit!: number;

  @observable
  @Transform(value => (value ? new Date(value) : undefined), { toClassOnly: true })
  @Transform(value => (value ? formatISO(value, { representation: "date" }) : undefined), { toPlainOnly: true })
  @Expose({ name: "next_payment" })
  nextPayment?: Date;

  static ValidationRules = {
    advanceFrequencyId: { required: true, number: true },
    count: { number: true },
    min: { number: true },
    amount: { required: true, number: true },
    limit: { required: true, number: true },
    nextPayment: { date: true },
  };
}
