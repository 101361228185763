import { Col, LoaderOverlay, Row, SideNav } from "@emanprague/ppaskit";
import { Router } from "@frui.ts/screens";
import { registerView, View, ViewComponent } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import React from "react";
import ProductDetailViewModel from "viewModels/products/myProducts/productDetailViewModel";

const productDetailViewModel: ViewComponent<ProductDetailViewModel> = observer(({ vm }) => {
  if ((vm.activeChild as any)?.activeChild?.fullScreen) {
    return <View vm={vm.activeChild} />;
  }

  const getUrl = Router.getChildUrlFactory(vm);

  const menuItems = vm.children.map((x: any) => ({
    id: x.navigationName,
    title: x.name,
    href: getUrl(x.navigationName),
    className: x.className,
    suffix: x.suffix,
  }));

  return vm.productDetailContext.supplyPoint ? (
    <Row noGutters>
      <Col xs={12} lg="auto" className="px-3 pl-sm-0 pr-sm-3 mb-4 mb-lg-0">
        <SideNav links={menuItems} activeId={vm.activeChild?.navigationName} />
      </Col>
      <Col lg={true} className="px-3 p-sm-0 pl-lg-3 pr-lg-0">
        <View vm={vm.activeChild} />
      </Col>
    </Row>
  ) : (
    <LoaderOverlay loading variant="transparent" text={vm.localization.translateGeneral("app_loading")} />
  );
});

registerView(productDetailViewModel, ProductDetailViewModel);
