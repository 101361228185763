import { observable } from "mobx";
import { Transform } from "class-transformer";
import formatISO from "date-fns/formatISO";
import { Expose } from "class-transformer";
import EnumPaymentMethodValue from "./enumPaymentMethodValue";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class EnumPaymentMethod {
  id!: number;

  @observable
  name!: string;

  @observable
  value!: EnumPaymentMethodValue;

  @observable
  @Expose({ name: "partner_type_id" })
  partnerTypeId!: number;

  /**
   * Změna platby záloh
   */
  @observable
  advances?: boolean;

  /**
   * Změna plateb nedoplatky
   */
  @observable
  underpayments?: boolean;

  /**
   * Změna plateb přeplatky
   */
  @observable
  overpayments?: boolean;

  @observable
  @Transform(value => (value ? new Date(value) : undefined), { toClassOnly: true })
  @Transform(value => (value ? formatISO(value, { representation: "date" }) : undefined), { toPlainOnly: true })
  @Expose({ name: "valid_from" })
  validFrom!: Date;

  @observable
  @Transform(value => (value ? new Date(value) : undefined), { toClassOnly: true })
  @Transform(value => (value ? formatISO(value, { representation: "date" }) : undefined), { toPlainOnly: true })
  @Expose({ name: "valid_to" })
  validTo?: Date;

  /**
   * Allow to choose?
   */
  @observable
  active?: boolean;

  static ValidationRules = {
    id: { required: true, number: true },
    name: { required: true },
    value: { required: true },
    partnerTypeId: { required: true, number: true },
    validFrom: { required: true, date: true },
    validTo: { date: true },
  };
}
