import React from "react";
import { styleBlock } from "../";

export interface LoaderProps {
  small?: boolean;
  big?: boolean;
}

export default (props: LoaderProps) => {
  const { big, small } = props;
  const classNames = styleBlock("loader");

  return <div className={classNames({ small, big })}></div>;
};
