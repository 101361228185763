import { IEventBus } from "@emanprague/shared-services";
import { action, observable, runInAction } from "mobx";
import NotificationsRepository from "../repositories/notificationsRepository";
import Notification from "entities/notification";
import { DownloadingEvents } from "./events";
import downloadjs from "downloadjs";
import UserContext from "./userContext";

export default class DownloadingService {
  @observable private notifications: Notification[] = [];
  constructor(
    private eventBus: IEventBus,
    private notificationsRepository: NotificationsRepository,
    private userContext: UserContext
  ) {
    eventBus.subscribe(DownloadingEvents.makeRefresh, this.loadNotifications);
    this.loadNotifications();
  }

  public get items() {
    return this.notifications;
  }

  @action.bound
  async loadNotifications() {
    const result = await this.notificationsRepository.getNotifications({ limit: 1000, offset: 0 });
    if (result.success && result.payload[0]) {
      runInAction(() => (this.notifications = result.payload[0]));
    }
  }

  @action.bound
  async downloadFile(id: number, defaultName?: string) {
    const response = await this.notificationsRepository.getFile(id);
    if (response.success) {
      const mimeType = (response.payload as any).headers.get("Content-Type") as string;
      const fileNameFromServer = defaultName ?? (response.payload as any).headers.get("content-disposition") ?? "default-name";
      const fileName = decodeURIComponent(fileNameFromServer.split("UTF-8")[1].slice(2));
      const body = await (response.payload as any).blob();

      downloadjs(body, fileName, mimeType);

      this.notificationsRepository.deleteNotification(id);
    }
  }

  @action.bound
  deleteNotification(id: number) {
    return this.notificationsRepository.deleteNotification(id);
  }
}
