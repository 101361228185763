import { ILocalizationService } from "@emanprague/shared-services";
import type { IPagingInfo } from "@frui.ts/data";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, watchBusy } from "@frui.ts/screens";
import ReadingChartDataReadingsItem from "entities/readingChartDataReadingsItem";
import { isDoubleTariff } from "helpers/supplyPointHelper";
import { action, observable, runInAction } from "mobx";
import CommodityType from "models/commodityType";
import ProductDetailContext from "models/productDetailContext";
import ReadingsRepository from "repositories/readingsRepository";

export default class ChartViewModel {
  busyWatcher = new BusyWatcher();

  @observable readingChartData: ReadingChartDataReadingsItem[];

  @observable paging: IPagingInfo = {
    offset: 0,
    limit: 5,
    totalItems: 0,
  };

  get commodityType() {
    return this.context.commodityType;
  }

  get isDoubleTariff() {
    return this.context.supplyPoint?.tariff && isDoubleTariff(this.context.supplyPoint.tariff);
  }

  constructor(
    private context: ProductDetailContext,
    private repository: ReadingsRepository,
    private localization: ILocalizationService
  ) {}

  @watchBusy
  async loadData() {
    const result = await this.repository.getReadingsListChart(
      this.context.partnerId,
      this.context.supplyPointId,
      this.paging.limit,
      this.paging.offset
    );

    if (result.success) {
      runInAction(() => {
        this.readingChartData = result.payload.data;
        this.paging.totalItems = result.payload.total ?? 0;
      });
    }
  }

  get labels() {
    if (this.readingChartData) {
      const format = this.localization.formatDate;
      return this.readingChartData.map(data => {
        if (data.from && data.to) {
          return `${format(data.from)} - ${format(data.to)}`;
        } else {
          return format(data.from || data.to);
        }
      });
    } else {
      return [];
    }
  }

  get datasets() {
    const dataNT: number[] = [];
    const dataVT: number[] = [];
    const dataTotal: number[] = [];

    if (this.readingChartData) {
      this.readingChartData.forEach((data: any) => {
        dataVT.push(data.valueVt);
        dataNT.push(data.valueNt);
        dataTotal.push(data.value);
      });
    }

    if (this.isDoubleTariff && this.commodityType === CommodityType.Electricity) {
      return [
        { label: `${this.translateGeneral("high_tariff")}`, data: dataVT },
        { label: `${this.translateGeneral("low_tariff")}`, data: dataNT },
      ];
    } else {
      return [{ label: "", data: dataTotal }];
    }
  }

  get canMoveNext() {
    return this.paging.totalItems > this.paging.offset + this.paging.limit;
  }

  @action.bound
  moveNext() {
    if (this.canMoveNext) {
      this.paging.offset += this.paging.limit;
      this.loadData();
    }
  }

  get canMoveBack() {
    return this.paging.offset > 0;
  }

  @action.bound
  moveBack() {
    if (this.canMoveBack) {
      this.paging.offset = Math.max(0, this.paging.offset - this.paging.limit);
      this.loadData();
    }
  }

  @bound translateGeneral(key: string) {
    return this.localization.translateGeneral(`general.${key}`);
  }
}
