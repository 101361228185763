import { ILocalizationService } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, ConductorSingleChild, watchBusy } from "@frui.ts/screens";
import Invoice from "entities/invoice";
import InvoiceListItem from "entities/invoiceListItem";
import { createSupplyPointsWithIcons } from "helpers/supplyPointHelper";
import { interfaces } from "inversify";
import { action, observable, runInAction } from "mobx";
import InvoicesRepository from "repositories/invoicesRepository";
import EnumsService from "services/enumsService";
import InvoiceComplaintViewModel from "./invoiceComplaintViewModel";
import OnlinePaymentViewModel from "./onlinePaymentViewModel";

export default class InvoiceDetailViewModel extends ConductorSingleChild<InvoiceComplaintViewModel | OnlinePaymentViewModel> {
  navigationName = "advanceDetail";
  busyWatcher = new BusyWatcher();

  @observable invoice: Invoice;
  @observable qrCode: string;

  constructor(
    private partnerId: number,
    public item: InvoiceListItem,
    public localization: ILocalizationService,
    private invoicesRepository: InvoicesRepository,
    private enumsService: EnumsService,
    private invoiceComplaintViewModelFactory: ReturnType<typeof InvoiceComplaintViewModel.Factory>,
    private onlinePaymentFactory: ReturnType<typeof OnlinePaymentViewModel.Factory>
  ) {
    super();

    this.navigationName = String(item.id);
  }

  @action onInitialize() {
    return this.loadData();
  }

  @bound
  activateComplaint() {
    if (this.invoice) {
      return this.tryActivateChild(this.invoiceComplaintViewModelFactory(this.invoice, this.partnerId));
    }
  }

  @bound
  activateOnlinePayment() {
    return this.tryActivateChild(this.onlinePaymentFactory(this.partnerId, this.invoice));
  }

  @bound
  @watchBusy
  async loadData() {
    if (!this.partnerId || !this.item.id) {
      return;
    }
    const response = await this.invoicesRepository.getInvoiceDetail(this.partnerId, this.item.id);
    if (response.success) {
      runInAction(() => {
        this.invoice = response.payload;
        this.qrCode = this.invoicesRepository.getQrCodeUrl(this.partnerId, this.item.id);
      });
    }
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`finance.invoice_detail.${key}`);
  }

  @bound translateAttribute(key: string) {
    return this.localization.translateAttribute("invoice", key);
  }

  @bound translateGeneral(key: string) {
    return this.localization.translateGeneral(`general.${key}`);
  }

  @bound translateInvoiceType(id: number) {
    return this.enumsService.getValue("invoiceTypes", id)?.name;
  }

  get hasCharges() {
    return this.invoice.charges && this.invoice.charges.length > 0;
  }

  get consumption() {
    const { consumption, consumptionNt } = this.invoice;
    if (consumption && consumptionNt) {
      return `${consumption} (VT), ${consumptionNt} (NT)`;
    } else if (consumption) {
      return `${consumption}`;
    } else if (consumptionNt) {
      return `${consumptionNt} (NT)`;
    } else {
      return "";
    }
  }

  get amount() {
    return this.localization.formatNumber(this.invoice.amount);
  }

  get supplyPoints() {
    return this.invoice?.supplyPoints ? createSupplyPointsWithIcons(this.enumsService, this.invoice.supplyPoints) : [];
  }

  get commodityType() {
    const commodityId = this.invoice?.supplyPoints[0]?.commodityId;
    return commodityId ? this.enumsService.getCommodityType(commodityId) : undefined;
  }

  get amountToPay() {
    return this.invoice ? this.invoice.amount + this.invoice.amountCharge - this.invoice.amountPaid : 0;
  }

  get areRemindersVisible() {
    if (this.invoice) {
      return this.invoice.reminders?.length > 0 || this.invoice.attachments.length > 0;
    }
    return false;
  }

  get isSingleSupplyPoint() {
    return !(this.invoice?.supplyPoints && this.invoice.supplyPoints.length > 1);
  }

  get headerTitle() {
    return this.isSingleSupplyPoint ? this.translate("title") : this.translate("title_common");
  }

  get supplyPointsTitle() {
    return this.localization.translateModel("supply_point", this.invoice?.supplyPoints?.length);
  }

  get isOverdue() {
    return this.invoice?.state === "unpaid";
  }

  static Factory({ container }: interfaces.Context) {
    return (item: InvoiceListItem, partnerId: number) => {
      return new InvoiceDetailViewModel(
        partnerId,
        item,
        container.get("ILocalizationService"),
        container.get(InvoicesRepository),
        container.get(EnumsService),
        container.get(InvoiceComplaintViewModel.Factory),
        container.get(OnlinePaymentViewModel.Factory)
      );
    };
  }

  protected findNavigationChild(navigationName: string | undefined) {
    return undefined;
  }
}
