import { Expose, Transform } from "class-transformer";
import formatISO from "date-fns/formatISO";
import { observable } from "mobx";
import { EntityValidationRules } from "services/validation/entityValidationRules";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class ReqRegistrationStep3 {
  @observable
  @Transform(value => (value ? new Date(value) : undefined), { toClassOnly: true })
  @Transform(value => (value ? formatISO(value, { representation: "date" }) : undefined), { toPlainOnly: true })
  @Expose({ name: "birth_date" })
  birthDate!: Date;

  /**
   * @example
   * +420606707808
   */
  @observable
  phone!: string;

  static ValidationRules = {
    birthDate: { required: true, date: true },
    phone: { required: true },
  };
}

export const customHouseholdValidationRules: EntityValidationRules<ReqRegistrationStep3> = {
  birthDate: { required: true, date: true },
  phone: { required: true, isPhone: true },
};

export const customCompanyValidationRules: EntityValidationRules<ReqRegistrationStep3> = {
  phone: { required: true, isPhone: true },
};
