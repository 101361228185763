import { bound } from "@frui.ts/helpers";
import { observable, runInAction } from "mobx";
import {
  ILocaleProvider,
  ILocalizedResource,
  IResourceDictionary,
  IResourcesContainer,
  isResourceDictionary,
  LocaleCode,
} from "../contracts/localeProvider";
import { ILocalizationService } from "../contracts/localizationService";

export default class LocalizationService implements ILocalizationService {
  @observable currentLocale: LocaleCode;
  @observable.ref protected resources: IResourcesContainer;
  protected currentPluralRules: Intl.PluralRules;

  constructor(protected provider: ILocaleProvider) {}

  initialize(defaultLocale: LocaleCode) {
    return this.setLocale(defaultLocale);
  }

  getAvailableLocales() {
    return this.provider.getAvailableLocales();
  }

  async setLocale(locale: LocaleCode) {
    const resources = await this.provider.fetchResources(locale);

    runInAction(() => {
      this.currentLocale = locale;
      this.resources = resources;
      this.currentPluralRules = new Intl.PluralRules(locale);
    });
  }

  @bound translateGeneral(code: string, placeholders?: Record<string, string>) {
    const translated = this.translate(this.resources.general, code);
    return placeholders ? this.processTemplate(translated, placeholders) : translated;
  }

  @bound translateModel(name: string, size?: number) {
    const pluralForm = this.getPluralForm(size);
    return this.translate(this.resources.models.models, `${name}.${pluralForm}`);
  }

  @bound translateAttribute(model: string, attribute: string): string {
    let resource = this.findResource(this.resources.models.attributes, `${model}.${attribute}`);
    if (!resource) {
      resource = this.findResource(this.resources.attributes, attribute);
    }

    if (!resource) {
      console.warn(`Could not find localized attribute name '${attribute}' for '${model}'.`);
      return attribute;
    }

    return resource as string;
  }

  @bound formatDate(date: Date | undefined, options?: Intl.DateTimeFormatOptions): string {
    return date ? date.toLocaleDateString(this.currentLocale, options) : "";
  }
  @bound formatTime(time: Date | undefined, options?: Intl.DateTimeFormatOptions): string {
    return time ? time.toLocaleTimeString(this.currentLocale, options) : "";
  }
  @bound formatDateTime(date: Date | undefined, options?: Intl.DateTimeFormatOptions): string {
    return date ? date.toLocaleString(this.currentLocale, options) : "";
  }
  @bound formatNumber(number: number | undefined, options?: Intl.NumberFormatOptions): string {
    return number !== undefined ? number.toLocaleString(this.currentLocale, options) : "";
  }

  processTemplate(template: string, params: Record<string, string>) {
    return template.replace(LocalizationService.placeholderRegex, (match, key) => params[key]);
  }

  protected translate(root: IResourceDictionary, code: string) {
    const resource = this.findResource(root, code);
    if (resource) {
      return resource as string;
    } else {
      console.warn(`Could not find localized resource '${code}'.`);
      return code;
    }
  }

  protected findResource(root: IResourceDictionary, code: string) {
    if (!code) {
      return undefined;
    }

    let scope: ILocalizedResource = root;

    const segments = code.split(".");
    for (const key of segments) {
      if (!isResourceDictionary(scope)) {
        return undefined;
      }

      scope = scope[key];
    }

    return scope;
  }

  static placeholderRegex = /%\{(\w+)\}/g;

  getPluralForm(size = 1) {
    return this.currentPluralRules.select(size);
  }
}
