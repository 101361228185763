import { Button, HeaderRow, LoaderOverlay, Modal, Col, Form } from "@emanprague/ppaskit";
import { preventDefault, registerView, View, ViewComponent } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import React from "react";
import InvoiceComplaintViewModel from "viewModels/finance/invoiceComplaintViewModel";
import SupplyPointSelect from "controls/twoway/SupplyPointSelect";
import { Check, Input, Select } from "@frui.ts/bootstrap";
import CommodityType from "models/commodityType";
import ComplaintType from "models/complaintType";
import { getCommodityUnit } from "helpers/commodityHelper";
import Uploader from "controls/uploader";

const supplyPointSelect: ViewComponent<InvoiceComplaintViewModel> = observer(({ vm }) => (
  <>
    <Modal.Body>
      <Form className="modal-form">
        <Form.Row className="form-row-centered">
          <Form.Group className="text-center" as={Col} xs={12}>
            <strong>{vm.translate("related_supply_point")}</strong>
            <SupplyPointSelect target={vm} property="selectedSupplyPointIds" supplyPoints={vm.supplyPoints} search={false} />
          </Form.Group>
        </Form.Row>
      </Form>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={vm.requestClose}>
        {vm.translateGeneral("cancel_button")}
      </Button>
      <Button
        variant="primary"
        onClick={vm.openComplaintDetailsForm}
        disabled={!(vm.selectedSupplyPointIds && vm.selectedSupplyPointIds[0])}>
        {vm.translateGeneral("continue_button")}
      </Button>
    </Modal.Footer>
  </>
));

registerView(supplyPointSelect, InvoiceComplaintViewModel, "supplyPointSelect");

const complaintDetails: ViewComponent<InvoiceComplaintViewModel> = observer(({ vm }) => (
  <>
    <Modal.Body>
      <div className="w-100 text-muted font-size-sm text-center mb-3">
        {vm.translate("invoice_number")} {vm.invoice.code}
      </div>
      <Form className="modal-form" onSubmit={preventDefault(vm.createComplaint)}>
        <Form.Row className="form-row-centered">
          <Form.Group as={Col} xs={11}>
            <Form.Label>{vm.translate("reason")}</Form.Label>
            <Select
              target={vm.invoiceComplaint}
              property="claimTypeId"
              items={vm.complaintReasons}
              mode="key"
              keyProperty="id"
              textProperty="name"
              onValueChanged={vm.selectedComplaintTypeChanged}
            />

            {vm.selectedComplaintType === ComplaintType.IncorrectFinalState && (
              <>
                {vm.commodityType === CommodityType.Gas && (
                  <>
                    <Form.Label className="mt-3">
                      {vm.translate("complaint_state")} ({getCommodityUnit(vm.commodityType)})
                    </Form.Label>
                    <Input target={vm.consumptionData} property="oldValue" />
                    <Form.Text className="font-size-sm text-muted">{vm.translate("state_hint")}</Form.Text>

                    <Form.Label className="mt-3">{vm.translate("current_state")}</Form.Label>
                    <Input target={vm.consumptionData} property="currentValue" />

                    <Form.Label className="mt-3">
                      {vm.translate("wanted_state")}
                      <span className="text-muted text-light">{" " + vm.translateGeneral("not_mandatory")}</span>
                    </Form.Label>
                    <Input target={vm.consumptionData} property="claimedValue" />
                  </>
                )}
                {vm.commodityType === CommodityType.Electricity && (
                  <>
                    <Form.Label className="mt-3">{vm.translate("rate")}</Form.Label>
                    <Select
                      target={vm.invoiceComplaint}
                      property="rateId"
                      items={vm.rates}
                      mode="key"
                      keyProperty="id"
                      textProperty="value"
                      onValueChanged={() => vm.handleRateChanged()}
                    />

                    {vm.invoiceComplaint.rateId && (
                      <>
                        {vm.isDoubleTariff ? (
                          <>
                            <Check
                              target={vm.consumptionData}
                              property="complaintVt"
                              custom
                              label={vm.translate("high_tariff")}
                              className="mt-5"
                              onValueChanged={vm.handleComplaintSelectChanged}
                            />
                            {vm.consumptionData.complaintVt && (
                              <>
                                <Form.Label className="mt-3">
                                  {vm.translate("complaint_state")} ({getCommodityUnit(vm.commodityType)})
                                </Form.Label>
                                <Input target={vm.consumptionData} property="oldValue" />
                                <Form.Text className="font-size-sm text-muted">{vm.translate("state_hint")}</Form.Text>

                                <Form.Label className="mt-3">{vm.translate("current_state")}</Form.Label>
                                <Input target={vm.consumptionData} property="currentValue" />

                                <Form.Label className="mt-3">
                                  {vm.translate("wanted_state")}
                                  <span className="text-muted text-light">{" " + vm.translateGeneral("not_mandatory")}</span>
                                </Form.Label>
                                <Input target={vm.consumptionData} property="claimedValue" />
                              </>
                            )}

                            <Check
                              target={vm.consumptionData}
                              property="complaintNt"
                              custom
                              label={vm.translate("low_tariff")}
                              className="mt-4"
                              onValueChanged={vm.handleComplaintSelectChanged}
                            />
                            {vm.consumptionData.complaintNt && (
                              <>
                                <Form.Label className="mt-3">
                                  {vm.translate("complaint_state")} ({getCommodityUnit(vm.commodityType)})
                                </Form.Label>
                                <Input target={vm.consumptionData} property="oldValueNt" />
                                <Form.Text className="font-size-sm text-muted">{vm.translate("state_hint")}</Form.Text>

                                <Form.Label className="mt-3">{vm.translate("current_state")}</Form.Label>
                                <Input target={vm.consumptionData} property="currentValueNt" />

                                <Form.Label className="mt-3">
                                  {vm.translate("wanted_state")}
                                  <span className="text-muted text-light">{" " + vm.translateGeneral("not_mandatory")}</span>
                                </Form.Label>
                                <Input target={vm.consumptionData} property="claimedValueNt" />
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            <Form.Label className="mt-3">
                              {vm.translate("complaint_state")} ({getCommodityUnit(vm.commodityType)})
                            </Form.Label>
                            <Input target={vm.consumptionData} property="oldValue" />
                            <Form.Text className="font-size-sm text-muted">{vm.translate("state_hint")}</Form.Text>

                            <Form.Label className="mt-3">{vm.translate("current_state")}</Form.Label>
                            <Input target={vm.consumptionData} property="currentValue" />

                            <Form.Label className="mt-3">
                              {vm.translate("wanted_state")}
                              <span className="text-muted text-light">{" " + vm.translateGeneral("not_mandatory")}</span>
                            </Form.Label>
                            <Input target={vm.consumptionData} property="claimedValue" />
                          </>
                        )}
                      </>
                    )}
                  </>
                )}

                <Form.Label className="mt-5">{vm.translate("comment")}</Form.Label>
                <Input
                  {...{ as: "textarea", rows: 5 }}
                  target={vm.invoiceComplaint}
                  property="note"
                  placeholder={vm.translate("note_hint")}
                />

                <Form.Label className="mt-5">
                  <strong>{vm.translateGeneral("upload_attachment")}</strong>
                  <div className="hint-text">{vm.translate("upload_hint")}</div>
                </Form.Label>
                <Uploader
                  localization={vm.localization}
                  onDrop={vm.onDrop}
                  onDropRejected={vm.onDropRejected}
                  fileSelectError={vm.uploaderErrorMessage}
                />
              </>
            )}

            {vm.selectedComplaintType === ComplaintType.IncorrectBilling && (
              <>
                <Form.Label className="mt-3">{vm.translate("comment")}</Form.Label>
                <Input
                  {...{ as: "textarea", rows: 5 }}
                  target={vm.invoiceComplaint}
                  property="note"
                  placeholder={vm.translate("note_hint")}
                  className="mb-3"
                />

                <Form.Label className="mt-4 mb-4">
                  <strong>{vm.translate("mandatory_upload_hint")}</strong>
                </Form.Label>
                <Uploader
                  localization={vm.localization}
                  onDrop={vm.onDrop}
                  onDropRejected={vm.onDropRejected}
                  fileSelectError={vm.uploaderErrorMessage}
                />
              </>
            )}

            {vm.selectedComplaintType === ComplaintType.Other && (
              <>
                <Form.Label className="mt-3">{vm.translate("comment")}</Form.Label>
                <Input
                  {...{ as: "textarea", rows: 5 }}
                  target={vm.invoiceComplaint}
                  property="note"
                  placeholder={vm.translate("note_hint")}
                  className="mb-3"
                />

                <Form.Label className="mt-4">
                  <strong>{vm.translateGeneral("upload_attachment")}</strong>
                  <span className="text-muted">{" " + vm.translateGeneral("not_mandatory")}</span>
                  <div className="hint-text">{vm.translate("upload_hint")}</div>
                </Form.Label>
                <Uploader
                  localization={vm.localization}
                  onDrop={vm.onDrop}
                  onDropRejected={vm.onDropRejected}
                  fileSelectError={vm.fileSelectError}
                />
              </>
            )}
            {vm.errorMessage && <Form.Control.Feedback type="invalid">{vm.errorMessage}</Form.Control.Feedback>}
          </Form.Group>
        </Form.Row>
      </Form>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={vm.requestClose}>
        {vm.translateGeneral("cancel_button")}
      </Button>
      <Button variant="primary" onClick={vm.createComplaint} disabled={!vm.canConfirm}>
        {vm.translateGeneral("send_button")}
      </Button>
    </Modal.Footer>
  </>
));

registerView(complaintDetails, InvoiceComplaintViewModel, "complaintDetails");

const invoiceComplaintView: ViewComponent<InvoiceComplaintViewModel> = observer(({ vm }) => (
  <Modal centered show onHide={vm.requestClose}>
    <Modal.Header closeButton>
      <HeaderRow title={vm.name} level={4} />
    </Modal.Header>
    <LoaderOverlay loading={vm.busyWatcher.isBusy}>
      <View vm={vm} context={vm.currentActiveView} />
    </LoaderOverlay>
  </Modal>
));

registerView(invoiceComplaintView, InvoiceComplaintViewModel);
