import { ILocalizationService, INotificationService, SeverityLevel } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, ScreenBase, watchBusy } from "@frui.ts/screens";
import { action, observable, runInAction } from "mobx";
import { interfaces } from "inversify";
import { unwrapErrorMessage } from "repositories/helpers";
import ReqMktAgreement from "entities/reqMktAgreement";
import AccountsRepository from "repositories/accountsRepository";
import UserContext from "services/userContext";

export default class ModalEditMarketingAgreementViewModel extends ScreenBase {
  static navigationName = "marketing";

  navigationName = ModalEditMarketingAgreementViewModel.navigationName;
  busyWatcher = new BusyWatcher();

  @observable data: ReqMktAgreement;
  @observable errorMessage?: string;

  constructor(
    private partnerId: number,
    public localization: ILocalizationService,
    private accountsRepository: AccountsRepository,
    private userContext: UserContext,
    private notificationService: INotificationService
  ) {
    super();

    this.name = this.translate("agreements");
    this.data = new ReqMktAgreement();
  }

  @action
  protected onInitialize() {
    this.data.mktAgreement = this.currentMktAgreementState;
  }

  @bound
  @watchBusy
  async confirmAgreement() {
    if (this.data.mktAgreement === undefined) {
      return;
    }

    const response = await this.accountsRepository.setMarketingAgreement(this.partnerId, this.data);

    if (response.success) {
      this.notificationService.addNotification(this.translateGeneral("request_sent_success"), SeverityLevel.success);
      this.userContext.setMktAgreement(this.data.mktAgreement);
      this.requestClose();
    } else {
      runInAction(() => (this.errorMessage = unwrapErrorMessage(response.payload)));
    }
  }

  get currentMktAgreementState() {
    return this.userContext.activePartner?.marketingAgreement;
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`products.customer_info.${key}`);
  }

  @bound translateGeneral(key: string) {
    return this.localization.translateGeneral(`general.${key}`);
  }

  static Factory({ container }: interfaces.Context) {
    return (partnerId: number) => {
      return new ModalEditMarketingAgreementViewModel(
        partnerId,
        container.get("ILocalizationService"),
        container.get(AccountsRepository),
        container.get(UserContext),
        container.get("INotificationService")
      );
    };
  }
}
