import { ILocalizationService } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { Router, ScreenBase } from "@frui.ts/screens";
import { groupBy } from "helpers/utils";
import CommodityType from "models/commodityType";
import DataSyncService from "services/dataSyncService";
import EnumsService from "services/enumsService";
import UserContext from "services/userContext";

export default class SupplyPointsViewModel extends ScreenBase {
  get supplyPointsByType() {
    const supplyPoints = this.dataService.supplyPoints;
    return groupBy(supplyPoints, x => this.enumsService.getCommodityType(x.commodityId) as CommodityType);
  }

  constructor(
    public localization: ILocalizationService,
    public router: Router,
    private userContext: UserContext,
    private dataService: DataSyncService,
    private enumsService: EnumsService
  ) {
    super();
  }

  get newSupplyPointLink() {
    return this.userContext.newSupplyPointLink;
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`dashboard.${key}`);
  }
}
