import { ILocalizationService } from "@emanprague/shared-services";
import { ResponsiveColumnDefinition } from "@frui.ts/dataviews";

export type ColumnsContext<TViewModel> = {
  vm: TViewModel;
  tg: (code: string) => string;
  ta: (model: string, name: string) => string;
  tm: (name: string, size: number) => string;
};

export type DataColumn<TEntity, TViewModel = any> = ResponsiveColumnDefinition<TEntity, ColumnsContext<TViewModel>> & {
  scope?: string;
};

export function createColumnsContext<TViewModel extends { localization: ILocalizationService }>(
  viewModel: TViewModel
): ColumnsContext<TViewModel> {
  const { translateGeneral: tg, translateAttribute: ta, translateModel: tm } = viewModel.localization;

  return {
    vm: viewModel,
    tg,
    ta,
    tm,
  };
}
