import { Expose, Transform, Type } from "class-transformer";
import formatISO from "date-fns/formatISO";
import { observable } from "mobx";
import PaymentItem from "./paymentItem";
import SupplyPointSimple from "./supplyPointSimple";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class PaymentListPaymentsItem {
  id!: number;

  /**
   * Amount
   */
  @observable
  amount!: number;

  @observable
  type!: string;

  @observable
  vs!: string;

  @observable
  @Expose({ name: "account_id" })
  accountId!: number;

  @observable
  @Transform(value => (value ? new Date(value) : undefined), { toClassOnly: true })
  @Transform(value => (value ? formatISO(value, { representation: "date" }) : undefined), { toPlainOnly: true })
  date!: Date;

  @observable
  @Expose({ name: "document_id" })
  documentId?: string;

  @observable
  file?: string;

  @observable
  @Type(() => SupplyPointSimple)
  @Expose({ name: "supply_points" })
  supplyPoints!: SupplyPointSimple[];

  @observable
  @Type(() => PaymentItem)
  @Expose({ name: "payment_items" })
  paymentItems?: PaymentItem[];

  static ValidationRules = {
    id: { required: true, number: true },
    amount: { required: true, number: true },
    type: { required: true },
    vs: { required: true },
    accountId: { required: true, number: true },
    date: { required: true, date: true },
    supplyPoints: { required: true },
  };
}
