import { BusyWatcher, ScreenBase, watchBusy } from "@frui.ts/screens";
import ProductDetailContext from "models/productDetailContext";
import { ILocalizationService, INotificationService, SeverityLevel } from "@emanprague/shared-services";
import EnumsService from "services/enumsService";
import { interfaces } from "inversify";
import { bound } from "@frui.ts/helpers";
import { action, computed, observable, runInAction } from "mobx";
import ReqChangeInvoicePayment from "entities/reqChangeInvoicePayment";
import {
  attachAutomaticValidator,
  hasVisibleErrors,
  hideValidationErrors,
  ManualEntityValidator,
  validate,
} from "@frui.ts/validation";
import SupplyPointsRepository from "repositories/supplyPointsRepository";
import DataSyncService from "services/dataSyncService";
import { createSupplyPointsWithIcons, SupplyPointError } from "helpers/supplyPointHelper";
import UserContext from "services/userContext";
import EnumPaymentMethodValue from "entities/enumPaymentMethodValue";
import merge from "lodash/merge";
import { EntityValidationRules } from "services/validation/entityValidationRules";
import { addManualValidation, applyServerErrors } from "services/validation/validationHelpers";

export default class ModalInvoicePaymentMethodViewModel extends ScreenBase {
  static navigationName = "invoice_payment";

  navigationName = ModalInvoicePaymentMethodViewModel.navigationName;
  busyWatcher = new BusyWatcher();

  @observable data: ReqChangeInvoicePayment;
  @observable supplyPointsErrors: SupplyPointError[] = [];
  @observable validationRules: EntityValidationRules<ReqChangeInvoicePayment> = {};

  manualValidator = new ManualEntityValidator<ReqChangeInvoicePayment & { base: any }>(true);

  constructor(
    private productDetailContext: ProductDetailContext,
    public localization: ILocalizationService,
    private supplyPointRepository: SupplyPointsRepository,
    private enumsService: EnumsService,
    private dataService: DataSyncService,
    private notificationService: INotificationService,
    private userContext: UserContext
  ) {
    super();

    const account = productDetailContext.supplyPoint?.account;
    if (!account) {
      throw new Error("Supply point detail is not loaded");
    }

    this.data = new ReqChangeInvoicePayment();
    this.data.supplyPointIds = this.currentSupplyPoints.map(i => i.id);
    this.data.overpaymentMethodId = account.overpaymentMethodId;
    this.data.underpaymentMethodId = account.underpaymentMethodId;

    // Don't set values as null (undefined is right way to not sent values)
    if (account.overpaymentAccountPrefix && account.overpaymentAccountPrefix !== 0) {
      this.data.overpaymentAccountPrefix = account.overpaymentAccountPrefix;
    }
    this.data.overpaymentAccount = account.overpaymentAccount ?? undefined;
    this.data.overpaymentAccountBankId = account.overpaymentAccountBankId ?? undefined;

    if (account.underpaymentAccountPrefix && account.underpaymentAccountPrefix !== 0) {
      this.data.underpaymentAccountPrefix = account.underpaymentAccountPrefix;
    }
    this.data.underpaymentAccount = account.underpaymentAccount ?? undefined;
    this.data.underpaymentAccountBankId = account.underpaymentAccountBankId ?? undefined;

    // We don't have underpayment account or bank id..
    this.setValidationRules(this.validationRules);
    attachAutomaticValidator(this.data, addManualValidation(this.validationRules, this.manualValidator));
    this.name = this.translate("invoice_payment_method");
  }

  @action.bound
  @watchBusy
  async confirmChange() {
    this.manualValidator.clearErrors();
    this.supplyPointsErrors = [];

    validate(this.data);

    if (!this.canConfirm) {
      return;
    }

    const response = await this.supplyPointRepository.changeInvoicePaymentMethods(this.productDetailContext.partnerId, this.data);

    if (response.success) {
      runInAction(() => (this.supplyPointsErrors = response.payload.supplyPoints.filter(item => !item.status)));
      if (this.supplyPointsErrors.length === 0) {
        this.notificationService.addNotification(this.translateGeneral("request_sent_success"), SeverityLevel.success);
        this.requestClose();
      }
    } else {
      applyServerErrors(
        {
          ...response.payload,
          errors: ReqChangeInvoicePayment.getTranslatedErrors(response.payload.errors),
        },
        this.manualValidator
      );
    }
  }

  @bound clearManualError(value: unknown, property: string) {
    this.manualValidator.removeError(property as any);
  }

  paymentMethodValue(key: number | undefined): EnumPaymentMethodValue | undefined {
    if (key) {
      return this.enumsService.getValue("paymentMethods", key)?.value;
    }

    return undefined;
  }

  @action.bound
  onUnderpaymentMethodChange() {
    this.data.underpaymentAccountPrefix = undefined;
    this.data.underpaymentAccount = undefined;
    this.data.underpaymentAccountBankId = undefined;
    hideValidationErrors(this.data);
  }

  @action.bound
  onOverpaymentMethodChange() {
    this.data.overpaymentAccountPrefix = undefined;
    this.data.overpaymentAccount = undefined;
    this.data.overpaymentAccountBankId = undefined;
    hideValidationErrors(this.data);
  }

  @action.bound
  setValidationRules(target: EntityValidationRules) {
    merge(target, ReqChangeInvoicePayment.ValidationRules, {
      underpaymentMethodId: { required: true },
      underpaymentAccountPrefix: {
        isNumeric: true,
        range: { min: 0, maxExclusive: 1000000, translationCode: "validators.account_prefix" },
      },
      underpaymentAccount: {
        requiredIf: {
          condition: (item: ReqChangeInvoicePayment) =>
            this.selectedUnderpaymentMethod === "zpusobPlatbyPrikazKUhrade" ||
            this.selectedUnderpaymentMethod === "zpusobPlatbyPrimeInkaso",
        },
        isNumeric: true,
        range: {
          min: 10,
          maxExclusive: 10000000000,
          translationCode: "validators.account",
        },
      },
      underpaymentAccountBankId: {
        requiredIf: {
          condition: (item: ReqChangeInvoicePayment) =>
            this.selectedUnderpaymentMethod === "zpusobPlatbyPrikazKUhrade" ||
            this.selectedUnderpaymentMethod === "zpusobPlatbyPrimeInkaso",
        },
      },

      overpaymentMethodId: { required: true },
      overpaymentAccountPrefix: {
        isNumeric: true,
        range: { min: 0, maxExclusive: 1000000, translationCode: "validators.account_prefix" },
      },
      overpaymentAccount: {
        requiredIf: {
          condition: (item: ReqChangeInvoicePayment) => this.selectedOverpaymentMethod === "zpusobPlatbyPrikazKUhrade",
        },
        isNumeric: true,
        range: {
          min: 10,
          maxExclusive: 10000000000,
          translationCode: "validators.account",
        },
      },
      overpaymentAccountBankId: {
        requiredIf: {
          condition: (item: ReqChangeInvoicePayment) => this.selectedOverpaymentMethod === "zpusobPlatbyPrikazKUhrade",
        },
      },
    });
  }

  get canConfirm() {
    return !hasVisibleErrors(this.data);
  }

  @computed
  get selectedUnderpaymentMethod() {
    return this.paymentMethodValue(this.data.underpaymentMethodId);
  }

  @computed
  get selectedOverpaymentMethod() {
    return this.paymentMethodValue(this.data.overpaymentMethodId);
  }

  @computed
  get currentSupplyPoints() {
    const currentAccountId = this.productDetailContext.supplyPointCore.accountId;
    return this.dataService.supplyPoints.filter(item => item.account.id === currentAccountId);
  }

  @computed
  get currentSupplyPointsWithIcons() {
    return createSupplyPointsWithIcons(this.enumsService, this.currentSupplyPoints, "grey", this.supplyPointsErrors);
  }

  /**
   * Allow to select other supply points.
   */
  get additionalSupplyPointsWithIcons() {
    return createSupplyPointsWithIcons(
      this.enumsService,
      this.dataService.supplyPoints.filter(item => !this.currentSupplyPoints.some(selectedItem => selectedItem.id === item.id)),
      "grey",
      this.supplyPointsErrors
    );
  }

  get bankCodes() {
    return this.enumsService.getValues("banks").filter(x => x.active);
  }

  get underpaymentMethods() {
    const partnerTypeId = this.userContext.activePartner?.partnerTypeId;

    if (!partnerTypeId) {
      return [];
    }

    return this.enumsService
      .getValues("paymentMethods")
      .filter(x => x.active && x.underpayments && x.partnerTypeId === partnerTypeId)
      .map(x => ({
        id: x.id,
        name: x.name,
      }));
  }

  get overpaymentMethods() {
    const partnerTypeId = this.userContext.activePartner?.partnerTypeId;

    if (!partnerTypeId) {
      return [];
    }

    return this.enumsService
      .getValues("paymentMethods")
      .filter(x => x.active && x.overpayments && x.partnerTypeId === partnerTypeId)
      .map(x => ({
        id: x.id,
        name: x.name,
      }));
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`products.options_deposit.${key}`);
  }

  @bound translateGeneral(key: string, placeholders?: Record<string, string>) {
    return this.localization.translateGeneral(`general.${key}`, placeholders);
  }

  static Factory({ container }: interfaces.Context) {
    return (productDetailContext: ProductDetailContext) => {
      return new ModalInvoicePaymentMethodViewModel(
        productDetailContext,
        container.get("ILocalizationService"),
        container.get(SupplyPointsRepository),
        container.get(EnumsService),
        container.get(DataSyncService),
        container.get("INotificationService"),
        container.get(UserContext)
      );
    };
  }
}
