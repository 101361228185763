import React, { useState } from "react";
import Loader from "@emanprague/basekit/dist/Loader";

import iconArrowSimpleDown from "../assets/icons/Arrow Simple/Down.svg";
import iconArrowSimpleUp from "../assets/icons/Arrow Simple/Up.svg";
import iconStateOk from "../assets/icons/State/Ok.svg";
import iconStateNotOk from "../assets/icons/State/Not Ok.svg";

import { styleBlock } from "../";

export enum QueueItemType {
  COMPLETE = 1,
  FAIL = 2,
  IN_PROGRESS = 3,
}

export interface QueueItem {
  id: number;
  label: string;
  type: QueueItemType;
}

export interface DownloadingQueueProps {
  title: string;
  items: QueueItem[];
  onClick: (item: QueueItem) => void;
}

const DownloadingQueue = (props: DownloadingQueueProps) => {
  const { title, items, onClick } = props;
  const [isOpen, setOpen] = useState(true);

  const b = styleBlock("downloading_queue");

  if (items.length === 0) {
    return null;
  }

  const itemData = items.map((item: QueueItem) => {
    let icon;
    const handleOnCLick = () => onClick(item);

    switch (item.type) {
      case QueueItemType.FAIL:
        icon = <img src={iconStateNotOk} alt="not ok" />;
        break;
      case QueueItemType.IN_PROGRESS:
        icon = <Loader small />;
        break;
      default:
        icon = <img src={iconStateOk} alt="ok" />;
        break;
    }

    return (
      <div className="item" key={`notification-item-${item.id}`}>
        <span className="item_content" onClick={handleOnCLick} title={item.label}>
          {item.label}
        </span>
        {icon}
      </div>
    );
  });

  return (
    <div className={b.toString()}>
      <h1 onClick={() => setOpen(!isOpen)}>
        {title} <img src={isOpen ? iconArrowSimpleDown : iconArrowSimpleUp} alt="arrow" />
      </h1>
      {isOpen && <section>{itemData}</section>}
    </div>
  );
};

export default DownloadingQueue;
