import { observable } from "mobx";
import { Type } from "class-transformer";
import AuditLogListItem from "./auditLogListItem";

export default class AuditLogList {
  @observable
  total!: number;

  @observable
  @Type(() => AuditLogListItem)
  items!: AuditLogListItem[];

  static ValidationRules = {
    total: { required: true, number: true },
    items: { required: true },
  };
}
