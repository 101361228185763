import { IPagingFilter } from "@frui.ts/data";
import RepositoryBase from "./repositoryBase";
import LinksFilter from "models/linksFilter";
import LinkList from "entities/linkList";
import ReqLinksInSection from "entities/reqLinksInSection";
import { entityToPlain } from "./helpers";

export default class LinksRepository extends RepositoryBase {
  getLinks(paging: IPagingFilter, filter?: LinksFilter) {
    const query = entityToPlain(filter);
    return this.callApi(api => api.all("links").getPagedEntities(LinkList, x => x.links, paging, query));
  }

  updateLinks(data: ReqLinksInSection) {
    return this.callApi(api => api.all("admin/links").putOnly(data));
  }
}
