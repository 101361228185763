import { registerView, View, ViewComponent } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import React from "react";
import AttachmentsViewModel, { defaultAmountRange } from "viewModels/dashboard/attachments/attachmentsViewModel";
import { ButtonTooltip, InputSearch, Layout, LoaderOverlay } from "@emanprague/ppaskit";
import { Button, Card, Col, Row } from "@emanprague/ppaskit";
import { createColumnsContext, DataColumn } from "controls/tableHelper";
import { ResponsiveTable } from "@frui.ts/dataviews";
import iconEdit from "@emanprague/ppaskit/dist/assets/icons/Edit Light.svg";
import iconDelete from "@emanprague/ppaskit/dist/assets/icons/Delete Light.svg";
import iconDownload from "@emanprague/ppaskit/dist/assets/icons/Download Blue.svg";
import "./style.scss";
import AttachmentListItem from "entities/attachmentListItem";
import EmptyDataMessage from "components/EmptyDataMessage";
import { formatBytesToMB } from "helpers/utils";
import FilterButton from "controls/filterButton";
import { Input } from "@frui.ts/bootstrap";
import DatePicker from "controls/twoway/DatePicker";
import RangeInput from "controls/twoway/RangeInput";

const columns: DataColumn<AttachmentListItem, AttachmentsViewModel>[] = [
  {
    titleFactory: ({ vm, ta }) => (
      <>
        {ta("file", "name")}
        <FilterButton
          id="idFilter"
          isActive={!!vm.filter.fileName}
          clearLabel={vm.translateGeneral("cancel_button")}
          confirmLabel={vm.translateGeneral("confirm")}
          onClear={() => vm.clearFilter("fileName")}
          onConfirm={vm.applyFilterAndLoad}>
          <Input target={vm.filter} property="fileName" placeholder={vm.translateGeneral("search")} />
        </FilterButton>
      </>
    ),
    responsiveTitleFactory: ({ ta }) => ta("file", "name"),
    valueFormatter: ({ item }) => (
      <span className="text-nowrap">
        {item.title && <div className="font-weight-bold">{item.title}</div>}
        {item.fileName}
      </span>
    ),
  },
  {
    property: "description",
    titleFactory: ({ vm, ta }) => (
      <>
        {ta("file", "description")}
        <FilterButton
          id="idFilter"
          isActive={!!vm.filter.description}
          clearLabel={vm.translateGeneral("cancel_button")}
          confirmLabel={vm.translateGeneral("confirm")}
          onClear={() => vm.clearFilter("description")}
          onConfirm={vm.applyFilterAndLoad}>
          <Input target={vm.filter} property="description" placeholder={vm.translateGeneral("search")} />
        </FilterButton>
      </>
    ),
    responsiveTitleFactory: ({ ta }) => ta("file", "description"),
    valueFormatter: ({ value }) => <span className="text-nowrap">{value}</span>,
  },
  {
    property: "fileSize",
    titleFactory: ({ vm, ta }) => (
      <>
        {ta("file", "size")}
        <FilterButton
          id="amountFilter"
          isActive={!!vm.fileSizeFromKB || !!vm.fileSizeToKB}
          clearLabel={vm.translateGeneral("cancel_button")}
          confirmLabel={vm.translateGeneral("confirm")}
          onClear={vm.clearFileSizeFilter}
          onConfirm={vm.applyFileSizeFilter}>
          <RangeInput
            target={vm}
            property="amountRange"
            allowUserOverride={true}
            min={defaultAmountRange[0]}
            max={defaultAmountRange[1]}
            step={100}
            disabled={false}
            autofocus
            suffix=" kB"
            name="rangeslider"
            form="rangeform"
          />
        </FilterButton>
      </>
    ),
    responsiveTitleFactory: ({ ta }) => ta("file", "size"),
    valueFormatter: ({ value }) => formatBytesToMB(value),
  },
  {
    property: "createdAt",
    titleFactory: ({ vm, ta }) => (
      <>
        {ta("file", "upload_date")}
        <FilterButton
          id="dueDateFilter"
          isActive={!!vm.filter.createdAtFrom || !!vm.filter.createdAtTo}
          clearLabel={vm.translateGeneral("cancel_button")}
          confirmLabel={vm.translateGeneral("confirm")}
          onClear={() => vm.clearFilter("createdAtFrom", "createdAtTo")}
          onConfirm={vm.applyFilterAndLoad}>
          <Row className="align-items-center">
            <Col className="mr-n3">
              <span className="date-filter">{vm.translateGeneral("filter_date_from")}</span>
              <DatePicker
                target={vm.filter}
                property="createdAtFrom"
                selectsStart
                startDate={vm.filter.createdAtFrom}
                endDate={vm.filter.createdAtTo}
                maxDate={vm.filter.createdAtTo}
              />
            </Col>
            <Col>
              <span className="date-filter to">{vm.translateGeneral("filter_date_to")}</span>
              <DatePicker
                target={vm.filter}
                property="createdAtTo"
                selectsEnd
                startDate={vm.filter.createdAtFrom}
                endDate={vm.filter.createdAtTo}
                minDate={vm.filter.createdAtFrom}
              />
            </Col>
          </Row>
        </FilterButton>
      </>
    ),
    responsiveTitleFactory: ({ ta }) => ta("file", "upload_date"),
    valueFormatter: ({ value, context }) => <span className="text-nowrap">{context.vm.localization.formatDate(value)}</span>,
  },
  {
    valueFormatter: ({ item, context }) => (
      <span className="text-nowrap">
        <ButtonTooltip tooltip={context.vm.translateGeneral("edit")} className="mr-2">
          <Button
            size="sm"
            variant="secondary"
            className="btn-responsive-block square-button"
            onClick={() => context.vm.openDetail(item)}>
            <img src={iconEdit} width={18} height={18} alt="" />
          </Button>
        </ButtonTooltip>
        <ButtonTooltip tooltip={context.vm.translateGeneral("download_button")} className="mr-2">
          <Button
            size="sm"
            variant="secondary"
            className="btn-responsive-block square-button"
            onClick={() => context.vm.download(item)}>
            <img src={iconDownload} width={18} height={18} alt="" />
          </Button>
        </ButtonTooltip>
        <ButtonTooltip tooltip={context.vm.translateGeneral("delete")} last>
          <Button
            size="sm"
            variant="secondary"
            className="btn-responsive-block square-button"
            onClick={() => context.vm.openDelete(item.id)}>
            <img src={iconDelete} width={18} height={18} alt="" />
          </Button>
        </ButtonTooltip>
      </span>
    ),
    responsiveTitle: false,
    cellClassName: "cell-right",
  },
];

const attachmentsView: ViewComponent<AttachmentsViewModel> = observer(({ vm }) => {
  const { translateGeneral: tg } = vm.localization;

  return (
    <Layout fluid="xl" className="mt-4 mt-sm-5 mb-3">
      <Row className="mb-4 mb-sm-4 mx-1 mx-sm-n3">
        <Col xs={12} sm={true} className="mb-2 mb-sm-0">
          <h2 className="display-2 mb-2">{vm.name}</h2>
        </Col>
        <Col xs={12} sm="auto">
          <Button onClick={vm.addAttachment}>{vm.translate("upload_file_button")}</Button>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Card>
            <Card.Body>
              <LoaderOverlay loading={vm.busyWatcher.isBusy}>
                <Row className="mb-3 justify-content-between align-items-center">
                  <Col xs={12} md={6} lg={4} className="ml-auto">
                    <InputSearch
                      type="text"
                      size="sm"
                      transparent
                      value={vm.filter.search}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => vm.setSearchValue(e.target.value)}
                      placeholder={vm.translate("search_placeholder")}
                    />
                  </Col>
                </Row>

                <div className="responsive-table-wrapper">
                  <ResponsiveTable
                    className="table no-wrap"
                    items={vm.items}
                    itemKey="id"
                    columns={columns}
                    context={createColumnsContext(vm)}
                  />
                </div>
                {!vm.items?.length && <EmptyDataMessage translateGeneral={tg} />}

                {vm.canLoadData && (
                  <div className="load-more-button">
                    <Button onClick={vm.loadData} variant="secondary">
                      {tg("general.button_load_more")}
                    </Button>
                  </div>
                )}

                <View vm={vm.activeChild} />
              </LoaderOverlay>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Layout>
  );
});

registerView(attachmentsView, AttachmentsViewModel);
