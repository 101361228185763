import { BusyWatcher, ScreenBase, watchBusy } from "@frui.ts/screens";
import { ILocalizationService, INotificationService, SeverityLevel } from "@emanprague/shared-services";
import { interfaces } from "inversify";
import { bound } from "@frui.ts/helpers";
import { action, computed, observable, runInAction } from "mobx";
import { attachAutomaticValidator, hasVisibleErrors, validate } from "@frui.ts/validation";
import UserListItem from "entities/userListItem";
import ReqUpdateAdminUser from "entities/reqUpdateAdminUser";
import { unwrapErrorMessage } from "repositories/helpers";
import AdminRepository from "repositories/adminRepository";
import EnumsService from "services/enumsService";

const navigationName = "edit";

export default class AdminUpdateViewModel extends ScreenBase {
  static navigationName = navigationName;

  navigationName = navigationName;
  busyWatcher = new BusyWatcher();

  @observable data: ReqUpdateAdminUser;
  @observable errorMessage?: string;

  constructor(
    private item: UserListItem,
    private refreshDataCallback: () => void,
    public localization: ILocalizationService,
    private adminRepository: AdminRepository,
    private notificationService: INotificationService,
    private enumsService: EnumsService
  ) {
    super();

    this.name = this.translate("title");
    this.data = new ReqUpdateAdminUser();
    this.data.firstName = item.firstName;
    this.data.lastName = item.lastName;
    this.data.email = item.email;
    this.data.role = item.role;
    this.data.active = item.active;

    attachAutomaticValidator(this.data, ReqUpdateAdminUser.ValidationRules);
  }

  @action.bound
  @watchBusy
  async confirmRequest() {
    if (!validate(this.data)) {
      return undefined;
    }

    const response = await this.adminRepository.updateAdmin(this.data, this.item.id);
    if (response.success) {
      this.notificationService.addNotification(this.translateGeneral("request_sent_success"), SeverityLevel.success);
      this.refreshDataCallback();
      this.requestClose();
    } else {
      runInAction(() => (this.errorMessage = unwrapErrorMessage(response.payload)));
    }
  }

  get canConfirm() {
    return !hasVisibleErrors(this.data);
  }

  @computed
  get userRoles() {
    return this.enumsService.getValues("userRoles");
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`admin.admins.detail.${key}`);
  }

  @bound translateGeneral(key: string) {
    return this.localization.translateGeneral(`general.${key}`);
  }

  static Factory({ container }: interfaces.Context) {
    return (item: UserListItem, refreshDataCallback: () => void) => {
      return new AdminUpdateViewModel(
        item,
        refreshDataCallback,
        container.get("ILocalizationService"),
        container.get(AdminRepository),
        container.get("INotificationService"),
        container.get(EnumsService)
      );
    };
  }
}
