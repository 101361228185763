import { IPagingFilter } from "@frui.ts/data";
import { entityToPlain } from "./helpers";
import RepositoryBase from "./repositoryBase";
import ProductCardsFilter from "models/productCardsFilter";
import ProductCardPreviewList from "entities/productCardPreviewList";

export default class ProductCardsRepository extends RepositoryBase {
  getProductCards(partnerId: number, filter?: ProductCardsFilter) {
    // set limit 100 and hope, that they won't use more than that
    const paging: IPagingFilter = { offset: 0, limit: 100 };
    const query = entityToPlain(filter);

    return this.callApi(api =>
      api
        .all(`partners/${partnerId}/product_cards`)
        .getPagedEntities(ProductCardPreviewList, result => result.productCards, paging, query)
    );
  }
}
