import RepositoryBase from "./repositoryBase";
import GeneralSettingsInfo from "entities/generalSettingsInfo";

export default class GeneralSettingsRepository extends RepositoryBase {
  refreshEnums() {
    return this.callApi(api => api.all("admin/portal_settings/enums").postOnly(undefined));
  }

  generalInfo() {
    return this.callApi(api => api.all("admin/portal_settings/general").getEntity(GeneralSettingsInfo));
  }
}
