import Tooltip from "@emanprague/basekit/dist/Tooltip";
import iconInfo from "@emanprague/ppaskit/dist/assets/icons/Info.svg";
import Reminder from "entities/reminder";
import React from "react";
import DOMPurify from "dompurify";
import "./style.scss";

interface ReminderTooltipProps {
  reminder: Reminder;
}

export default class ReminderTooltip extends React.PureComponent<ReminderTooltipProps> {
  addExecutorInfo(content: string | undefined, className?: string) {
    return content && <span className={className} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(content) }} />;
  }

  render() {
    const { reminder } = this.props;
    const executor = reminder.executor;
    const title = reminder.desc;

    return (
      executor && (
        <Tooltip
          placement="top"
          id="executor-tooltip"
          defaultShow={false}
          className="executor-tooltip"
          tooltip={
            <div className="executor-flexbox">
              <span className="title">{title}</span>
              <div className="separator" />
              {this.addExecutorInfo(executor.name, "name")}
              {this.addExecutorInfo(executor.address)}
              {this.addExecutorInfo(executor.email)}
              {this.addExecutorInfo(executor.phone)}
              {this.addExecutorInfo(executor.web)}
            </div>
          }>
          <span className="text-nowrap">
            <img src={iconInfo} alt="" width={22} height={22} className="align-top" />
          </span>
        </Tooltip>
      )
    );
  }
}
