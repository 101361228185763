import type { ILocalizationService } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, Router, ScreenBase } from "@frui.ts/screens";
import SupplyPointListItem from "entities/supplyPointListItem";
import { getSupplyPointTypeIcon } from "helpers/supplyPointHelper";
import { computed, observable } from "mobx";
import AppendixesService from "services/appendixesService";
import DataSyncService from "services/dataSyncService";
import EnumsService from "services/enumsService";
import { IProductPage } from "viewModels/types";

const navigationName = "offer";

@Router.registerRoute({ name: Router.Self, route: navigationName, children: [] })
export default class AppendixesPageViewModel extends ScreenBase implements IProductPage {
  name: string;
  title: string;

  orderIndex = 0;
  navigationName = navigationName;

  @observable errorMessage?: string;
  @observable modalShown = false;
  busyWatcher = new BusyWatcher();

  constructor(
    public localization: ILocalizationService,
    public router: Router,
    public enumsService: EnumsService,
    private dataService: DataSyncService,
    private appendixesService: AppendixesService
  ) {
    super();

    this.name = this.translate("screen_name");
    this.title = this.translate("screen_title");
  }

  @computed
  get hideInMenu() {
    return !this.hasNewAppendixes;
  }

  @computed
  get supplyPointsWithAppendixes() {
    if (this.dataService.supplyPoints) {
      return this.dataService.supplyPoints.filter(supplyPoint =>
        this.newAppendixes.some(appendix => appendix.supplyPointId === supplyPoint.id)
      );
    } else {
      return [];
    }
  }

  @computed
  get hasNewAppendixes() {
    return this.appendixesService.newAppendixesCount > 0;
  }

  @computed
  get newAppendixes() {
    return this.appendixesService.newAppendixes;
  }

  supplyPointIcon(supplyPoint: SupplyPointListItem) {
    return getSupplyPointTypeIcon(this.enumsService.getCommodityType(supplyPoint.commodityId), "yellowAndLightBlue");
  }

  getNumberOfAppendixesLabel(supplyPointId: number) {
    const numberOfAppendixes = this.getNumberOfAppendixes(supplyPointId);
    return numberOfAppendixes.toString() + " " + this.localization.translateModel("offer", numberOfAppendixes).toLowerCase();
  }

  private getNumberOfAppendixes(supplyPointId: number) {
    return this.newAppendixes.reduce((count, appendix) => (appendix.supplyPointId === supplyPointId ? count + 1 : count), 0);
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`profile.appendixes.${key}`);
  }

  @bound translateGeneral(key: string) {
    return this.localization.translateGeneral(`general.${key}`);
  }
}
