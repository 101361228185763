import React from "react";
import { default as Container, ContainerProps } from "react-bootstrap/Container";
import { ReplaceProps } from "react-bootstrap/helpers";
import { styleBlock } from "../";

interface LayoutBaseProps extends ContainerProps {
  children: React.ReactNode;
}

export type LayoutProps = ReplaceProps<"div", LayoutBaseProps>;

export default class Layout extends React.PureComponent<LayoutProps> {
  render() {
    const { ref, className, children, ...props } = this.props;
    const classNames = styleBlock("layout");

    return (
      <Container className={classNames.mix(className).toString()} {...props}>
        {children}
      </Container>
    );
  }
}
