import type { ILocalizationService } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { Router } from "@frui.ts/screens";
import PaymentsRepository from "repositories/paymentsRepository";
import DataSyncService from "services/dataSyncService";
import EnumsService from "services/enumsService";
import UserContext from "services/userContext";
import PaymentsPageViewModel from "viewModels/finance/paymentsPageViewModel";
import { IDetailPage } from "viewModels/types";
import ProductDetailViewModel from "../productDetailViewModel";

const navigationName = "payments";

@Router.registerRoute({ name: Router.Self, route: `${navigationName}` })
export default class PaymentsDetailPageViewModel extends PaymentsPageViewModel implements IDetailPage {
  orderIndex = 3;
  navigationName = navigationName;
  className = "icon-receipt";

  parent: ProductDetailViewModel;

  constructor(
    localization: ILocalizationService,
    userContext: UserContext,
    dataService: DataSyncService,
    repository: PaymentsRepository,
    enumsService: EnumsService
  ) {
    super(localization, userContext, dataService, repository, enumsService);

    this.name = this.translate("title");
  }

  onInitialize() {
    this.filter.supplyPointIds = [this.parent.productDetailContext.supplyPointId];
    return super.onInitialize();
  }

  @bound translateProductScope(key: string) {
    return this.localization.translateGeneral(`products.payments.${key}`);
  }
}
