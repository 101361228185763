import React from "react";
import { styleBlock } from "../";
import Loader from "../Loader";

// Compatible with https://getbootstrap.com/docs/4.1/utilities/colors/#background-color
export type OverlayVariant = "primary" | "secondary" | "light" | "dark" | "transparent";

export interface OverlayProps extends React.HTMLProps<HTMLDivElement> {
  variant?: OverlayVariant;
  loader?: React.ReactNode;
  loading?: boolean;
  text?: React.ReactNode;
}

export default (props: OverlayProps) => {
  const { className, variant = "light", children, loader, loading, text, ...rest } = props;
  const classNames = styleBlock("loader-overlay");

  return (
    <div className={classNames({ loading }).mix(className)} {...rest}>
      {loading && (
        <div className={classNames("overlay", { [variant]: true })}>
          <div className={classNames("loader")}>{loader ? loader : <Loader />}</div>
          { text && <div className={classNames("text")}>{text}</div> }
        </div>
      )}
      {children}
    </div>
  );
};
