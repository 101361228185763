import { Alert, Button, Card, Col, Form, LoaderOverlay } from "@emanprague/ppaskit";
import { registerView, ViewComponent } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import React from "react";
import BulkSelfReadingViewModel from "viewModels/products/myProducts/detailPages/history/bulkSelfReadingViewModel";
import Uploader from "controls/uploader";
import iconDownloadBlue from "@emanprague/ppaskit/dist/assets/icons/Download Blue.svg";

import styles from "./bulkSelfReading.module.scss";

const bulkSelfReadingView: ViewComponent<BulkSelfReadingViewModel> = observer(({ vm }) => (
  <>
    {!vm.bulkResult && (
      <Card className="mx-n3 mx-sm-0 mb-3">
        <LoaderOverlay loading={vm.busyWatcher.isBusy}>
          <Card.Body>
            <Form>
              <Form.Row className="form-row-centered">
                <Form.Group as={Col} xs={8} className="text-center pt-4">
                  <h2>{vm.translate("upload_self_reading")}</h2>
                </Form.Group>
                <Form.Group as={Col} xs={8}>
                  <Uploader
                    maxFiles={1}
                    customTypes=".xlsx"
                    accept={["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"]}
                    localization={vm.localization}
                    onDrop={vm.onSelectFile}
                  />
                </Form.Group>
                <Form.Group as={Col} xs={8} className="text-center">
                  <span className={styles.description}>{vm.translate("upload_file_description")}</span>
                </Form.Group>
                <Form.Group as={Col} xs={8} className="text-center">
                  <Alert variant="dark" className={styles.downloadCard}>
                    <div className="mb-3">
                      <strong>{vm.translate("download_template")}</strong>
                    </div>
                    <Button variant="light" onClick={vm.downloadTemplate}>
                      <img src={iconDownloadBlue} width={16} height={16} alt="download" />{" "}
                      {vm.localization.translateGeneral("general.download_button")}
                    </Button>
                  </Alert>
                </Form.Group>
              </Form.Row>
            </Form>
          </Card.Body>
        </LoaderOverlay>
      </Card>
    )}
    {vm.bulkResult && (
      <section className={styles.bulkResult}>
        <img src={vm.bulkResult.icon} width={80} height={80} alt="state" />
        <h1 className="mt-3 mb-4">{vm.bulkResult.title}</h1>
        <span className="text-muted">{vm.bulkResult.description}</span>
        {vm.bulkResult.onDownload && (
          <Button className="mt-3 mb-4" variant="secondary" onClick={vm.bulkResult.onDownload}>
            <img src={iconDownloadBlue} width={16} height={16} alt="download" />{" "}
            {vm.localization.translateGeneral("general.download_button")}
          </Button>
        )}
        <Button variant="link" className={styles.action} onClick={vm.bulkResult.onClick}>
          {vm.bulkResult.actionText}
        </Button>
      </section>
    )}
  </>
));

registerView(bulkSelfReadingView, BulkSelfReadingViewModel);
