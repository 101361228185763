import { BusyWatcher, ScreenBase, watchBusy } from "@frui.ts/screens";
import { ILocalizationService, INotificationService, SeverityLevel } from "@emanprague/shared-services";
import { interfaces } from "inversify";
import { bound } from "@frui.ts/helpers";
import { action, computed, observable, runInAction } from "mobx";
import { attachAutomaticValidator, hasVisibleErrors, validate } from "@frui.ts/validation";
import ReqCreateAdminUser from "entities/reqCreateAdminUser";
import AdminRepository from "repositories/adminRepository";
import { unwrapErrorMessage } from "repositories/helpers";
import EnumsService from "services/enumsService";

const navigationName = "create";

export default class AdminCreateViewModel extends ScreenBase {
  static navigationName = navigationName;

  navigationName = navigationName;
  busyWatcher = new BusyWatcher();

  @observable data: ReqCreateAdminUser;
  @observable errorMessage?: string;

  constructor(
    public localization: ILocalizationService,
    private adminRepository: AdminRepository,
    private notificationService: INotificationService,
    private enumsService: EnumsService
  ) {
    super();

    this.name = this.translate("title_new");
    this.data = new ReqCreateAdminUser();

    attachAutomaticValidator(this.data, ReqCreateAdminUser.ValidationRules);
  }

  @action.bound
  protected onInitialize(): Promise<any> | void {
    this.data.role = (this.userRoles.find(item => item.default) || this.userRoles[0]).id;
  }

  @action.bound
  @watchBusy
  async confirmRequest() {
    validate(this.data);
    if (!this.canConfirm) {
      return;
    }

    const response = await this.adminRepository.addAdmin(this.data);
    if (response.success) {
      this.notificationService.addNotification(this.translateGeneral("request_sent_success"), SeverityLevel.success);
      this.requestClose();
    } else {
      runInAction(() => (this.errorMessage = unwrapErrorMessage(response.payload)));
    }
  }

  get canConfirm() {
    return !hasVisibleErrors(this.data);
  }

  @computed
  get userRoles() {
    return this.enumsService.getValues("userRoles");
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`admin.admins.detail.${key}`);
  }

  @bound translateGeneral(key: string) {
    return this.localization.translateGeneral(`general.${key}`);
  }

  static Factory({ container }: interfaces.Context) {
    return () => {
      return new AdminCreateViewModel(
        container.get("ILocalizationService"),
        container.get(AdminRepository),
        container.get("INotificationService"),
        container.get(EnumsService)
      );
    };
  }
}
