import { IEventBus, ILocalizationService } from "@emanprague/shared-services";
import { IPagingFilter } from "@frui.ts/data";
import { bound } from "@frui.ts/helpers";
import { ConductorSingleChild, Router } from "@frui.ts/screens";
import LinkListItem from "entities/linkListItem";
import { observable, runInAction } from "mobx";
import LinksFilter from "models/linksFilter";
import LinksRepository from "repositories/linksRepository";
import { IAdminModule } from "viewModels/types";
import LinksDetailViewModel from "./linksDetailViewModel";
import { DataEvents } from "services/events";

const navigationName = "links";

@Router.registerRoute({ name: Router.Self, route: `${navigationName}` })
export default class LinksSettingsPageViewModel extends ConductorSingleChild<LinksDetailViewModel> implements IAdminModule {
  navigationName = navigationName;
  orderIndex = 30;
  menuPart = "content";

  @observable questionLinks: LinkListItem[] = [];
  @observable suggestedLinks: LinkListItem[] = [];

  constructor(
    public localization: ILocalizationService,
    private detailFactory: ReturnType<typeof LinksDetailViewModel.Factory>,
    private linksRepository: LinksRepository,
    private eventBus: IEventBus
  ) {
    super();

    this.name = this.translate("title");
  }

  protected onInitialize() {
    this.fetchQuestionLinks();
    this.fetchSuggestedLinks();

    this.eventBus.subscribe(DataEvents.linksUpdated, event => {
      if (event.payload === "questions") {
        this.fetchQuestionLinks();
      } else {
        this.fetchSuggestedLinks();
      }
    });
  }

  @bound
  async fetchQuestionLinks() {
    const pagingFilter: IPagingFilter = { offset: 0, limit: 10 };
    const filter = new LinksFilter();
    filter.section = "questions";
    const result = await this.linksRepository.getLinks(pagingFilter, filter);

    if (result.success && result.payload[0]?.length) {
      const links = result.payload[0];
      runInAction(() => {
        this.questionLinks = links;
      });
    }
  }

  @bound
  async fetchSuggestedLinks() {
    const pagingFilter: IPagingFilter = { offset: 0, limit: 10 };
    const filter = new LinksFilter();
    filter.section = "suggested_links";
    const result = await this.linksRepository.getLinks(pagingFilter, filter);

    if (result.success && result.payload[0]?.length) {
      const links = result.payload[0];
      runInAction(() => {
        this.suggestedLinks = links;
      });
    }
  }

  @bound
  editSuggestedLinks() {
    return this.tryActivateChild(this.detailFactory(this.suggestedLinks, "suggested_links"));
  }

  @bound
  editFaq() {
    return this.tryActivateChild(this.detailFactory(this.questionLinks, "questions"));
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`admin.link_settings.${key}`);
  }

  @bound translateGeneral(key: string) {
    return this.localization.translateGeneral(`general.${key}`);
  }

  protected findNavigationChild(navigationName: string | undefined) {
    return undefined;
  }
}
