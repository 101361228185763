import React from "react";
import { ToggleButtonGroup, ToggleButton, ButtonProps } from "react-bootstrap";
import { styleBlock } from "..";

export interface SwitchProps {
  id: number | string;
  href: string;
  title: string | React.ReactNode;
  onClick?: any;
}
export interface SwitchNavProps {
  className?: string;
  activeId?: SwitchProps["id"];
  links?: SwitchProps[];
  size?: ButtonProps["size"];
  variant?: ButtonProps["variant"];
}
const SwitchNav: React.FunctionComponent<SwitchNavProps> = ({ activeId, links, size, variant, className, ...props }) => {
  const classNames = styleBlock("switchnav");
  const cn = classNames({}).mix([className]);

  return (
    <ToggleButtonGroup type="radio" name="switchnav" className={cn.toString()}>
      {links?.map(link => {
        return (
          <ToggleButton
            value={link.id}
            variant={variant ?? "info"}
            size={size}
            key={"switchnav-" + link.id}
            onClick={link.onClick}
            checked={link.id === activeId}
            className={link.id === activeId ? "active" : ""}>
            <a href={link.href}>{link.title}</a>
          </ToggleButton>
        );
      })}
    </ToggleButtonGroup>
  );
};

export default SwitchNav;
