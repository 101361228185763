import { Transform } from "class-transformer";
import formatISO from "date-fns/formatISO";
import { Expose } from "class-transformer";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class EnumCountry {
  id!: number;

  value!: string;

  name!: string;

  currency!: string;

  iso!: string;

  mpz?: string;

  @Transform(value => (value ? new Date(value) : undefined), { toClassOnly: true })
  @Transform(value => (value ? formatISO(value, { representation: "date" }) : undefined), { toPlainOnly: true })
  @Expose({ name: "valid_from" })
  validFrom!: Date;

  @Transform(value => (value ? new Date(value) : undefined), { toClassOnly: true })
  @Transform(value => (value ? formatISO(value, { representation: "date" }) : undefined), { toPlainOnly: true })
  @Expose({ name: "valid_to" })
  validTo?: Date;

  /**
   * Allow to choose?
   */
  active?: boolean;

  static ValidationRules = {
    id: { required: true, number: true },
    value: { required: true },
    name: { required: true },
    currency: { required: true },
    iso: { required: true },
    validFrom: { required: true, date: true },
    validTo: { date: true },
  };
}
