import { Expose, Transform, Type } from "class-transformer";
import formatISO from "date-fns/formatISO";
import { observable } from "mobx";
import AdvanceListItemState from "./advanceListItemState";
import SupplyPointSimple from "./supplyPointSimple";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class AdvanceListItem {
  id!: number;

  /**
   * Amount
   */
  @observable
  amount!: number;

  /**
   * Paid amount
   */
  @observable
  @Expose({ name: "amount_paid" })
  amountPaid?: number;

  @observable
  state!: AdvanceListItemState;

  @observable
  @Expose({ name: "state_text" })
  stateText?: string;

  @observable
  vs!: string;

  @observable
  @Expose({ name: "account_id" })
  accountId!: number;

  @observable
  @Transform(value => (value ? new Date(value) : undefined), { toClassOnly: true })
  @Transform(value => (value ? formatISO(value, { representation: "date" }) : undefined), { toPlainOnly: true })
  @Expose({ name: "due_date" })
  dueDate!: Date;

  @observable
  @Transform(value => (value ? new Date(value) : undefined), { toClassOnly: true })
  @Transform(value => (value ? formatISO(value, { representation: "date" }) : undefined), { toPlainOnly: true })
  @Expose({ name: "pay_date" })
  payDate?: Date;

  /**
   * Can we use payment GW or Transfer?
   */
  @observable
  @Expose({ name: "allow_to_pay" })
  allowToPay?: boolean;

  /**
   * Enumeration::AdvanceFrequency
   */
  @observable
  @Expose({ name: "advance_frequency_id" })
  advanceFrequencyId!: number;

  @observable
  period?: string;

  /**
   * Číslo účtu PP kam má být záloha uhrazena
   */
  @observable
  @Expose({ name: "account_number" })
  accountNumber?: string;

  @observable
  @Type(() => SupplyPointSimple)
  @Expose({ name: "supply_points" })
  supplyPoints?: SupplyPointSimple[];

  static ValidationRules = {
    id: { required: true, number: true },
    amount: { required: true, number: true },
    amountPaid: { number: true },
    state: { required: true },
    vs: { required: true },
    accountId: { required: true, number: true },
    dueDate: { required: true, date: true },
    payDate: { date: true },
    advanceFrequencyId: { required: true, number: true },
  };
}
