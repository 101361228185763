import { observable } from "mobx";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class ReminderExecutor {
  @observable
  name!: string;

  @observable
  address?: string;

  @observable
  email?: string;

  @observable
  phone?: string;

  @observable
  web?: string;

  static ValidationRules = {
    name: { required: true },
  };
}
