import { Badge, Nav, NavDropdown, Button, Header, HeaderRow, RawDropdownButton, MessagesWidget } from "@emanprague/ppaskit";
import checkLight from "@emanprague/ppaskit/dist/assets/icons/Check Light.svg";
import plusLight from "@emanprague/ppaskit/dist/assets/icons/Plus Light.svg";
import iconEdit from "@emanprague/ppaskit/dist/assets/icons/Edit.svg";
import iconArrowRightGray from "@emanprague/ppaskit/dist/assets/icons/Arrow/Right.svg";
import CommentIcon from "@emanprague/ppaskit/dist/assets/icons/Comment.svg";
import Logo from "@emanprague/ppaskit/dist/assets/images/Logo_PPAS.svg";
import { Router } from "@frui.ts/screens";
import type { ViewComponent } from "@frui.ts/views";
import { registerView, View } from "@frui.ts/views";
import { observer, Observer } from "mobx-react-lite";
import React from "react";
import RootViewModel from "../viewModels/rootViewModel";
import MessagesOverviewViewModel from "../viewModels/messages/overviewViewModel";
import { getNavItemIcon } from "helpers/rootHelper";
import "./style.scss";
import type { MessageProps } from "@emanprague/ppaskit/dist/MessagesWidget";
import AttachmentsViewModel from "viewModels/dashboard/attachments/attachmentsViewModel";
import PartnerConnectViewModel from "viewModels/dashboard/partnerConnectViewModel";

const HeaderView: ViewComponent<RootViewModel> = observer(({ vm }) => {
  const { translateGeneral: tg } = vm.localization;
  const getUrl = Router.getChildUrlFactory(vm);

  const childrenWithVisibleNavItem = vm.children.filter(x => !x.hideInMenu);
  const menuItems = childrenWithVisibleNavItem.map(x => (
    <Nav.Item key={x.name}>
      <Nav.Link href={getUrl(x)} active={vm.activeChild === x} style={{ position: "relative" }}>
        <img className="pr-2" width={28} height={28} src={getNavItemIcon(x.navigationName, vm.activeChild === x)} alt="" />
        {!!x.notificationBadge && <Badge className="badge-darkblue floating">{x.notificationBadge}</Badge>}

        {x.name}
      </Nav.Link>
    </Nav.Item>
  ));

  const userMenu = (
    <NavDropdown alignRight renderMenuOnMount title={vm.activePartnerFullName} id="user-nav-dropdown">
      <NavDropdown.Item href={vm.router.getUrl("customerAccount")}>{vm.translate("account_settings")}</NavDropdown.Item>
      <NavDropdown.Item onClick={vm.activateUpdatePasswordModal}>{vm.translate("change_password")}</NavDropdown.Item>
      <NavDropdown.Item href={vm.router.getUrl(AttachmentsViewModel)}>{vm.translate("my_files")}</NavDropdown.Item>
      <div className="dropdown__divider" />
      <NavDropdown.Item key={vm.currentPartner.id}>
        <div className="dropdown__main-account-wrapper">
          <div className="text-muted font-size-sm dropdown__accounts-header">{vm.translate("main_account")}</div>
          <div className="dropdown__main-account">
            <div className="font-size-md dropdown__user-name">{vm.activePartnerFullName}</div>
            <img src={checkLight} alt="" />
          </div>

          <div className="dropdown__main-account-divider" />
          <div className="dropdown__connected-accounts-header">
            <span className="text-muted font-size-sm dropdown__accounts-header">
              {vm.translate("connected_accounts")} ({vm.numberOfConnectedAccounts})
            </span>
            {vm.profile.partners.length > 1 ? (
              <span className="d-flex justify-content-end">
                <Button
                  variant="secondary"
                  size="sm"
                  onClick={() => vm.router.navigate(PartnerConnectViewModel)}
                  className="px-2">
                  <img src={plusLight} alt="" height={16} width={16} className="m-0" />
                </Button>
                <Button variant="secondary" size="sm" onClick={vm.activatePartnerRemove}>
                  <img src={iconEdit} alt="" height={16} width={16} /> {vm.translateGeneral("manage_button")}
                </Button>
              </span>
            ) : (
              <Button variant="secondary" size="sm" onClick={() => vm.router.navigate(PartnerConnectViewModel)}>
                <img src={plusLight} alt="" height={16} width={16} /> {vm.translateGeneral("add_button")}
              </Button>
            )}
          </div>
        </div>
      </NavDropdown.Item>

      <div className="connected-partners__container blue-scrollbar">
        {vm.profile.connectedPartners.map((partner, index) => (
          <NavDropdown.Item
            key={partner.id}
            className={index === vm.profile.connectedPartners.length - 1 ? "mt-0" : ""}
            onClick={() => vm.changeActivePartner(partner.id)}>
            <div className={"connected-partners__row " + (index === vm.profile.connectedPartners.length - 1 ? "last-row" : "")}>
              {partner.externalId} - {vm.getPartnerFullName(partner)}
            </div>
          </NavDropdown.Item>
        ))}
      </div>
      <div className="dropdown__divider" />
      {!vm.userContext.morphedAdminId && (
        <NavDropdown.Item onClick={vm.profile.logout}>{vm.translate("logout")}</NavDropdown.Item>
      )}
      {vm.userContext.morphedAdminId && (
        <NavDropdown.Item onClick={vm.profile.unmorphPartner}>{vm.translate("unmorph_partner")}</NavDropdown.Item>
      )}
    </NavDropdown>
  );

  const notificationTitle = (
    <div className="notification-title">
      <img className="pr-2" width={28} height={28} src={CommentIcon} alt="" />
      {vm.unreadMessagesCount > 0 && <Badge className="badge-darkblue floating-message">{vm.unreadMessagesCount}</Badge>}
      {vm.translate("messages")}
    </div>
  );

  const getFormattedMessagePreviews = () =>
    vm.messagePreviews.map(
      item =>
        ({
          date: vm.localization.formatDate(item.date),
          title: (
            <span
              className="cursor-pointer"
              onClick={() => vm.router.navigate(MessagesOverviewViewModel, undefined, { message: item })}>
              {item.title}
            </span>
          ),
          isUnread: !item.read,
        } as MessageProps)
    );

  const notifications = (
    <RawDropdownButton
      alignRight
      title={notificationTitle}
      variant="link"
      id="notifications-nav-dropdown"
      className="without-caret position-relative dropdown-notification">
      <MessagesWidget
        headerRow={
          <HeaderRow title={tg("messages.title")} level={2}>
            <Button
              variant="link"
              className="font-size-base font-weight-bold link-underline-hover pr-0 d-none d-sm-block"
              href={vm.router.getUrl(MessagesOverviewViewModel)}>
              {vm.translateGeneral("display_all_button")}
              <img
                className="ml-sm-3 opacity-third align-text-bottom"
                width="16"
                height="20"
                draggable={false}
                src={iconArrowRightGray}
                alt=""
              />
            </Button>
          </HeaderRow>
        }
        messages={getFormattedMessagePreviews()}
        noMessagesLabel={tg("messages.no_messages")}
      />
    </RawDropdownButton>
  );

  return (
    <Header
      expand="lg"
      containerWrap={true}
      containerFluid="xl"
      className="bg-white shadow"
      brand={<img src={Logo} alt="" />}
      brandHref={"#/" + vm.children[0]?.navigationName}
      menu={menuItems}
      notifications={notifications}
      usermenu={userMenu}
    />
  );
});

const AppendedViews: ViewComponent<RootViewModel> = observer(({ vm }) => (
  <>
    {vm.appendedViewModels.map(x => (
      <View key={x.name} vm={x} />
    ))}
  </>
));

const rootView: ViewComponent<RootViewModel> = ({ vm }) => {
  return (
    <>
      <Observer>
        {() => (
          <div className="toast__container">
            {vm.toasts.map(item => (
              <View key={item.key} vm={item} useLifecycle />
            ))}
          </div>
        )}
      </Observer>
      <HeaderView vm={vm} />
      <Observer>{() => <View vm={vm.activeChild} />}</Observer>
      <AppendedViews vm={vm} />

      <Observer>
        {() => (
          <>
            {vm.confirmationService.dialogs.map((dialog, i) => (
              <View key={i} vm={dialog} useLifecycle />
            ))}
          </>
        )}
      </Observer>

      <div className="container-xl">
        <div className="pb-4 version">
          {vm.translateGeneral("pp_version")} {process.env.REACT_APP_VERSION ?? "0.0.1"} (
          {process.env.REACT_APP_VERSION_DEPLOYED_AT ?? "unknown"})
        </div>
      </div>
    </>
  );
};

registerView(rootView, RootViewModel);
