import { preventDefault, registerView, ViewComponent } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import React from "react";
import { Form, Modal, Col, Button, LoaderOverlay } from "@emanprague/ppaskit";
import ModalInvoicePaymentMethodViewModel from "viewModels/products/myProducts/detailPages/optionsDeposit/modalInvoicePaymentMethodViewModel";
import { Check, Input, Select } from "@frui.ts/bootstrap";
import SupplyPointSelectByAccount from "controls/twoway/SupplyPointSelectByAccount";
import { Row } from "react-bootstrap";

const modalInvoicePaymentMethodView: ViewComponent<ModalInvoicePaymentMethodViewModel> = observer(({ vm }) => {
  const ta = vm.localization.translateAttribute;

  return (
    <Modal centered show onHide={vm.requestClose}>
      <Modal.Header closeButton>
        <h2 className="display-4 m-0">{vm.name}</h2>
      </Modal.Header>
      <LoaderOverlay loading={vm.busyWatcher.isBusy}>
        <Modal.Body>
          <Form className="modal-form" onSubmit={preventDefault(vm.confirmChange)}>
            <Form.Row className="form-row-centered">
              <Form.Group as={Col} xs={12}>
                <Form.Label className="mb-3">
                  <strong>{vm.translate("underpayments")}</strong>
                </Form.Label>
                {vm.underpaymentMethods.map(method => (
                  <Check
                    custom
                    key={method.id}
                    target={vm.data}
                    property="underpaymentMethodId"
                    value={method.id}
                    type="radio"
                    label={method.name}
                    className="mb-1"
                    onValueChanged={vm.onUnderpaymentMethodChange}
                  />
                ))}
              </Form.Group>
              {(vm.selectedUnderpaymentMethod === "zpusobPlatbyPrikazKUhrade" ||
                vm.selectedUnderpaymentMethod === "zpusobPlatbyPrimeInkaso") && (
                <Form.Row className="w-100">
                  <Form.Group as={Col} xs={8}>
                    <Row noGutters>
                      <Form.Group as={Col} xs={5} className="bank-account-prefix">
                        <Form.Label>{ta("bank_account", "prefix")}</Form.Label>
                        <Input
                          target={vm.data}
                          property="underpaymentAccountPrefix"
                          type="number"
                          onValueChanged={vm.clearManualError}
                        />
                      </Form.Group>
                      <Form.Group as={Col} xs={6}>
                        <Form.Label>{ta("bank_account", "number")}</Form.Label>
                        <Input
                          target={vm.data}
                          property="underpaymentAccount"
                          type="number"
                          onValueChanged={vm.clearManualError}
                        />
                      </Form.Group>
                    </Row>
                  </Form.Group>

                  <Form.Group as={Col} xs={4}>
                    <Form.Label>{vm.translate("bank_code")}</Form.Label>
                    <Select
                      target={vm.data}
                      property="underpaymentAccountBankId"
                      items={vm.bankCodes}
                      mode="key"
                      keyProperty="id"
                      textProperty="value"
                    />
                  </Form.Group>
                </Form.Row>
              )}

              <Form.Group as={Col} xs={12} className="mt-3">
                <Form.Label className="mb-3">
                  <strong>{vm.translate("overpayments")}</strong>
                </Form.Label>
                {vm.overpaymentMethods.map(method => (
                  <Check
                    custom
                    key={method.id}
                    target={vm.data}
                    property="overpaymentMethodId"
                    value={method.id}
                    type="radio"
                    label={method.name}
                    className="mb-1"
                    onValueChanged={vm.onOverpaymentMethodChange}
                  />
                ))}
              </Form.Group>
              {vm.selectedOverpaymentMethod === "zpusobPlatbyPrikazKUhrade" && (
                <Form.Row className="w-100">
                  <Form.Group as={Col} xs={8}>
                    <Row noGutters>
                      <Form.Group as={Col} xs={5} className="bank-account-prefix">
                        <Form.Label>{ta("bank_account", "prefix")}</Form.Label>
                        <Input
                          target={vm.data}
                          property="overpaymentAccountPrefix"
                          type="number"
                          onValueChanged={vm.clearManualError}
                        />
                      </Form.Group>
                      <Form.Group as={Col} xs={6}>
                        <Form.Label>{ta("bank_account", "number")}</Form.Label>
                        <Input
                          target={vm.data}
                          property="overpaymentAccount"
                          type="number"
                          onValueChanged={vm.clearManualError}
                        />
                      </Form.Group>
                    </Row>
                  </Form.Group>
                  <Form.Group as={Col} xs={4}>
                    <Form.Label>{vm.translate("bank_code")}</Form.Label>
                    <Select
                      target={vm.data}
                      property="overpaymentAccountBankId"
                      items={vm.bankCodes}
                      mode="key"
                      keyProperty="id"
                      textProperty="value"
                    />
                  </Form.Group>
                </Form.Row>
              )}

              <SupplyPointSelectByAccount
                defaultSupplyPoints={vm.currentSupplyPointsWithIcons}
                supplyPoints={vm.additionalSupplyPointsWithIcons}
                target={vm.data}
                localization={vm.localization}
                supplyPointErrors={vm.supplyPointsErrors}
                property="supplyPointIds"
              />

              {vm.manualValidator.errors.base && (
                <Form.Group as={Col} xs={12} className="mt-n4">
                  <Form.Control.Feedback type="invalid">{vm.manualValidator.errors.base}</Form.Control.Feedback>
                </Form.Group>
              )}
            </Form.Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" className="order-2" onClick={vm.confirmChange} disabled={!vm.canConfirm}>
            {vm.localization.translateGeneral("general.confirm_changes_button")}
          </Button>
          <Button onClick={vm.requestClose} variant="secondary" className="order-1">
            {vm.localization.translateGeneral("general.cancel_button")}
          </Button>
        </Modal.Footer>
      </LoaderOverlay>
    </Modal>
  );
});

registerView(modalInvoicePaymentMethodView, ModalInvoicePaymentMethodViewModel);
