import { registerView, ViewComponent, preventDefault } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import React from "react";
import { Form, Col, Button, Modal, HeaderRow, LoaderOverlay } from "@emanprague/ppaskit";
import { Input } from "@frui.ts/bootstrap";
import AttachmentUpdateViewModel from "viewModels/dashboard/attachments/attachmentUpdateViewModel";
import iconAttachment from "@emanprague/ppaskit/dist/assets/icons/Attachment.svg";

const attachmentUpdateView: ViewComponent<AttachmentUpdateViewModel> = observer(({ vm }) => (
  <>
    <Modal centered show onHide={vm.requestClose}>
      <Modal.Header closeButton>
        <HeaderRow title={vm.name} level={4} />
      </Modal.Header>
      <LoaderOverlay loading={vm.busyWatcher.isBusy}>
        <Modal.Body>
          <Form className="modal-form" onSubmit={preventDefault(vm.confirmChange)}>
            <Form.Row className="form-row-centered">
              <Form.Group as={Col} xs={11}>
                <Form.Label>
                  {vm.translate("file_name")} <span className="text-muted">{vm.translateGeneral("not_mandatory")}</span>
                </Form.Label>
                <Input target={vm.data} property="title" />
              </Form.Group>
              <Form.Group as={Col} xs={11}>
                <Form.Label>
                  {vm.translate("file_description")} <span className="text-muted">{vm.translateGeneral("not_mandatory")}</span>
                </Form.Label>
                <Input target={vm.data} property="description" />
              </Form.Group>

              <Form.Group as={Col} xs={11}>
                <div className="file-row mt-4">
                  <div className="file-name-wrapper">
                    <img width={18} height={18} src={iconAttachment} className="ml-3 mr-2" alt="" />
                    <div className="file-name">{vm.attachment.fileName}</div>
                  </div>
                </div>
              </Form.Group>
            </Form.Row>

            {vm.errorMessage && <Form.Control.Feedback type="invalid">{vm.errorMessage}</Form.Control.Feedback>}
          </Form>
        </Modal.Body>
        <Modal.Footer className="justify-content-between">
          <Button variant="primary" className="order-2" onClick={vm.confirmChange}>
            {vm.translateGeneral("save_button")}
          </Button>
          <Button onClick={vm.deleteAttachment} variant="danger" className="order-1">
            {vm.translate("delete_file_button")}
          </Button>
        </Modal.Footer>
      </LoaderOverlay>
    </Modal>
  </>
));

registerView(attachmentUpdateView, AttachmentUpdateViewModel);
