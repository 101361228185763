import { ILocalizationService } from "@emanprague/shared-services";
import { BusyWatcher, ConductorOneChildActive, Router } from "@frui.ts/screens";
import { action } from "mobx";
import { IModule } from "viewModels/types";
import { IFinancePage } from "./types";
import { bound } from "@frui.ts/helpers";
import UserContext from "services/userContext";
import AdvanceChangeViewModel from "./advanceChangeViewModel";
import AdvancesPageViewModel from "./advancesPageViewModel";
import InvoicesPageViewModel from "./invoicesPageViewModel";
import DownloadingService from "services/downloadingService";
import { QueueItem, QueueItemType } from "@emanprague/ppaskit/dist/DownloadingQueue";

const navigationName = "finance";

@Router.registerRoute({
  name: Router.Self,
  route: navigationName,
  children: [AdvancesPageViewModel, AdvanceChangeViewModel, InvoicesPageViewModel],
})
export default class OverviewViewModel extends ConductorOneChildActive<IFinancePage> implements IModule {
  orderIndex = 3;
  navigationName = navigationName;
  busyWatcher = new BusyWatcher();

  constructor(
    public localization: ILocalizationService,
    pages: IFinancePage[],
    private userContext: UserContext,
    private downloadingService: DownloadingService
  ) {
    super();

    this.name = this.translate("title");
    this.setPages(pages);
  }

  protected onInitialize() {
    this.children.forEach(x => x.initialize());

    if (!this.activeChild && this.children.length) {
      return this.tryActivateChild(this.children[0]);
    }
  }

  get downloadsList() {
    return this.downloadingService.items.map(item => {
      let type = QueueItemType.COMPLETE;
      switch (item.status) {
        case "in_queue":
        case "in_progress":
          type = QueueItemType.IN_PROGRESS;
          break;
        case "failed":
          type = QueueItemType.FAIL;
          break;
      }
      return { id: item.id, label: item.humanName, type };
    });
  }

  @action.bound
  handleDownloadItemClick(item: QueueItem) {
    switch (item.type) {
      case QueueItemType.COMPLETE:
        this.downloadingService.downloadFile(item.id);
        break;
      case QueueItemType.IN_PROGRESS:
        break;
      default:
        this.downloadingService.deleteNotification(item.id);
        break;
    }
  }

  get depositDueAmount() {
    return Number(this.userContext.activePartner?.depositDueAmount) ?? 0;
  }

  get invoiceDueAmount() {
    return Number(this.userContext.activePartner?.invoiceDueAmount) ?? 0;
  }

  get totalBalance(): number {
    return this.depositDueAmount + this.invoiceDueAmount;
  }

  @action
  public setPages(pages: IFinancePage[]) {
    pages.sort((a, b) => a.orderIndex - b.orderIndex);
    this.children.push(...pages);
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`finance.${key}`);
  }

  @bound translateGeneral(key: string) {
    return this.localization.translateGeneral(`general.${key}`);
  }
}
