import { observable } from "mobx";
import { Type } from "class-transformer";
import Banner from "./banner";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class BannerListToRender {
  @observable
  total!: number;

  @observable
  @Type(() => Banner)
  banners!: Banner[];

  static ValidationRules = {
    total: { required: true, number: true },
    banners: { required: true },
  };
}
