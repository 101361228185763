export enum SeverityLevel {
  critical = "critical",
  important = "important",
  success = "success",
  information = "information",
}

export interface NotificationModel {
  message: string;
  severity: SeverityLevel;
  created: Date;
  timeoutHandle?: number;
}

export interface INotificationService {
  notifications: Map<string, NotificationModel>;

  addNotification(message: string, severity?: SeverityLevel, key?: string): void;
  removeNotification(key: string): void;
}
