import cs from "date-fns/locale/cs";
import buildFormatLongFn from "date-fns/locale/_lib/buildFormatLongFn";

const dateFormats = {
  full: "EEEE, d. MMMM yyyy",
  long: "d. MMMM yyyy",
  medium: "d. M. yyyy",
  short: "dd.MM.yyyy",
};

if (cs.formatLong) {
  cs.formatLong.date = buildFormatLongFn({
    formats: dateFormats,
    defaultWidth: "full",
  });
}

export default cs;
