import { Col, Row, SwitchNav } from "@emanprague/ppaskit";
import { registerView, View, ViewComponent } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import React from "react";
import ModalSelfReadingViewModel from "viewModels/products/myProducts/detailPages/history/modalSelfReadingViewModel";

const modalSelfReadingView: ViewComponent<ModalSelfReadingViewModel> = observer(({ vm }) => (
  <Row className="d-flex justify-content-center">
    <Col lg={6}>
      <h1 className="display-1 text-center mb-4">{vm.translate("self_reading")}</h1>
      <div className="display-1 text-center mb-4">
        <SwitchNav variant="light" links={vm.navLinks} size="sm" activeId={vm.activeChild?.navigationName} />
      </div>
      <View vm={vm.activeChild} />
    </Col>
  </Row>
));

registerView(modalSelfReadingView, ModalSelfReadingViewModel);
