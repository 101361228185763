import Tooltip from "@emanprague/basekit/dist/Tooltip";
import React from "react";
import InfoIcon from "../assets/icons/Info.svg";

export interface InputWithTooltipProps {
  tooltipIcon?: string;
}

type InputWithTooltipType = InputWithTooltipProps & React.ComponentPropsWithRef<typeof Tooltip>;

const InputWithTooltip: React.FC<InputWithTooltipType> = ({ className, tooltipIcon, children, ...props }) => (
  <div className={"position-relative " + className}>
    {children}
    <Tooltip {...props}>
      <img src={tooltipIcon || InfoIcon} className="input-inner-element cursor-help" />
    </Tooltip>
  </div>
);

export default InputWithTooltip;
