import { observable } from "mobx";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class EnumUserRole {
  id!: string;

  @observable
  name!: string;

  @observable
  default!: boolean;

  static ValidationRules = {
    id: { required: true },
    name: { required: true },
    default: { required: true },
  };
}
