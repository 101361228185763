import { observable } from "mobx";
import { Expose, Type } from "class-transformer";
import AccountInvoiceSentMethod from "./accountInvoiceSentMethod";
import Address from "./address";

// This entity has been generated, do not change its content, your changes might get lost. You CAN modify the rest of the file.
export default class Account {
  /**
   * @example
   * 200
   */
  id!: number;

  /**
   * @example
   * 214145154
   */
  @observable
  @Expose({ name: "external_id" })
  externalId!: string;

  /**
   * -
   * @example
   * Chata Plyn
   */
  @observable
  name!: string;

  @observable
  active?: boolean;

  @observable
  @Expose({ name: "allow_to_change_sent_method" })
  allowToChangeSentMethod?: boolean;

  /**
   * -
   */
  @observable
  @Expose({ name: "variable_symbol" })
  variableSymbol?: string;

  @observable
  @Expose({ name: "invoice_sent_method" })
  invoiceSentMethod!: AccountInvoiceSentMethod;

  /**
   * Email při elektronickém zasílání faktur
   */
  @observable
  @Expose({ name: "invoice_sent_method_email" })
  invoiceSentMethodEmail?: string;

  /**
   * Enumeration::AccountType
   */
  @observable
  @Expose({ name: "account_type_id" })
  accountTypeId?: number;

  /**
   * Enumeration::PaymentMethod
   */
  @observable
  @Expose({ name: "advance_method_id" })
  advanceMethodId?: number;

  /**
   * Account prefix
   */
  @observable
  @Expose({ name: "advance_account_prefix" })
  advanceAccountPrefix?: number;

  /**
   * SIPO/Account
   */
  @observable
  @Expose({ name: "advance_account" })
  advanceAccount?: number;

  /**
   * Enumeration::Bank
   */
  @observable
  @Expose({ name: "advance_account_bank_id" })
  advanceAccountBankId?: number;

  /**
   * Enumeration::PaymentMethod
   */
  @observable
  @Expose({ name: "underpayment_method_id" })
  underpaymentMethodId?: number;

  /**
   * Account prefix
   */
  @observable
  @Expose({ name: "underpayment_account_prefix" })
  underpaymentAccountPrefix?: number;

  /**
   * SIPO/Account
   */
  @observable
  @Expose({ name: "underpayment_account" })
  underpaymentAccount?: number;

  /**
   * Enumeration::Bank
   */
  @observable
  @Expose({ name: "underpayment_account_bank_id" })
  underpaymentAccountBankId?: number;

  /**
   * Enumeration::PaymentMethod
   */
  @observable
  @Expose({ name: "overpayment_method_id" })
  overpaymentMethodId?: number;

  /**
   * Account prefix
   */
  @observable
  @Expose({ name: "overpayment_account_prefix" })
  overpaymentAccountPrefix?: number;

  /**
   * SIPO/Account
   */
  @observable
  @Expose({ name: "overpayment_account" })
  overpaymentAccount?: number;

  /**
   * Enumeration::Bank
   */
  @observable
  @Expose({ name: "overpayment_account_bank_id" })
  overpaymentAccountBankId?: number;

  @observable
  @Type(() => Address)
  addresses?: Address[];

  static ValidationRules = {
    id: { required: true, number: true },
    externalId: { required: true },
    name: { required: true },
    invoiceSentMethod: { required: true },
    accountTypeId: { number: true },
    advanceMethodId: { number: true },
    advanceAccountPrefix: { number: true },
    advanceAccount: { number: true },
    advanceAccountBankId: { number: true },
    underpaymentMethodId: { number: true },
    underpaymentAccountPrefix: { number: true },
    underpaymentAccount: { number: true },
    underpaymentAccountBankId: { number: true },
    overpaymentMethodId: { number: true },
    overpaymentAccountPrefix: { number: true },
    overpaymentAccount: { number: true },
    overpaymentAccountBankId: { number: true },
  };
}
