import React from "react";

import iconStateOk from "../assets/icons/State/Ok.svg";
import iconStateNotOk from "../assets/icons/State/Not Ok.svg";

import { styleBlock, Card } from "..";

interface AccountStatusProps {
  status: boolean;
  title: string;
  className?: string;
}

export default class AccountStatus extends React.PureComponent<AccountStatusProps> {
  render() {
    const { status, title, className, children } = this.props;
    const classNames = styleBlock("account-status");
    const cn = classNames({}).mix(className);

    return (
      <Card className={cn.toString()}>
        <Card.Body
          className="d-lg-flex flex-column align-items-center justify-content-center text-center"
          style={{ padding: "15px" }}>
          <img width="80" height="80" className="mb-4" draggable={false} src={status ? iconStateOk : iconStateNotOk} alt="" />
          <h2
            className={"display-2 mb-2 font-weight-normal " + (status ? "text-success" : "text-danger")}
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <div className="font-size-md color-gray my-0">{children}</div>
        </Card.Body>
      </Card>
    );
  }
}
