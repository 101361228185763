import { ILocalizationService, INotificationService, SeverityLevel } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, Router, watchBusy } from "@frui.ts/screens";
import { action, observable } from "mobx";
import ContinuousListViewModelBase from "viewModels/continuousListViewModelBase";
import { IAdminModule } from "viewModels/types";
import BannersFilter from "models/bannersFilter";
import BannerDetailViewModel from "./bannerDetailViewModel";
import BannerListItem from "entities/bannerListItem";
import BannerListItemType from "entities/bannerListItemType";
import AdminRepository from "repositories/adminRepository";
import BannerPreviewViewModel from "./bannerPreviewViewModel";
import ConfirmationService from "services/confirmationService";

const navigationName = "banners";

@Router.registerRoute({ name: Router.Self, route: `${navigationName}` })
export default class BannersPageViewModel
  extends ContinuousListViewModelBase<BannerListItem, BannersFilter, BannerDetailViewModel | BannerPreviewViewModel>
  implements IAdminModule
{
  navigationName = navigationName;
  orderIndex = 40;
  menuPart = "content";
  busyWatcher = new BusyWatcher();

  @observable bannerTypes: { id: BannerListItemType; name: string }[];
  @observable errorMessage?: string;

  constructor(
    public localization: ILocalizationService,
    private detailFactory: ReturnType<typeof BannerDetailViewModel.Factory>,
    private previewFactory: ReturnType<typeof BannerPreviewViewModel.Factory>,
    private adminRepository: AdminRepository,
    private confirmationService: ConfirmationService,
    private notificationService: INotificationService
  ) {
    super();

    this.bannerTypes = [
      { id: "cross_sell", name: this.translate("detail.cross_sell_type") },
      { id: "large", name: this.translate("detail.large_type") },
      { id: "small", name: this.translate("detail.small_type") },
    ];
    this.name = this.translate("title");
  }

  protected onInitialize(): Promise<any> | void {
    return this.loadData();
  }

  @bound
  @watchBusy
  async loadData() {
    const response = await this.adminRepository.getBanners(this.pagingFilter, this.filter);
    if (response.success) {
      this.setData(response.payload);
    }
  }

  @action.bound
  reloadData() {
    this.applyFilterAndLoadDebounced();
    this.selectedItems.clear();
  }

  @bound
  addBanner() {
    return this.tryActivateChild(this.detailFactory(undefined, this.reloadData));
  }

  @action
  openDetail(bannerId: number) {
    return this.tryActivateChild(this.detailFactory(bannerId, this.reloadData));
  }

  @action
  openPreview(bannerId: number) {
    return this.tryActivateChild(this.previewFactory(bannerId));
  }

  @bound
  @watchBusy
  async openDelete(bannerIds: number[]) {
    const confirm = await this.confirmationService.showConfirmation(
      this.translate("delete_confirmation_" + this.localization.getPluralForm(bannerIds.length)),
      undefined,
      { variant: "danger", text: this.translateGeneral("delete_confirmation_button") },
      this.translateGeneral("cancel_button")
    );

    if (!confirm) {
      return;
    }

    const response = await this.adminRepository.deleteBanners(bannerIds);
    if (response.success) {
      this.notificationService.addNotification(this.translateGeneral("request_sent_success"), SeverityLevel.success);
      this.reloadData();
    }
  }

  @action.bound
  clearFilter(...properties: (keyof BannersFilter)[]) {
    properties.forEach(property => {
      this.filter[property] = undefined;
    });
    this.applyFilterAndLoad();
  }

  get deleteButtonLabel() {
    return `${this.translateGeneral("delete_selected_button")} (${this.selectedItems.size})`;
  }

  get selectedBannersIds() {
    const banners: BannerListItem[] = Array.from(this.selectedItems);
    return banners.map(item => item.id);
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  protected resetFilterValues(filter: BannersFilter) {}

  protected createFilter() {
    return new BannersFilter();
  }

  protected findNavigationChild(navigationName?: string): any {
    if (navigationName === BannerDetailViewModel.navigationName) {
      return this.addBanner();
    }
    return super.findNavigationChild(navigationName);
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`admin.banners.${key}`);
  }

  @bound translateGeneral(key: string) {
    return this.localization.translateGeneral(`general.${key}`);
  }
}
