import { ILocalizationService, INotificationService, SeverityLevel } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, ScreenBase, watchBusy } from "@frui.ts/screens";
import { interfaces } from "inversify";
import { action, computed, observable, runInAction } from "mobx";
import InvoicesRepository from "repositories/invoicesRepository";
import EnumsService from "services/enumsService";
import Invoice from "entities/invoice";
import ReqInvoiceComplaint from "entities/reqInvoiceComplaint";
import { createSupplyPointsWithIcons, isDoubleTariff } from "helpers/supplyPointHelper";
import { attachAutomaticValidator, hasVisibleErrors, validate } from "@frui.ts/validation";
import { unwrapErrorMessage } from "repositories/helpers";
import CommodityType from "models/commodityType";
import ComplaintType from "models/complaintType";
import SupplyPointSimple from "entities/supplyPointSimple";
import merge from "lodash/merge";
import {
  ConsumptionData,
  getConsumptionDataValidationRules,
  getIncorrectBillingAndOtherValidationRules,
  getIncorrectFinancialStateValidationRules,
} from "models/invoiceComplaint";

type ViewName = "supplyPointSelect" | "complaintDetails";

export default class InvoiceComplaintViewModel extends ScreenBase {
  navigationName = "complaint";
  busyWatcher = new BusyWatcher();

  @observable invoiceComplaint: ReqInvoiceComplaint;
  @observable currentActiveView: ViewName = "supplyPointSelect";
  @observable selectedSupplyPointIds: number[];
  @observable errorMessage?: string;
  @observable selectedSupplyPoint?: SupplyPointSimple;
  @observable isUploadValid = true;
  @observable fileSelectError?: string;
  @observable consumptionData: ConsumptionData;

  constructor(
    private partnerId: number,
    public invoice: Invoice,
    public localization: ILocalizationService,
    private invoicesRepository: InvoicesRepository,
    private enumsService: EnumsService,
    private notificationService: INotificationService
  ) {
    super();

    this.name = this.translate("title");
  }

  @action onInitialize() {
    this.initializeValues();
  }

  @action.bound
  initializeValues() {
    if (this.invoice?.supplyPoints.length === 1) {
      this.selectedSupplyPoint = this.invoice.supplyPoints[0];
      this.currentActiveView = "complaintDetails";
    }

    const incorrectStateType = this.complaintReasons.find(x => x.externalId === ComplaintType.IncorrectFinalState);
    if (incorrectStateType) {
      this.selectedComplaintTypeChanged(incorrectStateType.id);
    }
  }

  @action.bound
  openComplaintDetailsForm() {
    this.selectedSupplyPoint = this.invoice.supplyPoints.find(item => item.id === this.selectedSupplyPointIds[0]);
    this.currentActiveView = "complaintDetails";
  }

  @action.bound
  selectedComplaintTypeChanged(complaintTypeId: number) {
    this.consumptionData = new ConsumptionData();
    this.invoiceComplaint = new ReqInvoiceComplaint();
    this.invoiceComplaint.claimTypeId = complaintTypeId;
    if (this.selectedSupplyPoint?.id) {
      this.invoiceComplaint.supplyPointId = this.selectedSupplyPoint?.id;
    }
    if (this.selectedComplaintType === ComplaintType.IncorrectFinalState && this.commodityType === CommodityType.Gas) {
      this.consumptionData.oldValue = this.invoice.consumption;
    }
    this.isUploadValid = true;
    this.attachValidatorToRequest(this.invoiceComplaint);
  }

  @action.bound
  @watchBusy
  async createComplaint() {
    this.fileSelectError = undefined;

    if (!this.partnerId) {
      return;
    }

    if (
      this.selectedComplaintType === ComplaintType.IncorrectFinalState ||
      this.selectedComplaintType === ComplaintType.IncorrectBilling
    ) {
      runInAction(() => {
        this.isUploadValid = !!this.invoiceComplaint.file;
      });
    }

    validate(this.invoiceComplaint);
    if (this.selectedComplaintType === ComplaintType.IncorrectFinalState) {
      validate(this.consumptionData);
    }
    if (!this.canConfirm) {
      return;
    }

    runInAction(() => {
      this.invoiceComplaint = merge(this.invoiceComplaint, this.consumptionData);
    });

    const response = await this.invoicesRepository.postInvoiceComplaint(this.partnerId, this.invoice.id, this.invoiceComplaint);
    if (response.success) {
      this.notificationService.addNotification(this.translateGeneral("request_sent_success"), SeverityLevel.success);
      this.requestClose();
    } else {
      runInAction(() => (this.errorMessage = unwrapErrorMessage(response.payload)));
    }
  }

  @action.bound
  attachValidatorToRequest(target: ReqInvoiceComplaint) {
    attachAutomaticValidator(this.consumptionData, getConsumptionDataValidationRules(this.commodityType));

    if (this.selectedComplaintType === ComplaintType.IncorrectFinalState) {
      attachAutomaticValidator(target, getIncorrectFinancialStateValidationRules(this.commodityType));
    } else if (
      this.selectedComplaintType === ComplaintType.IncorrectBilling ||
      this.selectedComplaintType === ComplaintType.Other
    ) {
      attachAutomaticValidator(target, getIncorrectBillingAndOtherValidationRules());
    }
  }

  @action.bound
  onDrop(files: File[]) {
    this.invoiceComplaint.file = files;
    this.isUploadValid = true;
    this.fileSelectError = undefined;
  }

  @action.bound
  onDropRejected() {
    this.fileSelectError = this.translateGeneral("upload_invalid_files");
  }

  @bound
  getComplaintTypeId(complaintType: ComplaintType) {
    const result = this.complaintReasons.find(item => item.externalId && item.externalId === complaintType);
    return result ? result.id : 0;
  }

  @action.bound
  handleRateChanged() {
    if (this.isDoubleTariff) {
      this.consumptionData.complaintVt = undefined;
      this.consumptionData.complaintNt = undefined;
    } else {
      this.consumptionData.complaintVt = true;
    }
  }

  @action.bound
  handleComplaintSelectChanged() {
    if (!this.consumptionData.complaintVt) {
      this.consumptionData.complaintVt = undefined;
      this.consumptionData.oldValue = undefined;
      this.consumptionData.currentValue = undefined;
      this.consumptionData.claimedValue = undefined;
    }
    if (!this.consumptionData.complaintNt) {
      this.consumptionData.complaintNt = undefined;
      this.consumptionData.oldValueNt = undefined;
      this.consumptionData.currentValueNt = undefined;
      this.consumptionData.claimedValueNt = undefined;
    }
  }

  get canConfirm() {
    return (
      !hasVisibleErrors(this.invoiceComplaint) &&
      !hasVisibleErrors(this.consumptionData) &&
      this.invoiceComplaint.claimTypeId &&
      this.isUploadValid
    );
  }

  get supplyPoints() {
    return createSupplyPointsWithIcons(this.enumsService, this.invoice.supplyPoints, "darkBlue");
  }

  get commodityType() {
    const commodityId = this.selectedSupplyPoint?.commodityId;
    return commodityId ? this.enumsService.getCommodityType(commodityId) : undefined;
  }

  get selectedComplaintTypeId() {
    return Number(this.invoiceComplaint.claimTypeId);
  }

  @computed
  get complaintReasons() {
    return this.enumsService.getValues("claimTypes").filter(x => x.active);
  }

  @computed
  get rates() {
    return this.enumsService.getValues("rates").filter(x => x.active);
  }

  get selectedComplaintType() {
    const complaintType = this.complaintReasons.find(item => item.id === this.selectedComplaintTypeId);
    if (complaintType) {
      return complaintType.externalId;
    }
    return "";
  }

  get isDoubleTariff() {
    return isDoubleTariff(this.rates.find(item => String(item.id) === String(this.invoiceComplaint.rateId))?.value);
  }

  get uploaderErrorMessage() {
    if (this.fileSelectError) {
      return this.fileSelectError;
    }
    return !this.isUploadValid ? this.localization.translateGeneral("validators.required_file") : undefined;
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`finance.invoice_complaint.${key}`);
  }

  @bound translateGeneral(key: string) {
    return this.localization.translateGeneral(`general.${key}`);
  }

  static Factory({ container }: interfaces.Context) {
    return (invoice: Invoice, partnerId: number) => {
      return new InvoiceComplaintViewModel(
        partnerId,
        invoice,
        container.get("ILocalizationService"),
        container.get(InvoicesRepository),
        container.get(EnumsService),
        container.get("INotificationService")
      );
    };
  }
}
