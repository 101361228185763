import { ILocalizationService } from "@emanprague/shared-services";
import { bound } from "@frui.ts/helpers";
import { BusyWatcher, ScreenBase, watchBusy } from "@frui.ts/screens";
import { attachAutomaticValidator, hasVisibleErrors, validate } from "@frui.ts/validation";
import ResetPassword, { ResetPasswordValidationRules } from "entities/resetPassword";
import { action, observable } from "mobx";
import LoginRepository from "repositories/loginRepository";

type StepName = "step1" | "step2";

export default class ForgottenPasswordViewModel extends ScreenBase {
  static navigationName = "forgottenPassword";
  navigationName = ForgottenPasswordViewModel.navigationName;
  busyWatcher = new BusyWatcher();
  @observable data: ResetPassword;
  @observable currentStep: StepName = "step1";
  @observable successMessage: string;

  constructor(public localization: ILocalizationService, private loginRepository: LoginRepository) {
    super();

    this.name = this.translate("title");

    this.data = { email: "" };
    attachAutomaticValidator(this.data, ResetPasswordValidationRules);
  }

  @bound translate(key: string) {
    return this.localization.translateGeneral(`profile.forgotten_password.${key}`);
  }

  get canContinue() {
    return !hasVisibleErrors(this.data);
  }

  @action.bound
  @watchBusy
  continue() {
    if (validate(this.data) && this.data.email) {
      this.loginRepository.resetPassword(this.data);
      this.successMessage = this.translate("message_sent").replace("%email%", this.data.email);
      this.currentStep = "step2";
    }
  }
}
