import type { ViewComponent } from "@frui.ts/views";
import { registerView, View, preventDefault } from "@frui.ts/views";
import { observer } from "mobx-react-lite";
import React from "react";
import { Form, Col, Button, Modal, SwitchNav, LoaderOverlay } from "@emanprague/ppaskit";
import { Input, Select } from "@frui.ts/bootstrap";
import ModalEditSupplyPointViewModel from "viewModels/products/myProducts/detailPages/supplyPoint/modalEditSupplyPointViewModel";
import AddressSelector from "controls/twoway/AddressSelector";
import Uploader from "controls/uploader";

const modalEditSupplyPointView: ViewComponent<ModalEditSupplyPointViewModel> = observer(({ vm }) => {
  const enableGeneralEdit = vm.productDetailContext.supplyPointCore.settings.change_supply_point_info;

  const navLinks = [
    {
      id: "step1",
      title: `${vm.translate("detail_data")}`,
      href: "",
      onClick: preventDefault(() => vm.changeStep("step1")),
    },
    {
      id: "step2",
      title: `${vm.translate("detail_address_change")}`,
      href: "",
      onClick: preventDefault(() => vm.changeStep("step2")),
    },
  ];

  return (
    <Modal centered show onHide={vm.requestClose}>
      <Modal.Header closeButton>
        <h2 className="display-4 m-0">{vm.name}</h2>
      </Modal.Header>
      <LoaderOverlay loading={vm.busyWatcher.isBusy}>
        <Modal.Body>
          {enableGeneralEdit && (
            <div className="d-flex justify-content-center mb-4">
              <SwitchNav links={navLinks} activeId={vm.currentStep} size="sm" className="px-5 py-3" />
            </div>
          )}
          <View vm={vm} context={vm.currentStep} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" className="order-2" onClick={vm.confirmChanges} disabled={!vm.canConfirm}>
            {vm.localization.translateGeneral("general.confirm_changes_button")}
          </Button>
          <Button variant="secondary" className="order-1" onClick={vm.requestClose}>
            {vm.localization.translateGeneral("general.cancel_button")}
          </Button>
        </Modal.Footer>
      </LoaderOverlay>
    </Modal>
  );
});

registerView(modalEditSupplyPointView, ModalEditSupplyPointViewModel);

const step1: ViewComponent<ModalEditSupplyPointViewModel> = observer(({ vm }) => (
  <>
    <Form className="w-100 w-sm-75 mx-auto mb-n3 mt-10">
      <Form.Row>
        <Form.Group as={Col} xs={12}>
          <Form.Label>{vm.translate("naming_supply_point")}</Form.Label>
          <Input type="text" target={vm.supplyPointDetails} property="name" />
        </Form.Group>
      </Form.Row>
      <Form.Row>
        <Form.Group as={Col} xs={6}>
          <Form.Label>{vm.localization.translateAttribute("supply_point", "floor")}</Form.Label>
          <Select
            target={vm.supplyPointDetails}
            property="installationFloorId"
            items={vm.installationFloors}
            mode="key"
            keyProperty="id"
            textProperty="name"
          />
        </Form.Group>
        <Form.Group as={Col} xs={6}>
          <Form.Label>{vm.localization.translateAttribute("supply_point", "flat_number")}</Form.Label>
          <Input type="text" target={vm.supplyPointDetails} property="flatNumber" />
        </Form.Group>
      </Form.Row>
      {vm.errorMessage && <Form.Control.Feedback type="invalid">{vm.errorMessage}</Form.Control.Feedback>}
    </Form>
  </>
));

registerView(step1, ModalEditSupplyPointViewModel, "step1");

const step2: ViewComponent<ModalEditSupplyPointViewModel> = observer(({ vm }) => (
  <>
    <Form className="modal-form">
      <Form.Row className="form-row-centered">
        <AddressSelector vm={vm.addressChangeVM} />
      </Form.Row>
      <Form.Row className="mb-4">
        <strong className="mt-4 mb-3">{vm.translate("long_message")}</strong>
        <Uploader
          localization={vm.localization}
          onDrop={vm.onDrop}
          onDropRejected={vm.onDropRejected}
          fileSelectError={vm.fileSelectError}
        />
      </Form.Row>
      {vm.errorMessage && <Form.Control.Feedback type="invalid">{vm.errorMessage}</Form.Control.Feedback>}
    </Form>
  </>
));

registerView(step2, ModalEditSupplyPointViewModel, "step2");
